import Whiteboard from '../whiteboard/whiteboard';

export default function ThinkletLoader($imagePreloader, $thinkletManager, $cuethink) {
  var _me = this;

  function digest($scope) {
    if ($scope.$$phase)
      return;

    if ($scope.$digest) {
      try {
        $scope.$digest();
      } catch (e) {
      }
    }
  }

  _me.load = function (thinklet, $scope, forRecording, callback) {
    $scope.loadImage = $imagePreloader.getImage;

    if (thinklet.videoURL) {
      console.log('loading video: ' + thinklet.videoURL);

      if (thinklet.videoURL.indexOf('.txt') >= 0) {
        $cuethink.get(thinklet.videoURL, function (data) {
          if ((typeof data) != 'object')
            return;

          var imageURLs = {};

          function addImageURL(event) {
            if ((event.type == 'image' || event.type == 'equation'))
              imageURLs[event.url] = null;
          }

          Whiteboard.crawlEvents(data.recordedEvents, addImageURL, addImageURL);

          imageURLs = Object.keys(imageURLs);

          $imagePreloader.load(imageURLs, function (images) {
            $scope.ratio = data.ratio;
            $scope.events = forRecording ? data.events : data.recordedEvents;

            if ((typeof callback) == 'function')
              callback(data, images);

            digest($scope);
          });
        }, function () {
          $thinkletManager.loadThinklet($scope.thinklet.id, function (thinklet) {
            $scope.thinklet.dfs_playback_url = thinklet.dfs_playback_url;
            $scope.thinklet.videoURL = thinklet.videoURL;

            $thinkletManager.cacheThinklet(thinklet);

            _me.load(thinklet, callback);
          }, false, true);
        });
      } else {
        $scope.events = null;
        $scope.videoURL = thinklet.videoURL;

        digest($scope);
      }
    } else {
      $scope.events = null;
      $scope.videoURL = null;

      digest($scope);
    }

    if (thinklet.audioURL === undefined)
      thinklet.audioURL = null;
  };
}

ThinkletLoader.sharedInstance = null;
