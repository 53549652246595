import './rubric.css';
import tpl from './rubricSummaryDirective.tpl.html';
import TeacherScreenDirective from 'core/teacherscreen/teacherScreenDirective';
import _ from 'underscore';
import './rubricStatsDirective';
import { translate } from 'core/common/translation-helpers';
import { setUserModesScopeMembers } from '../common/user-modes';

export default function RubricSummaryDirective ($q, $translate, $rootScope) {
    "ngInject";

    var linkFunction = function ($scope) {
        var _maxVersion;

        setUserModesScopeMembers($scope);

        $scope.viewModel = {
            notStartedStudents: [],
            inProgressStudents: [],
            completedStudents: [],
            notesTypes: [],
            notesSorts: [
                {
                    name: 'Score Asc.',
                    sort: 'score'
                },
                {
                    name: 'Score Desc.',
                    sort: '-score'
                },
                {
                    name: 'Name A-Z',
                    sort: 'name'
                },
                {
                    name: 'Name Z-A',
                    sort: '-name'
                }
            ],
          versions: null
        };

      var _mostRecentVersion = {
        id: -1,
          name: 'Most Recent'
      };

      var _allVersions = {
        id: 0,
          name: 'All Versions'
      };

      var TOTAL_SCORE;

      translate([
        'SCORE_ASC',
        'SCORE_DESC',
        'NAME_A_Z',
        'NAME_Z_A',
        'MOST_RECENT',
        'ALL_VERSIONS',
        'UNDERSTAND',
        'EXPLORE',
        'PLAN',
        'SOLVE',
        'REVIEW',
        'TOTAL_SCORE'], $translate, $rootScope, $scope, function (translations) {
        $scope.viewModel.notesSorts[0].name = translations['SCORE_ASC'];
        $scope.viewModel.notesSorts[1].name = translations['SCORE_DESC'];
        $scope.viewModel.notesSorts[2].name = translations['NAME_A_Z'];
        $scope.viewModel.notesSorts[3].name = translations['NAME_Z_A'];

        _mostRecentVersion.name = translations['MOST_RECENT'];
        _allVersions.name = translations['ALL_VERSIONS'];

        $scope.viewModel.notesTypes[0] = $scope.isNewDesign() ? translations.EXPLORE : translations.UNDERSTAND;
        $scope.viewModel.notesTypes[1] = translations.PLAN;
        $scope.viewModel.notesTypes[2] = translations.SOLVE;
        $scope.viewModel.notesTypes[3] = translations.REVIEW;
        $scope.viewModel.notesTypes[4] = TOTAL_SCORE = translations['TOTAL_SCORE'];

        $scope.selectNotesType($scope.viewModel.notesTypes[0]);
      });

      function generateVersions () {
        var result = [_mostRecentVersion];

        if (_maxVersion)
            for (var i = 1; i <= _maxVersion; i++)
                result.push({ id: i, name: 'V' + i});

        //result.push(_allVersions);

        $scope.viewModel.versions = result;
      }

      generateVersions();

      $scope.viewModel.selectedVersion = _mostRecentVersion;
        //$scope.viewModel.selectedVersion = _allVersions;

        $scope.selectNotesType = function (type) {
            $scope.viewModel.isNotesTypeMenuOpen = false;

            if (type)
                $scope.viewModel.selectedNotesType = type;

            if ($scope.viewModel.rubricStats) {
              if ($scope.viewModel.selectedNotesType === TOTAL_SCORE)
                $scope.viewModel.notes = $scope.viewModel.rubricStats.totalScore;
              else
                $scope.viewModel.notes = _.filter($scope.viewModel.rubricStats.rubric_notes, {type: $scope.viewModel.selectedNotesType.toLowerCase()});
            }
        };

        $scope.selectNotesSort = function (sort) {
            $scope.viewModel.isNotesSortMenuOpen = false;

            $scope.viewModel.selectedNotesSort = sort;
        };

        $scope.selectNotesSort($scope.viewModel.notesSorts[0]);

        var assignmentsDeferred = $q.defer();

        $scope.loadAssignments().then(function (assignments) {
            $scope.viewModel.assignments = assignments;

            if (assignments.length > 0) {
                var assignment;

                if (typeof localStorage !== 'undefined') {
                    var id = localStorage.getItem('rubricSummaryAssignmentId');

                    if (id) {
                        localStorage.removeItem('rubricSummaryAssignmentId');

                        assignment = _.find(assignments, function (a) { return a.id == id });
                    }
                }

                if (!assignment)
                    assignment = assignments[0];

                $scope.selectAssignment(assignment).then(function () {
                    assignmentsDeferred.resolve(assignments);
                });
            } else {
                assignmentsDeferred.resolve(assignments);
            }
        });

        var allClasses;
        var allStudents;
        var studentsDeferred = $q.defer();

        $scope.loadClasses().then(function (classes) {
            allClasses = classes;
            $scope.viewModel.classes = classes;

            var students = [];

            classes.forEach(function (cls) {
                if (cls.students)
                students = students.concat(cls.students);
            });

            students = _.uniq(students, false, function (student) {
                return student.id;
            });

            $scope.viewModel.students = allStudents = students;

            studentsDeferred.resolve(students);
        });

        var getStudentById = function (id) {
            return _.find(allStudents, { id: id });
        };

        var calculateStatuses = function () {
            var notStartedStudents = [];
            var inProgressStudents = [];
            var completedStudents = [];
            var notStartedThinklets = 0;
            var inProgressThinklets = 0;
            var completedThinklets = 0;

            var assignment = $scope.viewModel.selectedAssignment;

            var selectedStudents = getSelectedStudents(function (student) {
                return student.firstName + ' ' + student.lastName;
            });

            if (selectedStudents && selectedStudents.length === 0)
                selectedStudents = null;

            var classIds = $scope.getSelectedClassIds();

            _.each(assignment.classNames, function (item) {
                if (classIds.length > 0 && !classIds.includes(item.classId))
                    return;

                var thinkletCount;

                if (selectedStudents)
                    thinkletCount = 0;

                _.each(item.notStartedThinkletsStudents, function (student) {
                    if (selectedStudents && !selectedStudents.includes(student))
                        return;

                    thinkletCount++;

                    if (!notStartedStudents.includes(student))
                        notStartedStudents.push(student);
                });

                if (selectedStudents) {
                    notStartedThinklets += thinkletCount;

                    thinkletCount = 0;
                } else {
                    notStartedThinklets += item.notStartedThinklets;
                }

                _.each(item.inProgressThinkletsStudents, function (student) {
                    if (selectedStudents && !selectedStudents.includes(student))
                        return;

                    thinkletCount++;

                    if (!inProgressStudents.includes(student))
                        inProgressStudents.push(student);
                });

                if (selectedStudents) {
                    inProgressThinklets += thinkletCount;

                    thinkletCount = 0;
                } else {
                    inProgressThinklets += item.inProgressThinklets;
                }

                _.each(item.completedThinkletsStudents, function (student) {
                    if (selectedStudents && !selectedStudents.includes(student))
                        return;

                    thinkletCount++;

                    if (!completedStudents.includes(student))
                        completedStudents.push(student);
                });

                if (selectedStudents)
                    completedThinklets += thinkletCount;
                else
                    completedThinklets += item.completedThinklets;
            });

          _.each(assignment.studentNames, function (item) {
            if (classIds.length > 0 && !classIds.includes(item.classId))
              return;

            var thinkletCount;

            if (selectedStudents)
              thinkletCount = 0;

            _.each(item.notStartedThinkletsStudents, function (student) {
              if (selectedStudents && !selectedStudents.includes(student))
                return;

              thinkletCount++;

              if (!notStartedStudents.includes(student))
                notStartedStudents.push(student);
            });

            if (selectedStudents) {
              notStartedThinklets += thinkletCount;

              thinkletCount = 0;
            } else {
              notStartedThinklets += item.notStartedThinklets;
            }

            _.each(item.inProgressThinkletsStudents, function (student) {
              if (selectedStudents && !selectedStudents.includes(student))
                return;

              thinkletCount++;

              if (!inProgressStudents.includes(student))
                inProgressStudents.push(student);
            });

            if (selectedStudents) {
              inProgressThinklets += thinkletCount;

              thinkletCount = 0;
            } else {
              inProgressThinklets += item.inProgressThinklets;
            }

            _.each(item.completedThinkletsStudents, function (student) {
              if (selectedStudents && !selectedStudents.includes(student))
                return;

              thinkletCount++;

              if (!completedStudents.includes(student))
                completedStudents.push(student);
            });

            if (selectedStudents)
              completedThinklets += thinkletCount;
            else
              completedThinklets += item.completedThinklets;
          });

            $scope.viewModel.notStartedStudents = notStartedStudents;
            $scope.viewModel.inProgressStudents = inProgressStudents;
            $scope.viewModel.completedStudents = completedStudents;
            $scope.viewModel.notStartedThinklets = notStartedThinklets;
            $scope.viewModel.inProgressThinklets = inProgressThinklets;
            $scope.viewModel.completedThinklets = completedThinklets;
        };

        $q.all([assignmentsDeferred.promise, studentsDeferred.promise]).then(calculateStatuses);

        var getFullName = function (user) {
            return user.firstName + ' ' + user.lastName;
        };

        $scope.getSelectedClassIds = function () {
            var result = [];
            var allIds = [];

            _.each($scope.viewModel.classes, function (classroom) {
                var id = classroom.id;

                if (classroom.selected)
                    result.push(id);

                allIds.push(id);
            });

            if (result.length === 0)
                result = allIds;

            return result;
        };

        $scope.getSelectedStudentIds = function () {
            var result = [];
            var all = [];

            _.each($scope.viewModel.students, function (student) {
                var id = student.id;

                if (student.selected)
                    result.push(id);

                all.push(id);
            });

            if (result.length === 0)
                result = all;

            return result;
        };

        $scope.getSelectedClassesCount = function () {
            if (!$scope.viewModel.classes)
                return 0;

            var result = _.filter($scope.viewModel.classes, { selected: true });

            if (!result)
                return 0;

            return result.length;
        };

        function getSelectedStudents (callback) {
            if (!$scope.viewModel.students)
                return null;

            var result = [];

            $scope.viewModel.students.forEach(function (student) {
                if (student.selected) {
                    var item;

                    if (callback)
                        item = callback(student);
                    else
                        item = student;

                    result.push(item);
                }
            });

            return result;
        }

        $scope.getSelectedStudentsCount = function () {
            var result = getSelectedStudents();

            if (!result)
                return 0;

            return result.length;
        };

        var _setMaxVersion;

        function loadStats () {
            if (!$scope.viewModel.students) {
                studentsDeferred.promise.then(function () {
                    loadStats();
                });

                return;
            }

            var version = $scope.viewModel.selectedVersion.id;
            var classIds = $scope.getSelectedClassIds();
            var studentIds = $scope.getSelectedStudentIds();

            if (!_maxVersion && !_setMaxVersion) {
                $scope.loadRubricStats($scope.viewModel.selectedAssignment.problemId,
                                        $scope.viewModel.selectedAssignment.id,
                                        classIds,
                                        studentIds,
                                        0)
                    .then(function (result) {
                        _maxVersion = result.max_version;
                        _setMaxVersion = true;

                        generateVersions();

                        loadStats();
                    });

                return;
            }

            if (version === -1)
                version = _maxVersion;

            $scope.loadRubricStats($scope.viewModel.selectedAssignment.problemId,
                                    $scope.viewModel.selectedAssignment.id,
                                    classIds,
                                    studentIds,
                                    version)
                .then(function (result) {
                    $scope.viewModel.rubricStats = result;

                    var type;

                    if ($scope.viewModel.selectedAssignment && $scope.viewModel.selectedAssignment.type) {
                        type = $scope.viewModel.selectedAssignment.type;
                        type = type[0].toUpperCase() + type.substr(1);
                    } else {
                        type = $scope.viewModel.notesTypes[0];
                    }

                    $scope.selectNotesType(type);
                });

            calculateStatuses();
        }

        $scope.selectAssignment = function (assignment) {
            $scope.viewModel.isProblemMenuOpen = false;

            $scope.viewModel.selectedAssignment = assignment;

            return $scope.loadAssignment(assignment.id).then(function (assign) {
              assignment.classIds = assign.classIds.concat(assign.studentClassIds);
              assignment.classNames = assign.classNames;
              assignment.studentNames = assign.studentNames;

                $scope.viewModel.classes = _.filter(allClasses, function (cls) {
                    var found;

                    if (assignment.classIds) {
                      found = assignment.classIds.includes(cls.id);

                      if (!found)
                        cls.selected = false;
                    }

                    return found;
                });

                populateStudents();

                _maxVersion = null;
                _setMaxVersion = false;
                $scope.viewModel.selectedVersion = _mostRecentVersion;

                loadStats();
            });
        };

        var populateStudents = function () {
            if (!allStudents)
                return;

            var selectedClassIds = $scope.getSelectedClassIds();

            $scope.viewModel.students = _.filter(allStudents, function (user) {
                var found = selectedClassIds.find(function (selectedClassId) {
                  return user.classIds.find(function (studentClassId) {
                    return selectedClassId === studentClassId;
                  });
                });

                if (!found)
                    user.selected = false;

                return found;
            });
        };

        $scope.changedClassCheckbox = function (classroom) {
            populateStudents();

            loadStats();
        };

        $scope.changedStudentCheckbox = loadStats;

        $scope.toggleNotesSort = function () {
            var sort = $scope.viewModel.selectedNotesSort;
            var newSort;

            if (sort.sort == 'name' || sort.sort == 'score')
                newSort = '-' + sort.sort;
            else
                newSort = sort.sort.substr(1);

            $scope.viewModel.selectedNotesSort = _.find($scope.viewModel.notesSorts, { sort: newSort });
        };

        $scope.selectThinklet = function (note) {
            if (note.thinklet_id) {
                if (typeof localStorage !== 'undefined')
                    localStorage.setItem('rubricSummaryAssignmentId', $scope.viewModel.selectedAssignment.id);

                $scope.viewThinklet(note.thinklet_id);
            }
        };

        $scope.selectVersion = function (version) {
            $scope.viewModel.isVersionMenuOpen = false;

          $scope.viewModel.selectedVersion = version;

          loadStats();
        };
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: TeacherScreenDirective.createScope({
            loadAssignments: '=',
            loadClasses: '=',
            loadRubricStats: '=',
            viewThinklet: '=',
            loadAssignment: '='
		})
    };
};
