angular.module('cuethink.singleclick', []).directive('singleClick', [function() {
    var linkFunction = function($scope, $elm) {
        function onClick (event) {
            $(event.target).css('pointerEvents', 'none');
        }

        $($elm).on('click', onClick);

        $scope.$on('$destroy', function () {
            $($elm).off('click', onClick);
        });
    };

    return {
        restrict: "A",
        link: linkFunction
    };
}]);
