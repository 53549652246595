import 'core/navigation/navbar';
import 'src/components/shared/user_data';
import 'src/components/shared/thinkletloader';
import 'core/tutorial/tutorial';
import 'config';
import 'src/components/shared/teacher_data';
import 'angular-translate';
import CueThinkConfig from '../../app/config.provider';
import { setUserModesScopeMembers } from 'core/common/user-modes';
import 'src/app/digital-promise/digitalPromiseProbe.directive';
import 'src/images/AlgebraTileCircle.svg';
import 'src/images/FractionBar_Circle.svg';
import 'src/images/PlanningJournal_Circle.svg';
import 'src/images/SpeechText_Circle.svg';
import 'src/images/TextSpeechCircleCircle.svg';
import helperStore from 'core/helper/helper.store';
import permissionsManager, { Permissions } from 'core/permissions/permissions';

angular.module('baseController', ['cuethink.navigation', 'userManager', 'thinkletLoader', 'CueThinkConfig', 'cuethink.tutorial', 'pascalprecht.translate'])
	.controller('BaseController', ['$scope', '$location', '$state', 'UserManager', '$rootScope', 'ThinkletLoader', 'CueThinkConfig', 'TutorialService', 'ThinkletManager', 'TeacherManager', '$transitions', '$translate', '$stateParams', '$compile', 'CueThink', function ($scope, $location, $state, $userManager, $rootScope, $thinkletLoader, config, tutorial, thinkletManager, TeacherManager, $transitions, $translate, $stateParams, $compile, CueThink) {
	    "ngInject";

		if (false) { // not logged in
			$location.path('/login');

			return;
		}

		$scope.state = $state.current.name;

		$scope.navigateDashboard = function () {
			$state.go('dashboard');
		};

		$scope.navigateProfile = function () {
			$state.go('profile');
		};

		$scope.navigateGallery = function () {
      CueThink.logEvent('gallery');

			$state.go('browse');
		};

		$scope.navigateRubric = function () {
			$state.go('rubric-overview');
		};

		$scope.navigateSettings = function () {
			$state.go('student-settings');
		};

		$scope.navigateJoinClass = function () {
		  $state.go('class-codes');
    };

		$scope.selectThinklet = function (thinkletId) {
			if ((typeof thinkletId) == 'object')
				thinkletId = thinkletId.id;

			$state.go('view', { id: thinkletId });
		};

		$scope.user = $userManager.getUser();

		if ($scope.user.type != 'Student') {
			$scope.navigateToTeacherDashboard = function () {
				$state.go('view-all-assignments');
			};
		}

		if (config.tutorialSteps) {
			$scope.startTutorial = function (finishCallback) {
				try {
					Intercom('trackEvent', 'User started student tutorial', { state: $state.current.name });
				} catch (e) {
				}

				tutorial.start(config.tutorialSteps);

				tutorial.setOnFinishCallback(function () {
					var result = tutorialFinishCallback();

					if (finishCallback)
						finishCallback();

					return result;
				});
			};
		}

		$scope.$on('CloseDigitalPromiseProbe', function (event, finished) {
		  if (finished)
		    $scope.logOut(true);
    });

		$scope.$on('SaveDigitalPromiseProbeResponse', function (event, result) {
		  $userManager.saveDigitalPromiseProbeResponse(result);
    });

		function showDigitalPromiseProbe () {
      var probe = angular.element(document.createElement('digital-promise-probe'));
      var el = $compile(probe)($scope.$new(true));

      angular.element(document.body).append(probe);
    }

		$scope.logOut = function (skipPrompt) {
			if ($scope.user.type === 'Student' && $scope.isDPUser() && $userManager.getDigitalPromiseToolsUsed().length > 0 && !skipPrompt) {
				showDigitalPromiseProbe();

				return false;
			}

			if ($scope.isLogOutAllowed()) {
				return BaseController.logOut(skipPrompt, $state, $userManager, $translate);
			}
		};

		$scope.setUpNavigation = $userManager.logOutIntercom;
		$scope.getTrialDays = $userManager.getTrialDaysLeft;
		$scope.isCueteachEnabled = $userManager.getUser().cueteachEnabled;

		this.loadThinkletRecording = function (thinklet, forRecording, callback) {
			$thinkletLoader.load(thinklet, $scope, forRecording, callback);
		};

		var leaveListeners = [];
		this.addLeaveListener = function (callback) {
			leaveListeners.push(callback);
		};

		$scope.addLeaveListener = this.addLeaveListener;

		tutorial.setLoadCallback(function (step, finish, steps) {
			if (step.state != $state.current.name && (!step.data || !step.data.thinklet))
				return;

			if (!step.data) {
				var originalState = step.state;
				var i = steps.indexOf(step);

				if (steps.length > i + 1 && steps[i + 1].state != originalState) {
					while (i > 0 && !step.data && step.state == originalState) {
						step = steps[--i];
					}
				}
			}

			if (step.data && step.data.thinklet)
				thinkletManager.cacheThinklet(step.data.thinklet);
		});

		var tutorialFinishCallback = function () {
			try {
				Intercom('trackEvent', 'User finished tutorial', { state: $state.current.name });
			} catch (e) {
			}

			$state.go('dashboard', { welcome: null, finishedTutorial: true });

			return false;
		};

		tutorial.setOnFinishCallback(tutorialFinishCallback);

		var destroyed, changingState;
		$transitions.onStart({}, transition => {
			if (changingState || destroyed)
				return;

			var cancel;

			for (var i = 0; i < leaveListeners.length; i++)
				if (leaveListeners[i](transition) === false)
					cancel = true;

			if (cancel)
				return false;
			else
				changingState = true;
		});

		const unbindTransitionListener = $transitions.onSuccess({}, function () {
			if (destroyed)
				return;

			changingState = false;
		});

		$scope.isLogOutAllowed = function () {
			return true;
		};

		$scope.$on('$destroy', function () {
			tutorial.setLoadCallback(null);

			if (unbindTransitionListener)
				unbindTransitionListener();

			destroyed = true;
		});

		if (tutorial.isActive()) {
			var resumedTutorial;

			$scope.$$postDigest(function () {
				if (resumedTutorial)
					return;

				resumedTutorial = true;

				tutorial.resume();
			});
		}

		$scope.fetchHasSeenTrialEndPopUp = TeacherManager.fetchHasSeenTrialEndPopUp;
		$scope.setHasSeenTrialEndPopUp = TeacherManager.setHasSeenTrialEndPopUp;

		$scope.onSubmitMindWanderingProbe = function (options) {
      var thinklet;

      if ($stateParams.id)
        thinklet = thinkletManager.getCachedThinklet($stateParams.id);

      if (!thinklet)
        thinklet = thinkletManager.getLastLoadedThinklet();

      if (thinklet) {
        options.thinkletId = thinklet.id;
        options.problemId = thinklet.problemId;
        options.problemName = thinklet.problem.title;
      }

			$userManager.submitMindWanderingProbe(options);
		};

    setUserModesScopeMembers($scope);

		$scope.navigateToWorkOnThinklet = BaseController.navigateToWorkOnThinklet.bind(null, $state, $scope.user);

		$scope.defaultAvatarColors = config.defaultAvatarColors;
	}])
	.directive('back', function () {
		return {
			restrict: 'A',
			link: function (scope, element) {
				element.on('click', function () {
					window.history.back();
					scope.$apply();
				});
			}
		};
	});

var BaseController = {
	logOut: function (skipPrompt, $state, userManager, $translate) {
		if (skipPrompt || confirm($translate.instant('ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT'))) {
			userManager.logOutIntercom();

			userManager.logOut(function () {
				$state.go('login');
			});
		}
	},

  navigateToWorkOnThinklet: function ($state, user, thinklet) {
    var state;
    var thinkletId;

    if (typeof thinklet === 'object')
      thinkletId = thinklet.id;
    else if (typeof thinklet === 'number')
      thinkletId = thinklet;

    if (permissionsManager.getPermission(Permissions.FourPhaseMenu))
      state = 'four-phase-menu';
    else
      state = 'understand';

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_TITLE', null);
    helperStore.commit('SET_MESSAGE', null);

    $state.go(state, { id: thinkletId });
  }
};

export default BaseController;
