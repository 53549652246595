export default function TranslateManager() {
  let language;

  this.getLanguage = () => {
    if (typeof language === 'function')
      return language();
    else
      return language;
  };

  this.setLanguage = val => {
    language = val;
  };

  this.setTranslator = val => {
    this.translate = val;
  };
};

TranslateManager.getShared = () => {
  if (!TranslateManager.sharedInstance)
    TranslateManager.sharedInstance = new TranslateManager();

  return TranslateManager.sharedInstance;
};
