import module from '../login.module';
import '../loginDirective';
import CleverAuthentication from '../clever';
import '../register/register.css';
import tpl from './ssoRegistrationDirective.tpl.html';
import jQuery from 'jquery';
import '../register/registrationFieldDirective';
require('../register/registerStudent.partial.html');
require('../register/registerTeacher.partial.html');
import _ from 'underscore';
import 'angular-translate';
import { CueThinkLogin } from '../loginDirective';
import { translate } from 'core/common/translation-helpers';

export default function SSORegisterDirective ($q, $translate, $rootScope) {
  "ngInject";

  var linkFunction = function ($scope) {
    var email = $scope.email;
    var name = $scope.name;
    var id_token = $scope.idToken;
    var firstName;
    var lastName;

    $scope.isClever = $scope.service == 'clever';

    if ($scope.service == 'google')
      $scope.disableEmail = true;

    if ($scope.firstName) {
      firstName = $scope.firstName;
      lastName = $scope.lastName;
    } else if (name) {
      firstName = name.split(' ')[0];
      lastName = name.split(' ')[1];
    }

    if (!$scope.username)
      $scope.username = email;

    $scope.formData = {
      student: {
        codeName: '',
        type: 103,
        'email': email,
        username: $scope.username
      },
      teacher:  {
        type: '',
        'email': email,
        username: $scope.username
      },
      typeList: []
    };

    translate(['TEACHER', 'DEPARTMENT_HEAD', 'INSTRUCTIONAL_COACH', 'TECHNOLOGY_SPECIALIST', 'ADMINISTRATOR', 'CURRICULUM_COORDINATOR', 'PARENT', 'OTHER'], $translate, $rootScope, $scope, function (translations) {
      $scope.formData.typeList = [
        { id: '104', label: translations.TEACHER },
        { id: '109', label: translations['DEPARTMENT_HEAD'] },
        { id: '106', label: translations['INSTRUCTIONAL_COACH'] },
        { id: '111', label: translations['TECHNOLOGY_SPECIALIST'] },
        { id: '101', label: translations['ADMINISTRATOR'] },
        { id: '110', label: translations['CURRICULUM_COORDINATOR'] },
        { id: '112', label: translations['PARENT'] }
      ];

      $scope.viewModel.grades = [
        'Pre-K',
        'K',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        translations.OTHER
      ].map(function (grade) {
        return { name: grade, selected: false };
      });
    });

    $scope.viewModel = {
      schools: null,
      isUS: true,
      grades: null,
      isGradeOpen: false,
      isTermsOfServiceChecked: false,
      hasRegistered: false
    };

    $scope.viewModel.states = [
      {
        "name": "Alabama",
        "abbreviation": "AL"
      },
      {
        "name": "Alaska",
        "abbreviation": "AK"
      },
      {
        "name": "Arizona",
        "abbreviation": "AZ"
      },
      {
        "name": "Arkansas",
        "abbreviation": "AR"
      },
      {
        "name": "California",
        "abbreviation": "CA"
      },
      {
        "name": "Colorado",
        "abbreviation": "CO"
      },
      {
        "name": "Connecticut",
        "abbreviation": "CT"
      },
      {
        "name": "Delaware",
        "abbreviation": "DE"
      },
      {
        "name": "District Of Columbia",
        "abbreviation": "DC"
      },
      {
        "name": "Florida",
        "abbreviation": "FL"
      },
      {
        "name": "Georgia",
        "abbreviation": "GA"
      },
      {
        "name": "Hawaii",
        "abbreviation": "HI"
      },
      {
        "name": "Idaho",
        "abbreviation": "ID"
      },
      {
        "name": "Illinois",
        "abbreviation": "IL"
      },
      {
        "name": "Indiana",
        "abbreviation": "IN"
      },
      {
        "name": "Iowa",
        "abbreviation": "IA"
      },
      {
        "name": "Kansas",
        "abbreviation": "KS"
      },
      {
        "name": "Kentucky",
        "abbreviation": "KY"
      },
      {
        "name": "Louisiana",
        "abbreviation": "LA"
      },
      {
        "name": "Maine",
        "abbreviation": "ME"
      },
      {
        "name": "Maryland",
        "abbreviation": "MD"
      },
      {
        "name": "Massachusetts",
        "abbreviation": "MA"
      },
      {
        "name": "Michigan",
        "abbreviation": "MI"
      },
      {
        "name": "Minnesota",
        "abbreviation": "MN"
      },
      {
        "name": "Mississippi",
        "abbreviation": "MS"
      },
      {
        "name": "Missouri",
        "abbreviation": "MO"
      },
      {
        "name": "Montana",
        "abbreviation": "MT"
      },
      {
        "name": "Nebraska",
        "abbreviation": "NE"
      },
      {
        "name": "Nevada",
        "abbreviation": "NV"
      },
      {
        "name": "New Hampshire",
        "abbreviation": "NH"
      },
      {
        "name": "New Jersey",
        "abbreviation": "NJ"
      },
      {
        "name": "New Mexico",
        "abbreviation": "NM"
      },
      {
        "name": "New York",
        "abbreviation": "NY"
      },
      {
        "name": "North Carolina",
        "abbreviation": "NC"
      },
      {
        "name": "North Dakota",
        "abbreviation": "ND"
      },
      {
        "name": "Ohio",
        "abbreviation": "OH"
      },
      {
        "name": "Oklahoma",
        "abbreviation": "OK"
      },
      {
        "name": "Oregon",
        "abbreviation": "OR"
      },
      {
        "name": "Pennsylvania",
        "abbreviation": "PA"
      },
      {
        "name": "Rhode Island",
        "abbreviation": "RI"
      },
      {
        "name": "South Carolina",
        "abbreviation": "SC"
      },
      {
        "name": "South Dakota",
        "abbreviation": "SD"
      },
      {
        "name": "Tennessee",
        "abbreviation": "TN"
      },
      {
        "name": "Texas",
        "abbreviation": "TX"
      },
      {
        "name": "Utah",
        "abbreviation": "UT"
      },
      {
        "name": "Vermont",
        "abbreviation": "VT"
      },
      {
        "name": "Virginia",
        "abbreviation": "VA"
      },
      {
        "name": "Washington",
        "abbreviation": "WA"
      },
      {
        "name": "West Virginia",
        "abbreviation": "WV"
      },
      {
        "name": "Wisconsin",
        "abbreviation": "WI"
      },
      {
        "name": "Wyoming",
        "abbreviation": "WY"
      }
    ];

    $scope.formData.teacher.firstName = firstName;
    $scope.formData.teacher.lastName = lastName;
    $scope.formData.student.firstName = firstName;
    $scope.formData.student.lastName = lastName;
    $scope.invitationCode = "";

    $scope.studentInput = function () {
      $scope.schoolName = "";
      $scope.digest();
    };

    $scope.teacherInput = function () {
      $scope.invitationCode = "";
      $scope.digest();
    };

    function getSelectedGrades () {
      return $scope.viewModel.grades.filter(function (grade) {
        return grade.selected;
      }).map(function (grade) {
        return grade.name;
      });
    }

    function registerGoogleUser (postData) {
      var error = function (message) {
        var msg = $translate.instant(message);

        if (typeof msg === 'object')
          msg = Object.values(msg);

        if (Array.isArray(msg))
          msg = msg.join('\n');

        alert(msg);

        $scope.isRegistering = false;

        digest();
      };

      var success = function (success, message) {
        if (success) {
          $scope.googleAuthenticate(id_token, $scope.isMobile, function (success, message) {
            if (success) {
              var isStudent = postData.type == 103;

              var done = function () {
                $scope.googleLogInSuccess(isStudent);
              };

              if (isStudent || !$scope.createStarterAssignments)
                done();
              else
                $scope.createStarterAssignments().then(done, done);
            } else {
              alert($translate.instant(message));
            }
          });
        } else {
          error(message);
        }
      };

      var promise = $scope.register(postData, success, error);

      if (promise) {
        promise.then(function (user) {
          success(true, user);
        }, function (message) {
          success(false, message);
        });
      }
    }

    var cleverAuthentication;

    if ($scope.isClever)
      cleverAuthentication = new CleverAuthentication($scope.register, $scope.cleverRedirectUrl, $scope.cleverClientId);

    function logIn () {
      var deferred = $q.defer();

      CueThinkLogin.cleverAuthenticate($scope.cleverAuthenticate, $scope.cleverRedirectUrl, $scope.cleverClientId, function (success, user) {
        if (!user) {
          user = success;
          success = true;
        }

        if (success) {
          deferred.resolve(user);

          var logInSuccess = function () {
            $scope.logInSuccess(user);
          };

          if (user.type == 'Student' || !$scope.createStarterAssignments)
            logInSuccess();
          else
            $scope.createStarterAssignments().then(logInSuccess, logInSuccess);
        }
      });

      return deferred.promise;
    }

    function registerCleverUser (register, postData, authCode, isToken) {
      var deferred = $q.defer();

      postData.cleverAuthCode = authCode;

      if (isToken)
        postData.fromMobile = true;

      var error = function (message) {
        var msg = $translate.instant(message);

        if (typeof msg === 'object')
          msg = Object.values(msg);

        if (Array.isArray(msg))
          msg = msg.join('\n');

        alert(msg);

        $scope.isRegistering = false;

        digest();

        deferred.reject(msg);
      };

      var success = function (success, message) {
        if (success) {
          logIn().then(function (user) {
            deferred.resolve(user);
          });
        } else {
          error(message);
        }
      };

      var promise = register(postData, success, error);

      if (promise)
        promise.then(function (user) {
          success(true, user);
        }, function (message) {
          success(false, message);
        });

      return deferred.promise;
    }

    function register (formName, postData) {
      var isValid = $scope.formData[formName].$valid;

      if (!$scope.isValid())
        return false;

      if (isValid) {
        $scope.isRegistering = true;

        postData.oAuthRegistration = true;

        if ($scope.isClever) {
          if ($scope.token) {
            registerCleverUser($scope.register, postData, $scope.token, $scope.isMobile);
          } else {
            cleverAuthentication.authenticate(function (authCallback, authCode, callback, isToken) {
              return registerCleverUser(authCallback, postData, authCode, isToken);
            }, null);
          }
        } else {
          registerGoogleUser(postData);
        }
      } else {
        _.forOwn($scope.formData[formName].$error, function(value, key) {
          _.each(value, function(element) {
            element.$setDirty();
          });
        });
      }
    }

    $scope.registerStudent = function () {
      var postData = {
        email: $scope.formData.student.email,
        firstName: $scope.formData.student.firstName,
        invitationCode: $scope.formData.student.invitationCode,
        lastName: $scope.formData.student.lastName,
        referral: $scope.referral,
        type: 103,
        username: $scope.formData.student.username
      };

      register('registerStudentForm', postData);
    };

    $scope.registerTeacher = function() {
      var postData = {
        email: $scope.formData.teacher.email,
        firstName: $scope.formData.teacher.firstName,
        hearFrom: $scope.formData.teacher.hearFrom,
        lastName: $scope.formData.teacher.lastName,
        referral: $scope.referral,
        state: $scope.formData.teacher.state,
        type: $scope.formData.teacher.type,
        username: $scope.formData.teacher.username,
        userSchoolName: $scope.formData.teacher.userSchoolName,
        zipCode: $scope.formData.teacher.zipCode
      };

      if ($scope.formData.school) {
        postData.schoolSearchId = $scope.formData.school.id;
        postData.schoolSearchDistrictId = $scope.formData.school.districtId;
        postData.schoolSearchDistrict = $scope.formData.school.district;
        postData.schoolSearchType = $scope.formData.school.type;
        postData.userSchoolName = $scope.formData.school.name;
      } else {
        postData.schoolSearchId = null;
        postData.schoolSearchDistrict = null;
        postData.schoolSearchDistrictId = null;
        postData.schoolSearchType = null;
      }

      postData.grade = getSelectedGrades().join(',');

      register('registerTeacherForm', postData);
    };

    $scope.linkAccount = function (event) {
      if ($scope.isClever)
        $scope.linkClever();
      else
        $scope.linkGoogle();

      event.stopPropagation();
      event.preventDefault();

      return false;
    };

    function digest () {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    $scope.resetServerError = function () {
      $scope.formData.serverErrorMessage = '';
    };

    $scope.validateStudentUsername = function () {
      var username = $scope.formData.student.username;

      if (!username) {
        $scope.formData.isRegisteredStudentUsername = false;

        return;
      }

      var reg = /[^A-Za-z0-9 @\.\+]/;
      var hasNonAlphanumeric = reg.test(username);

      username = encodeURIComponent(username);

      if (hasNonAlphanumeric) {
        $scope.formData.isRegisteredStudentUsername = false;

        digest();
      } else {
        $scope.formData.isRegisteredStudentUsername = true;
      }

      $scope.resetServerError();
    };

    $scope.validateStudentUsername();

    $scope.validateTeacherUsername = function () {
      var username = $scope.formData.teacher.username;

      if (!username) {
        $scope.formData.isRegisteredTeacherUsername = false;

        return;
      }

      var reg = /[^A-Za-z0-9 @\.\+]/;
      var hasNonAlphanumeric = reg.test(username);

      username = encodeURIComponent(username);

      if (hasNonAlphanumeric) {
        $scope.formData.isRegisteredTeacherUsername = false;

        digest();
      } else {
        $scope.formData.isRegisteredTeacherUsername = true;
      }

      $scope.resetServerError();
    };

    $scope.validateTeacherUsername();

    $scope.disablePassword = true;

    $scope.isValid = function () {
      if (!$scope.viewModel.isTermsOfServiceChecked)
        return false;

      if (SSORegisterDirective.selectedView.tag === 'teacher-registration') {
        if (!$scope.formData.registerTeacherForm)
          return false;

        if ($scope.formData.registerTeacherForm.$invalid)
          return false;

        if ($scope.getSelectedGrades().length === 0)
          return false;

        if ($scope.viewModel.isUS)
          return $scope.formData.school;
        else
          return $scope.formData.teacher.userSchoolName;
      } else {
        if (!$scope.formData.registerStudentForm)
          return false;

        return !$scope.formData.registerStudentForm.$invalid;
      }
    };

    $scope.getSelectedGrades = function () {
      return getSelectedGrades().join(', ');
    };

    var _lastSchoolZipCode, _lastSchoolState;

    function reloadSchools () {
      var userObj;

      if ($scope.type === 'student')
        userObj = $scope.formData.student;
      else
        userObj = $scope.formData.teacher;

      if (!userObj.state || !userObj.zipCode)
        return;

      if (userObj.state === _lastSchoolState && userObj.zipCode === _lastSchoolZipCode)
        return;

      resetSchools();

      _lastSchoolZipCode = userObj.zipCode;
      _lastSchoolState = userObj.state;

      $scope.loadSchools(userObj.state, userObj.zipCode).then(function (results) {
        results.push({
          id: null,
          name: 'Other',
          district: null,
          type: null
        });

        $scope.viewModel.schools = results;
      });
    }

    function resetSchools () {
      $scope.viewModel.schools = null;
      $scope.formData.school = null;
    }

    $scope.onZipBlur = function () {
      reloadSchools();
    };

    $scope.onStateChange = function () {
      $scope.formData.teacher.state = $scope.viewModel.state.abbreviation;

      $scope.resetServerError();

      reloadSchools();
    };

    $scope.onStateBlur = function () {
      reloadSchools();
    };

    $scope.onSchoolChange = function () {
      $scope.formData.teacher.userSchoolName = $scope.formData.school.name;

      $scope.resetServerError();
    };

    function isEmailValid (email) {
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    }

    var _hasEmailChanged;

    $scope.isEmailValid = function () {
      return !_hasEmailChanged || isEmailValid($scope.formData.teacher.email);
    };

    $scope.onEmailBlur = function () {
      _hasEmailChanged = true;

      $scope.resetServerError();
    };

    var _lastUserNameChecked;

    function isUserNameValid (username) {
      if (!username) {
      } else if (/[^A-Za-z0-9 ]/.test(username)) {
      } else {
        return true;
      }

      return false;
    }

    $scope.checkUserName = function (username) {
      if (username && username !== _lastUserNameChecked && !isUserNameValid(username)) {
        _lastUserNameChecked = username;

        $translate('SORRY_A_USERNAME_CAN_ONLY_CONTAIN_NUMBERS_AND_LETTERS').then(function (msg) {
          alert(msg);
        });
      }
    };

    $scope.onIsUSChange = function () {
      if (!$scope.viewModel.isUS) {
        $scope.formData.school = null;
        $scope.formData.teacher.schoolSearchId = null;
        $scope.formData.teacher.schoolSearchDistrict = null;
        $scope.formData.teacher.schoolSearchDistrictId = null;
        $scope.formData.teacher.schoolSearchType = null;
      }
    };

    $scope.logIn = logIn;

    translate('TERMS_OF_SERVICE_HTML', $translate, $rootScope, $scope, function (translation) {
      $scope.viewModel.termsOfServiceHtml = translation;
    });
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    replace: true,
    scope: {
      cancel: '=',
      cleverAuthenticate: '=',
      cleverClientId: '=',
      cleverRedirectUrl: '=',
      code: '=',
      createStarterAssignments: '=',
      email: '=',
      firstName: '=',
      googleAuthenticate: '=',
      googleLogInSuccess: '=',
      idToken: '=',
      isMobile: '=',
      lastName: '=',
      linkClever: '=',
      linkGoogle: '=',
      loadSchools: '=',
      logInSuccess: '=',
      logoPath: '@',
      name: '=',
      productName: '=',
      referral: '=',
      register: '=',
      schoolName: '=',
      service: '=',
      token: '=',
      type: '=',
      username: '='
    }
  };
}

module.directive('ssoregister', SSORegisterDirective);

module.directive('ssoregistration', function () {
  var views = [];

  return {
    restrict: 'E',
    template: '<div class="button-container" ng-if="!type"><button tabindex="0" ng-class="isViewSelected(v)" ng-repeat="v in views" ng-click="switchView(v)">{{v.name}}</button></div>' +
    '<section id="registration" ng-include="view"></section>',
    controller: function ($scope) {
      "ngInject";

      if ($scope.type == 0)
        $scope.type = null;

      if (!$scope.type || $scope.type.toLowerCase() == 'student') {
        views.push({
          tag: 'student-registration',
          name: 'I am a student',
          template: 'registerStudent.partial.html'
        });
      }

      if (!$scope.type || $scope.type.toLowerCase() != 'student') {
        views.push({
          tag: 'teacher-registration',
          name: 'I am an educator',
          template: 'registerTeacher.partial.html'
        });
      }

      $scope.views = views;

      $scope.switchView = function (view) {
        $scope.view = view.template;
        $scope.selectedView = view;

        SSORegisterDirective.selectedView = view;

        bindLinks();
      };

      if (views)
        $scope.switchView(views[0]);

      if (!$scope.type) {
        $scope.isViewSelected = function (view) {
          return view === $scope.selectedView ? 'selected' : undefined;
        };
      }

      $scope.presentModalIFrame = function (url, $event) {
        var container = jQuery('<div>');
        container.css('background-color', 'rgba(0, 0, 0, .5)');
        container.css('position', 'absolute');
        container.css('left', 0);
        container.css('top', 0);
        container.css('width', '100%');
        container.css('height', '100%');

        var frameContainer = jQuery('<div>');
        frameContainer.css('position', 'absolute');
        frameContainer.css('left', '50%');
        frameContainer.css('top', '50%');
        frameContainer.css('width', '75%');
        frameContainer.css('height', '75%');
        frameContainer.css('transform', 'translatex(-50%) translatey(-50%)');
        frameContainer.css('-webkit-transform', 'translatex(-50%) translatey(-50%)');
        frameContainer.css('overflow', 'auto');
        container.append(frameContainer);

        var frame = jQuery('<iframe>');
        frame.attr('src', url);
        frame.css('width', '100%');
        frame.css('height', '100%');
        frame.css('background-color', 'white');
        frame.css('overflow', 'auto');
        frameContainer.append(frame);

        var closeButton = jQuery('<button>');
        closeButton.attr('tabindex', 0);
        closeButton.text('X');
        closeButton.css('text-indent', '-99em');
        closeButton.css('overflow', 'hidden');
        closeButton.css('background-image', 'url(/images/Close_btn.png)');
        closeButton.css('width', '31px');
        closeButton.css('height', '32px');
        closeButton.css('position', 'absolute');
        closeButton.css('right', '20px');
        closeButton.css('top', '20px');
        closeButton.click(function () {
          container.remove();
        });
        frameContainer.append(closeButton);

        jQuery('body:first').append(container);

        $event.stopPropagation();
        $event.preventDefault();
        $event.returnValue = false;

        return false;
      };

      $scope.openTermsOfService = function ($event) {
        $scope.presentModalIFrame('https://file.cuethink.com/documents/TermsofUseCueThink.htm', $event);
      };

      $scope.openPrivacyPolicy = function ($event) {
        $scope.presentModalIFrame('https://file.cuethink.com/documents/PrivacyPolicyCueThink.htm', $event);
      };

      function bindEvent (el, event, handler) {
        el = $(el);
        el.on(event, handler);
        el.on('remove', function () {
          el.off(event, handler);
        });
      }

      function bindLinks () {
        $scope.$$postDigest(function () {
          bindEvent($('.terms-of-service-link').first(), 'click', function (event) {
            $scope.openTermsOfService(event);
          });

          bindEvent($('.privacy-policy-link').first(), 'click', function (event) {
            $scope.openPrivacyPolicy(event);
          });
        });
      }
    }
  };
});
