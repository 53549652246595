<template>
  <div class="learning-hub-section">
    <div
      class="header"
      :style="{ 'background-color': section.foregroundColor, 'background-image': 'url(' + section.backgroundImage + ')' }">
      <div class="nav-buttons">
        <a
          class="back-btn"
          tabindex="0"
          @click.stop.prevent="onBackButtonClick">
          {{ 'BACK' | translate }}
        </a>

        <a
          class="next-btn"
          tabindex="0"
          @click.stop.prevent="onNextButtonClick">
          {{ 'NEXT' | translate }}
        </a>
      </div>

      <h1 class="title">{{ section.title | translate }}</h1>

      <p class="description">{{ section.description | translate }}</p>
    </div>

    <div class="body">
      <div
        v-for="item in section.items"
        class="item"
        :style="{ 'background-color': section.backgroundColor, 'border-color': section.foregroundColor }">
        <div class="content">
          <img
            :alt="translate(item.title)"
            :src="item.icon"
          />

          <label>
            {{ item.title | translate }}
          </label>
        </div>

        <div class="buttons">
          <button
            v-for="button in item.buttons"
            tabindex="0"
            :class="{ 'instructions-btn': button.title !== 'VIDEO', 'video-btn': button.title === 'VIDEO' }"
            :style="{ 'background-color': section.foregroundColor }"
            @click="onItemButtonClick(item, button)">
            <span class="title">
              {{ button.title | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <video-pop-up
      v-if="selectedItem && selectedButton"
      :title="selectedItem.title"
      :url="selectedButton.url"
      @close="onVideoPopUpClose">
    </video-pop-up>
  </div>
</template>

<script>
import translate from '../common/filters/translate.filter';
import downloadIcon from './images/DownloadIcon.svg';
import videoIcon from './images/VideoIcon.svg';
import VideoPopUp from './video-pop-up';

export default {
  components: {
    VideoPopUp
  },

  filters: {
    translate
  },

  props: {
    section: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      downloadIcon,
      selectedButton: null,
      selectedItem: null,
      translate,
      videoIcon
    };
  },

  methods: {
    onBackButtonClick() {
      this.$emit('back');
    },

    onItemButtonClick(item, button) {
      if (button.title === 'VIDEO') {
        this.selectedItem = item;
        this.selectedButton = button;
      } else {
        this.selectedItem = null;
        this.selectedButton = null;

        window.open(button.url, '_blank');
      }
    },

    onNextButtonClick() {
      this.$emit('next');
    },

    onVideoPopUpClose() {
      this.selectedItem = null;
      this.selectedButton = null;
    }
  }
}
</script>

<style scoped lang="scss">
  .learning-hub-section {
    .header {
      padding: 30px 40px;
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 250px;
      min-height: 185px;

      .nav-buttons {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        justify-content: space-between;
        -webkit-justify-content: space-between;

        a {
          width: auto;
          line-height: 18px;
        }

        .back-btn, .next-btn {
          border-bottom: 1px solid #363845;
          color: #363845;

          &:hover {
            text-decoration: none;
          }
        }

        .back-btn::before, .next-btn::after {
          background-repeat: no-repeat;
          background-image: url(/images/Arrow.svg);
          background-size: 10px 10px;
          width: 10px;
          height: 10px;
          display: inline-block;
          content: '';
        }

          .back-btn::before {
            float: left;
            margin-right: 4px;
            position: relative;
            top: 4px;
          }

          .next-btn::after {
            transform: rotate(180deg);
            margin-left: 4px;
          }
      }

      .title {
        color: #363845;
        margin-top: 30px;
        margin-bottom: 8px;
      }
    }

    .body {
      padding: 100px 30px;
      flex-wrap: wrap;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      -webkit-flex-wrap: wrap;
      justify-content: center;
      -webkit-justify-content: center;

      .item {
        padding: 20px;
        border-radius: 8px;
        border-width: 2px;
        border-style: solid;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        width: 300px;
        height: 300px;
        margin-right: 20px;
        align-items: stretch;
        -webkit-align-items: stretch;
        margin-bottom: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);

        &:hover {
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
        }

        .content {
          flex-grow: 1;
          -webkit-flex-grow: 1;
          display: flex;
          display: -webkit-flex;
          flex-direction: column;
          -webkit-flex-direction: column;
          justify-content: center;
          -webkit-justify-content: center;
          align-items: center;
          -webkit-align-items: center;

          img {
            width: 50px;
            height: 50px;
            margin-bottom: 15px;
          }

          label {
            text-align: center;
          }
        }

        .buttons {
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          -webkit-flex-direction: row;

          button {
            flex-grow: 1;
            -webkit-flex-grow: 1;
            padding: 10px;
            margin-right: 8px;
            color: #363845;
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: center;

            &:last-child {
              margin-right: 0;
            }

            .title {
              background-repeat: no-repeat;
              background-position: center left;
              padding-left: 20px;
              background-size: 14px 14px;
            }

            &.instructions-btn .title {
              background-image: url(/images/DownloadIcon.svg);
            }

            &.video-btn .title {
              background-image: url(/images/VideoIcon.svg);
            }
          }
        }
      }
    }
  }
</style>