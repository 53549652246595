import 'src/components/shared/user_data';
import 'src/components/shared/teacher_data';
import 'core/login/register/registrationDirective';
import CueThinkLoginController from '../loginController';

function RegisterController ($scope, $state, UserManager, CueThinkConfig, TeacherManager, $stateParams) {
    "ngInject";

    var userManager = UserManager;

    $scope.name = CueThinkConfig.name;
    $scope.register = userManager.registerUser;

    $scope.authenticateGoogle = function (user, isMobile, callback) {
      userManager.oAuthLogIn(user, isMobile, $scope.referral, callback);
    };

    $scope.logIn = userManager.logIn;

    $scope.cleverAuthenticate = function () {
      var args = [];

      for (var i = 0; i < arguments.length; i++)
        args.push(arguments[i]);

      args.push($scope.referral);

      return userManager.authenticateClever.apply(null, args);
    };

    $scope.googleClientID = CueThinkConfig.googleClientId;
    $scope.cleverRedirectURL = CueThinkConfig.cleverRedirectURL;
    $scope.cleverClientID = CueThinkConfig.cleverClientID;
    $scope.createStarterAssignments = TeacherManager.createStarterAssignments;
    $scope.type = $stateParams.type;
    $scope.loadSchools = userManager.loadNearbySchools;
    $scope.referral = $stateParams.ref;

    $scope.logInSuccess = function (user) {
      UserManager.setNotIdleFlag().then(function () {
        if (user.type == 'Student')
          CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);
        else
          $state.go('dashboard', { welcome: true });
      });
    };

    $scope.cleverRegister = function (user) {
      var params = {
        email: user.email,
        first_name: user.firstName,
        grade: user.grade,
        is_mobile: user.isMobile,
        last_name: user.lastName,
        ref: $scope.referral,
        school: user.schoolName,
        token: user.token,
        type: user.type,
      };

      $state.go('cleversignup', params);
    };

    $scope.googleRegister = function (user, isMobile) { // TODO: BEAC-3434: remember register page tab
      var params = {
        email: user.email,
        name: user.name,
        id_token: user.token,
        is_mobile: isMobile,
        ref: $scope.referral
      };
      $state.go('googlesignup', params);
    };

    $scope.cancel = function () {
      $state.go('login', { ref: $stateParams.ref });
    };
}

export default RegisterController;