import './form.css';

export default function (module) {
    module.directive('uiRequired', ['$compile', function ($compile) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function ($scope, $elm, $attrs, ngModelController) {
                function clearErrors() {
                    ngModelController.$setValidity("required", true);

                    if (ngModelController.$valid)
                        $attrs.$set('popover', '');
                }

                function validate(value) {
                    var isValid = false;

                    if (typeof value === 'number')
                        isValid = !isNaN(value);
                    else if (typeof value === 'string')
                        isValid = !value ? false : value.length > 0;

                    if (isValid)
                        ngModelController.$setValidity("required", true);
                    else
                        ngModelController.$setValidity("required", false);

                    if (ngModelController.$dirty)
                        if (!isValid)
                            $attrs.$set('popover', 'Required');

                    if (ngModelController.$valid)
                        $attrs.$set('popover', '');
                }

                function startValidation() {
                    var validationEnabled = $attrs.uiRequired !== 'false';

                    if (validationEnabled)
                        validate(ngModelController.$viewValue);
                    else
                        clearErrors();
                }

                $scope.$watch($attrs.ngModel, function () {
                    startValidation();
                });

                $attrs.$observe('uiRequired', function () {
                    startValidation();
                });
            }
        };
    }]);
};
