import 'core/viewThinklet/viewThinkletDirective';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/thinkletloader';
import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'core/tutorial/tutorial';
import 'src/app/navigation/teachernavbarController';
require('src/components/shared/view_drawer.partial.html');
import 'src/app/urls';
import 'angular-translate';

function RosterViewController ($scope, $stateParams, $state, ThinkletManager, ThinkletLoader, UserManager, $controller, TeacherManager, $q, CueThinkConfig) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });

    $scope.isCueTeachEnabled = $scope.user.cueteachEnabled;

    $scope.logIn = function () {
        $state.go('login');
    };

    $scope.register = function () {
        $state.go('registerchoice');
    };

    $scope.teacherManager = TeacherManager;
    $scope.thinkletId = parseInt($stateParams.id);
    $scope.thinkletManager = ThinkletManager;
    $scope.user = UserManager.getUser();
    $scope.userManager = UserManager;

    $scope.viewModel = {
        defaultSentenceStarters: CueThinkConfig.defaultSettings.viewingThinklets.annotations.sentenceStarters.slice(0)
    };

    $scope.$on('back', () => {
        $state.go('classes');
    });

    $scope.$on('view-thinklet', (e, thinkletId) => {
        $state.go('view-teacherdash', { id: thinkletId });
    });
}

export default RosterViewController;
