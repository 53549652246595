import RegularPolygonTool from './regular-polygon-tool';
import { inherit } from './base-tool';

export default function OctagonTool (whiteboard) {
  var me = this;

  RegularPolygonTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, color) {
      this.id = whiteboard.getNextId();
      this.type = OctagonTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  this.getSideCount = function () {
    return 8;
  };

  this.getAngleOffset = function () {
    return -Math.PI / this.getSideCount();
  };
}

inherit(OctagonTool, RegularPolygonTool);

OctagonTool.type = 'octagon';
