export function SpeechToText () {
  var _callback, _enabled;

  var func;

  if (typeof SpeechRecognition !== 'undefined')
    func = SpeechRecognition;
  else if (typeof webkitSpeechRecognition !== 'undefined')
    func = webkitSpeechRecognition;

  if (func) {
    var speechRecognition = new func();
    speechRecognition.lang = 'en-US';
    speechRecognition.continuous = true;
    speechRecognition.interimResults = true;

    speechRecognition.onresult = function (event) {
      if (_callback) {
        var interim_transcript = '';
        var final_transcript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }

        if (final_transcript)
          _callback(final_transcript);
      }
    };

    speechRecognition.onerror = function (event) {
      console.log(event);
    };

    speechRecognition.onend = function (event) {
      if (_enabled)
        speechRecognition.start();
    };
  }

  this.start = function () {
    if (speechRecognition) {
      speechRecognition.start();

      _enabled = true;
    }
  };

  this.stop = function () {
    if (speechRecognition) {
      _enabled = false;

      speechRecognition.stop();
    }
  };

  this.setCallback = function (callback) {
    _callback = callback;
  };

  this.isEnabled = function () {
    return _enabled;
  };

  return this;
}
