import './cuethink';
import 'config';
import './user_data';
import _ from 'underscore';
import 'angular-translate';
import Vue from 'vue';
import permissionsManager, { Permissions } from 'core/permissions/permissions';
import CueThinkConfig from '../../app/config.provider';

angular.module('thinkletManager', ['cuethink', 'CueThinkConfig', 'userManager']).factory('ThinkletManager', ['CueThink', 'CueThinkConfig', 'UserManager', '$q', '$translate', function (cuethink, config, userManager, $q, $translate) {
  "ngInject";

  ThinkletManager.sharedInstance = new ThinkletManager(cuethink, config, userManager, $q, $translate);

  return ThinkletManager.sharedInstance;
}]);

var CREATE_STEPS = {
  Understand: 'understand',
  Plan: 'plan',
  Solve: 'solve',
  Review: 'review'
};

export default function ThinkletManager (cuethink, config, userManager, $q, $translate) {
  var _me = this;

  _me.loadProblems = function (callback) {
    cuethink.get(config.problemsURL, callback);
  };

  _me.loadProblemStats = function (id) {
    if (userManager.getUser().state)
      return _me.loadStandardTypeProblemStats(id);
    else
      return cuethink.get(config.problemStatisticsURL.replace('{0}', id)).then(function (result) {
        if (result.ccss)
          result.standards = result.ccss.split(',');

        result.domain = result.topicName;

        return result;
      });
  };

  _me.loadStandardTypeProblemStats = function (id) {
    return cuethink.get(config.problemStandardTypeStatisticsURL.replace('{0}', id).replace('{1}', userManager.getUser().state)).then(function (stats) {
      stats.standards = stats.standards.split(',');

      return stats;
    });
  };

  _me.loadUserThinklets = function (id) {
    return cuethink.get(config.userThinkletsURL.replace('{0}', id)).then(function (thinklets) {
      processThinklets(thinklets);

      return thinklets;
    });
  };

  _me.loadUserThinkletsAll = function (id, callback) {
    cuethink.get(config.userThinkletsAllURL.replace('{0}', id), function (thinklets) {
      processThinklets(thinklets);

      callback(thinklets);
    });
  };

  _me.loadStudentsThinklets = function (startDate, classIds, userIds, assignmentIds, labels, completedOnly, sort, sortAscending, start, limit, callback, explore, plan, solve, review, videoGallery) {
    var data = {};

    if (startDate) {
      var month = startDate.getMonth() + 1;

      if (month < 10)
        month = '0' + month;

      var day = startDate.getDate();

      if (day < 10)
        day = '0' + day;

      startDate = startDate.getFullYear() + '-' + month + '-' + day;

      data.createdAtStart = data.completedAtStart = startDate;
    }

    if (classIds && classIds.length > 0)
      data.classIds = classIds.join(',');

    if (userIds && userIds.length > 0)
      data.userIds = userIds.join(',');

    if (assignmentIds && assignmentIds.length > 0)
      data.assignmentIds = assignmentIds.join(',');

    if (labels && labels.length > 0)
      data.labels = labels.join(',');

    if (completedOnly)
      data.status = 'completed';

    // orderBy=time&orderType=desc&start=0&limit=10
    data.orderBy = sort;
    data.orderType = sortAscending ? 'asc' : 'desc';

    if (typeof start == 'number')
      data.start = start;

    if (limit)
      data.limit = limit;

    if (explore)
      data.explore = 1;

    if (plan)
      data.plan = 1;

    if (solve)
      data.solve = 1;

    if (review)
      data.review = 1;

    if (videoGallery)
      data.videoGallery = 1;

    return cuethink.getjson(config.studentsThinkletsURL, data, function (thinklets) {
      processThinklets(thinklets);

      callback(thinklets);
    });
  };

  _me.loadRecentThinklets = function (start, limit, students, classes, problems, labels, strategies, draftsOnly, userName, callback) {
    var url;

    url = config.recentThinkletsURL.replace('{0}', start).replace('{1}', limit);

    if (students && students.length > 0) {
      var studentNames = [];

      students.forEach(function (student) {
        studentNames.push(encodeURIComponent(student.firstName + ' ' + student.lastName));
      });

      url += '&studentNames=' + studentNames.join(',');
    }

    if (classes && classes.length > 0)
      url += '&classIds=' + classes.map(function (cls) { return cls.id; }).join(',');

    if (problems && problems.length > 0)
      url += '&problemNames=' + problems.map(function (problem) { return encodeURIComponent(problem.name ? problem.name : problem.title); }).join(',');

    if (labels && labels.length > 0)
      url += '&labels=' + labels.map(encodeURIComponent).join(',');

    if (strategies && strategies.length > 0)
      url += '&strats=' + encodeURIComponent(strategies[0].name);

    if (draftsOnly)
      url += '&status=draft';

    if (userName)
      url += '&username=' + encodeURIComponent(userName);

    return cuethink.get(url, function (thinklets) {
      processThinklets(thinklets);

      if (callback)
        callback(thinklets);

      return thinklets;
    });
  };

  _me.loadGalleryFilterList = function (callback) {
    cuethink.get(config.galleryFilterListURL, callback);
  };

  _me.deleteMyProblemBankProblems = function (data, callback) {
    var ids = [];

    for (var i = 0; i < data.length; i++)
      ids.push(data[i].id);

    data = angular.toJson(ids);

    cuethink.deletejson(config.teacherProblemsURL, data, callback);
  };

  function concatenateArrays (a, b, callback, filter) {
    for (var i = 0; i < b.length; i++) {
      var value = b[i];

      if (callback)
        value = callback(value);

      if (filter)
        if (!filter(value))
          continue;

      a.push(b[i]);
    }

    return a;
  }

  function trim (value) {
    return value.trim();
  }

  _me.processProblem = function (problem) {
    var deferred = $q.defer();

    if (!problem) {
      deferred.reject();

      return deferred.promise;
    }

    if (problem.problem) {
      problem = Object.assign({}, problem.problem, {
        translations: problem.problemTranslations
      });

      problem.translations.forEach(function (translation) {
        translation.imgList = translation.questionImage;
        translation.answerImgList = translation.answerImage;
      });
    }

    var checklist = [], keys = ['genericCheckArray', 'genericCheckList', 'specificCheckArray', 'specificCheckList'];

    function filter (value) {
      return value.length > 0;
    }

    var i;

    for (i = 0; i < keys.length; i++)
      if (problem[keys[i]])
        concatenateArrays(checklist, problem[keys[i]], trim, filter);

    problem.mathChecklist = checklist;

    checklist = [];

    for (i = 0; i < problem.recordingCheckList.length; i++) {
      var value = problem.recordingCheckList[i];

      value = value.trim();

      if (!filter(value))
        continue;

      checklist.push(value);
    }

    problem.recordingChecklist = checklist;

    if (problem.imgList) {
      problem.imgList = problem.imgList.map(function (path) {
        if (!path)
          return path;

        return _me.getMediaURLFromPath(path);
      });
    }

    if (problem.answerImgList) {
      problem.answerImgList = problem.answerImgList.map(function (path) {
        if (!path)
          return path;

        return _me.getMediaURLFromPath(path);
      });
    }

    if (problem.translations) {
      problem.translations.forEach(function (p) {
        if (p.imgList) {
          p.imgList = p.imgList.map(function (path) {
            if (!path)
              return path;

            return _me.getMediaURLFromPath(path);
          });
        }

        if (p.answerImgList) {
          p.answerImgList = p.answerImgList.map(function (path) {
            if (!path)
              return path;

            return _me.getMediaURLFromPath(path);
          });
        }
      });
    }

    if (problem.strategyCheckList && problem.strategyCheckList.length > 0) {
      deferred.resolve(problem);
    } else {
      _me.loadStrategies(function (strategies) {
        problem.strategyCheckList = [];

        for (var i = 0; i < strategies.length; i++)
          problem.strategyCheckList.push(strategies[i].name);

        deferred.resolve(problem);
      });
    }

    return deferred.promise;
  };

  _me.loadProblem = function (id) {
    var url = config.problemURL.replace('{0}', id);

    url += '?noCheck=1';

    return cuethink.get(url).then(function (response) {
      return _me.processProblem(response);
    });
  };

  _me.loadStateProblem = function (id, state) {
    return cuethink.get(config.stateProblemURL.replace('{0}', id).replace('{1}', state)).then(function (problem) {
      return _me.processProblem(problem);
    });
  };

  function handleThinkletResponse (thinklet, retrieveFlaggedLiked, retrieveProblem, noCheck, callback) {
    var retrieveFlaggedLikedLoaded, loadedProblem;

    processThinklet(thinklet);

    if (retrieveFlaggedLiked) {
      thinklet.views++;

      cuethink.post(config.incrementViewsURL.replace('{0}', thinklet.id));

      cuethink.get(config.appraiseURL.replace('{0}', thinklet.id), function (data) {
        thinklet.isFlagged = data.flagged;
        thinklet.isLiked = data.liked;

        retrieveFlaggedLikedLoaded = true;

        if (loadedProblem || !thinklet.problem || !retrieveProblem)
          if (callback)
            callback(thinklet);
      });
    }

    if (retrieveProblem && thinklet.problem) {
      _me.loadProblem(thinklet.problem.id).then(function (problem) {
        thinklet.problem = problem;

        if (thinklet.question != thinklet.problem.question)
          thinklet.annotatedQuestion = null;

        loadedProblem = true;

        var strategies = thinklet.complexItems.strategies;

        if (!Array.isArray(strategies))
          strategies = thinklet.complexItems.strategies = [];

        if (strategies.length === 0) {
          var i;

          if (problem.strategies[100]) {
            for (i = 0; i < problem.strategies[100].length; i++)
              strategies.push({ status: 'false', name: problem.strategies[100][i].description });
          } else if (problem.strategyCheckList) {
            for (i = 0; i < problem.strategyCheckList.length; i++)
              strategies.push({ status: 'false', name: problem.strategyCheckList[i] });
          }
        }

        if ((!retrieveFlaggedLiked || retrieveFlaggedLikedLoaded) && callback)
          callback(thinklet);
      });
    } else if (!retrieveFlaggedLiked) {
      if (callback)
        callback(thinklet);
    }
  }

  function processThinklet (thinklet) {
    var i;

    if (thinklet.creation_timestamp)
      thinklet.creationTimestamp = thinklet.creation_timestamp;

    if (thinklet.completion_timestamp)
      thinklet.completionTimestamp = new Date(thinklet.completion_timestamp);

    if (thinklet.assignment_end_date)
      thinklet.dueDate = new Date(thinklet.assignment_end_date);

    if (thinklet.creation_timestamp)
      thinklet.creationTimestamp = thinklet.creation_timestamp;

    if (thinklet.assignment_name)
      thinklet.assignmentName = thinklet.assignment_name;

    if (thinklet.assignment_id)
      thinklet.assignmentId = thinklet.assignment_id;

    if (thinklet.annotation_count)
      thinklet.annotationCount = thinklet.annotation_count;

    if (thinklet.dfs_thumbnail_url)
      thinklet.thumbnailURL = _me.getMediaURLFromPath(thinklet.dfs_thumbnail_url);

    if (thinklet.explore_screenshot)
      thinklet.explore_screenshot = _me.getMediaURLFromPath(thinklet.explore_screenshot);

    if (thinklet.plan_screenshot)
      thinklet.plan_screenshot = _me.getMediaURLFromPath(thinklet.plan_screenshot);

    if (thinklet.solve_screenshot)
      thinklet.solve_screenshot = _me.getMediaURLFromPath(thinklet.solve_screenshot);

    if (thinklet.review_screenshot)
      thinklet.review_screenshot = _me.getMediaURLFromPath(thinklet.review_screenshot);

    if (thinklet.dfs_playback_url)
      thinklet.videoURL = _me.getMediaURLFromPath(thinklet.dfs_playback_url);
    else if (thinklet.dfs_video_url)
      thinklet.videoURL = _me.getMediaURLFromPath(thinklet.dfs_video_url);

    if (thinklet.static_data && thinklet.static_data.length > 0)
      thinklet.whiteboardURL = _me.getMediaURLFromPath(thinklet.static_data[thinklet.static_data.length - 1]);
    else if (thinklet.dfs_playback_url)
      thinklet.whiteboardURL = thinklet.videoURL;

    if (thinklet.dfs_audio_url)
      thinklet.audioURL = _me.getMediaURLFromPath(thinklet.dfs_audio_url);
    else
      thinklet.audioURL = null;

    thinklet.scrapPaperURLs = [];

    if (thinklet.scrap_paper_url) {
      for (i = 0; i < thinklet.scrap_paper_url.length; i++) {
        var item = thinklet.scrap_paper_url[i];

        thinklet.scrapPaperURLs.push(_me.getMediaURLFromPath(item.dfsMediaFile));
      }
    }

    if (thinklet.items) {
      var items, key, keys;

      if (thinklet.items.known) {
        items = [];

        keys = Object.keys(thinklet.items.known);

        for (i = 0; i < keys.length; i++) {
          key = keys[i];

          var known = thinklet.items.known[key];

          if (known)
            items.push(known);
        }

        thinklet.items.known = items;
      }

      if (thinklet.items.wondering) {
        items = [];

        keys = Object.keys(thinklet.items.wondering);

        for (i = 0; i < keys.length; i++) {
          key = keys[i];

          var wondering = thinklet.items.wondering[key];

          if (wondering)
            items.push(wondering);
        }

        thinklet.items.wondering = items;
      }
    }

    if (thinklet.user) {
      if (thinklet.user.first_name)
        thinklet.user.firstName = thinklet.user.first_name;

      if (thinklet.user.last_name)
        thinklet.user.lastName = thinklet.user.last_name;
    } else {
      thinklet.user = {
        firstName: thinklet.firstName,
        lastName: thinklet.lastName
      };
    }

    if (thinklet.correct_answer)
      thinklet.correctAnswer = thinklet.correct_answer;

    if (thinklet.user_answer)
      thinklet.userAnswer = thinklet.user_answer;

    if (thinklet.solveScreenshot)
      thinklet.solveScreenshot.forEach(page => page.filePath = _me.getMediaURLFromPath(page.filePath));
  }

  var _lastLoadedThinklet;

  _me.loadThinklet = function (id, callback, retrieveFlaggedLiked, retrieveProblem, noCheck) {
    return cuethink.get(config.thinkletURL.replace('{0}', id), function (thinklet) {
      return new Promise(resolve => {
        handleThinkletResponse(thinklet, retrieveFlaggedLiked, retrieveProblem, noCheck, callback ? callback : result => {
          resolve(result);
        });

        _lastLoadedThinklet = thinklet;
      });
    });
  };

  _me.getLastLoadedThinklet = function () {
    return _lastLoadedThinklet;
  };

  _me.loadShared = function (shareCode, success) {
    return cuethink.get(config.playSharedURL.replace('{0}', shareCode), function (thinklet) {
      handleThinkletResponse(thinklet, false, false, false, success);

      /*if (thinklet.audio)
        thinklet.audioURL = config.mediaURL + "/" + thinklet.audio;

      if (thinklet.playback)
        thinklet.videoURL = config.mediaURL + "/" + thinklet.playback;

      thinklet.annotations = _.each(thinklet.annotations, function(value) {
        value.time = value.offset;

        if (typeof value.user !== 'object')
          value.user = {'username': value.username};
      });

      thinklet.user.firstName = thinklet.user.first_name;
      thinklet.user.lastName = thinklet.user.last_name;

      thinklet.creation_timestamp = thinklet.completedAt;
      thinklet.problem = {'question': thinklet.question};

      success(thinklet);*/

      return thinklet;
    });
  };

  function processThinklets (thinklets) {
    for (var i = 0; i < thinklets.length; i++) {
      var thinklet = thinklets[i];

      processThinklet(thinklet);
    }
  }

  _me.loadNotifications = function (callback, error) {
    return cuethink.getjson(config.notificationsURL, null, callback, error);
  };

  _me.loadNewNotifications = function (callback, error) {
    return cuethink.getjson(config.newNotificationsURL, null, callback, error);
  };

  function getFieldsForStep (thinklet, step) {
    var result;

    if (step == CREATE_STEPS.Understand) {
      result = { notice: thinklet.items ? thinklet.items.known : null, wonder: thinklet.items ? thinklet.items.wondering : null, estimate: thinklet.understand };
    } else if (step == CREATE_STEPS.Plan) {
      var checkedStrategies;

      if (thinklet.complexItems && thinklet.complexItems.strategies) {
        checkedStrategies = thinklet.complexItems.strategies.filter(function (strategy) {
          return strategy.status === 'true';
        }).length;
      } else {
        checkedStrategies = 0;
      }

      result = { strategies: checkedStrategies, plan: thinklet.plan };
    } else if (step == CREATE_STEPS.Solve) {
      result = { videoURL: thinklet.videoURL };
    } else if (step == CREATE_STEPS.Review) {
      var mathChecked, allMathChecked, recordingChecked, allRecordingChecked;

      if (thinklet.complexItems) {
        if (thinklet.complexItems.math_checklist) {
          mathChecked = thinklet.complexItems.math_checklist.filter(function (item) {
            return item.status === 'true';
          }).length;

          allMathChecked = mathChecked === thinklet.complexItems.math_checklist.length;
        } else {
          mathChecked = allMathChecked = false;
        }

        if (thinklet.complexItems.recording_checklist) {
          recordingChecked = thinklet.complexItems.recording_checklist.filter(function (item) {
            return item.status === 'true';
          }).length;

          allRecordingChecked = recordingChecked === thinklet.complexItems.recording_checklist.length;
        } else {
          recordingChecked = allRecordingChecked = false;
        }
      } else {
        mathChecked = recordingChecked = allMathChecked = allRecordingChecked = false;
      }

      result = { math_checked: mathChecked, all_math_checked: allMathChecked, recording_checked: recordingChecked, all_recording_checked: allRecordingChecked, answer: thinklet.answer ? thinklet.answer.answer : null };
    }

    return result;
  }

  function isThinkletInProgress (thinklet, step) {
    var fields = getFieldsForStep(thinklet, step);
    var result = false;
    var fieldMissing  = false;

    _.each(fields, function (value, key) {
      if (Array.isArray(value)) {
        if (value.length > 0)
          result = true;
        else
          fieldMissing = true;
      } else {
        if (value) {
          if (step === 'plan' && key === 'plan' && value.trim() === $translate.instant('THE_PROBLEM_IS_ASKING_ME_TO')) {
            fieldMissing = true;
          } else {
            result = true;
          }
        } else {
          fieldMissing = true;
        }
      }
    });

    if (result && !fieldMissing)
      result = false;

    return result;
  }

  function isThinkletComplete (thinklet, step) {
    var fields = getFieldsForStep(thinklet, step);
    var result = true;

    _.each(fields, function (value) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        result = false;

        return false;
      }
    });

    return result;
  }

  // BEAC-3678: calculate progress to take load off server
  function calculateThinkletProgress (thinklet) {
    var progress = {};
    var steps = Object.values(CREATE_STEPS);

    for (var i = 0; i < steps.length; i++) {
      var step = steps[i];
      var score;

      if (isThinkletInProgress(thinklet, step))
        score = 1;
      else if (isThinkletComplete(thinklet, step))
        score = 2;
      else
        score = 0;

      progress[step] = score;
    }

    return progress;
  }

  _me.saveThinklet = function (id, changes, thinklet, success, error) {
    var dict = {};

    if (changes.understand !== undefined || changes.plan !== undefined || changes.review !== undefined || changes.explore !== undefined) {
      dict.steps = {};

      if (changes.understand !== undefined) {
        dict.steps['1'] = {
          understand: changes.understand
        };
      }

      if (changes.explore !== undefined) {
        dict.steps['5'] = {
          explore: changes.explore
        };
      }

      if (changes.plan !== undefined) {
        dict.steps['2'] = {
          plan: changes.plan
        };
      }

      if (changes.review !== undefined) {
        dict.steps['4'] = {
          review: changes.review
        };
      }
    }

    if (changes.annotatedQuestion !== undefined)
      dict.description = { annotatedQuestion: changes.annotatedQuestion };

    if (changes.items !== undefined)
      dict.items = changes.items;

    if (changes.answer !== undefined)
      dict.answer = changes.answer;

    if (changes.complexItems !== undefined)
      dict.complexItems = changes.complexItems;

    var progress = calculateThinkletProgress(thinklet);
    dict.uScore = progress.understand;
    dict.pScore = progress.plan;
    dict.sScore = progress.solve;
    dict.rScore = progress.review;

    cuethink.putjson(config.saveThinkletURL.replace('{0}', id), dict, success, error);
  };

  _me.addComment = function (thinkletID, comment, success, error) {
    cuethink.postjson(config.addCommentURL.replace('{0}', thinkletID), { text: comment, type: 0 }, success, error);
  };

  function isDateAfterDate (a, b) {
    var aValue = a.getFullYear();
    var bValue = b.getFullYear();

    if (aValue > bValue)
      return true;
    else if (aValue < bValue)
      return false;

    aValue = a.getMonth();
    bValue = b.getMonth();

    if (aValue > bValue)
      return true;
    else if (aValue < bValue)
      return false;

    aValue = a.getDate();
    bValue = b.getDate();

    return aValue > bValue;
  }

  _me.loadAssignment = function (id) {
    return cuethink.getjson(config.assignmentURL.replace('{0}', id));
  };

  _me.fetchAssignments = function (cls) {
    return cuethink.getjson(config.assignmentsURL, cls ? { classId: cls.id } : null).then(function (assignments) {
      var results = [];
      var now = new Date();

      for (var i = 0; i < assignments.length; i++) {
        var assignment = assignments[i];

        if (!assignment.startDate)
          assignment.startDate = assignment.plan_start_date;

        assignment.startDate = new Date(assignment.startDate);

        if (isDateAfterDate(assignment.startDate, now))
          continue;

        if (!assignment.endDate)
          assignment.endDate = assignment.plan_end_date;

        assignment.endDate = new Date(assignment.endDate);

        if (!assignment.problemId)
          continue;

        if (!assignment.title)
          assignment.title = assignment.name;
        else
          assignment.name = assignment.title;

        results.push(assignment);
      }

      return results;
    });
  };

  _me.fetchProblemsForAssignment = function (id, callback, error) {
    cuethink.get(config.assignmentProblemsURL.replace('{0}', id), callback ? function (problems) {
      for (var i = 0; i < problems.length; i++) {
        if (!problems[i].name)
          problems[i].name = problems[i].problemTitle;

        if (!problems[i].id)
          problems[i].id = problems[i].problemId;
      }

      callback(problems);
    } : null, error);
  };

  _me.createThinklet = function (problem, assignment, success, error) {
    if (typeof assignment == 'number')
      assignment = { id: assignment };

    var thinklet = {
      title: problem.title,
      question: problem.question,
      topic: problem.topic,
      objective: problem.objective,
      questionSource: problem.questionSource,
      gradeBand: problem.gradeBand,
      assignmentId: assignment.id,
      assignmentGroupId: assignment.group_id
    };

    return cuethink.postjson(config.createThinkletURL.replace('{0}', problem.id), thinklet, function (thinklet) {
      var strategies = thinklet.complexItems.strategies;

      if (!Array.isArray(strategies))
        strategies = thinklet.complexItems.strategies = [];

      if (permissionsManager.getPermission(Permissions.StrategyCustomization)) {
        var i;

        if (problem.strategyCheckList) {
          for (i = 0; i < problem.strategyCheckList.length; i++)
            strategies.push({status: 'false', name: problem.strategyCheckList[i]});
        } else if (problem.strategies[100]) {
          for (i = 0; i < problem.strategies[100].length; i++)
            strategies.push({status: 'false', name: problem.strategies[100][i].description});
        }
      } else {
        CueThinkConfig.smartDesignSettings.creatingThinklets.plan.strategies.forEach(strategy => {
          if (strategy.enabled) {
            strategies.push({
              name: strategy.text,
              status: 'false'
            });
          }
        });
      }

      _me.saveThinklet(thinklet.id, { complexItems: thinklet.complexItems }, thinklet);

      if (success)
        success(thinklet);

      return thinklet;
    }, error);
  };

  _me.addAnnotation = function (thinklet, annotation, success, error) {
    var a = {
      id: annotation.id,
      offset: annotation.time,
      text: annotation.text,
      x: annotation.x,
      y: annotation.y,
      private: annotation.private
    };

    return cuethink.postjson(config.addAnnotationURL.replace('{0}', thinklet.id), a).then(annotation => {
      annotation.time = annotation.offset;

      if (!thinklet.annotations)
        Vue.set(thinklet, 'annotations', []);

      annotation.user = userManager.getUser();

      thinklet.annotations.push(annotation);

      if (success)
        success(annotation);

      return annotation;
    }, error);
  };

  _me.addAnnotationReply = function  (annotation, reply, text, isPrivate, success, error) {
    var annotationId = annotation.id ? annotation.id : annotation.annotationId;

    return cuethink.postjson(config.addAnnotationReplyURL.replace('{0}', annotationId), { text: text, private: isPrivate, replyId: reply ? reply.id : null }).then(result => {
      if (!annotation.replies)
        annotation.replies = [];

      annotation.replies.push({
        id: result,
        text: text,
        user: userManager.getUser(),
        annotationId: annotationId,
        replyId: reply ? reply.id : 0,
        private: isPrivate
      });

      if (success)
        success(result);

      return result;
    }, error);
  };

  _me.deleteAnnotationReply = function  (annotation, reply, success, error) {
    return cuethink.deletejson(config.deleteAnnotationReplyURL.replace('{0}', reply.id), null, success, error).then(function (result) {
      if (result)
        annotation.replies.splice(annotation.replies.indexOf(reply), 1);
    });
  };

  _me.editAnnotationReply = function  (annotation, success, error) {
    return cuethink.putjson(config.editAnnotationReplyURL.replace('{0}', annotation.id), { text: annotation.text, private: annotation.private }, success, error);
  };

  _me.flagAnnotation = function (annotation) {
    return cuethink.get(config.flagAnnotationURL.replace('{0}', annotation.annotationId)).then(function (result) {
      if (result)
        annotation.isFlagged = true;

      return result;
    });
  };

  _me.flagAnnotationReply = function (annotation) {
    return cuethink.post(config.flagAnnotationReplyURL.replace('{0}', annotation.id)).then(function (result) {
      if (result)
        annotation.flagged = true;

      return result;
    });
  };

  _me.unflagAnnotation = function (annotation) {
    return cuethink.get(config.unflagAnnotationURL.replace('{0}', annotation.annotationId)).then(function (result) {
      if (result)
        annotation.isFlagged = false;

      return result;
    });
  };

  _me.unflagAnnotationReply = function (annotation) {
    return cuethink.post(config.unflagAnnotationReplyURL.replace('{0}', annotation.id)).then(function (result) {
      if (result)
        annotation.flagged = false;

      return result;
    });
  };

  _me.likeAnnotation = function (annotation) {
    return cuethink.get(config.likeAnnotationURL.replace('{0}', annotation.annotationId)).then(function (result) {
      if (result)
        annotation.isLiked = true;

      return result;
    });
  };

  _me.unlikeAnnotation = function (annotation) {
    return cuethink.get(config.unlikeAnnotationURL.replace('{0}', annotation.annotationId)).then(function (result) {
      if (result)
        annotation.isLiked = false;

      return result;
    });
  };

  _me.likeAnnotationReply = function (annotation) {
    return cuethink.post(config.likeAnnotationReplyURL.replace('{0}', annotation.id)).then(function (result) {
      if (result)
        annotation.liked = true;

      return result;
    });
  };

  _me.unlikeAnnotationReply = function (annotation) {
    return cuethink.post(config.unlikeAnnotationReplyURL.replace('{0}', annotation.id)).then(function (result) {
      if (result)
        annotation.liked = false;

      return result;
    });
  };

  _me.deleteAnnotation = function (thinklet, annotation) {
    thinklet.annotations.splice(thinklet.annotations.indexOf(annotation), 1);

    return cuethink.delete(config.annotationURL.replace('{0}', annotation.id ? annotation.id : annotation.annotationId));
  };

  _me.flagThinklet = function (thinklet) {
    return cuethink.post(config.flagThinkletURL.replace('{0}', thinklet.id));

    thinklet.isFlagged = true;
  };

  _me.unflagThinklet = function (thinklet) {
    return cuethink.post(config.unflagThinkletURL.replace('{0}', thinklet.id));

    thinklet.isFlagged = false;
  };

  _me.likeThinklet = function (thinklet) {
    return cuethink.post(config.likeThinkletURL.replace('{0}', thinklet.id));

    thinklet.isLiked = true;
    thinklet.likes++;
  };

  _me.unlikeThinklet = function (thinklet) {
    return cuethink.post(config.unlikeThinkletURL.replace('{0}', thinklet.id));

    thinklet.isLiked = false;
    thinklet.likes--;
  };

  _me.createShareCode = function (thinklet, success) {
    return cuethink.get(config.createShareCodeURL.replace('{0}', thinklet.id), success);
  };

  _me.logThinkletEvent = function (name, thinklet, data) {
    var assignmentId, thinkletId, problemId;

    if (thinklet) {
      thinkletId = thinklet.id;

      if (thinklet.problem) {
        problemId = thinklet.problem.problemId ? thinklet.problem.problemId : thinklet.problem.id;

        if (thinklet.problem.assignment)
          assignmentId = thinklet.problem.assignment.id;
      }
    }

    cuethink.logEvent(name, assignmentId, problemId, thinkletId, data);
  };

  _me.publishThinklet = function  (thinklet, isPrivate, success, error) {
    var dict = {
      title: thinklet.title,
      question: thinklet.problem.question,
      questionSource: thinklet.problem.questionSource,
      objective: thinklet.problem.objective,
      topic: thinklet.problem.topic,
      gradeBand: thinklet.problem.gradeBand,
      keywords: '',
      public: thinklet.isPublic,
      sharedWithTeacher: thinklet.is_shared_with_teacher,
      classes: thinklet.classes
    };

    if (isPrivate)
      dict.publishToGallery = false;

    cuethink.postjson(config.publishURL.replace('{0}', thinklet.id), dict, success, error);
  };

  _me.unpublishThinklet = function (thinklet) {
    cuethink.get(config.unpublishThinkletURL.replace('{0}', thinklet.id), null, function () {
      thinklet.isPublic = thinklet.isComplete = false;
    });
  };

  _me.getMediaURLFromPath = function (path) {
    if (path.indexOf('http') === 0)
      return path;
    else
      return config.mediaURL + '/' + path;
  };

  _me.getPathFromMediaUrl = function (url) {
    return url.replace(config.mediaURL + '/', '');
  };

  _me.deleteThinklet = function (thinklet, success, error) {
    cuethink.delete(config.deleteThinkletURL.replace('{0}', thinklet.id), success, error);
  };

  var _cachedThinklets;

  function getCachedThinklets () {
    if (!_cachedThinklets) {
      if (typeof(Storage) === 'undefined') {
        _cachedThinklets = {};
      } else {
        var items = localStorage.getItem('thinklets');

        if (items)
          _cachedThinklets = JSON.parse(items);
        else
          _cachedThinklets = {};
      }
    }

    return _cachedThinklets;
  }

  _me.getCachedThinklet = function (id) {
    var thinklets = getCachedThinklets();

    return thinklets[id];
  };

  _me.cacheThinklet = function (thinklet) {
    var items = getCachedThinklets();

    items[thinklet.id] = thinklet;

    if (typeof(Storage) !== 'undefined')
      localStorage.setItem('thinklets', angular.toJson(items));
  };

  _me.removeCachedThinklet = function (thinklet) {
    var items = getCachedThinklets();

    if (items[thinklet.id])
      delete items[thinklet.id];

    if (typeof(Storage) !== 'undefined')
      localStorage.setItem('thinklets', angular.toJson(items));
  };

  _me.clearThinkletCache = function () {
    _cachedThinklets = {};

    if (typeof localStorage !== 'undefined')
      localStorage.removeItem('thinklets');
  };

  _me.saveAnnotation = function (annotation, success, error) {
    var id;

    if (annotation.annotationId)
      id = annotation.annotationId;
    else
      id = annotation.id;

    cuethink.putjson(config.saveAnnotationURL.replace('{0}', id), { text: annotation.text, private: annotation.private }, success, error);
  };

  _me.saveRubric = function (thinkletId, id, value, success, error) {
    return cuethink.postjson(config.rubricURL.replace('{0}', thinkletId), { 'id': id, optionId: value }, success, error);
  };

  _me.saveRubricCorrect = function (thinklet, value, success, error) {
    return cuethink.postjson(config.rubricURL.replace('{0}', thinklet.id), { 'type': 'answer', optionId: value }, success, error);
  };

  _me.resetRubric = function (thinklet) {
    if (!thinklet.rubric)
      return false;

    if (thinklet.rubric.understandList) {
      _.each(thinklet.rubric.understandList, function (response) {
        response.optionId = null;
      });
    }

    if (thinklet.rubric.planList) {
      _.each(thinklet.rubric.planList, function (response) {
        response.optionId = null;
      });
    }

    if (thinklet.rubric.solveList) {
      _.each(thinklet.rubric.solveList, function (response) {
        response.optionId = null;
      });
    }

    if (thinklet.rubric.reviewList) {
      _.each(thinklet.rubric.reviewList, function (response) {
        response.optionId = null;
      });
    }

    if (thinklet.rubric.responseList) {
      _.each(thinklet.rubric.responseList, function (response) {
        response.optionId = null;
      });
    }

    if (thinklet.rubric.answerList) {
      _.each(thinklet.rubric.answerList, function (response) {
        response.optionId = null;
      });
    }

    var note = _.find(thinklet.rubric.notes, { type: CREATE_STEPS.Understand });

    if (note)
      note.note = null;

    note = _.find(thinklet.rubric.notes, { type: CREATE_STEPS.Plan });

    if (note)
      note.note = null;

    note = _.find(thinklet.rubric.notes, { type: CREATE_STEPS.Solve });

    if (note)
      note.note = null;

    note = _.find(thinklet.rubric.notes, { type: CREATE_STEPS.Review });

    if (note)
      note.note = null;

    return cuethink.delete(config.resetRubricURL.replace('{0}', thinklet.id));
  };

  _me.shareRubric = function (thinklet, isShared) {
    if (!thinklet.rubric)
      return;

    return cuethink.post(config.shareRubricURL.replace('{0}', thinklet.id).replace('{1}', isShared ? 'true' : 'false')).then(function () {
      thinklet.is_rubric_shared = isShared;
    });
  };

  _me.loadTags = function (success, error) {
    cuethink.getjson(config.tagsURL, null, success, error);
  };

  _me.loadTagsAndStrategies = function (success, error) {
    cuethink.getjson(config.tagsAndStrategiesURL, null, success, error);
  };

  _me.saveTags = function (thinklet, tags, success, error) {
    var url = config.saveTagsURL.replace('{0}', thinklet.id);

    url += '?tags=';

    if (tags)
      url += encodeURIComponent(tags.join(','));

    cuethink.put(url, null, success, error);
  };

  _me.loadStrategies = function (success, error) {
    cuethink.getjson(config.defaultStrategiesURL, null, success, error);
  };

  _me.deleteNotification = function (notification, success, error) {
    var url;

    /*if (notification.old)
      url = config.deleteOldNotificationURL;
    else*/
    url = config.deleteNotificationURL;

    url = url.replace('{0}', notification.id);

    return cuethink.put(url, success, error);
  };

  _me.createNewThinkletVersion = function (id, success, error) {
    cuethink.postjson(config.thinkletVersionURL.replace('{0}', id), null, function (id) {
      _me.loadThinklet(id, success, error);
    }, error);
  };

  _me.loadThinkletVersions = function (id, success, error) {
    return cuethink.getjson(config.thinkletVersionsURL.replace('{0}', id), null, success, error);
  };

  _me.copyExemplarThinklet = function (thinkletId, assignmentId) {
    return cuethink.post(config.copyExemplarThinkletURL, { thinkletId: thinkletId, assignmentId: assignmentId });
  };

  function calculateRubricScore (thinklet) {
    if (thinklet.status != 'completed')
      return null;

    var result = 0;

    if (thinklet.rubric) {
        var lists = [thinklet.rubric.understandList, thinklet.rubric.planList, thinklet.rubric.solveList, thinklet.rubric.reviewList];
        var max = 0;

        _.each(lists, function (list) {
            _.each(list, function (response) {
                if (response.optionId === 1)
                    result += 2;
                else if (response.optionId === 2)
                    result += 1;
            });

            max += list.length * 2;
        });

        if (max === 0)
            result = 'NOT_SCORED';
        else
            result += '/' + max;
    } else {
        result = 'NOT_SCORED';
    }

    return result;
  }

  _me.loadRubrics = function () {
    if (!userManager.getUser() || !userManager.getUser().selectedClass) {
      var deferred = $q.defer();
      deferred.reject();
      return deferred.promise;
    }

    return cuethink.getjson(config.loadRubricsURL, { classId: userManager.getUser().selectedClass.id }).then(function (thinklets) {
      return thinklets.map(function (thinklet) {
        var score = calculateRubricScore(thinklet);

        if (score == 'NOT_SCORED')
            score = -1;

        thinklet.score = score;

        thinklet.problem = {
          answer: thinklet.problemAnswer,
          id: thinklet.problemId,
          problemTranslations: thinklet.problemTranslations,
          title: thinklet.title
        };

        return thinklet;
      });
    });
  };

  _me.saveStudentReflection = function (thinklet, reflection) {
    return cuethink.postjson(config.saveStudentReflectionURL.replace('{0}', thinklet.id), { studentReflection: reflection }).then(function () {
      thinklet.rubric.studentReflection = reflection;
    });
  };

  _me.createAssignmentFromProblem = function (problemId) {
    return cuethink.postjson(config.createAssignmentFromProblemURL.replace('{problemId}', problemId));
  };
}

ThinkletManager.sharedInstance = null;

ThinkletManager.getShared = () => {
  return ThinkletManager.sharedInstance;
};
