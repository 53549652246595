import navBarModule from './navbar.module';
import tpl from './quaidMenuDirective.tpl.html';

navBarModule.directive('quaidMenu', function () {
    "ngInject";

    return {
        restrict: 'E',
        template: tpl,
        link: function ($scope) {
            $scope.switchToEducatorMode = function () {
                if ($scope.isEducatorModeEnabled)
                    $scope.switchMode();
                else
                    $scope.showTrialEndPopUp();
            };
        },
        scope: {
            isEducatorModeEnabled: '=educatorEnabled',
            showTrialEndPopUp: '&',
            startTutorial: '=',
            switchMode: '=',
            switchModeName: '=',
            user: '='
        }
    };
});
