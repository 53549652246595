import 'src/components/shared/cuethink';

function RedirectController ($stateParams, $window, CueThink) {
  "ngInject";
  var url = $stateParams.url;
  var unit = $stateParams.unit;
  var slide = $stateParams.slide;
  var name = $stateParams.name;
  var calendar = $stateParams.calendar;
  var intercomEventStr = "";
  var intercomEvent = $stateParams.intercomEvent && ($stateParams.intercomEvent === "true" || $stateParams.intercomEvent === true);

  if (name){
    if (intercomEvent)
      intercomEventStr = "User Viewed " + name;

    CueThink.logPLEvent(name, unit, slide, calendar, url, intercomEventStr);
  }

  if ($stateParams.nw == "true"){
    $window.open(url, "_blank");
    $window.history.back();
  }

  else{
    $window.open(url, "_self");
  }
}

export default RedirectController;
