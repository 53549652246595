import 'src/components/shared/create/create';
import 'src/components/shared/media';
import 'config';
import 'src/components/shared/cuethink';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/user_data';
import 'core/whiteboard/whiteboard.module';
import 'core/whiteboard/libs/imagepreloader.module';
import './solve.css';

require('src/components/shared/create/create.partial.html');
require('src/components/shared/create/question_plan.partial.html');
import SpeedTester from 'src/vendor/speed-tester';
import Whiteboard from 'core/whiteboard/whiteboard';
import 'angular-ui-router';
import 'core/common/cuethinkPopUpDirective';
import 'angular-translate';
import NotificationCenter from 'core/notifications/notification-center';
import permissionsManager, { Permissions } from 'core/permissions/permissions';
import IdleTimer from '../shared/idle-timer';
import 'src/images/ClosexBlue_2px.svg';
import 'src/images/CloseX_InCircle.svg';
import 'src/images/LightBulb.svg';
import LogManager from 'core/logging/log-manager'

function SolveController($scope, $controller, $stateParams, MediaManager, imagePreloader, CueThink, ThinkletManager, CueThinkConfig, UserManager, $q, TutorialService, $state, $translate, $rootScope, $transitions, $sce) {
  "ngInject";

  var $cuethink = CueThink;
  var $thinkletManager = ThinkletManager;
  var $imagePreloader = imagePreloader;
  var $mediaManager = MediaManager;
  var _status;
  var hasRecording, _images = {};
  let _idleTimer;
  let _seenInactiveMetacognitivePopUp, _seenActiveMetacognitivePopUp;

  var _speedTester = new SpeedTester(function (rate, elapsed) {
    if (rate <= 300 * 1024 && elapsed >= 5000)
      $scope.viewModel.showSlowUploadMessage = true;
  });

  var _progressBar;

  $scope.$$postDigest(function () {
    _progressBar = $('#progressbar');

    _progressBar.progressbar({
      value: false
    });
  });

  $scope.viewModel = {
    dontShowLeavePopUpChecked: true,
    isAlgebraTilesEnabled: false,
    isArrayEnabled: false,
    isDiagramEnabled: false,
    isEquationEnabled: false,
    isFractionBarsEnabled: false,
    isHighlighterEnabled: false,
    isImageEnabled: false,
    isLoaded: false,
    isNumberBondEnabled: false,
    isNumberLineEnabled: false,
    isPaused: false,
    isPenEnabled: false,
    isShapesEnabled: false,
    isSpreadsheetEnabled: false,
    isStampsEnabled: false,
    isTextEnabled: false,
    isUnifixCubeEnabled: false,
    showSlowUploadMessage: false
  };

  function applySettings(settings) {
    const settingsMap = {
      isAlgebraTilesEnabled: 'algebraTile',
      isArrayEnabled: 'array',
      isDiagramEnabled: 'diagrams',
      isEquationEnabled: 'equation',
      isFractionBarsEnabled: 'fractionBar',
      isGridEnabled: 'grid',
      isHighlighterEnabled: 'highlighter',
      isImageEnabled: 'image',
      isNumberBondEnabled: 'numberBond',
      isNumberLineEnabled: 'numberLine',
      isPenEnabled: 'pen',
      isProtractorEnabled: 'protractor',
      isShapesEnabled: 'shapes',
      isSpreadsheetEnabled: 'table',
      isStampsEnabled: 'stamps',
      isTextEnabled: 'text',
      isUnifixCubeEnabled: 'unifixCube'
    };

    Object.keys(settingsMap).forEach(key => {
      const setting = settings.creatingThinklets.solve.tools[settingsMap[key]];

      $scope.viewModel[key] = setting ? setting.enabled : false;
    });

    $scope.viewModel.toolOrder = settings.creatingThinklets.solve.toolOrder;
    $scope.viewModel.hiddenTools = settings.creatingThinklets.solve.hiddenTools;
  }

  if (permissionsManager.getPermission(Permissions.WhiteboardToolCustomization)) {
    UserManager.getGlobalSettings().then(function (settings) {
      applySettings(settings);

      $scope.isLoaded = true;
    });
  } else {
    applySettings(CueThinkConfig.smartDesignSettings);

    $scope.isLoaded = true;
  }

  function saveWhiteboard(whiteboard, events) {
    if ($scope.loading)
      return;

    console.log('autosaving');


    // if (!_status || (_status !== 'start record' && _status !== 'resume record'))
    //   $scope.saveScreenshot(whiteboard.getPage(), 'solve');

    $mediaManager.uploadWhiteboard($scope.thinklet, events, null, alert);
  }

  // TODO: make sure overwrite logic still working
  // TODO: repeat mathjax until it is available

  function loadVideo() {
    if (!$scope.thinklet.whiteboardURL) {
      $scope.saveWhiteboard = saveWhiteboard; // begin autosaving
      $scope.shouldAutosaveWhiteboard = true;

      return;
    }

    $scope.loading = 'Loading...';

    var imageURLs;

    function addImageURL(event) {
      if (event.type === 'image' || event.type === 'equation')
        imageURLs[event.url] = null;
    }

    $cuethink.get($scope.thinklet.whiteboardURL, function (data) {
      if (!Array.isArray(data)) {
        if (typeof data === 'object') { // older thinklets used to be saved in the same file as recording data under a different JSON key
          data = data.events;

          if (!data) {
            $scope.saveWhiteboard = saveWhiteboard; // begin autosaving
            $scope.shouldAutosaveWhiteboard = true;

            $scope.loading = false;

            digest();

            return;
          }
        } else {
          $scope.loading = false;

          digest();

          return;
        }
      }

      imageURLs = {};

      for (var i = 0; i < data.length; i++)
        Whiteboard.crawlEvents(data[i], addImageURL, addImageURL);

      imageURLs = Object.keys(imageURLs);

      $imagePreloader.load(imageURLs, function (images) {
        _images = images;

        $scope.events = data;
        $scope.saveWhiteboard = saveWhiteboard; // begin autosaving
        $scope.shouldAutosaveWhiteboard = true;

        $scope.loading = false;

        digest();
      });
    }, function () {
      $thinkletManager.loadThinklet($scope.thinklet.id, function (thinklet) {
        $scope.thinklet.static_data = thinklet.static_data;
        $scope.thinklet.whiteboardURL = thinklet.whiteboardURL;

        $thinkletManager.cacheThinklet(thinklet);

        loadVideo();
      }, false, true);
    });
  }

  function thinkletLoadCallback(thinklet) {
    if (thinklet.whiteboardURL) {
      hasRecording = true;

      loadVideo();
    } else {
      $scope.saveWhiteboard = saveWhiteboard; // begin autosaving
      $scope.shouldAutosaveWhiteboard = true;
    }
  }

  $controller('CreateController', {
    '$scope': $scope,
    '$stateParams': $stateParams,
    'thinkletLoadCallback': thinkletLoadCallback
  });

  $scope.controller = name;

  var _progress;
  var _stalledTimer;

  function clearStalledTimer() {
    clearTimeout(_stalledTimer);
    _stalledTimer = null;
  }

  function startStalledTimer() {
    _stalledTimer = setTimeout(function () {
      $scope.viewModel.showSlowUploadMessage = true;

      digest();
    }, 5000);
  }

  $scope.recordingCallback = function (whiteboard, events, recordedEvents, audio, callback) {
    var uploadedRecording, uploadedThumbnail;

    console.log('finished recording');

    setProgress(0);
    _speedTester.reset();

    startStalledTimer();

    $scope.viewModel.uploadError = null;
    $scope.viewModel.showSlowUploadMessage = false;

    digest();

    $mediaManager.uploadRecording($scope.thinklet, {
      ratio: whiteboard.getWidth() / whiteboard.getHeight(),
      recordedEvents: recordedEvents
    }, audio instanceof Blob ? audio : null, function (result, isVideo, isAudio) {
      clearStalledTimer();

      $scope.viewModel.showSlowUploadMessage = false;

      if (!audio) {
        $mediaManager.deleteThinkletAudio($scope.thinklet);

        $thinkletManager.logThinkletEvent('User Audio Error', $scope.thinklet, $scope.audioRecorderError);
      }

      if (isAudio || !(audio instanceof Blob)) {
        uploadedRecording = true;

        if (uploadedThumbnail) {
          $scope.loading = false;

          digest();

          callback();
        }

        NotificationCenter.getShared().post('Uploaded Recording');
      }

      $thinkletManager.saveThinklet($scope.thinklet.id, {}, $scope.thinklet);

      if ($scope.showBeliefsProbe)
        $scope.showBeliefsProbe('solve');
    }, function (error) {
      $thinkletManager.logThinkletEvent('Recording Upload Error', $scope.thinklet, error);

      $scope.viewModel.uploadError = error;
    }, function (progress, bytes, total) {
      clearStalledTimer();
      startStalledTimer();

      setProgress(progress, bytes, total);

      digest();
    });

    whiteboard.getImage(function (blob) {
      $mediaManager.uploadThumbnail($scope.thinklet, blob, function () {
        uploadedThumbnail = true;

        if (uploadedRecording) {
          $scope.loading = false;

          digest();

          callback();
        }
      });
    });

    // $scope.saveScreenshot(whiteboard.getPage(), 'solve');
  };

  $scope.logEvent = function (name, msg) {
    $thinkletManager.logThinkletEvent(name, $scope.thinklet, msg);
  };

  $scope.getProgress = function () {
    return _progress;
  };

  function setProgress(value, bytes) {
    _progress = value;

    _progressBar.progressbar({
      value: _progress * 100
    });

    _speedTester.update(bytes);
  }

  $scope.getLoadingMessage = function () {
    var result = $scope.loading;

    if (_progress !== undefined)
      result += ' ' + Math.trunc(_progress * 100) + '%';

    return result;
  };

  var _record;

  $scope.startRecording = function () {
    $scope.showOverwritePrompt = false;

    _record();
  };

  $scope.shouldOverwrite = function (callback) {
    if (!$scope.thinklet.videoURL)
      return false;

    _record = callback;

    $scope.showOverwritePrompt = true;

    digest();

    return true;
  };

  $scope.uploadImage = function (image, callback) {
    var loadImage = function (url) {
      $imagePreloader.loadImage(url, function (url, image) {
        _images[url] = image;

        callback(url);

        _isImageLoading = false;

        digest();
      });
    };

    if (typeof image == 'string') {
      loadImage(image);
    } else {
      _isImageLoading = true;

      digest();

      $mediaManager.uploadImage($scope.thinklet.id, image).then(function (response) {
        var url = $mediaManager.getMediaURLFromPath(response.id);

        loadImage(url);
      });
    }
  };

  $scope.isLoading = function () {
    return !!$scope.loading;
  };

  var _isImageLoading;
  $scope.isImageLoading = function () {
    return _isImageLoading;
  };

  $scope.loadImage = function (url) {
    return _images ? _images[url] : null;
  };

  $scope.onPageChange = function (page, isNew) {
    let name;

    if (isNew) {
      name = 'create new page';
    } else {
      name = 'select page';
    }

    $cuethink.logThinkletEvent(name, $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, page);
  };

  $scope.onToolChange = function (tool, type) {
    if (tool === 'algebra tile')
      UserManager.addDigitalPromiseToolUsed('ALGEBRA_TILES');
    else if (tool === 'fraction bar')
      UserManager.addDigitalPromiseToolUsed('FRACTION_BARS');

    $cuethink.logThinkletEvent('Solve tool used', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, tool + (type ? ': ' + type : ''));
  };

  let lastToolType;
  let lastToolCount = 0;
  let isActive = false;

  $scope.onWhiteboardChange = obj => {
    $scope.resetHelperIdleTimer();

    if (_idleTimer)
      _idleTimer.reset();

    isActive = true;

    if ($scope.isMetaCognitiveIconEnabled()) {
      if (obj.type === 'clear') {
        $scope.metacognitivePopoverTemplate = 'metacognitivePopoverClear.tpl.html';

        digest();

        LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'IT_LOOKS_LIKE_YOU_VE_CHANGED_YOUR_MIND_THATS_OKAY_WHAT_OTHER_STRATEGIES_OR_TOOLS_CAN_YOU_TRY', thinkletId: $scope.thinklet.id, userId: $scope.user.id });
      }
    }
  };

  function digest() {
    if (!$scope.$$phase) {
      try {
        $scope.$digest();
      } catch (e) {
      }
    }
  }

  $scope.onStatusChange = function (status) {
    _status = status;

    if (status === 'start record') {
      $thinkletManager.logThinkletEvent('Start Record', $scope.thinklet);
    } else if (status === 'pause record') {
      $thinkletManager.logThinkletEvent('Pause Record', $scope.thinklet);
    } else if (status === 'resume record') {
      $thinkletManager.logThinkletEvent('Resume Record', $scope.thinklet);
    } else if (status === 'stop record') {
      $thinkletManager.logThinkletEvent('Stop Record', $scope.thinklet);

      $scope.loading = 'Uploading...';

      digest();
    }
  };

  $scope.onMicrophoneNotAvailable = function () {
    $scope.isMicrophonePromptVisible = true;

    digest();
  };

  $scope.hideMicrophonePrompt = function () {
    $scope.isMicrophonePromptVisible = false;
  };

  function handleImage(url, callback) {
    $imagePreloader.loadImage(url, function (url, image) {
      _images[url] = image;

      callback(url);
    });
  }

  $scope.renderEquation = function (mathML, font, size) {
    return $mediaManager.renderMathML($scope.thinklet.id, mathML, font, size).then(function (url) {
      var deferred = $q.defer();

      handleImage(url, function (url) {
        deferred.resolve(url);
      });

      return deferred.promise;
    });
  };

  $scope.closeOverwritePrompt = function () {
    $scope.showOverwritePrompt = false;
  };

  $scope.closeOverwritePrompt = function () {
    $scope.showOverwritePrompt = false;
  };

  $scope.closeLeavePopUp = function () {
			$('#solve-leave-pop-up').hide();
  };

  $scope.confirmLeavePopUp = function () {
	};

	function handleLeaveNavigation () {
		$scope.viewModel.isPaused = true;

    $('#solve-leave-pop-up').show();

    setTimeout(function () {
      $scope.viewModel.isPaused = false;
    });
	}

  $transitions.onStart({}, function () {
    if (!$scope.isRecording)
      return true;

		handleLeaveNavigation();

		return false;
  });

	$scope.isLogOutAllowed = function () {
		if ($scope.isRecording) {
			handleLeaveNavigation();

			return false;
		} else {
			return true;
		}
	};

  $scope.isMetaCognitiveIconVisible = false;

  function startIdleTimer() {
    if (typeof _idleTimer === 'undefined') { // only show once
      _idleTimer = new IdleTimer(2 * 60 * 1000);

      _idleTimer.setCallback(() => {
        if (isActive) {
          if (!_seenActiveMetacognitivePopUp) {
            $scope.metacognitivePopoverTemplate = 'metacognitivePopoverActive.tpl.html';

            LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'GREAT_START_IS_YOUR_CURRENT_STRATEGY_WORKING', thinkletId: $scope.thinklet.id, userId: $scope.user.id });

            $scope.onMetacognitivePopoverYesButtonClick = () => {
              $scope.metacognitivePopoverTemplate = 'metacognitivePopoverYes.tpl.html';

              LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'AWESOME_KEEP_GOING', thinkletId: $scope.thinklet.id, userId: $scope.user.id });
            };

            $scope.onMetacognitivePopoverNoButtonClick = () => {
              $scope.metacognitivePopoverTemplate = 'metacognitivePopoverNo.tpl.html';

              LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'THATS_OKAY_NO_WORRIES_ASK_YOURSELF_WHERE_IS_MY_STRATEGY_NOT_WORKING_OR_TALK_TO_A_CLASSMATE_TO_GET_IDEAS', thinkletId: $scope.thinklet.id, userId: $scope.user.id });
            };

            _seenActiveMetacognitivePopUp = true;
          }
        } else {
          if (!_seenInactiveMetacognitivePopUp) {
            const idleMessages = [
              'JUST_CHECKING_IN_WHAT_SOLVE_PHASE_TOOLS_COULD_YOU_USE',
              'JUST_CHECKING_IN_WHAT_HAVE_YOU_DONE_BEFORE_TO_SOLVE_PROBLEMS_LIKE_THIS'
            ];

            const idleMessage = idleMessages[Math.trunc(Math.random() * idleMessages.length)];

            $scope.metaCognitiveIdleHtml = idleMessage;
            $scope.metacognitivePopoverTemplate = 'metacognitivePopoverIdle.tpl.html';

            LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: idleMessage, thinkletId: $scope.thinklet.id, userId: $scope.user.id });

            _seenInactiveMetacognitivePopUp = true;
          }
        }

        digest();

        if (_seenInactiveMetacognitivePopUp && _seenActiveMetacognitivePopUp)
          _idleTimer = null;
      });
    }
  }

  if ($scope.isMetaCognitiveIconEnabled()) {
    $scope.isMetaCognitiveIconVisible = true;

    $scope.onMetaCognitiveIconClick = () => {
      if ($scope.metacognitivePopoverTemplate) {
        $scope.metacognitivePopoverTemplate = null;

        startIdleTimer();
      } else {
        $scope.metacognitivePopoverTemplate = 'metacognitivePopoverClick.tpl.html';

        LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'WHAT_SOLVE_PHASE_TOOLS_CAN_I_USE\nWHAT_HAVE_I_DONE_BEFORE_TO_SOLVE_PROBLEMS_LIKE_THIS\nWHERE_IS_MY_STRATEGY_NOT_WORKING' , thinkletId: $scope.thinklet.id, userId: $scope.user.id });
      }
    };

    let seenIntro;

    if (typeof localStorage === 'undefined') {
      seenIntro = {};
    } else {
      seenIntro = localStorage.getItem('SeenMetacognitiveIntro');

      if (seenIntro)
        seenIntro = JSON.parse(seenIntro);
      else
        seenIntro = {};
    }

    let userSeenIntro = seenIntro[$scope.user.id];

    if (!userSeenIntro) {
      userSeenIntro = {};
      seenIntro[$scope.user.id] = userSeenIntro;
    }

    if (userSeenIntro[$scope.thinklet.id]) {
      startIdleTimer();
    } else {
      $scope.metacognitivePopoverTemplate = 'metacognitivePopoverIntro.tpl.html';
      $scope.metacognitiveIntroHtml = $sce.trustAsHtml($translate.instant('META_COGNITIVE_INTRO_MESSAGE'));

      LogManager.getShared().logBehaviorEvent('Metacognitive Helper', { assignmentId: $scope.thinklet.assignmentId, message: 'METACOGNITION_VIDEO', thinkletId: $scope.thinklet.id, userId: $scope.user.id });

      userSeenIntro[$scope.thinklet.id] = true;

      if (typeof localStorage !== 'undefined')
        localStorage.setItem('SeenMetacognitiveIntro', JSON.stringify(seenIntro));
    }

    $scope.setIdleHelperCallback(() => false);
  } else {
    $scope.setHelperIdleTitle('STOP_AND_THINK');
    $scope.setHelperIdleMessage('READ_YOUR_PLAN_AND_TRY_USING_IT_TO_SOLVE_THE_PROBLEM_THINK_ABOUT_WHAT_WORKED_AND_WHAT_YOU_COULD_CHANGE_THIS_WILL_HELP_YOU_DO_BETTER', true);
  }

  const oldToggleQuestion = $scope.toggleQuestion;

  $scope.toggleQuestion = () => {
    oldToggleQuestion();

    if ($scope.isNewDesign())
      $cuethink.logThinkletEvent('Plan accessed on Solve phase', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, 'Yes');
  };

  $scope.$on('$destroy', function() {
    $scope.setIdleHelperCallback(null);
  });
}

export default SolveController;
