import 'angular-translate';
import 'core/common/cuethinkPopUpDirective';
import './digitalPromiseProbe.css';
import CueThinkConfig from '../config.provider';
import tpl from './digitalPromiseProbe.tpl.html';
import 'core/common/paginatorDirective';

var cloneDeep = require('lodash.clonedeep');

export default function DigitalPromiseProbe($translate, UserManager) {
  return {
    link: function ($scope, element) {
      var _pages = [];

      $scope.viewModel = {
        page: null,
        radioSelection: null,
        responseText: null
      };

      $scope.getPageCount = () => {
        return _pages.length;
      };

      $scope.getPageIndex = () => {
        return _pages.indexOf($scope.viewModel.page);
      };

      var digitalPromiseToolsUsed = UserManager.getDigitalPromiseToolsUsed();

      var sections = cloneDeep(CueThinkConfig.digitalPromiseProbePrompt).responses.filter(function (response) {
        return digitalPromiseToolsUsed.indexOf(response.text) !== -1;
      }).map(response => response.questions.map(question => Object.assign({}, question, {
        title: response.text,
        image: response.image
      })));

      sections.forEach(section => {
        _pages = _pages.concat(section);
      });

      function selectPage(index) {
        $scope.viewModel.page = _pages[index];

        $scope.viewModel.radioSelection = null;
        $scope.viewModel.responseText = null;
      }

      selectPage(0);

      function close(finished) {
        element.remove();

        $scope.$emit('CloseDigitalPromiseProbe', finished);
      }

      $scope.onClose = function () {
        close();
      };

      function submit(previous) {
        if (!$scope.viewModel.radioSelection || !$scope.viewModel.responseText)
          return;

        $scope.$emit('SaveDigitalPromiseProbeResponse', {
          input: $scope.viewModel.responseText,
          probe: $scope.viewModel.page.title + '_' + $scope.viewModel.page.text,
          radio: $scope.viewModel.radioSelection
        });

        var pageIndex = _pages.indexOf($scope.viewModel.page);

        if (previous) {
          if (pageIndex > 0)
            selectPage(pageIndex - 1);
        } else {
          if (pageIndex < _pages.length - 1)
            selectPage(pageIndex + 1);
          else
            close(true);
        }

        return false;
      }

      $scope.onSubmitButtonClick = function () {
        return submit();
      };

      $scope.$on('SelectedNextPage', () => {
        submit();
      });

      $scope.$on('SelectedPreviousPage', () => {
        submit(true);
      });
    },
    restrict: 'E',
    template: tpl
  }
}

export function bootstrapDirective(module) {
  module.directive('digitalPromiseProbe', DigitalPromiseProbe);
}
