import 'src/components/shared/create/create';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import './plan.css';
require('src/components/shared/create/create.partial.html');
import { translate } from 'core/common/translation-helpers';
import 'src/images/PlusSign.svg';
import 'src/images/draw.svg';
import 'src/images/table-list.svg';
import 'src/images/backward-icon.svg';
import 'src/images/guess_check_icon.svg';
import 'src/images/manipulatives.svg';
import 'src/images/pattern_icon_new.svg';
import 'src/images/equation_icon.svg';
import 'src/images/DifferentEquation.svg';
import 'src/images/check_icon.svg';
import 'src/images/DownArrow.svg';
import 'src/images/UpArrow.svg';
import 'src/images/SpeechToText.svg';
import 'src/components/shared/cuethink';
import NotificationCenter from 'core/notifications/notification-center';
import LogManager from 'core/logging/log-manager';
import 'angular-translate';
import permissionsManager, { Permissions } from 'core/permissions/permissions';

function PlanController ($scope, $controller, $stateParams, $timeout, UserManager, $state, $translate, $rootScope, CueThink, CueThinkConfig) {
  "ngInject";

  function getObjectSize(obj) {
    var size = 0, key;

    for (key in obj)
      if (obj.hasOwnProperty(key))
        size++;

    return size;
  }

  $controller('CreateController', {
    '$scope': $scope, '$stateParams': $stateParams, 'thinkletLoadCallback': function (thinklet) {
      if (thinklet.problem.strategies)
        $scope.defaultStrategyCount = getObjectSize(thinklet.problem.strategies[100]);
    }
  });

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  setTimeout(() => {
    if ($scope.isNewDesign()) {
      var translation = $translate.instant('THE_PROBLEM_IS_ASKING_ME_TO');

      if (!$scope.thinklet.plan || $scope.thinklet.plan === (translation + ' ')) {
        if (!$scope.thinklet.plan || $scope.thinklet.explore)
          $scope.thinklet.plan = translation + ' ' + ($scope.thinklet.explore ? $scope.thinklet.explore : '');

        digest();
      }
    }
  }, 1000);

  $scope.controller = name;

  translate('MY_STRATEGY_WILL_BE', $translate, $rootScope, $scope, function (value) {
    $scope.placeholder = value;
  });

  $scope.formData = {
    addStrategyForm: null,
    newStrategy: null
  };

  $scope.viewModel = {
    isSentenceStarterDropdownOpen: false,
    sentenceStarters: CueThinkConfig.sentenceStarters
  };

  if (!$scope.isNewDesign()) {
    UserManager.getGlobalSettings().then(function (settings) {
      $scope.viewModel.sentenceStarters = settings.creatingThinklets.plan.sentenceStarters.filter(function (starter) {
        return starter.enabled;
      }).map(function (starter) {
        return starter.text;
      });
    });
  }

  $scope.saveStrategy = function () {
    if (!$scope.thinklet)
      return;

    let strategy;

    if ($scope.new_strategy) {
      strategy = $scope.new_strategy;

      $scope.thinklet.complexItems.strategies.push($scope.new_strategy);
      $scope.new_strategy = null;
    }

    $scope.save({ complexItems: $scope.thinklet.complexItems }, strategy);
  };

  $scope.toggleStrategy = function (strategy) {
    if (strategy.status === 'true')
      strategy.status = 'false';
    else
      strategy.status = 'true';

    $scope.save({ complexItems: $scope.thinklet.complexItems }, strategy);
  };

  $scope.onAddNewStrategy = function () {
    var newStrategy = {
      name: $scope.formData.newStrategy,
      status: 'false',
      description: 'user created'
    };

    if (newStrategy.name.length) {
      $scope.thinklet.complexItems.strategies.unshift(newStrategy);
      $scope.formData.newStrategy = null;
    }

    $scope.save({ complexItems: $scope.thinklet.complexItems }, newStrategy);
  };

  $scope.onDeleteStrategy = function (index) {
    $scope.thinklet.complexItems.strategies.splice(index, 1);
    $scope.save({ complexItems: $scope.thinklet.complexItems });
  };

  function insertTextIntoPlan(text) {
    var plan = $scope.thinklet.plan;

    if (!plan)
      plan = '';

    var originalPlan = plan;

    var endsInPeriod;

    var textarea = $('textarea.plan-field');
    var position = plan.length; // textarea.prop('selectionStart');

    if (plan[position - 1] === '.')
      endsInPeriod = true;

    plan = originalPlan.substr(0, position);

    if (endsInPeriod)
      plan += ' ';

    plan += text + ' ';

    var newPosition = plan.length;

    plan += originalPlan.substr(position);

    $scope.$$postDigest(function () {
      textarea[0].selectionEnd = newPosition;
    });

    $scope.thinklet.plan = plan;

    digest();
  }

  $scope.selectSentenceStarter = function (starter, event) {
    insertTextIntoPlan($translate.instant(starter));

    event.stopPropagation();
    event.preventDefault();

    $scope.viewModel.isSentenceStarterDropdownOpen = false;

    CueThink.logEvent('Choose Sentence Starter', $scope.thinklet.assignmentId, $scope.thinklet.problemId, $scope.thinklet.id, starter);

    UserManager.addDigitalPromiseToolUsed('SENTENCE_STARTERS');

    return false;
  };

  $scope.getStrategyIconUrl = function (strategy) {
    var fileName;

    if (strategy.name === 'DRAW_A_PICTURE' || strategy.name === $translate.instant('DRAW_A_PICTURE')
      || strategy.name === 'CREATE_A_MODEL' || strategy.name === $translate.instant('CREATE_A_MODEL'))
      fileName = 'draw.svg';
    else if (strategy.name === 'MAKE_A_TABLE' || strategy.name === $translate.instant('MAKE_A_TABLE'))
      fileName = 'table-list.svg';
    else if (strategy.name === 'SOLVE_WITH_AN_EASIER_PROBLEM' || strategy.name === $translate.instant('SOLVE_WITH_AN_EASIER_PROBLEM'))
      fileName = 'solveicon.svg';
    else if (strategy.name === 'WORK_BACKWARDS' || strategy.name === $translate.instant('WORK_BACKWARDS'))
      fileName = 'backward-icon.svg';
    else if (strategy.name === 'GUESS_CHECK_AND_REVISE' || strategy.name === $translate.instant('GUESS_CHECK_AND_REVISE'))
      fileName = 'guess_check_icon.svg';
    else if (strategy.name === 'MODEL_IT_WITH_MANIPULATIVES' || strategy.name === $translate.instant('MODEL_IT_WITH_MANIPULATIVES')
      || strategy.name === 'SHOW_WITH_MANIPULATIVES' || strategy.name === $translate.instant('SHOW_WITH_MANIPULATIVES')
      || strategy.name === 'USE_MANIPULATIVES' || strategy.name === $translate.instant('USE_MANIPULATIVES'))
      fileName = 'manipulatives.svg';
    else if (strategy.name === 'LOOK_FOR_A_PATTERN' || strategy.name === $translate.instant('LOOK_FOR_A_PATTERN'))
      fileName = 'pattern_icon_new.svg';
    else if (strategy.name === 'MODEL_WITH_AN_EQUATION' || strategy.name === $translate.instant('MODEL_WITH_AN_EQUATION')
      || strategy.name === 'SHOW_WITH_AN_EQUATION' || strategy.name === $translate.instant('SHOW_WITH_AN_EQUATION'))
      fileName = 'equation_icon.svg';
    else if (strategy.name === 'TRY_WITH_DIFFERENT_NUMBERS' || strategy.name === $translate.instant('TRY_WITH_DIFFERENT_NUMBERS')
      || strategy.name === 'TRY_WITH_FRIENDLIER_NUMBERS' || strategy.name === $translate.instant('TRY_WITH_FRIENDLIER_NUMBERS'))
      fileName = 'DifferentEquation.svg';

    if (fileName)
      return '/images/' + fileName;
    else
      return null;
  };

  $scope.onPlanStrategyClick = function (strategy) {
    if ($scope.thinklet.plan)
      insertTextIntoPlan($translate.instant(strategy.name).toLowerCase());
    else
      $scope.thinklet.plan = $translate.instant(strategy.name).toLowerCase();

    CueThink.logThinkletEvent('Thinklet Strategies Used', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
      Used: strategy.name
    });
  };

  $scope.getSelectedStrategies = function () {
    if (!$scope.thinklet || !$scope.thinklet.complexItems || !$scope.thinklet.complexItems.strategies)
      return [];

    return $scope.thinklet.complexItems.strategies.filter(function (strategy) {
      return strategy.status === 'true';
    });
  };

  $scope.isStrategyInPlan = function (strategy) {
    return $scope.thinklet.plan && $scope.thinklet.plan.indexOf($translate.instant(strategy.name).toLowerCase()) !== -1;
  };

  $scope.isSpeechToTextButtonEnabled = false;

  $scope.onSpeechButtonClick = function () {
    var planField = $('.plan-field');

    planField.focus();
    planField[0].selectionStart = planField.val().length;

    if ($scope.isSpeechToTextButtonEnabled) {
      $scope.isSpeechToTextButtonEnabled = false;
    } else if ($scope.isSpeechToTextEnabled()) { // already in use
      return;
    } else {
      $scope.isSpeechToTextButtonEnabled = true;

      CueThink.logThinkletEvent('Speech to Text', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, 'Yes');
    }

    $scope.setSpeechToTextEnabled($scope.isSpeechToTextButtonEnabled);
  };

  NotificationCenter.getShared().addListener('SpeechToText', function (text) {
    if ($scope.isSpeechToTextButtonEnabled) {
      insertTextIntoPlan(text);
    }
  });

  /*$scope.setHelperIdleTitle('HOW_ARE_YOU_FEELING');
  $scope.setHelperIdleMessage(null);
  $scope.setHelperIdleButtons({
    ['<span style="font-size: 20px;">☹️<br /></span>' + $translate.instant('FRUSTRATED')]: () => {
      $scope.setHelperTitle('ITS_OK_TO_GET_FRUSTRATED');
      $scope.setHelperMessage('TRY_TAKING_A_BREATH_OR_ASKING_A_PEER_FOR_HELP');
      $scope.setHelperButtons(null);
    },
    ['<span style="font-size: 20px;">🙂<br /></span>' + $translate.instant('CONFIDENT')]: () => {
      $scope.setHelperTitle('THATS_GREAT_KEEP_GOING');
      $scope.setHelperMessage(null);
      $scope.setHelperButtons(null);
    }
  });*/

  const oldSave = $scope.save;

  function logUserInput(type, value) {
    return CueThink.logThinkletEvent('Plan input', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
      type,
      value
    });
  }

  $scope.save = (data, item) => {
    oldSave(data);

    if (data.complexItems && item) {
      LogManager.getShared().logThinkletEvent('Thinklet Strategies', $scope.thinklet, item.name, 'plan');

      if (data.complexItems.strategies)
        logUserInput($translate.instant('ADD_STRATEGIES_TO_PLAN'), data.complexItems.strategies.map(strategy => strategy.name).join('\n'));
    }

    if (typeof data.plan !== 'undefined')
      logUserInput($translate.instant('WRITE_YOUR_INITIAL_THOUGHTS_AND_ADD_IDEAS'), data.plan);
  };

  $scope.isStrategyVideosEnabled = () => {
    return permissionsManager.getPermission(Permissions.StrategyVideos);
  };

  $scope.getVideoForStrategy = strategy => {
    let result = CueThinkConfig.strategyVideos[strategy.name];

    if (!result) {
      const i = Object.keys(CueThinkConfig.strategyVideos).findIndex(key => $translate.instant(key) === strategy.name);

      if (i > -1)
        result = CueThinkConfig.strategyVideos[Object.keys(CueThinkConfig.strategyVideos)[i]];
    }

    return result;
  };

  $scope.onStrategyVideoButtonClick = strategy => {
    $scope.viewModel.videoStrategy = strategy;

    CueThink.logThinkletEvent('Strategy Video Clicked', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
      Used: strategy.name
    });
  };

  $scope.$on('CloseStrategyVideoPopUp', function () {
    $scope.viewModel.videoStrategy = null;

    digest();
  });
}

export default PlanController;

export function setUpPlanController(module) {
  module.directive('strategyVideoPopUp', require('./strategyVideoPopUp.directive').default);
}
