<template>
  <cuethink-pop-up
    class="strategy-video-pop-up"
    :showClose="true"
    :title="translate(strategy.name)"
    @close="onClose">
    <iframe
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      frameborder="0"
      height="320"
      width="480"
      :src="video">
    </iframe>
  </cuethink-pop-up>
</template>

<script>
export default {
  mixins: [require('core/common/mixins/translate.mixin').default],

  components: {
    CuethinkPopUp: require('core/common/cuethink-pop-up.vue').default,
  },

  props: {
    strategy: Object,
    video: String
  },

  methods: {
    onClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
  iframe {
    margin-top: 20px;
  }
</style>

<style>
  .strategy-video-pop-up .body {
    width: 675px !important;
    height: 440px !important;
  }
</style>