import module from './settings.module';
import './settingsDirective';
import '../login/loginDirective';
import '../login/forgotpassword/changePasswordDirective';
import './settings.css';
import tpl from './studentSettings.tpl.html';
require('./user-form.partial.html');
require('./password-form.partial.html');
import { CueThinkChangePassword } from 'core/login/forgotpassword/changePasswordDirective';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import './images/CueThinkAvatars_Ape.svg';
import './images/CueThinkAvatars_Ape2.svg';
import './images/CueThinkAvatars_Ape3.svg';
import './images/CueThinkAvatars_Chicken.svg';
import './images/CueThinkAvatars_Bear.svg';
import './images/CueThinkAvatars_Bird.svg';
import './images/CueThinkAvatars_Bull.svg';
import './images/CueThinkAvatars_Cat.svg';
import './images/CueThinkAvatars_Cobra.svg';
import './images/CueThinkAvatars_Deer.svg';
import './images/CueThinkAvatars_Dog.svg';
import './images/CueThinkAvatars_Duck.svg';
import './images/CueThinkAvatars_Elephant.svg';
import './images/CueThinkAvatars_Girafe.svg';
import './images/CueThinkAvatars_Koala.svg';
import './images/CueThinkAvatars_Lamb.svg';
import './images/CueThinkAvatars_Ox.svg';
import './images/CueThinkAvatars_Penguin.svg';
import './images/CueThinkAvatars_Pig.svg';
import './images/CueThinkAvatars_Rabit.svg';
import './images/CueThinkAvatars_Rhino.svg';
import './images/CueThinkAvatars_SpottedBear.svg';
import './images/CueThinkAvatars_Tiger.svg';
import './images/CueThinkAvatars_Wolf.svg';
import './images/CueThinkAvatars_Wolf_2.svg';
import './chooseColorThemeDirective';
import './images/Settings_EditIcon.svg';
import {setUserModesScopeMembers} from '../common/user-modes';
import permissionsManager, { Permissions } from '../permissions/permissions';

module.directive('studentSettings', function ($q, $translate, $rootScope) {
  return {
    restrict: 'E',
    template: tpl,
    link: function ($scope) {
      var result = cuethinkSettings.link($scope, $q, $translate, $rootScope);

      function bindEvent (el, event, handler) {
        el = $(el);
        el.on(event, handler);
        el.on('remove', function () {
          el.off(event, handler);
        });
      }

      translate('LINK_HTML', $translate, $rootScope, $scope, function (translation) {
        $scope.linkHtml = translation;

        $scope.$$postDigest(function () {
          bindEvent('.clever-link', 'click', function () {
            $scope.linkCleverAccount();
          });

          bindEvent('.google-link', 'click', function () {
            $scope.linkGoogleAccount();
          });
        });
      });

      return result;
    },
    scope: {
      changePassword: '=',
      colorThemes: '=',
      defaultAvatarColors: '=',
      fetchHasSeenTrialEndPopUp: '=',
      getTrialDays: '=',
      isCueteachEnabled: '=',
      joinClass: '=',
      leaveClass: '=',
      linkCleverAccount: '=',
      linkGoogleAccount: '=',
      logOut: '=logout',
      navigateDashboard: '=',
      navigateGallery: '=',
      navigateJoinClass: '=',
      navigateProfile: '=',
      navigateRubric: '=',
      navigateToTeacherDashboard: '=teacherDashboard',
      onSubmitMindWanderingProbe: '&',
      save: '=',
      setHasSeenTrialEndPopUp: '=',
      settings: '=',
      setUpNavigation: '=',
      showTrialEndPopUp: '&',
      tutorial: '=',
      user: '=',
      userManager: '='
    }
  };
});

var cuethinkSettings = {
  link: function ($scope, $q, $translate, $rootScope) {
    var user = $scope.user;

    function digest() {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    $scope.userSettings = {
      avatarUrl: user.avatarUrl,
      colorTheme: user.colorTheme ? user.colorTheme : 'DEFAULT',
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      username: user.username
    };

    var _originalSettings = Object.assign({}, $scope.userSettings);

    $scope.isStudent = "Student" === user.type;

    var views = [{
      name: 'User Settings',
      template: 'user-form.partial.html'
    }, {
      name: 'Change Password',
      template: 'password-form.partial.html'
    }];

    var USER_SETTINGS;

    translate(['USER_SETTINGS', 'CHANGE_PASSWORD'], $translate, $rootScope, $scope, function (translations) {
      views[0].name = USER_SETTINGS = translations['USER_SETTINGS'];
      views[1].name = translations['CHANGE_PASSWORD'];
    });

    $scope.showUserSettings = function () {
      $scope.view = views[0];
    };

    $scope.showChangePassword = function () {
      $scope.view = views[1];
    };

    $scope.showUserSettings();

    function saveClasses (callback) {
      var promises = [];

      if ($scope.classes) {
        for (var i = 0; i < $scope.classes.length; i++) {
          var cls = $scope.classes[i];

          if (cls.id) {
            if (!cls.invitationCode)
              promises.push($scope.leaveClass(cls));
          } else if (cls.invitationCode) {
            var promise = $scope.joinClass(cls.invitationCode);

            if (callback)
              callback(promise);

            promises.push(promise);
          }
        }
      }

      return $q.all(promises);
    }

    function isNameValid (name) {
      if (!name) {
      } else if (/[^A-Za-z\-' ]/.test(name)) {
      } else {
        return true;
      }

      return false;
    }

    function isUserNameValid (username) {
      if (!username) {
      } else if (/[^A-Za-z0-9 ]/.test(username)) {
      } else {
        return true;
      }

      return false;
    }

    $scope.saveSettings = function (showAlert) {
      if ($scope.isSaving)
        return;

      var isSettings = $scope.view.name == USER_SETTINGS;

      if (!isSettings && !CueThinkChangePassword.validatePassword($scope.userPassword.newPassword, $scope.userPassword.newPasswordRetype))
        return false;

      if ($scope.userSettings.firstName !== $scope.user.firstName && !isNameValid($scope.userSettings.firstName)) {
        $translate('FIRST_NAME_IS_NOT_VALID').then(function (msg) {
          alert(msg);
        });

        return false;
      }

      if ($scope.userSettings.lastName !== $scope.user.lastName && !isNameValid($scope.userSettings.lastName)) {
        $translate('LAST_NAME_IS_NOT_VALID').then(function (msg) {
          alert(msg);
        });

        return false;
      }

      var changedUsername = $scope.userSettings.username !== $scope.user.username;

      if (changedUsername && !isUserNameValid($scope.userSettings.username)) {
        $translate('USER_NAME_IS_NOT_VALID').then(function (msg) {
          alert(msg);
        });

        return false;
      }

      $scope.isSaving = true;

      var promise;

      if (isSettings) {
        if ($scope.user.type !== 'Student' && $scope.viewModel && !$scope.viewModel.isUS) {
          $scope.userSettings.zip = null;
          $scope.userSettings.schoolSearchId = null;
          $scope.userSettings.schoolSearchDistrict = null;
          $scope.userSettings.schoolSearchDistrictId = null;
        }

        var data = Object.assign({}, $scope.userSettings);
        data.avatar = data.avatarUrl;
        delete data.avatarUrl;
        data.theme = data.colorTheme;
        delete data.colorTheme;

        promise = $scope.save(data);
      } else {
        promise = $scope.changePassword($scope.userPassword.currentPassword, $scope.userPassword.newPassword, $scope.userPassword.newPasswordRetype);
      }

      return promise.then(function () {
        var isAddingClass;

        saveClasses(function () {
          isAddingClass = true;
        }).then(function (success) {
          if ($scope.classes && !(isAddingClass && user.type === 'Student')) {
            reloadClasses();

            digest();
          }

          if (isSettings) {
            _originalSettings = Object.assign({}, $scope.userSettings);

            if (showAlert !== false) {
              $translate('SETTINGS_SUCCESSFULLY_CHANGED').then(function (msg) {
                alert(msg);
              });
            }

            if (changedUsername) {
              $translate('YOU_HAVE_CHANGED_YOUR_USERNAME').then(function (msg) {
                alert(msg);
              });

              $scope.logOut(true);
            }

            if (user.type === "Student") {
              $scope.navigateDashboard();

              return;
            }
          } else {
            $translate('PASSWORD_SUCCESSFULLY_CHANGED').then(function (msg) {
              alert(msg);
            });

            $scope.logOut(true);
          }

          $scope.isSaving = false;
        });
      }, function (result) {
        alert($translate.instant(result));

        $scope.isSaving = false;
      });
    };

    //text fields are disabled until user clicks pencil
    $scope.inputDisabled = true;

    $scope.enableTextInput = function () {
      $scope.inputDisabled = false;
      $scope.editSettings = !$scope.editSettings;
    };

    $scope.userPassword = {
      currentPassword: "",
      newPassword: "",
      newPasswordRetype: ""
    };

    $scope.showDeleteAccountPopup = function () {
      $scope.deleteAccountPopup = true;
    };

    $scope.closeDeleteAccountPopup = function () {
      $scope.deleteAccountPopup = false;
    };

    function reloadClasses() {
      var classes = [];

      if (user.classes)
        for (var i = 0; i < user.classes.length; i++)
          classes.push(user.classes[i]);

        $translate('NEW_CLASS').then(function (value) {
          value = value.toUpperCase();

          for (var j = classes.length; j < 3; j++)
            classes.push({name: value});

          $scope.classes = classes;
        });
    }

    reloadClasses();

    $scope.cancelChangePassword = function () {
      $scope.showUserSettings();
    };

    setUserModesScopeMembers($scope);

    $scope.isChooseAvatarPopUpVisible = false;

    $scope.onAvatarButtonClick = function () {
      $scope.isChooseAvatarPopUpVisible = true;
    };

    $scope.onChooseAvatarClose = function () {
      $scope.isChooseAvatarPopUpVisible = false;
    };

    $scope.onChooseAvatarSubmit = function (url) {
      $scope.userSettings.avatarUrl = url;
      $rootScope.$broadcast('SetAvatar', url);

      $scope.isChooseAvatarPopUpVisible = false;
    };

    $scope.onDeleteAvatarLinkClick = function (e) {
      e.stopPropagation();
      e.preventDefault();

      $scope.userSettings.avatarUrl = null;

      $rootScope.$broadcast('SetAvatar', null);
    };

    $scope.isChooseColorThemePopUpVisible = false;

    $scope.onColorThemeButtonClick = function (e) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      $scope.isChooseColorThemePopUpVisible = true;
    };

    $scope.onChooseColorThemeSubmit = function (theme) {
      $scope.userSettings.colorTheme = theme;

      $scope.isChooseColorThemePopUpVisible = false;

      var data = Object.assign({}, _originalSettings);
      data.avatar = data.avatarUrl;
      delete data.avatarUrl;
      data.theme = theme;
      delete data.colorTheme;

      $scope.save(data);
    };

    $scope.onChooseColorThemeClose = function () {
      $scope.isChooseColorThemePopUpVisible = false;
    };

    $scope.getColorTheme = function () {
      return $scope.colorThemes.find(function (theme) { return $scope.userSettings.colorTheme === theme.name; })
    };

    $scope.getDefaultAvatarColors = function () {
      var i = $scope.user.id % ($scope.defaultAvatarColors.length - 1);

      return $scope.defaultAvatarColors[i];
    };

    $scope.isAvatarsVisible = function () {
      return permissionsManager.getPermission(Permissions.Avatars);
    };

    $scope.isColorThemesVisible = function () {
      return permissionsManager.getPermission(Permissions.Themes);
    };
  }
};

export default cuethinkSettings;
