import 'src/components/shared/thinklet_data';
import 'src/components/shared/create/create';
import './understand.css';
require('src/components/shared/create/create.partial.html');
import 'angular-translate';
import 'src/components/shared/cuethink';
import { Permissions } from 'core/permissions/permissions';

function UnderstandController ($scope, $controller, $stateParams, $state, $translate, CueThink, PermissionsManager) {
	"ngInject";

	if ($state.current.name === 'understand-by-id') {
	  $state.go('understand', { id: $stateParams.id });

	  return;
  }

	function populateNotices () {
		var items;

		items = [];

		if ($scope.thinklet.items && $scope.thinklet.items.known) {
			$.each($scope.thinklet.items.known, function (i, value) {
				items.push({ text: value });
			});
		}

		for (var i = items.length; i < 10; i++)
			items.push({});

		$scope.notices = items;

		if (!$scope.$$phase)
			$scope.$digest();
	}

	function thinkletLoadCallback (thinklet) {
		var items;

		populateNotices();

		items = [];

		if (thinklet.items && thinklet.items.wondering) {
			$.each(thinklet.items.wondering, function (i, value) {
				items.push({ text: value });
			});
		}

		for (var i = items.length; i < 10; i++)
			items.push({});

		$scope.wonders = items;

		if (!$scope.$$phase)
			$scope.$digest();
	}

	$controller('CreateController', { '$scope': $scope, '$stateParams': $stateParams, 'thinkletLoadCallback': thinkletLoadCallback });

	$scope.controller = name;

  const oldSave = $scope.save;

  function logUserInput(type, value) {
    return CueThink.logThinkletEvent('Explore input', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
      type,
      value
    });
  }

  $scope.save = data => {
    oldSave(data);

    if (typeof data.explore !== 'undefined') {
      const type = $translate.instant('THE_PROBLEM_IS_ASKING_ME_TO');
      const value = data.explore;

      logUserInput(type, value);
    }
  };

	$scope.saveWonders = function () {
		var items;

		// haven't loaded yet
		if ($scope.wonders === undefined)
			return;

		items = [];

		$.each($scope.wonders, function (i, value) {
			if (value && value.text)
				items.push(value.text);
		});

		$scope.thinklet.items.wondering = items;

		$scope.save({ items: $scope.thinklet.items });

    const type = $translate.instant('WHAT_I_WONDER_IS');
    const value = items.join('\n');

    logUserInput(type, value);
	};

	$scope.saveNotices = function () {
		var items;

		// haven't loaded yet
		if ($scope.wonders === undefined)
			return;

		items = [];

		$.each($scope.notices, function (i, value) {
			if (value && value.text)
				items.push(value.text);
		});

		$scope.thinklet.items.known = items;

		$scope.save({ items: $scope.thinklet.items });

    const type = $translate.instant('WHAT_I_NOTICE_IS');
    const value = items.join('\n');

    logUserInput(type, value);
	};

	$scope.onAddWords = function (phrases) {
		var changed;

		for (var i = 0; i < phrases.length; i++) {
			var found, phrase;

			phrase = phrases[i];

			found = false;

			var j;

			if ($scope.thinklet.items.known) {
				for (j = 0; j < $scope.thinklet.items.known.length; j++) {
					if ($scope.thinklet.items.known[j] && phrase.toLowerCase() == $scope.thinklet.items.known[j].trim().toLowerCase()) {
						found = true;

						break;
					}
				}
			}

			if (!found) {
				if ($scope.thinklet.items.known) {
					for (j = 0; j < $scope.thinklet.items.known.length; j++) {
						if (!$scope.thinklet.items.known[j]) {
							$scope.thinklet.items.known[j] = phrase;

							found = true;

							break;
						}
					}
				}

				if (!found) {
					if (!$scope.thinklet.items.known)
						$scope.thinklet.items.known = [];

					$scope.thinklet.items.known.push(phrase);
				}

				populateNotices();

				changed = true;
			}
		}

		if (changed)
			$scope.saveNotices();
	};

	$scope.getFirstEmptyNoticeIndex = () => {
	  return $scope.notices.findIndex(notice => !notice.text || notice.text.length === 0);
  };

	$scope.getFirstEmptyWonderIndex = () => {
    return $scope.wonders.findIndex(notice => !notice.text || notice.text.length === 0);
  };

	$scope.isExplore = function () {
	  return PermissionsManager.getPermission(Permissions.ExplorePhase);
  };
}

export default UnderstandController;
