import googleManager from 'core/google/google-manager';

export default function GoogleSignIn (authCallback, callback, availabilityCallback, forceAccountChooser) {
	var idToken;

	function authenticateGoogleUser (user, isMobile) {
		var success = function (success, usr) {
			callback(((typeof usr) == 'object') ? usr : user, success, ((typeof usr) == 'string') ? usr : null);
		};

		var promise = authCallback(user, isMobile, success);

		if (promise)
			promise.then(success);
		
		return promise;
	}

	function onError (error) {
		console.log(error);
	}

	this.signIn = function () {
		return googleManager.signIn(null, forceAccountChooser).then(function (result) {
			idToken = result.getAuthResponse().id_token;
			var email = result.getBasicProfile().getEmail();
			var name = result.getBasicProfile().getName();

			return authenticateGoogleUser({ token: idToken, email: email, name: name }, false);
		}, onError);
	};

	var me = this;

	if (availabilityCallback) {
		googleManager.isAvailable().then(function () {
			availabilityCallback(true, me.signIn);
		});
	} else {
		googleManager.isAvailable().then(function () {
			me.signIn();
		});
	}
}
