import { BaseWhiteboardTool, inherit } from './base-tool';
import TextTool from './text-tool';

export default function TableTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, rows, cols, color) {
      this.id = whiteboard.getNextId();
      this.type = TableTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.rows = rows;
      this.cols = cols;

      if (color)
        this.color = color;

      whiteboard.updateEventTime(this);
    };
  };

  this.isRotatable = function () {
    return false;
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.setStyles = function (obj) {
    var color = obj.color;

    if (!color)
      color = 'black';

    whiteboard.setStrokeColor(color);

    whiteboard.context.lineWidth = 1;
  };

  this.draw = function (obj) {
    TableTool.renderTable(whiteboard, obj.coordinates[0], obj.coordinates[1], obj.coordinates[2], obj.coordinates[3], obj.rows, obj.cols, obj.text, obj.color);
  };
}

TableTool.type = 'table';

inherit(TableTool, BaseWhiteboardTool);

TableTool.getBoundsForTableCell = function (whiteboard, sx, sy, ex, ey, rows, cols, row, col) {
  var lineWidth = whiteboard.getAbsoluteLineWidth();
  var halfLineWidth = lineWidth / 2;
  var width = (ex - sx) / cols;
  var height = (ey - sy) / rows;

  return {
    x: sx + (col * width) + halfLineWidth,
    y: sy + (row * height) + halfLineWidth,
    width: width - lineWidth,
    height: height - lineWidth
  };
};

TableTool.renderTableText = function (whiteboard, table, text, row, col, color, textSize, autoSizeText) {
  var sx = table.coordinates[0] * whiteboard.getWidth();
  var sy = table.coordinates[1] * whiteboard.getHeight();
  var ex = table.coordinates[2] * whiteboard.getWidth();
  var ey = table.coordinates[3] * whiteboard.getHeight();
  var x, y, adjustedTextSize;

  if (!color)
    color = 'black';

  var cellBounds = TableTool.getBoundsForTableCell(whiteboard, sx, sy, ex, ey, table.rows, table.cols, row, col);

  if (text) {
    var w = ex - sx;
    var h = ey - sy;
    var size = { width: w / table.cols, height: h / table.rows };

    if (!textSize)
      textSize = size.height / 3;

    var textBounds = TextTool.measureText(whiteboard, text, textSize, cellBounds.width, textSize);
    adjustedTextSize = textSize;

    if (autoSizeText !== false) {
      while (textBounds.height > size.height) {
        adjustedTextSize--;

        if (adjustedTextSize <= 0)
          break;

        textBounds = TextTool.measureText(whiteboard, text, adjustedTextSize, cellBounds.width, adjustedTextSize);
      }
    }

    if (textBounds.width > size.width)
      textBounds.width = size.width;

    if (textBounds.height > size.height)
      textBounds.height = size.height;

    x = cellBounds.x;
    y = cellBounds.y + ((cellBounds.height - textBounds.height) / 2);
  }

  var context = whiteboard.getContext();

  context.save();

  context.rect(cellBounds.x, cellBounds.y, cellBounds.width, cellBounds.height);
  context.clip();

  whiteboard.setFillColor('white');
  context.fillRect(cellBounds.x, cellBounds.y, cellBounds.width, cellBounds.height);

  if (text) {
    whiteboard.setStrokeColor(color);
    whiteboard.setFillColor(color);
    TextTool.wrapText(whiteboard, text, adjustedTextSize, x, y, cellBounds.width, adjustedTextSize, true);
  }

  context.restore();
};

TableTool.renderTable = function (whiteboard, sx, sy, ex, ey, rows, cols, text, color, textSize, callback) {
  var bounds = {
    left: sx * whiteboard.getWidth(),
    top: sy * whiteboard.getHeight(),
    right: ex * whiteboard.getWidth(),
    bottom: ey * whiteboard.getHeight(),
    width: (ex - sx) * whiteboard.getWidth(),
    height: (ey - sy) * whiteboard.getHeight()
  };

  var x, y;

  var context = whiteboard.getContext();

  context.lineWidth = 4;
  whiteboard.setStrokeColor('black');

  context.beginPath();
  context.rect(bounds.left, bounds.top, bounds.width, bounds.height);
  context.closePath();
  context.stroke();

  for (var i = 1; i < rows; i++) {
    y = bounds.top + ((i * bounds.height) / rows);

    context.beginPath();
    context.moveTo(bounds.left, y);
    context.lineTo(bounds.right, y);
    context.closePath();
    context.stroke();

    if (callback && callback(i - 1) === false)
      break;
  }

  for (i = 1; i < cols; i++) {
    x = bounds.left + ((i * bounds.width) / cols);

    context.beginPath();
    context.moveTo(x, bounds.top);
    context.lineTo(x, bounds.bottom);
    context.closePath();
    context.stroke();

    if (callback && callback(null, i - 1) === false)
      break;
  }

  if (text) {
    var rowKeys = Object.keys(text);

    var table = {
      coordinates: [sx, sy, ex, ey],
      rows: rows,
      cols: cols
    };

    for (i = 0; i < rowKeys.length; i++) {
      var row = rowKeys[i];
      var colKeys = Object.keys(text[row]);

      for (var j = 0; j < colKeys.length; j++) {
        var col = colKeys[j];

        TableTool.renderTableText(whiteboard, table, text[row][col], row, col, color, textSize);
      }
    }
  }

  return bounds;
};

export function TableText (whiteboard, tableId, text, row, col, color) {
  this.id = whiteboard.getNextId();
  this.tableId = tableId;
  this.type = 'tabletext';
  this.row = row;
  this.col = col;
  this.text = text;

  if (color)
    this.color = color;

  whiteboard.updateEventTime(this);
}
