import './cuethink';
import 'core/whiteboard/libs/imagepreloader.module';
import './thinklet_data';
import ThinkletLoader from 'core/common/thinkletloader';

angular.module('thinkletLoader', ['imagePreloader', 'thinkletManager', 'cuethink']).factory('ThinkletLoader', ['imagePreloader', 'ThinkletManager', 'CueThink', function ($imagePreloader, $thinkletManager, $cuethink) {
  "ngInject";

  ThinkletLoader.sharedInstance = new ThinkletLoader($imagePreloader, $thinkletManager, $cuethink);

  return ThinkletLoader.sharedInstance;
}]);
