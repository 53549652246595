import reflectionTpl from './rubricReflection.tpl.html';
import './rubricReflection.css';
import 'core/common/cuethinkPopUpDirective';
import { getLocalizedProblemTitle } from '../common/translation-helpers';
import 'angular-translate';

export default function () {
	return {
		link: function ($scope, $translate) {
			"ngInject";

			var strategyList;

			if (!$scope.reflection)
				$scope.reflection = {};

			if ($scope.reflection.strategies) {
				$scope.reflection.strategies.sort(function (a, b) {
					return a.name > b.name;
				});
			} else {
				if (strategyList) {
					$scope.reflection.strategies = [];

					for (var i = 0; i < strategyList.length; i++)
						$scope.reflection.strategies.push({ name: strategyList[i].name, selected: strategyList[i].selected });
				} else {
					$scope.loadStrategies().then(function (strategies) {
						$scope.reflection.strategies = [];
						strategyList = [];

						for (var i = 0; i < strategies.length; i++) {
							$scope.reflection.strategies.push({ name: strategies[i], selected: false });
							strategyList.push({ name: strategies[i], selected: false });
						}

						$scope.reflection.strategies.sort(function (a, b) {
							return a.name > b.name;
						});

						strategyList.sort(function (a, b) {
							return a.name > b.name;
						});
					});
				}
			}

			clear();

			$scope.save = function (isShared) {
				var strategies = $scope.reflection.strategies;

				if ($scope.otherStrategy.name) {
					strategies.push($scope.otherStrategy);
					$scope.otherStrategy = { name: '', selected: false };
				}

				var data = {
					notes: $scope.reflection.notes,
					goals: $scope.reflection.goals,
					misconceptions: $scope.reflection.misconceptions,
					'strategies': strategies
				};

				$scope.onSubmit({ assignment: $scope.assignment, problem: $scope.problem, data: data, isShared: !!isShared });
			};

			$scope.share = function () {
				$scope.save(true);
			};

			$scope.done = function () {
				$scope.save();
			};

			function clear() {
				$scope.goals = $scope.notes = $scope.misconceptions = null;

				if ($scope.strategies)
					for (var i = 0; i < $scope.strategies.length; i++)
						$scope.strategies[i].selected = false;

				$scope.otherStrategy = { name: null, selected: false };
			}

			$scope.getProblemTitle = getLocalizedProblemTitle.bind(this, $translate);
		},
		scope: {
			assignment: '=',
			loadStrategies: '=',
			onClose: '&',
			onSubmit: '&',
			problem: '=',
			reflection: '='
		},
		template: reflectionTpl
	};
}
