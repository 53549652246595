import 'src/components/shared/thinkletloader';
import 'src/components/shared/create/create';
import 'core/whiteboard/whiteboardplayer.module';
import 'src/components/shared/thinklet_data';
import 'core/common/autosave';
import './review.css';
require('src/components/shared/create/create.partial.html');
require('src/components/shared/create/question_plan.partial.html');
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';

function ReviewController ($scope, $controller, $stateParams, ThinkletManager, $state, $translate, $rootScope, CueThink) {
		"ngInject";

		var $thinkletManager = ThinkletManager;

		var defaultRecordingChecklistTranslationIds;

		if ($scope.isNewDesign()) {
      defaultRecordingChecklistTranslationIds = ['MY_VOICE_CAN_BE_HEARD', 'MY_WORK_IS_ORGANIZED'];

      if ($scope.isEF23User() || $scope.isCuethinkV2User())
        defaultRecordingChecklistTranslationIds.push('I_EXPLAINED_MY_THINKING');
    } else {
      defaultRecordingChecklistTranslationIds = ['MY_VOICE_IS_LOUD_AND_CLEAR', 'MY_WRITING_IS_CLEAR', 'THERE_IS_NO_DISTRACTING_BACKGROUND_NOISE', 'I_USED_THE_HIGHLIGHTER'];
    }

		var defaultRecordingChecklist = {};

		function setDefaultRecordingChecklist () {
			var translationIds = Object.keys(defaultRecordingChecklist);

			if ($scope.thinklet.complexItems.recording_checklist && $scope.thinklet.complexItems.recording_checklist.length > 0) {
				$scope.thinklet.complexItems.recording_checklist.forEach(function (item, i) {
					item.name = defaultRecordingChecklist[translationIds[i]];
				});
			} else {
				var checklist;

				checklist = [];

				for (var i = 0; i < translationIds.length; i++)
					checklist.push({ name: defaultRecordingChecklist[translationIds[i]], status: 'false' });

				$scope.thinklet.complexItems.recording_checklist = checklist;
			}
		}

		function translateDefaultRecordingChecklist () {
			translate(defaultRecordingChecklistTranslationIds, $translate, $rootScope, $scope, function (translations) {
				defaultRecordingChecklist = translations;

				setDefaultRecordingChecklist();
			});
		}

		var parentController, _shouldLoadRecording;

		$scope.getChecklistItemName = function (item) {
			return $translate.instant(item.name);
		};

		function thinkletLoadCallback (thinklet) {
			if (!thinklet.complexItems)
				thinklet.complexItems = {};

			if (!thinklet.complexItems.math_checklist && thinklet.problem.mathChecklist) {
				var checklist = [];
				var items = thinklet.problem.mathChecklist;

				for (var i = 0; i < items.length; i++) {
					var item = items[i];

					checklist.push({ name: item, status: 'false' });
				}

				thinklet.complexItems.math_checklist = checklist;
			}

			translateDefaultRecordingChecklist();

			if (parentController)
				parentController.loadThinkletRecording(thinklet);
			else
				_shouldLoadRecording = true;
		}

		parentController = $controller('CreateController', { '$scope': $scope, '$stateParams': $stateParams, 'thinkletLoadCallback': thinkletLoadCallback });

		if (_shouldLoadRecording)
			parentController.loadThinkletRecording($scope.thinklet);

		$scope.controller = name;

		$scope.saveChecklist = (item, type) => {
			$scope.save({ complexItems: $scope.thinklet.complexItems });

      logUserInput(type + ' checklist', item.name);
		};

		$scope.saveMathChecklist = item => {
      $scope.saveChecklist(item, 'math');
    };

		$scope.saveRecordingChecklist = item => {
      $scope.saveChecklist(item, 'recording');
    };

		$scope.submit = function (isPrivate) {
			$scope.triedToSubmit = true;

			if (!$scope.thinklet.answer.answer)
				$('.answer-container input:first').focus();

			if (!$scope.thinklet.videoURL && (!$scope.events || $scope.events.length === 0)) {
				$translate('PLEASE_GO_BACK_TO_THE_SOLVE_STEP').then(function (msg) {
					alert(msg);
				});

				return;
			}

			if (!$scope.thinklet.answer.answer)
				return;

			$scope.thinklet.classes = [$scope.thinklet.assignment_class_id];

			$thinkletManager.publishThinklet($scope.thinklet, isPrivate, function () {
				$state.go('browse');
			});
		};

		$scope.onStatusChange = function (status) {
			if (status == 'start playback') {
        status = 'Start';

        logUserInput('Watch your recording');
      } else if (status == 'pause playback')
				status = 'Pause';
			else if (status == 'seek playback')
				status = 'Cue';
			else if (status == 'end playback')
				status = 'Autostop';
			else
				return;
		};

		const oldSave = $scope.save;

		$scope.save = data => {
      oldSave(data);

		  let type, value;

		  if (data.answer) {
        type = 'Based on my work, I think a solution is:';
        value = data.answer;
      }

		  if (type && value)
        logUserInput(type, value);
    };

		function logUserInput(type, value) {
      return CueThink.logThinkletEvent('Review input', $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
        type,
        value
      });
    }
}

export default ReviewController;
