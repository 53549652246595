import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import './educatorGuide.css';
import tpl from './educatorGuideDirective.tpl.html';

function EducatorGuideDirective () {
    "ngInject";

    var linkFunction = function ($scope) {
        $scope.navigate = function (guide) {
            $scope.viewGuide({ guide: guide });
        };
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: TeacherScreenDirective.createScope({
            guides: '=',
            viewGuide: '&'
        })
    };
}

angular.module('cuethink.educatorguide', ['cuethink.teacherscreen']).directive('educatorguide', EducatorGuideDirective);
