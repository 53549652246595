import 'ng-infinite-scroll/build/ng-infinite-scroll.min';
import '../thinklet/thinklet_cell';
import '../navigation/navbar';
import './gallery.css';
import tpl from './galleryDirective.tpl.html';
import 'core/common/cuethinkCheckboxDropdownDirective';
import 'angular-translate';
import 'src/images/Delete_Tag_X.png';
import { setUserModesScopeMembers } from '../common/user-modes';
import setUpFacilitatorGuide from '../facilitatorGuide/facilitatorGuideSetup';
import '../facilitatorGuide/facilitatorGuideDirective';
import LogManager from '../logging/log-manager';
require('./browse_grid.partial.html');
require('./browse_list.partial.html');

angular.module('cuethink.gallery', ['cuethink.thinklet', 'cuethink.navigation', 'infinite-scroll', 'cuethink.common', 'pascalprecht.translate']).directive('gallery', function ($translate) {
  return {
    restrict: 'E',
    transclude: true,
    template: tpl,
    link: function ($scope) {
      var start = 0;
      var limit = 24;
      var draftsOnly = '';

      $scope.thinklets = [];

      $scope.getSelectedClasses = function () {
        return $scope.viewModel.classes.filter(function (cls) {
          return cls.selected;
        });
      };

      function getSelectedClassIds() {
        return $scope.getSelectedClasses().map(cls => cls.id);
      }

      $scope.viewModel = {
        classes: $scope.user.classes.slice(0).map(function (cls) {
          cls.selected = false;

          return cls;
        }),
        isClassesVisible: false,
        labels: [],
        loadedSettings: false,
        nameFormat: null,
        problems: [],
        strategies: [],
        students: [],
        translations: {}
      };

      if ($scope.user.selectedClass) {
        const selectedClassIds = [$scope.user.selectedClass.id];

        selectedClassIds.forEach(id => {
          var selectedClass = $scope.viewModel.classes.find(function (cls) {
            return cls.id === id;
          });

          if (selectedClass)
            selectedClass.selected = true;
        });
      }

      saveClassIds();

      $translate(['CLASS_NAME', 'STUDENT', 'PROBLEM', 'TAG']).then(function (translations) {
        $scope.viewModel.translations = translations;
      });

      var _thinkletPrivacySetting, _userThinklets;

      function filterThinklets (thinklets) {
        return thinklets.filter(function (thinklet) {
          if (_thinkletPrivacySetting && _thinkletPrivacySetting === 'submitted' && _userThinklets && thinklet.problemId) { // skip sample thinklets from tutorial
            var found = _userThinklets.find(function (t) {
              return t.problemId === thinklet.problemId;
            });

            if (!found || found.status !== 'completed')
              return false;
          }

          return true;
        });
      }

      $scope.loadGlobalSettings().then(function (settings) {
        $scope.viewModel.nameFormat = settings.nameFormat;
        _thinkletPrivacySetting = settings.thinkletPrivacySetting;

        if (_thinkletPrivacySetting && _thinkletPrivacySetting === 'submitted') {
          $scope.loadUserThinklets($scope.user.id).then(function (data) {
            _userThinklets = data;

            $scope.thinklets = filterThinklets($scope.thinklets);

            $scope.viewModel.loadedSettings = true;
          });
        } else {
          $scope.viewModel.loadedSettings = true;
        }
      });

      function digest() {
        if ($scope.$$phase)
          return;

        try {
          $scope.$digest();
        } catch (e) {
        }
      }

      var filtersLoaded;

      var success = function (data) {
        var i, items;

        var sortName = function (a, b) {
          var firstItem = a.name.toLowerCase();
          var secondItem = b.name.toLowerCase();

          return firstItem == secondItem ? 0 : +(firstItem > secondItem) || -1;
        };

        items = [];

        for (i = 0; i < data.fullnamelist.firstnamelist.length; i++) {
          items.push({
            name: data.fullnamelist.firstnamelist[i] + ' ' + data.fullnamelist.lastnamelist[i],
            firstName: data.fullnamelist.firstnamelist[i],
            lastName: data.fullnamelist.lastnamelist[i],
            selected: false,
            type: 'name'
          });
        }

        items.sort(sortName);

        $scope.viewModel.students = items;

        items = [];

        for (i = 0; i < data.problemlist.length; i++) {
          if (data.problemlist[i]) {
            items.push({
              name: data.problemlist[i],
              selected: false,
              type: 'problem'
            });
          }
        }

        items.sort(sortName);

        $scope.viewModel.problems = items;

        items = [];

        for (i = 0; i < data.labellist.length; i++) {
          items.push({
            name: data.labellist[i],
            selected: false,
            type: 'label'
          });
        }

        items.sort(sortName);

        $scope.viewModel.labels = items;

        filtersLoaded = true;

        $scope.isNameFilterLoading = $scope.isProblemFilterLoading = $scope.isLabelFilterLoading = false;

        digest();
      };

      $scope.isNameFilterLoading = $scope.isProblemFilterLoading = $scope.isLabelFilterLoading = true;

      var promise = $scope.loadGalleryFilterList(success);

      if (promise)
        promise.then(success);

      function generateThinklets() {
        start = 0;
        _hasReachedEndOfResults = false;
        $scope.thinklets = [];

        $scope.generateMoreThinklets();
      }

      $scope.isLoading = function () {
        return $scope.loading;
      };

      var _hasReachedEndOfResults;

      $scope.generateMoreThinklets = function () {
        if (_hasReachedEndOfResults)
          return;

        $scope.loading = true;

        $scope.loadFilteredThinklets(
            start,
            limit,
            $scope.getSelectedStudents(),
            $scope.getSelectedClasses(),
            $scope.getSelectedProblems(),
            $scope.getSelectedLabels().map(function (label) { return label.name; }),
            $scope.getSelectedStrategies(),
            draftsOnly
        ).then(function (data) {
          if (data.length === 0) {
            _hasReachedEndOfResults = true;
          } else {
            var thinklets = filterThinklets(data);

            for (var i = 0; i < thinklets.length; i++)
              $scope.thinklets.push(thinklets[i]);
          }

          $scope.loading = false;
        });

        start++;
      };

      $scope.filterThinklets = function (key) {
        $scope.loading = false;

        var items;

        if (key.type == 'name')
          items = $scope.viewModel.students;

        if (key.type == 'problem')
          items = $scope.viewModel.problems;

        if (key.type == 'label')
          items = $scope.viewModel.labels;

        if (key.type == 'strategy')
          items = $scope.viewModel.strategies;

        if (items) {
          items.forEach(function (item) {
            item.selected = item === key;
          });
        }

        generateThinklets();
      };

      function saveClassIds() {
        const classes = $scope.getSelectedClasses();

        if (classes.length > 0 && (!$scope.user.selectedClass || $scope.user.selectedClass.id !== classes[0].id))
          $scope.selectClass(classes[0]);
      }

      $scope.removeClassFilter = function (cls) {
        cls.selected = false;

        saveClassIds();

        generateThinklets();
      };

      $scope.removeStudentFilter = function (student) {
        student.selected = false;

        generateThinklets();
      };

      $scope.removeProblemFilter = function (problem) {
        problem.selected = false;

        generateThinklets();
      };

      $scope.removeLabelFilter = function (label) {
        label.selected = false;

        generateThinklets();
      };

      $scope.removeStrategyFilter = function (strategy) {
        strategy.selected = false;

        generateThinklets();
      };

      $scope.resetFilter = function (event) {
        var filterItems = [$scope.viewModel.students, $scope.viewModel.problems, $scope.viewModel.labels, $scope.viewModel.strategies];

        if ($scope.isNewDesign())
          filterItems.push($scope.viewModel.classes);

        filterItems.forEach(function (items) {
          items.forEach(function (item) {
            item.selected = false;
          });
        });

        saveClassIds();

        draftsOnly = "";

        generateThinklets();

        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }

        return true;
      };

      $scope.getSelectedStudents = function () {
        return $scope.viewModel.students.filter(function (cls) {
          return cls.selected;
        });
      };

      $scope.getSelectedProblems = function () {
        return $scope.viewModel.problems.filter(function (cls) {
          return cls.selected;
        });
      };

      $scope.getSelectedLabels = function () {
        return $scope.viewModel.labels.filter(function (cls) {
          return cls.selected;
        });
      };

      $scope.getSelectedStrategies = function () {
        return $scope.viewModel.strategies.filter(function (cls) {
          return cls.selected;
        });
      };

      $scope.toggleFilter = function (key, e, data) {
        var items;

        $scope.openFilter = key;

        if (key == 'name')
          items = $scope.viewModel.students;
        else if (key == 'problem')
          items = $scope.viewModel.problems;
        else if (key == 'label')
          items = $scope.viewModel.labels;
        else if (key == 'strategy')
          items = $scope.viewModel.strategies;

        if ($scope.filterList == items) {
          $scope.filterList = null;
        } else {
          if (items && items.length > 0)
            $scope.filterList = items;
          else
            $scope.filterList = null;
        }

        //prevent close filter pane on menu click
        if (!e)
          e = window.event;

        e.cancelBubble = true;

        if (e.stopPropagation)
          e.stopPropagation();
      };

      $scope.getFilterParams = function () {
        if ($scope.filterList == 'name')
          return $scope.viewModel.students;
        else if ($scope.filterList == 'userProblems')
          return $scope.viewModel.problems;
        else if ($scope.filterList == 'label')
          return $scope.viewModel.labels;
        else
          return null;
      };

      $scope.views = [{
        name: 'Grid',
        template: 'browse_grid.partial.html'
      }, {
        name: 'List',
        template: 'browse_list.partial.html'
      }];

      $scope.selected = $scope.views[0];
      $scope.view = $scope.selected.template;

      //closes the filter tab on page click
      $scope.pageClick = function () {
        $scope.filterList = null;
      };

      $scope.switchView = function (view) {
        $scope.view = view.template;
        $scope.selected = view;
      };

      $scope.selectedView = function (view) {
        return view === $scope.selected ? 'selected' : undefined;
      };

      $scope.isLoading = function () {
        return $scope.loading;
      };

      $scope.isLabelFilterVisible = function () {
        return $scope.isCueteachEnabled && $scope.viewModel.labels && $scope.viewModel.labels.length > 0;
      };

      $scope.chooseClass = function (cls) {
        $scope.selectClass(cls);

        $scope.viewModel.isClassesVisible = false;

        generateThinklets();
      };

      $scope.toggleClasses = function () {
        $scope.viewModel.isClassesVisible = !$scope.viewModel.isClassesVisible;

        if ($scope.viewModel.isClassesVisible) {
          $scope.$$postDigest(function () {
            var button = $('navbar .class-chooser:first');
            var list = $('navbar ul.class-list:first');
            var offset = button.offset();

            $('dashboard:first').append(list);
            list.css('left', offset.left + 'px');
            list.css('top', offset.top + button.height() + 'px');
          });
        }
      };

      $scope.getFilterItemLabel = function (item) {
        return item.name;
      };

      $scope.onToggleFilterItem = function () {
        saveClassIds();

        generateThinklets();
      };

      $scope.viewThinklet = function (thinklet) {
        LogManager.getShared().logPageEvent('view thinklet', $scope.state, window.location.pathname, null, null, thinklet.id, thinklet.title);

        $scope.selectThinklet(thinklet);
      };

      if ($scope.user.type !== 'Student')
        setUpFacilitatorGuide($scope, $scope.teacherManager, $scope.userManager, $scope.state, null, $scope.user, $scope.colorThemes);

      setUserModesScopeMembers($scope);
    },

    scope: {
      colorThemes: '=',
      dashboard: '=',
      dashboardState: '=',
      defaultAvatarColors: '=',
      fetchHasSeenTrialEndPopUp: '=',
      gallery: '=',
      galleryState: '=',
      getTrialDays: '=',
      isCueteachEnabled: '=',
      loadFilteredThinklets: '=',
      loadGalleryFilterList: '=',
      loadGlobalSettings: '=',
      loadUserThinklets: '=',
      logOut: '=logout',
      navigateJoinClass: '=',
      navigateRubric: '=',
      navigateToTeacherDashboard: '=teacherDashboard',
      onSubmitMindWanderingProbe: '&',
      profile: '=',
      profileState: '=',
      selectClass: '=',
      selectThinklet: '=',
      setHasSeenTrialEndPopUp: '=',
      settings: '=',
      setUpNavigation: "=",
      state: '=',
      teacherManager: '=',
      tutorial: '=',
      user: '=',
      userManager: '='
    }
  };
});
