import NotificationCenter from '../notifications/notification-center';

export const ROUTE_NOTIFICATION = 'route';

export default function RouteManager() {
  let currentRoute;

  this.getCurrentRoute = () => {
    return currentRoute;
  };

  this.route = (route, data) => {
    currentRoute = {
      name: route,
      data
    };

    NotificationCenter.getShared().post(ROUTE_NOTIFICATION, currentRoute);
  };

  this.setCurrentRoute = route => {
    currentRoute = route;
  };
}

RouteManager.sharedInstance = null;

RouteManager.getSharedInstance = () => {
  if (!RouteManager.sharedInstance)
    RouteManager.sharedInstance = new RouteManager();

  return RouteManager.sharedInstance;
};
