import './form.css';

export default function (module) {
    module.directive('uiAutoFocus', function ($timeout) {
        var linkFunction = function ($scope, $elm, $attrs) {

            $timeout(function () {
                $($elm).focus();
            });
        };

        return {
            restrict: "A",
            link: linkFunction
        };
    });
};
