import 'core/learning-hub/learningHubDirective';
import '../navigation/teachernavbarController';
import 'src/components/shared/teacher_data';
import { setUserModesScopeMembers } from 'core/common/user-modes';

function LearningHubController ($scope, TeacherManager, $controller, $state, $stateParams) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  setUserModesScopeMembers($scope);

  if ($scope.isNewDesign()) {
    $scope.section = $stateParams.section;

    $scope.$on('select-section', (event, section) => {
      $scope.section = section ? section.title : null;

      $state.go('.', { section: section ? section.title : null });
    });

    return;
  }

  $scope.loadUnits = TeacherManager.loadLearningHubUnits;
  $scope.saveUnit = TeacherManager.saveLearningHubUnit;

  $scope.trackEvent = function (name, data) {
    try {
      Intercom('trackEvent', name, data);
    } catch (e) {
    }
  };

  $scope.unitId = $stateParams.unitId;

  $scope.onUnitChange = function (unit) {
    $state.go('.', { unitId: unit.id });
  };
}

export default LearningHubController;
