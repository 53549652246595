import 'core/rubric/rubric.module';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/user_data';
import 'src/components/shared/base';
import 'core/rubric/rubricOverviewDirective';

function RubricOverviewController ($scope, $controller, $state, ThinkletManager, UserManager) {
    "ngInject";

    $controller('BaseController', { '$scope': $scope });

    $scope.loadRubrics = ThinkletManager.loadRubrics;
    $scope.selectClass = UserManager.selectClass;

    $scope.viewThinklet = function (thinklet) {
        $state.go('view', { id: thinklet.id, studentRubric: true });
    };
}

export default RubricOverviewController;
