import { BaseWhiteboardTool } from './base-tool';

export default function AlgebraTileTool(whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, type) {
      this.id = whiteboard.getNextId();
      this.type = AlgebraTileTool.type;
      this.label = type;

      var w, h, ex, ey;

      var size = AlgebraTileTool.getSize(type);
      w = size.width;
      h = size.height;

      ex = sx + (w / whiteboard.getWidth());
      ey = sy + (h / whiteboard.getHeight());

      this.coordinates = [sx, sy, ex, ey];

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isTransformable = function () {
    return false;
  };

  this.isRotatable = function () {
    return true;
  };

  this.hasSelectionBorder = function () {
    return false;
  };

  this.setStyles = function (obj) {
    var strokeColor, fillColor;

    strokeColor = 'black';

    if (obj.label === '1') {
      fillColor = '#ffe673';
    } else if (obj.label === '-1' || obj.label === '-x' || obj.label === '-x^2') {
      fillColor = '#dd6b5a';
    } else if (obj.label === 'x') {
      fillColor = '#7bab59';
    } else if (obj.label === 'x^2') {
      fillColor = '#142b7e';
    }

    whiteboard.setStrokeColor(strokeColor);
    whiteboard.setFillColor(fillColor);
    whiteboard.setLineWidth(0.5);
    whiteboard.getContext().font = '12px Museo Sans';
  };

  function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke === 'undefined') {
      stroke = true;
    }

    if (typeof radius === 'undefined') {
      radius = 5;
    }

    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};

      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

    if (fill) {
      ctx.fill();
    }

    if (stroke) {
      ctx.stroke();
    }
  }

  this.draw = function (obj) {
    var sx, sy, ex, ey, w, h;

    sx = obj.coordinates[0] * whiteboard.getWidth();
    sy = obj.coordinates[1] * whiteboard.getHeight();
    ex = obj.coordinates[2] * whiteboard.getWidth();
    ey = obj.coordinates[3] * whiteboard.getHeight();

    var context = whiteboard.getContext();

    w = ex - sx;
    h = ey - sy;
    context.fillRect(sx, sy, w, h);
    context.strokeRect(sx, sy, w, h);

    roundRect(context, sx, sy, w, h, 2, true, true);

    var textWidth = context.measureText(obj.label).width;
    var textHeight = 12;

    if (obj.label === '1')
      whiteboard.setFillColor('black');
    else
      whiteboard.setFillColor('white');

    var textXOffset;
    var textYOffset = 0;

    if (obj.label === 'x^2' || obj.label === '-x^2') {
      textXOffset = 4;

      var baseText = obj.label.substr(0, obj.label.indexOf('^2'));
      var superText = obj.label.substr(obj.label.indexOf('^2') + 1);

      context.fillText(baseText, sx + ((w - textWidth) / 2) + textXOffset, sy + ((h - textHeight) / 2) + textHeight + textYOffset, 1000, textHeight);

      whiteboard.getContext().font = '8px Museo Sans';
      context.fillText(superText, sx + ((w - textWidth) / 2) + (textWidth / 2) + textXOffset, sy + ((h - textHeight) / 2) + (textHeight / 2) + textYOffset, 1000, textHeight / 2);
    } else {
      textXOffset = 0;

      context.fillText(obj.label, sx + ((w - textWidth) / 2) + textXOffset, sy + ((h - textHeight) / 2) + textHeight + textYOffset, 1000, textHeight);
    }
  };
}

AlgebraTileTool.type = 'algebratile';

AlgebraTileTool.getSize = function (type) {
  var w, h;

  if (type === '1' || type === '-1') {
    w = h = AlgebraTileTool.SHORT_LENGTH;
  } else if (type === 'x' || type === '-x') {
    w = AlgebraTileTool.SHORT_LENGTH;
    h = AlgebraTileTool.LONG_LENGTH;
  } else if (type === 'x^2' || type === '-x^2') {
    w = h = AlgebraTileTool.LONG_LENGTH;
  }

  return {
    width: w,
    height: h
  };
}

AlgebraTileTool.SHORT_LENGTH = 22;
AlgebraTileTool.LONG_LENGTH = 88;
