import {
  isCueCollabUser,
  isCuethinkV2User,
  isEFUser,
  isEF23User,
  isNewDesign,
  isEFLiteUser
} from 'core/common/user-modes';

function createEnumObject(obj) {
  let i = 0;

  Object.keys(obj).forEach(key => obj[key] = i++);

  return Object.freeze(obj);
}

// IDs don't matter because they will get set below
export const Permissions = createEnumObject({
  AnnotationsRatings: 1,
  AssignedWorkContextMenu: 2,
  Avatars: 3,
  BeliefsProbe: 4,
  Chat: 5,
  CloneProblem: 0,
  CRProblems: 6,
  DashboardV2: 7,
  EFTour: 8,
  EquityTour: 9,
  ExplorePhase: 10,
  FacilitatorGuide: 11,
  FourPhaseMenu: 12,
  GalleryAnimatedHelper: 13,
  IMProblems: 14,
  Languages: 15,
  LearningHubV2: 16,
  MetaCognitiveHelper: 0,
  MindWanderingProbe: 17,
  EducatorReportsV2: 18,
  StrategyCustomization: 19,
  PositiveMindsetMessages: 19,
  ReflectionProbe: 20,
  ReviewPhaseV2: 20,
  Rubric: 22,
  ScaffoldedPlanningJournal: 23,
  SentenceStarterCustomization: 0,
  SmartDesign: 0,
  SolvePlanDrawer: 24,
  SRLDetectors: 25,
  StrategyVideos: 0,
  StudentSettings: 26,
  Tags: 27,
  TagsV2: 28,
  TeacherAnimatedHelper: 29,
  Themes: 30,
  WhiteboardToolCustomization: 0,
  WorkingMemoryHelperText: 31
});

export function PermissionsManager() {
  const permissions = {};

  this.clearPermissions = function () {
    Object.values(Permissions).forEach(permission => permissions[permission] = false);
  };

  this.clearPermissions();

  this.getPermission = function (id) {
    return permissions[id];
  };

  this.setPermission = function (id, value) {
    permissions[id] = value;
  };

  this.setPermissions = function (user) {
    if (!user) {
      this.clearPermissions();

      return;
    }

    if (isEFUser(user) || isEFLiteUser(user) || isEF23User(user) || isCuethinkV2User(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.AssignedWorkContextMenu, true);
    }

    this.setPermission(Permissions.Avatars, true);

    if (isEFUser(user) || isEF23User(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.Chat, true);
    }

    if ((isNewDesign(user) || isCueCollabUser(user)) && !isCuethinkV2User(user)) {
      this.setPermission(Permissions.EFTour, true);
      this.setPermission(Permissions.EquityTour, true);
    }

    if (isNewDesign(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.ExplorePhase, true);
      this.setPermission(Permissions.FourPhaseMenu, true);
    }

    if (isEF23User(user) || isCuethinkV2User(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.GalleryAnimatedHelper, true);
    }

    this.setPermission(Permissions.Languages, true);

    if (isNewDesign(user)) {
      this.setPermission(Permissions.LearningHubV2, true);
    }

    if (user.cueteachEnabled && (true || !isEF23User(user) || isCuethinkCollab(user))) {
      this.setPermission(Permissions.Rubric, true);
    }

    if (isNewDesign(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.SolvePlanDrawer, true);
    }

    this.setPermission(Permissions.Themes, true);

    if (isEF23User(user) || isCuethinkV2User(user) || isCueCollabUser(user)) {
      this.setPermission(Permissions.TeacherAnimatedHelper, true);
    }

    if (user.isCueCollabAccount) {
      const permissions = [
        Permissions.AnnotationsRatings,
        Permissions.AssignedWorkContextMenu,
        Permissions.Avatars,
        Permissions.BeliefsProbe,
        Permissions.Chat,
        Permissions.DashboardV2,
        Permissions.ExplorePhase,
        Permissions.FacilitatorGuide,
        Permissions.FourPhaseMenu,
        Permissions.GalleryAnimatedHelper,
        Permissions.Languages,
        Permissions.LearningHubV2,
        Permissions.PositiveMindsetMessages,
        Permissions.Rubric,
        Permissions.ScaffoldedPlanningJournal,
        Permissions.SolvePlanDrawer,
        Permissions.SRLDetectors,
        Permissions.StudentSettings,
        Permissions.Tags,
        Permissions.TagsV2,
        Permissions.TeacherAnimatedHelper,
        Permissions.Themes,
        Permissions.WorkingMemoryHelperText
      ];

      permissions.forEach(perm => {
        this.setPermission(perm, true);
      });
    }

    if (user.username === 'demoteacher1' || user.username === 'demostudentone' || user.username === 'demostudenttwo' || user.username === 'demostudentthree')
      this.setPermission(Permissions.BeliefsProbe, false);

    this.setPermission(Permissions.SentenceStarterCustomization, true);

    if (user.efAccountGroup) {
      this.setPermission(Permissions.CloneProblem, false);
      this.setPermission(Permissions.SmartDesign, true);
      this.setPermission(Permissions.WhiteboardToolCustomization, !(user.efAccountGroup === 200 || user.efAccountGroup === 211 || user.efAccountGroup === 212 || user.efAccountGroup === 221 || user.efAccountGroup === 222));

      if (user.efAccountGroup === 112 || user.efAccountGroup === 122 || user.efAccountGroup === 212 || user.efAccountGroup === 222)
        this.setPermission(Permissions.MetaCognitiveHelper, true);

      this.setPermission(Permissions.ReflectionProbe, true);
      this.setPermission(Permissions.StrategyCustomization, false);
      this.setPermission(Permissions.StrategyVideos, user.efAccountGroup === 200 || user.efAccountGroup === 211 || user.efAccountGroup === 212 || user.efAccountGroup === 221 || user.efAccountGroup === 222);
    } else {
      this.setPermission(Permissions.CloneProblem, true);
      this.setPermission(Permissions.StrategyCustomization, true);
      this.setPermission(Permissions.WhiteboardToolCustomization, true);
    }
  };
}

const permissionsManager = new PermissionsManager();

export default permissionsManager;

angular.module('cuethink.permissions', []).constant('PermissionsManager', permissionsManager);
