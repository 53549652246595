import module from '../login.module';
import '../loginDirective';
import './forgotpassword.css';
import tpl from './changePasswordDirective.tpl.html';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';

function ChangePasswordDirective ($translate) {
  return {
    restrict: 'E',
    template: tpl,
    link: function ($scope) {
      if (!$scope.userPassword)
        $scope.userPassword = {};

      if ($scope.changePassword) {
        $scope.submit = function (currentPassword, newPassword, newPasswordRetype) {
          if (!CueThinkChangePassword.validatePassword(newPassword, newPasswordRetype))
            return;

          $scope.changePassword(newPassword).then(function () {
            var msgId;

            if ($scope.isTeacherChange)
              msgId = 'STUDENTS_PASSWORD_SUCCESSFULLY_CHANGED';
            else
              msgId = 'PASSWORD_SUCCESSFULLY_CHANGED';

            $translate(msgId).then(function (msg) {
              alert(msg);

              $scope.logIn();
            });
          });
        };
      }

      $scope.onKeyPress = function (event, currentPassword, newPassword, newPasswordRetype) {
        if (event.which === 13 || event.keyCode === 13) {
          if ($scope.submit)
            $scope.submit(currentPassword, newPassword, newPasswordRetype);

          if ($scope.onEnter)
            $scope.onEnter();
        }

        event.stopPropagation();
      };
    },
    scope: {
      cancel: '=',
      changePassword: '=',
      isExpired: '=',
      isResetting: '=reset',
      isTeacherChange: '=',
      logIn: '=',
      onEnter: '=',
      referral: '=',
      userPassword: '=data'
    }
  };
}

module.directive('changePassword', ChangePasswordDirective);

var CueThinkChangePassword = {
  validatePassword: function (password1, password2, shouldAlert) {
    var message = CueThinkChangePassword.getPasswordValidationMessage(password1);

    if (!message)
      message = CueThinkChangePassword.getConfirmPasswordValidationMessage(password1, password2);

    if (message) {
      if (shouldAlert !== false)
        alert(message);

      return false;
    } else {
      return true;
    }
  }
};

module.run(function ($translate, $rootScope) {
  var PASSWORDS_MAY_NOT_CONTAIN_EMOJIS, PASSWORDS_CREATED_MUST_BE_AT_LEAST_EIGHT_CHARACTERS, THE_PASSWORDS_DO_NOT_MATCH;

  translate(['PASSWORDS_MAY_NOT_CONTAIN_EMOJIS', 'PASSWORDS_CREATED_MUST_BE_AT_LEAST_EIGHT_CHARACTERS'], $translate, $rootScope, null, function (translations) {
    PASSWORDS_MAY_NOT_CONTAIN_EMOJIS = translations['PASSWORDS_MAY_NOT_CONTAIN_EMOJIS'];
    PASSWORDS_CREATED_MUST_BE_AT_LEAST_EIGHT_CHARACTERS = translations['PASSWORDS_CREATED_MUST_BE_AT_LEAST_EIGHT_CHARACTERS'];
    THE_PASSWORDS_DO_NOT_MATCH = translations['THE_PASSWORDS_DO_NOT_MATCH'];
  });

  CueThinkChangePassword.getPasswordValidationMessage = function (password1, $translate) {
    if (/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(password1))
      return PASSWORDS_MAY_NOT_CONTAIN_EMOJIS;

    if (!password1 || password1.length < 8 || !/[A-Z]/.test(password1) || !/[a-z]/.test(password1) || !/[0-9]/.test(password1))
      return PASSWORDS_CREATED_MUST_BE_AT_LEAST_EIGHT_CHARACTERS;

    return null;
  };

  CueThinkChangePassword.getConfirmPasswordValidationMessage = function (password1, password2) {
    if (/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(password2))
      return PASSWORDS_MAY_NOT_CONTAIN_EMOJIS;

    if (password1 !== password2)
      return THE_PASSWORDS_DO_NOT_MATCH;

    if (!password2 || password2.length < 8 || !/[A-Z]/.test(password2) || !/[a-z]/.test(password2) || !/[0-9]/.test(password2))
      return '';

    return null;
  };
});

export {
  CueThinkChangePassword
};
