import AnnotatedText from './annotatedtext';
import './question.css';
import tpl from './question.html';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import 'core/question/images/Eraser_icon.svg';
import 'core/question/images/AddHighlights_btn.svg';
import 'core/question/images/StrikeThrough_icon.svg';
import 'core/question/images/Highlighter_Icon_blue.svg';
import 'core/question/images/Highlighter_Icon_green.svg';
import 'core/question/images/Highlighter_Icon_pink.svg';
import 'core/question/images/Highlighter_Icon_yellow.svg';

angular.module('question', ['pascalprecht.translate']).directive('question', function ($translate, $rootScope) {
	var annotatedText;

	return {
		restrict: 'E',
		transclude: true,
		template: tpl,
		link: function (scope, element, attributes) {
			element = $(element);

			annotatedText = new AnnotatedText(element, element.find('.question-text:first'));

			scope.$watch(attributes.onprocessword, function (value) {
				annotatedText.setProcessWordCallback(value);
			});

			scope.$watch(attributes.text, function (value) {
				if (value)
					annotatedText.setText(value);
			});

			scope.$watch(attributes.html, function (value) {
				if (value)
					annotatedText.setHTML(value);
			});

			scope.$watch(attributes.images, function (value) {
				scope.images = value;
			});

			scope.$watch(attributes.selectImage, function (value) {
				scope.selectImage = value;
			});

			scope.$watch(attributes.showTools, function (value) {
				scope.showTools = value;

				if (value)
					annotatedText.initialize();
			});

			scope.$watch(attributes.onquestionchange, function (value) {
				annotatedText.setChangeCallback(value);
			});

			scope.$watch(attributes.onaddwords, function (value) {
				annotatedText.setOnAddWordsCallback(value);
			});

			scope.$watch(attributes.onToolChange, function (value) {
				annotatedText.setOnToolChangeCallback(value);
			});

			scope.$watch(attributes.speech, function (value) {
				annotatedText.setSpeechCallback(value);

				scope.speech = !!value;
			});

			scope.$watch(attributes.hasTranslations, function (value) {
				scope.hasTranslations = value;
			});

			function addTooltip (el, text, position, pointer) {
				if (/ipad|iphone/.test(window.navigator.userAgent.toLowerCase()))
					return;

				if (!position)
					position = 'bottom center';

				if (!pointer)
					pointer = 'top left';

				el.qtip({
				   content: text,
				   position: {
						at: position,
						my: pointer
					},
				   style: {
						classes: 'whiteboard-qtip',
						def: false
					},
					hide: { when: { event: 'inactive' } }
				});
			}

			translate(['HIGHLIGHTER', 'CROSS_OUT', 'ERASER', 'ADD_TO_NOTICING'], $translate, $rootScope, scope, function (translations) {
				var position = 'bottom center';
				var pointer = 'top center';

				addTooltip(element.find('.highlighter:first'), translations.HIGHLIGHTER, 'top left', 'bottom left');
				addTooltip(element.find('.strikethrough:first'), translations['CROSS_OUT'], position, pointer);
				addTooltip(element.find('.eraser:first'), translations.ERASER, position, pointer);
				addTooltip(element.find('.add-words:first'), translations['ADD_TO_NOTICING'], position, pointer);
			});

			scope.isEnglishEnabled = function () {
				if (typeof localStorage === 'undefined') {
					return true;
				} else {
					var lang = localStorage.getItem('language');

					return lang === 'en';
				}
			};
		}
	};
});
