import 'src/components/shared/user_data';
import '../loginController';
import 'core/login/linkaccount/linkAccountDirective';
import CueThinkLoginController from '../loginController';

function LinkAccountController ($scope, UserManager, $state, CueThinkConfig, $stateParams) {
	"ngInject";

	var userManager = UserManager;

	$scope.version = CueThinkConfig.version;
	$scope.name = CueThinkConfig.name;
	$scope.service = $stateParams.service;
	$scope.linkGoogle = userManager.linkAndLogIn;
	$scope.linkClever = userManager.linkClever;
	$scope.cleverRedirectURL = CueThinkConfig.cleverRedirectURL;
	$scope.cleverClientID = CueThinkConfig.cleverClientID;
	$scope.googleClientID = CueThinkConfig.googleClientId;
	$scope.referral = $stateParams.ref;

	$scope.logInSuccess = function (user) {
		CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);
	};

	$scope.logIn = function () {
		$state.go('login', { ref: $scope.referral });
	};

	$scope.cancel = function () {
		$state.go('login', { ref: $scope.referral });
	};

	$scope.forgotPassword = function () {
		$state.go('forgotpassword');
	};
}

export default LinkAccountController;
