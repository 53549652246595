import CueThinkConfig from 'src/app/config.provider';
import { $pres, Strophe } from 'strophe.js';
import 'strophejs-plugin-muc';
import translate from 'core/common/filters/translate.filter';

export default function ChatManager() {
  var _connection;
  var _handlers = {};
  var _isConnected = false;
  var _messages = null;
  var _roomName;
  var _user;
  var _usernames;
  var me = this;

  this.broadcast = function (type, data) {
    var handlers = _handlers[type];

    if (handlers) {
      handlers.forEach(function (handler) {
        handler(data);
      });
    }
  };

  this.clear = function () {
    if (_user) {
      this.leaveRooms();

      var key = 'chatRooms-' + _user.id;
      localStorage.removeItem(key);
    }

    _isConnected = false;

    if (_messages)
      _messages.splice(0);

    _roomName = null;
    _user = null;
    _usernames = null;
  };

  this.connect = function (user, reconnectCallback) {
    if (_isConnected && user === _user)
      return Promise.resolve();

    _user = user;

    var username = user.id + '@' + CueThinkConfig.chatHostname; // TODO: will this work with email usernames?
    var password = '123456'; // this.user.username;
    var me = this;

    return new Promise(function (resolve, reject) {
      _connection = new Strophe.Connection(CueThinkConfig.chatURL, { keepalive: true });

      _connection.addProtocolErrorHandler('HTTP', 400, console.error);
      _connection.addProtocolErrorHandler('HTTP', 401, console.error);
      _connection.addProtocolErrorHandler('HTTP', 404, console.error);
      _connection.addProtocolErrorHandler('HTTP', 500, console.error);

      _connection.connect(username, password, function (status, error) {
        if (status === Strophe.Status.CONNECTED) {
          _isConnected = true;

          resolve();
        } else if (status === Strophe.Status.DISCONNECTED || status === Strophe.Status.AUTHFAIL || status == Strophe.Status.CONNFAIL || status == Strophe.Status.CONNTIMEOUT || status == Strophe.Status.ERROR) {
          me.clear();

          _connection = null;

          if (reconnectCallback && _user)
            reconnectCallback();
        }

        if (status === Strophe.Status.AUTHFAIL || status == Strophe.Status.CONNFAIL || status == Strophe.Status.CONNTIMEOUT || status == Strophe.Status.ERROR) {
          reject(error);
        }
      });
    });
  };

  this.disconnect = function () {
    if (_isConnected && _connection) {
      this.clear();

      const result = _connection.disconnect();

      _connection = null;

      return result;
    } else {
      return false;
    }
  };

  this.getMessages = function () {
    return _messages;
  };

  this.leaveRooms = function () {
    if (!_user || !_connection)
      return;

    var key = 'chatRooms-' + _user.id;
    var joinedChatRooms = localStorage.getItem(key);

    if (joinedChatRooms)
      joinedChatRooms = JSON.parse(joinedChatRooms);

    if (joinedChatRooms) {
      joinedChatRooms.forEach(roomName => {
        _connection.muc.leave(roomName, _user.username);
      });
    }
  };

  this.join = function (roomName, presenceCallback) {
    if (!_isConnected || !_connection)
      return Promise.reject();

    roomName += '@' + CueThinkConfig.groupChatDomain;

    if (_roomName === roomName)
      return Promise.resolve({ messages: _messages, usernames: _usernames });

    _roomName = roomName;

    _messages = [];
    _usernames = [];

    if (typeof localStorage !== 'undefined') {
      var key = 'chatRooms-' + _user.id;
      var joinedChatRooms = localStorage.getItem(key);

      if (joinedChatRooms)
        joinedChatRooms = JSON.parse(joinedChatRooms);
      else
        joinedChatRooms = [];

      if (joinedChatRooms.indexOf(roomName) === -1) {
        joinedChatRooms.push(roomName);

        localStorage.setItem(key, JSON.stringify(joinedChatRooms));

        var initialMessage = {
          isSystem: true,
          text: translate('WELCOME_CHAT_MESSAGE')
        };

        _messages.push(initialMessage);
      }
    }

    return new Promise(function (resolve) {
      const result = _connection.muc.join(roomName, _user.username, function (message) {
        var body = message.getElementsByTagName('body');

        if (body.length > 0) {
          let from = message.getAttribute('from');
          const delay = message.getElementsByTagName('delay')[0];

          let timestamp;

          if (delay) {
            timestamp = delay.getAttribute('stamp');

            if (!from)
              from = delay.getAttribute('from');
          }

          const userName = from.substr(from.indexOf('/') + 1);

          if (!timestamp && _user.username === userName)
            timestamp = new Date().getTime();

          var msg = { text: body[0].innerHTML, timestamp, userName };

          _messages.push(msg);

          me.broadcast('message', msg);
        }

        /*const presenceMsg = $pres({ from: _connection.jid, to: roomName }).c("x", { xmlns: Strophe.NS.MUC });

        _connection.send(presenceMsg);*/

        return true;
      },
      message => {
        if (presenceCallback) {
          let from = message.getAttribute('from');
          const delay = message.getElementsByTagName('delay')[0];

          let timestamp;

          if (delay) {
            timestamp = delay.getAttribute('stamp');

            if (!from)
              from = delay.getAttribute('from');
          }

          const userName = from.substr(from.indexOf('/') + 1);

          presenceCallback(userName);
        }
      },
      function (roster) {
        Object.keys(roster).forEach(item => _usernames.push(item));

        resolve({ messages: _messages, usernames: _usernames });
      }, null, {});

      resolve({ messages: _messages, usernames: _usernames });
    });
  };

  this.off = function (type, handler) {
    var handlers = _handlers[type];

    if (handlers) {
      if (handler) {
        var i = handlers.indexOf(handler);

        if (i >= 0)
          handlers.splice(i, 1);
      } else {
        delete _handlers[type];
      }
    }
  };

  this.on = function (type, handler) {
    var handlers = _handlers[type];

    if (!handlers)
      handlers = _handlers[type] = [];

    handlers.push(handler);
  };

  this.sendMessage = function (message, htmlMessage) {
    if (!_isConnected || !_connection)
      return Promise.reject();

    /*_messages.push({
      text: message,
      timestamp: new Date().getTime(),
      userName: _user.username
    });*/

    return _connection.muc.groupchat(_roomName, message, htmlMessage);
  };

  this.setStatus = function (status) {
    if (!_isConnected || !_connection)
      return Promise.reject();

    var presence = $pres();
    presence.c('show').t(status);

    _connection.send(presence);

    if (_roomName)
      _connection.muc.setStatus(_roomName, _user.username, null, status);
  };
}

(function () {
  var shared;

  ChatManager.getShared = function () {
    if (!shared)
      shared = new ChatManager();

    return shared;
  };
})();
