import './images/4PhasesScreen.png';
import './images/blocks.svg';
import './images/SolveScreen.png';
import './images/PlanScreen.png';
import './images/GalleryScreen.png';
import './images/ExploreScreen.png';
import './images/ICIcon.svg';
import './images/WMIcon.svg';
import './images/CFIcon.svg';
import './images/CueThinkEF.svg';
import 'core/common/cuethinkPopUpDirective.js';
import tpl from './ef-tutorial.tpl.html';
import './ef-tutorial.css';
import './images/Next_Arrow.svg';
import './images/CheckIcon.svg';

export default function EFTutorialDirective ($timeout) {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        step: 0,
        steps: $scope.steps,
        text: null
      };

      // wait until parent directive loads in case it provides its own steps
      $timeout(function () {
        if ($scope.steps) {
          $scope.viewModel.steps = $scope.steps;
        } else {
          $scope.viewModel.steps = [
            'EF_TUTORIAL_TEXT_1',
            'EF_TUTORIAL_TEXT_2',
            'EF_TUTORIAL_TEXT_3',
            'EF_TUTORIAL_TEXT_4',
            'EF_TUTORIAL_TEXT_5',
            'EF_TUTORIAL_TEXT_6'
          ];
        }

        updateStepText();
      });

      function updateStepText() {
        $scope.viewModel.text = $scope.viewModel.steps[$scope.viewModel.step];
      }

      $scope.onClickPreviousButton = function () {
        $scope.viewModel.step--;
        updateStepText();
      };

      $scope.onClickNextButton = function () {
        $scope.viewModel.step++;
        updateStepText();
      };

      $scope.onClickStep = function (step) {
        $scope.viewModel.step = step;
        updateStepText();
      };
    },
    restrict: 'E',
    scope: {
      onClose: '&',
      steps: '='
    },
    template: tpl
  };
}
