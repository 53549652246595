import 'core/common/commonModule';
import 'core/singleclick/singleClick';
import 'angular-translate';
import 'core/trial/trialEndPopUpDirective';
import MindWanderingProbeDirective from './mindWanderingProbeDirective';

var navigationModule = angular.module('cuethink.navigation', ['cuethink.singleclick', 'cuethink.common', 'pascalprecht.translate', 'cuethink.trial']);

export default navigationModule;

navigationModule.directive('mindWanderingProbe', MindWanderingProbeDirective);
