import ShapeTool from './shape-tool';
import { inherit } from './base-tool';
import Whiteboard from '../whiteboard';

export default function CurvedArrowTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = CurvedArrowTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  me.draw = function (obj) {
    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();

    CurvedArrowTool.drawCurvedArrowInsideRectangle(whiteboard.getContext(), whiteboard.getWidth(), whiteboard.getHeight(), sx, ey, ex, sy, 0.03 * whiteboard.getHeight());
  };
}

inherit(CurvedArrowTool, ShapeTool);

CurvedArrowTool.type = 'curvedarrow';

CurvedArrowTool.getPointOnCircleWithAngle = function (x, y, r, angle) {
  return { x: x + Math.cos(angle) * r, y: y + Math.sin(angle) * r };
};

CurvedArrowTool.drawCurvedArrowInsideRectangle = function (context, width, height, sx, sy, ex, ey, arrowSize) {
  // arc
  var controlPoints = CurvedArrowTool.drawArcInsideRectangle(context, sx, sy, ex, ey);

  // arrow
  var arrowStart, arrowEnd;

  Whiteboard.convertCoordinates([{ x: sx, y: sy }, { x: ex, y: ey }, controlPoints[1]], width, height, function (points) {
    var ex = points[1].x;
    var ey = points[1].y;
    var controlPoint = points[2];

    var angle = Math.atan2(controlPoint.y - ey, controlPoint.x - ex);
    var angleAdjustment = -Math.PI / 16;
    angle += angleAdjustment;
    var quarterPi = Math.PI / 4;

    var arrowStartAngle = angle + quarterPi;
    arrowStart = CurvedArrowTool.getPointOnCircleWithAngle(ex, ey, arrowSize, arrowStartAngle);
    var arrowEndAngle = angle - quarterPi;
    arrowEnd = CurvedArrowTool.getPointOnCircleWithAngle(ex, ey, arrowSize, arrowEndAngle);

    return [arrowStart, arrowEnd];
  });

  context.beginPath();
  context.moveTo(arrowStart.x, arrowStart.y);
  context.lineTo(ex, ey);
  context.lineTo(arrowEnd.x, arrowEnd.y);
  context.stroke();
};

CurvedArrowTool.drawArcInsideRectangle = function (ctx, sx, sy, ex, ey) {
  var w = ex - sx;
  var h = ey - sy;

  var kappa = 0.5522848,
    ox = (w / 2) * kappa, // control point offset horizontal
    oy = (h / 2) * kappa, // control point offset vertical
    xm = (ex + sx) / 2;       // x-middle

  var cp1 = { x: sx,		y: ey - oy };
  var cp2 = { x: xm + ox,	y: sy };

  ctx.beginPath();
  ctx.moveTo(sx, ey);
  ctx.bezierCurveTo(cp1.x, cp1.y, xm - ox, sy, xm, sy);
  ctx.bezierCurveTo(cp2.x, cp2.y, ex, ey - oy, ex, ey);
  ctx.stroke();

  return [cp1, cp2];
};
