import './cuethinkDropdownDirective.css';
import tpl from './cuethinkDropdownDirective.tpl.html';
import './images/UpArrow.svg';

function CuethinkDropdownDirective () {
  return {
    link: function ($scope) {
      if (typeof $scope.isDropdownOpen === 'undefined')
        $scope.isDropdownOpen = false;

      $scope.getLabel = function (item) {
        if ($scope.label)
          return item[$scope.label];
        else
          return item;
      };

      $scope.onClickItem = function (item) {
        $scope.onSelect({ item });
      };
    },
    replace: true,
    restrict: 'E',
    scope: {
      isDropdownOpen: '=',
      isPopulated: '=populated',
      items: '=',
      label: '@',
      onDropdownToggle: '&',
      onSelect: '&',
      selected: '='
    },
    template: tpl,
    transclude: true
  };
}

export default CuethinkDropdownDirective;
