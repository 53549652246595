import './images/CueThinkAvatars_Ape.svg';
import './images/CueThinkAvatars_Ape2.svg';
import './images/CueThinkAvatars_Ape3.svg';
import './images/CueThinkAvatars_Chicken.svg';
import './images/CueThinkAvatars_Bear.svg';
import './images/CueThinkAvatars_Bird.svg';
import './images/CueThinkAvatars_Bull.svg';
import './images/CueThinkAvatars_Cat.svg';
import './images/CueThinkAvatars_Cobra.svg';
import './images/CueThinkAvatars_Deer.svg';
import './images/CueThinkAvatars_Dog.svg';
import './images/CueThinkAvatars_Duck.svg';
import './images/CueThinkAvatars_Elephant.svg';
import './images/CueThinkAvatars_Girafe.svg';
import './images/CueThinkAvatars_Koala.svg';
import './images/CueThinkAvatars_Lamb.svg';
import './images/CueThinkAvatars_Ox.svg';
import './images/CueThinkAvatars_Penguin.svg';
import './images/CueThinkAvatars_Pig.svg';
import './images/CueThinkAvatars_Rabit.svg';
import './images/CueThinkAvatars_Rhino.svg';
import './images/CueThinkAvatars_SpottedBear.svg';
import './images/CueThinkAvatars_Tiger.svg';
import './images/CueThinkAvatars_Wolf.svg';
import './images/CueThinkAvatars_Wolf_2.svg';
import tpl from './chooseAvatarDirective.tpl.html';
import 'core/common/cuethinkPopUpDirective';
import './chooseAvatarDirective.css';
import './images/AvatarEditCheck.svg';
import 'angular-translate';

export default function ChooseAvatarDirective($translate) {
  return {
    restrict: 'E',
    template: tpl,
    link: function ($scope) {
      $scope.viewModel = {
        avatarUrls: [
          '/images/CueThinkAvatars_Ape.svg',
          '/images/CueThinkAvatars_Ape2.svg',
          '/images/CueThinkAvatars_Ape3.svg',
          '/images/CueThinkAvatars_Bear.svg',
          '/images/CueThinkAvatars_Bird.svg',
          '/images/CueThinkAvatars_Chicken.svg',
          '/images/CueThinkAvatars_Bull.svg',
          '/images/CueThinkAvatars_Cat.svg',
          '/images/CueThinkAvatars_Cobra.svg',
          '/images/CueThinkAvatars_Deer.svg',
          '/images/CueThinkAvatars_Dog.svg',
          '/images/CueThinkAvatars_Duck.svg',
          '/images/CueThinkAvatars_Elephant.svg',
          '/images/CueThinkAvatars_Girafe.svg',
          '/images/CueThinkAvatars_Koala.svg',
          '/images/CueThinkAvatars_Lamb.svg',
          '/images/CueThinkAvatars_Ox.svg',
          '/images/CueThinkAvatars_Penguin.svg',
          '/images/CueThinkAvatars_Pig.svg',
          '/images/CueThinkAvatars_Rabit.svg',
          '/images/CueThinkAvatars_Rhino.svg',
          '/images/CueThinkAvatars_SpottedBear.svg',
          '/images/CueThinkAvatars_Tiger.svg',
          '/images/CueThinkAvatars_Wolf.svg',
          '/images/CueThinkAvatars_Wolf_2.svg'
        ],
        selectedAvatarUrl: $scope.selectedAvatarUrl
      };

      $scope.onAvatarButtonClick = function (url) {
        $scope.viewModel.selectedAvatarUrl = url;
      };

      $scope.onSubmitButtonClick = function () {
        $scope.onSubmit({ url: $scope.viewModel.selectedAvatarUrl });
      };
    },
    scope: {
      onClose: '&',
      onSubmit: '&',
      selectedAvatarUrl: '=avatarUrl'
    }
  }
}