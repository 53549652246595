import '../contentAssignmentModule';
import 'src/app/common/utility/recursionHelper';
import tpl from './folderDirective.tpl.html';

export default function FolderDirective (RecursionHelper) {
  "ngInject";

  return {
    restrict: 'E',
    replace: true,
    template: tpl,
    compile: function (element) {
      var compileResult = RecursionHelper.compile(element);
      var post = compileResult.post;

      compileResult.post = function ($scope, element, attributes) {
        $scope.viewModel = {
          folderDropItem: null
        };

        $scope.selectFolder = function (folder) {
          if ($scope.select)
            $scope.select(folder);
        };

        $scope.onFolderDrop = function () {
          if ($scope.drop)
            $scope.drop($scope.folder, $scope.viewModel.folderDropItem);

          unhighlight();
        };

        $scope.onFolderDropOver = function (event) {
          $(element).find('button:first').addClass('highlighted');

          event.stopPropagation();
        };

        var unhighlight = function () {
          $(element).find('button:first').removeClass('highlighted');
        };

        $scope.onFolderDropOut = function (event) {
          unhighlight();

          event.stopPropagation();
        };

        var result = post($scope, element, attributes);

        return result;
      };

      return compileResult;
    },
    scope: {
      folder: '=',
      openedFolder: '=',
      select: '=',
      drop: '=folderDrop'
    }
  };
}

export function setUpFolderDirective (module) {
  module.directive('folder', FolderDirective);
};
