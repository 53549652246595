import TranslateManager from '../translate/translate-manager';
import NotificationCenter from '../notifications/notification-center';

export function translate(keys, $translate, $rootScope, $scope, callback) {
  var t = function () {
    $translate(keys).then(callback);
  };

  var unsubscribe = $rootScope.$on('$translateChangeSuccess', t);

  if ($scope) {
    $scope.$on("$destroy", function () {
      unsubscribe();
    });
  }

  t();
}

export function translateWatch(keys, callback) {
  const retranslate = () => {
    callback(TranslateManager.getShared().translate(keys));
  };

  retranslate();

  NotificationCenter.getShared().addListener('LanguageChanged', retranslate);
}

function getLocalizedThinkletProperty(thinklet, property, $translate, language) {
  if (!thinklet)
    return null;

  if (!language) {
    if ($translate)
      language = $translate.use();
    else
      language = TranslateManager.getShared().getLanguage();
  }

  if (thinklet.problem) {
    if (thinklet.problem.translations) {
      var translation = thinklet.problem.translations.find(function (translation) {
        return translation.locale === language;
      });

      if (translation)
        return translation[property];
      else if (thinklet.hasOwnProperty(property))
        return thinklet[property];
      else
        return thinklet.problem[property];
    } else if (thinklet.hasOwnProperty(property)) {
      return thinklet[property];
    } else {
      return thinklet.problem[property];
    }
  } else {
    return thinklet[property];
  }
}

function getLocalizedProblemProperty(problem, property, $translate, language) {
  if (!problem)
    return null;

  if (!language) {
    if ($translate)
      language = $translate.use();
    else
      language = TranslateManager.getShared().getLanguage();
  }

  if (problem.translations) {
    var translation = problem.translations.find(function (translation) {
      return translation.locale === language;
    });

    if (translation)
      return translation[property];
    else
      return problem[property];
  } else {
    return problem[property];
  }
}

export function getLocalizedThinkletTitle($translate, thinklet, language) {
  return getLocalizedProblemTitle($translate, thinklet.problem, language);
}

export function getLocalizedThinkletQuestion($translate, thinklet, language) {
  return getLocalizedProblemQuestion($translate, thinklet.problem, language);
}

export function getLocalizedThinkletAnswer($translate, thinklet, language) {
  return getLocalizedThinkletProperty(thinklet, 'answer', $translate, language);
}

export function getLocalizedThinkletQuestionImage($translate, thinklet, language) {
  return getLocalizedThinkletProperty(thinklet, 'imgList', $translate, language);
}

export function getLocalizedThinkletAnswerImage($translate, thinklet, language) {
  return getLocalizedThinkletProperty(thinklet, 'answerImgList', $translate, language);
}

export function getLocalizedProblemTitle($translate, problem, language) {
  return getLocalizedProblemProperty(problem, 'title', $translate, language);
}

export function getLocalizedProblemQuestion($translate, problem, language) {
  return getLocalizedProblemProperty(problem, 'question', $translate, language);
}

export function getLocalizedProblemAnswer($translate, problem, language) {
  return getLocalizedProblemProperty(problem, 'answer', $translate, language);
}

export function getLocalizedProblemQuestionImage($translate, problem, language) {
  return getLocalizedProblemProperty(problem, 'imgList', $translate, language);
}

export function getLocalizedProblemAnswerImage($translate, problem, language) {
  return getLocalizedProblemProperty(problem, 'answerImgList', $translate, language);
}
