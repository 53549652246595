<template>
  <div
    class="helper"
    title="Helper">
    <popover
      name="message"
      ref="popover"
      @hide="onPopoverHide"
      @show="onPopoverShow">
      <button
        class="close-btn"
        tabindex="0"
        @click="onCloseButtonClick">
        {{ 'CLOSE' | translate }}
      </button>

      <h5
        v-if="title"
        class="title">
        {{ title | translate }}
      </h5>

      <p
        v-if="message"
        class="message"
        v-html="translate(message)">
      </p>

      <div
        v-if="buttons"
        class="buttons">
        <label
          v-if="buttonsText"
          class="buttons-text">
          {{ buttonsText | translate }}
        </label>

        <button
          v-for="(action, content) in buttons"
          class="secondary-button"
          tabindex="0"
          v-html="content"
          @click.stop.prevent="action">
        </button>
      </div>
    </popover>

    <div
      ref="helper"
      v-popover="{ name: 'message', position }">
      <img
        alt="Helper"
        :src="imageUrl"
        @click="onHelperClick"
        @load="onImageLoad"
      />
    </div>
  </div>
</template>

<script>
import animatedImageUrl from './images/Helper.gif';
import stillImageUrl from './images/helper1.gif';
import './images/ClosexBlue_2px.svg';
import 'vue-js-popover';
import NotificationCenter from 'core/notifications/notification-center';

export default {
  name: 'helper',

  mixins: [require('core/common/mixins/translate.mixin').default],

  store: require('./helper.store').default,

  props: {
    animatedImage: String,
    hideOnPopoverClose: Boolean,
    stillImage: String
  },

  data() {
    return {
      imageUrl: null
    };
  },

  computed: {
    buttons() {
      return this.$store.state.buttons;
    },

    buttonsText() {
      return this.$store.state.buttonsText;
    },

    message() {
      return this.$store.state.message;
    },

    position() {
      return this.$store.state.position;
    },

    title() {
      return this.$store.state.title;
    }
  },

  watch: {
    buttons() {
      this.reshow();
    },

    buttonsText() {
      this.reshow();
    },

    message() {
      this.reshow();
    },

    title() {
      this.reshow();
    }
  },

  created() {
    this.start();
  },

  methods: {
    close() {
      this.$refs.helper.click();

      this.$emit('CloseHelper');

      NotificationCenter.getShared().post('CloseHelper');
    },

    reshow() {
      if (this.$refs.popover) {
        this.$refs.helper.click(); // hide
        this.$refs.helper.click(); // show
      } else {
        this.$refs.helper.click(); // show
      }
    },

    onCloseButtonClick() {
      if (this.hideOnPopoverClose)
        this.close();
      else
        this.$refs.helper.click();
    },

    onHelperClick() {
      NotificationCenter.getShared().post('ClickHelper');
    },

    onImageLoad() {
      this.reshow();
    },

    onPopoverHide() {
      // TODO
      // this.close();
    },

    onPopoverShow() {
      this.$refs.popover.style.left = null;
    },

    start() {
      this.imageUrl = this.animatedImage ? this.animatedImage : animatedImageUrl;

      var me = this;

      setTimeout(function () {
        me.stop();
      }, 3000);
    },

    stop() {
      this.imageUrl = this.stillImage ? this.stillImage : stillImageUrl;
    }
  }
}
</script>

<style>
  .helper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 250px;
    z-index: 1;
  }

    .helper .title {
      font-weight: 700;
      text-align: center;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .helper .message {
      text-align: center;
      margin-top: 0;
    }

    .helper img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .helper .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      background-image: url('/images/ClosexBlue_2px.svg');
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 10px 10px;
      width: 10px;
      height: 10px;
      background-color: transparent;
      text-indent: -9999em;
      overflow: hidden
    }

    .helper .buttons {
      text-align: center;
    }

      .helper .buttons button {
          background-color: transparent;
          color: #363845;
          margin-right: 10px;
        }

          .helper .buttons button:last-child {
            margin-right: 0;
          }
</style>
