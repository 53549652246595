import { isCueCollabUser, isCuethinkV2User, isDPUser, isEF23User, isEFLiteUser, isEFUser, isLZUser, isNewDesign, isOrigoUser } from '../user-modes';

export default {
  props: {
    user: Object
  },

  computed: {
    isCueCollabUser() {
      return this.permissionUser && isCueCollabUser(this.permissionUser);
    },

    isCuethinkV2User() {
      return this.permissionUser && isCuethinkV2User(this.permissionUser);
    },

    isDPUser() {
      return this.permissionUser && isDPUser(this.permissionUser);
    },

    isEF23User() {
      return this.permissionUser && isEF23User(this.permissionUser);
    },

    isEFLiteUser() {
      return this.permissionUser && isEFLiteUser(this.permissionUser);
    },

    isEFUser() {
      return this.permissionUser && isEFUser(this.permissionUser);
    },

    isLZUser() {
      return this.permissionUser && isLZUser(this.permissionUser);
    },

    isNewDesign() {
      return isNewDesign(this.permissionUser);
    },

    isOrigoUser() {
      return this.permissionUser && isOrigoUser(this.permissionUser);
    },

    permissionUser() {
      return this.user;
    }
  }
}
