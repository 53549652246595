import ShapeTool from './shape-tool';
import { inherit } from './base-tool';
import CurvedArrowTool from './curved-arrow-tool';

export default function VerticalCurvedArrowTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = VerticalCurvedArrowTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  me.draw = function (obj) {
    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();
    var width = ex - sx;
    var height = ey - sy;
    var aspectRatio = width / height;

    var ctx = whiteboard.getContext();
    ctx.save();
    var rads = -Math.PI / 2;
    ctx.translate(sx, sy);
    ctx.scale(1, -1);
    ctx.rotate(rads);
    CurvedArrowTool.drawCurvedArrowInsideRectangle(ctx, whiteboard.getWidth(), whiteboard.getHeight(), 0, width, height, 0, 0.03 * whiteboard.getHeight());
    ctx.restore();
  };
}

inherit(VerticalCurvedArrowTool, ShapeTool);

VerticalCurvedArrowTool.type = 'verticalcurvedarrow';
