import './cuethinkCheckboxDirective.css';

export default function CuethinkCheckboxDirective() {
  return {
    link: function () {
    },
    restrict: 'E',
    scope: {
      value: '='
    },
    template: require('./cuethinkCheckboxDirective.tpl.html')
  };
}