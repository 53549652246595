import '../thinklet/thinklet_cell';
import '../navigation/navbar';
import './profile.css';
import tpl from './profileDirective.tpl.html';
import _ from 'underscore';
import 'angular-translate';

angular.module('cuethink.profile', ['cuethink.thinklet', 'cuethink.navigation', 'pascalprecht.translate']).directive('profile', function ($translate, $q) {
  return {
    restrict: 'E',
    transclude: true,
    template: tpl,
    link: function ($scope) {
    	$scope.viewModel = {
				nameFormat: $scope.nameFormat
			};

      $scope.userClasses = [];

      var _fullThinklets;

      function filterThinklets() {
        if (!_fullThinklets)
          return;

        var completedThinklets = [], inProgressThinklets = [];

        for (var i = 0; i < _fullThinklets.length; i++) {
          var thinklet = _fullThinklets[i];

          if (thinklet.isComplete)
            completedThinklets.push(thinklet);
          else
            inProgressThinklets.push(thinklet);
        }

        $scope.completedThinklets = completedThinklets;
        $scope.inProgressThinklets = inProgressThinklets;

        if (completedThinklets.length === 0)
          $scope.noCompletedThinklets = true;

        if (inProgressThinklets.length === 0)
          $scope.noInProgressThinklets = true;
      }

      $scope.$watch('user.selectedClass', function () {
        filterThinklets();
      });

      (function () {
        var loadPromises = [];

        loadPromises.push($scope.loadThinklets($scope.user.id).then(function (data) {
          _fullThinklets = data;
        }));

        $q.all(loadPromises).then(function () {
          filterThinklets();
        });
      })();

      if ($scope.createThinklet) {
        var handleCallback = function (func, callback, error) {
          var promise = func(callback, error);

          if (promise)
            promise.then(callback, error);
        };

        $scope.beginThinklet = function (thinklet) {
          handleCallback(function () {
            return $scope.loadProblem(thinklet.problemId);
          }, function (problem) {
            handleCallback(function (callback) {
              return $scope.createThinklet(problem, thinklet.assignmentId, callback);
            }, function (thinklet) {
              $scope.startThinklet(thinklet);
            });
          });
        };
      }

      $scope.edit = function (thinklet) {
        if (thinklet.isComplete) {
          var match = _.find($scope.inProgressThinklets, function (t) {
            return _.find(thinklet.versionInfo, function (version) {
              return version.id === t.id;
            }) && !t.isComplete;
          });

          if (match)
            $scope.editThinklet({ thinklet: match });
          else
            $scope.createVersion({ thinklet: thinklet });
        } else {
          $scope.editThinklet({ thinklet: thinklet });
        }
      };

      $scope.removeThinklet = function (thinklet) {
        if (window.confirm($translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_THINKLET'))) {
          var thinklets = $scope.inProgressThinklets;
          var i = thinklets.indexOf(thinklet);

          if (i < 0) {
            thinklets = $scope.completedThinklets;
            i = thinklets.indexOf(thinklet);
          }

          thinklets.splice(i, 1);

          $scope.deleteThinklet(thinklet);
        }
      };

      $scope.preventParentClick = function (e) {
        if (!e)
          e = window.event;

        e.cancelBubble = true;

        if (e.stopPropagation)
          e.stopPropagation();
      };

      $scope.isOverdue = function (thinklet) {
        return thinklet.dueDate < new Date();
      };

			$scope.chooseClass = function (cls) {
				$scope.selectClass(cls);

				$scope.classesVisible = false;
			};

			$scope.toggleClasses = function () {
				$scope.classesVisible = !$scope.classesVisible;

				if ($scope.classesVisible) {
					$scope.$$postDigest(function () {
						var button = $('navbar .class-chooser:first');
						var list = $('navbar ul.class-list:first');
						var offset = button.offset();

						$('dashboard:first').append(list);
						list.css('left', offset.left + 'px');
						list.css('top', offset.top + button.height() + 'px');
					});
				}
			};
    },
    scope: {
			createThinklet: '=',
			createVersion: '&',
			dashboard: '=',
			dashboardState: '=',
      defaultAvatarColors: '=',
			deleteThinklet: '=',
			editThinklet: '&',
			isCueteachEnabled: '=',
      fetchHasSeenTrialEndPopUp: '=',
			gallery: '=',
			galleryState: '=',
			getTrialDays: '=',
			loadProblem: '=',
			loadThinklets: '=',
			logOut: '=logout',
    	nameFormat: '=',
      navigateJoinClass: '=',
			navigateRubric: '=',
			navigateToTeacherDashboard: '=teacherDashboard',
      onSubmitMindWanderingProbe: '&',
			profile: '=',
      profileState: '=',
      selectClass: '=',
      setHasSeenTrialEndPopUp: '=',
			settings: '=',
      setUpNavigation: '=',
      showTrialEndPopUp: '&',
			startThinklet: '=',
			state: '=',
			tutorial: '=',
			user: '=',
			viewThinklet: '='
    }
  };
});
