import module from './settings.module';
import './settingsDirective';
import tpl from './solveSettingsDirective.tpl.html';
import CueThinkSettingsDirective from './settingsDirective';
import './images/Pen_Icon_Black.svg';
import './images/Highlighter_Icon_Black.svg';
import './images/PhotoIcon_Black.svg';
import './images/Shape_Icon_Black.svg';
import './images/StampIcon_Black.svg';
import './images/HTO_Icon_Black.svg';
import './images/Table_Icon_Black.svg';
import './images/DotIcon_Black.svg';
import './images/Circle_Icon_Black.svg';
import './images/CubeIcon.svg';
import './images/Grid.svg';
import './images/Calculator_Icon_Black.svg';
import './images/NumberLine_Black.svg';
import './images/ProtractorIcon.svg';
import './images/TextTool_Black.svg';
import permissionsManager, { Permissions } from '../permissions/permissions';

export default function SolveSettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    CueThinkSettingsDirective.bootstrapSettingsDirective($scope, $translate, $rootScope);

    $scope.viewModel = {
      isEnabled: !permissionsManager.getPermission(Permissions.SmartDesign)
    };

    var tools = [
      {
        type: 'pen',
        label: 'PEN',
        icon: 'Pen_Icon_Black.svg'
      },
      {
        type: 'highlighter',
        label: 'HIGHLIGHTER',
        icon: 'Highlighter_Icon_Black.svg'
      },
      {
        type: 'image',
        label: 'INSERT_IMAGE',
        icon: 'PhotoIcon_Black.svg'
      },
      {
        type: 'shapes',
        label: 'SHAPES',
        icon: 'Shape_Icon_Black.svg'
      },
      {
        type: 'stamps',
        label: 'STAMPS',
        icon: 'StampIcon_Black.svg'
      },
      {
        type: 'diagrams',
        label: 'DIAGRAMS',
        icon: 'HTO_Icon_Black.svg'
      },
      {
        type: 'table',
        label: 'TABLE',
        icon: 'Table_Icon_Black.svg'
      },
      {
        type: 'array',
        label: 'ARRAY',
        icon: 'DotIcon_Black.svg'
      },
      {
        type: 'numberBond',
        label: 'NUMBER_BOND',
        icon: 'Circle_Icon_Black.svg'
      },
      {
        type: 'unifixCube',
        label: 'UNIFIX_CUBE',
        icon: 'CubeIcon.svg'
      },
      {
        type: 'grid',
        label: 'GRID',
        icon: 'Grid.svg'
      },
      {
        type: 'equation',
        label: 'EQUATION_EDITOR',
        icon: 'Calculator_Icon_Black.svg'
      },
      {
        type: 'numberLine',
        label: 'NUMBER_LINE',
        icon: 'NumberLine_Black.svg'
      },
      {
        type: 'protractor',
        label: 'PROTRACTOR',
        icon: 'ProtractorIcon.svg'
      },
      {
        type: 'text',
        label: 'TEXT_EDITOR',
        icon: 'TextTool_Black.svg'
      },
      {
        type: 'algebraTile',
        label: 'ALGEBRA_TILE',
        icon: 'AlgebraTiles.svg'
      },
      {
        type: 'fractionBar',
        label: 'FRACTION_BAR',
        icon: 'FractionIcon_blue.svg'
      }
    ];

    $scope.getToolIconPath = function (type) {
      return '/images/' + tools.find(function (tool) {
        return tool.type === type;
      }).icon;
    };

    $scope.getToolLabel = function (type) {
      return tools.find(function (tool) {
        return tool.type === type;
      }).label;
    };

    $scope.onListItemMoved = function (index) {
      $scope.settings.toolOrder.splice(index, 1);
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: CueThinkSettingsDirective.createSettingsScope()
  };
}

module.directive('solveSettings', SolveSettingsDirective);
