/**
 * HTML5 Canvas Whiteboard
 *
 * Authors:
 * Antti Hukkanen
 * Kristoffer Snabb
 *
 * Aalto University School of Science and Technology
 * Course: T-111.2350 Multimediatekniikka / Multimedia Technology
 *
 * Under MIT Licence
 *
 */

import './libs/base64';
import './libs/canvas-to-blob.min';
import './libs/imagepreloader';
import rbush from './libs/rbush.min.js';
import _ from 'underscore';
import CurvedArrowTool from './tools/curved-arrow-tool';
import VerticalCurvedArrowTool from './tools/vertical-curved-arrow-tool';
import EllipseTool from './tools/ellipse-tool';
import HexagonTool from './tools/hexagon-tool';
import LineTool from './tools/line-tool';
import OctagonTool from './tools/octagon-tool';
import ParallelogramTool from './tools/parallelogram-tool';
import PentagonTool from './tools/pentagon-tool';
import RectangleTool from './tools/rectangle-tool';
import RightTriangleTool from './tools/right-triangle-tool';
import SpreadsheetTool from './tools/spreadsheet-tool';
import TrapezoidTool from './tools/trapezoid-tool';
import TriangleTool from './tools/triangle-tool';
import AudioRecorder from './libs/audio_recorder';
import NumberLineTool from './tools/number-line-tool';
import GridTool from './tools/grid-tool';
import UnifixCubeTool, { UnifixCubeStackTool } from './tools/unifix-cube-tool';
import DiagramTool from './tools/diagram-tool';
import TableTool, { TableText } from './tools/table-tool';
import TextTool from './tools/text-tool';
import ProtractorTool from './tools/protractor-tool';
import AlgebraTileTool from './tools/algebra-tile-tool';
import FractionBarTool from './tools/fraction-bar-tool';
import cloneDeep from 'lodash.clonedeep';
import cloneDeepWith from 'lodash.clonedeepwith';

function Whiteboard () {
  var _me = this;
  var _isRecording, _recordedEvents = [], _lineWidth, _time, _isRecordingPaused, _isPlayingPaused, _audioRecorder, _recordingCallback, _font = 'Museo Sans', _audioPlayer, _undoneEvents = [], _audioURL, _isPlaying, _playbackIndex, _previewEndedCallback, _group, _groupIndex = 0, _currentPage = 0, _playbackEventChangeCallback, _ended = true, _selectCallback, _deselectCallback, _selectedEvent, _startRecordingTime, _seekedPlayback, _lastEvent, _duration, _beginPlaybackTime, _recordedTime, _recordAudio, _color = 'black', _pausedTime, _lastDrawTime;
  var _savedCanvas;
  var _nextID = 0;
  var _deletedEvents = [];
  //var _isExecutingInstantaneous;
  var _undonePlayEvents = [];
  var _lastUndoIndex;
  var _playTimer, _nextEvent, _playIndexPath = [];
  var _microphoneNotAvailableCallback;
  var _eventMap = {};
  var _relatedEvents = {};
  var _selectIndex = new WhiteboardSelectIndex();
  var _rectangleTool = new RectangleTool(this);
  var _lineTool = new LineTool(this);
  var _ellipseTool = new EllipseTool(this);
  var _curvedArrowTool = new CurvedArrowTool(this);
  var _verticalCurvedArrowTool = new VerticalCurvedArrowTool(this);
  var _triangleTool = new TriangleTool(this);
  var _rightTriangleTool = new RightTriangleTool(this);
  var _pentagonTool = new PentagonTool(this);
  var _parallelogramTool = new ParallelogramTool(this);
  var _hexagonTool = new HexagonTool(this);
  var _octagonTool = new OctagonTool(this);
  var _trapezoidTool = new TrapezoidTool(this);
  var _spreadsheetTool = new SpreadsheetTool(this);
  var _numberLineTool = new NumberLineTool(this);
  var _diagramTool = new DiagramTool(this);
  var _tableTool = new TableTool(this);
  var _gridTool = new GridTool(this);
  var _unifixCubeTool = new UnifixCubeTool(this);
  var _unifixCubeStackTool = new UnifixCubeStackTool(this);
  var _algebraTileTool = new AlgebraTileTool(this);
  var _fractionBarTool = new FractionBarTool(this);
  var _textTool = new TextTool(this);
  var _protractorTool = new ProtractorTool(this);
  var _tools = [];
  var _readOnly;
  let _onChangeCallback;

  _me.getRectangleTool = function () {
    return _rectangleTool;
  };

  _me.getLineTool = function () {
    return _lineTool;
  };

  _me.getEllipseTool = function () {
    return _ellipseTool;
  };

  _me.getCurvedArrowTool = function () {
    return _curvedArrowTool;
  };

  _me.getVerticalCurvedArrowTool = function () {
    return _verticalCurvedArrowTool;
  };

  _me.getTriangleTool = function () {
    return _triangleTool;
  };

  _me.getRightTriangleTool = function () {
    return _rightTriangleTool;
  };

  _me.getParallelogramTool = function () {
    return _parallelogramTool;
  };

  _me.getHexagonTool = function () {
    return _hexagonTool;
  };

  _me.getOctagonTool = function () {
    return _octagonTool;
  };

  _me.getPentagonTool = function () {
    return _pentagonTool;
  };

  _me.getTrapezoidTool = function () {
    return _trapezoidTool;
  };

  _me.getUnifixCubeTool = function () {
    return _unifixCubeTool;
  };

  _me.getDiagramTool = function () {
    return _diagramTool;
  };

  _me.getTableTool = function () {
    return _tableTool;
  };

  _me.getTextTool = function () {
    return _textTool;
  };

  _me.getProtractorTool = function () {
    return _protractorTool;
  };

  _me.getAlgebraTileTool = function () {
    return _algebraTileTool;
  };

  _me.getFractionBarTool = function () {
    return _fractionBarTool;
  };

  _me.registerTool = function (tool) {
    if (_tools.indexOf(tool) === -1)
      _tools.push(tool);
  };

  _me.registerTool(_rectangleTool);
  _me.registerTool(_lineTool);
  _me.registerTool(_triangleTool);
  _me.registerTool(_curvedArrowTool);
  _me.registerTool(_verticalCurvedArrowTool);
  _me.registerTool(_ellipseTool);
  _me.registerTool(_rightTriangleTool);
  _me.registerTool(_pentagonTool);
  _me.registerTool(_parallelogramTool);
  _me.registerTool(_hexagonTool);
  _me.registerTool(_octagonTool);
  _me.registerTool(_trapezoidTool);
  _me.registerTool(_numberLineTool);
  _me.registerTool(_gridTool);
  _me.registerTool(_unifixCubeTool);
  _me.registerTool(_unifixCubeStackTool);
  _me.registerTool(_tableTool);
  _me.registerTool(_diagramTool);
  _me.registerTool(_textTool);
  _me.registerTool(_protractorTool);
  _me.registerTool(_algebraTileTool);
  _me.registerTool(_fractionBarTool);

  function getToolForObjectType (type) {
    for (var i = 0; i < _tools.length; i++) {
      var tool = _tools[i];

      if (tool.getType() === type)
        return tool;
    }

    return null;
  }

  _me.getNextId = function () {
    return _nextID++;
  };

  _me.getFont = function () {
    return _font;
  };

  _me.setFont = function (value) {
    _font = value;
  };

  _me.getColor = function () {
    return _color;
  };

  function getAbsoluteLineWidth () {
    var oneUnit = _me.canvas.width / 1024;
    var result;

    if (_lineWidth)
      result = _lineWidth;
    else
      result = 2;

    result *= oneUnit;

    return result;
  }

  _me.getAbsoluteLineWidth = getAbsoluteLineWidth;

  _me.getCanvas = function () {
    return _me.canvas;
  };

  _me.getContext = function () {
    return _me.context;
  };

  function saveCanvas () {
    var tmp = document.createElement("canvas");
    var tmpcnv = tmp.getContext('2d');
    tmp.width = _me.canvas.width;
    tmp.height = _me.canvas.height;
    tmpcnv.drawImage(_me.canvas, 0, 0);

    return tmp;
  }

  _me.saveCanvas = saveCanvas;

  function restoreCanvas (canvas, shouldClear) {
    if (!canvas)
      return false;

    if (shouldClear !== false)
      clear();

    _me.context.drawImage(canvas, 0, 0);
  }

  _me.restoreCanvas = restoreCanvas;

  _me.isDeleted = function (event) {
    var parentId;

    if (event.type === 'tabletext')
      parentId = event.tableId;
    else if (event.type === 'numberlinetext')
      parentId = event.numberLineId;

    if (parentId !== undefined && !_eventMap[parentId])
      return false;

    for (var i = 0; i < _deletedEvents.length; i++) {
      var d = _deletedEvents[i];

      if (areEventsEqual(event, d))
        return true;

      if (parentId !== undefined && parentId === d.id)
        return true;
    }

    return false;
  };

  function isUndone (event) {
    for (var i = 0; i < _undonePlayEvents.length; i++)
      if (areEventsEqual(event, _undonePlayEvents[i]))
        return true;

    return false;
  }

  _me.getRecordedTime = function (time) {
    if (_me.isRecordingPaused())
      return _recordedTime;
    else if ((typeof _startRecordingTime) === 'number')
      return _recordedTime + (time === undefined ? new Date().getTime() -  _startRecordingTime : time);
    else
      return false;
  };

  function updateEventTime (event, time) {
    if (_me.isRecording() || _me.isRecordingPaused()) {
      var t = _me.getRecordedTime(time);

      if (t !== false)
        event.time = t;
    }
  }

  _me.updateEventTime = updateEventTime;

  function BeginPath (x, y, color) {
    this.id = _nextID++;
    this.coordinates = [x, y];
    this.type = 'beginpath';

    if (color)
      this.color = color;

    updateEventTime(this);
  }

  function DrawPathToPoint (x, y) {
    this.id = _nextID++;
    this.type = 'drawpathtopoint';
    this.coordinates = [x, y];

    updateEventTime(this);
  }

  function DrawHighlighterPathToPoint (x, y) {
    this.id = _nextID++;
    this.type = 'drawhighlighterpathtopoint';
    this.coordinates = [x, y];

    updateEventTime(this);
  }

  function Erase (x, y) {
    this.id = _nextID++;
    this.type = 'erase';
    this.coordinates = [x, y];

    updateEventTime(this);
  }

  function NumberLineText (numberLineId, text, index, color) {
    this.id = _nextID++;
    this.numberLineId = numberLineId;
    this.type = 'numberlinetext';
    this.index = index;
    this.text = text;

    if (color)
      this.color = color;

    updateEventTime(this);
  }

  function ArrayShape (sx, sy, ex, ey, rows, cols, color) {
    this.id = _nextID++;
    this.type = 'array';
    this.coordinates = [sx, sy, ex, ey];
    this.rows = rows;
    this.cols = cols;

    if (color)
      this.color = color;

    updateEventTime(this);
  }

  function Bond (x, y, ex, ey, color) {
    this.id = _nextID++;
    this.type = 'bond';
    this.coordinates = [x, y, ex, ey];

    if (color)
      this.color = color;

    updateEventTime(this);
  }

  function Image (x, y, w, h, url, orientation, isResizable) {
    this.coordinates = [x, y, w, h];
    this.id = _nextID++;

    if (orientation > 1)
      this.orientation = orientation;

    if (typeof isResizable === 'boolean')
      this.resizable = isResizable;

    this.type = 'image';
    this.url = url;

    updateEventTime(this);
  }

  function Equation (x, y, w, h, url, orientation, mathML) {
    this.id = _nextID++;
    this.type = 'equation';
    this.coordinates = [x, y, w, h];
    this.url = url;

    if (orientation > 1)
      this.orientation = orientation;

    this.mathML = mathML;

    updateEventTime(this);
  }

  function Group (events, color) {
    this.id = _nextID++;
    this.events = events;
    this.type = 'group';

    if (color)
      this.color = color;

    updateEventTime(this);

    if (events)
      Whiteboard.crawlEvents(events, setParentEvent, setParentEvent);
  }

  function Clear () {
    this.id = _nextID++;
    this.type = 'clear';

    updateEventTime(this);
  }

  function Delete (event, indexPath, playIndexPath) {
    this.id = _nextID++;
    this.type = 'delete';

    this.event = event;

    this.indexPath = indexPath;
    this.playIndexPath = playIndexPath;

    updateEventTime(this);
  }

  function Undo () {
    this.id = _nextID++;
    this.type = 'undo';

    updateEventTime(this);
  }

  function Redo () {
    this.id = _nextID++;
    this.type = 'redo';

    updateEventTime(this);
  }

  function makeInstantaneousWithZeroTime (event) {
    event.time = 0;
    event.isInstantaneous = true; // TODO: set this in a callback passed to getEventsSinceLastClear
  }

  function getBoundsForSpreadsheetCell (sx, sy, ex, ey, rows, cols, rowHeights, colWidths, row, col) {
    var x = 0;
    var y = 0;

    for (var i = 0; i < row; i++)
      y += rowHeights[i] * _me.getHeight();

    for (i = 0; i < col; i++)
      x += colWidths[i] * _me.getWidth();

    return {
      x: sx + x,
      y: sy + y,
      width: colWidths[col] * _me.getWidth(),
      height: rowHeights[row] * _me.getHeight()
    };
  }

  function renderSpreadsheetText (table, data, row, col, color, textSize, autoSizeText) {
    var text = data[row][col];
    var sx = table.coordinates[0] * _me.getWidth();
    var sy = table.coordinates[1] * _me.getHeight();
    var ex = table.coordinates[2] * _me.getWidth();
    var ey = table.coordinates[3] * _me.getHeight();
    var x, y, adjustedTextSize;

    if (!color)
      color = 'black';

    var cellBounds = getBoundsForSpreadsheetCell(sx, sy, ex, ey, table.rows, table.cols, table.rowHeights, table.colWidths, row, col);

    if (text) {
      var size = {
        width: cellBounds.width,
        height: cellBounds.height
      };

      if (!textSize)
        textSize = size.height / 3;

      var textBounds = TextTool.measureText(_me, text, textSize, cellBounds.width, textSize);
      adjustedTextSize = textSize;

      if (autoSizeText !== false) {
        while (textBounds.height > size.height) {
          adjustedTextSize--;

          if (adjustedTextSize <= 0)
            break;

          textBounds = TextTool.measureText(_me, text, adjustedTextSize, cellBounds.width, adjustedTextSize);
        }
      }

      if (textBounds.width > size.width)
        textBounds.width = size.width;

      if (textBounds.height > size.height)
        textBounds.height = size.height;

      x = cellBounds.x;
      y = cellBounds.y;
    }

    _me.context.save();

    _me.context.rect(cellBounds.x, cellBounds.y, cellBounds.width, cellBounds.height);
    _me.context.clip();

    if (text) {
      _me.setStrokeColor(color);
      _me.setFillColor(color);
      TextTool.wrapText(_me, text, adjustedTextSize, x, y, cellBounds.width, adjustedTextSize);
    }

    _me.context.restore();
  }

  function renderSpreadsheet (sx, sy, ex, ey, data, rowHeights, colWidths, color, textSize, callback) {
    var w = 0;
    var h = 0;

    rowHeights.forEach(function (value) {
      h += value;
    });

    colWidths.forEach(function (value) {
      w += value;
    });

    ex = sx + w;
    ey = sy + h;

    var bounds = {
      left: sx * _me.getWidth(),
      top: sy * _me.getHeight(),
      right: ex * _me.getWidth(),
      bottom: ey * _me.getHeight(),
      width: w * _me.getWidth(),
      height: h * _me.getHeight()
    };

    _me.setFillColor('white');
    _me.context.fillRect(bounds.x, bounds.y, bounds.width, bounds.height);

    _me.context.lineWidth = 1;
    _me.setStrokeColor('#999');
    _me.context.beginPath();
    _me.context.rect(bounds.left, bounds.top, bounds.width, bounds.height);
    _me.context.closePath();
    _me.context.stroke();

    var rows = data.length;
    var cols = rows > 0 ? Object.keys(data[0]).length : 1;

    // rows
    var y = bounds.top;

    for (var i = 0; i < rows - 1; i++) {
      var rowHeight = rowHeights[i] * _me.getHeight();

      y += rowHeight;

      _me.context.beginPath();
      _me.context.moveTo(bounds.left, y);
      _me.context.lineTo(bounds.right, y);
      _me.context.closePath();
      _me.context.stroke();

      if (callback && callback(i - 1) === false)
        break;
    }

    // cols
    var x = bounds.left;

    for (i = 0; i < cols - 1; i++) {
      var colWidth = colWidths[i] * _me.getWidth();

      x += colWidth;

      _me.context.beginPath();
      _me.context.moveTo(x, bounds.top);
      _me.context.lineTo(x, bounds.bottom);
      _me.context.closePath();
      _me.context.stroke();

      if (callback && callback(null, i - 1) === false)
        break;
    }

    if (data) {
      var table = {
        coordinates: [sx, sy, ex, ey],
        rows: rows,
        cols: cols,
        rowHeights: rowHeights,
        colWidths: colWidths
      };

      for (var row = 0; row < data.length; row++)
        for (var col = 0; col < data[row].length; col++)
          renderSpreadsheetText(table, data, row, col, color, textSize, true);
    }

    return bounds;
  }

  function renderArray (sx, sy, ex, ey, rows, cols) {
    var w = ex - sx;

    var size = w / ((cols * 2) - 1);

    var y = sy;

    for (var j = 0; j < rows; j++) {
      var x = sx;

      for (var i = 0; i < cols; i++) {
        EllipseTool.strokeEllipse(_me.context, x, y, size, size);

        x += size;
        x += size;
      }

      y += size;
      y += size;
    }
  }

  function drawNumberBond (wbevent) {
    var contentBounds = {};
    var sx, sy, ex, ey, w, h;

    contentBounds.sx = sx = wbevent.coordinates[0] * _me.getWidth();
    contentBounds.sy = sy = wbevent.coordinates[1] * _me.getHeight();

    if (wbevent.coordinates.length > 2) {
      contentBounds.ex = ex = wbevent.coordinates[2] * _me.getWidth();
      contentBounds.ey = ey = wbevent.coordinates[3] * _me.getHeight();
      contentBounds.w = ex - sx;
      contentBounds.h = ey - sy;

      if (contentBounds.w < contentBounds.h) {
        contentBounds.h = contentBounds.w;
        contentBounds.ey = contentBounds.sy + contentBounds.h;
      } else if (contentBounds.h < contentBounds.w) {
        contentBounds.w = contentBounds.h;
        contentBounds.ex = contentBounds.sx + contentBounds.w;
      }
    } else {
      contentBounds.w = w = 0.1 * _me.getWidth();
      contentBounds.h = h = w;
      contentBounds.ex = sx + w;
      contentBounds.ey = sy + h;
    }

    var circleSize = contentBounds.w / 3, topCircleSize = circleSize * 1.5;
    var radius = circleSize / 2, topCircleRadius = radius * 1.5;

    _me.setLineWidth(4);

    EllipseTool.strokeEllipse(_me.context, contentBounds.sx + (contentBounds.w / 2) - topCircleRadius, contentBounds.sy, topCircleSize, topCircleSize);
    EllipseTool.strokeEllipse(_me.context, contentBounds.sx, contentBounds.ey - circleSize, circleSize, circleSize);
    EllipseTool.strokeEllipse(_me.context, contentBounds.ex - circleSize, contentBounds.ey - circleSize, circleSize, circleSize);

    _me.context.moveTo(contentBounds.sx + radius, contentBounds.ey - circleSize);
    _me.context.lineTo(contentBounds.sx + (contentBounds.w / 2), contentBounds.sy + topCircleSize);
    _me.context.closePath();
    _me.context.stroke();

    _me.context.moveTo(contentBounds.ex - radius, contentBounds.ey - circleSize);
    _me.context.lineTo(contentBounds.sx + (contentBounds.w / 2), contentBounds.sy + topCircleSize);
    _me.context.closePath();
    _me.context.stroke();

    return contentBounds;
  }

  function previewEnded () {
    clearInterval(_playTimer);
    _playTimer = null;

    _isPlaying = false;
    _ended = true;

    if (_previewEndedCallback)
      _previewEndedCallback();

    if (_playbackStateChangeCallback)
      _playbackStateChangeCallback(_me, 'stopped');
  }

  function getObjectAtNextIndexPath (objects, indexPath, key, newIndexPath) {
    var result, i;

    if (indexPath.length > 1) {
      var ip;

      if (newIndexPath)
        ip = [];

      var children = objects[indexPath[0]];

      if ((typeof children) === 'object')
        children = children[key];

      result = getObjectAtNextIndexPath(children, indexPath.slice(1), key, ip);

      if (result) {
        if (newIndexPath) {
          for (i = 0; i < ip.length; i++) {
            if (1 + i < newIndexPath.length)
              newIndexPath[1 + i] = ip[i];
            else
              newIndexPath.push(ip[i]);
          }
        }
      } else {
        i = indexPath[0] + 1;

        if (i < objects.length) {
          result = objects[i];
        }
      }

      return result;
    } else {
      i = indexPath[0] + 1;

      if (i < objects.length) {
        if (newIndexPath)
          newIndexPath[0] = i;

        result = objects[i];
      }
    }

    return result;
  }

  function getEventAtNextIndexPath (events, indexPath, newIndexPath) {
    return getObjectAtNextIndexPath(events, indexPath, 'events', newIndexPath);
  }

  var _isUpdatingCanvas;
  function updateCanvas () {
    if (_isUpdatingCanvas) // avoid concurrent executions
      return;

    var event = _nextEvent;

    if (!event)
      return;

    _isUpdatingCanvas = true;

    var now = new Date().getTime() - _beginPlaybackTime;

    if (now < event.time) {
      _isUpdatingCanvas = false;

      return;
    }

    var lastEvent;

    do {
      if (!event.isInstantaneous)
        while (event.type === 'group')
          event = event.events[0];

      lastEvent = event;

      _playIndexPath = getIndexPathForEvent(event, _recordedEvents);

      _me.execute(event);

      _nextEvent = event = getEventAtNextIndexPath(_recordedEvents, _playIndexPath);

      if (!_nextEvent) { // end of the line
        if (_me.getAudioURL() && !_overrideDuration) { // wait for audio to stop to call previewEnded()
          clearInterval(_playTimer);
          _playTimer = null;
        } else {
          previewEnded();
        }
      }
    } while (event && (event.isInstantaneous || event.time === lastEvent.time));

    _isUpdatingCanvas = false;
  }

  function playPreview (callback) {
    if (_playbackStateChangeCallback)
      _playbackStateChangeCallback(_me, 'started');

    _beginPlaybackTime = new Date().getTime();

    _nextEvent = _recordedEvents[0];

    updateCanvas();

    _playTimer = setInterval(updateCanvas, 1);

    if (callback)
      callback();
  }

  function resumePreview () {
    if (_playIndexPath.length === 0) {
      playPreview();

      return;
    }

    if (_playbackStateChangeCallback)
      _playbackStateChangeCallback(_me, 'started');

    var currentTime = new Date().getTime();
    _beginPlaybackTime = currentTime - (_pausedTime * 1000);

    updateCanvas();

    _playTimer = setInterval(updateCanvas, 1);
  }

  var _audioRecordingErrorCallback;
  _me.setAudioRecordingErrorCallback = function (value) {
    _audioRecordingErrorCallback = value;
  };

  let _logCallback;
  _me.setLogCallback = function (value) {
    _logCallback = value;
  };

  _me.setMicrophoneNotAvailableCallback = function (value) {
    _microphoneNotAvailableCallback = value;
  };

  function redraw (events, callback, skipTypes) {
    var event;

    if (!events) {
      if (_me.isPlaying() || _me.isPlayingPaused()) {
        event = getEventAtIndexPath(_recordedEvents, _playIndexPath);

        if (!event)
          return false;

        events = getEventHierarchyUntilEvent(_recordedEvents, event); // TODO: optimize
      } else {
        events = getEventsSinceLastClear(_me.getEventsForCurrentPage(), false);
      }
    }

    if (events) {
      var executeEvent = function (event) {
        var result;

        if (callback)
          result = callback(event);

        if (result === false)
          return false;

        return result;
      };

      for (var i = 0; i < events.length; i++) {
        event = events[i];

        if (_editingObject === event)
          continue;

        _me.execute(event, callback ? executeEvent : null, skipTypes);
      }
    }
  }

  _me.redraw = redraw;

  function getEventAtIndexPath (events, indexPath) {
    var result;

    result = events[indexPath[0]];

    for (var i = 1; i < indexPath.length; i++)
      result = result.events[indexPath[i]];

    return result;
  }

  function onAudioEnd () {
    if (_overrideDuration)
      return;

    previewEnded();
  }

  function playAudio (callback, wasPaused) {
    var url = _me.getAudioURL();

    if (!url) {
      if (callback)
        callback();

      return;
    }

    if (!_audioPlayer) {
      _audioPlayer = Whiteboard.getSharedAudioPlayer();

      _audioPlayer.bind('ended', onAudioEnd);

      $(_me.canvas).on('remove', function () {
        _audioPlayer.trigger('pause');
      });
    }

    if (callback) {
      var p;

      var playHandler = function () {
        if (callback) {
          callback();
          callback = null;
        }

        _audioPlayer.unbind('playing', p);
      };

      p = playHandler;
      _audioPlayer.bind('playing', playHandler);
    }

    _audioPlayer.trigger('play');

    if ((typeof _time) === 'number') {
      _audioPlayer[0].currentTime = _time;

      _time = null;
    } else if (!wasPaused) {
      _audioPlayer[0].currentTime = 0;
    }

    return _audioPlayer;
  }

  function isUndoableEvent (event) {
    var type;

    if (event.type)
      type = event.type;
    else
      type = event;

    var types = ['group', DiagramTool.type, 'image', 'delete', 'text', 'clear', 'array', TableTool.type, 'bond', 'numberline', 'equation', 'numberlinetext', 'tabletext', SpreadsheetTool.type];

    _tools.forEach(function (tool) {
      if (tool.isUndoable())
        types.push(tool.getType());
    });

    return types.includes(type);
  }

  function getBoundsForAnchors (rect, size) {
    if (!size)
      size = 10 / _me.getWidth();

    var squareWidth = size;
    var halfSquareWidth = squareWidth / 2;

    return {
      topLeft: {
        left: rect.x - halfSquareWidth,
        right: rect.x + halfSquareWidth,
        top: rect.y - halfSquareWidth,
        bottom: rect.y + halfSquareWidth,
        width: squareWidth,
        height: squareWidth
      },
      topRight: {
        left: rect.x + rect.width - halfSquareWidth,
        right: rect.x + rect.width + halfSquareWidth,
        top: rect.y - halfSquareWidth,
        bottom: rect.y + halfSquareWidth,
        width: squareWidth,
        height: squareWidth
      },
      bottomLeft: {
        left: rect.x - halfSquareWidth,
        right: rect.x + halfSquareWidth,
        top: rect.y + rect.height - halfSquareWidth,
        bottom: rect.y + rect.height + halfSquareWidth,
        width: squareWidth,
        height: squareWidth
      },
      bottomRight: {
        left: rect.x + rect.width - halfSquareWidth,
        right: rect.x + rect.width + halfSquareWidth,
        top: rect.y + rect.height - halfSquareWidth,
        bottom: rect.y + rect.height + halfSquareWidth,
        width: squareWidth,
        height: squareWidth
      }
    };
  }

  _me.getBoundsForAnchors = function (event) {
    var rect = getSelectionRect(event);

    if (!rect)
      return null;

    return getBoundsForAnchors(rect);
  };

  var _rotateImage = $('<img>');
  _rotateImage.attr('alt', 'Rotate');
  _rotateImage.attr('src', '/images/Rotate.png');

  _me.getBoundsForRotateAnchor = function (rect) {
    var useRelativeCoordinates;

    if (rect.left === undefined) {
      rect = Whiteboard.getEventBounds(rect);

      useRelativeCoordinates = true;
    }

    if (rect.width === undefined) {
      rect.width = rect.right - rect.left;
      rect.height = rect.bottom - rect.top;
    }

    var result = {
      width: 28,
      height: 27 // TODO: get dimensions from image
    };

    var spacing = 10;

    if (useRelativeCoordinates) {
      result.width /= _me.getWidth();
      result.height /= _me.getHeight();
      spacing /= _me.getHeight();
    }

    result.left = rect.left + ((rect.width - result.width) / 2);
    result.top = rect.top - spacing - result.height;

    return result;
  };

  function getSelectionRect (event) {
    var bounds = Whiteboard.getEventBounds(event);

    if (!bounds)
      return null;

    var rect = { x: bounds.left, y: bounds.top, width: bounds.right - bounds.left, height: bounds.bottom - bounds.top };

    var padding = 10 / _me.getWidth();

    if (rect.width < padding || rect.height < padding) {
      rect.x -= padding;
      rect.y -= padding;
      rect.width += padding + padding;
      rect.height += padding + padding;
    }

    return rect;
  }

  function drawSelectionUI (rect, canTransform, canRotate, type) {
    var oldColor, oldWidth, oldFillStyle;

    oldColor = _me.context.strokeStyle;
    oldWidth = _me.context.lineWidth;
    oldFillStyle = _me.context.fillStyle;

    _me.setStrokeColor('black');

    var tool = _tools.find(tool => tool.constructor.type === type);

    if (!tool || tool.hasSelectionBorder()) {
      // outline
      _me.context.lineWidth = 1;
      _me.context.beginPath();
      _me.context.rect(rect.x + 0.5, rect.y + 0.5, rect.width, rect.height);
      _me.context.closePath();
      _me.context.stroke();

      // top anchor
      /*_me.context.beginPath();
          _me.context.moveTo(rect.x + (rect.width / 2), rect.y);
          _me.context.lineTo(rect.x + (rect.width / 2), rect.y - 50);
          _me.context.stroke();*/

      _me.context.lineWidth = 1;
      _me.setFillColor('white');

      // top square
      /*var x = rect.x + (rect.width / 2) - (squareWidth / 2), y = rect.y - 50 - (squareWidth / 2);
          _me.context.fillRect(x, y, squareWidth, squareWidth);
          _me.context.beginPath();
          _me.context.rect(x, y, squareWidth, squareWidth);
          _me.context.closePath();
          _me.context.stroke();*/

      if (canTransform) {
        var anchors = getBoundsForAnchors(rect, 15);
        var keys = Object.keys(anchors);

        for (var i = 0; i < keys.length; i++) {
          var anchor = anchors[keys[i]];

          _me.context.fillRect(anchor.left, anchor.top, anchor.width, anchor.height);
          _me.context.beginPath();
          _me.context.rect(anchor.left, anchor.top, anchor.width, anchor.height);
          _me.context.closePath();
          _me.context.stroke();
        }
      }
    }

    if (canRotate) {
      var _rotateBounds = _me.getBoundsForRotateAnchor({ left: rect.x, top: rect.y, width: rect.width, height: rect.height });
      _me.context.drawImage(_rotateImage[0], _rotateBounds.left, _rotateBounds.top, _rotateBounds.width, _rotateBounds.height);
    }

    _me.setStrokeColor(oldColor);
    _me.context.lineWidth = oldWidth;
    _me.setFillColor(oldFillStyle);
  }

  var publicMembers = {
    context: null,
    canvas: null,
    type: '',
    coordinates: [0,0],
    events: [[]],

    /**
     * Initializes the script by setting the default
     * values for parameters of the class.
     */
    init: function (canvas, readOnly, recordAudio) {
      _readOnly = readOnly;
      _recordAudio = recordAudio !== false;

      // set the canvas width and height
      // the offsetWidth and Height is default width and height
      this.canvas = canvas;
      this.canvas.width = this.canvas.offsetWidth;
      this.canvas.height = this.canvas.offsetHeight;

      this.context = this.canvas.getContext('2d');

      var lastResized; // TODO
      bindEvent($(window), 'resize', function () {
        var now = new Date().getTime();

        if (lastResized) {
          if (now - lastResized < 1000)
            return;
        }

        redraw();

        lastResized = now;
      });

      $(_me.canvas).on('remove', function () {
        // force users to manually stop so they don't try to play before upload finishes
        // _me.stopRecording();

        if (_audioRecorder) {
          _audioRecorder.disconnect();
          _audioRecorder = null;
        }
      });
    },

    getHighlighterSize: function () {
      return getAbsoluteLineWidth() * 4;
    },

    execute: function (wbevent, callback, skipTypes) {
      var afterCallback;
      var sx, sy, ex, ey;
      var w, h, x, y, i, j;
      var result;
      var size;

      var type = wbevent.type;

      if (typeof skipTypes === 'function' && skipTypes(wbevent) === false)
        return false;

      if (_me.isDeleted(wbevent) || isUndone(wbevent))
        return false;

      if (_hiddenEvents.indexOf(wbevent) >= 0)
        return false;

      if (callback) {
        result = callback(wbevent);

        if (result === false)
          return false;

        if ((typeof result) === 'function')
          afterCallback = result;
      }

      if (_lastUndoIndex && !skipTypes && type !== 'undo' && type !== 'redo')
        _lastUndoIndex = undefined;

      _lastEvent = wbevent;

      if (wbevent.rotate) {
        _me.context.save();
        var bounds = Whiteboard.getEventBounds(wbevent, _me.getWidth(), _me.getHeight());
        var boundsWidth = bounds.right - bounds.left;
        var boundsHeight = bounds.bottom - bounds.top;
        var deltaX = (bounds.left + (boundsWidth / 2)) * _me.getWidth();
        var deltaY = (bounds.top + (boundsHeight / 2)) * _me.getHeight();
        _me.context.translate(deltaX, deltaY);
        _me.context.rotate(wbevent.rotate);
        _me.context.translate(-deltaX, -deltaY);
      }

      if (wbevent.type === 'group') {
        result = executeGroup(wbevent, callback, skipTypes);

        if (wbevent.rotate)
          _me.context.restore();

        if (afterCallback)
          afterCallback();

        return result;
      }

      var tool = getToolForObjectType(wbevent.type);

      if (tool) {
        tool.render(wbevent);
      } else {
        if (wbevent.color) {
          _me.setStrokeColor(wbevent.color);
          _me.setFillColor(wbevent.color);
        }

        if (type === 'beginpath') {
          _me.setLineWidth(4);
          _me.setLineCap('round');

          _me.context.beginPath();
          _me.context.moveTo(wbevent.coordinates[0] * _me.getWidth(), wbevent.coordinates[1] * _me.getHeight());
          _me.context.stroke();
        } else if (type === 'drawpathtopoint') {
          _me.context.lineTo(wbevent.coordinates[0] * _me.getWidth(), wbevent.coordinates[1] * _me.getHeight());
          _me.context.stroke();
        } else if (type === 'drawhighlighterpathtopoint') {
          _me.setStrokeColor('rgba(255, 241, 0, .1)');
          _me.context.lineWidth = _me.getHighlighterSize();

          _me.context.lineTo(wbevent.coordinates[0] * _me.getWidth(), wbevent.coordinates[1] * _me.getHeight());
          _me.context.stroke();
          _me.context.lineWidth = getAbsoluteLineWidth();
        } else if (type === 'erase') {
          x = wbevent.coordinates[0] * _me.getWidth();
          y = wbevent.coordinates[1] * _me.getHeight();
          size = (20 / 1024) * _me.getWidth();

          var comp = _me.context.globalCompositeOperation;
          _me.context.globalCompositeOperation = 'destination-out';
          _me.context.beginPath();
          _me.context.arc(x, y, size, 0, Math.PI);
          _me.context.fill();
          _me.context.closePath();
          _me.context.globalCompositeOperation = comp;
        } else if (type === 'tabletext') {
          var table = _eventMap[wbevent.tableId];

          if (table)
            TableTool.renderTableText(_me, table, wbevent.text, wbevent.row, wbevent.col, wbevent.color);
        } else if (type === SpreadsheetTool.type) {
          renderSpreadsheet(wbevent.coordinates[0], wbevent.coordinates[1], wbevent.coordinates[2], wbevent.coordinates[3], wbevent.data, wbevent.rowHeights, wbevent.colWidths, wbevent.color, 16);
        } else if (type === 'array') {
          sx = wbevent.coordinates[0] * _me.getWidth();
          sy = wbevent.coordinates[1] * _me.getHeight();
          ex = wbevent.coordinates[2] * _me.getWidth();
          ey = wbevent.coordinates[3] * _me.getHeight();

          renderArray(sx, sy, ex, ey, wbevent.rows, wbevent.cols);
        } else if (type === 'numberlinetext') {
          var numberLine = _eventMap[wbevent.numberLineId];

          if (numberLine) {
            var related = getRelatedEvents(numberLine);

            if (related.includes(wbevent)) {
              var numbers = related.map(function (numberLineText) {
                return numberLineText.text;
              });

              numberLine = Object.assign({}, numberLine, { numbers: numbers });

              NumberLineTool.renderNumberLineText(_me, numberLine, 0, wbevent.text, NumberLineTool.isNumberLineHorizontal(_me, numberLine));
            }
          }
        } else if (type === 'bond') {
          drawNumberBond(wbevent);
        } else if (type === 'image' || type === 'equation') {
          var image;

          if (_loadImageCallback) {
            image = _loadImageCallback(wbevent.url);
          } else { // TODO: if image data
            image = new window.Image();
            image.src = wbevent.url;
          }

          sx = wbevent.coordinates[0] * _me.getWidth();
          sy = wbevent.coordinates[1] * _me.getHeight();
          w = wbevent.coordinates[2] * _me.getWidth();
          h = wbevent.coordinates[3] * _me.getHeight();

          if (image) {
            var imageWidth, imageHeight;

            if (image.width > image.height) {
              imageWidth = w;
              imageHeight = (imageWidth * image.height) / image.width;

              if (imageHeight > h) {
                imageHeight = h;
                imageWidth = (imageHeight * image.width) / image.height;
              }
            } else {
              imageHeight = h;
              imageWidth = (imageHeight * image.width) / image.height;

              if (imageWidth > w) {
                imageWidth = w;
                imageHeight = (imageWidth * image.height) / image.width;
              }
            }

            var img = $(image).css('width', imageWidth + 'px');
            img.css('height', imageHeight + 'px');

            var orientation = wbevent.orientation;

            _me.context.save();

            _me.context.translate(sx, sy);

            if (orientation > 1) {
              if (orientation === 2) {
                // flip around vertical axis
                _me.context.translate(imageWidth, 0);
                _me.context.scale(-1, 1);
              } else if (orientation === 3) {
                // flip both ways
                _me.context.translate(imageWidth, imageHeight);
                _me.context.scale(-1, -1);
              } else if (orientation === 4) {
                // flip around horizontal axis
                _me.context.translate(0, imageHeight);
                _me.context.scale(1, -1);
              } else if (orientation === 5) {
                // rotate 90 degrees then flip around vertical axis
                _me.context.rotate(-90 * Math.PI / 180);
                _me.context.scale(-1, 1);
              } else if (orientation === 6) {
                // rotate 90 degrees
                _me.context.translate(imageHeight, 0);
                _me.context.rotate(90 * Math.PI / 180);
              } else if (orientation === 7) {
                // rotate -90 degrees then flip around vertical axis
                _me.context.translate(imageHeight, imageWidth);
                _me.context.rotate(90 * Math.PI / 180);
                _me.context.scale(-1, 1);
              } else if (orientation === 8) {
                // rotate -90 degrees
                _me.context.translate(0, imageWidth);
                _me.context.rotate(-90 * Math.PI / 180);
              }
            }

            _me.context.drawImage(image, 0, 0, imageWidth, imageHeight);

            _me.context.restore();
          }
        } else if (type === 'clear') {
          clear();
        } else if (!skipTypes && (_me.isPlaying() || _me.isPlayingPaused())) {
          var cancel;

          if (type === 'delete') {
            var events = _recordedEvents;
            var e, indexPath;

            if (wbevent.playIndexPath)
              indexPath = wbevent.playIndexPath;
            else
              indexPath = wbevent.indexPath;

            if (indexPath.length > 1) {
              for (i = 0; i < indexPath.length - 1; i++) {
                events = events[indexPath[i]];

                if (events.type === 'group')
                  events = events.events;
              }
            }

            e = events[indexPath[indexPath.length - 1]];

            if (e && (!wbevent.event || areEventsEqual(wbevent.event, e)))
              _deletedEvents.push(e);
            else
              cancel = true;
          } else if (type === 'undo') {
            var undoEvent, isFirst, start;

            isFirst = true;

            if (_lastUndoIndex === undefined)
              start = getIndexPathForEvent(wbevent, _recordedEvents)[0] - 1;
            else
              start = _lastUndoIndex - 1;

            for (j = start; j >= 0; j--) {
              undoEvent = _recordedEvents[j];

              if (!isFirst && isUndoableEvent(undoEvent)) {
                _lastUndoIndex = j + 1;

                break;
              }

              isFirst = false;

              _undonePlayEvents.push(undoEvent);

              if (undoEvent.type === 'delete') {
                var deletedEventIndex = _deletedEvents.findIndex(e => e.id === undoEvent.event.id);

                if (deletedEventIndex >= 0)
                  _deletedEvents.splice(deletedEventIndex, 1);
              }
            }
          } else if (type === 'redo') {
            var redoEvent;

            do {
              redoEvent = _undonePlayEvents.pop();

              if (redoEvent.type === 'delete')
                _deletedEvents.push(redoEvent.event);

              _lastUndoIndex++;
            } while (!isUndoableEvent(redoEvent) && _undonePlayEvents.length > 0);
          }

          if (!cancel) {
            clear();
            redraw(null, null, true);
          }
        }
      }

      if (wbevent.rotate)
        _me.context.restore();

      // TODO: move to base tool
      // TODO: remove
      if (wbevent.selected && !_me.isPlaying()) {
        var selectionRect;

        // more precise width and height by avoiding floating point math
        if (wbevent.type === 'text') {
          selectionRect = {
            x: wbevent.coordinates[0] * _me.getWidth(),
            y: wbevent.coordinates[1] * _me.getHeight(),
            width: wbevent.coordinates[2] * _me.getWidth(),
            height: wbevent.coordinates[3] * _me.getHeight()
          };
        } else {
          selectionRect = getSelectionRect(wbevent);

          selectionRect.x *= _me.getWidth();
          selectionRect.y *= _me.getHeight();
          selectionRect.width *= _me.getWidth();
          selectionRect.height *= _me.getHeight();
        }

        if (selectionRect)
          drawSelectionUI(selectionRect, _me.canTransform(wbevent), _me.canRotate(wbevent), wbevent.type);
      }

      if (afterCallback)
        afterCallback();

      _lastDrawTime = new Date().getTime();

      return [wbevent];
    },

    /**
     * Resolves the relative width and height of the canvas
     * element. Relative parameters can vary depending on the
     * zoom. Both are equal to 1 if no zoom is encountered.
     *
     * @return An array containing the relative width as first
     * element and relative height as second.
     */
    getRelative: function () {
      return { width: _me.canvas.width / _me.canvas.offsetWidth, height: _me.canvas.height / _me.canvas.offsetHeight };
    },

    /**
     * Opens a new window and writes the canvas image data
     * to an element of type "img" in the new windows html body.
     * The image is written in the specified image form.
     */
    getImage: function (callback) {
      return  _me.canvas.toBlob(callback, 'image/png');
    },

    /* === BEGIN ACTIONS === */

    /**
     * Starts the animation action in the canvas. This clears
     * the whole canvas and queues the first action to be executed
     * in the polling timer that invokes updateCanvas()
     */
    preview: function (callback) {
      if (_me.isRecording() || _me.isRecordingPaused())
        return;

      if (_isPlaying)
        return;

      if (_ended) {
        _ended = false;

        _me.resetPlayback();
      }

      var wasPaused = _isPlayingPaused;

      _isPlaying = true;
      _isPlayingPaused = false;

      if (_me.getAudioURL())
        playAudio(function () {
          playPreview(callback);
        }, wasPaused);
      else
        playPreview(callback);
    },

    resumePreview: function () {
      if (!_me.isPlayingPaused() || _isPlaying)
        return;

      _isPlaying = true;
      _isPlayingPaused = false;

      if (_me.getAudioURL())
        playAudio(resumePreview, true);
      else
        resumePreview();
    },

    setPreviewEndedCallback: function (value) {
      _previewEndedCallback = value;
    },

    pausePreview: function (callback, shouldSetPauseTime) {
      if (!_me.isPlayingPaused()) {
        _isPlaying = false;
        _isPlayingPaused = true;

        clearInterval(_playTimer);
        _playTimer = null;

        if (_audioPlayer || _lastDrawTime) {
          if (_audioPlayer) {
            var promise = _audioPlayer[0].pause();

            if (callback) {
              if (promise)
                promise.then(callback);
              else
                setTimeout(callback, 150);

              callback = null;
            }

            if (shouldSetPauseTime !== false)
              _pausedTime = _audioPlayer[0].currentTime;
          } else if (_lastDrawTime && shouldSetPauseTime !== false) {
            var lastEvent = getEventAtIndexPath(_recordedEvents, _playIndexPath);
            var lastEventTime = Whiteboard.getTimeForEvent(lastEvent);
            var timeSinceLastEvent = (new Date().getTime() - _lastDrawTime) / 1000;
            _pausedTime = lastEventTime + timeSinceLastEvent;
          }
        }

        if (_playbackStateChangeCallback)
          _playbackStateChangeCallback(_me, 'paused');
      }

      if (callback)
        callback();
    },

    stopPreview: function () {
      _me.resetPlayback();

      if (_audioPlayer)
        _audioPlayer.trigger('pause');

      if (_playbackStateChangeCallback)
        _playbackStateChangeCallback(_me, 'stopped');
    },

    isPlayingPaused: function () {
      return !!_isPlayingPaused;
    },

    isRecordingPaused: function () {
      return !!_isRecordingPaused;
    },

    isPlaying: function () {
      return !!_isPlaying;
    },

    getAudioURL: function () {
      return _audioURL || (_audioRecorder ? _audioRecorder.getURL() : null);
    },

    /**
     * Begins a drawing path.
     *
     * @param x Coordinate x of the path starting point
     * @param y Coordinate y of the path starting point
     */
    beginPencilDraw: function(x, y) {
      var e = new BeginPath(x, y, _color);
      _group = new Group([e]);
      addEvent(_group);
    },

    /**
     * Draws a path from the path starting point to the
     * point indicated by the given parameters.
     *
     * @param x Coordinate x of the path ending point
     * @param y Coordinate y of the path ending point
     */
    pencilDraw: function(x, y) {
      var e = new DrawPathToPoint(x, y);
      _group.events.push(e);
      e.parentEvent = _group;
      _me.execute(e);
    },

    beginHighlighterDraw: function (x, y) {
      var e = new BeginPath(x, y, _color);
      _group = new Group([e]);
      addEvent(_group);
    },

    highlighterDraw: function (x, y) {
      var e = new DrawHighlighterPathToPoint(x, y);
      _group.events.push(e);
      e.parentEvent = _group;
      _me.execute(e);
    },

    /**
     * Begins erasing path.
     *
     * @param x Coordinate x of the path starting point
     * @param y Coordinate y of the path starting point
     */
    beginErasing: function(x, y) {
      var e = new BeginPath(x, y);

      addEvent(e);
    },

    /**
     * Erases the point indicated by the given coordinates.
     * Actually this doesn't take the path starting point
     * into account but erases a rectangle at the given
     * coordinates with width and height specified in the
     * Erase object.
     *
     * @param x Coordinate x of the path ending point
     * @param y Coordinate y of the path ending point
     */
    erasePoint: function(x, y) {
      var e = new Erase(x, y);

      addEvent(e);
    },

    drawText: function (x, y, width, height, text) {
      var cls = _textTool.getDataConstructor();
      var e = new cls(x, y, width, height, text, _color);

      addEvent(e);

      return e;
    },

    drawNumberLine: function (sx, sy, ex, ey, numbers, isVertical) {
      var cls = _numberLineTool.getDataConstructor();
      var e = new cls(sx, sy, ex, ey, numbers, _color, isVertical);

      addEvent(e);

      return e;
    },

    drawGrid: function (sx, sy, ex, ey, xNumbers, yNumbers, type) {
      var cls = _gridTool.getDataConstructor();
      var e = new cls(sx, sy, ex, ey, xNumbers, yNumbers, type === 'open' ? 'rgb(38, 169, 224)' : 'lightgray', type);

      addEvent(e);

      return e;
    },

    drawUnifixCube: function (sx, sy, ex, ey, isHorizontal, color, strokeColor) {
      var cls = _unifixCubeTool.getDataConstructor();
      var e = new cls(sx, sy, ex, ey, isHorizontal, color, strokeColor);

      addEvent(e);

      return e;
    },

    drawAlgebraTile: function (sx, sy, type) {
      var cls = _algebraTileTool.getDataConstructor();
      var e = new cls(sx, sy, type);

      addEvent(e);

      return e;
    },

    drawFractionBar: function (sx, sy, type) {
      var cls = _fractionBarTool.getDataConstructor();
      var e = new cls(sx, sy, type);

      addEvent(e);

      return e;
    },

    drawProtractor: function (sx, sy, ex, ey) {
      var cls = _protractorTool.getDataConstructor();
      var obj = new cls(sx, sy, ex, ey);

      addEvent(obj);

      return obj;
    },

    drawTable: function (sx, sy, ex, ey, rows, cols) {
      var cls = _tableTool.getDataConstructor();
      var e = new cls(sx, sy, ex, ey, rows, cols, _color);

      addEvent(e);

      return e;
    },

    drawSpreadsheet: function (sx, sy, ex, ey, rows, cols) {
      var cls = _spreadsheetTool.getDataConstructor();
      var e = new cls(sx, sy, ex, ey, rows, cols);

      addEvent(e);

      return e;
    },

    drawArray: function (x, y, ex, ey, rows, cols) {
      var sx, sy;

      sx = x;
      sy = y;

      var w = (ex - sx) * _me.getWidth();
      var size = w / ((cols * 2) - 1);

      var bottom = sy + ((size * ((rows * 2) - 1)) / _me.getHeight());

      if (bottom <= ey) {
        ey = bottom;
      } else {
        size = (ey - sy) / ((rows * 2) - 1);

        ex = sx + (((size * ((cols * 2) - 1)) * _me.getHeight()) / _me.getWidth());
      }

      var e = new ArrayShape(sx, sy, ex, ey, rows, cols, _color);

      addEvent(e);

      return e;
    },

    drawBond: function (x, y, ex, ey) {
      var e = new Bond(x, y, ex, ey, _color);

      addEvent(e);

      return e;
    },

    drawDiagram: function (x, y, ex, ey, type) {
      var cls = _diagramTool.getDataConstructor();
      var e = new cls(x, y, ex, ey, type, _color);

      addEvent(e);

      return e;
    },

    /**
     * Sets stroke style for the canvas. Stroke
     * style defines the color with which every
     * stroke should be drawn on the canvas.
     *
     * @param color The wanted stroke color
     */
    setStrokeStyle: function(color) {
      if (color !== _color)
        _color = color;
    },

    getStrokeStyle: function () {
      return _color;
    },

    /**
     * This removes the last event from this events
     * and redraws (it can be made more
     * effective then to redraw but time is limited)
     */

    undo: function () {
      var events;

      events = _me.getEventsForCurrentPage();

      if (events.length === 0)
        return;

      var reverseEvent;

      do {
        reverseEvent = events.pop();

        if (reverseEvent.type === 'delete') {
          if (reverseEvent.indexPath.length === 1) {
            events.splice(reverseEvent.indexPath[0], 0, reverseEvent.event);
          } else if (reverseEvent.indexPath.length > 1) {
            var event = getEventAtIndexPath(events, reverseEvent.indexPath.slice(reverseEvent.indexPath.length - 1));

            event.events.splice(reverseEvent.indexPath[reverseEvent.indexPath.length - 1], 0, reverseEvent.event);
          }

          _eventMap[reverseEvent.event.id] = reverseEvent.event;

          _selectIndex.insert(_currentPage, reverseEvent.event);
        } else if (reverseEvent.type === 'clear') {
          indexEvents(_currentPage, events);
        } else {
          _selectIndex.remove(_currentPage, reverseEvent);
        }

        _undoneEvents.push([_currentPage, reverseEvent]);

        if (typeof reverseEvent.tableId !== 'undefined') {
          var related = _relatedEvents[reverseEvent.tableId];

          related.splice(related.indexOf(reverseEvent, 1));
        }
      } while (!isUndoableEvent(reverseEvent) && events.length > 0);

      clear();
      redraw();

      if (_me.isRecording() || _me.isRecordingPaused())
        _recordedEvents.push(new Undo());

      for (var i = 0; i < events.length; i++)
        if (isUndoableEvent(events[i]))
          return true;

      return false;
    },

    redo: function() {
      if (_undoneEvents.length === 0)
        return false;

      var reverseEvent, drawingPath, events;

      do {
        var event;

        event = _undoneEvents[_undoneEvents.length - 1][1];

        if (!event || (event.type === 'beginpath' && drawingPath))
          break;

        reverseEvent = _undoneEvents.pop();

        events = _me.events[reverseEvent[0]];
        events.push(reverseEvent[1]);
        reverseEvent = reverseEvent[1];

        if (reverseEvent.type === 'beginpath') {
          drawingPath = true;
        } else if (reverseEvent.type === 'delete') {
          var pageEvents = _me.getEventsForCurrentPage();

          if (reverseEvent.indexPath.length === 1) {
            pageEvents.splice(reverseEvent.indexPath[0], 1);
          } else if (reverseEvent.indexPath.length > 1) {
            var group = getEventAtIndexPath(pageEvents, reverseEvent.indexPath.slice(0, reverseEvent.indexPath.length - 1));

            group.events.splice(reverseEvent.indexPath[reverseEvent.indexPath.length - 1], 1);
          }

          delete _eventMap[reverseEvent.event.id];

          _selectIndex.remove(_currentPage, reverseEvent.event);
        } else if (reverseEvent.type === 'clear') {
          _selectIndex.clear(_currentPage);
        } else {
          _selectIndex.insert(_currentPage, reverseEvent);
        }

        if (typeof reverseEvent.tableId !== 'undefined')
          _relatedEvents[reverseEvent.tableId].push(reverseEvent);
      } while ((drawingPath || !isUndoableEvent(reverseEvent)) && _undoneEvents.length > 0);

      clear();
      redraw();

      if (_me.isRecording() || _me.isRecordingPaused())
        _recordedEvents.push(new Redo());

      for (var i = 0; i < _undoneEvents.length; i++)
        if (isUndoableEvent(_undoneEvents[i][1]))
          return true;

      return false;
    },

    startRecording: function (beganCallback) {
      var paused;

      if (_isRecording)
        return false;

      _isRecording = true;

      if (_isRecordingPaused) {
        paused = true;

        _isRecordingPaused = false;

        var setTime = function (event) {
          if (event.time)
            return false;

          if (event.time === undefined)
            updateEventTime(event, 0);
        };

        Whiteboard.crawlEvents(_recordedEvents, setTime, setTime, true);
      } else {
        _recordedTime = 0;

        _recordedEvents = [];

        var pageEvents = _me.getEventsForCurrentPage();

        if (pageEvents.length > 0) {
          var events = getEventsSinceLastClear(pageEvents);

          Whiteboard.crawlEvents(events, makeInstantaneousWithZeroTime, makeInstantaneousWithZeroTime);

          for (var i = 0; i < events.length; i++)
            _recordedEvents.push(events[i]);
        }
      }

      if (!_readOnly && _recordAudio) {
        if (!_audioRecorder) {
          _audioRecorder = new AudioRecorder();
          _audioRecorder.setLogCallback(_logCallback);
        }

        var audioCallback = function (success) {
          _startRecordingTime = new Date().getTime();

          if (!success) {
            if (_logCallback)
              _logCallback('Audio Recording Error', _audioRecorder.getError());

            if (_audioRecordingErrorCallback)
              _audioRecordingErrorCallback(_audioRecorder.getError());

            if (_microphoneNotAvailableCallback)
              _microphoneNotAvailableCallback();
          }

          if (beganCallback)
            beganCallback();
        };

        if (paused) {
          return _audioRecorder.resume(audioCallback);
        } else {
          return _audioRecorder.start(audioCallback);
        }
      }

      _startRecordingTime = new Date().getTime();

      if (beganCallback)
        beganCallback();

      return true;
    },

    pauseRecording: function () {
      if (!_isRecording)
        return false;

      _isRecordingPaused = true;
      _isRecording = false;

      _recordedTime += new Date().getTime() - _startRecordingTime;

      if (_audioRecorder)
        _audioRecorder.pause();

      return true;
    },

    stopRecording: function () {
      if (!_isRecording && !_isRecordingPaused)
        return false;

      _isRecording = _isRecordingPaused = false;

      _me.deselect();

      if (_audioRecorder && (_audioRecorder.isRecording() || _audioRecorder.isPaused())) {
        _audioRecorder.stop(function (audio) {
          saveRecording(audio);

          _audioRecorder = null;
        });
      } else {
        saveRecording();
      }

      return true;
    },

    getRecordedEvents: function (returnPromise) {
      var doWork = function () {
        var result;

        result = cloneEvents(_recordedEvents);

        function callback (event) {
          deselectEvent(event);

          delete event.parentEvent;
        }

        Whiteboard.crawlEvents(result, callback, callback, undefined, undefined, undefined, undefined, true);

        Whiteboard.crawlEvents(result, function (event) {
          if (event.type === 'drawpathtopoint' && !event.coordinates) {
            alert('NO COORDINATES');

            return false;
          }
        }, undefined, undefined, undefined, undefined, undefined, true);

        return result;
      };

      if (returnPromise) {
        return new Promise(function (resolve, reject) {
          var result = doWork();

          if (result === false)
            reject(result);
          else
            resolve(result);

          return result;
        });
      } else {
        return doWork();
      }
    },

    setRecordedEvents: function (value) {
      if (!Array.isArray(value))
        value = [];

      Whiteboard.crawlEvents(value, mapEventAndSetParent, mapEventAndSetParent);

      _recordedEvents = value;
    },

    isRecording: function () {
      return _isRecording;
    },

    setRecordingCallback: function (value) {
      _recordingCallback = value;
    },

    setEvents: function (events) {
      if (events)
        processEvents(events);

      _me.events = events ? events : [[]];

      _currentPage = 0;
    },

    setAudioURL: function (url) {
      _duration = null;

      _audioURL = url;
    }
    /* === END ACTIONS === */
  };

  {
    var keys = Object.keys(publicMembers);

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      this[key] = publicMembers[key];
    }
  }

  // TODO: what if loading is in progress?
  _me.loadAudio = function () {
    return new Promise(function (resolve, reject) {
      if (!_audioURL) {
        resolve(null);

        return;
      }

      var audio = Whiteboard.getSharedAudioPlayer();
      var l, e;

      if (audio.attr('src') === _audioURL) {
        resolve(_audioURL);

        return;
      }

      function loadHandler () {
        // wait until duration is not infinity
        if (audio[0].duration === Infinity) {
          audio[0].currentTime = 10000000 * Math.random();

          setTimeout(loadHandler, 1000);

          return;
        }

        _duration = audio[0].duration;

        if (!audio[0].paused)
          audio[0].currentTime = 0;

        audio.unbind('durationchange', l);

        if (_playbackStateChangeCallback)
          _playbackStateChangeCallback(_me, 'loaded');

        resolve(_audioURL);
      }

      l = loadHandler;

      audio.bind('durationchange', l);

      function errorHandler (event) {
        console.log(event);

        _duration = 0; // old thinklets recorded with a 0-duration audio file will generate an error when loaded

        audio.unbind('error', e);

        if (_playbackStateChangeCallback)
          _playbackStateChangeCallback(_me, 'loaded');

        reject(event);
      }

      e = errorHandler;

      audio.bind('error', e);

      audio.attr('src', _audioURL);

      audio.trigger('load');
    });
  };

  function clear () {
    _me.context.clearRect(0, 0, _me.canvas.width, _me.canvas.height);
  }

  function saveRecording (audio) {
    if (_recordingCallback) {
      var events, recordedEvents, promises = [];

      var promise = _me.getEvents().then(function (e) {
        events = e;

        return e;
      });

      promises.push(promise);

      promise = _me.getRecordedEvents(true).then(function (e) {
        recordedEvents = e;

        return e;
      });

      promises.push(promise);

      Promise.all(promises).then(function () {
        _recordingCallback(_me, events, recordedEvents, audio);
      });
    }
  }

  _me.getWidth = function () {
    return _me.canvas.width;
  };

  _me.getHeight = function () {
    return _me.canvas.height;
  };

  _me.addImage = function (x, y, w, h, url, orientation, isStampResizable) {
    var img = new Image(x, y, w, h, url, orientation, isStampResizable);

    addEvent(img);

    return img;
  };

  _me.addEquation = function (x, y, w, h, url, orientation, mathML) {
    var img = new Equation(x, y, w, h, url, orientation, mathML);

    addEvent(img);

    return img;
  };

  var _loadImageCallback;
  _me.setImageLoadCallback = function (value) {
    _loadImageCallback = value;
  };

  function recordEvent (event) {
    if (_me.isRecording() || _me.isRecordingPaused())
      _recordedEvents.push(event);
  }

  function addEvent (event, isTemporary, shouldExecute) {
    if (event.id === undefined)
      event.id = _nextID++;

    _eventMap[event.id] = event;

    _me.getEventsForCurrentPage().push(event);

    updateRelatedEvents(event);

    if (shouldExecute !== false)
      _me.execute(event);

    if (!isTemporary)
      recordEvent(event);

    _undoneEvents.splice(0);

    if (event.type !== 'group' && !isTemporary)
      _selectIndex.insert(_currentPage, event);

    if (_onChangeCallback)
      _onChangeCallback(event);
  }

  _me.addEvent = addEvent;

  function updateRelatedEvents (event) {
    if (event.type === 'tabletext' || event.type === 'numberlinetext') {
      if (_relatedEvents) {
        var key;

        if (event.type === 'tabletext')
          key = 'tableId';
        else
          key = 'numberLineId';

        if (!_relatedEvents[event[key]])
          _relatedEvents[event[key]] = [];

        _relatedEvents[event[key]].push(event);
      }
    }
  }

  var asdfasdfasdf, _postSelectCanvas;
  function selectEvent (event, sx, sy, ex, ey) {
    _me.deselect();

    event.selected = true;

    _selectedEvent = asdfasdfasdf = event;

    function saveStateBeforeSelect (event) {
      if (event === _selectedEvent) {
        _savedCanvas = saveCanvas();

        return clear;
      }
    }

    clear();

    // find selected object while redrawing and save the canvas before it is drawn and then clear
    var idKey;

    if (event.type === TableTool.type)
      idKey = 'tableId';
    else if (event.type === 'numberline')
      idKey = 'numberLineId';

    redraw(undefined, saveStateBeforeSelect, function (e) {
      if (e[idKey] === event.id)
        return false;
    });

    // save the objects after the select object
    // TODO: only redraw non-object text events
    _postSelectCanvas = saveCanvas();

    // draw bottom layer (anything drawn before selected object)
    restoreCanvas(_savedCanvas);

    // draw selected object with outline
    _me.execute(_selectedEvent);

    redrawRelatedEvents();

    // draw objects after selected object
    restoreCanvas(_postSelectCanvas, false);

    // TODO: redraw all erases since erase is not included in restoreCanvas. keep track of erases in separate array?

    if (_selectCallback) {
      if (sx === undefined) {
        var bounds = Whiteboard.getEventBounds(event, _me.getWidth(), _me.getHeight());

        if (!bounds)
          return false;

        sx = bounds.left;
        sy = bounds.top;
        ex = bounds.right;
        ey = bounds.bottom;
      }

      _selectCallback({ left: sx, top: sy, width: ex - sx, height: ey - sy });
    }
  }

  function deselectEvent (event) {
    delete event.selected;
  }

  function attemptToSelectEvents (x, y, type, dryRun) {
    var result = retrieveFromSelectIndex(x, y, type);

    if (!result)
      return null;

    if (dryRun) {
      return result;
    } else {
      if (result.selected) {
        deselectEvent(result);

        _selectedEvent = null;

        clear();
        redraw();

        if (_deselectCallback)
          _deselectCallback();
      } else {
        selectEvent(result, result.x, result.y, result.x + result.width, result.y + result.height);

        return result;
      }
    }
  }

  function retrieveFromSelectIndex (x, y, type) {
    return _selectIndex.retrieve(_currentPage, x, y, type);
  }

  _me.getObjectAtPoint = function (x, y, type, cell) {
    var event, result;

    var callback = function (e) {
      if (e.type === 'clear')
        return false;

      if (e.type === 'numberline') {
        result = _me.getNumberLineCell(e, x, y);

        if (result) {
          event = e;

          if (cell) {
            cell.x = result.x;
            cell.y = result.y;
            cell.width = result.width;
            cell.height = result.height;
            cell.text = result.text;
            cell.i = result.i;
          }

          return false;
        }
      }
    };

    Whiteboard.crawlEvents(_me.getEventsForCurrentPage(), callback, callback, true);

    if (!event) {
      event = attemptToSelectEvents(x, y, type, true);

      if (event && cell && event.type === TableTool.type) {
        result = _me.getTableCell(event, x, y);

        if (result) {
          cell.x = result.x;
          cell.y = result.y;
          cell.width = result.width;
          cell.height = result.height;
          cell.text = result.text;
          cell.row = result.row;
          cell.col = result.col;
        }
      }
    }

    return event;
  };

  // TODO: recalculate last clear index path when page changes

  _me.select = function (x, y) {
    if (x && x.type) {
      selectEvent(x);

      return true;
    }

    var result = attemptToSelectEvents(x, y);

    if (result !== null)
      return result;

    _me.deselect();

    return false;
  };

  _me.deselect = function () {
    if (!_selectedEvent)
      return;

    deselectEvent(_selectedEvent);

    function evaluateEventForDeselection (event) {
      if (event.selected)
        deselectEvent(event);
    }

    if (_me.isRecording() || _me.isRecordingPaused())
      Whiteboard.crawlEvents(_recordedEvents, evaluateEventForDeselection, evaluateEventForDeselection);

    for (var i = 0; i < _me.events.length; i++)
      Whiteboard.crawlEvents(_me.events[i], evaluateEventForDeselection, evaluateEventForDeselection);

    _selectedEvent = null;

    clear();
    redraw();

    _savedCanvas = _postSelectCanvas = null;
  };

  _me.isReadyToPlay = function () {
    return !_me.isPlaying() && !_me.isRecording() && _recordedEvents && _recordedEvents.length > 0 && (_audioURL || _audioURL === false);
  };

  _me.resetPlayback = function () {
    clearInterval(_playTimer);
    _playTimer = null;

    _groupIndex = 0;
    _playbackIndex = 0;
    _time = 0;
    _isPlaying = _isPlayingPaused = false;
    _deletedEvents.splice(0);
    _undonePlayEvents.splice(0);
    _playIndexPath.splice(0);
    _nextEvent = null;
    _lastUndoIndex = undefined;

    clear();
  };

  function cloneEvents(events) {
    let lastObj;

    return cloneDeepWith(events, (value, key, obj) => {
      const oldParentEvent = value.parentEvent;

      delete value.parentEvent;

      const result = cloneDeep(value);

      value.parentEvent = oldParentEvent;

      result.parentEvent = lastObj;

      if (typeof value === 'object')
        lastObj = value;

      return result;
    });
  }

  _me.getEvents = function () {
    return new Promise(function (resolve) {
      var result = cloneEvents(_me.events);

      function callback (event) {
        deselectEvent(event);

        delete event.parentEvent;
      }

      for (var i = 0; i < result.length; i++)
        Whiteboard.crawlEvents(result[i], callback, callback, undefined, undefined, undefined, undefined, true);

      resolve(result);

      return result;
    });
  };

  var _playbackStateChangeCallback;
  _me.setPlaybackStateChangeCallback = function (value) {
    _playbackStateChangeCallback = value;
  };

  _me.advancePage = function () {
    _me.deselect();

    _currentPage++;

    if (_currentPage >= _me.events.length) {
      _me.events.push([]);
    }

    clear();
    redraw();

    recordPageChange();
  };

  _me.reversePage = function () {
    if (_currentPage === 0)
      return;

    _me.deselect();

    _currentPage--;

    clear();
    redraw();

    recordPageChange();
  };

  _me.getTime = function () {
    if (_playIndexPath.length === 0)
      return 0;

    return Whiteboard.getTimeForEvent(getEventAtIndexPath(_recordedEvents, _playIndexPath));
  };

  _me.hasEnded = function () {
    return _ended;
  };

  function recordPageChange () {
    if (_me.isRecording() || _me.isRecordingPaused()) {
      var clear = new Clear();

      _recordedEvents.push(clear);

      var events = getEventsSinceLastClear(_me.getEventsForCurrentPage());

      var t = _me.getRecordedTime();

      var makeInstantaneous = function (event) {
        event.time = t;
        event.isInstantaneous = true;
      };

      Whiteboard.crawlEvents(events, makeInstantaneous, makeInstantaneous);

      for (var i = 0; i < events.length; i++)
        _recordedEvents.push(events[i]);
    }
  }

  _me.getPage = function () {
    return _currentPage;
  };

  _me.getPageCount = function () {
    return _me.events.length;
  };

  function getEventAtTime (time) {
    var result;

    if (!_recordedEvents || _recordedEvents.length === 0)
      return null;

    time *= 1000;

    Whiteboard.crawlEvents(_recordedEvents, function (event) {
      if (!event.isInstantaneous && event.time > time)
        return false;

      result = event;
    });

    return result;
  }

  _me.getDuration = function () {
    if (_duration)
      return _duration;

    if (_audioURL && _duration === undefined) // wait on audio to load to get duration
      return false;

    if (!_recordedEvents || _recordedEvents.length === 0)
      return false;

    return Whiteboard.getDuration(_recordedEvents);
  };

  function getEventHierarchyUntilEvent (topEvents, event, childEvents) {
    var result, events;

    if (event.parentEvent)
      events = event.parentEvent.events;
    else
      events = topEvents;

    result = events.slice(0, events.indexOf(event) + 1);

    if (childEvents) {
      var e = result[result.length - 1];

      e = cloneEvents(e);
      e.events = childEvents;
      result[result.length - 1] = e;
    }

    if (event.parentEvent)
      return getEventHierarchyUntilEvent(topEvents, event.parentEvent, result);
    else
      return result;
  }

  _me.setCurrentEvent = function (event, pauseCallback) {
    var wasPlaying;

    clear();

    wasPlaying = _me.isPlaying();

    _me.pausePreview(function () {
      if (pauseCallback)
        pauseCallback();

      _seekedPlayback = true;

      clear();

      _deletedEvents.splice(0);
      _undonePlayEvents.splice(0);

      Whiteboard.crawlEvents(_recordedEvents, function (e) {
        if (areEventsEqual(e, event)) {
          _playIndexPath = getIndexPathForEvent(event, _recordedEvents);
          _nextEvent = getEventAtNextIndexPath(_recordedEvents, _playIndexPath);
          _lastUndoIndex = undefined;

          redraw();

          return false;
        }
      });

      if (wasPlaying)
        _me.resumePreview();
    }, false);
  };

  _me.setTime = function (time) {
    _lastDrawTime = null;

    var event = getEventAtTime(time); // TODO: reuse crawl

    if (event) {
      _me.setCurrentEvent(event, function () {
        _pausedTime = time;
      });
    }

    if (_audioPlayer)
      _audioPlayer[0].currentTime = time;
    else
      _time = time;

    _ended = false;
  };

  _me.setProgress = function (value) {
    var duration = _me.getDuration();

    if (duration === false)
      return false;

    _me.setTime(value * duration);
  };

  _me.setOnChangeCallback = value => _onChangeCallback = value;

  _me.setPlaybackEventChangeCallback = function (value) {
    _playbackEventChangeCallback = value;
  };

  _me.setSelectCallback = function (value) {
    _selectCallback = value;
  };

  _me.setDeselectCallback = function (value) {
    _deselectCallback = value;
  };

  function getRelatedEvents (event) {
    var related = _relatedEvents[event.id];
    var results;

    if (related && event.type === 'numberline') {
      var map = {};

      results = [];

      related.forEach(function (event) {
        map[event.index] = event;
      });

      var keys = Object.keys(map);

      keys.forEach(function (key) {
        results.push(map[key]);
      });
    }

    if (!results)
      results = related;

    return results;
  }

  function redrawRelatedEvents () {
    if (!_selectedEvent)
      return;

    var related = getRelatedEvents(_selectedEvent);

    if (related) {
      for (var i = 0; i < related.length; i++) {
        var event = related[i];

        _me.execute(event);
      }
    }
  }

  _me.move = function (x, y) {
    if (!_selectedEvent)
      return;

    if (_selectedEvent.type === 'group') {
      translate(_selectedEvent, x, y);

      crawlGroup(_selectedEvent, function (event) {
        translate(event, x, y);
      });
    } else {
      translate(_selectedEvent, x, y);
    }

    restoreCanvas(_savedCanvas);

    _me.execute(_selectedEvent);

    redrawRelatedEvents();

    restoreCanvas(_postSelectCanvas, false);

    _selectIndex.update(_currentPage, _selectedEvent);

    // TODO: when finish moving add a new object to recorded events
  };

  _me.rotate = function (angle) {
    if (!_selectedEvent || !_me.canRotate(_selectedEvent))
      return;

    if (_selectedEvent.rotate === undefined)
      _selectedEvent.rotate = 0;

    var newAngle = (_selectedEvent.rotate + angle) % (Math.PI * 2);

    _selectedEvent.rotate = newAngle;

    restoreCanvas(_savedCanvas);

    _me.execute(_selectedEvent);

    redrawRelatedEvents();

    restoreCanvas(_postSelectCanvas, false);

    _selectIndex.update(_currentPage, _selectedEvent);

    // TODO: when finish rotating add a new object to recorded events
  };

  _me.canTransform = function (event) {
    var tool = _tools.find(tool => tool.constructor.type === event.type);

    if (tool)
      return tool.isTransformable(event);
    else if (event.hasOwnProperty('resizable'))
      return event.resizable;

    return event.type !== 'group';
  };

  _me.canRotate = function (event) {
    var tool = _tools.find(function (tool) {
      return tool.constructor.type === event.type;
    });

    if (tool)
      return tool.isRotatable(event);

    return _me.canTransform(event) && event.type !== 'equation';
  };

  function adjustObjectForResize (object, scaleX, scaleY) {
    if (scaleY === undefined)
      scaleY = scaleX;

    if (object.type === SpreadsheetTool.type) {
      var widths = object.colWidths;
      var heights = object.rowHeights;

      for (i = 0; i < widths.length; i++)
        widths[i] *= scaleX;

      for (var i = 0; i < heights.length; i++)
        heights[i] *= scaleY;
    }
  }

  _me.resize = function (x, y, corner) {
    if (!_selectedEvent || !_me.canTransform(_selectedEvent))
      return;

    var coordinates = _selectedEvent.coordinates;

    var sx = coordinates[0];
    var sy = coordinates[1];
    var ex, ey;

    if ([EllipseTool.type, 'image', 'equation', TextTool.type].indexOf(_selectedEvent.type) !== -1) {
      ex = sx + coordinates[2];
      ey = sy + coordinates[3];
    } else {
      ex = coordinates[2];
      ey = coordinates[3];
    }

    var oldWidth = ex - sx;
    var oldHeight = ey - sy;

    if (corner === 'topLeft') {
      if (x < ex)
        sx = x;
      else
        sx = ex;

      if (y < ey)
        sy = y;
      else
        sy = ey;
    } else if (corner === 'topRight') {
      if (x > sx)
        ex = x;
      else
        ex = sx;

      if (y < ey)
        sy = y;
      else
        sy = ey;
    } else if (corner === 'bottomLeft') {
      if (x < ex)
        sx = x;
      else
        sx = ex;

      if (y > sy)
        ey = y;
      else
        ey = sy;
    } else if (corner === 'bottomRight') {
      if (x > sx)
        ex = x;
      else
        ex = sx;

      if (y > sy)
        ey = y;
      else
        ey = sy;
    }

    var scaleX = (ex - sx) / oldWidth;
    var scaleY = (ey - sy) / oldHeight;

    adjustObjectForResize(_selectedEvent, scaleX, scaleY);

    coordinates[0] = sx;
    coordinates[1] = sy;

    if ([EllipseTool.type, 'image', 'equation', TextTool.type].indexOf(_selectedEvent.type) !== -1) {
      coordinates[2] = ex - sx;
      coordinates[3] = ey - sy;
    } else {
      coordinates[2] = ex;
      coordinates[3] = ey;
    }

    restoreCanvas(_savedCanvas);

    _me.execute(_selectedEvent);

    redrawRelatedEvents();

    restoreCanvas(_postSelectCanvas, false);

    _selectIndex.update(_currentPage, _selectedEvent);

    // TODO: add object to record resize
  };

  _me.scale = function (factor) {
    if (!_selectedEvent || !_me.canTransform(_selectedEvent))
      return;

    var coordinates = _selectedEvent.coordinates;
    var sx = coordinates[0];
    var sy = coordinates[1];
    var w, h;

    if (_selectedEvent.type === EllipseTool.type || _selectedEvent.type === 'image' || _selectedEvent.type === 'equation') {
      w = coordinates[2];
      h = coordinates[3];
    } else {
      w = coordinates[2] - sx;
      h = coordinates[3] - sy;
    }

    var newWidth = w * factor;
    var newHeight = h * factor;
    var widthDelta = newWidth - w;
    var heightDelta = newHeight - h;
    var halfWidthDelta = widthDelta / 2;
    var halfHeightDelta = heightDelta / 2;

    sx -= halfWidthDelta;
    sy -= halfHeightDelta;

    adjustObjectForResize(_selectedEvent, factor);

    coordinates[0] = sx;
    coordinates[1] = sy;

    if (_selectedEvent.type === EllipseTool.type || _selectedEvent.type === 'image' || _selectedEvent.type === 'equation') {
      coordinates[2] = newWidth;
      coordinates[3] = newHeight;
    } else {
      coordinates[2] += halfWidthDelta;
      coordinates[3] += halfHeightDelta;
    }

    restoreCanvas(_savedCanvas);

    _me.execute(_selectedEvent);

    redrawRelatedEvents();

    restoreCanvas(_postSelectCanvas, false);
  };

  function crawlGroup (group, callback) {
    for (var i = 0; i < group.events.length; i++) {
      var event = group.events[i];

      if (event.type === 'group')
        crawlGroup(event);
      else
        callback(event);
    }
  }

  function translate (event, x, y) {
    event.coordinates[0] += x;
    event.coordinates[1] += y;

    var shapeTypes = [RectangleTool.type, TriangleTool.type, LineTool.type, CurvedArrowTool.type, VerticalCurvedArrowTool.type, RightTriangleTool.type, PentagonTool.type, HexagonTool.type, TrapezoidTool.type, ParallelogramTool.type, OctagonTool.type];
    var types = shapeTypes.concat([DiagramTool.type, 'group', 'array', TableTool.type, 'bond', 'numberline', SpreadsheetTool.type, GridTool.type, UnifixCubeTool.type, UnifixCubeStackTool.type, ProtractorTool.type, AlgebraTileTool.type, FractionBarTool.type]);

    if (types.includes(event.type)) {
      event.coordinates[2] += x;
      event.coordinates[3] += y;
    }
  }

  _me.clear = function () {
    var e = new Clear();

    clear();

    addEvent(e);

    _selectIndex.clear(_currentPage);
  };

  function executeGroup (wbevent, callback, skipTypes) {
    var result = [];

    for (var i = 0; i < wbevent.events.length; i++) {
      var events = _me.execute(wbevent.events[i], callback, skipTypes);

      for (var j = 0; j < events.length; j++)
        result.push(events[j]);
    }

    if (wbevent.selected && !_me.isPlaying()) {
      var selectionRect = getSelectionRect(wbevent);

      selectionRect.x *= _me.getWidth();
      selectionRect.y *= _me.getHeight();
      selectionRect.width *= _me.getWidth();
      selectionRect.height *= _me.getHeight();

      if (selectionRect)
        drawSelectionUI(selectionRect);
    }

    return result;
  }

  function areEventsEqual (a, b) {
    return a.id === b.id && a.type === b.type && (a.isInstantaneous || b.isInstantaneous || a.time === b.time);
  }

  _me.getEventsForCurrentPage = function () {
    return _me.events[_currentPage];
  };

  _me.drawCurrentPage = function () {
    redraw();
  };

  function getIndexPathForEvent (event, events) {
    var result = [];

    while (event.parentEvent) {
      result.unshift(event.parentEvent.events.indexOf(event));

      event = event.parentEvent;
    }

    result.unshift(events.indexOf(event));

    return result;
  }

  _me.deleteEvent = function (event) {
    var events, indexPath;

    if (event.parentEvent) {
      events = event.parentEvent.events;
    } else {
      events = _me.getEventsForCurrentPage();
    }

    indexPath = getIndexPathForEvent(event, events);

    var i = events.indexOf(event);

    if (i === -1) {
      // alert('i == -1');

      return;
    }

    events.splice(i, 1);

    deselectEvent(event);

    _selectedEvent = null;
    delete _eventMap[event.id];

    var playIndexPath;

    if (_me.isRecording() || _me.isRecordingPaused()) {
      var matchEvent = function (ev) {
        if (areEventsEqual(ev, event))
          return getIndexPathForEvent(ev, _recordedEvents);
      };

      playIndexPath = Whiteboard.crawlEvents(_recordedEvents, matchEvent, matchEvent);

      if (!playIndexPath)
        alert('playIndexPath is undefined!');
    }

    var deleteEvent = new Delete(event, indexPath, playIndexPath);
    addEvent(deleteEvent, false, false);

    restoreCanvas(_savedCanvas);

    restoreCanvas(_postSelectCanvas, false);

    _savedCanvas = _postSelectCanvas = null;

    _selectIndex.remove(_currentPage, event);

    return deleteEvent;
  };

  _me.getSelectedEvent = function () {
    return _selectedEvent;
  };

  _me.setFillColor = function (value) {
    if (!value)
      value = 'transparent';

    _me.context.fillStyle = value;
  };

  _me.setStrokeColor = function (value) {
    if (!value)
      value = 'transparent';

    _me.context.strokeStyle = value;
  };

  _me.setLineWidth = function (value) {
    _lineWidth = value;

    if (_me.context && value)
      _me.context.lineWidth = getAbsoluteLineWidth();
  };

  _me.setLineCap = function (value) {
    _me.context.lineCap = value;
  };

  function bindEvent (element, name, handler) {
    element.on(name, handler);
    element.on('destroy', function () {
      element.off(name, handler);
    });
  }

  // TODO: only autosave between transactions

  _me.endDrawing = function () {
    if (!_group)
      return;

    var bounds = Whiteboard.getEventBounds(_group, _me.getWidth(), _me.getHeight());

    _group.coordinates = [bounds.left, bounds.top, bounds.right, bounds.bottom];

    _selectIndex.insert(_currentPage, _group);

    _group = null;
  };

  function getEventsSinceLastClear (events, copy) {
    var result = [];

    if (typeof copy === 'undefined')
      copy = true;

    for (var i = events.length - 1; i >= 0; i--) {
      var event;

      if (copy)
        event = cloneEvents(events[i]);
      else
        event = events[i];

      if (event.type === 'clear')
        break;

      if (event.type === 'group')
        event.events = getEventsSinceLastClear(event.events, copy);

      result.unshift(event);
    }

    return result;
  }

  _me.getEventsSinceLastClear = getEventsSinceLastClear;

  function mapEventAndSetParent (event, events, indexPath, group) {
    _eventMap[event.id] = event;

    if (group)
      event.parentEvent = group;

    updateRelatedEvents(event);
  }

  function setParentEvent (event, events, indexPath, group) {
    if (group)
      event.parentEvent = group;
  }

  // TODO: find last clear index path when clearing
  // TODO: lookup table to avoid transferring copies of same event
  // TODO: minify JSON

  function indexEvents (page, events) {
    var notDeletedEvents = Whiteboard.getNotDeletedEvents(events);

    notDeletedEvents.forEach(function (event) {
      _selectIndex.insert(page, event);
    });

    return notDeletedEvents;
  }

  function processEvents (events) {
    var page;
    var eventsMissingIds = [];
    var highestId = -1;
    var notDeletedEvents = [];

    _selectIndex.clear();

    var callback = function (event, events, indexPath, group) {
      if (event.id === undefined) {
        eventsMissingIds[page].push(event);
      } else {
        _eventMap[event.id] = event;

        if (event.id > highestId)
          highestId = event.id;
      }

      setParentEvent(event, events, indexPath, group);

      notDeletedEvents[page] = Whiteboard.updateNotDeletedEvents(event, notDeletedEvents[page]);

      updateRelatedEvents(event);
    };

    for (page = 0; page < events.length; page++) {
      eventsMissingIds.push([]);
      notDeletedEvents.push({});

      Whiteboard.crawlEvents(events[page], callback, callback);
    }

    eventsMissingIds.forEach(function (items, i) {
      items.forEach(function (event) {
        event.id = ++highestId;

        if (!notDeletedEvents[i])
          notDeletedEvents[i] = {};

        notDeletedEvents[i][event.id] = event;
      });
    });

    _nextID = highestId + 1;

    notDeletedEvents.forEach(function (items, i) {
      _.each(items, function (event) {
        _selectIndex.insert(i, event);
      });
    });
  }

  _me.getNextID = function () {
    return _nextID;
  };

  _me.editText = function (event, text, width, height) {
    event.text = text;

    event.coordinates[2] = width;
    event.coordinates[3] = height;

    clear();
    redraw();
  };

  var _hiddenEvents = [];
  _me.hideEvent = function (event) {
    _hiddenEvents.push(event);

    clear();
    redraw();
  };

  _me.showEvent = function (event) {
    var i = _hiddenEvents.indexOf(event);

    if (i >= 0)
      _hiddenEvents.splice(i, 1);

    clear();
    redraw();
  };

  var _overrideDuration;
  _me.setDuration = function (value) {
    _overrideDuration = value;
  };

  _me.getNumberLineCell = function (event, x, y) {
    var numberArray = event.numbers;

    if (!numberArray)
      return false;

    var sx = event.coordinates[0];
    var sy = event.coordinates[1];
    var ex = event.coordinates[2];
    var ey = event.coordinates[3];
    var w = ex - sx;
    var h = ey - sy;
    var result;

    if (!Array.isArray(numberArray[0]))
      numberArray = [numberArray];

    var isHorizontal = NumberLineTool.isNumberLineHorizontal(_me, event);

    for (var i = 0; i < numberArray.length; i++) {
      var numbers = numberArray[i];

      NumberLineTool.walkNumberLine(sx, sy, w, h, numbers.length, function (i) {
        var bounds = NumberLineTool.getBoundsForNumberLineCell(_me, sx, sy, ex, ey, numbers[i], numbers.length, i, isHorizontal);

        if (Whiteboard.isPointInRect({ x: x, y: y }, bounds)) {
          result = bounds;
          result.i = i;

          var callback = function (e) {
            if (_me.isDeleted(e))
              return;

            if (e.type === 'numberlinetext' && e.numberLineId === event.id && e.index === i) {
              result.text = e.text;

              return false;
            } else if (e.type === 'clear') {
              return false;
            }
          };

          Whiteboard.crawlEvents(_me.getEventsForCurrentPage(), callback, callback, true);

          return false;
        }
      }, isHorizontal);
    }

    return result;
  };

  _me.getTableCell = function (event, x, y) {
    var lineWidth = getAbsoluteLineWidth() / _me.getWidth();
    var halfLineWidth = lineWidth / 2;
    var bounds = Whiteboard.getEventBounds(event, _me.getWidth(), _me.getHeight());
    var w = bounds.right - bounds.left;
    var h = bounds.bottom - bounds.top;
    var cell = {};

    cell.width = w / event.cols;
    cell.height = h / event.rows;

    cell.col = Math.trunc((x - bounds.left) / cell.width);
    cell.row = Math.trunc((y - bounds.top) / cell.height);

    cell.x = bounds.left + (cell.col * cell.width) + halfLineWidth;
    cell.y = bounds.top + (cell.row * cell.height) + halfLineWidth;

    var callback = function (e) {
      if (_me.isDeleted(e))
        return;

      if (e.type === 'tabletext' && e.tableId === event.id && e.row === cell.row && e.col === cell.col) {
        cell.text = e.text;

        return false;
      } else if (e.type === 'clear') {
        return false;
      }
    };

    Whiteboard.crawlEvents(_me.getEventsForCurrentPage(), callback, callback, true);

    cell.width -= lineWidth;
    cell.height -= lineWidth;

    return cell;
  };

  _me.editSpreadsheet = function (spreadsheet, data, rowHeights, colWidths, shouldRedraw) {
    spreadsheet.data = data;
    spreadsheet.rowHeights = rowHeights;
    spreadsheet.colWidths = colWidths;

    var w = 0;
    var h = 0;

    rowHeights.forEach(function (value) {
      h += value;
    });

    colWidths.forEach(function (value) {
      w += value;
    });

    spreadsheet.coordinates[2] = spreadsheet.coordinates[0] + w;
    spreadsheet.coordinates[3] = spreadsheet.coordinates[1] + h;

    _selectIndex.update(_currentPage, spreadsheet);

    // update recorded copy
    Whiteboard.crawlEvents(_recordedEvents, function (obj) {
      if (obj.id === spreadsheet.id) {
        Object.assign(obj, spreadsheet);

        return false;
      }
    }, null, true);

    _editingObject = null;

    if (shouldRedraw !== false) {
      clear();
      redraw();
    }
  };

  var _tableText = {};
  _me.editTableText = function (table, row, col, text) {
    var event, obj;

    if (text !== false) {
      event = _tableText;

      obj = _tableText[row];

      if (obj) {
        event = obj[col];

        if (event) {
          delete obj[col];

          event.text = text;

          recordEvent(event);

          _me.execute(event);
        }
      }

      if (!event) {
        event = new TableText(_me, table.id, text, row, col, _color);
        addEvent(event);
      }
    } else {
      event = new TableText(_me, table.id, text, row, col, _color);
      addEvent(event, text === false);

      if (text === false) {
        obj = _tableText[row];

        if (!obj)
          obj = _tableText[row] = {};

        obj[col] = event;
      }
    }

    return event;
  };

  var _numberLineText = {};
  _me.editNumberLineText = function (numberline, index, text) {
    var event;

    if (text !== false && _numberLineText[index]) {
      event = _numberLineText[index];
      delete _numberLineText[index];

      event.text = text;

      recordEvent(event);

      _me.execute(event);
    } else {
      event = new NumberLineText(numberline.id, text, index, _color);
      addEvent(event, text === false, false);

      clear();
      redraw();

      if (text === false)
        _numberLineText[index] = event;
    }

    return event;
  };

  _me.removeEvent = function (event) {
    var events = _me.getEventsForCurrentPage();

    var i = events.indexOf(event);

    if (i === -1)
      return;

    events.splice(i, 1);
  };

  var _editingObject;

  _me.beginEditing = function (event) {
    _editingObject = event;

    clear();
    redraw();
  };

  _me.redraw = function () {
    clear();
    redraw();
  };

  _me.finishMoving = function (obj) {
    var tool = getToolForObjectType(obj.type);

    if (tool)
      return tool.finishMoving(obj);
    else
      return false;
  };
}

Whiteboard.getGroupRegion = function (group, width, height) {
  if (group.coordinates)
    return { left: group.coordinates[0], top: group.coordinates[1], right: group.coordinates[2], bottom: group.coordinates[3] };

  var region = { left: 1, right: 0, top: 1, bottom: 0 };
  var found = false;

  for (var i = 0; i < group.events.length; i++) {
    var event = group.events[i];

    if (event.type === 'group') {
      var r = Whiteboard.getGroupRegion(event, width, height);

      if (r) {
        if (r.left < region.left)
          region.left = r.left;

        if (r.right > region.right)
          region.right = r.right;

        if (r.top < region.top)
          region.top = r.top;

        if (r.bottom > region.bottom)
          region.bottom = r.bottom;

        found = true;
      }
    } else {
      var bounds = Whiteboard.getEventBounds(event, width, height);

      if (!bounds)
        continue;

      if (bounds.left < region.left)
        region.left = bounds.left;

      if (bounds.right > region.right)
        region.right = bounds.right;

      if (bounds.top < region.top)
        region.top = bounds.top;

      if (bounds.bottom > region.bottom)
        region.bottom = bounds.bottom;

      found = true;
    }
  }

  if (!found)
    return found;

  return region;
};

Whiteboard.getEventBounds = function (event, width, height) {
  var sx, sy, ex, ey;

  if (event.type === 'group') {
    var region;

    region = Whiteboard.getGroupRegion(event, width, height);

    if (!region)
      return false;

    sx = region.left;
    sy = region.top;
    ex = region.right;
    ey = region.bottom;
  } else {
    if (!event.coordinates)
      return false;

    var coordinates = event.coordinates;

    if (coordinates.length < 4) {
      sx = coordinates[0];
      sy = coordinates[1];

      if (event.type === 'bond') {
        ex = sx + 0.1;

        var w = width * (ex - sx);
        ey = sy + (w / height);
      } else {
        ex = sx;
        ey = sy;
      }
    } else {
      var tmp;

      sx = coordinates[0];
      sy = coordinates[1];

      if (event.type === EllipseTool.type || event.type === 'image' || event.type === 'equation' || event.type === TextTool.type) {
        ex = sx + coordinates[2];
        ey = sy + coordinates[3];
      } else {
        ex = coordinates[2];
        ey = coordinates[3];
      }

      if (ex < sx) {
        tmp = sx;
        sx = ex;
        ex = tmp;
      }

      if (ey < sy) {
        tmp = sy;
        sy = ey;
        ey = tmp;
      }
    }
  }

  return {
    bottom: ey,
    left: sx,
    right: ex,
    top: sy
  };
};

Whiteboard.crawlEvents = function (events, callback, groupCallback, reverse, indexPath, indexLevel, group, crawlDeletes) {
  if (indexPath === undefined)
    indexPath = [0];

  if (indexLevel === undefined)
    indexLevel = 0;

  var delta, start, end;

  if (reverse) {
    delta = -1;
    start = events.length - 1;
    end = -1;
  } else {
    delta = 1;
    start = 0;
    end = events.length;
  }

  for (var i = start; i !== end; i += delta) {
    var result;

    indexPath[indexLevel] = i;

    var event = events[i];

    if (event.type === 'group') {
      var leftGroupCallback;

      if (groupCallback) {
        result = groupCallback(event, events, indexPath, group);

        if (typeof result === 'function')
          leftGroupCallback = result;
        else if (result !== undefined)
          return result;
      }

      if (indexPath.length <= indexLevel + 1)
        indexPath.push(0);

      result = Whiteboard.crawlEvents(event.events, callback, groupCallback, reverse, indexPath, indexLevel + 1, event, crawlDeletes);

      if (leftGroupCallback) {
        leftGroupCallback();
        leftGroupCallback = null;
      }

      if (result !== undefined)
        return result;

      indexPath.pop();
    } else {
      result = callback(event, events, indexPath, group);

      if (typeof result === 'function')
        result();
      else if (result !== undefined)
        return result;

      // events that reference other events need to be crawled, too. pointer references will now be separate copies after deep cloning
      if (event.event && (crawlDeletes || event.type !== 'delete')) {
        result = Whiteboard.crawlEvents([event.event], callback, groupCallback, reverse, indexPath, indexLevel + 1, event, crawlDeletes);

        if (typeof result === 'function')
          result();
        else if (result !== undefined)
          return result;
      }
    }
  }
};

Whiteboard.getFirstEventInGroup = function (group) {
  if (!group.events || group.events.length === 0)
    return null;

  var event = group.events[0];

  if (event.type === 'group')
    return Whiteboard.getFirstEventInGroup(event);
  else
    return event;
};

Whiteboard.getTimeForEvent = function (lastEvent) {
  if (lastEvent.type === 'group') {
    lastEvent = Whiteboard.getFirstEventInGroup(lastEvent);

    if (!lastEvent)
      return false;
  }

  if (lastEvent.time === undefined)
    return false;

  return lastEvent.time / 1000;
};

Whiteboard.getDuration = function (events) {
  var event = Whiteboard.getLastEventWithTime(events);

  return Whiteboard.getTimeForEvent(event);
};

Whiteboard.getLastEventWithTime = function (events) {
  var result;

  function findEvent (event) {
    if ((typeof event.time) === 'number')
      result = event;
  }

  Whiteboard.crawlEvents(events, findEvent, findEvent);

  return result;
};

Whiteboard.isPointInRect = function (point, rect) {
  var bounds = {
    x: rect.x,
    y: rect.y,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    bottom: rect.bottom,
    width: rect.width,
    height: rect.height
  };

  if (bounds.left === undefined)
    bounds.left = bounds.x;

  if (bounds.top === undefined)
    bounds.top = bounds.y;

  if (bounds.width === undefined)
    bounds.width = bounds.right - bounds.left;

  if (bounds.width === undefined)
    bounds.height = bounds.bottom - bounds.top;

  if (bounds.right === undefined)
    bounds.right = bounds.left + bounds.width;

  if (bounds.bottom === undefined)
    bounds.bottom = bounds.top + bounds.height;

  return point.x >= bounds.left && point.y >= bounds.top && point.x < bounds.right && point.y < bounds.bottom;
};

Whiteboard.convertCoordinates = function (points, w, h, callback) {
  for (var i = 0; i < points.length; i++)
    points[i].y = h - points[i].y;

  var result = callback(points);

  if (Array.isArray(result)) {
    for (i = 0; i < result.length; i++)
      result[i].y = h - result[i].y;
  } else if (result.x && result.y) {
    result.y = h - result.y;
  }

  return result;
};

Whiteboard.updateNotDeletedEvents = function (event, notDeletedEvents) {
  if (event.type === 'delete')
    delete notDeletedEvents[event.event.id];
  else if (event.type === 'clear')
    notDeletedEvents = {};
  else if (typeof event.id !== 'undefined')
    notDeletedEvents[event.id] = event;

  return notDeletedEvents;
};

Whiteboard.getNotDeletedEvents = function (events) {
  var notDeletedEvents = {};

  events.forEach(function (event) {
    notDeletedEvents = Whiteboard.updateNotDeletedEvents(event, notDeletedEvents);
  });

  return _.values(notDeletedEvents);
};

Whiteboard.getSelectableTypes = function () {
  var tools = [RectangleTool, TriangleTool, LineTool, CurvedArrowTool, VerticalCurvedArrowTool, EllipseTool, RightTriangleTool, ParallelogramTool, TrapezoidTool, HexagonTool, OctagonTool, PentagonTool, GridTool, NumberLineTool, UnifixCubeTool, UnifixCubeStackTool, ProtractorTool, AlgebraTileTool, FractionBarTool];
  var result = ['group', TableTool.type, 'numberline', 'array', 'bond', DiagramTool.type, TextTool.type, 'image', 'equation', SpreadsheetTool.type];

  tools.forEach(function (tool) {
    if (new tool().isSelectable())
      result.push(tool.type);
  });

  return result;
};

function WhiteboardSelectIndex () {
  var _rBushes = [];
  var me = this;
  var _itemMap = {};

  this.clear = function (page) {
    if (page) {
      if (_rBushes[page])
        _rBushes[page].clear();
    } else {
      _.invoke(_rBushes, 'clear');
    }
  };

  this.getPageCount = function () {
    return _rBushes.length;
  };

  this.addPage = function () {
    _rBushes.push(new rbush(1));
  };

  this.addPage();

  var updateTree = function (page, event) {
    var bounds = Whiteboard.getEventBounds(event, 1, 1);

    var item = {
      minX: bounds.left,
      minY: bounds.top,
      maxX: bounds.right,
      maxY: bounds.bottom,
      event: event
    };

    _rBushes[page].insert(item);
    _itemMap[event.id] = item;
  };

  this.insert = function (page, event) {
    if (!Whiteboard.getSelectableTypes().includes(event.type))
      return;

    while (me.getPageCount() <= page)
      me.addPage();

    updateTree(page, event);
  };

  this.update = function (page, event) {
    me.remove(page, event);
    me.insert(page, event);
  };

  var retrieveFromTree = function (page, x, y) {
    var item = {
      minX: x,
      minY: y,
      maxX: x,
      maxY: y
    };

    if (_rBushes[page]) {
      var result = _rBushes[page].search(item);

      return _.map(result, function (obj) {
        return obj.event;
      });
    } else {
      return null;
    }
  };

  this.retrieve = function (page, x, y, type, filter) {
    if (me.getPageCount() <= page)
      me.addPage();

    var result = retrieveFromTree(page, x, y);

    if (!result)
      return null;

    if (type || filter) {
      result = _.filter(result, function (event) {
        if (filter && filter(event) === false)
          return false;

        if (type) {
          if (Array.isArray(type))
            return type.includes(event.type);
          else
            return event.type === type;
        } else {
          return true;
        }
      });
    }

    if (result.length > 0)
      return _.max(result, 'id');
    else
      return null;
  };

  this.remove = function (page, event) {
    var item = _itemMap[event.id];

    if (!item)
      return false;

    _rBushes[page].remove(item);
  };
}

(function () {
  var shared;

  Whiteboard.getSharedAudioPlayer = function () {
    if (!shared)
      shared = $('<audio>');

    return shared;
  };
})();

export default Whiteboard;
