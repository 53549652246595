export default function IdleTimer(timeout) {
  var _callback;
  var _timer;
  var _fired;

  this.hasFired = function () {
    return _fired;
  };

  this.reset = function () {
    _fired = false;

    this.stop();

    _timer = setTimeout(function () {
      _fired = true;

      if (_callback)
        _callback();
    }, timeout);
  };

  this.setCallback = function (callback) {
    _callback = callback;
  };

  this.stop = () => {
    if (_timer)
      clearTimeout(_timer);
  };

  this.reset();
}

IdleTimer.getSharedInstance = function () {
  if (!IdleTimer.sharedInstance)
    IdleTimer.sharedInstance = new IdleTimer(120  * 1000);

  return IdleTimer.sharedInstance;
};
