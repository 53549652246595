function GoogleManager () {
  var _clientId;
  var _user;

  this.checkAuth = function (scopes, forceSelectAccount) {
    return this.signIn(scopes, forceSelectAccount);
  };

  this.getClientId = function () {
    return _clientId;
  };

  this.getUser = function () {
    if (_user)
      return _user;
    else if (gapi.auth2.getAuthInstance() && gapi.auth2.getAuthInstance().currentUser)
      return gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    else
      return null;
  };

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  function initAuth () {
    return gapi.auth2.init({
      clientId: this.getClientId()
    }).then(function (result) {
      return result;
    }, function(error) {
      console.log(JSON.stringify(error, null, 2));

      return error;
    });
  }

  this.isAvailable = function () {
    return new Promise(function (resolve) {
      if (typeof gapi !== 'undefined' && gapi.auth2) {
        resolve();
      } else {
        var timer;

        timer = setInterval(function () {
          if (typeof gapi !== 'undefined' && gapi.auth2) {
            resolve();

            clearInterval(timer);
            timer = null;
          }
        }, 100);
      }
    });
  };

  this.setUp = function (clientId) {
    var me = this;

    _clientId = clientId;

    window.loadGAPI = function () {
      gapi.load('client:auth2', initAuth.bind(me));
    };

    if (typeof gapi === 'undefined') {
      var script = $('<script>');
      script.attr('src', 'https://apis.google.com/js/platform.js?onload=loadGAPI');
      script.attr('defer', '');
      script.attr('async', '');
      $('body:first').append(script);
    }
  };

  /**
   *  Sign in the user upon button click.
   */
  this.signIn = function (scopes, forceSelectAccount) {
    return this.isAvailable().then(function () {
      if (!forceSelectAccount && gapi.auth2.getAuthInstance().isSignedIn.get() && _user) {
        return _user;
      } else {
        var options = {};
    
        if (forceSelectAccount)
          options.prompt = 'select_account';
    
        if (scopes)
          options.scope = scopes.join(' ');
        
        return gapi.auth2.getAuthInstance().signIn(options).then(function (user) {
          if (user) {
            _user = user.getBasicProfile();

            return user;
          } else {
            return null;
          }
        });
      }
    });
  };

  /**
   *  Sign out the user upon button click.
   */
  this.signOut = function () {
    _user = null;
    
    return gapi.auth2.getAuthInstance().signOut();
  };
}

export default new GoogleManager();
