import 'src/components/shared/base';
import 'core/assignments/assignmentsDirective';
import 'src/components/shared/thinklet_data';

 function AssignmentsController ($scope, $controller, ThinkletManager, $state) {
    "ngInject";

	$controller('BaseController', { '$scope': $scope, '$state': $state });

	$scope.loadAssignments = ThinkletManager.fetchAssignments;
	$scope.loadProblem = ThinkletManager.loadProblem;

	$scope.createThinklet = function (problem, assignment) {
		ThinkletManager.createThinklet(problem, assignment, function (thinklet) {
		  $scope.navigateToWorkOnThinklet(thinklet);
		});
	};
}

export default AssignmentsController;
