angular.module('cuethinkApp.ContentAssignmentServices', [])
    .service('ContentAssignmentServices', function () {
        var assignProblems = [];
        var assignProblemIds = [];
        var assignmentData = {};
        var _classes, _students, _toggledStudents;

        var setIdsFromProblems = function(){
          assignProblemIds = [];

          for (var i = 0; i < assignProblems.length; i++) {
            assignProblemIds.push(assignProblems[i].id);
          }
        };

        return {
            getAssignProblems: function () {
                return assignProblems;
            },
            setAssignProblems: function(value) {
                assignProblems = value;
                setIdsFromProblems();
            },
            pushAssignProblems: function(problem){
              if (assignProblemIds.indexOf(problem.id) === -1) {
                assignProblems.push(problem);
                assignProblemIds.push(problem.id);
              }
            },
            addAllAssignProblems: function(problems){
              for (var i=0; i<problems.length; i++){
                if (assignProblemIds.indexOf(problems[i].id) === -1) {
                  assignProblems.push(problems[i]);
                  assignProblemIds.push(problems[i].id);
                }
              }
            },
            deleteAssignProblems: function(problem){
              assignProblems.splice(assignProblems.indexOf(problem), 1);
              assignProblemIds.splice(assignProblemIds.indexOf(problem.id), 1);
            },
            clearAssignProblems: function(){
              assignProblems = [];
              assignProblemIds = [];
            },
            getAssignmentData: function (setClasses, setStudents) {
                if (setClasses)
                    setClasses(_classes);

                if (setStudents)
                    setStudents(_students, _toggledStudents);

              return assignmentData;
            },
            setAssignmentData: function (value, classes, students, toggledStudents) {
              assignmentData = value;
              _classes = classes;
              _students = students;
              _toggledStudents = toggledStudents;
            }
        };
    });
