import 'src/components/shared/teacher_data';
import 'src/components/shared/media';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import '../../contentassignment/contentAssignmentServices';
import 'src/app/navigation/teachernavbarController';
import './manageContent.css';
import ContentHeaderController from './header/contentHeaderController';
require('./header/contentHeader.partial.html');
require('./manageProblemStepOne.partial.html');
require('./manageProblemStepTwo.partial.html');
require('./manageProblemStepThree.partial.html');
import _ from 'underscore';
import '../shareProblem/shareProblemDirective';
import 'angular-ui-bootstrap';
import './createProblemPopUpDirective';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import 'core/common/commonModule';
var cloneDeep = require('lodash.clonedeep');
import 'src/images/Close x.svg';
import 'src/images/DownArrow.svg';
import 'src/images/Calculator_Icon_Black.svg';
import 'src/images/PlusSign.svg';
import 'src/images/PictureIcon.svg';
import 'core/common/cuethinkDropdownDirective';
import 'src/images/Delete_Tag_X.png';
import { setUserModesScopeMembers } from 'core/common/user-modes';
import 'src/images/explore_preview1.png';
import 'src/images/explore_preview2.png';
import 'src/images/explore_preview3.png';
import 'core/common/directives/cuethinkCheckboxDirective';
import 'src/images/ClosexBlue_2px.svg';
import permissionsManager, { Permissions } from 'core/permissions/permissions';

function ManageContentController ($scope, $state, $stateParams, TeacherManager, MediaManager, UserManager, $controller, ThinkletManager, ContentAssignmentServices, $rootScope, $timeout, $transitions, $translate, taTools, CueThinkConfig) {
  "ngInject";

  var _restoreSelection;

  $controller('TeacherNavBarController', { '$scope': $scope });

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  var gradeList = [
    { id: 'K', label: 'K', selected: false },
    { id: 1, label: '1', selected: false },
    { id: 2, label: '2', selected: false },
    { id: 3, label: '3', selected: false },
    { id: 4, label: '4', selected: false },
    { id: 5, label: '5', selected: false },
    { id: 6, label: '6', selected: false },
    { id: 7, label: '7', selected: false },
    { id: 8, label: '8', selected: false },
    { id: 9, label: 'HS', selected: false }
  ];

  var isEditMode = !!$stateParams.id;

  $scope.isEditMode = isEditMode;
  $scope.userCheckList = [];
  $scope.grades = [];
  $scope.categories = [];
  $scope.contentData = {
    addToBank: false,
    answer: {},
    grade: null,
    question: {},
    sourceDetail: null,
    title: {}
  };
  $scope.user = UserManager.getUser();

  $scope.formData = {
    domainList: null,
    questionSourceList: [],
    selectedQuestionSource: null
  };

  $scope.viewModel = {
    dropdownSelections: {},
    getSharedUsers: null,
    gradeList: null,
    hasChangedSourceType: false,
    imgURL: {},
    isDomainsDropdownOpen: false,
    isProblemPopUpVisible: false,
    isShared: false,
    isStandardFilterDropdownOpen: false,
    languages: [{ active: true, locale: 'en', name: 'ENGLISH' }, { active: false, locale: 'es', name: 'SPANISH' }],
    newChecklistItem: {
      checkListItem: null,
      selected: false,
      translationId: null
    },
    newStrategy: {
      name: null,
      selected: false,
      translationId: null
    },
    problem: null,
    selectedGrades: [],
    selectedGradesAsString: null,
    selectedStandards: [],
    selectedStandardType: null,
    sharedUsers: null,
    showSharePrompt: null,
    standardTypes: null
  };

  function selectProblemStandardType() {
    var problem = $scope.viewModel.problem;

    if ($stateParams.standardType) {
      $scope.viewModel.selectedStandardType = $scope.viewModel.standardTypes.find(function (standardType) {
        return standardType.id === $stateParams.standardType;
      });
    }

    if (!$scope.viewModel.selectedStandardType && problem && problem.state && $scope.viewModel.standardTypes) {
      $scope.viewModel.selectedStandardType = $scope.viewModel.standardTypes.find(function (standardType) {
        return standardType.id === problem.state;
      });
    }
  }

  TeacherManager.loadStandardTypes().then(function (standardTypes) {
    standardTypes = standardTypes.map(function (standardType) {
      return { id: standardType.state, name: standardType.name };
    });

    standardTypes = standardTypes.filter(function (standardType) {
      return standardType.id === 'CCK12' || standardType.id === 'VA' || standardType.id === 'TX';
    });

    standardTypes = _.sortBy(standardTypes, 'name');

    $scope.viewModel.standardTypes = standardTypes;

    $scope.viewModel.selectedStandardType = standardTypes.find(function (standardType) {
      return standardType.id === 'CCK12';
    });

    selectProblemStandardType();
  });

  $scope.onClickStandardType = function (standardType) {
    if (standardType === $scope.viewModel.selectedStandardType)
      return;

    $scope.clearSelectedStandards();

    $scope.viewModel.selectedStandardType = standardType;

    $scope.viewModel.dropdownSelections[standardType.id] = {
      grades: getSelectedGradeIds(),
      domains: getSelectedDomainIds()
    };

    $scope.viewModel.isStandardTypeOpen = false;

    var selection = $scope.viewModel.dropdownSelections[standardType.id];

    if (selection) {
      $scope.viewModel.gradeList.forEach(function (grade) {
        var found = selection.grades.find(function (g) {
          return g === grade.label;
        });

        grade.selected = !!found;
      });

      updateSelectedGrades();
    } else {
      resetGradeSelections();
    }

    populateDomains();
  };

  $scope.onSourceTypeChange = function () {
    $scope.viewModel.hasChangedSourceType = true;

    if ($scope.formData.selectedQuestionSource.name === 'Original Work')
      $scope.contentData.sourceDetail = $scope.user.username;
    else
      $scope.contentData.sourceDetail = null;
  };

  $scope.isClone = function () {
    return !!$stateParams.clone || ($scope.formData.selectedQuestionSource && $scope.formData.selectedQuestionSource.name === 'Cloned Work');
  };

  function resetGradeSelections() {
    $scope.viewModel.gradeList = cloneDeep(gradeList);
    $scope.viewModel.selectedGrades = [];
  }

  $scope.addFile = function ($file, $event, $flow, locale) {
    MediaManager.uploadProblemImage($file.file).then(function (result) {
      if (!$scope.viewModel.imgURL)
        $scope.viewModel.imgURL = {};

      $scope.viewModel.imgURL[locale] = result.id;
    });
  };

  $scope.clearImg = function ($flow, locale) {
    if ($stateParams.id && !$stateParams.clone)
      MediaManager.deleteProblemImage($stateParams.id);

    $flow.files = [];

    if (!$scope.viewModel.imgURL)
      $scope.viewModel.imgURL = {};

    $scope.viewModel.imgURL[locale] = null;
  };

  $scope.getImageURL = function (locale) {
    var path;

    if ($scope.viewModel.imgURL && $scope.viewModel.imgURL[locale])
      path = $scope.viewModel.imgURL[locale];
    else
      return null;

    return ThinkletManager.getMediaURLFromPath(path);
  };

  function getSelectedDomains() {
    if (!$scope.formData.domainList)
      return [];

    return $scope.formData.domainList.filter(function (domain) {
      return domain.selected;
    });
  }

  function getSelectedDomainIds() {
    var domains = getSelectedDomainNames();
    var result = [];

    domains.forEach(function (name) {
      fullDomainList.forEach(function (domain) {
        if (domain.name === name)
          result.push(domain.id);
      });
    });

    return result;
  }

  function getSelectedDomainIdsAndStates() {
    var domains = getSelectedDomains();
    var result = [];

    domains.forEach(function (domain) {
      var value = domain.id + '_' + domain.state;

      if (result.indexOf(value) === -1)
        result.push(value);
    });

    return result;
  }

  function getSelectedStandards() {
    return $scope.viewModel.selectedStandards;
  }

  function reloadStandards() {
    var domainIds = getSelectedDomainIds();

    if (domainIds.length === 0)
      return;

    var states = [$scope.viewModel.selectedStandardType.id];

    return TeacherManager.loadStandardTypeStandards(getSelectedGradeIds(), states, domainIds).then(function (results) {
      var standards = results.map(function (standard) {
        return {
          id: standard.standard,
          stateStandard: standard.description,
          state: standard.state,
          rootStandard: standard.rootStandard
        };
      });

      var getDomain = function (standard) {
        var id = standard.id;

        var i = id.indexOf('.');
        var result = id.substring(i + 1);

        return result;
      };

      var getGrade = function (standard) {
        var id = standard.id;

        var i = id.indexOf('.');
        var result = id.substring(0, i);

        if (result === 'K')
          result = -1;
        else
          result = parseInt(result);

        return result;
      };

      var result;

      $scope.standards = result = _(standards).chain().sortBy(getDomain).sortBy(getGrade).value();

      if (!hasSetInitialStandards) {
        hasSetInitialStandards = true;

        if (isEditMode && $scope.viewModel.problem.standard) {
          var existingStandards = $scope.viewModel.problem.standard.split(',');

          $scope.standards.forEach(function (standard) {
            var found = existingStandards.find(function (std) {
              return standard.id === std;
            });

            if (found)
              addStandard(standard);
          });
        }
      }

      return result;
    });
  }

  function selectDomain(domain) {
    domain.selected = true;

    return $scope.onDomainChange(domain);
  }

  $scope.onDomainChange = function (domain) {
    $scope.standards = [];

    return reloadStandards();
  };

  function getStandardIndex(standard) {
    var standards = $scope.viewModel.selectedStandards;

    for (var i = 0; i < standards.length; i++) {
      if (standard.id === standards[i].id)
        return i;
    }

    return -1;
  }

  $scope.isStandardSelected = function (standard) {
    return getStandardIndex(standard) !== -1;
  };

  $scope.addStrategy = function () {
    $scope.viewModel.newStrategy.selected = true;
    $scope.userStrategies.splice(0, 0, $scope.viewModel.newStrategy);

    $scope.viewModel.newStrategy = {
      name: null,
      selected: false,
      translationId: null
    };
  };

  $scope.deleteStrategy = function (index) {
    $scope.userStrategies.splice(index, 1);
  };

  $scope.addCheckListItem = function () {
    $scope.viewModel.newChecklistItem.selected = true;
    $scope.userCheckList.push($scope.viewModel.newChecklistItem);

    $scope.viewModel.newChecklistItem = {
      checkListItem: null,
      selected: false,
      translationId: null
    };
  };

  $scope.deleteCheckListItem = function (item) {
    $scope.userCheckList.splice($scope.userCheckList.indexOf(item), 1);
  };

  function getGradeBand(grade) {
    var grades = grade.split(/, ?/);
    var highestGradeBand = 0;

    for (var i = 0; i < grades.length; i++) {
      grade = grades[i];

      if (grade === 'HS')
        grade = 9;
      else if (grade === 'K')
        grade = 0;
      else
        grade = parseInt(grade);

      if (grade > highestGradeBand)
        highestGradeBand = grade;
    }

    if (highestGradeBand <= 5)
      return 'Elementary School';
    else if (highestGradeBand <= 8)
      return 'Middle School';
    else
      return 'High School';
  }

  $scope.submitProblem = function (assign) {
    _isSubmitting = true;

    var checklist = [];

    for (var i = 0; i < $scope.userCheckList.length; i++) {
      var item = $scope.userCheckList[i];

      if (item.selected)
        checklist.push(item.translationId ? item.translationId : item.checkListItem);
    }

    var callback = function (problem) {
      problem.topicName = getSelectedDomainNames().join(',');
      problem.creatorName = $scope.user.username;
      problem.gradeBandName = getGradeBand($scope.contentData.grade);

      // share

      if ($scope.cueteachEnabled) {
        var shareUsers = $scope.viewModel.getSharedUsers();

        if (shareUsers.length > 0)
          TeacherManager.shareProblem(problem, shareUsers);
      }

      var stateName = $stateParams.referer;
      var params;

      if (assign) {
        if ($stateParams.returnToAssign) {
          ContentAssignmentServices.addAllAssignProblems([problem]);

          params = { isAddingProblems: true };
        } else {
          ContentAssignmentServices.setAssignProblems([problem]);
        }

        stateName = 'assign-problems';
      } else if (!stateName || stateName === 'view-all-problems') {
        stateName = 'view-all-problems';
        params = { problemId: problem.id, state: $scope.viewModel.selectedStandardType.id };
      }

      $state.go(stateName, params);
    };

    var error = function () {
      _isSubmitting = false;

      digest();

      $translate('SUBMISSION_FAILED').then(function (value) {
        alert(value);
      });
    };

    var selectedStandards = $scope.viewModel.selectedStandards.slice(0);

    if ($scope.viewModel.problem && $scope.viewModel.problem.ccssId) {
      var oldCcssIds = $scope.viewModel.problem.ccssId.split(/, ?/);

      var missingCcssIds = _.filter(oldCcssIds, function (ccssId) {
        return !_.find($scope.standards, { id: ccssId });
      });

      _.each(missingCcssIds, function (ccssId) {
        selectedStandards.push({ id: ccssId });
      });
    }

    if (selectedStandards.length === 0) {
      $translate('PLEASE_SELECT_AT_LEAST_1_STANDARD_TO_SAVE_THIS_PROBLEM').then(function (value) {
        alert(value);
      });

      return;
    }

    var state = $scope.viewModel.selectedStandardType.id;

    var strategies = $scope.userStrategies.filter(function (strategy) {
      return strategy.selected;
    }).map(function (strategy) {
      return strategy.translationId ? strategy.translationId : strategy.name;
    });

    var englishLocale = $scope.viewModel.languages[0].locale;
    var promise;
    var translations = [];

    $scope.viewModel.languages.forEach(function (language) {
      if (language.locale !== englishLocale && language.active) {
        translations.push({
          locale: language.locale,
          title: $scope.contentData.title[language.locale],
          question: $scope.contentData.question[language.locale],
          answer: $scope.contentData.answer[language.locale],
          images: ($scope.viewModel.imgURL && $scope.viewModel.imgURL[language.locale]) ? [$scope.viewModel.imgURL[language.locale]] : null
        });
      }
    });

    if ($stateParams.edit)
      promise = TeacherManager.submitEditedProblem($scope.viewModel.problem.id, $scope.contentData.addToBank, $scope.contentData.title[englishLocale], $scope.formData.selectedQuestionSource.id, $scope.contentData.sourceDetail, $scope.contentData.question[englishLocale], $scope.contentData.answer[englishLocale], state, $scope.contentData.grade, getSelectedDomainIdsAndStates(), selectedStandards, checklist, strategies, $scope.viewModel.imgURL[englishLocale] ? [$scope.viewModel.imgURL[englishLocale]] : null, translations);
    else
      promise = TeacherManager.submitProblem($scope.contentData.addToBank, $scope.contentData.title[englishLocale], $scope.formData.selectedQuestionSource.id, $scope.contentData.sourceDetail, $scope.contentData.question[englishLocale], $scope.contentData.answer[englishLocale], state, $scope.contentData.grade, getSelectedDomainIdsAndStates(), selectedStandards, checklist, strategies, $scope.viewModel.imgURL[englishLocale] ? [$scope.viewModel.imgURL[englishLocale]] : null, translations);

    promise.then(callback).catch(error);
  };

  function clearSelectedDomains() {
    if ($scope.formData.domainList) {
      $scope.formData.domainList.forEach(function (domain) {
        domain.selected = false;
      });
    }
  }

  function updateSelectedGrades() {
    $scope.viewModel.selectedGrades = _.filter($scope.viewModel.gradeList, function (grade) {
      return grade.selected === true;
    });

    var gradeArray = _.pluck($scope.viewModel.selectedGrades, "label");
    $scope.contentData.grade = $scope.viewModel.selectedGradesAsString = gradeArray.join(", ");
  }

  $scope.onSelectGrade = function () {
    updateSelectedGrades();

    if (getSelectedGradeIds().length > 0) {
      populateDomains();
    } else {
      clearSelectedDomains();

      $scope.formData.domainList = null;
      $scope.standards = null;
    }
  };

  function getSelectedGradeIds() {
    return _.pluck($scope.viewModel.selectedGrades, 'label');
  }

  $scope.getDomainsLabel = function () {
    var domains = getSelectedDomains();

    if (domains.length === 0)
      return '';

    return domains.length + ' domain' + ((domains.length === 1) ? '' : 's');
  };

  $scope.getStandardsLabel = function () {
    var result = 0;

    if ($scope.standards) {
      $scope.standards.forEach(function (standard) {
        if ($scope.isStandardSelected(standard))
          result++;
      });
    }

    if (result === 0)
      return '';

    return result + ' standard' + ((result === 1) ? '' : 's');
  };

  var hasSetInitialDomain;
  var hasSetInitialStandards;
  var fullDomainList;

  function populateDomains() {
    $scope.formData.domainList = null;
    $scope.standards = null;

    var selectedGrades = getSelectedGradeIds();

    if (selectedGrades.length === 0)
      return;

    var selectedDomains = getSelectedDomains();

    var standardType = $scope.viewModel.selectedStandardType;

    if (standardType)
      standardType = standardType.id;
    else
      standardType = 'CCK12';

    var standardTypes = [standardType];

    return TeacherManager.loadStandardTypeDomains(selectedGrades, standardTypes).then(function (domains) {
      fullDomainList = domains.map(function (domain) {
        return { id: domain.standard, name: domain.description, state: domain.state };
      });

      domains = _.uniq(fullDomainList, function (domain) {
        return domain.name;
      });

      $scope.formData.domainList = domains.map(function (domain) {
        var found = selectedDomains.find(function (d) {
          return d.id === domain.id || d.name == domain.name;
        });

        return { id: domain.id, name: domain.name, selected: found ? true : false, state: domain.state };
      });

      if (hasSetInitialDomain) {
        var selection = $scope.viewModel.dropdownSelections[$scope.viewModel.selectedStandardType.id];

        if (selection) {
          selection.domains.forEach(function (domain) {
            var found = $scope.formData.domainList.find(function (d) {
              return d.id === domain;
            });

            if (found)
              found.selected = true;
          });
        }
      } else {
        hasSetInitialDomain = true;

        if (isEditMode && $scope.viewModel.problem.root_standard) {
          var rootStandards = $scope.viewModel.problem.root_standard.split(',');

          $scope.formData.domainList.forEach(function (domain) {
            var found = rootStandards.find(function (d) {
              return d === domain.id;
            });

            if (found)
              domain.selected = true;
          });
        }
      }

      if (getSelectedDomains().length > 0)
        reloadStandards();
    });
  }

  function isLanguagesValid () {
    var languagesValid = true;
    var atLeastOneLanguageActive = false;

    $scope.viewModel.languages.forEach(function (language) {
      if (language.active) {
        atLeastOneLanguageActive = true;

        if (!$scope.contentData.question[language.locale])
          languagesValid = false;

        if (!$scope.contentData.title[language.locale])
          languagesValid = false;

        if (!$scope.contentData.answer[language.locale])
          languagesValid = false;
      }
    });

    return atLeastOneLanguageActive && languagesValid;
  }

  $scope.isStepOneFormValid = function () {
    return ContentHeaderController.isStepOneFormValid = $scope.formData.stepOneForm.$valid
      && $scope.formData.selectedQuestionSource
      && isLanguagesValid();
  };

  $scope.isStepTwoFormValid = function () {
    return ContentHeaderController.isStepTwoFormValid = $scope.formData.stepTwoForm.$valid
      && $scope.contentData
      && $scope.contentData.grade
      && getSelectedDomains().length > 0
      && getSelectedStandards().length > 0
      && isLanguagesValid()
      && $scope.formData.selectedQuestionSource
      && (!$scope.isSourceURLRequired() || $scope.contentData.sourceDetail);
  };

  var _isSubmitting;
  $scope.allowSubmit = function () {
    return $scope.contentData
      && getSelectedDomains().length > 0
      && $scope.viewModel.selectedGrades.length > 0
      && $scope.formData.selectedQuestionSource
      && ($scope.contentData.sourceDetail || !$scope.isSourceURLRequired())
      && isLanguagesValid()
      && !_isSubmitting;
  };

  $scope.pasteHandler = function (pastedContent) {
  };

  function populateSources() {
    TeacherManager.loadSources(function (sources) {
      $scope.formData.questionSourceList = sources;

      if ($scope.viewModel.problem)
        $scope.formData.selectedQuestionSource = _.find($scope.formData.questionSourceList, { id: $scope.viewModel.problem.questionSource });

      var i = _.findIndex(sources, { name: 'Cloned Work' });

      if (i !== -1) {
        sources[i].name = 'Cloned and Modified Work';

        if ($stateParams.clone)
          $scope.formData.selectedQuestionSource = sources[i];

        if (!$scope.formData.selectedQuestionSource || $scope.formData.selectedQuestionSource.name !== 'Cloned and Modified Work')
          sources.splice(i, 1);
      }

      digest();
    });
  }

  $scope.isSourceURLRequired = function () {
    if (!$scope.formData.selectedQuestionSource)
      return true;

    return $scope.formData.selectedQuestionSource.id == 101 || $scope.formData.selectedQuestionSource.id == 102;
  };

  var globalSettingsStrategies;

  if (isEditMode) {
    var problemPromise;

    if ($stateParams.standardType)
      problemPromise = ThinkletManager.loadStateProblem($stateParams.id, $stateParams.standardType);
    else
      problemPromise = ThinkletManager.loadProblem($stateParams.id);

    problemPromise.then(function (problem) {
      $scope.viewModel.problem = problem;

      $scope.problem = problem;

      var englishLocale = $scope.viewModel.languages[0].locale;

      $scope.viewModel.languages.forEach(function (language) {
        if (language.locale === englishLocale) {
          language.active = true;
        } else {
          var active = problem.translations.find(function (translation) {
            return translation.locale === language.locale;
          });

          language.active = !!active;
        }
      });

      populateSources();

      if ($scope.formData.questionSourceList && !$scope.formData.selectedQuestionSource)
        $scope.formData.selectedQuestionSource = _.find($scope.formData.questionSourceList, { id: $scope.viewModel.problem.questionSource });

      $scope.contentData.answer[englishLocale] = problem.answer;

      //Loading user custom checklist
      $scope.userStrategies = [];

      if (problem.strategyCheckList.length > 0) {
        $translate(problem.strategyCheckList).then(function (translations) {
          Object.keys(translations).forEach(function (key) {
            var item = {
              name: translations[key],
              selected: true,
              translationId: key
            };

            $scope.userStrategies.push(item);
          });
        });
      }

      $scope.userCheckList = [];

      if (problem.genericCheckList.length > 0) {
        $translate(problem.genericCheckList).then(function (translations) {
          Object.keys(translations).forEach(function (key) {
            var item = {
              checkListItem: translations[key],
              selected: true,
              translationId: key
            };

            $scope.userCheckList.push(item);
          });
        });
      }

      var unsubscribe = $rootScope.$on('$translateChangeSuccess', function () {
        if ($scope.userCheckList.length > 0) {
          var translationIds = $scope.userCheckList.map(function (item) {
            return item.translationId;
          });

          $translate(translationIds).then(function (translations) {
            Object.keys(translations).forEach(function (key) {
              var item = $scope.userCheckList.find(function (item) {
                return item.translationId === key;
              });

              item.checkListItem = translations[key];
              item.selected = true;
              item.translationId = key;
            });
          });
        }
      });

      $scope.$on("$destroy", function() {
        unsubscribe();
      });

      $scope.contentData.sourceDetail = problem.sourceDetail;

      if (problem.questionType === 'Math Forum')
        $scope.contentData.sourceDetail = 'https://www.nctm.org/mathforum';
      else if (problem.addToBank && problem.creatorName === 'cuethinkmgr')
        $scope.contentData.sourceDetail = 'CueThink';
      else if (problem.creatorName)
        $scope.contentData.sourceDetail = problem.creatorName;
      else
        $scope.contentData.sourceDetail = $scope.user.username;

      $scope.contentData.learningObjective = problem.learningObjective;
      $scope.contentData.question[englishLocale] = problem.question;
      $scope.contentData.questionSource = problem.questionSource;

      if ($scope.isSourceURLRequired() && !$scope.contentData.sourceDetail)
        $scope.contentData.sourceDetail = $scope.user.username;

      $scope.contentData.grade = problem.grade;

      if (problem.grade && problem.grade !== "0") {
        var selectedGrades = problem.grade.split(/, ?/);

        for (var i = 0; i < $scope.viewModel.gradeList.length; i++)
          for (var j = 0; j < selectedGrades.length; j++)
            if ($scope.viewModel.gradeList[i].label === selectedGrades[j])
              $scope.viewModel.gradeList[i].selected = true;

        $scope.viewModel.selectedGrades = _.filter($scope.viewModel.gradeList, function (grade) {
          return grade.selected === true;
        });

        populateDomains().then(function () {
          var domainIds = problem.root_standard.split(',');

          domainIds.forEach(function (id) {
            var domain = _.find($scope.formData.domainList, { id: id });

            if (domain)
              selectDomain(domain);
          });
        });

        $scope.viewModel.selectedGradesAsString = problem.grade;
      }

      if (problem.imgList)
        $scope.viewModel.imgURL[englishLocale] = problem.imgList[0];

      if ($stateParams.edit) {
        $scope.contentData.title[englishLocale] = problem.title;
        $scope.contentData.tag = problem.tag.toString();
      }

      if ($stateParams.clone)
        $scope.contentData.title[englishLocale] = 'Clone of ' + problem.title;

      $scope.contentData.learningObjective = $scope.contentData.learningObjective.toString();

      $scope.contentData.addToBank = problem.addToBank;

      if (problem.translations) {
        problem.translations.forEach(function (translation) {
          $scope.contentData.title[translation.locale] = translation.title;
          $scope.contentData.question[translation.locale] = translation.question;
          $scope.contentData.answer[translation.locale] = translation.answer;
          $scope.viewModel.imgURL[translation.locale] = translation.imgList[0];
        });
      }

      digest();
    });
  } else {
    populateSources();

    function applySettings(settings) {
      globalSettingsStrategies = settings.creatingThinklets.plan.strategies;

      var strategies = globalSettingsStrategies.filter(function (strategy) {
        return strategy.enabled && strategy.text.trim();
      }).map(function (strategy) {
        return strategy.text;
      });

      translate(strategies, $translate, $rootScope, $scope, function (translations) {
        if ($scope.userStrategies) {
          $scope.userStrategies.forEach(function (strategy) {
            var found = globalSettingsStrategies.find(function (settingStrategy) {
              return strategy.translationId === settingStrategy.text;
            });

            if (found)
              strategy.name = translations[strategy.translationId];
          });
        } else {
          $scope.userStrategies = Object.keys(translations).map(function (translationId) {
            return {
              name: translations[translationId],
              selected: true,
              translationId: translationId
            };
          });
        }
      });
    }

    if (permissionsManager.getPermission(Permissions.StrategyCustomization)) {
      UserManager.getGlobalSettings().then(settings => {
        applySettings(settings);
      });
    } else {
      applySettings(CueThinkConfig.smartDesignSettings);
    }

    $scope.userCheckList = null;

    translate(TeacherManager.getDefaultReviewChecklist(), $translate, $rootScope, $scope, function (translations) {
      if ($scope.userCheckList) {
        $scope.userCheckList.forEach(function (item) {
          if (item.translationId) {
            $translate(item.translationId).then(function (value) {
              item.checkListItem = value;
            });
          }
        });
      } else {
        var checklist = [];
        var keys = Object.keys(translations);

        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];

          checklist.push({
            checkListItem: translations[key],
            selected: true,
            translationId: key
          });
        }

        $scope.userCheckList = checklist;
      }
    });
  }

  resetGradeSelections();

  $scope.showContentTips = function () {
    $scope.areContentTipsVisible = true;
  };

  $scope.hideContentTips = function () {
    $scope.areContentTipsVisible = false;

    if (typeof localStorage !== 'undefined')
      localStorage.setItem('contentTipsLastClosed', new Date().getTime());
  };

  function getSelectedDomainNames() {
    return getSelectedDomains().map(function (domain) {
      return domain.name;
    });
  }

  function getStandardIds() {
    var ccssIds = [];

    if ($scope.viewModel.selectedStandards) {
      $scope.viewModel.selectedStandards.forEach(function (standard) {
        ccssIds.push(standard.id);
      });
    }

    if ($scope.viewModel.problem && $scope.viewModel.problem.ccssId) {
      var oldCcssIds = $scope.viewModel.problem.ccssId.split(/, ?/);

      var missingCcssIds;

      if ($scope.standards) {
        missingCcssIds = _.filter(oldCcssIds, function (ccssId) {
          return !_.find($scope.standards, { id: ccssId });
        });
      } else {
        missingCcssIds = oldCcssIds;
      }

      _.each(missingCcssIds, function (ccssId) {
        ccssIds.push(ccssId);
      });
    }

    return ccssIds;
  }

  $scope.showDetails = function (event) {
    $scope.viewModel.standardIds = getStandardIds();
    $scope.viewModel.isProblemPopUpVisible = true;

    event.stopPropagation();
  };

  $scope.closeDetailsPopup = function () {
    $scope.moreData = null;
  };

  $scope.removeStrategy = function (strategy) {
    $scope.userStrategies.splice($scope.userStrategies.indexOf(strategy), 1);
  };

  $scope.cueteachEnabled = UserManager.getUser().cueteachEnabled;

  if (typeof localStorage !== 'undefined') {
    var contentTipsLastClosed = localStorage.getItem('contentTipsLastClosed');

    if (contentTipsLastClosed) {
      var age = new Date().getTime() - contentTipsLastClosed;

      if (age >= 2 * 7 * 24 * 60 * 60 * 1000) // 2 weeks
        $scope.areContentTipsVisible = true;
    } else {
      $scope.areContentTipsVisible = true;
    }
  }

  $scope.isNewProblem = function () {
    return !$scope.problem || $stateParams.clone;
  };

  translate(['WHAT_IS_THE_RELATIONSHIP_BETWEEN', 'HOW_DO_YOU_THINK', 'WHY_DOES', 'USE_YOUR_UNDERSTANDING_TO_EXPLAIN_WHY', 'EXPLAIN_YOUR_REASONING'], $translate, $rootScope, $scope, function (translations) {
    $scope.viewModel.sentenceStarters = [
      translations.WHAT_IS_THE_RELATIONSHIP_BETWEEN,
      translations.HOW_DO_YOU_THINK,
      translations.WHY_DOES,
      translations.USE_YOUR_UNDERSTANDING_TO_EXPLAIN_WHY,
      translations.EXPLAIN_YOUR_REASONING
    ];
  });

  $scope.addStarter = function (starter, locale) {
    if (!$scope.contentData.question[locale])
      $scope.contentData.question[locale] = '';

    $scope.contentData.question[locale] += starter;
  };

  function bindEvent(element, type, handler, cleanupHandler) {
    element.on(type, handler);
    element.on('remove', function (event) {
      $(event.target).off(type, handler);

      if (cleanupHandler)
        cleanupHandler();
    });
  }

  var _equationEditor, _equationEditorBody, _equationEditorBackdrop;
  var equationParameters = {
    language: 'en',
    fontSize: '28px',
    hand: false,
    fontStyle: 'normal',
    fontFamily: 'Arial',
    toolbar: '<toolbar ref="general" removeLinks="true"><removeTab ref="contextual" /></toolbar>'
  };

  var textEditor, deferredAction;

  (function addEquationTool() {
    var timer;

    var fireTimer = function () {
      _equationEditorBackdrop = $('.equation-editor-backdrop:first');

      if (_equationEditorBackdrop.length === 0)
        return;

      clearInterval(timer);
      timer = null;

      var equationEditorContainer = $('.equation-editor-backdrop:first .equation-editor-container:first');
      equationEditorContainer.draggable();

      _equationEditorBody = _equationEditorBackdrop.find('.equation-editor:first');

      var _closeEquationButton = _equationEditorBackdrop.find('button.close:first');
      bindEvent(_closeEquationButton, 'click', onEquationCloseClick);

      bindEvent(_equationEditorBackdrop.find('.save:first'), 'click', equationSaveClick, function () {
        delete taTools.equationEditor;
        _equationEditor = _equationEditorBody = _equationEditorBackdrop = null;
      });
    };

    timer = setInterval(fireTimer, 1000);

    taTools.equationEditor = {
      display: "<button tabindex=\"0\" ng-click='action()' ng-class='displayActiveToolClass(active)' class='equation-button'></button>",
      action: function (deferred, restoreSelection) {
        textEditor = this.$editor();
        deferredAction = deferred;
        _restoreSelection = restoreSelection;

        if (!_equationEditor) {
          _equationEditor = com.wiris.jsEditor.JsEditor.newInstance(equationParameters);
          _equationEditor.insertInto(_equationEditorBody[0]);
        }

        if (_equationEditorBackdrop.css('display') == 'none') {
          _equationEditorBackdrop.show();

          _equationEditor.focus();
        } else {
          _equationEditorBackdrop.hide();
        }

        return false;
      },
      activeState: function () {
        return false;
      } //If this returns true then the ta-toolbar-button-active-class will be applied to the button.
    };
  })();

  // TODO: test
  var unbindStateChangeListener = $transitions.onSuccess({ to: 'create-new-problem' }, function () {
    addEquationTool();
  });

  $scope.$on("$destroy", function () {
    unbindStateChangeListener();
  });

  function onEquationCloseClick() {
    closeEquation();
  }

  function equationSaveClick() {
    commitEquation();

    _equationEditor.setMathML('<math></math>');

    closeEquation();
  }

  function closeEquation() {
    if (_equationEditorBackdrop)
      _equationEditorBackdrop.hide();
  }

  function commitEquation() {
    var markup = _equationEditor.getMathML();

    if (!markup || markup == '<math xmlns="http://www.w3.org/1998/Math/MathML"/>' || markup == '<math></math>')
      return;

    var model = _equationEditor.getEditorModel();
    var styles = model.getCurrentStyles();
    var font = styles.getFontFamily();
    var size = styles.getFontSize();

    if (font == 'inherit')
      font = equationParameters.fontFamily;

    if (size === 0)
      size = parseInt(equationParameters.fontSize);

    renderEquation(markup, font, size);
  }

  function escapeHtml(unsafe) {
    return unsafe
    .replace(/&/g, "&amp;")
    .replace(/\</g, "&lt;")
    .replace(/\>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
  }

  var renderEquation = function (mathML, font, size) {
    MediaManager.renderMathML(0, mathML, font, size).then(function (url) {
      _restoreSelection();
      _restoreSelection = null;

      var encodedMathML = btoa(mathML);
      var html = '<img src="' + escapeHtml(url) + '" alt="' + escapeHtml(encodedMathML) + '" />';
      deferredAction.resolve(textEditor.wrapSelection('insertHTML', html, true));
      deferredAction = null;

      setTimeout(function () {
        var contentHeight = textEditor.displayElements.text.children().first().height();

        if (contentHeight > textEditor.displayElements.scrollWindow.height())
          textEditor.displayElements.scrollWindow.css('height', contentHeight + 'px');

        textEditor = null;
      }, 500);
    });
  };

  $timeout(function () {
    $('.create-new-problem:first .scrollContainer:first').on('scroll', function () {
      $scope.$digest();
    });
  });

  $scope.goToStepOne = ContentHeaderController.goToStepOne;
  $scope.goToStepTwo = ContentHeaderController.goToStepTwo;
  $scope.goToStepThree = ContentHeaderController.goToStepThree;

  // sharing

  $scope.onShareChange = function () {
    if ($scope.viewModel.isShared)
      $scope.viewModel.showSharePrompt();
  };

  $scope.submitSharePrompt = function () {
  };

  $scope.cancelSharePrompt = function () {
    $scope.viewModel.isShared = false;
  };

  $scope.isStepOneActive = function () {
    return ContentHeaderController.activeStep === 1;
  };

  $scope.isStepTwoActive = function () {
    return ContentHeaderController.activeStep === 2;
  };

  $scope.isStepThreeActive = function () {
    return ContentHeaderController.activeStep === 3;
  };

  function addStandard(standard) {
    $scope.viewModel.selectedStandards.push(standard);
  }

  $scope.removeStandard = function (standard) {
    var i = getStandardIndex(standard);

    $scope.viewModel.selectedStandards.splice(i, 1);
  };

  $scope.clearSelectedStandards = function () {
    if ($scope.standards) {
      $scope.standards.forEach(function (standard) {
        standard.selected = false;
      });
    }

    $scope.viewModel.selectedStandards.splice(0);
  };

  $scope.getGetterSetterForStandard = function (standard) {
    return function (value) {
      if (arguments.length > 0) {
        if (value)
          addStandard(standard);
        else
          $scope.removeStandard(standard);
      } else {
        return $scope.isStandardSelected(standard);
      }
    };
  };

  translate('TERMS_OF_USE', $translate, $rootScope, $scope, function (termsOfUse) {
    $translate('TERMS_OF_AGREEMENT_TEXT', { termsOfUseLink: '<a tabindex="0" href="http://www.cuethink.com/terms-of-use" target="_blank">' + termsOfUse + '</a>' }).then(function (text) {
      $scope.termsOfAgreementHtml = text;
    });
  });

  $scope.onStrategyChange = function (strategy) {
    strategy.translationId = null;
  };

  $scope.onChecklistItemChange = function (item) {
    item.translationId = null;
  };

  setUserModesScopeMembers($scope);

  $scope.isStrategyCustomizationEnabled = () => {
    return permissionsManager.getPermission(Permissions.StrategyCustomization);
  };
}

export default ManageContentController;
