import './paginatorDirective.css';
import tpl from './paginatorDirective.tpl.html';
import 'angular-translate';

export default function PaginatorDirective($translate) {
  return {
    link($scope) {
      if (typeof $scope.showArrows === 'undefined')
        $scope.showArrows = true;

      $scope.onNextButtonClick = () => {
        $scope.$emit('SelectedNextPage');
      };

      $scope.onPreviousButtonClick = () => {
        $scope.$emit('SelectedPreviousPage');
      };
    },
    restrict: 'E',
    scope: {
      currentPage: '=',
      pages: '=',
      showArrows: '='
    },
    template: tpl
  }
}

export function bootstrapDirective(module) {
  module.directive('paginator', PaginatorDirective);
}
