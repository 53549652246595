<template>
  <div
    class="viewThinklet"
    id="view-thinklet">
    <template v-if="thinklet">
      <div class="main">
        <div id="player-and-details">
          <annotations-overlay
            :annotations="thinklet.annotations"
            :playback-state="playbackState"
            :seek-time="seekTime"
            :selected-annotation="selectedAnnotation"
            :selector="areaSelector"
            :time="time"
            :ui-callback="annotationCallback"
            @click="clickedAnnotation">
            <whiteboard-player
              :audio-url="thinklet.audioURL"
              :events="events"
              :line-width="4"
              :load-image="loadImage"
              :ratio="ratio"
              :user="user"
              :video-url="videoURL"
              @update:playbackState="onPlaybackStateUpdate"
              @update:seekTime="onUpdateSeekTime"
              @update:time="onUpdateTime">
            </whiteboard-player>

            <new-annotation
              v-if="showNewAnnotation"
              ref="newAnnotation"
              :color="newAnnotationColor"
              :default-sentence-starters="defaultSentenceStarters"
              :drag-area="areaSelector"
              :is-private="isAnnotationPrivate"
              :private-enabled="isPrivateAnnotationOptionEnabled()"
              :sentence-starters="sentenceStarters"
              :text="annotationText"
              :user="user"
              :x="annotationX"
              :y="annotationY"
              @add="onNewAnnotationAdd"
              @cancel="cancelAnnotation"
              @select-sentence-starter="onSelectSentenceStarter"
              @update:text="annotationText = $event">
            </new-annotation>
          </annotations-overlay>

          <button tabindex="0"
            v-if="goBack"
            class="back"
            @click="onBackButtonClick">
            &lt; {{ 'BACK' | translate }}
          </button>

          <div
            v-if="versions.length > 1 && !isStudentRubricEnabled && !thinklet.isExemplarThinklet"
            class="versions">
            <button tabindex="0"
              v-for="version in versions"
              class="version"
              :class="{ selected: version.version === thinklet.thinklet_version }"
              @click="onVersionClick(version)">
              v{{version.version}}
            </button>
          </div>

          <div id="info">
            <p class="title">{{ title }}</p>

            <p class="subtitle">
              <span class="user_name">{{ getUserName() }}</span>

              <span class="date">{{ getTimestamp() | formatDate }}</span>

              <span id="views">{{ getViews() }}</span>
            </p>

            <div class="buttons">
            </div>
          </div>
        </div>

        <div class="bottomContent">
          <div
            v-show="tab === 1"
            id="more-info">
            <div id="details">
              <h2 class="label">{{ 'QUESTION' | translate }}:</h2>

              <p
                class="question"
                v-html="question">
              </p>

              <img
                v-if="questionImage"
                alt="Question Image"
                :src="questionImage[0]"
              />

              <button tabindex="0"
                id="show-details"
                @click="onShowDetailsButtonClick">
                <i class="fa fa-angle-left fa-2x"></i>
              </button>

              <div
                class="hiddenDetails"
                id="hidden-details"
                :class="{ hidden: !isHiddenDetailsVisible }">
                <button tabindex="0"
                  @click="onHideDetailsButtonClick">
                  <i class="fa fa-angle-right fa-2x"></i>
                </button>

                <div class="more-details">
                  <div class="columns">
                    <div>
                      <h2>{{ 'WHAT_I_NOTICED' | translate }}</h2>

                      <div id="noticed">{{ noticedAsText }}</div>
                    </div>

                    <div>
                      <h2>{{ 'WHAT_I_WONDERED' | translate }}</h2>

                      <div id="wondered">{{ wonderedAsText }}</div>
                    </div>

                    <div>
                      <h2>{{ 'MY_STRATEGIES' | translate }}</h2>

                      <div id="my-strategies">
                        {{ strategiesAsText }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <p v-if="isNewDesign">
                      <label>{{ 'THE_PROBLEM_IS_ASKING_ME_TO' | translate }}:</label> <span>{{ thinklet.explore }}</span>
                    </p>
                    <p v-else>
                      <label>{{ 'MY_ESTIMATE' | translate }}:</label> <span>{{ thinklet.understand }}</span>
                    </p>

                    <p><label>{{ 'MY_PLAN' | translate }}:</label> <span>{{ thinklet.plan }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="tab === 2"
            class="thinklets"
            id="morefromThinklets">
            <thinklet-card
              v-for="t in moreThinklets"
              class="thinklet"
              :can-create="false"
              :can-edit="false"
              :can-remove="false"
              :editable="false"
              :name-format="getNameFormat()"
              :thinklet="t"
              @click="onThinkletClick(t)">
            </thinklet-card>
          </div>

          <!--Footer-->
          <div
            v-if="isFooterEnabled"
            class="footer">
            <div
              class="footer-tab"
              :class="{'selected' : isTabSelected(1)}"
              @click="selectTab(1)">

              <a tabindex="0">
                <template v-if="isNewDesign">
                  {{ 'DETAILS' | translate }}
                </template>
                <template v-else>
                  {{ 'DETAILS' | translate | uppercase }}
                </template>
              </a>
            </div>

            <div
              v-if="moreThinklets && moreThinklets.length > 0"
              class="footer-tab"
              :class="{'selected' : isTabSelected(2)}"
              @click="selectTab(2)">

              <a tabindex="0">
                <template v-if="isNewDesign">
                  {{ 'MORE_FROM' | translate }}
                </template>
                <template v-else>
                  {{ 'MORE_FROM' | translate | uppercase }}
                </template>

                <span>{{ getUserName() }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="annotations">
        <div class="annotations-body">
          <div
            class="annotationContainer"
            v-show="isAnnotationsVisible()">
            <div class="add-annotation-container">
              <button tabindex="0"
                v-if="user && isAnnotationsEnabled()"
                id="add-annotation"
                @click="startNewAnnotation">
                + {{ 'ANNOTATION' | translate }}
              </button>
            </div>

            <template v-for="(annotation, i) in sortedAnnotations">
              <video-annotation
                v-if="isAnnotationVisible(annotation)"
                :annotation="annotation"
                :class="{ selected: isAnnotationSelected(annotation) }"
                :colors="annotationColors"
                :index="i"
                :is-annotations-enabled="isAnnotationsEnabled()"
                :is-reviewer="isTeacher()"
                :is-private-enabled="isPrivateAnnotationReplyOptionEnabled()"
                :is-reply-enabled="!thinklet.isExemplarThinklet"
                :key="annotation.id"
                :reply-count="getRepliesForAnnotation(annotation).length"
                :user="user"
                :user-id="user.id"
                :user-name="user.username"
                @begin-reply="beginReply"
                @click.native="selectAnnotation(annotation)"
                @edit="editAnnotation"
                @flag="toggleAnnotationFlag(annotation)"
                @like="toggleAnnotationLike(annotation)"
                @remove="removeAnnotation(thinklet, annotation)"
                @reply="onVideoAnnotationReply(annotation, $event)"
                @unflag="onVideoAnnotationUnflag(annotation)"
                @view-replies="onViewReplies">
              </video-annotation>

              <template v-for="reply in getRepliesForAnnotation(annotation)">
                <video-annotation
                  v-if="isAnnotationReplyVisible(reply, annotation)"
                  :annotation="reply"
                  :class="{ selected: isAnnotationSelected(annotation) }"
                  :is-annotations-enabled="isAnnotationsEnabled()"
                  :is-reviewer="isTeacher()"
                  :is-private-enabled="isPrivateAnnotationReplyOptionEnabled()"
                  :is-reply-enabled="!thinklet.isExemplarThinklet"
                  :key="annotation.id"
                  :reply-count="getRepliesForAnnotation(reply).length"
                  :target-user-name="getTargetUserNameForAnnotationReply(reply)"
                  :user="user"
                  :user-id="user.id"
                  :user-name="user.username"
                  @begin-reply="beginReply"
                  @click.native="selectAnnotation(annotation)"
                  @edit="onVideoAnnotationEdit"
                  @flag="toggleAnnotationReplyFlag(annotation, reply)"
                  @like="toggleAnnotationReplyLike(annotation, reply)"
                  @remove="removeAnnotationReply(annotation, reply)"
                  @reply="onVideoAnnotationReplyReply(annotation, reply, $event)"
                  @view-replies="onViewReplies">
                </video-annotation>
              </template>
            </template>
          </div>

          <rubric
            v-if="isRubricVisible()"
            :is-editable="!isStudentRubricEnabled && thinklet.rubric.editable && (!thinklet.rubric.gradedBy || thinklet.rubric.gradedBy === user.username)"
            :is-rubric-shared="isRubricShared"
            :is-student="isStudentRubricEnabled"
            :thinklet="thinklet"
            :user="user"
            :user-name="user.username"
            @close="hideRubric"
            @reset="onRubricReset"
            @save="rubricSave"
            @save-notes="onRubricSaveNotes"
            @save-student-reflection="onRubricSaveStudentReflection"
            @share-rubric="shareThinkletRubric">
          </rubric>

          <labels
            v-if="isLabelsVisible()"
            :isCueTeachEnabled="isCueTeachEnabled"
            :teacherManager="teacherManager"
            :thinklet="thinklet"
            :user="user">
          </labels>
        </div>
      </div>

      <div
        v-if="isTeacher() && isCueTeachEnabled && isEducatorMode"
        class="teacher-tabs">
        <button tabindex="0"
          class="annotations-button"
          :class="{ active: isAnnotationsVisible() }"
          @click="showAnnotations">
          <i class="fa fa-comment fa-2x"></i>
        </button>

        <button tabindex="0"
          v-if="thinklet.rubric && isCueTeachEnabled"
          class="rubric-button"
          :class="{ active: isRubricVisible() }"
          @click="showRubric">
          <i class="fa fa-list-alt fa-2x"></i>
        </button>

        <button tabindex="0"
          class="labels-button"
          :class="{ active: isLabelsVisible() }"
          @click="showLabels">
          <i class="fa fa-tags fa-2x"></i>
        </button>
      </div>

      <div
        v-if="isAddTagsVisible"
        id="add-tag">
        <div id="new-tag">
          <input
            type="text"
            :aria-label="translate('ADD_YOUR_OWN')"
            :placeholder="translate('ADD_YOUR_OWN')"
            v-model="newTagName"
          />

          <button tabindex="0"
            id="submit-tag"
            :style="{ opacity: newTagName.length > 0 ? 1 : .5 }"
            @click="addTag(newTagName)">
            {{ 'SUBMIT' | translate }}
          </button>
        </div>

        <div id="tags">
          <div v-for="tag in tags">
            <input
              type="checkbox"
              :aria-label="tag"
              :name="tag"
              :value="true"
              v-model="selectedTags[tag]"
              @change="tagsSave"
            />

            <label :for="tag">
              {{ tag }}
            </label>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import 'font-awesome/css/font-awesome.min.css';
import formatTime from 'core/common/filters/formatTime.filter';
import lowercase from 'core/common/filters/lowercase.filter';
import translate from 'core/common/filters/translate.filter';
import Tutorial from 'core/tutorial/tutorial';
import {
  getLocalizedThinkletQuestion,
  getLocalizedThinkletQuestionImage,
  getLocalizedThinkletTitle
} from '../common/translation-helpers';
import NotificationCenter from '../notifications/notification-center';
import _ from 'underscore';
import thinkletLoaderMixin from '../common/mixins/thinklet-loader.mixin';
import translateMixin from '../common/mixins/translate.mixin';
import Vue from 'vue';
import './images/Grey_EyeIcon.svg';
import './images/ArrowIcon.svg';
import './images/HeartIcon.svg';
import './images/FlagIcon.svg';
import './images/Rubric.svg';
import './images/Tag_Icon.svg';
import './images/HeartIcon_Unselected.svg';
import './images/FlagIcon_Unselected.svg';
import LogManager from '../logging/log-manager';
import RouteManager from '../common/route-manager'

export default {
  mixins: [
    require('core/common/mixins/config.mixin').default,
    thinkletLoaderMixin,
    translateMixin,
    require('core/common/mixins/user.mixin').default
  ],

  components: {
    AnnotationsOverlay: () => import('./annotations-overlay'),
    Labels: () => import('./labels'),
    NewAnnotation: () => import('./new-annotation'),
    Rubric: () => import('../rubric/rubric'),
    ThinkletCard: () => import('../thinklet/thinklet-card'),
    VideoAnnotation: () => import('./video-annotation'),
    WhiteboardPlayer: () => import('core/whiteboard/whiteboard-player.vue')
  },

  filters: {
    formatDate: val => formatTime(val, 'MM.DD.YY'),
    lowercase,
    translate,
    uppercase: require('core/common/filters/uppercase.filter').default
  },

  props: {
    defaultSentenceStarters: {
      required: true,
      type: Array
    },

    goBack: {
      default: false,
      required: false,
      type: Boolean,
    },

    isCueTeachEnabled: {
      default: false,
      required: false,
      type: Boolean
    },

    isEducatorMode: {
      default: false,
      required: false,
      type: Boolean
    },

    isFooterEnabled: {
      default: true,
      required: false,
      type: Boolean
    },

    isPrivateAnnotationsEnabled: {
      default: false,
      required: false,
      type: Boolean
    },

    isStudentRubricEnabled: {
      default: false,
      required: false,
      type: Boolean
    },

    sampleThinklet: {
      required: false,
      type: Object
    },

    teacherManager: {
      required: true,
      type: Object
    },

    thinkletId: {
      required: true,
      type: Number
    },

    thinkletManager: {
      required: true,
      type: Object
    },

    user: {
      required: false,
      type: Object
    },

    userManager: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      annotationColors: ['rgb(155, 66, 153)', 'rgb(176, 102, 169)', 'rgb(153, 113, 157)', 'rgb(118, 201, 179)', 'rgb(146, 210, 192)', 'rgb(173, 220, 206)', 'rgb(37, 49, 91)', 'rgb(72, 75, 112)', 'rgb(107, 105, 136)'],
      annotationText: null,
      annotationX: null,
      annotationY: null,
      areaSelector: '.whiteboard-player:first',
      editedAnnotation: null,
      isAddTagsVisible: false, // TODO
      isAnnotationPrivate: false,
      isHiddenDetailsVisible: false,
      isLoaded: false,
      isRubricShared: false,
      moreThinklets: null,
      newAnnotationColor: null,
      newTagName: null,
      noticedAsText: null,
      originalLabelText: null,
      playbackState: null,
      question: null,
      questionImage: null,
      seekTime: null,
      selectedAnnotation: null,
      selectedAnnotations: [],
      selectedTags: null,
      sentenceStarters: null,
      settings: null,
      showNewAnnotation: false,
      sortedAnnotations: [],
      strategiesAsText: null,
      tab: 1,
      tags: null,
      time: null,
      title: null,
      versions: [],
      visibleReplyAnnotations: [],
      visibleSidebar: 'annotations',
      wonderedAsText: null
    };
  },

  computed: {
    defaultAvatarColors() {
      return this.config.defaultAvatarColors;
    },

    tutorial() {
      return Tutorial.sharedInstance;
    }
  },

  watch: {
    sampleThinklet() {
      this.handleThinkletData(this.sampleThinklet);
    },

    'thinklet.annotations': function () {
      this.updateSortedAnnotations();
    }
  },

  created() {
    if (this.isStudentRubricEnabled)
      this.visibleSidebar = 'rubric';

    this.time = 0;

    this.loadGlobalSettings().then(settings => {
      this.settings = settings;

      this.sentenceStarters = settings.annotations.sentenceStarters.filter(starter => {
        return starter.enabled && starter.text.trim();
      }).map(starter => {
        return starter.text;
      });
    });

    if (typeof this.thinkletId === 'number' && this.thinkletId > 0) {
      this.loadThinkletData(this.thinkletId).then(thinklet => this.handleThinkletData(thinklet));

      this.thinkletManager.loadThinkletVersions(this.thinkletId).then(versions => {
        this.versions = versions;
      });
    }
  },

  methods: {
    addAnnotation(text, x, y, isPrivate) {
      if (!text)
        return;

      this.showNewAnnotation = false;

      var annotation;

      if (this.editedAnnotation)
        annotation = this.editedAnnotation;
      else
        annotation = {};

      annotation.offset = this.time;
      annotation.private = isPrivate;
      annotation.text = text;
      annotation.user = this.user;
      annotation.x = x;
      annotation.y = y;

      var id;

      if (annotation.annotationId)
        id = annotation.annotationId;
      else if (annotation.id)
        id = annotation.id;

      if (id) {
        this.thinkletManager.saveAnnotation(annotation);

        var annotations = this.thinklet.annotations;

        for (var i = 0; i < annotations.length; i++) {
          if (annotations[i].annotationId === id || annotations[i].id === id) {
            annotations[i] = annotation;

            break;
          }
        }
      } else {
        annotation.time = this.time;

        this.thinkletManager.addAnnotation(this.thinklet, annotation).then(() => {
          this.updateSortedAnnotations();
        });

        this.selectAnnotation(annotation, false);

        this.highlightAnnotation(annotation);
      }

      this.$refs.newAnnotation.blur();
    },

    addAnnotationReply(annotation, reply, replyText, isPrivate) {
      this.thinkletManager.addAnnotationReply(annotation, reply, replyText, isPrivate).then(() => {
        Vue.set(annotation, 'replies', this.sortReplies(annotation.replies));
      });
    },

    addTag(tag) {
      // TODO
    },

    annotationCallback(manager, annotation) {
      const overlay = document.createElement('div');
      overlay.className = 'annotation-overlay';

      let i;
      let color;

      if (this.isNewDesign) {
        const componentClass = Vue.extend(require('core/common/avatar').default);

        const component = new componentClass({
          propsData: {
            firstInitialOnly: true,
            fontSize: 12,
            size: 20,
            user: annotation.user
          }
        });

        component.$mount();

        overlay.appendChild(component.$el);
      } else {
        const annotations = manager.getAnnotations();

        if (annotations && annotations.length > 0) {
          i = annotations.indexOf(annotation);

          if (i < 0)
            i = annotations.length;
        } else {
          i = 0;
        }

        i = i % this.annotationColors.length;

        color = this.annotationColors[i];
      }

      if (color)
        overlay.style.backgroundColor = color;

      this.highlightAnnotation(annotation);

      return overlay;
    },

    cancelAnnotation() {
      this.showNewAnnotation = false;
    },

    clickedAnnotation() {
      NotificationCenter.getShared().post('PauseVideo');
    },

    editAnnotation(annotation) {
      this.newAnnotationColor = this.annotationColors[this.thinklet.annotations.indexOf(annotation) % (this.annotationColors.length + 1)];

      this.editedAnnotation = annotation;

      this.annotationX = annotation.x;
      this.annotationY = annotation.y;
      this.annotationText = annotation.text;
      this.isAnnotationPrivate = annotation.private;
      this.showNewAnnotation = true;
    },

    filterReplies(annotation, thinkletUserId) {
      if (annotation.replies) {
        Vue.set(annotation, 'replies', _.filter(annotation.replies, a => {
          var result = this.isAnnotationReplyVisible(a, annotation, true);

          if (result)
            this.filterReplies(a, thinkletUserId);

          return result;
        }));

        Vue.set(annotation, 'replies', this.sortReplies(annotation.replies));
      }
    },

    handleThinkletData(thinklet) {
      var localizedQuestion = getLocalizedThinkletQuestion(null, thinklet);

      var hasTex = localizedQuestion && (localizedQuestion.indexOf('$$') >= 0 || localizedQuestion.indexOf('\\(') >= 0);

      if (hasTex)
        var question = $('#details:first .question:first').hide();

      thinklet.annotations = _.filter(thinklet.annotations, annotation => {
        var result = this.isAnnotationVisible(annotation, thinklet);

        if (result)
          this.filterReplies(annotation, thinklet.user.id);

        return result;
      });

      this.thinklet = thinklet;

      this.updateSortedAnnotations();

      if (hasTex) {
        setTimeout(() => {
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, question[0]]);

          MathJax.Hub.Queue(() => {
            question.show();
          });
        });
      }

      this.loadThinkletRecording(thinklet);

      this.isRubricShared = thinklet.is_rubric_shared;

      this.setLocalizedText();
    },

    highlightAnnotation(annotation) {
      this.selectedAnnotations.push(annotation);

      setTimeout(() => {
        this.selectedAnnotations.splice(this.selectedAnnotations.indexOf(annotation), 1);
      }, 2000);
    },

    isAnnotationReplyVisible(annotation, parentAnnotation, isFiltering) {
      if ((annotation.isFlagged || annotation.flagged) && (!this.isTeacher() || !this.isEducatorMode))
        return false;

      if (!isFiltering && this.isNewDesign && this.visibleReplyAnnotations.indexOf(parentAnnotation) === -1)
        return false;

      if (annotation.private) {
        if (this.user) {
          var annotationUserId = parentAnnotation.userId;

          if (!annotationUserId && parentAnnotation.user)
            annotationUserId = parentAnnotation.user.id;

          if (this.user.type === 'Student') {
            if (this.user.id !== annotationUserId)
              return false;
          } else if (!this.isEducatorMode && this.user.id !== annotationUserId) {
            return false;
          }
        } else {
          return false;
        }
      }

      return true;
    },

    isAnnotationSelected(annotation) {
      return this.selectedAnnotations.indexOf(annotation) >= 0;
    },

    isAnnotationVisible(annotation, thinklet) {
      if (!this.user)
        return false;

      if (!thinklet)
        thinklet = this.thinklet;

      var thinkletUserId = thinklet ? thinklet.user.id : null;

      if ((annotation.isFlagged || annotation.flagged) && (!this.isTeacher() || !this.isEducatorMode))
        return false;

      if (annotation.private) {
        if (this.user) {
          if (this.user.type === 'Student') {
            if (this.user.id !== thinkletUserId)
              return false;
          } else if (!this.isEducatorMode && this.user.id !== thinkletUserId) {
            return false;
          }
        } else {
          return false;
        }
      }

      return true;
    },

    isMobileApp() {
      return false;
    },

    isStudent() {
      return !this.user || this.user.type === 'Student';
    },

    isTabSelected(activeTab) {
      return this.tab === activeTab;
    },

    likeThinklet() {
      if (this.thinklet.isLiked)
        this.thinkletManager.unlikeThinklet(this.thinklet);
      else
        this.thinkletManager.likeThinklet(this.thinklet);
    },

    loadGlobalSettings() {
      return this.userManager.getGlobalSettings().then(settings => {
        return settings.viewingThinklets;
      });
    },

    loadMoreThinklets() {
      return this.thinkletManager.loadRecentThinklets(0, 12, null, null, null, null, null, false, this.thinklet.user.username);
    },

    loadThinkletData(thinkletId) {
        return this.thinkletManager.loadThinklet(thinkletId).then(thinklet => {
          if (thinklet.items) {
            this.noticedAsText = thinklet.items.known ? thinklet.items.known.join("\n") : '';
            this.wonderedAsText = thinklet.items.wondering ? thinklet.items.wondering.join("\n") : '';
          }

          if (thinklet.complexItems) {
            var strategyNames = _.pluck(_.filter(thinklet.complexItems.strategies, { status: 'true' }), 'name');

            const translations = translate(strategyNames); // TODO: test

            if (translations)
              this.strategiesAsText = Object.values(translations).join("\n");
            else
              this.strategiesAsText = strategyNames.join("\n");
          }

          this.thinklet = thinklet;

          if (thinklet.id !== -1) {
            this.loadMoreThinklets().then(thinklets => {
              this.moreThinklets = thinklets;
            });
          }

          return thinklet;
        }, false, true, true);
      },

    loadThinkletRecording(thinklet) {
      return this.loadThinklet(thinklet, false);
    },

    onHideDetailsButtonClick() {
      this.isHiddenDetailsVisible = false;
    },

    onLanguageChange(lang) {
      this.setLocalizedText(lang);
    },

    onSelectSentenceStarter(starter) {
      LogManager.getShared().logBehaviorEvent('Annotation Sentence Starters', { thinkletId: this.thinklet.id }, {
        'Sentence Starter Used': starter
      });
    },

    onShowDetailsButtonClick() {
      this.showDetails();
    },

    onViewReplies(annotation) {
      const i = this.visibleReplyAnnotations.indexOf(annotation);

      if (i === -1)
        this.visibleReplyAnnotations.push(annotation);
      else
        this.visibleReplyAnnotations.splice(i, 1);
    },

    pushRepliesForReply(replies, replyId, result) {
      if (!replyId)
        replyId = 0;

      for (var i = 0; i < replies.length; i++) {
        var reply = replies[i];

        if (reply.replyId === replyId) {
          result.push(reply);

          this.pushRepliesForReply(replies, reply.id, result);
        }
      }
    },

    removeAnnotation(thinklet, annotation) {
      this.thinkletManager.deleteAnnotation(thinklet, annotation); // TODO: reactive

      this.updateSortedAnnotations();
    },

    removeAnnotationReply(annotation, reply) {
      this.thinkletManager.deleteAnnotationReply(annotation, reply);

      this.updateSortedAnnotations();
    },

    selectAnnotation(annotation, seek) {
      if (seek !== false) {
        NotificationCenter.getShared().post('PauseVideo');
        NotificationCenter.getShared().post('SeekVideo', annotation.time);
      }

      this.selectedAnnotation = annotation;

      setTimeout(() => {
        this.selectedAnnotation = null;
      });
    },

    selectTab(tab) {
      this.tab = tab;
    },

    showDetails() {
      this.isHiddenDetailsVisible =  true;
    },

    sortReplies(replies) {
      var result = [];

      this.pushRepliesForReply(replies, null, result);

      return result;
    },

    startNewAnnotation() {
      if (!this.user || this.user.annotationDisabled) {
        alert(translate('YOUR_TEACHER_HAS_DISABLED_YOUR_ABILITY_TO_MAKE_ANNOTATIONS'));

        return;
      }

      NotificationCenter.getShared().post('PauseVideo');

      this.editedAnnotation = null;
      this.newAnnotationColor = this.annotationColors[(this.thinklet.annotations ? this.thinklet.annotations.length : 0) % (this.annotationColors.length + 1)];
      this.annotationX = 0.5;
      this.annotationY = 0.5;
      this.annotationText = null;
      this.showNewAnnotation = true;
    },

    tagsSave() {
      // TODO
    },

    toggleAnnotationFlag(annotation) {
      if (annotation.isFlagged) {
        this.thinkletManager.unflagAnnotation(annotation);
      } else {
        this.thinkletManager.flagAnnotation(annotation).then(result => {
          if (result)
            if (!this.isTeacher())
              this.thinklet.annotations.splice(this.thinklet.annotations.indexOf(annotation), 1);
        });
      }
    },

    toggleAnnotationLike(annotation) {
      if (annotation.isLiked)
        this.thinkletManager.unlikeAnnotation(annotation);
      else
        this.thinkletManager.likeAnnotation(annotation);
    },

    toggleAnnotationReplyFlag(annotation, reply) {
      if (reply.flagged) {
        this.thinkletManager.unflagAnnotationReply(reply);
      } else {
        this.thinkletManager.flagAnnotationReply(reply).then(result => {
          if (result) {
            Vue.set(annotation, 'replies', annotation.replies.filter(r => {
              return r !== reply && r.replyId !== reply.id;
            }));
          }
        });
      }
    },

    toggleAnnotationReplyLike(annotation, reply) {
      if (reply.liked)
        this.thinkletManager.unlikeAnnotationReply(reply);
      else
        this.thinkletManager.likeAnnotationReply(reply);
    },

    // RUBRIC

    beginReply(annotationComponent) {
      setTimeout(() => annotationComponent.$el.scrollIntoView()); // TODO: test
    },

    getRepliesForAnnotation(annotation) {
      return annotation.replies ? annotation.replies : [];
    },

    getReplyWithId(id) {
      var result;

      _.each(this.thinklet.annotations, annotation => {
        result = _.find(annotation.replies, reply => {
          return reply.id === id;
        });

        if (result)
          return false;
      });

      return result;
    },

    getTargetUserNameForAnnotationReply(reply) {
      var target;

      if (reply.replyId) {
        target = this.getReplyWithId(reply.replyId);
      } else {
        target = _.find(this.thinklet.annotations, annotation => {
          return annotation.annotationId === reply.annotationId;
        });
      }

      if (target) {
        var firstName, lastName;

        if (target.user) {
          firstName = target.user.first_name;

          if (!firstName)
            firstName = target.user.firstname;

          lastName = target.user.last_name;

          if (!lastName)
            lastName = target.user.lastname;

          return firstName + ' ' + lastName.substr(0, 1).toUpperCase();
        } else if (target.firstname && target.lastname) {
          firstName = target.firstname;
          lastName = target.lastname;

          return firstName + ' ' + lastName.substr(0, 1).toUpperCase();
        } else {
          return target.username;
        }
      } else {
        return null;
      }
    },

    getTimestamp() {
      if (!this.thinklet)
        return null;

      return this.thinklet.creationTimestamp;
    },

    getTitle() {
      if (!this.thinklet)
        return null;

      return getLocalizedThinkletTitle(null, this.thinklet);
    },

    getUserName() {
      if (!this.thinklet)
        return null;

      return this.getDisplayName();
    },

    getViews() {
      if (!this.thinklet)
        return null;

      return this.thinklet.views;
    },

    isAnnotationsVisible() {
      return this.visibleSidebar === 'annotations';
    },

    isLabelsVisible() {
      return this.visibleSidebar === 'labels';
    },

    isRubricVisible() {
      return this.visibleSidebar === 'rubric';
    },

    isTeacher() {
      return this.user && this.user.type !== 'Student';
    },

    rubricSave(question) {
      this.thinkletManager.saveRubric(this.thinklet.id, question.id, question.optionId);
    },

    showAnnotations() {
      return this.visibleSidebar = 'annotations';
    },

    showLabels() {
      return this.visibleSidebar = 'labels';
    },

    showRubric() {
      this.visibleSidebar = 'rubric';

      this.showDetails();
    },

    getDisplayName() {
      var result = this.getNameFormat();

      result = result.replace(/%f/g, this.thinklet.user.firstName);
      result = result.replace(/%li/g, this.thinklet.user.lastName.substr(0, 1).toUpperCase());
      result = result.replace(/%l/g, this.thinklet.user.lastName);
      result = result.replace(/%u/g, this.thinklet.user.username);

      return result;
    },

    getNameFormat() {
      if (this.settings) {
        return this.settings.gallery.nameFormat;
      } else {
        return '';
      }
    },

    hideRubric() {
      // TODO
    },

    isAnnotationsEnabled() {
      if (!this.user)
        return false;

      if (this.isEducatorMode || (this.tutorial && this.tutorial.isActive())) {
        return true;
      } else if (this.settings) {
        if (this.settings.annotations.enabled === true) {
          return true;
        } else if (this.settings.annotations.enabled === false) {
          return false;
        } else if (this.user.type === 'Student' && this.settings.annotations.enabled === 'restricted') {
          if (this.thinklet) {
            return !this.user.classes.find(cls => {
              return this.settings.annotations.notAllowedForClasses.indexOf(cls.id) !== -1;
            });
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    isPrivateAnnotationOptionEnabled() {
      return this.isPrivateAnnotationsEnabled && this.isTeacher();
    },

    isPrivateAnnotationReplyOptionEnabled() {
      return this.isPrivateAnnotationOptionEnabled();
    },

    onBackButtonClick() {
      this.$emit('back');
    },

    onNewAnnotationAdd({ text, x, y, isPrivate }) {
      this.addAnnotation(text, x, y, isPrivate)
    },

    onPlaybackStateUpdate(value) {
      this.playbackState = value;

      this.$emit('update:playbackState', value);

      if (value === 'loaded') {
        this.isLoaded = true;
      } else if (this.isLoaded) {
        LogManager.getShared().logBehaviorEvent(value + ' thinklet', { thinkletId: this.thinklet.id, title: this.thinklet.title }, RouteManager.getSharedInstance().getCurrentRoute().name);

        this.startPlayingTime = null;
      }
    },

    onRubricReset(thinklet) {
      this.thinkletManager.resetRubric(thinklet);
    },

    onRubricSaveNotes({ step, thinklet, value }) {
      this.teacherManager.saveRubricNotes(thinklet, step, value);
    },

    onRubricSaveStudentReflection({ thinklet, value }) {
      this.thinkletManager.saveStudentReflection(thinklet, value);
    },

    onThinkletClick(t) {
      this.$emit('view-thinklet', t.id);
    },

    onUpdateSeekTime(value) {
      this.seekTime = value;
    },

    onUpdateTime(value) {
      this.time = value;
    },

    onVersionClick(version) {
      this.$emit('view-thinklet', version.id);
    },

    onVideoAnnotationEdit(reply) {
      this.thinkletManager.editAnnotationReply(reply);
    },

    onVideoAnnotationReply(annotation, { text, isPrivate }) {
      this.addAnnotationReply(annotation, null, text, isPrivate);
    },

    onVideoAnnotationReplyReply(annotation, reply, { text, isPrivate }) {
      this.addAnnotationReply(annotation, reply, text, isPrivate);
    },

    onVideoAnnotationUnflag(annotation) {
      this.thinkletManager.unflagAnnotation(annotation);
    },

    setLocalizedText(language) {
      this.title = getLocalizedThinkletTitle(null, this.thinklet, language);
      this.question = getLocalizedThinkletQuestion(null, this.thinklet, language);
      this.questionImage = getLocalizedThinkletQuestionImage(null, this.thinklet, language);
    },

    shareThinkletRubric(isShared) {
      this.thinkletManager.shareRubric(this.thinklet, isShared).then(() => {
        this.isRubricShared = isShared;
      });
    },

    translate(value) {
      return translate(value);
    },

    updateSortedAnnotations() {
      this.sortedAnnotations = _.sortBy(this.thinklet.annotations, ['time', 'timestamp']);
    }
  }
}
</script>

<style lang="scss">
.viewThinklet {
  .annotations-overlay {
    flex-grow: 1;
    -webkit-flex-grow: 1;

    .whiteboard-player {
      height: 100%;
    }
  }
}

/*
  Starting to refactor styles and overwrites specific to how thinklets are displayed in different pages
  Use classes instead of ids!!!
*/
.viewThinklet .thinklets .thinklet .thinklet-bottom-bar {
  padding: 2px !important;
  max-height: 36px !important;
}

#view-thinklet .main, #player-and-details, #view-thinklet annotationsoverlay, #view-thinklet annotationsoverlay > *, .annotations, #annotations-list, #info .buttons {
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

#view-thinklet, #view-thinklet .main {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

#view-thinklet .main, #player-and-details {
  -webkit-flex-direction: column;
  flex-direction: column;
}

#player-and-details {
  overflow: hidden;
}

.annotations {
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: black;
}

#add-annotation, #open-rubric {
  height: 47px;
  min-height: 47px;
  width: 100%;
  font-size: 22px;
}

#add-annotation {
  background-color: rgb(38, 169, 224);
  text-transform: uppercase;
}

#add-annotation, #info {
  color: white;
}

#view-thinklet .annotation-overlay {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

#more-info, #morefromThinklets {
  width: 100%;
  font-size: 15px;
  background-color: white;
}

#details, #more-info .user-thinklets {
  width: 100%;
}

#details {
  padding: 20px;
  overflow: hidden;
  position: relative;
  height: 100%;
}

#details .label {
  font-size: 20px;
  font-weight: 500;
  color: rgb(38, 169, 224);
}

#question img {
  float: right;
}

#details button {
  width: 38px;
  height: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  color: rgb(38, 169, 224);
}

#details>button {
  right: 0;
}

#hidden-details {
  font-size: 15px;
  color: rgb(99, 100, 104);
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  overflow-y: auto;
  transition-property: left;
  transition-duration: 0.5s;
}

#hidden-details.hidden {
  left: 100%;
  display: block !important;
}

  #hidden-details>button {
    left: 0;
  }

  .hiddenDetails .row {
    margin-left: 25px;
  }

.viewThinklet .bottomContent {
  position: relative;
  height: 175px;
  min-height: 175px;
}

.viewThinklet .bottomContent .more-details label {
  font-weight: 500;
  font-size: 20px;
}

.viewThinklet .bottomContent .more-details p {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
}

.viewThinklet .bottomContent label {
  display: inline;
}

.viewThinklet .bottomContent .footer {
  position: absolute;
  bottom: 0;
}

#view-thinklet .footer a {
  text-decoration: none;
  color: black;
}

#view-thinklet .footer div {
  border-right: 1px solid white;
  padding: 0 2em;
  float: left;
}

#view-thinklet .footer .footer-tab {
  cursor: pointer;
  height: 30px;
  padding-top: 5px;
}

#view-thinklet .footer span {
  margin-left: 5px;
}

#view-thinklet .footer .footer-tab.selected {
  background-color: rgb(38, 169, 224);
  border-right: none;
}

#view-thinklet .footer .selected a,
#view-thinklet .footer .selected span {
  color: white;
}

#morefrom {
  float: left;
  display: block;
}

#morefromThinklets {
  overflow: auto;
  white-space: nowrap;
  position: absolute;
  top: 0;
  bottom: 40px;
}

#morefromThinklets #gridview .thinklet-container,
#morefromThinklets #gridview thinklet {
  height: 175px;
  vertical-align: top;
}

#view-thinklet .footer span {
  color: rgb(38, 169, 224);
}

#view-thinklet .footer .footer-tab:hover {
  background-color: #BCBDC6;
}

#add-annotation:hover,
#view-thinklet .footer .footer-tab.selected:hover {
  background-color: #0D90C7;
}

.bubble {
  background-color: #CCCCCC;
  border-radius: 10px;
  padding: 10px;
}

#view-thinklet whiteboardplayer {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

#morefromThinklets {
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  overflow-y: hidden;
}

#morefromThinklets thinklet {
  height: 131px;
}

#morefromThinklets thinklet img {
  max-height: 95px;
}

#wondered, #noticed, #my-strategies, #my-plan {
  /*white-space: pre;*/
  word-wrap: break-word;
  /*white-space: pre-wrap;*/
}

#view-thinklet, #view-thinklet .main, #player-and-details, #view-thinklet annotationsoverlay, #view-thinklet annotationsoverlay > *, /*.viewThinklet .annotations, */#info .buttons {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: flex;
}

/*#view-thinklet {
  -webkit-flex-direction: row;
  flex-direction: row;
}*/

#player-and-details, #view-thinklet annotationsoverlay, #view-thinklet annotationsoverlay > * {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

#view-thinklet .main {
  position: relative;
}

/*#view-thinklet .main, #player-and-details {
  overflow: visible;
  background-color: #ffffff;
}

#player-and-details {
  height: 400px;
}

.viewThinklet .main {
  width: 80%;
  -webkit-flex-direction: column;
  flex-direction: column;
}*/

.td-view-header {
  z-index: 10;
  height: 60px;
  background-color: #ffffff;
}

.td-view-header h1 {
  cursor: pointer;
  color: #2aa6db;
  padding-left: 30px;
  font-size: 16px;
  display: inline-block;
}

.td-view-thinklet {
  height: 100%;
}

/*.viewThinklet .annotations {
  width: 300px;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: black;
}

.viewThinklet #add-annotation {
  height: 50px;
  font-size: 22px;
  background-color: rgb(38, 169, 224);
}

.viewThinklet #info {
  color: black;
  border-bottom: 1px solid #eeeeee;
}*/

#topic, #grade, #tags, #shared, #question .label/*, .viewThinklet .header a*/ {
  color: rgb(38, 169, 224);
}

#info .buttons button:hover {
  opacity: 0.5;
}

.label {
  padding: 0;
  color: black;
}

#more-info {
  width: 100%;
  font-size: 15px;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 30px;
}

#details, #more-info .user-thinklets {
  width: 100%;
}

#details {
  padding: 20px;
  overflow: hidden;
  position: relative;
}

#details .label {
  font-size: 20px;
  font-weight: 500;
  color: rgb(38, 169, 224);
}

#question img {
  float: right;
}

/*#view-thinklet .footer {
  height: 150px;
  background-color: #ffffff;
  font-size: 14px;
  z-index: 1000;
  line-height: 37px;
}*/

#view-thinklet .footer a {
  text-decoration: none;
  color: black;
}

/*.viewThinklet .footer {
  margin: 0 24px 24px 24px;
}

#view-thinklet .footer .footer-tab {
  width: 20%;
  height: 34px;
  padding-top: 1em;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  float: left;
  cursor: pointer;

}

#view-thinklet .footer div h2 {
  height: 34px;
  font-size: 16px;
}

#view-thinklet .footer .footer-tab.active {
  color: rgb(38, 169, 224);
  border-bottom: 0;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

#view-thinklet .footer .student-work {
  width: 100%;
  text-align: left;
  height: 100px;
  overflow: auto;
  border-top: 0;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}*/

#view-thinklet .footer .selected {
  color: white;
}

#morefrom {
  float: left;
  display: block;
}

#view-thinklet .footer span {
  color: rgb(38, 169, 224);
}

#view-thinklet .footer .footer-tab.teacher-view:hover {
  background-color: transparent;
}

.check-image {
  margin-right: 8px;
}

.thinklets {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.thinklets .loading-text {
  width: 100%;
  font-size: 20px;
  text-align: center;
}

.ellipsis {
  width: 4px;
  height: 4px;
  margin-left: 2px;
  background: #af2c90;
  border-radius: 50px;
  display: inline-block;
}

button.back {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #2aa6db;
  font-size: 16px;
  z-index: 101;
  background-color: transparent;
}

#open-rubric {
  background-color: rgb(178, 34, 151);
}

#view-thinklet .date, #topic, #grade, #tags, #date, #shared, #question .label {
  color: rgb(38, 169, 224);
}

/*.viewThinklet #info {
  position: relative;
  font-size: 17px;
  padding: 30px;
  min-height: 75px;
  background-color: #ffffff;
}

.viewThinklet #info .title {
  font-size: 20px;
  font-weight: 700;
}

.viewThinklet #info .user_name, .viewThinklet #info .date {
  color: black;
  display: inline;
}*/

#info > p {
  position: relative;
}

/*.viewThinklet #info .problem_question {
  font-size: 13px;
  padding-top: 15px;
}*/

#info {
  position: relative;
  background-color: black;
  padding: 14px 20px;
  min-height: 75px;
  font-size: 14px;
}

#info .title {
  font-size: 16px;
}

#info > p {
  position: relative;
  vertical-align: middle;
}

.subtitle {
  color: rgb(38, 169, 224);
}

.subtitle>* {
  margin-right: 10px;
}

#info .buttons {
  position: absolute;
  right: 20px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: rgb(38, 169, 224);
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

#info .buttons>* {
  margin-left: 30px;
}

#info .buttons button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -99em;
  overflow: hidden;
}

#info .buttons>div p {
  text-align: center;
}

#views, #likes {
  padding-left: 15px;
  background-repeat: no-repeat;
  background-position: left center;
}

#views {
  background-image: url(/images/View_icon.png);
  background-size: 13px 8px;
}

  .ef #views {
    background-image: url(/images/Grey_EyeIcon.svg) !important;
  }

#likes {
  background-image: url(/images/Like_Icon_unselected.png);
  background-size: 10px 8px;
}

  .ef .viewThinklet #likes {
    background-image: url(/images/HeartIcon.svg);
  }

#info .buttons button {
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -99em;
  overflow: hidden;
}

#flag, #like {
  background-position: center center;
  background-repeat: no-repeat;
}

#flag {
  background-image: url(/images/Flag_icon.png);
  width: 20px;
  height: 18px;
  background-size: 20px 18px;
}

  #flag.selected {
    background-image: url(/images/FLag_Icon_Selected.png);
  }

  .ef .viewThinklet #flag.selected {
    background-image: url(/images/FlagIcon.svg);
  }

  .ef .viewThinklet #flag {
    background-image: url(/images/FlagIcon_Unselected.svg);
  }

#like {
  background-image: url(/images/Like_Icon_unselected.png);
  width: 20px;
  height: 17px;
  background-size: 20px 17px;
}

  #like.selected {
    background-image: url(/images/Like_Icon_selected.png);
  }

  .ef .viewThinklet #like {
    background-image: url(/images/HeartIcon_Unselected.svg);
  }

  .ef .viewThinklet #like.selected {
    background-image: url(/images/HeartIcon.svg);
  }

#info .buttons button#tag {
  background-image: url(/images/Tag.png);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
}

  .ef .viewThinklet #info .buttons button#tag {
    background-image: url(/images/Tag_Icon.svg);
  }

.label {
  padding: 0;
  color: black;
}

.viewThinklet, .viewThinklet .main {
  position: relative;
}

.viewThinklet .whiteboardPlayer {
  position: absolute !important;;
  top: 0;
  bottom: 0;
  left: 0;
  right: 300px;
}

.viewThinklet .annotations {
  width: 300px;
  overflow: hidden;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: black;
}

.viewThinklet .annotations-body {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  position: relative;
}

.viewThinklet .annotations-body>* {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.viewThinklet .annotations .annotationContainer {
  width: 100%;
  overflow-x: hidden;
}

#add-tag {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  width: 270px;
  height: 184px;
  bottom: 0;
  right: 195px;
  border: 1px solid rgb(178, 34, 151);
  margin: 0 !important;
  color: black;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

#add-tag:before {
  content: "";
  position: absolute;
  top: -19px;
  width: 0;
  height: 0;
  border-width: 0 20px 20px 20px;
  border-style: solid;
  border-color: transparent transparent white transparent;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

#new-tag, #tags>div {
  height: 26px;
  min-height: 26px;
  margin-bottom: 4px;
}

#tags {
  overflow: auto;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

#add-tag input[type=checkbox] {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

#new-tag {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: flex;
}

#add-tag input[type=text] {
  border: 1px solid rgb(151, 151, 151);
  flex-grow: 1;
  -webkit-flex-grow: 1;
  margin-right: 10px;
  padding-left: 2px;
}

#submit-tag {
  width: 26px;
  height: 26px;
  border: 1px solid rgb(151, 151, 151);
  background-image: url(/images/Check.png);
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -99em;
  overflow: hidden;
  background-color: white;
}

#add-tag label {
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-weight: 500;
  vertical-align: middle;
}

#add-tag .delete {
  text-indent: -99em;
  overflow: hidden;
  background-color: transparent;
  background-image: url(/images/X.png);
  background-size: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: top left;
}

.viewThinklet .versions {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1049;
}

.viewThinklet .version {
  font-size: 12px;
  background: rgb(38, 169, 224);
  border: 1px solid #2aa6db;
  color: white;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 10px;
}

.viewThinklet .version.selected {
  color: rgb(38, 169, 224);
  background-color: white;
}

.teacher-tabs {
  padding: 8px;
  background-color: white;
}

.teacher-tabs button {
  display: block;
  width: 50px;
  height: 50px;
  background-color: rgb(38, 169, 224);
  margin-bottom: 8px;
}

.teacher-tabs button.active {
  background-color: gray;
}

.viewThinklet .delete-prompt {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 100;
}

.viewThinklet .delete-prompt .body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  padding: 40px 40px 84px 40px;
  background-color: white;
  text-align: center;
  width: 400px;
}

.viewThinklet .delete-prompt h1 {
  color: rgb(38, 169, 224);
  font-size: 24px;
  margin-top: 0;
}

.viewThinklet .delete-prompt p {
  font-size: 18px;
}

.viewThinklet .delete-prompt .buttons {
  height: 44px;
  color: white;
  display: flex;
  display: -webkit-flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.viewThinklet .delete-prompt button {
  padding: 10px;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  display: inline-block;
  font-size: 16px;
}

.viewThinklet .delete-prompt .submit {
  background-color: rgb(38, 169, 224);
}

.viewThinklet .delete-prompt .cancel {
  background-color: rgb(178, 34, 151);
}

.viewThinklet .question {
  max-height: 100px;
  overflow-y: auto;
}

.ef .viewThinklet {
  .annotations {
    background-color: #27292C;
    width: 325px;
  }

  .add-annotation-container {
    padding: 20px 20px 0 20px;
    text-transform: none;
  }

  #add-annotation {
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 2px;
  }

  #info {
    background-color: #E0F6FF !important;
    min-height: 60px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    .title {
      color: #363845 !important;
      font-weight: 700;
    }

    .subtitle {
      .user_name, .date, #views {
        color: #979797 !important;
      }
    }
  }

  #details {
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;

    h2 {
      color: black !important;
      font-size: 14px !important;
    }

    .more-details {
      padding-left: 20px;
      max-height: 100%;
      overflow-y: auto;

      label {
        font-size: 14px;
      }
    }
  }

  .question {
    font-size: 14px !important;
  }

  .ellipsis {
    background: rgb(38, 169, 224) !important;
  }

  .teacher-tabs>button {
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    background-size: 30px 30px;
    opacity: .5;

    &.active {
      opacity: 1;
    }

    i {
      display: none;
    }

    &.annotations-button {
      background-image: url(/images/Comments_Icon.svg);
    }

    &.rubric-button {
      background-image: url(/images/Rubric.svg);
    }

    &.labels-button {
      background-image: url(/images/Tag_Icon.svg);
    }
  }

  #more-info {
    bottom: 40px;

    .label {
      text-align: left;
      font-weight: 700;
    }
  }

  .footer {
    height: 40px;
    background-color: #E0F6FF;
    left: 0;
    right: 0;

    .footer-tab {
      height: 100% !important;
      background-color: #E0F6FF;
      line-height: 30px;

      &.selected {
        background-color: rgb(38, 169, 224);
      }
    }
  }

  .bottomContent .more-details p {
    margin-left: 0;
    margin-top: 12px;
  }
}

.more-details .columns {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.viewThinklet .thinklet-card {
  display: flex;
  flex-direction: column;
  height: auto;

  img {
    flex-grow: 1;
    min-height: 1px;
  }
}
</style>
