import module from '../login.module';
import '../loginDirective';
import './forgotpassword.css';
import tpl from './forgotUserNameDirective.tpl.html';
import _ from 'underscore';
import 'angular-translate';

module.directive('forgotUserName', function ($translate) {
    var linkFunction = function ($scope) {
		$scope.forgotUserName = function () {
			if ($scope.email) {
				$scope.submit({ email: $scope.email }).then(function (result) {
					alert($translate.instant(result));
				});
			} else {
				_.forOwn($scope.formData.resetUsernameForm.$error, function(value, key) {
					_.each(value, function (element) {
						element.$setDirty();
					});
				});
			}
		};
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: {
        	name: '=',
        	logoPath: '@',
        	submit: '&',
        	cancel: '&',
					forgotPassword: '&'
        }
    };
});
