<template>
  <b-dropdown
    class="checkbox-dropdown"
    :text="title">
    <b-dropdown-text
      v-for="item in items"
      @click.stop="onItemClick">
      <label>
        <input
          type="checkbox"
          :checked="isSelected(item)"
          @change="onCheckboxChange(item)"
        />

        {{ getLabel(item) }}
      </label>
    </b-dropdown-text>
  </b-dropdown>
</template>

<script>
import 'bootstrap-vue';

export default {
  props: {
    items: Array,
    label: [Function, String],
    title: String,
    value: Array
  },
  methods: {
    getLabel(item) {
      var formatter;

      if (typeof this.label === 'string') {
        formatter = item => item[this.label];
      } else if (typeof this.label === 'function') {
        formatter = this.label;
      } else {
        formatter = () => item;
      }

      return formatter(item);
    },

    isSelected(item) {
      return this.value.indexOf(item) !== -1;
    },

    onCheckboxChange(item) {
      var selectedItems;

      selectedItems = this.value.slice(0);

      if (this.isSelected(item)) {
        selectedItems.splice(this.value.indexOf(item), 1);
      } else {
        selectedItems.push(item);
      }

      this.$emit('input', selectedItems);
    },

    onItemClick() {
    }
  }
}
</script>

<style>
  .checkbox-dropdown .dropdown-menu {
    min-width: 300px;
  }
</style>