import './chooseColorThemeDirective.css';
import tpl from './chooseColorThemeDirective.tpl.html';

export default function ChooseColorThemeDirective() {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        colorTheme: $scope.colorTheme
      };

      $scope.onSubmitButtonClick = function () {
        $scope.onSubmit({ colorTheme: $scope.viewModel.colorTheme });
      };

      $scope.onColorThemeClick = function (colorTheme) {
        $scope.viewModel.colorTheme = colorTheme.name;
      };
    },
    restrict: 'E',
    template: tpl,
    scope: {
      colorTheme: '=',
      colorThemes: '=',
      onClose: '&',
      onSubmit: '&'
    }
  }
}
