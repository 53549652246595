import config from 'config';
import { getShared as getSharedConfigStore } from 'core/common/stores/config.store';

var CueThinkConfig = config;

angular.module('CueThinkConfig', []).factory('CueThinkConfig', function () {
  return CueThinkConfig;
});

getSharedConfigStore().commit('SET_CONFIG', config);

export default CueThinkConfig;
