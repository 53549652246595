export function waitUntil(until) {
  return new Promise(resolve => {
    let result = until();

    if (!result) {
      let intervalId;

      intervalId = setInterval(() => {
        result = until();

        if (result) {
          clearInterval(intervalId);

          resolve(result);
        }
      }, 100);

      return;
    }

    resolve(result);
  });
}
