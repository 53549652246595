import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'src/components/shared/media';
import 'src/components/shared/thinklet_data';
import 'src/app/navigation/teachernavbarController';
import 'config';
import tpl from './manage/manageContent.tpl.html';
import ContentHeaderController from './manage/header/contentHeaderController';
import manageContentController from './manage/manageContentController';
import './shareProblem/shareProblemDirective';
import 'angular-ui-bootstrap';
import { registerCreateProblemPopUpDirective } from './manage/createProblemPopUpDirective';
import 'angular-translate';

var contentModule = angular.module('cuethinkApp.content', ['cuethinkApp.teacherNavController', 'thinkletManager', 'teacherManager', 'userManager', 'mediaManager', 'CueThinkConfig', 'textAngular', 'textAngularSetup', 'cuethink.teacherscreen', 'cuethinkApp.ContentAssignmentServices', 'flow', 'cuethinkApp.shareProblem', 'ui.bootstrap', 'pascalprecht.translate']);

contentModule.config(function ($stateProvider) {
    $stateProvider
        .state('create-new-problem', {
            url: '/create-new-problem?id&clone&edit&referer&standardType&returnToAssign',
            template: tpl,
            controller: manageContentController
        });
});

contentModule.controller('ContentHeaderController', ContentHeaderController);
registerCreateProblemPopUpDirective(contentModule);
