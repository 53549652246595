import AngularVueComponentDirective from '../common/directives/angularVueComponent.directive';

const ViewThinkletDirective = new AngularVueComponentDirective(require('./view-thinklet').default, [
  'defaultSentenceStarters',
  'goBack',
  'isCueTeachEnabled',
  'isEducatorMode',
  'isFooterEnabled',
  'isMoreDetailsVisible',
  'isPrivateAnnotationsEnabled',
  'isStudentRubricEnabled',
  'sampleThinklet',
  'teacherManager',
  'thinkletId',
  'thinkletManager',
  'user',
  'userManager'
], ['back', 'view-thinklet', 'update:playbackState']);

export default ViewThinkletDirective;

export function bootstrapDirective(module) {
  module.directive('viewThinkletDirective', ViewThinkletDirective);
}
