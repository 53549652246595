import tpl from './foldersDirective.tpl.html';
import _ from 'underscore';
import { setUpFolderDirective } from './folderDirective';

export default function FoldersDirective () {
  "ngInject";

  var link = function ($scope) {
    var folderManager = new FolderManager($scope.rootFolder);

    $scope.openedFolder = null;

    $scope.$watch('rootFolder', function (value) {
      if (value)
        folderManager.setRoot(value);
    });

    $scope.showCreateFolderPrompt = function () {
      if ($scope.addFolder)
        $scope.addFolder($scope.createFolder);
    };

    $scope.onFolderSelect = function (folder) {
      if (folder === $scope.openedFolder)
        folder = null;

      $scope.openedFolder = folder;
      
      if ($scope.selectFolder)
        $scope.selectFolder(folder);
    };

    $scope.createFolder = function (name) {
      var folder = folderManager.createFolder(name, $scope.openedFolder);

      $scope.onFolderSelect(folder);

      return folderManager.getRootFolder();
    };

    $scope.renameFolder = function (name) {
      $scope.openedFolder.name = name;
    };

    $scope.deleteFolder = function () {
      var result = folderManager.deleteFolder($scope.openedFolder, $scope.openedFolder);

      if (result)
        $scope.onFolderSelect();
    };

    $scope.onFolderDrop = function (folder, problem) {
      if (folderManager.addFile(problem.id, folder))
        if ($scope.change)
          $scope.change();
    };

    $scope.removeFile = function (file) {
      var i = $scope.openedFolder.files.indexOf(file);

      if (i === -1)
        return false;

      $scope.openedFolder.files.splice(i, 1);

      return true;
    };
  };

  return {
    restrict: 'E',
    replace: true,
    template: tpl,
    link: link,
    scope: {
      rootFolder: '=',
      selectFolder: '=select',
      addFolder: '=add',
      createFolder: '=create',
      renameFolder: '=rename',
      deleteFolder: '=delete',
      change: '=',
      openedFolder: '=',
      removeFile: '='
    }
  };
}

export function setUpFoldersDirective (module) {
  module.directive('folders', FoldersDirective);

  setUpFolderDirective(module);
}

function Folder (name) {
  this.name = name;
  this.folders = [];
  this.files = [];
}

function FolderManager (structure) {
  var me = this;
  var rootFolder = structure || new Folder();

  this.getRootFolder = function () {
    return rootFolder;
  };

  this.setRoot = function (root) {
    rootFolder = root;
  };

  this.createFolder = function (name, parentFolder) {
    if (!parentFolder)
      parentFolder = rootFolder;

      var folder = new Folder(name);

    parentFolder.folders.push(folder);

    return folder;
  };

  function getParentFolder (folder, root) {
    if (!root)
      root = rootFolder;

    for (var i = 0; i < root.folders.length; i++) {
      var child = root.folders[i];

      if (child === folder)
        return root;

      var result = getParentFolder(folder, child);

      if (result)
        return result;
    }

    return null;
  }

  this.deleteFolder = function (folder) {
    var fromFolder = getParentFolder(folder);

    if (!fromFolder)
      return false;

    fromFolder.folders.splice(fromFolder.folders.indexOf(folder), 1);

    return true;
  };

  this.reorderFolder = function (from, to, before) {
    var fromIndex, fromParent, toIndex, toParent;

    _.each(rootFolder.folders, function (folder, i) {
      if (folder === from) {
        fromIndex = i;
        fromParent = rootFolder;

        return false;
      } else {
        fromIndex = _.indexOf(folder.folders, from);

        if (fromIndex != -1) {
          fromParent = folder;

          return false;
        }
      }
    });

    _.each(rootFolder.folders, function (folder, i) {
      if (folder === to) {
        toIndex = i;
        toParent = rootFolder;

        return false;
      } else {
        toIndex = _.indexOf(folder.folders, to);

        if (toIndex != -1) {
          toParent = folder;

          return false;
        }
      }
    });

    if (!before)
      toIndex++;

    if (fromParent == toParent && fromIndex < toIndex)
      toIndex--;

    if (fromParent == toParent && fromIndex == toIndex)
      return false;

    fromParent.folders.splice(fromIndex, 1);

    toParent.folders.splice(toIndex, 0, from);

    return true;
  };

  this.moveFolder = function (folder, to) {
    var current = me.getCurrentFolder();
    current.folders.splice(current.indexOf(folder), 1);
    to.folders.push(folder);
  };

  this.addFile = function (file, folder) {
    if (folder.files.indexOf(file) != -1)
      return false;

    folder.files.push(file);

    return true;
  };
}
