import NotificationCenter from '../notifications/notification-center';
import RouteManager, { ROUTE_NOTIFICATION } from '../common/route-manager';

export default function LogManager () {
  const _logHandlers = [];
  let state;

  this.addLogHandler = handler => {
    _logHandlers.push(handler);
  };

  this.getState = () => {
    if (state)
      return state;
    else if (RouteManager.getSharedInstance().getCurrentRoute())
      return RouteManager.getSharedInstance().getCurrentRoute().name;
    else
      return null;
  };

  this.logBehaviorEvent = (name, data, object, state) => {
    _logHandlers.forEach(handler => {
      handler.logBehaviorEvent(name, state ? state : this.getState(), data, object);
    });
  };

  this.logPageEvent = (name, state, path, previousPageUrl, timeOnPage, thinkletId, problemTitle) => {
    _logHandlers.forEach(handler => {
      handler.logPageEvent(name, state, path, previousPageUrl, timeOnPage, thinkletId, problemTitle);
    });
  };

  this.logThinkletEvent = (name, thinklet, data, state) => {
    _logHandlers.forEach(handler => {
      handler.logThinkletEvent(name, state ? state : this.getState(), thinklet.problem.id, thinklet.problem.title, thinklet.id, data);
    });
  };

  NotificationCenter.getShared().addListener(ROUTE_NOTIFICATION, route => {
    state = route.name;
  });
}

LogManager.sharedInstance = null;

LogManager.getShared = () => {
  if (!LogManager.sharedInstance)
    LogManager.sharedInstance = new LogManager();

  return LogManager.sharedInstance;
};
