import './createProblemPopUp.css';
import tpl from './createProblemPopUp.tpl.html';
import { waitUntil } from 'core/common/util';
import 'core/common/cuethinkPopUpDirective';

var MathJax;

export default function CreateProblemPopUpDirective () {
  "ngInject";

  var link = function ($scope, element) {
    $scope.close = function () {
      $scope.isVisible = false;
    };

    $scope.$$postDigest(function () {
      var questionEl = $(element).find('.question');
      var answerEl = $(element).find('.answer');

      if (questionEl.hide)
        questionEl.hide();

      if (answerEl.hide)
        answerEl.hide();

      $scope.$$postDigest(() => {
        waitUntil(() => typeof MathJax !== 'undefined').then(() => {
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, questionEl[0]]);
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, answerEl[0]]);
          MathJax.Hub.Queue(() => {
            if (questionEl.show)
              questionEl.show();

            if (answerEl.show)
              answerEl.show();
          });
        });
      });
    });
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: {
      isVisible: '=',
      title: '=',
      standards: '=',
      question: '=',
      answer: '=',
      imageUrl: '='
    }
  };
}

export function registerCreateProblemPopUpDirective (module) {
  module.directive('createProblemPopUp', CreateProblemPopUpDirective)
  .filter('unsafe', function ($sce) {
    return function (val) {
      return $sce.trustAsHtml(val);
    };
  });
}
