import 'core/navigation/navbar';
import 'core/settings/classCodesDirective';
import 'src/components/shared/base';
import './classCodes.css';
import 'src/components/shared/user_data';

export default function ClassCodesController($scope, $controller, UserManager, $translate, $q) {
  $controller('BaseController', {'$scope': $scope});

  function reloadClasses() {
    if ($scope.user.classes)
      $scope.classes = $scope.user.classes.slice(0);
    else
      $scope.classes = [];
  }

  reloadClasses();

  function saveClasses (callback) {
    var promises = [];
    const result = {};

    if ($scope.classes) {
      for (var i = 0; i < $scope.classes.length; i++) {
        var cls = $scope.classes[i];

        if (cls.id) {
          if (!cls.invitationCode)
            promises.push(UserManager.leaveClass(cls));
        } else if (cls.invitationCode) {
          var promise = UserManager.joinClass(cls.invitationCode)
            .then(() => {
              result[cls.invitationCode] = true;
            })
            .catch(() => {
              $scope.classes.splice($scope.classes.indexOf(cls), 1);

              result[cls.invitationCode] = false;

              digest();
            });

          if (callback)
            callback(promise);

          promises.push(promise);
        }
      }
    }

    $scope.user.classes.forEach(function (cls) {
      var found = $scope.classes.find(function (c) {
        return cls.invitationCode === c.invitationCode;
      });

      if (!found)
        promises.push(UserManager.leaveClass(cls));
    });

    return $q.all(promises).then(() => result);
  }

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  $scope.saveSettings = function () {
    if ($scope.isSaving)
      return;

    $scope.isSaving = true;

    var isAddingClass;

    saveClasses(function () {
      isAddingClass = true;
    }).then(function (result) {
      const success = Object.values(result).findIndex(item => !item) === -1;

      if (!success)
        return; // add class failed

      if ($scope.classes && !(isAddingClass && $scope.user.type === 'Student')) {
        reloadClasses();

        digest();
      }

      $scope.$$postDigest(() => {
        setTimeout(() => {
          alert($translate.instant('SETTINGS_SUCCESSFULLY_CHANGED'));

          if ($scope.user.type === "Student") {
            $scope.navigateDashboard();

            return;
          }
        }, 500);
      });
    }).finally(() => {
      $scope.isSaving = false;
    });
  };

  $scope.userManager = UserManager;
}
