import 'core/roster/classes/rosterClassesDirective';
import 'src/components/shared/teacher_data';
import 'src/components/shared/thinklet_data';
import 'src/app/navigation/teachernavbarController';
import 'src/components/shared/thinkletloader';
import Tutorial from 'core/tutorial/tutorial';
import _ from 'underscore';
import 'core/helper/helper.directive';
import { setUpHelper } from '../../../components/shared/set-up-helper';
import { Permissions } from 'core/permissions/permissions';
import LogManager from 'core/logging/log-manager'
import RouteManager from 'core/common/route-manager'

let _thinkletsOpened = 0;
let _seenHelper;

function RosterClassesController ($scope, $state, $stateParams, ThinkletManager, TeacherManager, $controller, $q, TutorialService, CueThinkConfig, $translate, PermissionsManager) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });

    $scope.loadStudents = TeacherManager.loadStudents;
    $scope.loadClasses = TeacherManager.loadClasses;

    $scope.loadStudentsThinklets = function (startDate, classIds, userIds, assignmentIds, labels, completedOnly, sort, sortAscending, start, limit, callback) {
        return ThinkletManager.loadStudentsThinklets(startDate, classIds, userIds, assignmentIds, labels, completedOnly, sort, sortAscending, start, limit, function (thinklets) {
            if (thinklets.length > 0) {
                for (var i = 0; i < thinklets.length; i++) {
                    var thinklet = thinklets[i];

                  if (thinklet.rubric) {
                      for (var j = 0; j < thinklet.rubric.responseList.length; j++) {
                          var response = thinklet.rubric.responseList[j];
                          var name = thinklet.rubric.responseList[j].name.toLowerCase();

                          if (name.indexOf('solution') >= 0 && name.indexOf('correct') >= 0) {
                              thinklet.rubric.correctSolutionResponse = response.optionId;

                              break;
                          }
                      }
                  }
               }
           }

            callback(thinklets);
        });
    };

    $scope.navigateStudent = function (student) {
        $state.go('student', { studentname: student.firstName + " " + student.lastName, id: student.id, classname: student.className, classId: student.classId });
    };

    $scope.classId = $stateParams.id;

    $scope.navigateAssignment = function (assignment, student) {
        $state.go('assignment-roster', { assignmentname: assignment.assignment_name, id: assignment.assignment_id, classname: student.className, classId: student.classId });
    };

    $scope.selectThinklet = thinklet => {
        _thinkletsOpened++;

        if (_thinkletsOpened === 6 && PermissionsManager.getPermission(Permissions.TeacherAnimatedHelper)) {
          const showHelper = setUpHelper($scope, 'MANAGING_WORK', 'TO_MANAGE_YOUR_TIME_FOCUS_ON_A_FEW_THINKLETS_HAVE_STUDENTS_ANNOTATE_THE_OTHER_THINKLETS_TO_LEARN_FROM_PEERS', true);

          showHelper();
        } else {
          LogManager.getShared().logPageEvent('view thinklet', RouteManager.getSharedInstance().getCurrentRoute().name, window.location.pathname, null, null, thinklet.id, thinklet.title);

          $state.go('view-teacherdash', { id: thinklet.id });
        }
    };

    if ($scope.user.cueteachEnabled) {
        $scope.saveCorrect = (thinklet, value) => {
            RosterClassesController.saveCorrect(thinklet, value, ThinkletManager);
        };
    }

    $scope.viewOverdueProblems = function () {
        $state.go('view-all-assignments');
    };

    $scope.loadAssignments = TeacherManager.loadOpenAssignments;
    $scope.tagsEnabled = $scope.user.cueteachEnabled;

    $scope.loadProblems = function () {
        var deferred = $q.defer();

        TeacherManager.loadCoteachingAssignments().then(function (assignments) {
            var problems = _.map(assignments, function (assignment) {
                return {
                    title: assignment.name,
                    assignmentId: assignment.id
                };
            });

            var assignmentMap = {};
            var result = [];

            _.each(problems, function (problem) {
                var ids = assignmentMap[problem.title];

                if (!ids)
                    assignmentMap[problem.title] = ids = [];

                if (ids.indexOf(problem.assignmentId) === -1)
                    ids.push(problem.assignmentId);

                if (!_.find(result, { title: problem.title }))
                    result.push(problem);
            });

            _.each(result, function (problem) {
                problem.assignmentIds = assignmentMap[problem.title];
            });

            deferred.resolve(result);
        }, function (result) {
            deferred.reject(result);
        });

        return deferred.promise;
    };

    $scope.loadLabels = TeacherManager.loadLabels.bind(null, true);
    $scope.isCueTeachEnabled = $scope.user.cueteachEnabled;

    $scope.fetchSeenTutorial = TeacherManager.fetchSeenToolbarTutorial;
    $scope.flagSeenTutorial = TeacherManager.flagSeenToolbarTutorial;

    $scope.startToolbarTutorial = function () {
        TutorialService.toolbarTutorial = new Tutorial($state, $translate);

        TutorialService.toolbarTutorial.start(CueThinkConfig.toolbarTutorialSteps);
    };

    if (!_seenHelper && (PermissionsManager.getPermission(Permissions.TeacherAnimatedHelper))) {
        _seenHelper = true;

        const showHelper = setUpHelper($scope, 'MONITOR', 'IDENTIFY_STUDENTS_TO_TOUCH_BASE_WITH_USING_THE_PROGRESS_AND_STATUS_COLUMNS_CLICK_HERE_TO_LEARN_MORE', true);

        showHelper();
    }
}

RosterClassesController.saveCorrect = function (thinklet, value, thinkletManager) {
    thinkletManager.saveRubricCorrect(thinklet, value);
};

export default RosterClassesController;
