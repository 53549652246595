import module from '../login.module';

module.directive('registrationField', function () {
  "ngInject";

  var linkFn = function ($scope) {
    $scope.isValid = function () {
      var enabled = $scope.isValidationEnabled;

      if (enabled === false)
        return true;

      return $scope.valid !== false;
    };
  };

  return {
    template: '<div class="registration-field"><label>{{label}} <span ng-if="!isValid() && validationMessage" class="validation-message">{{validationMessage}}</span></label><ng-transclude></ng-transclude></div>',
    transclude: true,
    replace: true,
    link: linkFn,
    scope: {
      label: '@',
      required: '=',
      validationMessage: '=',
      valid: '=',
      isValidationEnabled: '=validationEnabled'
    }
  };
});