import 'core/students/viewStudentsDirective';
import 'src/components/shared/teacher_data';
import 'src/app/navigation/teachernavbarController';

function ViewStudentsController ($scope, $controller, TeacherManager, UserManager, $state, $stateParams, CueThinkConfig) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });
    $scope.loadClasses = TeacherManager.loadMyClasses;
    $scope.stateParams = $stateParams;
    $scope.loadccssGrades = TeacherManager.loadccssGrades;
    $scope.createClass = TeacherManager.createClass;
    $scope.createStudent = TeacherManager.createStudent;
    $scope.editStudent = TeacherManager.editStudent;
    $scope.removeStudent = TeacherManager.removeStudentFromMyClasses;
    $scope.changeStudentPassword = TeacherManager.changeStudentPassword;
    $scope.joinExistingClass =  TeacherManager.coteachClass;
    $scope.archiveClass = TeacherManager.archiveClass;
    $scope.reactivateClass = TeacherManager.reactivateClass;
    $scope.deleteClass = TeacherManager.deleteClass;
    $scope.loadArchivedClasses = TeacherManager.loadArchivedClasses;
    $scope.allowAnnotations = TeacherManager.allowAnnotations;
    $scope.disallowAnnotations = TeacherManager.disallowAnnotations;
    $scope.expireStudentPassword = TeacherManager.resetStudentPassword;
    $scope.fetchJoinedClasses = TeacherManager.fetchJoinedClasses;
    $scope.rosterTemplateUrl = CueThinkConfig.rosterTemplateURL;
    $scope.rosterTemplateErrorUrl = CueThinkConfig.importRosterErrorURL;
    $scope.importRoster = TeacherManager.importRoster;
    $scope.leaveCoteachClass = TeacherManager.leaveCoteachClass;
    $scope.editClass = TeacherManager.editClass;
    $scope.fetchDontShowArchivePopUp = TeacherManager.fetchDontShowArchivePopUp;
    $scope.saveDontShowArchivePopUp = TeacherManager.saveDontShowArchivePopUp;
    $scope.fetchDontShowLeaveClassPopUpAgain = TeacherManager.fetchDontShowLeaveClassPopUpAgain;
    $scope.saveDontShowLeaveClassPopUpAgain = TeacherManager.saveDontShowLeaveClassPopUpAgain;
    $scope.registerGoogleClassroomStudents = TeacherManager.registerGoogleClassroomStudents;
}

export default ViewStudentsController;
