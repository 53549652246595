import googleManager from './google-manager';

function GoogleClassroom () {
  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  var SCOPES = ['https://www.googleapis.com/auth/classroom.courses.readonly', 'https://www.googleapis.com/auth/classroom.rosters.readonly', 'https://www.googleapis.com/auth/classroom.profile.emails'];

  // Array of API discovery doc URLs for APIs used by the quickstart
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest"];

  this.checkAuth = function (forceSelectAccount) {
    return googleManager.checkAuth(SCOPES, forceSelectAccount);
  };

  googleManager.isAvailable().then(function () {
    gapi.client.init({
      clientId: googleManager.getClientId(),
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES.join(' ')
    });
  });

  /**
   * Print the names of the first 10 courses the user has access to. If
   * no courses are found an appropriate message is printed.
   */
  this.listCourses = function (forceSelectAccount) {
    return this.checkAuth(forceSelectAccount).then(function () {
      return gapi.client.classroom.courses.list({
        pageSize: 0
      }).then(function (response) {
        return response.result.courses ? response.result.courses : [];
      });
    });
  };

  this.listStudents = function (courseId, forceSelectAccount) {
    return this.checkAuth(forceSelectAccount).then(function () {
      return gapi.client.classroom.courses.students.list({
        courseId: courseId,
        pageSize: 0
      }).then(function (response) {
        return response.result.students ? response.result.students : [];
      });
    });
  };

  this.getCourse = function (courseId, forceSelectAccount) {
    return this.checkAuth(forceSelectAccount).then(function () {
      return gapi.client.classroom.courses.get({
        id: courseId,
        pageSize: 0
      }).then(function (response) {
        return response.result;
      });
    });
  };
}

export default new GoogleClassroom();
