<template>
  <div class="avatar-container">
    <img
      v-if="isNewDesign && getAvatarUrl()"
      class="avatar"
      :alt="translate('AVATAR')"
      :src="getAvatarUrl()"
      :style="{ height: size + 'px', width: size + 'px' }"
    />
    <span
      v-if="isNewDesign && !getAvatarUrl()"
      class="avatar"
      :style="{ 'background-color': getDefaultAvatarColors().background, 'border-color': getDefaultAvatarColors().border, 'border-radius': (size / 2) + 'px', fontSize: fontSize + 'px', height: size + 'px', 'line-height': size + 'px', width: size + 'px' }">
      {{ (user.first_name || user.firstName).substr(0, 1) | uppercase }}<template v-if="!firstInitialOnly">{{ (user.last_name || user.lastName).substr(0, 1) | uppercase }}</template>
    </span>
  </div>
</template>

<script>
import translate from './filters/translate.filter';
import uppercase from './filters/uppercase.filter';

export function getAvatarUrl(user) {
  let url;
  const store = require('../common/stores/config.store').getShared();

  if (store.state.user.id === user.id)
    url = store.state.temporaryAvatarUrl;

  if (typeof url === 'undefined')
    url = user.avatarUrl;

  if (typeof url === 'undefined' && store.state.user.id === user.id)
    url = store.state.user.avatarUrl;

  return url;
}

export default {
  mixins: [require('./mixins/config.mixin').default, require('./mixins/user.mixin').default],

  filters: {
    translate,
    uppercase
  },

  props: {
    firstInitialOnly: {
      default: false,
      required: false,
      type: Boolean
    },

    fontSize: {
      default: 14,
      required: false,
      type: Number
    },

    size: {
      default: 50,
      required: false,
      type: Number
    }
  },

  computed: {
    defaultAvatarColors() {
      return this.config.defaultAvatarColors;
    },

    permissionUser() {
      return this.$store.state.user;
    }
  },

  methods: {
    getAvatarUrl() {
      return getAvatarUrl(this.user ? this.user : this.permissionUser);
    },

    getDefaultAvatarColors() {
      var i = (this.user ? this.user.id : 0) % (this.defaultAvatarColors.length - 1);

      return this.defaultAvatarColors[i];
    },

    translate(value) {
      return translate(value);
    }
  }
}
</script>

<style scoped>
  .avatar-container {
    display: inline-block;
    vertical-align: middle;
  }

  span.avatar {
    color: white;
    text-align: center;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    border-width: 1px;
    border-style: solid;
  }
</style>
