<template>
  <div class="cuethink-pop-up">
    <div
      class="body"
      :style="getBodyStyle()">
      <button
        v-if="showClose !== false"
        class="close"
        tabindex="0"
        @click="close">
        {{ 'CLOSE' | translate }}
      </button>

      <img
        v-if="icon"
        alt="Icon"
        class="icon"
        :src="icon"
      />

      <h1
        v-if="title"
        class="title"
        v-html="translatedTitle">
      </h1>

      <p
        v-if="message"
        class="message">
        {{ message | translate }}
      </p>

      <slot></slot>

      <div
        v-if="isCancelButtonVisible() || submitText"
        class="buttons">
        <button
          v-if="isCancelButtonVisible()"
          class="cancel"
          tabindex="0"
          @click="cancel">
          {{ cancelText | translate }}
        </button>

        <button
          v-if="submitText"
          class="submit"
          tabindex="0"
          :class="{ disabled: !submitEnabled }"
          @click="submit">
          {{ submitText | translate }}
        </button>
      </div>

      <label
        v-if="dontShowAgainKey && saveDontShowAgain"
        class="dont-show-again">
        <input
          type="checkbox"
          v-model="dontShowAgain"
        />

        {{ 'DON_T_SHOW_ME_THIS_AGAIN' | translate }}
      </label>
    </div>
  </div>
</template>

<script>
import translateMixin from './mixins/translate.mixin';
import Tutorial from '../tutorial/tutorial';
import './images/DownArrow.svg';
import './images/Close x.svg';

export default {
  mixins: [translateMixin],

  props: {
    bottom: {
      required: false,
      type: String
    },

    cancelText: {
      required: false,
      type: String
    },

    dontShowAgainChecked: {
      required: false,
      type: Boolean
    },

    dontShowAgainKey: {
      required: false,
      type: String
    },

    icon: {
      required: false,
      type: String
    },

    left: {
      required: false,
      type: String
    },

    message: {
      required: false,
      type: String
    },

    right: {
      required: false,
      type: String
    },

    saveDontShowAgain: {
      required: false,
      type: Function
    },

    showClose: {
      required: false,
      type: Boolean
    },

    spotlight: {
      required: false,
      type: String
    },

    submitEnabled: {
      default: true,
      required: false,
      type: Boolean
    },

    submitText: {
      required: false,
      type: String
    },

    title: {
      required: false,
      type: String
    },

    top: {
      required: false,
      type: String
    },

    validate: {
      required: false,
      type: Function
    }
  },

  data() {
    return {
      dontShowAgain: typeof this.dontShowAgainChecked === 'boolean' ? this.dontShowAgainChecked : true,
      spotlightElement: null
    };
  },

  computed: {
    translatedTitle() {
      return this.translate(this.title);
    }
  },

  created() {
    if (this.spotlight) {
      this.spotlightElement = Tutorial.spotlightElement(this.spotlight);
    }
  },

  methods: {
    cancel() {
      this.saveDontShow();

      this.$emit('cancel');
    },

    close(submitted) {
      if (this.spotlightElement) {
        this.spotlightElement.remove();
        this.spotlightElement = null;
      }

      this.saveDontShow();

      this.$emit('close', { submitted: submitted });
    },

    getBodyStyle() {
      return {
        bottom: this.bottom,
        left: this.left,
        right: this.right,
        top: this.top,
        transform: (this.bottom || this.left || this.right || this.top) ? 'none' : null,
        '-webkit-transform': (this.bottom || this.left || this.right || this.top) ? 'none' : null
      };
    },

    isCancelButtonVisible() {
      return typeof this.cancelText === 'string' && this.cancelText.length > 0;
    },

    saveDontShow() {
      if (this.saveDontShowAgain && this.viewModel.dontShowAgain && this.dontShowAgainKey)
        this.saveDontShowAgain(this.dontShowAgainKey);
    },

    submit() {
      if (this.validate && !this.validate())
        return;

      this.$emit('submit');

      this.close(true);
    }
  }
}
</script>

<style>
.cuethink-pop-up {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
}

body #main-view div.cuethink-pop-up div.body, body div.cuethink-pop-up .body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  padding: 45px 30px !important;
  background-color: white;
  text-align: center;
  border-radius: 4px !important;
  width: 300px;
  font-size: 13px;
  max-height: 100%;
  z-index: 1003;
}

.cuethink-pop-up button.close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: rgb(38, 169, 224);
  opacity: 1 !important;
  margin-top: 0 !important;
  margin-bottom: 0;
  padding: 0;
  font-size: 16px;
  background-image: url('/images/Close x.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: top left;
  text-indent: -9999em;
  width: 16px;
  height: 16px;
  overflow: hidden;
}

.cuethink-pop-up .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.cuethink-pop-up h1 {
  font-size: 15px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: black !important;
  text-align: center;
  line-height: 19px;
}

.cuethink-pop-up .message {
  margin-top: 10px !important;
}

.cuethink-pop-up label {
  text-align: left;
}

.cuethink-pop-up .dont-show-again {
  text-align: center;
}

.cuethink-pop-up p {
  margin: 15px 0 0 0 !important;
  color: black !important;
}

.cuethink-pop-up .buttons {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  margin-top: 10px;
}

.cuethink-pop-up .buttons button {
  padding: 10px;
  font-family: Museo Sans, sans-serif;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  border-radius: 2px;
  font-weight: 500;
  flex-basis: 0;
}

.cuethink-pop-up .cancel {
  border: 1px solid rgb(38, 169, 224);
  background-color: white;
  color: rgb(38, 169, 224);
  margin-right: 8px;
}

.cuethink-pop-up .cancel:hover {
  background-color: #F7F7F7;
}

.cuethink-pop-up .submit {
  background-color: rgb(38, 169, 224);
  color: white;
}

.cuethink-pop-up .submit:hover {
  background-color: #0692CE;
}

.cuethink-pop-up .dont-show-again {
  margin-top: 8px;
}

.cuethink-pop-up select {
  border: 1px solid #E7E6E6;
  color: black;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 30px;
  background-color: white;
  background-image: url(/images/DownArrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  text-align: left;
  font-family: "Museo Sans", sans-serif;;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 8px;
}

.cuethink-pop-up select.populated {
  border-color: #979797;
}

.cuethink-pop-up input[type=text], .cuethink-pop-up input[type=password] {
  border-radius: 2px;
  padding: 4px 10px;
  height: 32px !important;
  font-family: "Museo Sans", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #E7E6E6 !important;
  color: black !important;
  width: 100%;
  margin-bottom: 8px;
}

.cuethink-pop-up input[type=text]:focus, .cuethink-pop-up input[type=password]:focus {
  border-color: #979797;
}

.cuethink-pop-up .dropdown-toggle {
  width: 100%;
  overflow: hidden;
}

.cuethink-pop-up .disabled {
  opacity: .5;
  pointer-events: none;
}
</style>
