import module from './settings.module';
import './settingsDirective';
import tpl from './planPhaseSettingsDirective.tpl.html';
import 'core/common/textFieldListDirective';
import CueThinkSettingsDirective from './settingsDirective';
import { translate } from 'core/common/translation-helpers';
import permissionsManager, { Permissions } from '../permissions/permissions';

export default function PlanPhaseSettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    CueThinkSettingsDirective.bootstrapSettingsDirective($scope, $translate, $rootScope);

    $scope.viewModel = {
      isSentenceStartersEnabled: permissionsManager.getPermission(Permissions.SentenceStarterCustomization),
      sentenceStarters: null,
      strategies: null
    };

    var sentenceStarters = $scope.settings.sentenceStarters.map(function (s) {
      return s.text ? s.text : 'EMPTY_STRING';
    });

    const strategies = $scope.settings.strategies.map(function (s) {
      return s.text ? s.text : 'EMPTY_STRING';
    });

    translate(sentenceStarters, $translate, $rootScope, $scope, function (translations) {
      if ($scope.viewModel.sentenceStarters) {
        $scope.viewModel.sentenceStarters.forEach(function (starter) {
          var translation = translations[starter.translationId];

          if (translation)
            starter.text = translation;
        });
      } else {
        $scope.viewModel.sentenceStarters = Object.keys(translations).map(function (translationId) {
          var starter = $scope.settings.sentenceStarters.find(function (s) {
            return s.text === translationId || (!s.text && translationId === 'EMPTY_STRING');
          });

          return {
            enabled: starter.enabled,
            text: translations[translationId],
            translationId: translationId
          };
        });
      }
    });

    translate(strategies, $translate, $rootScope, $scope, function (translations) {
      if ($scope.viewModel.strategies) {
        $scope.viewModel.strategies.forEach(function (strategy) {
          var translation = translations[strategy.translationId];

          if (translation)
            strategy.text = translation;
        });
      } else {
        $scope.viewModel.strategies = Object.keys(translations).map(function (translationId) {
          var strategy = $scope.settings.strategies.find(function (s) {
            return s.text === translationId || (!s.text && translationId === 'EMPTY_STRING');
          });

          return {
            enabled: strategy.enabled,
            text: translations[translationId],
            translationId: translationId
          };
        });
      }
    });

    var oldSave = $scope.saveSettings;

    $scope.saveSettings = function () {
      $scope.settings.sentenceStarters = $scope.viewModel.sentenceStarters.map(function (starter) {
        var defaultStarter;

        if ($scope.defaultPlanSentenceStarters) {
          defaultStarter = $scope.defaultPlanSentenceStarters.find(function (d) {
            return $translate.instant(d) === starter.text;
          });
        }

        return {
          enabled: starter.enabled,
          text: defaultStarter ? defaultStarter : starter.text
        };
      });

      $scope.settings.strategies = $scope.viewModel.strategies.map(function (strategy) {
        var defaultStrategy;

        if ($scope.defaultPlanStrategies) {
          defaultStrategy = $scope.defaultPlanStrategies.find(function (d) {
            return $translate.instant(d) === strategy.text;
          });
        }

        return {
          enabled: strategy.enabled,
          text: defaultStrategy ? defaultStrategy : strategy.text
        };
      });

      oldSave();
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: CueThinkSettingsDirective.createSettingsScope({
      defaultPlanSentenceStarters: '=',
      defaultPlanStrategies: '='
    })
  };
}

module.directive('planPhaseSettings', PlanPhaseSettingsDirective);
