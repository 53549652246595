import tpl from './learningHubDirective.tpl.html';
import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import '../teacherscreen/teacherScreenDirective';
import './learningHub.css';
import LearningHubComponent from './learningHubComponent';
import { setUserModesScopeMembers } from '../common/user-modes';
import permissionsManager, { Permissions } from '../permissions/permissions';

function LearningHubDirective () {
  "ngInject";

  var linkFunction = function ($scope) {
    setUserModesScopeMembers($scope);

    $scope.isLearningHubV2Enabled = function () {
      return permissionsManager.getPermission(Permissions.LearningHubV2);
    };

    if ($scope.isNewDesign()) {
      $('#learning-hub-component').show();

      return;
    } else {
      $('#learning-hub-component').hide();
    }

    $scope.viewModel = {
      sections: [],
      selectedUnit: null,
      actionResponses: {},
      sortedActionResponses: null
    };

    if ($scope.trackEvent)
      $scope.trackEvent('Landing- Learning Hub');

    function isUnitDone (unit) {
      // select next unit if done
      if ($scope.isWatchVisible(unit) && !unit.status.watchChecked)
        return false;

      if ($scope.isTryVisible(unit) && !unit.status.tryChecked)
        return false;

      if ($scope.isViewVisible(unit) && !unit.status.viewChecked)
        return false;

      if ($scope.isReflectVisible(unit) && (!unit.status.reflectChecked || !unit.status.reflectResponse))
        return false;

      return true;
    }

    $scope.load().then(function (units) {
      var sections = {};

      units.forEach(function (unit) {
        var section = sections[unit.module.name];

        if (!section) {
          section = sections[unit.module.name] = { name: unit.module.name, units: [] };
          $scope.viewModel.sections.push(section);
        }

        section.units.push(unit);
      });

      var selectedUnit;

      var id = $scope.unitId;

      if (id) {
        selectedUnit = units.find(function (unit) { return unit.id == id; });
      } else {
        if (typeof localStorage !== 'undefined') {
          id = localStorage.getItem('learningHubLastSelectedUnitId');

          if (id) {
            selectedUnit = units.find(function (unit) { return unit.id == id; });

            while (selectedUnit && isUnitDone(selectedUnit)) {
              var section = sections[selectedUnit.module.name];

              var i = section.units.indexOf(selectedUnit);

              if (i < section.units.length - 1)
                selectedUnit = section.units[i + 1];
              else
                selectedUnit = null;
            }
          }
        }
      }

      if (!selectedUnit)
        selectedUnit = $scope.viewModel.sections[0].units[0];

      $scope.openUnit(selectedUnit);
    });

    function sortActionResponses (responses) {
      var otherIndex;

      responses.find(function (response, i) {
        if (response.name === 'Other:') { // TODO: localize
          otherIndex = i;

          return true;
        } else {
          return false;
        }
      });

      if (typeof otherIndex === 'undefined') {
        return responses;
      } else {
        var results = responses.slice();
        results.splice(otherIndex, 1);
        results.push(responses[otherIndex]);

        return results;
      }
    }

    $scope.openUnit = function (unit) {
      $scope.viewModel.selectedUnit = unit;

      $scope.viewModel.actionResponses = {};

      if (unit.status.actionResponseIds) {
        unit.status.actionResponseIds.forEach(function (id) {
          $scope.viewModel.actionResponses[id] = true;
        });
      }

      if (typeof localStorage !== 'undefined')
        localStorage.setItem('learningHubLastSelectedUnitId', unit.id);

      $scope.viewModel.sortedActionResponses = sortActionResponses($scope.viewModel.selectedUnit.actionResponses);

      if ($scope.onUnitChange)
        $scope.onUnitChange(unit);
    };

    $scope.onPlay = function (video) {
      if ($scope.trackEvent)
        $scope.trackEvent('Watched Learning Hub Video', { Unit: $scope.viewModel.selectedUnit.name, Video: video.name });
    };

    function digest () {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    $scope.viewResource = function (resource, $event) {
      if ($scope.trackEvent)
        $scope.trackEvent('Viewed Learning Hub Resource', { Unit: $scope.viewModel.selectedUnit.name, Resource: resource.name });

      window.open(resource.link, '_blank');

      $scope.viewModel.selectedUnit.status.viewChecked = true;

      $event.stopPropagation();
      $event.preventDefault();

      return false;
    };

    $scope.onTryResponseChange = function (response) {
      if ($scope.trackEvent)
        $scope.trackEvent('Tried in Learning Hub', { Unit: $scope.viewModel.selectedUnit.name, Value: response.name });

      var checked = false;

      $scope.viewModel.sortedActionResponses.forEach(function (response) {
        if ($scope.viewModel.actionResponses[response.id])
          checked = true;
      });

      if (checked) {
        $scope.viewModel.selectedUnit.status.tryChecked = true;

        digest();
      }

      $scope.onChange();
    };

    function markAsReflected () {
      if ($scope.trackEvent)
        $scope.trackEvent('Reflected in Learning Hub', { Unit: $scope.viewModel.selectedUnit.name, Reflection: $scope.viewModel.selectedUnit.status.reflectResponse });

      $scope.onChange();
    }

    $scope.onChange = function () {
      var selectedIds = [];

      $scope.viewModel.selectedUnit.actionResponses.forEach(function (response) {
        if ($scope.viewModel.actionResponses[response.id])
          selectedIds.push(response.id);
      });

      $scope.viewModel.selectedUnit.status.actionResponseIds = selectedIds;

      $scope.save($scope.viewModel.selectedUnit.status);
    };

    $scope.isYoutubeLink = function (url) {
      return url.indexOf('youtube') !== -1;
    };

    $scope.getYoutubeEmbedUrl = function (url) {
      var id = url.match(/v=([0-9a-zA-Z]*)/);

      if (!id)
        return null;

      id = id[1];

      return 'https://www.youtube.com/embed/' + id;
    };

    $scope.getVimeoEmbedUrl = function (url) {
      var id = url.match(/\/([0-9]+)/);

      if (!id)
        return null;

      id = id[1];

      return 'https://player.vimeo.com/video/' + id;
    };

    $scope.isVimeoLink = function (url) {
      return url.indexOf('vimeo') !== -1;
    };

    $scope.isWatchVisible = function (unit) {
      return unit.videos && unit.videos.length > 0;
    };

    $scope.isViewVisible = function (unit) {
      return unit.resources && unit.resources.length > 0;
    };

    $scope.isTryVisible = function (unit) {
      return unit.teacherActionPrompt && unit.actionResponses && unit.actionResponses.length > 0;
    };

    $scope.isReflectVisible = function (unit) {
      return unit.reflectionQuestionPrompt;
    };

    $scope.onReflectResponseInput = function () {
      if ($scope.viewModel.selectedUnit.status.reflectChecked)
        return;

      $scope.viewModel.selectedUnit.status.reflectChecked = true;

      markAsReflected();
    };
  };

  return {
      restrict: 'E',
      template: tpl,
      link: linkFunction,
      scope: TeacherScreenDirective.createScope({
          load: '=',
          save: '=',
          section: '=',
          trackEvent: '=',
          unitId: '=',
          onUnitChange: '='
      })
  };
}

function LearningHubCheckboxDirective () {
  "ngInject";

  var linkFunction = function ($scope) {
    $scope.trueValue = true;
    $scope.falseValue = false;

    $scope.isChecked = function () {
      return $scope.value === $scope.trueValue;
    };

    $scope.onClick = function () {
      if ($scope.isChecked())
        $scope.value = $scope.falseValue;
      else
        $scope.value = $scope.trueValue;

      $scope.$$postDigest(function () {
        $scope.onChange();
      });
    };
  };

  return {
      restrict: 'E',
      replace: true,
      template: '<button tabindex="0" class="learning-hub-checkbox" ng-click="onClick()" ng-class="{ checked: isChecked() }"><i class="fa" ng-class="{ \'fa-circle-o\': !isChecked(), \'fa-check-circle-o\': isChecked() }"></i></button>',
      link: linkFunction,
      scope: {
        value: '=',
        onChange: '&'
      }
  };
}

function OnPlayDirective () {
  "ngInject";

  var linkFunction = function ($scope, $element, attr) {
    $element[0].addEventListener('play', function () {
      $scope.onPlay(arguments);
    });
  };

  return {
      restrict: 'A',
      link: linkFunction,
      scope: {
        onPlay: '&',
      }
  };
}

angular.module('cuethink.learningHub', ['cuethink.teacherscreen'])
  .directive('learningHub', LearningHubDirective)
  .directive('learningHubCheckbox', LearningHubCheckboxDirective)
  .directive('learningHubComponent', LearningHubComponent)
  .directive('onPlay', OnPlayDirective)
	.filter("trustUrl", ['$sce', function ($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url);
        };
    }]);
