import navBarModule from './navbar.module';
import 'core/singleclick/singleClick';
import './navbar.css';
import tpl from './navbar.html';
import './quaidMenuDirective';
import languages from 'src/app/languages';
import { translate } from 'core/common/translation-helpers';
import './mindWanderingProbeDirective';
import 'src/images/AccountIcon.svg';
import 'src/images/GalleryIcon.svg';
import 'src/images/CueThinkIcon.svg';
import 'src/images/TableIcon_Dark.svg';
import 'src/images/CueThinkIcon_Dark.svg';
import 'src/images/GalleryIcon_Dark.svg';
import 'src/images/PlayIcon_Dark.svg';
import 'src/images/ProfileIcon_Dark.svg';
import EFTutorialDirective from '../popups/ef-tutorial.directive';
import EquityTutorialDirective from '../popups/equity-tutorial.directive';
import './images/play button.svg';
import 'src/components/shared/user_data';
import './images/EF_Guide_Arrow.svg';
import NotificationCenter from 'core/notifications/notification-center';
import { setUserModesScopeMembers } from '../common/user-modes';
import 'src/components/shared/cuethink';
import 'angular-ui-router';
import permissionsManager, { Permissions } from '../permissions/permissions';

var planMindWanderingProbeManager = new MindWanderingProbeManager(20 * 1000, false);
planMindWanderingProbeManager.setEnabled(true);

NotificationCenter.getShared().addListener('LoadingNewThinklet', function () {
	planMindWanderingProbeManager.stop();
});

function CueThinkNavBarController ($scope, $stateParams, $timeout, $translate, $rootScope, $state, UserManager, CueThink, PermissionsManager) {
	"ngInject";

	$scope.viewModel = {
		isEFTutorialVisible: false,
		isEquityTutorialVisible: false,
		isLanguageMenuOpen: false,
		isMindWanderingProbeCloseButtonVisible: false,
		isMindWanderingProbeInstructionsVisible: false,
		isMindWanderingProbeVisible: false,
		isPlanMindWanderingProbeVisible: false,
		isQuaidMenuVisible: false,
		isTrialEndPopUpVisible: false,
		isUserMenuOpen: false,
		language: null,
		languages: languages
	};

	function setPlanMindWanderingProbeManagerCallback() {
		planMindWanderingProbeManager.setCallback(function () {
			if ($state.current.name === 'solve')
				return false;

			$scope.viewModel.isPlanMindWanderingProbeVisible = true;

			planMindWanderingProbeManager.setEnabled(false);

			digest();
		});
	}

	if (permissionsManager.getPermission(Permissions.ReflectionProbe))
		setPlanMindWanderingProbeManagerCallback();

	function onUploadedRecording () {
		if (permissionsManager.getPermission(Permissions.ReflectionProbe)) {
			planMindWanderingProbeManager.setEnabled(true);

			planMindWanderingProbeManager.start();
		}
	}

	NotificationCenter.getShared().addListener('Uploaded Recording', onUploadedRecording);

	if (typeof localStorage !== 'undefined') {
		var lang = localStorage.getItem('language');

		if (!lang)
			lang = 'en';

		$scope.viewModel.language = $scope.viewModel.languages.find(function (l) {
			return l.id === lang;
		});
	}

	var user = $scope.user;
	$scope.stateParams = $stateParams;

	if ($scope.setUpNavigation)
		$scope.setUpNavigation();

	$scope.usernameNav = user.username;

	if (user.type !== "Student") {
		var userAgent = window.navigator.userAgent.toLowerCase();
		var ios = /ipad|iphone/.test(userAgent);
		var qtip;

		if (!ios) {
			translate('CLICK_HERE_TO_SWITCH_TO_EDUCATOR_MODE', $translate, $rootScope, $scope, function (msg) {
				qtip = $('#tutorial-button').qtip({
					content: msg,
					position: {
						at: 'bottom center',
						my: 'top right',
					},
					style: {
						classes: 'cuethink-qtip-style',
						def: false
					},
					hide: { when: { event: 'inactive' } }
				}).qtip('api');
			});
		}

		var trialWarning = null;

		if (typeof(Storage) !== 'undefined')
			trialWarning = localStorage.getItem('trialWarning');

		if (trialWarning !== null) {
			$scope.trialWarning = trialWarning;
		} else {
			var success = function (data) {
				if (data.trial === true) {
					if (data.daysleft === 'Unlimited') {
						$scope.trialWarning = "";
					} else if (data.daysleft === 'Expired') {
						$translate('YOUR_TRIAL_PERIOD_HAS_EXPIRED').then(function (msg) {
							$scope.trialWarning = msg;
						});
					} else {
						var daysLeft = parseInt(data.daysleft);

						if (daysLeft >= 0) {
							$translate('YOUR_TRIAL_PERIOD_WILL_END_IN_DAYS', { days: daysLeft }).then(function (msg) {
								$scope.trialWarning = msg;
							});
						}
					}
				} else {
					$scope.trialWarning = '';
				}

				if (typeof(Storage) !== 'undefined') {
					localStorage.removeItem('trialWarning');
					localStorage.setItem('trialWarning', $scope.trialWarning);
				}
			};

			var promise = $scope.getTrialDays(success);

			if (promise)
				promise.then(success);
		}
	}

	$scope.clickQuaid = function () {
		if (qtip)
			qtip.hide();

		$scope.viewModel.isQuaidMenuVisible = !$scope.viewModel.isQuaidMenuVisible;
	};

	if ($scope.tutorial) {
		$scope.startTutorial = function () {
			if (qtip)
				qtip.hide();

			$scope.viewModel.isQuaidMenuVisible = false;

			$scope.tutorial();
		};
	}

	setUserModesScopeMembers($scope);

	if (PermissionsManager.getPermission(Permissions.EFTour)) {
		$scope.startEFTutorial = function () {
			$scope.viewModel.isEFTutorialVisible = true;

			CueThink.logBehaviorEvent('Tutorial EF Tour', $state.current.name);
		};
	}

	if (PermissionsManager.getPermission(Permissions.EquityTour)) {
		$scope.startEquityTutorial = function () {
			$scope.viewModel.isEquityTutorialVisible = true;

			CueThink.logBehaviorEvent('Tutorial Equity Tour', $state.current.name);
		};
	}

	$scope.switchMode = function () {
		if ($scope.user.expired)
			showTrialEndPopUp();
		else if ($scope.navigateToTeacherDashboard)
			$scope.navigateToTeacherDashboard();
	};

	$scope.educatorModeEnabled = true;

	function addTooltip (el, text, position, pointer) { return; // TODO: remove
		if (!position)
			position = 'bottom center';

		if (!pointer)
			pointer = 'top left';

		el.qtip({
		   content: text,
		   position: {
				at: position,
				my: pointer
			},
		   style: {
				classes: 'nav-qtip',
				def: false
			},
			hide: { when: { event: 'inactive' } }
		});
	}

	$timeout(function () {
		translate(['HOME', 'PROFILE', 'GALLERY', 'RUBRIC OVERVIEW', 'SETTINGS', 'LOG_OUT'], $translate, $rootScope, $scope, function (translations) {
			addTooltip($('#dashboard-button'), translations.HOME);
			addTooltip($('#profile-button'), translations.PROFILE);
			addTooltip($('#browse-button'), translations.GALLERY);
			addTooltip($('#rubric-button'), translations['RUBRIC_OVERVIEW']);
			addTooltip($('#settings-button'), translations.SETTINGS, null, 'top right');
			addTooltip($('#log-out-button'), translations['LOG_OUT'], null, 'top right');
		});
	});

	function showTrialEndPopUp () {
		$scope.viewModel.isQuaidMenuVisible = false;
		$scope.viewModel.isTrialEndPopUpVisible = true;
	}

	$scope.tryToShowTrialEndPopUp = function () {
		$scope.fetchHasSeenTrialEndPopUp().then(function (result) {
			if (!result)
				showTrialEndPopUp();
		});
	};

	if ($scope.user.expired)
		$scope.tryToShowTrialEndPopUp();

	$scope.onTrialEndPopUpClose = function () {
		$scope.viewModel.isTrialEndPopUpVisible = false;
	};

	$scope.onTrialEndPopUpSubmit = function () {
		$scope.viewModel.isTrialEndPopUpVisible = false;

		$scope.setHasSeenTrialEndPopUp();
	};

	$scope.switchLanguage = function (language) {
		if ($scope.confirmLanguage && !$scope.confirmLanguage(language.id))
			return;

		$translate.use(language.id);

		$scope.viewModel.isLanguageMenuOpen = false;

		$('.language-menu').removeClass('open');
	};

	var unsubscribe = $rootScope.$on('$translateChangeSuccess', function (event, options) {
		if (typeof localStorage !== 'undefined')
			localStorage.setItem('language', options.language);

		$scope.viewModel.language = $scope.viewModel.languages.find(function (language) { return language.id === options.language });
	});

	$scope.$on("$destroy", function() {
		unsubscribe();

		NotificationCenter.getShared().removeListener('Uploaded Recording', onUploadedRecording);
	});

	function bindEvent (el, type, handler) {
		$(el).on(type, handler);

		$(el).on('remove', function () {
			$(el).off(type, handler);
		});
	}

	bindEvent($('.language-menu'), 'mouseleave', function () {
		$scope.viewModel.isLanguageMenuOpen = false;
	});

	bindEvent($('.user-menu'), 'mouseleave', function () {
		$scope.viewModel.isUserMenuOpen = false;
	});

	$scope.onLanguageMenuToggle = function () {
		if ($scope.viewModel.isLanguageMenuOpen)
			$scope.viewModel.isUserMenuOpen = false;
	};

	$scope.onUserMenuToggle = function () {
		if ($scope.viewModel.isUserMenuOpen)
			$scope.viewModel.isLanguageMenuOpen = false;
	};

	$scope.isSpanishEnabled = function () {
		if (typeof localStorage === 'undefined') {
			return false;
		} else {
			var lang = localStorage.getItem('language');

			return lang === 'es';
		}
	};

	function digest () {
		if ($scope.$$phase)
			return;

		try {
			$scope.$digest();
		} catch (e) {
		}
	}

	function setMindWanderingProbeCallback () {
		if (permissionsManager.getPermission(Permissions.ReflectionProbe)) {
			/*MindWanderingProbeManager.getShared().setCallback(function () {
				$scope.viewModel.isMindWanderingProbeInstructionsVisible = false;
				$scope.viewModel.isMindWanderingProbeCloseButtonVisible = false;
				$scope.viewModel.isMindWanderingProbeVisible = true;

				digest();
			});*/

			setPlanMindWanderingProbeManagerCallback();
		}
	}

	if ($scope.user && $scope.user.isEFAccount && $state.current.name !== 'solve')
		setMindWanderingProbeCallback();

	$scope.$on('$destroy', function () {
		// MindWanderingProbeManager.getShared().setCallback(null);
		planMindWanderingProbeManager.setCallback(null);
	});

	$scope.onCloseMindWanderingProbe = function () {
		$scope.viewModel.isMindWanderingProbeInstructionsVisible = false;
		$scope.viewModel.isMindWanderingProbeVisible = false;

		setMindWanderingProbeCallback();
		// MindWanderingProbeManager.getShared().start();

		if (planMindWanderingProbeManager.isEnabled)
			planMindWanderingProbeManager.start();
	};

	$scope.onSubmitMindWanderingProbePopUp = function ($event) {
		$scope.viewModel.isMindWanderingProbeVisible = false;

		$scope.onSubmitMindWanderingProbe($event);

		// MindWanderingProbeManager.getShared().start();

		if (planMindWanderingProbeManager.isEnabled)
			planMindWanderingProbeManager.start();
	};

	$scope.onClosePlanMindWanderingProbe = function () {
		$scope.viewModel.isPlanMindWanderingProbeVisible = false;

		setMindWanderingProbeCallback();
		// MindWanderingProbeManager.getShared().start();
	};

	$scope.onSubmitPlanMindWanderingProbePopUp = function ($event) {
		$scope.viewModel.isPlanMindWanderingProbeVisible = false;

		$scope.onSubmitMindWanderingProbe($event);

		// MindWanderingProbeManager.getShared().start();
	};

	$scope.onClickMindWanderingProbeInstructionsButton = function () {
		// MindWanderingProbeManager.getShared().setCallback(null);
		planMindWanderingProbeManager.setCallback(null);

		$scope.viewModel.isMindWanderingProbeInstructionsVisible = true;
		$scope.viewModel.isMindWanderingProbeVisible = true;
		$scope.viewModel.isMindWanderingProbeCloseButtonVisible = true;
	};

	$scope.onCloseEFTutorial = function () {
		$scope.viewModel.isEFTutorialVisible = false;
	};

	$scope.onCloseEquityTutorial = function () {
		$scope.viewModel.isEquityTutorialVisible = false;
	};

	$scope.onEFNavMenuButtonClick = function () {
		$scope.viewModel.isEFMenuVisible = true;

		setTimeout(function () {
			$('.ef-nav-menu').addClass('open');
		});
	};

	$scope.onEFNavMenuCloseButtonClick = function () {
		$scope.viewModel.isEFMenuVisible = false;

		$('.ef-nav-menu').removeClass('open');
	};

	$scope.getAvatarUrl = function () {
		var url = UserManager.getTemporaryAvatarUrl();

		if (typeof url === 'undefined')
			url = $scope.user.avatarUrl;

		return url;
	};

	$scope.getDefaultAvatarColors = function () {
		var i = $scope.user.id % ($scope.defaultAvatarColors.length - 1);

		return $scope.defaultAvatarColors[i];
	};

	$scope.isLanguageToggleVisible = function () {
		return PermissionsManager.getPermission(Permissions.Languages);
	};
}

navBarModule.directive('navbar', function () {
	return {
		restrict: 'E',
		transclude: true,
		template: tpl,
		controller: CueThinkNavBarController,
		scope: {
			confirmLanguage: '=',
			fetchHasSeenTrialEndPopUp: '=',
			getTrialDays: '=',
			dashboard: '=',
			dashboardState: '=',
			defaultAvatarColors: '=',
			gallery: '=',
			galleryState: '=',
			isCueteachEnabled: '=',
			logOut: '=logout',
			navigateJoinClass: '=',
			navigateRubric: '=',
			navigateSettings: '=settings',
			navigateToTeacherDashboard: '=teacherDashboard',
			onSubmitMindWanderingProbe: '&',
			profile: '=',
			profileState: '=',
			rubricState: '=',
			setHasSeenTrialEndPopUp: '=',
			setUpNavigation: '=setup',
			state: '=',
      tutorial: '=',
			user: '='
		}
	};
});

navBarModule.directive('efTutorial', EFTutorialDirective);
navBarModule.directive('equityTutorial', EquityTutorialDirective);

navBarModule.filter('propercase', function () {
	return function (str) {
		if (!str)
			return str;

		return str.substr(0, 1).toUpperCase() + (str.length > 1 ? str.substr(1).toLowerCase() : '');
	};
});

function MindWanderingProbeManager (interval, enabled) {
	var _callback;
	var mindWanderingProbeTimer;
	var _me = this;
	var _enabled;

	if (typeof enabled === 'undefined')
		enabled = true;

	_enabled = enabled;

	this.setCallback = function (callback) {
		_callback = callback;
	};

	this.isEnabled = function () {
		return _enabled;
	};

	this.setEnabled = function (val) {
		_enabled = val;
	};

	this.start = function () {
		if (!_enabled)
			return;

		if (!mindWanderingProbeTimer) {
			mindWanderingProbeTimer = setTimeout(function () {
				mindWanderingProbeTimer = null;

				if (_callback) {
					if (_callback() === false)
						_me.start();
				} else {
					_me.start();
				}
			}, interval);
		}
	};

	this.stop = function () {
		if (mindWanderingProbeTimer) {
			clearTimeout(mindWanderingProbeTimer);

			mindWanderingProbeTimer = null;
		}
	};

	if (_enabled)
		this.start();
}

MindWanderingProbeManager.getShared = function () {
	if (!MindWanderingProbeManager.sharedInstance) {
		MindWanderingProbeManager.sharedInstance = new MindWanderingProbeManager(10 * 60 * 1000);
	}

	return MindWanderingProbeManager.sharedInstance;
};

NotificationCenter.getShared().addListener('LogOut', function () {
	// MindWanderingProbeManager.getShared().stop();
});

NotificationCenter.getShared().addListener('LogIn', function () {
	// MindWanderingProbeManager.getShared().start();
});
