import helperStore from 'core/helper/helper.store';
import LogManager from 'core/logging/log-manager';
import NotificationCenter from 'core/notifications/notification-center';
import animatedHelperUrl from '../../images/teacher helper.png';
import stillHelperUrl from '../../images/teacher helper.png';
import 'src/images/ThumbDown_Outline.svg';
import 'src/images/ThumbsDown_Filled.svg';
import 'src/images/ThumbsUp_Outline.svg';
import 'src/images/ThumbUp_Filled.svg';

export function setUpHelper($scope, title, message, showButtons) {
  if (!$scope.viewModel)
    $scope.viewModel = {};

  $scope.viewModel.isHelperVisible = false;

  $scope.animatedHelperUrl = animatedHelperUrl;
  $scope.stillHelperUrl = stillHelperUrl;

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  function showHelper() {
    let buttons;

    if (showButtons) {
      buttons = {
        ['<span class="thumbs-up">Thumbs Up</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-down')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', {
            message: helperStore.state.message,
            response: true,
            title: helperStore.state.title
          });

          setTimeout(() => {
            $scope.viewModel.isHelperVisible = false;

            digest();
          }, 1000);
        },
        ['<span class="thumbs-down">Thumbs Down</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-up')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', {
            message: helperStore.state.message,
            response: false,
            title: helperStore.state.title
          });

          setTimeout(() => {
            $scope.viewModel.isHelperVisible = false;

            digest();
          }, 1000);
        }
      };
    } else {
      buttons = null;
    }

    helperStore.commit('SET_BUTTONS_TEXT', showButtons ? 'WAS_THIS_HELPFUL' : null);
    helperStore.commit('SET_BUTTONS', buttons);
    helperStore.commit('SET_MESSAGE', message);
    helperStore.commit('SET_TITLE', title);

    $scope.viewModel.isHelperVisible = true;
  }

  function closeHelper() {
    $scope.viewModel.isHelperVisible = false;

    digest();
  }

  $scope.$on('CloseHelper', () => {
    closeHelper();
  });

  const removeCloseHelperListener = NotificationCenter.getShared().addListener('CloseHelper', () => {
    closeHelper();
  });

  $scope.$on('$destroy', () => {
    removeCloseHelperListener();

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_TITLE', null);
    helperStore.commit('SET_MESSAGE', null);
  });

  return showHelper;
}
