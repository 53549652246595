import 'src/components/shared/teacher_data';
import './filterContent.css';
import tpl from './filterContent.tpl.html';
import 'core/common/cuethinkDropdownDirective';
import languages from 'src/app/languages';
import { translate } from 'core/common/translation-helpers';
var cloneDeep = require('lodash.clonedeep');
var find = require('lodash.find');
import 'src/images/ClosexBlue_2px.svg';

function ProblemFilterDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    var gradeList = [
      { id: 0, label: 'K', selected: false },
      { id: 1, label: '1', selected: false },
      { id: 2, label: '2', selected: false },
      { id: 3, label: '3', selected: false },
      { id: 4, label: '4', selected: false },
      { id: 5, label: '5', selected: false },
      { id: 6, label: '6', selected: false },
      { id: 7, label: '7', selected: false },
      { id: 8, label: '8', selected: false },
      { id: 9, label: 'HS', selected: false }
    ];

    $scope.viewModel = {
      gradeList: cloneDeep(gradeList),
      isGradeCombo: false,
      isLanguageOpen: false,
      languages: languages.map(function (lang) {
        var result = Object.assign({}, lang);
        result.selected = false;

        return result;
      })
    };

    var languageKeys = languages.map(function (language) {
      return language.name;
    });

    var languageTranslations;

    translate(languageKeys, $translate, $rootScope, $scope, function (translations) {
      languageTranslations = translations;
    });

    $scope.getSelectedLanguagesLabel = function () {
      return $scope.selectedLanguages.map(function (id) {
        var key = languages.find(function (lang) {
          return lang.id === id;
        }).name;

        return languageTranslations[key];
      }).join(', ');
    };

    function resetDomains() {
      fullDomainList = null;
      $scope.viewModel.domainList = null;
      $scope.selectedDomains.splice(0);
    }

    function resetStandards() {
      $scope.viewModel.standardList = null;
      $scope.selectedStandards.splice(0);
    }

    function isSelected(item) {
      return item.selected;
    }

    function getSelectedItems(items) {
      return items.filter(isSelected);
    }

    /* persist selections */

    function getLocalStorageItem(key, defaultValue) {
      if (localStorage === undefined)
        return undefined;

      var result = localStorage.getItem(key);

      if (result === null)
        result = defaultValue;
      else
        result = JSON.parse(result);

      return result;
    }

    function load() {
      if (localStorage === undefined || $scope.useSavedFilter === false)
        return;

      $scope.selectedLanguages = getLocalStorageItem('problemBank-selectedLanguages', []);
      $scope.selectedStandardTypes = getLocalStorageItem('problemBank-selectedStandardTypes', []);
      $scope.selectedGrades = getLocalStorageItem('problemBank-selectedGrades', []);
      $scope.selectedDomains = getLocalStorageItem('problemBank-selectedDomains', []);
      $scope.selectedStandards = getLocalStorageItem('problemBank-selectedStandards', []);
    }

    load();
    reloadDomains();
    reloadStandards();

    function save() {
      if (localStorage === undefined)
        return;

      localStorage.setItem('problemBank-selectedLanguages', JSON.stringify($scope.selectedLanguages));
      localStorage.setItem('problemBank-selectedStandardTypes', JSON.stringify($scope.selectedStandardTypes));
      localStorage.setItem('problemBank-selectedGrades', JSON.stringify($scope.selectedGrades));
      localStorage.setItem('problemBank-selectedDomains', JSON.stringify($scope.selectedDomains));
      localStorage.setItem('problemBank-selectedStandards', JSON.stringify($scope.selectedStandards));
    }

    function markItemsSelected(items, selected, matcher) {
      selected.forEach(function (selectedItem) {
        var func;

        if (matcher) {
          func = function (a) {
            return matcher(a, selectedItem);
          };
        } else {
          func = idMatches(selectedItem);
        }

        var found = items.find(func);

        if (found)
          found.selected = true;
      });
    }

    markItemsSelected($scope.viewModel.gradeList, $scope.selectedGrades);

    $scope.loadStandardTypes().then(function (types) {
      types.sort(function (a, b) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });

      if (!$scope.selectedStandardTypes || $scope.selectedStandardTypes.length === 0)
        $scope.selectedStandardTypes = [find(types, { state: 'CCK12' })];

      markItemsSelected(types, $scope.selectedStandardTypes);

      $scope.viewModel.standardTypes = types;

      if ($scope.onSetInitialStandardTypes)
        $scope.onSetInitialStandardTypes($scope.selectedStandardTypes);
    });

    $scope.onSelectGrade = function () {
      $scope.selectedGrades = getSelectedItems($scope.viewModel.gradeList);

      save();

      reloadDomains();
    };

    $scope.onSelectDomain = function () {
      var result = [];

      var selectedDomains = getSelectedItems($scope.viewModel.domainList);

      selectedDomains.forEach(function (domain) {
        var found = fullDomainList.filter(function (d) {
          return descriptionMatches(domain, d);
        });

        result = result.concat(found);
      });

      $scope.selectedDomains = result;

      save();

      reloadStandards();
    };

    $scope.onSelectStandard = function () {
      $scope.selectedStandards = getSelectedItems($scope.viewModel.standardList);

      save();
    };

    $scope.resetFilter = function () {
      $scope.searchTerm = '';
      $scope.selectedLanguages.splice(0);
      $scope.viewModel.gradeList = cloneDeep(gradeList);
      $scope.selectedGrades.splice(0);

      resetDomains();
      resetStandards();

      save();
    };

    $scope.removeFilter = function (filter, filterList) {
      if (filterList) {
        if (filterList === $scope.selectedLanguages) {
          $scope.viewModel.languages.find(function (language) {
            return language.id === filter;
          }).selected = false;

          filterList.splice(filterList.indexOf(filter), 1);
        } else {
          filter.selected = false;

          if (filterList === $scope.selectedStandardTypes) {
            $scope.selectedStandardTypes = $scope.selectedStandardTypes.filter(function (type) {
              return type.id !== filter.id;
            });

            resetDomains();
            resetStandards();
          } else if (filterList === $scope.selectedDomains) {
            for (var i = filterList.length - 1; i >= 0; i--) {
              var item = filterList[i];

              if (descriptionMatches(item, filter))
                filterList.splice(i, 1);
            }

            $scope.onSelectDomain();
          } else {
            filterList.splice(filterList.indexOf(filter), 1);

            if (filterList === $scope.selectedGrades)
              $scope.onSelectGrade();
          }
        }
      } else {
        $scope.searchTerm = null;
      }

      save();
    };

    $scope.getLabelForDomain = function (domain) {
      return domain.description;
    };

    $scope.getLabelForStandard = function (standard) {
      return standard.standard + ' - ' + standard.description;
    };

    $scope.getSelectedDomainLabels = function () {
      return getUniqueItemsByDescription($scope.selectedDomains);
    };

    function getSelectedGradeIds() {
      return $scope.selectedGrades.map(function (grade) {
        return grade.label;
      });
    }

    function getSelectedStandardTypeIds() {
      return $scope.selectedStandardTypes.map(function (type) {
        return type.state;
      });
    }

    function getSelectedDomainIds() {
      var result = [];

      $scope.selectedDomains.forEach(function (domain) {
        var found = result.find(function (d) {
          return d.standard === domain.standard;
        });

        if (!found)
          result.push(domain);
      });

      result = result.map(function (domain) {
        return domain.standard;
      });

      return result;
    }

    function idMatches(a) {
      return function (b) {
        return a.id === b.id;
      };
    }

    function filterMissingItems(target, source, matcher) {
      for (var i = target.length - 1; i >= 0; i--) {
        var func;
        var item = target[i];

        if (matcher) {
          func = function (a) {
            return matcher(a, item);
          };
        } else {
          func = idMatches(item);
        }

        var found = source.find(func);

        if (!found)
          target.splice(i, 1);
      }

      return target;
    }

    var fullDomainList;

    function descriptionMatches(a, b) {
      return a.description === b.description;
    }

    function getUniqueItemsByDescription(items) {
      var result = [];

      items.forEach(function (item) {
        var found = result.find(function (d) {
          return descriptionMatches(d, item);
        });

        if (!found)
          result.push(item);
      });

      return result;
    }

    function reloadDomains() {
      var grades = getSelectedGradeIds();

      if (grades.length === 0) {
        resetDomains();
        resetStandards();

        return;
      }

      var types = getSelectedStandardTypeIds();

      if (types.length === 0) {
        resetDomains();
        resetStandards();

        return;
      }

      $scope.loadDomains(grades, types).then(function (domains) {
        markItemsSelected(domains, $scope.selectedDomains, descriptionMatches);
        filterMissingItems($scope.selectedDomains, domains, descriptionMatches);

        fullDomainList = domains;

        $scope.viewModel.domainList = getUniqueItemsByDescription(domains);
      });

      reloadStandards();
    }

    function reloadStandards() {
      var grades = getSelectedGradeIds();

      if (grades.length === 0) {
        resetStandards();

        return;
      }

      var types = getSelectedStandardTypeIds();

      if (types.length === 0) {
        resetStandards();

        return;
      }

      var domains = getSelectedDomainIds();

      if (domains.length === 0) {
        resetStandards();

        return;
      }

      $scope.loadStandards(grades, types, domains).then(function (standards) {
        markItemsSelected(standards, $scope.selectedStandards);

        filterMissingItems($scope.selectedStandards, standards);

        $scope.viewModel.standardList = standards;
      });
    }

    $scope.isFiltering = function () {
      if ($scope.searchTerm ||
        $scope.selectedLanguages.length > 0 ||
        $scope.selectedGrades.length > 0 ||
        $scope.selectedDomains.length > 0 ||
        $scope.selectedStandards.length > 0 ||
        $scope.selectedStandardTypes.length > 0)
        return true;

      return false;
    };

    $scope.selectStandardType = function (type) {
      $scope.viewModel.isStandardTypeOpen = false;

      $scope.selectedStandardTypes = [type];

      save();

      reloadDomains();

      return true;
    };

    $scope.onDomainDropdownClick = function () {
      $('.domainDropdown').scrollTop(0);
    };

    var STANDARD_TYPE, GRADE, DOMAIN, PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_DOMAIN, AND, PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_STANDARD;

    translate(['STANDARD_TYPE', 'GRADE', 'DOMAIN', 'PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_DOMAIN', 'AND'], $translate, $rootScope, $scope, function (translations) {
      STANDARD_TYPE = translations['STANDARD_TYPE'].toLowerCase();
      GRADE = translations.GRADE.toLowerCase();
      DOMAIN = translations.DOMAIN.toLowerCase();
      PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_DOMAIN = translations['PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_DOMAIN'];
      PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_STANDARD = translations['PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_STANDARD'];
      AND = translations.AND;
    });

    $scope.getDomainErrorMessage = function () {
      var fields = [];

      if ($scope.selectedStandardTypes.length === 0)
        fields.push(STANDARD_TYPE);

      if ($scope.selectedGrades.length === 0)
        fields.push(GRADE);

      return PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_DOMAIN.replace('{0}', fields.join(' ' + AND + ' '));
    };

    $scope.getStandardsErrorMessage = function () {
      var fields = [];

      if ($scope.selectedStandardTypes.length === 0)
        fields.push(STANDARD_TYPE);

      if ($scope.selectedGrades.length === 0)
        fields.push(GRADE);

      if ($scope.selectedDomains.length === 0)
        fields.push(DOMAIN);

      var fieldString;

      if (fields.length >= 2) {
        fields[fields.length - 1] = ' ' + AND + ' ' + fields[fields.length - 1];

        if (fields.length > 2)
          fieldString = fields.join(', ');
        else
          fieldString = fields.join('');
      } else {
        fieldString = fields.join('');
      }

      return PLEASE_SELECT_A_IN_ORDER_TO_FILTER_BY_STANDARD.replace('{0}', fieldString);
    };

    $scope.isGradeFilterEnabled = function () {
      return $scope.selectedStandardTypes.length > 0;
    };

    $scope.isDomainFilterEnabled = function () {
      return $scope.isGradeFilterEnabled() && $scope.selectedGrades.length > 0;
    };

    $scope.isStandardFilterEnabled = function () {
      return $scope.isDomainFilterEnabled() && $scope.getSelectedDomainLabels().length > 0;
    };

    $scope.onStandardMenuToggle = function (event) {
      setTimeout(function () {
        $('.popover').remove();

        if ($scope.viewModel.isStandardOpen) {
          var el = $('.standard-filter-dropdown-menu');
          el.css('left', 'auto');
          el.css('right', '76px');
        }
      }, 100);
    };

    var SELECTED;

    translate('SELECTED', $translate, $rootScope, $scope, function (value) {
      SELECTED = value;
    });

    $scope.getSelectedGradesLabel = function () {
      return $scope.selectedGrades.length > 0 ? $scope.selectedGrades.length + ' ' + SELECTED : '';
    };

    $scope.getSelectedDomainsLabel = function () {
      return $scope.getSelectedDomainLabels().length > 0 ? $scope.getSelectedDomainLabels().length + ' ' + SELECTED : '';
    };

    $scope.getSelectedStandardsLabel = function () {
      return $scope.selectedStandards.length > 0 ? $scope.selectedStandards.length + ' ' + SELECTED : '';
    };

    $scope.onSelectLanguage = function () {
      $scope.selectedLanguages = getSelectedItems($scope.viewModel.languages).map(function (lang) {
        return lang.id;
      });

      save();
    };

    $scope.getLanguageKey = function (id) {
      var result = languages.find(function (language) {
        return language.id === id;
      });

      if (result)
        return result.name;
      else
        return null;
    };

    $scope.setGrades = function (grades) {
      var selected = [];

      $scope.viewModel.gradeList.forEach(function (grade) {
        if (grades.indexOf(grade.id) === -1) {
          grade.selected = false;
        } else {
          grade.selected = true;

          selected.push(grade);
        }
      });

      $scope.selectedGrades = selected;

      save();

      reloadDomains();
    };
  };

  return {
    restrict: 'E',
    replace: true,
    template: tpl,
    link: link,
    scope: {
      count: '=',
      loadDomains: '=',
      loadStandards: '=',
      loadStandardTypes: '=',
      onSetInitialStandardTypes: '=',
      searchTerm: '=',
      selectedDomains: '=',
      selectedGrades: '=',
      selectedLanguages: '=',
      selectedStandardTypes: '=',
      selectedStandards: '=',
      setGrades: '=',
      useSavedFilter: '='
    }
  };
}

export function setUpProblemFilterDirective (module) {
  module.directive('problemBankFilter', ProblemFilterDirective);
}

export default ProblemFilterDirective;
