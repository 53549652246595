import './rubric.css';
import tpl from './rubricStats.tpl.html';
import { translate } from 'core/common/translation-helpers';
import {setUserModesScopeMembers} from '../common/user-modes';

export default function RubricStatsDirective ($translate, $rootScope) {
    "ngInject";

    var linkFunction = function ($scope) {
        $scope.viewModel = {
            rubricLists: [
                {},
                {
                    name: 'PLAN',
                },
                {
                    name: 'SOLVE',
                },
                {
                    name: 'REVIEW'
                }
            ]
        };

        translate(['PLAN', 'SOLVE', 'REVIEW'], $translate, $rootScope, $scope, function (translations) {
            $scope.viewModel.rubricLists[1].name = translations.PLAN.toUpperCase();
            $scope.viewModel.rubricLists[2].name = translations.SOLVE.toUpperCase();
            $scope.viewModel.rubricLists[3].name = translations.REVIEW.toUpperCase();
        });

        $scope.$watch('rubric', function (val) {
            if (!val)
                return;

            $scope.viewModel.rubricLists[0].items = $scope.rubric.understandList;
            $scope.viewModel.rubricLists[1].items = $scope.rubric.planList;
            $scope.viewModel.rubricLists[2].items = $scope.rubric.solveList;
            $scope.viewModel.rubricLists[3].items = $scope.rubric.reviewList;
        });

        setUserModesScopeMembers($scope);
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: {
            rubric: '=',
            user: '='
        }
    };
};
