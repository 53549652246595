import 'src/components/shared/user_data';
import 'core/login/forgotpassword/forgotPasswordDirective';

function ForgotPasswordController ($scope, UserManager, CueThinkConfig, $state) {
	"ngInject";

	$scope.name = CueThinkConfig.name;

	$scope.resetPassword = function (name, callback, error) {
		return UserManager.resetPasswordByUsername({ username: name });
	};

	$scope.cancel = function () {
			$state.go('login');
	};

	$scope.forgotUserName = function () {
		$state.go('forgotusername');
	};
}

export default ForgotPasswordController;
