import 'core/common/cuethinkPopUpDirective';
import './images/play button blue.svg';
import 'src/images/LeftArrow_ScrapPaper.png';
import './mindWanderingProbe.css';
import tpl from './mindWanderingProbe.tpl.html';
import 'src/images/Icon_LeftArrow.svg';
import 'angular-translate';

export default function MindWanderingProbeDirective ($translate) {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        isVideoVisible: $scope.onlyShowInstructions,
        responses: [],
        videoWatched: false
      };

      if ($scope.mode === 'thinking') {
        $scope.viewModel.responses = [
          'SOMETHING_ELSE',
          'THIS_MATH_PROBLEM'
        ]
      } else if ($scope.mode === 'plan') {
        $scope.viewModel.responses = [
          'USED_ORIGINAL_PLAN',
          'THOUGHT_ABOUT_CHANGING_PLAN_BUT_DIDNT',
          'CHANGED_PLAN'
        ];
      }

      $scope.onClickWatchInstructions = function ($event) {
        $scope.viewModel.isVideoVisible = true;
        $scope.viewModel.videoWatched = true;

        $event.stopPropagation();
        $event.preventDefault();

        return true;
      };

      $scope.goBack = function () {
        $scope.viewModel.isVideoVisible = false;
      };

      $scope.onClickButton = function (answer) {
        $scope.onSubmit({ answer: $translate.instant(answer), videoWatched: $scope.viewModel.videoWatched });
      };

      $scope.getTitle = function () {
        if ($scope.viewModel.isVideoVisible)
          return 'POPUP_INSTRUCTIONS';
        else if ($scope.mode === 'thinking')
          return 'RIGHT_NOW_WHAT_ARE_YOU_THINKING_ABOUT';
        else if ($scope.mode === 'plan')
          return 'THINK_ABOUT_YOUR_PLAN_WHAT_DID_YOU_DO';
      };
    },
    restrict: 'E',
    scope: {
      close: '=',
      mode: '@',
      onlyShowInstructions: '=',
      onClose: '&',
      onSubmit: '&'
    },
    template: tpl
  }
}
