<template>
  <div class="view-progress">
    <button
      v-if="screenshots.length > 1 && screenshotIndex > 0"
      class="previous-btn"
      tabindex="0"
      @click="onPreviousButtonClick">
      Previous
    </button>

    <div class="body">
      <div class="header">
        {{ thinklet.user.firstName | propercase }}'s {{ 'PROGRESS' | translate }}

        <div class="mode-buttons">
          <button
            v-for="mode in modes"
            class="mode-btn"
            tabindex="0"
            :class="{ selected: selectedMode === mode }"
            @click="onModeButtonClick(mode)">
            {{ mode | uppercase | translate }}
          </button>
        </div>

        <div class="status">
          {{ 'STATUS' | translate }}:

          <progress-icons
            class="progress-icons"
            :thinklet="thinklet">
          </progress-icons>
        </div>
      </div>

      <view-thinklet
        v-if="selectedMode === 'video'"
        :default-sentence-starters="defaultSentenceStarters"
        :go-back="false"
        :is-cue-teach-enabled="user.cueteachEnabled"
        :is-educator-mode="true"
        :is-footer-enabled="false"
        :is-private-annotations-enabled="true"
        :is-student-rubric-enabled="false"
        :teacher-manager="teacherManager"
        :thinklet-id="thinklet.id"
        :thinklet-manager="thinkletManager"
        :user="user"
        :user-manager="userManager"
        @view-thinklet="onViewThinklet">
      </view-thinklet>
      <img
        v-else-if="thumbnailUrl"
        :alt="thinklet.title"
        :src="thumbnailUrl"
      />
      <div
        v-else
        class="empty-message">
        <p>{{ 'UH_OH_IT_LOOKS_LIKE_THE_STUDENT_HAS_NOT_MADE_PROGRESS_ON_THIS_PHASE_YET' | translate }}</p>

        <button
          v-if="false"
          class="secondary-button"
          tabindex="0"
          @click="onSendMessageButtonClick">
          {{ 'SEND_THEM_A_MESSAGE' | translate }}
        </button>
      </div>
    </div>

    <button
      v-if="screenshots.length > 1 && screenshotIndex < screenshots.length - 1"
      class="next-btn"
      tabindex="0"
      @click="onNextButtonClick">
      Next
    </button>

    <button
      class="close-btn"
      tabindex="0"
      @click="onCloseButtonClick">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</template>

<script>
import propercase from 'core/common/filters/propercase.filter';
import translate from 'core/common/filters/translate.filter';
import uppercase from 'core/common/filters/uppercase.filter';
import 'src/images/Close x_White.svg';
import 'src/images/Prev_Arrow.svg';
import 'src/images/Next_Arrow.svg';
import TeacherManager from '../../components/shared/teacher_data';
import { getShared as getSharedConfigStore } from 'core/common/stores/config.store';
import UserManager from '../../components/shared/user_data';
import ThinkletManager from '../../components/shared/thinklet_data';
import RouteManager from 'core/common/route-manager';
import 'src/images/share2.svg';
import _ from 'underscore';

export default {
  components: {
    ProgressIcons: () => import('./progress-icons'),
    ViewThinklet: () => import('core/viewThinklet/view-thinklet'),
    WhiteboardPlayer: () => import('core/whiteboard/whiteboard-player')
  },

  filters: {
    propercase,
    translate,
    uppercase
  },

  props: {
    phase: {
      required: true,
      type: String,
    },

    thinklet: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      modes: [
        'explore',
        'plan',
        'solve',
        'review',
        'video'
      ],
      screenshotIndex: 0,
      selectedMode: 'explore'
    };
  },

  computed: {
    defaultSentenceStarters() {
      return getSharedConfigStore().state.config.defaultSettings.viewingThinklets.annotations.sentenceStarters.slice(0);
    },

    screenshots() {
      let result = this.thinklet[this.selectedMode + '_screenshot'];

      if (!result)
        result = this.thinklet[this.selectedMode + 'Screenshot'];

      if (result) {
        if (Array.isArray(result)) {
          result = _.uniq(result, false, item => item.page);
        } else {
          result = [result];
        }
      } else {
        result = [];
      }

      return result;
    },

    teacherManager() {
      return TeacherManager.getShared();
    },

    thinkletManager() {
      return ThinkletManager.getShared();
    },

    thumbnailUrl() {
      let result = this.thinklet[this.selectedMode + '_screenshot'];

      if (!result)
        result = this.thinklet[this.selectedMode + 'Screenshot'];

      if (Array.isArray(result)) {
        result = result.filter(page => page.page === this.screenshotIndex);

        if (result.length > 0)
          result = result[result.length - 1].filePath;
        else
          result = null;
      }

      return result;
    },

    user() {
      return UserManager.getShared().getUser();
    },

    userManager() {
      return UserManager.getShared();
    }
  },

  created() {
    this.selectedMode = this.phase;
  },

  methods: {
    onCloseButtonClick() {
      this.$emit('close');
    },

    onModeButtonClick(mode) {
      this.selectedMode = mode;
    },

    onNextButtonClick() {
      let i = this.screenshotIndex;

      if (this.screenshotIndex < this.screenshots.length - 1) {
        this.screenshotIndex++;
      }
    },

    onPreviousButtonClick() {
      if (this.screenshotIndex > 0) {
        this.screenshotIndex--;
      }
    },

    onSendMessageButtonClick() {
      // TODO
    },

    onViewThinklet(id) {
      RouteManager.getSharedInstance().route('view-teacherdash', { id });
    }
  }
}
</script>

<style scoped>
.view-progress {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
}

.view-progress .body {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  position: absolute;
  top: 50%;
  left: 80px;
  right: 80px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-color: white;
  min-height: 400px;
  max-height: 100%;
}

.header {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 20px 20px 0 20px;
  font-weight: 700;
}

.mode-btn {
  padding-bottom: 20px;
  color: #979797;
  margin-right: 20px;
}

  .mode-btn.selected {
    border-bottom: 5px solid rgb(38, 169, 224);
    color: #363845;
  }

.view-progress img, .view-progress .empty-message {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.view-progress img {
  min-height: 0;
  object-fit: contain;
}

.close-btn, .previous-btn, .next-btn {
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  text-indent: -9999em;
  overflow: hidden;
}

.previous-btn, .next-btn {
  background-color: white;
  background-size: 25px 25px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.previous-btn {
  background-image: url(/images/Prev_Arrow.svg);
  left: 20px;
  background-position: left 10px center;
}

.next-btn {
  background-image: url(/images/Next_Arrow.svg);
  right: 20px;
}

.close-btn {
  top: 10px;
  right: 10px;
  background-image: url('/images/Close x_White.svg');
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-color: transparent;
}

.progress-icons {
  display: inline-block;
  margin-left: 10px;
}

.empty-message {
  position: relative;
  background-color: #EAEAEA;
}

  .empty-message p {
    font-weight: 700;
    padding-top: 40px;
    background-image: url(/images/Logo_icon.svg);
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    background-size: 30px 30px;
  }
</style>

<style>
.view-progress .viewThinklet {
  min-height: 500px;
}
</style>
