import StudentChat from './studentChat.vue';
import AngularVueComponentDirective from 'core/common/directives/angularVueComponent.directive';

export default new AngularVueComponentDirective(StudentChat, [
  'assignmentId',
  'chatRoomName',
  'classId',
  'speechToText',
  'thinkletId'
], [
  'CloseChat'
])
