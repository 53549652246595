import 'src/components/shared/user_data';
import 'src/components/shared/teacher_data';
import 'core/login/ssoregister/ssoRegistrationDirective';
import CueThinkLoginController from '../loginController';

function SSORegisterController($scope, UserManager, $state, $stateParams, CueThinkConfig, TeacherManager) {
  "ngInject";

  var userManager = UserManager;

  $scope.email = $stateParams.email;
  $scope.name = $stateParams.name;
  $scope.userName = $stateParams.username;
  $scope.id_token = $stateParams.id_token;
  $scope.token = $stateParams.token;
  $scope.isMobile = $stateParams.is_mobile === 'true' || $stateParams.is_mobile === true;
  $scope.service = $stateParams.is_clever ? 'clever' : 'google';
  $scope.firstName = $stateParams.first_name;
  $scope.lastName = $stateParams.last_name;
  $scope.type = $stateParams.type;
  $scope.schoolName = $stateParams.school;
  $scope.productName = CueThinkConfig.name;

  $scope.cleverAuthenticate = function () {
    var args = [];

    for (var i = 0; i < arguments.length; i++)
      args.push(arguments[i]);

    args.push($scope.referral);

    return userManager.authenticateClever.apply(null, args);
  };

  $scope.register = userManager.registerUser;

  $scope.googleAuthenticate = function (user, isMobile, callback) {
    userManager.oAuthLogIn(user, isMobile, $scope.referral, callback);
  };

  $scope.cleverRedirectURL = CueThinkConfig.cleverRedirectURL;
  $scope.cleverClientID = CueThinkConfig.cleverClientID;
  $scope.createStarterAssignments = TeacherManager.createStarterAssignments;
  $scope.loadSchools = userManager.loadNearbySchools;
  $scope.referral = $stateParams.ref;

  $scope.logInSuccess = function (user) {
    UserManager.setNotIdleFlag().then(function () {
      if (user.type == 'Student')
        CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);
      else
        $state.go('dashboard', { welcome: true });
    });
  };

  $scope.googleLogInSuccess = function (isStudent) {
    if (isStudent)
      $state.go('dashboard');
    else
      $state.go('dashboard', { welcome: true });
  };

  $scope.linkClever = function () {
    $state.go('linkclever', { ref: $scope.referral });
  };

  $scope.linkGoogle = function () {
    $state.go('linkaccount', { ref: $scope.referral, service: 'google' });
  };

  $scope.cancel = function () {
    $state.go('login', { ref: $scope.referral });
  };
}

export default SSORegisterController;
