function AnnotatedText (element, textElement) {
  var _mode, me, lastClickedWord, lastDraggedWord, started, highlightedPhrases, startElement, _changeCallback, _onAddWordsCallback;
  var HIGHLIGHTED_CLASS = 'highlighted';
  var STRIKETHROUGH_CLASS = 'crossed-out';
  var ERASE_MODE = 'erase';
  var HIGHLIGHT_MODE = 'highlight';
  var STRIKETHROUGH_MODE = 'strikethrough';
  var _highlightButton = $('.highlighter');
  var _colorPicker = element.find('.colorpicker');
  var _selectedColor;
  var highlightButton = element.find('.highlighter:first');
  var strikethroughButton = element.find('.strikethrough:first');
  var eraseButton = element.find('.eraser:first');
  var SELECTED_CLASS = 'selected';

  me = this;
  started = false;

  function bindEvent (element, type, handler) {
    element.on(type, handler);
    element.on('remove', function (event) {
      $(event.target).off(type, handler);
    });
  }

  function positionElementUnderElement (under, over) {
    var parent = under.parent();

    var pos = over.position();

    var padding = over.css(['paddingLeft', 'paddingTop', 'borderLeft', 'borderTop']);

    if (!padding.paddingLeft)
      padding.paddingLeft = 0;

    if (!padding.paddingTop)
      padding.paddingTop = 0;

    if (!padding.borderLeft)
      padding.borderLeft = 0;

    if (!padding.borderTop)
      padding.borderTop = 0;

    pos.left += parseInt(padding.paddingLeft);
    pos.top += parseInt(padding.paddingTop);
    pos.left += parseInt(padding.borderLeft);
    pos.top += parseInt(padding.borderTop);

    var overParent = over.parent();

    while (overParent[0] != parent[0]) {
      overParent = overParent.parent();

      var p = overParent.position();

      padding = overParent.css(['paddingLeft', 'paddingTop', 'borderLeft', 'borderTop']);

      if (!padding.paddingLeft)
        padding.paddingLeft = 0;

      if (!padding.paddingTop)
        padding.paddingTop = 0;

      if (!padding.borderLeft)
        padding.borderLeft = 0;

      if (!padding.borderTop)
        padding.borderTop = 0;

      p.left += parseInt(padding.paddingLeft);
      p.top += parseInt(padding.paddingTop);
      p.left += parseInt(padding.borderLeft);
      p.top += parseInt(padding.borderTop);

      pos.left += p.left;
      pos.top += p.top;
    }

    under.css('left', pos.left + 'px');
    under.css('top', pos.top + over.height() + 'px');
    under.css('position', 'absolute');

    var position = parent.css('position');
    if (position != 'absolute' && position != 'relative')
      parent.css('position', 'relative');
  }

  me.setMode = function (value) {
    lastClickedWord = null;

    _mode = value;
  };

  function activateHighlighter () {
    var mode = 'highlight';

    me.setMode(mode);

    highlightButton.addClass(SELECTED_CLASS);
    strikethroughButton.removeClass(SELECTED_CLASS);
    eraseButton.removeClass(SELECTED_CLASS);

    if (_onToolChangeCallback)
      _onToolChangeCallback(mode);
  }

  function selectColorButton (t) {
    var className = t.attr('class').split(' ')[0];

    _selectedColor = className;

    _highlightButton.removeClass('yellow');
    _highlightButton.removeClass('blue');
    _highlightButton.removeClass('green');
    _highlightButton.removeClass('pink');
    _highlightButton.addClass(className);

    activateHighlighter();
  }

  me.initialize = function () {
    selectColorButton(element.find('.yellow'));
  };

  function onColorDown (event) {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    selectColorButton($(event.target));
  }

  bindEvent(element.find('.colorpicker button'), 'click', onColorDown);

  /*textElement.bind('touchmove', function (event) {
      var span, touch;

      touch = event.originalEvent.touches.item(0);

      span = document.elementFromPoint(touch.clientX, touch.clientY);

      if (!span)
          return;

      span = $(span);

      if (!startElement)
          startElement = span;

      wordDragged(span);
  });*/

  textElement.bind('touchend', function (event) {
    lastDraggedWord = startElement = null;
  });

  me.setChangeCallback = function (value) {
    _changeCallback = value;
  };

  me.setText = function (value) {
    var container, child, words, i;

    textElement.children().remove();

    container = $('<div>');
    container.html(value);

    words = [];

    for (i = 0; i < container[0].childNodes.length; i++) {
      child = container[0].childNodes.item(i);

      processElement(child, textElement, words, true);
    }

    return words;
  };

  me.setHTML = function (value) {
    var words;

    textElement.html(value);

    words = [];

    textElement.find('span').each(function (i, span) {
      span = $(span);

      if (span.find('span').length === 0) {
        span.click(wordClicked).each(function (i, value) {
          words.push($(value).text());
        });
      }
    });

    return words;
  };

  function appendWord (word, c, words) {
    if (word.length === 0)
      return false;

    var span = $('<span>');
    span.text(word + ' ');
    bindEventHandlers(span);

    c.append(span);

    words.push(word);

    if (_onProcessWordCallback)
      _onProcessWordCallback(span);

    return span;
  }

  function processText (text, c, words) {
    var tex = text.split(/\$\$/);

    for (var j = 0; j < tex.length; j++) {
      if (j % 2 === 0) {
        var inline = tex[j].split(/(\\\(|\\\))/);

        for (var k = 0; k < inline.length; k++) {
          if (inline[k].length === 0)
            continue;

          if (k % 4 === 0) {
            var items = inline[k].split(/\s|&nbsp;/);

            for (var i = 0; i < items.length; i++)
              appendWord(items[i], c, words);
          } else if (k % 4 == 2) {
            appendWord('\\(' + inline[k] + '\\)', c, words);
          }
        }
      } else {
        appendWord('$$' + tex[j] + '$$', c, words);
      }
    }
  }

  function processElement (el, p, words, process) {
    var text, child, container, tagName;

    child = $(el).clone();

    if (el.nodeType === 3) { // text
      text = el.textContent;

      if (process) {
        if (text) {
          var c;

          tagName = child.prop('tagName');

          if (tagName)
            c = $('<' + tagName + '>');
          else
            c = p;

          lastClickedWord = null;

          processText(text, c, words);

          if (c != p)
            p.append(c);
        } else {
          p.append(child);
        }
      } else {
        bindEventHandlers(child);

        words.push(text);
      }
    } else {
      if (child.contents().length > 0) {
        if (process) {
          tagName = child.prop('tagName');

          if (tagName) {
            container = $('<' + tagName + '>');

            if (tagName.toLowerCase() === 'a') {
              container.attr('href', child.attr('href'));
              container.attr('target', '_blank');
            }
          } else {
            container = p;
          }
        } else {
          container = child;
        }

        child.contents().each(function (i, child) {
          processElement(child, container, words, process);
        });

        p.append(container);
      } else {
        // TODO: re-enable after saving non-rendered mathml
        // TODO: decode base64 encoded mathML
        /*tagName = child.prop('tagName');

        if (tagName && tagName.toLowerCase() === 'img') {
            var mathML = child.attr('alt');

            if (mathML && mathML.indexOf('<math') !== -1) {
                child = $('<span>').append($(mathML));

                bindEventHandlers(child);
            }
        }*/

        p.append(child);
      }
    }
  }

  me.getLastWord = function () {
    var result;

    result = lastClickedWord;
    lastClickedWord = null;

    return result;
  };

  function bindEventHandlers (element) {
    element.click(wordClicked);
  }

  function wordClicked (event) {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    lastClickedWord = $(this);

    wordDragged(lastClickedWord);

    if (_changeCallback)
      _changeCallback(me.getHTML(), _mode);
  }

  function eraseElements (elements) {
    removeHighlight(elements);
    elements.removeClass(STRIKETHROUGH_CLASS);
  }

  function highlightElements (elements) {
    removeHighlight(elements);

    elements.addClass(_selectedColor);
    elements.addClass(HIGHLIGHTED_CLASS);
  }

  function removeHighlight (elements) {
    elements.removeClass(HIGHLIGHTED_CLASS);
    elements.removeClass('yellow');
    elements.removeClass('green');
    elements.removeClass('blue');
    elements.removeClass('pink');
  }

  function wordDragged (sender) {
    var allElements;

    if (startElement) {
      if (lastDraggedWord && lastDraggedWord[0] == sender[0])
        return;

      if (startElement[0] == sender[0])
        allElements = sender;
      else if (startElement.nextAll().index(sender) == -1)
        allElements = $();
      else
        allElements = startElement.nextUntil(sender).add(startElement).add(sender);

      if (_mode === ERASE_MODE) {
        eraseElements(allElements);
      } else {
        if (_mode === HIGHLIGHT_MODE) {
          highlightElements(allElements);
        } else if (_mode === STRIKETHROUGH_MODE) {
          allElements.addClass(STRIKETHROUGH_CLASS);
        } else {
          return;
        }

        started = true;
      }

      lastDraggedWord = sender;
    } else {
      if (_mode === ERASE_MODE) {
        eraseElements(sender);
      } else {
        if (_mode === HIGHLIGHT_MODE) {
          if (sender.hasClass(_selectedColor))
            removeHighlight(sender);
          else
            highlightElements(sender);
        } else if (_mode === STRIKETHROUGH_MODE) {
          var className = STRIKETHROUGH_CLASS;

          if (sender.hasClass(className))
            sender.removeClass(className);
          else
            sender.addClass(className);
        } else {
          return;
        }

        started = true;
      }
    }
  }

  this.getHTML = function () {
    return textElement.html();
  };

  this.getStarted = function () {
    return started;
  };

  this.getSelectedWordCount = function () {
    highlightedPhrases = getHighlightedPhrases();

    return highlightedPhrases.length;
  };

  this.getSelectedWord = function (i) {
    return highlightedPhrases[i];
  };

  function nextElementInHierarchy (element, selector, stopElement) {
    var next = element.nextAll(selector);

    if (next.length === 0)
      next = element.nextAll().find(selector);

    if (next.length === 0) {
      var p = element.parent();

      if (stopElement && p[0] == stopElement[0])
        return next.first();

      return nextElementInHierarchy(p, selector, stopElement);
    } else {
      return next.first();
    }
  }

  function trimCharacters (text, chars) {
    var result = text;

    while (chars.indexOf(result.substring(0, 1)) !== -1)
      result = result.substring(1);

    while (chars.indexOf(result.substring(result.length - 1)) !== -1)
      result = result.substring(0, result.length - 1);

    return result;
  }

  function cleanPhrase (text) {
    return trimCharacters(text, ' <,>.?/:;"\'[{]}=+-_()*&^#@!');
  }

  function getHighlightedPhrases () {
    var phrases, span, phrase, selector;

    phrases = [];
    phrase = [];
    selector = '.' + HIGHLIGHTED_CLASS + ':first';

    span = textElement.find(selector);

    while (span.length > 0) {
      if (span.hasClass(HIGHLIGHTED_CLASS)) {
        phrase.push(span.text());

        var oldSpan = span;
        span = span.next();

        if (span.length === 0) {
          phrases.push(cleanPhrase(phrase.join('')));

          phrase = [];

          span = nextElementInHierarchy(oldSpan, selector, textElement);
        }
      } else {
        phrases.push(cleanPhrase(phrase.join('')));

        phrase = [];

        span = nextElementInHierarchy(span, selector, textElement);
      }
    }

    if (phrase.length > 0)
      phrases.push(cleanPhrase(phrase.join('')));

    return phrases;
  }

  var _hideColorPickerTimer;

  bindEvent(highlightButton, 'click', function (event) {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    activateHighlighter();

    _colorPicker.show();

    positionElementUnderElement(_colorPicker, element.find('.question-tools'));

    _hideColorPickerTimer = setTimeout(function () {
      _colorPicker.hide();

      _hideColorPickerTimer = null;
    }, 3000);
  });

  bindEvent(strikethroughButton, 'click', function () {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    var mode = STRIKETHROUGH_MODE;

    me.setMode(mode);

    highlightButton.removeClass(SELECTED_CLASS);
    eraseButton.removeClass(SELECTED_CLASS);
    strikethroughButton.addClass(SELECTED_CLASS);

    if (_onToolChangeCallback)
      _onToolChangeCallback(mode);
  });

  bindEvent(eraseButton, 'click', function () {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    var mode = ERASE_MODE;

    me.setMode(mode);

    strikethroughButton.removeClass(SELECTED_CLASS);
    highlightButton.removeClass(SELECTED_CLASS);
    eraseButton.addClass(SELECTED_CLASS);

    if (_onToolChangeCallback)
      _onToolChangeCallback(mode);
  });

  bindEvent(element.find('.add-words:first'), 'click', function (event) {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    if (_onAddWordsCallback)
      _onAddWordsCallback(getHighlightedPhrases());
  });

  function onSpeechEnd () {
    textElement.children().removeClass('tts');
  }

  var _speechButton = element.find('.speech:first');
  bindEvent(_speechButton, 'click', function (event) {
    if (_hideColorPickerTimer)
      clearTimeout(_hideColorPickerTimer);

    _colorPicker.hide();

    if (_speechCallback) {
      textElement.children().addClass('tts');

      _speechCallback(onSpeechEnd);
    }
  });

  me.setOnAddWordsCallback = function (value) {
    _onAddWordsCallback = value;
  };

  var _onToolChangeCallback;
  me.setOnToolChangeCallback = function (value) {
    _onToolChangeCallback = value;
  };

  var _speechCallback;
  me.setSpeechCallback = function (value) {
    _speechCallback = value;

    if (value)
      _speechButton.show();
    else
      _speechButton.hide();
  };

  var _onProcessWordCallback;
  me.setProcessWordCallback = function (value) {
    _onProcessWordCallback = value;
  };

  function addTooltip (el, text, position, pointer) { return; // TODO: remove
    if (!position)
      position = 'bottom center';

    if (!pointer)
      pointer = 'top left';

    el.qtip({
      content: text,
      position: {
        at: position,
        my: pointer
      },
      style: {
        classes: 'question-qtip',
        def: false
      },
      hide: { when: { event: 'inactive' } }
    });
  }

  addTooltip(_highlightButton, 'Highlighter', 'top left', 'bottom');
  addTooltip($('.strikethrough'), 'Strikethrough');
  addTooltip($('.eraser'), 'Eraser');
  addTooltip($('.add-words'), 'Add Words');
}

export default AnnotatedText;
