import module from '../login.module';
import '../loginDirective';
import './register.css';
import GoogleSignIn from '../google-sign-in';
import tpl from './registrationDirective.tpl.html';
import jQuery from 'jquery';
import _ from 'underscore';
require('./logo-and-sso.partial.html');
require('./registerTeacher.partial.html');
require('./registerStudent.partial.html');
import { CueThinkChangePassword } from '../forgotpassword/changePasswordDirective';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import { CueThinkLogin } from '../loginDirective';
import CueThinkConfig from '../../../beacon/src/app/config.provider';

module.directive('registration', function ($translate, $rootScope) {
  "ngInject";

  var linkFunction = function ($scope) {
    var _lastSchoolZipCode, _lastSchoolState;

    $scope.formData = {
      serverErrorMessage: '',
      student: {
        codeName: '',
        type: 103
      },
      teacher:  {
        type: ''
      },
      typeList: [],
      state: null,
      school: null
    };

    $scope.viewModel = {
      schools: null,
      isUS: true,
      firstNameValidationMessage: null,
      grades: null,
      invalidFormat: null,
      isConfirmPasswordChanged: false,
      isGradeOpen: false,
      isFirstNameChanged: false,
      isLastNameChanged: false,
      isPasswordChanged: false,
      isTermsOfServiceChecked: false,
      lastNameValidationMessage: null,
      termsOfServiceHtml: null
    };

    translate(['FIRST_NAME_VALIDATION_MESSAGE', 'LAST_NAME_VALIDATION_MESSAGE', 'INVALID_FORMAT', 'TEACHER', 'DEPARTMENT_HEAD', 'INSTRUCTIONAL_COACH', 'TECHNOLOGY_SPECIALIST', 'ADMINISTRATOR', 'CURRICULUM_COORDINATOR', 'PARENT', 'OTHER'], $translate, $rootScope, $scope, function (translations) {
      $scope.viewModel.firstNameValidationMessage = translations['FIRST_NAME_VALIDATION_MESSAGE'];
      $scope.viewModel.lastNameValidationMessage = translations['LAST_NAME_VALIDATION_MESSAGE'];
      $scope.viewModel.invalidFormat = translations['INVALID_FORMAT'];

      $scope.formData.typeList = [
        { id: '104', label: translations.TEACHER },
        { id: '109', label: translations['DEPARTMENT_HEAD'] },
        { id: '106', label: translations['INSTRUCTIONAL_COACH'] },
        { id: '111', label: translations['TECHNOLOGY_SPECIALIST'] },
        { id: '101', label: translations['ADMINISTRATOR'] },
        { id: '110', label: translations['CURRICULUM_COORDINATOR'] },
        { id: '112', label: translations['PARENT'] }
      ];

      $scope.viewModel.grades = [
        'Pre-K',
        'K',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        translations.OTHER
      ].map(function (grade) {
        return { name: grade, selected: false };
      });
    });

    $scope.viewModel.states = [
      {
        "name": "Alabama",
        "abbreviation": "AL"
      },
      {
        "name": "Alaska",
        "abbreviation": "AK"
      },
      {
        "name": "Arizona",
        "abbreviation": "AZ"
      },
      {
        "name": "Arkansas",
        "abbreviation": "AR"
      },
      {
        "name": "California",
        "abbreviation": "CA"
      },
      {
        "name": "Colorado",
        "abbreviation": "CO"
      },
      {
        "name": "Connecticut",
        "abbreviation": "CT"
      },
      {
        "name": "Delaware",
        "abbreviation": "DE"
      },
      {
        "name": "District Of Columbia",
        "abbreviation": "DC"
      },
      {
        "name": "Florida",
        "abbreviation": "FL"
      },
      {
        "name": "Georgia",
        "abbreviation": "GA"
      },
      {
        "name": "Hawaii",
        "abbreviation": "HI"
      },
      {
        "name": "Idaho",
        "abbreviation": "ID"
      },
      {
        "name": "Illinois",
        "abbreviation": "IL"
      },
      {
        "name": "Indiana",
        "abbreviation": "IN"
      },
      {
        "name": "Iowa",
        "abbreviation": "IA"
      },
      {
        "name": "Kansas",
        "abbreviation": "KS"
      },
      {
        "name": "Kentucky",
        "abbreviation": "KY"
      },
      {
        "name": "Louisiana",
        "abbreviation": "LA"
      },
      {
        "name": "Maine",
        "abbreviation": "ME"
      },
      {
        "name": "Maryland",
        "abbreviation": "MD"
      },
      {
        "name": "Massachusetts",
        "abbreviation": "MA"
      },
      {
        "name": "Michigan",
        "abbreviation": "MI"
      },
      {
        "name": "Minnesota",
        "abbreviation": "MN"
      },
      {
        "name": "Mississippi",
        "abbreviation": "MS"
      },
      {
        "name": "Missouri",
        "abbreviation": "MO"
      },
      {
        "name": "Montana",
        "abbreviation": "MT"
      },
      {
        "name": "Nebraska",
        "abbreviation": "NE"
      },
      {
        "name": "Nevada",
        "abbreviation": "NV"
      },
      {
        "name": "New Hampshire",
        "abbreviation": "NH"
      },
      {
        "name": "New Jersey",
        "abbreviation": "NJ"
      },
      {
        "name": "New Mexico",
        "abbreviation": "NM"
      },
      {
        "name": "New York",
        "abbreviation": "NY"
      },
      {
        "name": "North Carolina",
        "abbreviation": "NC"
      },
      {
        "name": "North Dakota",
        "abbreviation": "ND"
      },
      {
        "name": "Ohio",
        "abbreviation": "OH"
      },
      {
        "name": "Oklahoma",
        "abbreviation": "OK"
      },
      {
        "name": "Oregon",
        "abbreviation": "OR"
      },
      {
        "name": "Pennsylvania",
        "abbreviation": "PA"
      },
      {
        "name": "Rhode Island",
        "abbreviation": "RI"
      },
      {
        "name": "South Carolina",
        "abbreviation": "SC"
      },
      {
        "name": "South Dakota",
        "abbreviation": "SD"
      },
      {
        "name": "Tennessee",
        "abbreviation": "TN"
      },
      {
        "name": "Texas",
        "abbreviation": "TX"
      },
      {
        "name": "Utah",
        "abbreviation": "UT"
      },
      {
        "name": "Vermont",
        "abbreviation": "VT"
      },
      {
        "name": "Virginia",
        "abbreviation": "VA"
      },
      {
        "name": "Washington",
        "abbreviation": "WA"
      },
      {
        "name": "West Virginia",
        "abbreviation": "WV"
      },
      {
        "name": "Wisconsin",
        "abbreviation": "WI"
      },
      {
        "name": "Wyoming",
        "abbreviation": "WY"
      }
    ];

    if ($scope.type === 'teacher')
      $scope.view = 'registerTeacher.partial.html';
    else
      $scope.view = 'registerStudent.partial.html';

    var login = {
      name: '',
      password: '',
      type: null
    };

    function digest () {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    function handleSuccess (succeed) {
      if (succeed) {
        var success = function (success, user) {
          if (success) {
            var logInSuccess = function () {
              $scope.logInSuccess(user);
            };

            if (user.type == 'Student' || !$scope.createStarterAssignments)
              logInSuccess();
            else
              $scope.createStarterAssignments().then(logInSuccess, logInSuccess);
          } else {
            $scope.formData.serverErrorMessage = user;

            digest();
          }
        };

        var promise = $scope.login(login, success);

        if (promise) {
          promise.then(function (user) {
            success(true, user);
          }, function (message) {
            success(false, message);
          });
        }
      } else {
        $scope.formData.serverErrorMessage = succeed.message;
      }

      digest();
    }

    function handleError (error) {
      if (Array.isArray(error))
        error = error.join("\n");

      $scope.formData.serverErrorMessage = error;
      $scope.isRegistering = false;

      digest();
    }

    $scope.registerStudent = function () {
      var isValid = $scope.formData.registerStudentForm.$valid && CueThinkChangePassword.validatePassword($scope.formData.student.password, $scope.formData.student.password2);

      if (!$scope.isValid())
        return;

      if (isValid) {
        $scope.isRegistering = true;

        $scope.formData.student.codeName = $scope.formData.student.username;
        login.name = $scope.formData.student.username;
        login.password = $scope.formData.student.password;
        login.type = 'Student';
        login.referral = $scope.referral;

        var data = _.clone($scope.formData.student);
        data.referral = $scope.referral;

        var promise = $scope.register(data, handleSuccess, handleError);

        if (promise)
          promise.then(handleSuccess, handleError);
      } else {
        _.forOwn($scope.formData.registerStudentForm.$error, function (value, key) {
          _.each(value, function(element) {
            element.$setDirty();
          });
        });
      }
    };

    function getSelectedGrades () {
      return $scope.viewModel.grades.filter(function (grade) {
        return grade.selected;
      }).map(function (grade) {
        return grade.name;
      });
    }

    $scope.registerTeacher = function () {
      var isValid = $scope.formData.registerTeacherForm.$valid && CueThinkChangePassword.validatePassword($scope.formData.teacher.password, $scope.formData.teacher.password2);
      var data;

      if (!$scope.isValid())
        return;

      if (isValid) {
        $scope.isRegistering = true;

        $scope.formData.teacher.grade = getSelectedGrades().join(',');

        if (!$scope.viewModel.isUS)
          $scope.formData.teacher.zipCode = $scope.formData.teacher.state = null;

        login.name = $scope.formData.teacher.username;
        login.password = $scope.formData.teacher.password;
        login.type = 'Teacher';
        login.referral = $scope.referral;

        data = _.clone($scope.formData.teacher);
        data.referral = $scope.referral;

        var promise = $scope.register(data, handleSuccess, handleError);

        if (promise)
          promise.then(handleSuccess, handleError);
      } else {
        _.forOwn($scope.formData.registerTeacherForm.$error, function(value, key) {
          _.each(value, function(element) {
            element.$setDirty();
          });
        });
      }
    };

    $scope.resetServerError = function () {
      $scope.formData.serverErrorMessage = '';

      digest();
    };

    var _lastUserNameChecked;

    $scope.checkUserName = function (username) {
      if (username && username !== _lastUserNameChecked && !isUserNameValid(username)) {
        _lastUserNameChecked = username;

        $translate('SORRY_A_USERNAME_CAN_ONLY_CONTAIN_NUMBERS_AND_LETTERS').then(function (msg) {
          alert(msg);
        });
      }
    };

    function isUserNameValid (username) {
      if (!username) {
      } else if (/[^A-Za-z0-9 ]/.test(username)) {
      } else {
        return true;
      }

      return false;
    }

    $scope.validateStudentUsername = function () {
      var username = $scope.formData.student.username;

      $scope.formData.isRegisteredStudentUsername = isUserNameValid(username);

      $scope.resetServerError();
    };

    $scope.validateStudentUsername();

    function isValidName (name) {
      return /^[a-zA-Z'.-]+$/.test(name);
    };

    $scope.isFirstNameValid = function () {
      var name;

      if ($scope.type === 'student')
        name = $scope.formData.student.firstName;
      else
        name = $scope.formData.teacher.firstName;

      return isValidName(name);
    };

    $scope.onFirstNameBlur = function () {
      $scope.viewModel.isFirstNameChanged = true;
    };

    $scope.isLastNameValid = function () {
      var name;

      if ($scope.type === 'student')
        name = $scope.formData.student.lastName;
      else
        name = $scope.formData.teacher.lastName;

      return isValidName(name);
    };

    $scope.onLastNameBlur = function () {
      $scope.viewModel.isLastNameChanged = true;
    };

    $scope.isPasswordValid = function () {
      var password;

      if ($scope.type === 'student')
        password = $scope.formData.student.password;
      else
        password = $scope.formData.teacher.password;

      var message = CueThinkChangePassword.getPasswordValidationMessage(password);

      return !message;
    };

    $scope.isConfirmPasswordValid = function () {
      var password1, password2;

      if ($scope.type === 'student') {
        password1 = $scope.formData.student.password;
        password2 = $scope.formData.student.password2;
      } else {
        password1 = $scope.formData.teacher.password;
        password2 = $scope.formData.teacher.password2;
      }

      var message = CueThinkChangePassword.getConfirmPasswordValidationMessage(password1, password2);

      return !message;
    };

    $scope.onPasswordBlur = function () {
      $scope.viewModel.isPasswordChanged = true;
    };

    $scope.onConfirmPasswordBlur = function () {
      $scope.viewModel.isConfirmPasswordChanged = true;
    };

    $scope.isValidPassword = function (password) {
      var message = CueThinkChangePassword.getPasswordValidationMessage(password);

      return !message;
    };

    $scope.isValidConfirmPassword = function (password1, password2) {
      var message = CueThinkChangePassword.getConfirmPasswordValidationMessage(password1, password2);

      return !message;
    };

    $scope.getPasswordValidationMessage = function () {
      var password;

      if ($scope.type === 'student')
        password = $scope.formData.student.password;
      else
        password = $scope.formData.teacher.password;

      return CueThinkChangePassword.getPasswordValidationMessage(password);
    };

    $scope.getConfirmPasswordValidationMessage = function () {
      var password1, password2;

      if ($scope.type === 'student') {
        password1 = $scope.formData.student.password;
        password2 = $scope.formData.student.password2;
      } else {
        password1 = $scope.formData.teacher.password;
        password2 = $scope.formData.teacher.password2;
      }

      return CueThinkChangePassword.getConfirmPasswordValidationMessage(password1, password2);
    }

    $scope.validateTeacherUsername = function () {
      var username = $scope.formData.teacher.username;

      $scope.formData.isRegisteredTeacherUsername = isUserNameValid(username);

      $scope.resetServerError();
    };

    $scope.validateTeacherUsername();

    var _isMobile;
    new GoogleSignIn(function (user, isMobile, callback) {
      _isMobile = isMobile;

      return $scope.authenticateGoogle(user.token, isMobile, callback);
    }, function (user, success, message) {
      if (!success)
        $scope.googleRegister(user, _isMobile);
      else
        $scope.logInSuccess(user);
    }, function (available, signIn) {
      if (available) {
        $scope.googleSignIn = signIn;

        digest();
      }
    }, true);

    $scope.cleverSignIn = function () {
      CueThinkLogin.cleverAuthenticate($scope.authenticateClever, $scope.cleverRedirectUrl, $scope.cleverClientId, function (success, user) {
        if (success)
          $scope.logInSuccess(user);
        else
          $scope.cleverRegister(user);
      });
    };

    $scope.presentModalIFrame = function (url, $event) {
      var container = jQuery('<div>');
      container.css('background-color', 'rgba(0, 0, 0, .5)');
      container.css('position', 'absolute');
      container.css('left', 0);
      container.css('top', 0);
      container.css('width', '100%');
      container.css('height', '100%');

      var frameContainer = jQuery('<div>');
      frameContainer.addClass('modal-iframe');
      frameContainer.css('position', 'absolute');
      frameContainer.css('left', '50%');
      frameContainer.css('top', '50%');
      frameContainer.css('width', '75%');
      frameContainer.css('height', '75%');
      frameContainer.css('transform', 'translatex(-50%) translatey(-50%)');
      frameContainer.css('-webkit-transform', 'translatex(-50%) translatey(-50%)');
      frameContainer.css('overflow', 'auto');
      container.append(frameContainer);

      var frame = jQuery('<iframe>');
      frame.attr('src', url);
      frame.css('width', '100%');
      frame.css('height', '100%');
      frame.css('background-color', 'white');
      frame.css('overflow', 'auto');
      frameContainer.append(frame);

      var closeButton = jQuery('<button>');
      closeButton.attr('tabindex', 0);
      closeButton.text('X');
      closeButton.css('text-indent', '-99em');
      closeButton.css('overflow', 'hidden');
      closeButton.css('background-image', 'url(/images/Close_btn.png)');
      closeButton.css('width', '31px');
      closeButton.css('height', '32px');
      closeButton.css('position', 'absolute');
      closeButton.css('right', '20px');
      closeButton.css('top', '20px');
      closeButton.on('click', function (event) {
        container.remove();
      });
      frameContainer.append(closeButton);

      jQuery('body:first').append(container);

      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        $event.returnValue = false;
      }

      return false;
    };

    function reloadSchools () {
      var userObj;

      if ($scope.type === 'student')
        userObj = $scope.formData.student;
      else
        userObj = $scope.formData.teacher;

      if (!userObj.state || !userObj.zipCode)
        return;

      if (userObj.state === _lastSchoolState && userObj.zipCode === _lastSchoolZipCode)
        return;

      _lastSchoolZipCode = userObj.zipCode;
      _lastSchoolState = userObj.state;

      resetSchools();

      $scope.loadSchools(userObj.state, userObj.zipCode).then(function (results) {
        results.push({
          id: null,
          name: 'Other',
          district: null,
          type: null
        });

        $scope.viewModel.schools = results;
      });
    }

    function resetSchools() {
      $scope.viewModel.schools = null;
      $scope.formData.school = null;
    }

    $scope.onIsUSChange = function () {
      if (!$scope.viewModel.isUS) {
        $scope.formData.school = null;
        $scope.formData.teacher.schoolSearchId = null;
        $scope.formData.teacher.schoolSearchDistrict = null;
        $scope.formData.teacher.schoolSearchDistrictId = null;
        $scope.formData.teacher.schoolSearchType = null;
      }
    };

    function isZipCodeValid () {
      return /^\d{5}$/.test($scope.formData.teacher.zipCode);
    }

    $scope.onZipBlur = function () {
      if (!isZipCodeValid()) {
        $translate('PLEASE_ENTER_A_VALID_ZIP_CODE').then(function (msg) {
          alert(msg);
        });

        return;
      }

      reloadSchools();
    };

    $scope.onStateChange = function () {
      $scope.formData.teacher.state = $scope.viewModel.state.abbreviation;

      $scope.resetServerError();

      reloadSchools();
    };

    $scope.onSchoolChange = function () {
      if ($scope.formData.school) {
        if ($scope.formData.school.id)
          $scope.formData.teacher.userSchoolName = $scope.formData.school.name;
        else
          $scope.formData.teacher.userSchoolName = null;

        $scope.formData.teacher.schoolSearchId = $scope.formData.school.id;
        $scope.formData.teacher.schoolSearchDistrict = $scope.formData.school.district;
        $scope.formData.teacher.schoolSearchDistrictId = $scope.formData.school.districtId;
        $scope.formData.teacher.schoolSearchType = $scope.formData.school.type;
      } else {
        $scope.formData.teacher.schoolSearchId = null;
        $scope.formData.teacher.schoolSearchDistrict = null;
        $scope.formData.teacher.schoolSearchDistrictId = null;
        $scope.formData.teacher.schoolSearchType = null;
      }

      $scope.resetServerError();
    };

    function isEmailValid (email) {
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    }

    var _hasEmailChanged;

    $scope.isEmailValid = function () {
      return !_hasEmailChanged || isEmailValid($scope.formData.teacher.email);
    };

    $scope.onEmailBlur = function () {
      _hasEmailChanged = true;

      $scope.resetServerError();
    };

    $scope.isValid = function () {
      if (!$scope.isFirstNameValid() || !$scope.isLastNameValid())
        return false;

      if (!$scope.isPasswordValid() || !$scope.isConfirmPasswordValid())
        return false;

      if (!$scope.viewModel.isTermsOfServiceChecked)
        return false;

      if ($scope.type === 'teacher') {
        if ($scope.formData.registerTeacherForm.$invalid)
          return false;

        if ($scope.getSelectedGrades().length === 0)
          return false;

        if (!$scope.formData.teacher.userSchoolName)
          return false;

        if (!$scope.isEmailValid())
          return false;

        if ($scope.viewModel.isUS)
          return $scope.formData.teacher.zipCode && isZipCodeValid() && $scope.formData.teacher.state;

        return true;
      } else {
        return !$scope.formData.registerStudentForm.$invalid;
      }
    };

    $scope.getSelectedGrades = function () {
      return getSelectedGrades().join(', ');
    };

    $scope.openTermsOfService = function ($event) {
      $scope.presentModalIFrame(CueThinkConfig.termsURL, $event);
    };

    $scope.openPrivacyPolicy = function ($event) {
      $scope.presentModalIFrame(CueThinkConfig.privacyPolicyURL, $event);
    };

    function bindEvent (el, event, handler) {
      el = $(el);
      el.on(event, handler);
      el.on('remove', function () {
        el.off(event, handler);
      });
    }

    $scope.$$postDigest(function () {
      bindEvent('.terms-of-service-link', 'click', function (event) {
        $scope.openTermsOfService(event);
      });

      bindEvent('.privacy-policy-link', 'click', function (event) {
        $scope.openPrivacyPolicy(event);
      });
    });

    translate('TERMS_OF_SERVICE_HTML', $translate, $rootScope, $scope, function (translation) {
      $scope.viewModel.termsOfServiceHtml = translation;
    });
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    scope: {
      authenticateClever: '=',
      authenticateGoogle: '=',
      cancel: '=',
      cleverClientId: '=',
      cleverRedirectUrl: '=',
      cleverRegister: '=',
      createStarterAssignments: '=',
      googleClientId: '=',
      googleRegister: '=',
      loadSchools: '=',
      login: '=',
      logInSuccess: '=',
      logoPath: '@',
      name: '=',
      referral: '=',
      register: '=',
      type: '='
    },
    replace: true
  };
});
