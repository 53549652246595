import MobileDetect from 'mobile-detect';

export default angular.module('deviceDetection', [])
    .service('deviceDetection', function () {
    var mobileDetection = new MobileDetect(window.navigator.userAgent),
    isDesktop = !mobileDetection.mobile();

    var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    var isSafari = navigator.userAgent.indexOf("Safari") > -1;
    var isOpera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
    if ((isChrome)&&(isSafari)) { isSafari = false; }
    if ((isChrome)&&(isOpera)) { isChrome = false; }

    this.isDesktopSafari = function() {
        return isDesktop && isSafari;
    };

    this.isDesktop = function() {
        return isDesktop;
    };
});
