import { BaseWhiteboardTool, inherit } from './base-tool';

export default function ShapeTool (whiteboard) {
  var me = this;

  BaseWhiteboardTool.call(this, whiteboard);

  var _beginShape;
  var _savedCanvas;

  this.beginDraw = function (x, y) {
    _beginShape = {
      x: x,
      y: y
    };

    _savedCanvas = whiteboard.saveCanvas();
  };

  // redraws the canvas from before drawing begins and draws new shape size
  function redrawShape (factory, commit) {
    if (!_beginShape)
      return;

    whiteboard.restoreCanvas(_savedCanvas);

    var ev = factory();

    if (commit)
      whiteboard.addEvent(ev);
    else
      whiteboard.execute(ev);

    if (commit)
      _savedCanvas = null;

    return ev;
  }

  this.getRedrawnObject = function (beginShape, x, y) {
    var cls = this.getDataConstructor();

    return new cls(beginShape.x, beginShape.y, x, y);
  };

  this.redraw = function (x, y, commit) {
    return redrawShape(function () {
      return me.getRedrawnObject(_beginShape, x, y);
    }, commit);
  };

  this.endDraw = function (x, y) {
    return this.redraw(x, y, true);
  };

  this.setStyles = function (obj) {
    whiteboard.setFillColor(null);
    whiteboard.setStrokeColor(obj.color);
    whiteboard.setLineWidth(4);
    whiteboard.setLineCap('round');
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };
}

inherit(ShapeTool, BaseWhiteboardTool);
