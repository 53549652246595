export function canCreateThinklet(assignment, thinkletVersions) {
  if (assignment.exemplarThinkletId)
    return false;

  if (!thinkletVersions)
    return false;

  return !thinkletVersions[assignment.id];
}

export function hasCompletedAllThinkletsForAssignment(assignment, thinkletVersions) {
  if (!thinkletVersions)
    return false;

  var versions = thinkletVersions[assignment.id];

  if (!versions)
    return false;

  for (var i = 0; i < versions.length; i++)
    if (!versions[i].isComplete)
      return false;

  return true;
}

export function hasIncompleteThinkletForAssignment(assignment, thinkletVersions) {
  if (assignment.exemplarThinkletId && !hasCompletedAllThinkletsForAssignment(assignment, thinkletVersions))
    return true;

  if (!thinkletVersions)
    return false;

  var versions = thinkletVersions[assignment.id];

  if (!versions)
    return false;

  for (var i = 0; i < versions.length; i++)
    if (!versions[i].isComplete)
      return true;

  return false;
}

export function isAssignmentClosing(assignment, thinklets) {
  if (!thinklets)
    return false;

  var thinklet = thinklets[assignment.id];

  if (thinklet && thinklet.isComplete)
    return false;

  var now = new Date();
  var date = assignment.endDate;

  if (now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear())
    return false;

  return now > date;
}

export function isAssignmentDueSoon(assignment, thinklets) {
  if (!thinklets)
    return false;

  var thinklet = thinklets[assignment.id];

  if (thinklet && thinklet.isComplete)
    return false;

  var today = new Date();
  var date = assignment.endDate;

  if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
    return true;

  var now = today.getTime();
  var then = date.getTime();

  return then > now && then - now <= 3 * 24 * 60 * 60 * 1000;
}