import './tutorial.css';
import _ from 'underscore';
import 'angular-translate';
import { isNewDesign } from '../common/user-modes';

angular.module('cuethink.tutorial', ['pascalprecht.translate']).factory('TutorialService', function ($state, $translate, UserManager) {
  "ngInject";

  if (!Tutorial.sharedInstance)
    Tutorial.sharedInstance = new Tutorial($state, $translate, UserManager);

  return Tutorial.sharedInstance;
});

function Tutorial($state, $translate, userManager) {
  var _steps, i, _me = this, _previousButton, _nextButton, _target, _spotlight, _loadCallback, _isActive, _closeButton,
    _isPaused;
  var _returnToFirstScreen;
  var me = this;

  this.start = function (steps, returnToFirstScreen) {
    _isActive = true;
    _isPaused = false;

    _steps = steps;

    _returnToFirstScreen = returnToFirstScreen;

    i = 0;

    _me.load();
  };

  this.resume = function () {
    if (_isActive && _isPaused && _steps && typeof i != 'undefined') {
      _isPaused = false;

      _me.load();
    }
  };

  function areObjectsEqual(a, b) {
    var aKeys = Object.keys(a);
    var bKeys = Object.keys(b);

    var abort;

    $.each(aKeys, function (i, key) {
      if (typeof a[key] === 'undefined' || typeof b[key] === 'undefined')
        return;

      if (a[key] != b[key]) {
        abort = true;

        return false;
      }
    });

    if (abort)
      return false;

    $.each(bKeys, function (i, key) {
      if (typeof a[key] === 'undefined' || typeof b[key] === 'undefined')
        return;

      if (a[key] != b[key]) {
        abort = true;

        return false;
      }
    });

    if (abort)
      return false;

    return true;
  }

  this.load = function () {
    var step = _steps[i];

    var doLoad = function () {
      if ($state.current.name != step.state) {
        _isPaused = true;

        $state.go(step.state, step.params);

        return;
      }

      if (step.params && !areObjectsEqual($state.params, step.params)) {
        $state.go(step.state, step.params);

        return;
      }

      var go = function () {
        if (step.show)
          $(step.show).show();

        if (step.selector) {
          _target = $(step.selector);

          if (_target.length === 0) { // wait until element is in the DOM
            setTimeout(function () {
              me.load();
            }, 500);

            return;
          }

          _spotlight = Tutorial.spotlightElement(_target, step.padding);
        } else {
          _target = $('body:first');
        }

        if (step.hide) {
          var hideEl = $(step.hide);

          Tutorial.waitUntil(function () {
            if (hideEl.length > 0)
              return true;

            hideEl = $(step.hide);

            return false;
          }, function () {
            hideEl.hide();
          });
        }

        if (step.images) {
          var selectors = Object.keys(step.images);

          _.each(selectors, function (sel) {
            var el = $(sel);

            Tutorial.waitUntil(function () {
              if (el.length > 0)
                return true;

              el = $(sel);

              return false;
            }, function () {
              Tutorial.coverElementWithImage(el, step.images[sel]);
            });
          });
        }

        var options = {
          container: 'body',
          trigger: 'manual'
        };

        if (!step.selector || step.placement == 'center') {
          options.placement = function (el) {
            setTimeout(function () {
              el = $(el);
              el.css('position', 'absolute');
              el.css('left', '50%');
              el.css('top', '50%');
              el.css('transform', 'translateX(-50%) translateY(-50%)');
              el.css('-webkit-transform', 'translateX(-50%) translateY(-50%)');
            });
          };
        } else {
          options.placement = step.placement;
        }

        let content = step.content;

        if (typeof content === 'object') {
          if (content.newDesign && isNewDesign(userManager.getUser()))
            content = content.newDesign;
          else
            content = content.default;
        }

        $translate(['CLOSE', 'PREVIOUS', 'NEXT', 'DONE', content]).then(function (translations) {
          var html = '<div class="body"><button tabindex="0" class="close">' + translations.CLOSE + '</button>';
          html += translations[content];
          html += '</div><div class="buttons"><button tabindex="0" class="previous">' + translations.PREVIOUS + '</button><button tabindex="0" class="next">' + translations.NEXT + '</button></div>';

          options.html = true;
          options.content = html;
          options.template = '<div class="tutorial popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>';

          _target.popover(options);
          _target.popover('show');

          setTimeout(function () {
            if (!step.selector) {
              var body = $('body:first');

              _spotlight = Tutorial.createBackdrop({ left: 0, top: 0, right: 0, bottom: 0 });
              body.append(_spotlight);
            }

            _closeButton = $('.tutorial.popover .close').on('click', finish);
            _closeButton.on('remove', function () {
              _closeButton.off('click', finish);
            });

            _previousButton = $('.tutorial.popover .previous').on('click', _me.previous);
            _previousButton.on('remove', function () {
              _previousButton.off('click', _me.previous);
            });

            if (i === 0)
              _previousButton.addClass('disabled');
            else
              _previousButton.removeClass('disabled');

            _nextButton = $('.tutorial.popover .next').on('click', _me.next);
            _nextButton.on('remove', function () {
              _nextButton.off('click', _me.next);
            });

            if (i == _steps.length - 1)
              _nextButton.text(translations.DONE);
            else
              _nextButton.text(translations.NEXT);
          }, 500);
        });
      };

      if (step.click) {
        $(step.click).click();

        setTimeout(function () {
          go();
        }, 500);
      } else {
        go();
      }
    };

    function run() {
      if (_loadCallback)
        if (_loadCallback(step, doLoad, _steps) === false)
          return;

      doLoad();
    }

    if (step.delay)
      setTimeout(run, step.delay);
    else
      run();
  };

  function finish() {
    reset();

    _isActive = false;

    var shouldReturn;

    if (_onFinishCallback)
      shouldReturn = _onFinishCallback();
    else
      shouldReturn = _returnToFirstScreen !== false;

    if (shouldReturn)
      $state.go(_steps[0].state);
  }

  var _onFinishCallback;

  this.setOnFinishCallback = function (value) {
    _onFinishCallback = value;
  };

  function reset() {
    if (_target) {
      var popover = $('.tutorial.popover');

      _target.popover('destroy');
      _target = null;

      if (popover) {
        setTimeout(function () {
          popover.remove();
        }, 500);
      }
    }

    if (_spotlight) {
      _spotlight.remove();
      _spotlight = null;
    }
  }

  this.next = function () {
    if (i == _steps.length - 1) {
      finish();

      return;
    }

    reset();

    i++;

    // wait until popover is fully destroyed
    setTimeout(function () {
      _me.load();
    }, 500);
  };

  this.previous = function () {
    if (i === 0)
      return;

    reset();

    i--;

    _me.load();
  };

  this.getLoadCallback = function () {
    return _loadCallback;
  };

  this.setLoadCallback = function (value) {
    _loadCallback = value;
  };

  this.isActive = function () {
    return _isActive;
  };
}

Tutorial.createBackdrop = function (options) {
  var result = $('<div>');
  result.css('background-color', 'rgba(0, 0, 0, .5)');
  result.css('position', 'fixed');
  result.css('z-index', 1001);

  if (typeof options.left == 'number')
    result.css('left', options.left + 'px');

  if (typeof options.top == 'number')
    result.css('top', options.top + 'px');

  if (typeof options.right == 'number')
    result.css('right', options.right + 'px');

  if (typeof options.bottom == 'number')
    result.css('bottom', options.bottom + 'px');

  var win = $(window);

  if (typeof options.width == 'undefined')
    options.width = win.width() - options.left - options.right;

  result.css('width', options.width + 'px');

  if (typeof options.height == 'undefined')
    options.height = win.height() - options.top - options.bottom;

  result.css('height', options.height + 'px');

  return result;
};

Tutorial.spotlightElement = function (el, padding) {
  var result = $();

  el = $(el);

  var pos = el.offset();
  var width = parseInt(el.css('width'));
  var height = parseInt(el.css('height'));

  if (padding) {
    if (typeof padding == 'number') {
      padding = {
        left: padding,
        top: padding,
        right: padding,
        bottom: padding
      };
    }

    if (padding.left) {
      pos.left -= padding.left;
      width += padding.left;
    }

    if (padding.top) {
      pos.top -= padding.top;
      padding.height += padding.top;
    }

    if (padding.right)
      width += padding.right;

    if (padding.bottom)
      height += padding.bottom;
  }

  pos.right = pos.left + width;
  pos.bottom = pos.top + height;

  var body = $('body:first');

  if (pos.top > 0) {
    var top = Tutorial.createBackdrop({ left: 0, top: 0, right: 0, height: pos.top });
    result = result.add(top);
    body.append(top);
  }

  if (pos.left > 0) {
    var left = Tutorial.createBackdrop({ left: 0, top: pos.top, width: pos.left, height: height });
    result = result.add(left);
    body.append(left);
  }

  var win = $(window);

  if (pos.right < win.width()) {
    var right = Tutorial.createBackdrop({ left: pos.right, top: pos.top, right: 0, height: height });
    result = result.add(right);
    body.append(right);
  }

  if (pos.bottom < win.height()) {
    var bottom = Tutorial.createBackdrop({ left: 0, top: pos.bottom, right: 0, bottom: 0 });
    result = result.add(bottom);
    body.append(bottom);
  }

  var cover = $('<div>');
  cover.css('position', 'fixed');
  cover.css('left', pos.left + 'px');
  cover.css('top', pos.top + 'px');
  cover.css('width', width + 'px');
  cover.css('height', height + 'px');
  cover.css('outline', '2px solid red');
  cover.css('outline-offset', '-2px');
  cover.css('z-index', 1001);
  body.append(cover);
  result = result.add(cover);

  return result;
};

Tutorial.coverElementWithImage = function (el, imgUrl) {
  var img = $('<img>');
  img.attr('alt', 'Image');
  img.attr('src', imgUrl);
  img.css('position', 'fixed');
  var offset = el.offset();
  img.css('top', offset.top + 'px');
  img.css('left', offset.left + 'px');
  img.css('width', el.width() + 'px');
  img.css('background-color', 'white');
  img.insertAfter(el);

  var onLoad = function () {
    img.css('top', offset.top + ((el.height() - img.height()) / 2) + 'px');
    img.css('left', offset.left + ((el.width() - img.width()) / 2) + 'px');
  };

  img.on('load', onLoad);
  img.on('remove', function () {
    img.off('loaded', onLoad);
  });

  return img;
};

Tutorial.waitUntil = function (wait, callback, delay) {
  if (!delay)
    delay = 500;

  if (!wait()) {
    setTimeout(function () {
      Tutorial.waitUntil(wait, callback, delay);
    }, delay);

    return;
  }

  callback();
};

export default Tutorial;
