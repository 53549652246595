import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    buttons: null,
    buttonsText: null,
    message: null,
    position: 'top',
    title: null
  },

  mutations: {
    SET_BUTTONS(state, value) {
      state.buttons = value;
    },

    SET_BUTTONS_TEXT(state, value) {
      state.buttonsText = value;
    },

    SET_MESSAGE(state, value) {
      state.message = value;
    },

    SET_POSITION(state, value) {
      state.position = value;
    },

    SET_TITLE(state, value) {
      state.title = value;
    }
  }
});
