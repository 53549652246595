function CleverAuthentication (authCallback, redirectURL, clientID) {
  var _me = this;

  function QueryString (url) {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = url.substring(url.indexOf('?') + 1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // If first entry with this name
      if (typeof query_string[pair[0]] === "undefined") {
        query_string[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
      } else if (typeof query_string[pair[0]] === "string") {
        var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
        query_string[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }

    return query_string;
  }

  _me.authenticate = function (invokeCallback, callback) {
    if (callback === undefined) {
      callback = invokeCallback;
      invokeCallback = null;
    }

    var processAuthCode = function (code, isToken) {
      var additions = { isMobile: isToken };

      if (isToken)
        additions.token = code;

      var responseCallback = function (success, user) {
        var keys = Object.keys(additions);

        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];

          user[key] = additions[key];
        }

        callback(success, user);
      };

      var success = callback ? responseCallback : null;

      if (invokeCallback) {
        invokeCallback(authCallback, code, success, isToken);
      } else if (authCallback) {
        var promise = authCallback(code, success, isToken);

        if (promise)
          promise.then(success);
      } else {
        callback(true, additions);
      }
    };

    var url = 'https://clever.com/oauth/authorize?response_type=code&redirect_uri=' + encodeURIComponent(redirectURL) + '&client_id=' + encodeURIComponent(clientID) + '&state=internal';

    var popup = window.open(url, 'clever', 'width=800,height=500,menubar=0,toolbar=0');

    var timer = window.setInterval(function () {
      var code;

      try {
        code = QueryString(popup.document.location.href).code;
      } catch (e) {
        return;
      }

      if (code) {
        window.clearInterval(timer);

        processAuthCode(code);

        popup.close();
      }
    }, 100);
  };
}

export default CleverAuthentication;
