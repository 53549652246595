import { BaseWhiteboardTool, inherit } from './base-tool';
import TableTool from './table-tool';

export default function DiagramTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, type, color) {
      this.id = whiteboard.getNextId();
      this.type = DiagramTool.type;
      this.diagram = type;
      this.coordinates = [sx, sy, ex, ey];

      if (color)
        this.color = color;

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isRotatable = function (obj) {
    return obj.diagram === 'l-diagram' || obj.diagram === 'part-part-whole';
  };

  this.setStyles = function (obj) {
    var color = obj.color;

    if (!color)
      color = 'lightgray';

    whiteboard.setStrokeColor(color);

    whiteboard.context.lineWidth = 1;
  };

  function drawTenFrame (event) {
    return TableTool.renderTable(whiteboard, event.coordinates[0], event.coordinates[1], event.coordinates[2], event.coordinates[3], 2, 5);
  }

  function drawPlaceValueMat (event) {
    return TableTool.renderTable(whiteboard, event.coordinates[0], event.coordinates[1], event.coordinates[2], event.coordinates[3], 8, 3, { 0: { 0: 'Hundreds', 1: 'Tens', 2: 'Ones'} }, null, 24, function (row) {
      if (row === 0)
        return false;
    });
  }

  function drawPartPartWhole (event) {
    var context = whiteboard.getContext();
    var bounds = TableTool.renderTable(whiteboard, event.coordinates[0], event.coordinates[1], event.coordinates[2], event.coordinates[3], 2, 1);

    context.beginPath();
    var midX = (bounds.right + bounds.left) / 2;
    context.moveTo(midX, (bounds.bottom + bounds.top) / 2);
    context.lineTo(midX, bounds.bottom);
    context.stroke();

    return bounds;
  }

  function drawLDiagram (obj) {
    whiteboard.context.lineWidth = 2;

    var context = whiteboard.getContext();
    var margin = 35;

    // horizontal line
    context.beginPath();
    context.moveTo(obj.coordinates[0] * whiteboard.getWidth(), margin + (obj.coordinates[1] * whiteboard.getHeight()));
    context.lineTo(obj.coordinates[2] * whiteboard.getWidth(), margin + (obj.coordinates[1] * whiteboard.getHeight()));
    context.stroke();

    // vertical line
    context.beginPath();
    context.moveTo(margin + (obj.coordinates[0] * whiteboard.getWidth()), obj.coordinates[1] * whiteboard.getHeight());
    context.lineTo(margin + (obj.coordinates[0] * whiteboard.getWidth()), obj.coordinates[3] * whiteboard.getHeight());
    context.stroke();
  }

  this.draw = function (obj) {
    if (obj.diagram === 'ten-frame') {
      drawTenFrame(obj);
    } else if (obj.diagram === 'place-value-mat') {
      drawPlaceValueMat(obj);
    } else if (obj.diagram === 'part-part-whole') {
      drawPartPartWhole(obj);
    } else if (obj.diagram === 'l-diagram') {
      drawLDiagram(obj);
    }
  };
}

DiagramTool.type = 'diagram';

inherit(DiagramTool, BaseWhiteboardTool);
