import { BaseWhiteboardTool, inherit } from './base-tool';
import protractorImage from '../images/Protractor.svg';
import Whiteboard from '../whiteboard';

export default function ProtractorTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  var img = new Image();
  var isLoaded;

  var imagePromise = new Promise(function (resolve) {
    var listener;

    listener = function () {
      img.removeEventListener('load', listener);

      resolve(img);

      isLoaded = true;
    };

    img.addEventListener('load', listener);
  });

  img.src = protractorImage;

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = ProtractorTool.type;
      this.coordinates = [sx, sy, ex, ey];

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isTransformable = function () {
    return false;
  };

  this.isRotatable = function () {
    return true;
  };

  this.draw = function (obj) {
    var x = obj.coordinates[0] * whiteboard.getWidth();
    var y = obj.coordinates[1] * whiteboard.getHeight();
    var width = (obj.coordinates[2] - obj.coordinates[0]) * whiteboard.getWidth();
    var height = (obj.coordinates[3] - obj.coordinates[1]) * whiteboard.getHeight();
    var context = whiteboard.getContext();

    var drawImage = function () {
      context.drawImage(img, x, y, width, height);
    };

    if (isLoaded) {
      drawImage();
    } else {
      imagePromise.then(function () {
        if (obj.rotate) { // whiteboard can't rotate for us when this runs asynchronously
          context.save();
          var bounds = Whiteboard.getEventBounds(obj, whiteboard.getWidth(), whiteboard.getHeight());
          var boundsWidth = bounds.right - bounds.left;
          var boundsHeight = bounds.bottom - bounds.top;
          var deltaX = (bounds.left + (boundsWidth / 2)) * whiteboard.getWidth();
          var deltaY = (bounds.top + (boundsHeight / 2)) * whiteboard.getHeight();
          context.translate(deltaX, deltaY);
          context.rotate(obj.rotate);
          context.translate(-deltaX, -deltaY);
        }

        drawImage();

        if (obj.rotate)
          context.restore();
      });
    }

    drawImage();
  };
}

ProtractorTool.type = 'protractor';

inherit(ProtractorTool, BaseWhiteboardTool);
