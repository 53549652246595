import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import noReportsTpl from './noReports.tpl.html';
import classDetailsTpl from './classDetails.tpl.html';
import moment from 'moment';
import '../contentAssignmentServices';
import 'angular-local-storage';
import './viewAssigned.css';
import '../assignment.css';
import 'src/app/navigation/teachernavbarController';
import 'core/rubric/rubricReflectionDirective';
import ViewContentAssignmentBankController from 'src/app/contentassignment/viewbank/viewContentController';
import 'core/common/cuethinkButtonToggleDirective';
import '../../content/manage/createProblemPopUpDirective';
import 'config';
import '../../../images/assigned-work-reflection-icon.svg';
import { getLocalizedProblemTitle, getLocalizedProblemQuestion, getLocalizedProblemQuestionImage, getLocalizedProblemAnswer, translate } from 'core/common/translation-helpers';
import 'src/images/link.jpg';
import 'src/images/share.jpg';
import 'angular-translate';
import BaseController from '../../../components/shared/base';
import 'core/common/cuethinkPopUpDirective';
import 'src/images/ViewInStudentMode.svg';
import 'src/components/shared/cuethink';
import { setUserModesScopeMembers } from 'core/common/user-modes';
import { setUpHelper } from '../../../components/shared/set-up-helper';
import 'core/helper/helper.directive';
import { Permissions } from 'core/permissions/permissions';

function ViewAssignedController ($scope, $state, TeacherManager, ThinkletManager, UserManager, $controller, $uibModal,
                                 localStorageService, ContentAssignmentServices, $translate, CueThinkConfig, $rootScope,
                                 CueThink, $stateParams, PermissionsManager) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  function editAssignmentProblem (assignment) {
    return ViewContentAssignmentBankController.editProblem($state, assignment.problemId);
  }

  $scope.editAssignmentProblem = editAssignmentProblem;

  $scope.editAssignment = function (assignment, event) {
    $state.go("assign-problems", { id: assignment.id });

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  var _assignmentToDelete;

  $scope.showDeletePopup = function (assignment, event) {
    $('.popover').remove();

    _assignmentToDelete = assignment;

    $scope.viewModel.isDeleteAssignmentPopUpVisible = true;

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  $scope.viewModel = {
    assignments: [],
    classrooms: null,
    dontShowReflectPopUpAgain: false,
    dontShowReflectPopUpKey: 'dontShowReflectPopUp',
    hasReachedEndOfResults: false,
    isClassOpen: false,
    isDeleteAssignmentPopUpVisible: false,
    isDontShowThreeDotsPopUpAgainChecked: true,
    isLinkCopied: false,
    isProblemOpen: false,
    isProblemPopUpVisible: false,
    isReflectionPopUpVisible: false,
    isReflectPopUpVisible: false,
    isSharePopUpVisible: false,
    isThreeDotsPopUpVisible: false,
    mode: null,
    modeToggleOptions: [{ id: 'open', name: '' }, { id: 'closed', name: '' }],
    problems: null,
    seenThreeDotsPopUpKey: 'SeenThreeDots',
    sort: 'endDate',
    sortDirection: 'asc',
    students: null,
    timeFilter: null,
    timeFilters: [
      { name: null, time: { days: -14 } },
      { name: null, time: { months: -1 } },
      { name: null, time: { months: -3 } },
      { name: null, time: { months: -6 } },
      { name: null, time: { years: -1 } },
      { name: null }
    ],
    translations: {
      LAST: null,
      LAST_PLURAL: null
    }
  };

  $scope.viewModel.timeFilter = $scope.viewModel.timeFilters[1];

  translate(['WEEKS', 'MONTH', 'MONTHS', 'YEAR', 'SHOWING_ALL', 'ASSIGNED_PROBLEMS', 'CLOSED_PROBLEMS', 'LAST', 'LAST_PLURAL'], $translate, $rootScope, $scope, function (translations) {
    $scope.viewModel.timeFilters[0].name = '2 ' + translations['WEEKS'];
    $scope.viewModel.timeFilters[1].name = translations['MONTH'];
    $scope.viewModel.timeFilters[2].name = '3 ' + translations['MONTHS'];
    $scope.viewModel.timeFilters[3].name = '6 ' + translations['MONTHS'];
    $scope.viewModel.timeFilters[4].name = translations['YEAR'];
    $scope.viewModel.timeFilters[5].name = translations['SHOWING_ALL'];

    $scope.viewModel.modeToggleOptions[0].name = translations['ASSIGNED_PROBLEMS'];
    $scope.viewModel.modeToggleOptions[1].name = translations['CLOSED_PROBLEMS'];

    $scope.viewModel.translations.LAST = translations.LAST;
    $scope.viewModel.translations.LAST_PLURAL = translations.LAST_PLURAL;
  });

  var _page = -1;
  var _limit = 20;

  $scope.problemFilter = '';

  $scope.isOpenAssignmentsVisible = function () {
    return $scope.viewModel.mode === 'open';
  };

  $scope.deleteAssignment = function () {
    TeacherManager.deleteAssignment(_assignmentToDelete.id).then(function () {
      $scope.viewModel.assignments = $scope.viewModel.assignments.filter(function (assignment) {
        return assignment.id !== _assignmentToDelete.id;
      });

      _assignmentToDelete = null;
    });

    $scope.viewModel.isDeleteAssignmentPopUpVisible = false;
  };

  $scope.getDaysRemaining = function (date) {
    var now = new Date().getTime();
    var then = date.getTime() + (7 * 24 * 60 * 60 * 1000);

    return Math.ceil((then - now) / (24 * 60 * 60 * 1000));
  };

  $scope.isClosing = function (date) {
    var now = new Date();

    if (now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear())
      return false;

    if ($scope.getDaysRemaining(date) < 0)
      return false;

    return now > date;
  };

  $scope.isSoon = function (date) {
    var today = new Date();

    if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
      return true;

    var now = today.getTime();
    var then = date.getTime();

    return then > now && then - now <= 3 * 24 * 60 * 60 * 1000;
  };

  $scope.isFutureAssignment = function (date) {
    var today = new Date();

    if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
      return false;

    var now = today.getTime();
    var then = date.getTime();

    return then > now;
  };

  function getSelectedClasses() {
    if (!$scope.viewModel.classrooms)
      return [];

    return $scope.viewModel.classrooms.filter(function (classroom) {
      return classroom.selected;
    });
  }

  function getSelectedClassIds() {
    return getSelectedClasses().map(function (cls) {
      return cls.id;
    });
  }

  $scope.getSelectedClasses = getSelectedClasses;

  function getSelectedProblems() {
    if (!$scope.viewModel.problems)
      return [];

    return $scope.viewModel.problems.filter(function (problem) {
      return problem.selected;
    });
  }

  function getSelectedProblemIds() {
    return getSelectedProblems().map(function (problem) {
      return problem.id;
    });
  }

  $scope.getSelectedProblems = getSelectedProblems;

  TeacherManager.loadClasses().then(function (classes) {
    $scope.viewModel.classrooms = classes.map(function (cls) {
      return {
        id: cls.id,
        name: cls.name,
        selected: false
      };
    });
  });

  function populateProblemFilter () {
    $scope.viewModel.problems = [];

    TeacherManager.loadFilterProblems($scope.isOpenAssignmentsVisible()).then(function (problems) {
      var result = [];

      problems.forEach(function (problem) {
        var found = result.find(function (p) {
          return p.id === problem.id;
        });

        if (!found) {
          result.push({
            id: problem.id,
            name: problem.title,
            selected: false
          });
        }
      });

      result.sort(function (a, b) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      });

      $scope.viewModel.problems = result;
    });
  }

  populateProblemFilter();

  $scope.onModeToggle = function (mode) {
    $scope.viewModel.mode = mode;

    if (mode === 'closed') {
      $scope.viewModel.classrooms.forEach(function (cls) {
        cls.selected = false;
      });
    }

    $scope.filter();

    populateProblemFilter();
  };

  $scope.$on('select-button-toggle', function (event, option) {
    $scope.onModeToggle(option.id);
  });

  function isBankProblem (problem) {
    if (typeof problem.problemAddToBank !== 'undefined')
      return problem.problemAddToBank;
    else
      return problem.addToBank;
  }

  $scope.isBankProblem = isBankProblem;

  $scope.showMorePopup = function (event, assignment) {
    ThinkletManager.loadProblem(assignment.problemId).then(function (problem) {
      var imgList = getLocalizedProblemQuestionImage($translate, problem);

      $scope.viewModel.problemDetails = {
        title: getLocalizedProblemTitle($translate, problem),
        standards: problem.standard.split(','),
        question: getLocalizedProblemQuestion($translate, problem),
        answer: getLocalizedProblemAnswer($translate, problem),
        imageUrl: imgList && imgList.length > 0 ? ThinkletManager.getMediaURLFromPath(imgList[0]) : null
      };

      $scope.viewModel.isProblemPopUpVisible = true;
    });
  };

  $scope.closeMoreInfoPopup = function () {
    return ViewContentAssignmentBankController.closeMoreInfoPopup($scope);
  };

  $scope.closeDeleteAssignmentPopUp = function () {
    $scope.viewModel.isDeleteAssignmentPopUpVisible = false;
  };

  $scope.toggleMyBankButtons = function () {
    return ViewContentAssignmentBankController.toggleMyBankButtons($scope);
  };

  $scope.editProblem = function (problem) {
    return ViewContentAssignmentBankController.editProblem($state, problem.id);
  };

  $scope.cloneProblem = function (problem) {
    return ViewContentAssignmentBankController.cloneProblem($state, problem.id);
  };

  $scope.assignProblem = function (problem) {
    return ViewContentAssignmentBankController.assignProblem($state, ContentAssignmentServices, problem);
  };

  $scope.getClassesForAssignment = function (assignment) {
    return assignment.classInfo;
  };

  $scope.getStudentsForAssignment = function (assignment) {
    return assignment.studentInfo;
  };

  $scope.showClass = function (assignment, classroom) {
    TeacherManager.loadAssignmentStats(assignment.id, classroom.id).then(function (stats) {
      if (!stats || stats.completedThinklets === undefined) {
        showNoReportsPopup();

        return;
      }

      $uibModal.open({
        animation: false,
        windowClass: 'class-details-window',
        controller: function ($scope) {
          $scope.className = classroom.name;
          $scope.problemName = assignment.name;
          $scope.completedCount = stats.completedThinklets;
          $scope.inProgressCount = stats.inProgressThinklets;
          $scope.notStartedCount = stats.notStartedThinklets;
          $scope.completedStudents = stats.completetThinkletsStudents;
          $scope.inProgressStudents = stats.inProgressThinkletsStudents;
          $scope.notStartedStudents = stats.notStartedThinkletsStudents;

          if (stats.studentNames && stats.studentNames.length > 0)
            $scope.students = stats.studentNames.join(', ');

          $('.class-details-window').show();
        },
        template: classDetailsTpl
      });
    });
  };

  $scope.saveReflection = function (assignment, problem, data, isShared) {
    $scope.closeReflectionPopUp();

    TeacherManager.saveReflection(assignment, problem, data, isShared);

    remindMeLaterAboutReflection();
  };

  $scope.closeReflectionPopUp = function () {
    $scope.viewModel.isReflectionPopUpVisible = false;
  };

  $scope.loadStrategies = TeacherManager.loadReflectionStrategies;

  if (UserManager.getUser().cueteachEnabled) {
    $scope.showReflection = function (assignment) {
      TeacherManager.loadReflections(assignment, function (reflections) {
        ThinkletManager.loadProblem(assignment.problemId).then(function (problem) {
          $scope.viewModel.isReflectionPopUpVisible = true;

          $scope.viewModel.reflection = {
            assignment: assignment,
            problem: problem,
            reflection: reflections.length > 0 ? reflections[0] : null
          };
        });
      });
    };
  }

  function showNoReportsPopup() {
    $uibModal.open({
      template: noReportsTpl,
      animation: false,
      windowClass: 'no-report-dialog'
    });
  }

  function remindMeLaterAboutReflection() {
    localStorageService.set('reflection_remindmelater', new Date().getTime());
  }

  $scope.isExemplar = function (assignment) {
    return assignment.exemplarThinkletId || assignment.isExemplarAssignment;
  };

  if ($scope.user.cueteachEnabled) {
    UserManager.fetchFlag($scope.viewModel.dontShowReflectPopUpKey).then(function (value) {
      if (value)
        return;

      TeacherManager.loadHasIncompleteReflections().then(function (result) {
        if (result)
          $scope.viewModel.isReflectPopUpVisible = true;
      });
    });
  }

  $scope.sort = function (type) {
    if (type === $scope.viewModel.sort) {
      if ($scope.viewModel.sortDirection === 'asc')
        $scope.viewModel.sortDirection = 'desc';
      else
        $scope.viewModel.sortDirection = 'asc';
    } else {
      $scope.viewModel.sort = type;

      if (type === 'problem' || type === 'endDate')
        $scope.viewModel.sortDirection = 'asc';
      else
        $scope.viewModel.sortDirection = 'desc';
    }

    $scope.filter();
  };

  function loadAssignments () {
    var func;

    if ($scope.isOpenAssignmentsVisible())
      func = TeacherManager.loadOpenAssignments;
    else
      func = TeacherManager.loadClosedAssignments;

    var selectedClassIds = getSelectedClassIds();
    var selectedProblemIds = getSelectedProblemIds();

    var time = $scope.viewModel.timeFilter.time;
    var createdAtStart;

    if (time) {
      var now = moment();
      var units = ['days', 'weeks', 'months', 'years'];

      for (var i = 0; i < units.length; i++) {
        var unit = units[i];

        if (time.hasOwnProperty(unit)) {
          createdAtStart = now.subtract(-time[unit], unit).format('YYYY-MM-DD');

          break;
        }
      }
    }

    func(selectedProblemIds, selectedClassIds, createdAtStart, $scope.viewModel.sort ? $scope.viewModel.sort.toLowerCase() : null, $scope.viewModel.sortDirection, _page, _limit).then(function (assignments) {
      $scope.viewModel.assignments = $scope.viewModel.assignments.concat(assignments);

      if (assignments.length < _limit)
        $scope.viewModel.hasReachedEndOfResults = true;
    });
  }

  $scope.filter = function () {
    _page = 0;
    $scope.viewModel.hasReachedEndOfResults = false;
    $scope.viewModel.assignments.splice(0);

    loadAssignments();
  };

  $scope.loadMoreAssignments = function () {
    _page++;

    loadAssignments();
  };

  $scope.filterByTime = function (time, event) {
    $scope.viewModel.timeFilter = time;

    $scope.filter();

    event.stopPropagation();
    event.preventDefault();

    return true;
  };

  $scope.isAssignmentOwner = function (assignment) {
    return assignment.createdBy === $scope.user.id;
  };

  $scope.canEditAssignmentProblem = function (assignment) {
    return assignment.assignmentVersion && !$scope.isExemplar(assignment) && !isBankProblem(assignment);
  };

  $scope.canEditAssignment = function (assignment) {
    return !$scope.isExemplar(assignment);
  };

  $scope.canDeleteAssignment = function (assignment) {
    return !!assignment.assignmentVersion;
  };

  $scope.closeReflectPopUp = function () {
    $scope.viewModel.isReflectPopUpVisible = false;
  };

  $scope.saveDontShowReflectPopUpAgain = function () {
    UserManager.saveFlag($scope.viewModel.dontShowReflectPopUpKey);
  };

  $scope.getShareUrl = function (assignment) {
    return window.location.protocol + '//' + window.location.host + '/#/startassignment/' + assignment.id;
  };

  $scope.openSharePopUp = function (assignment, event) {
    $scope.viewModel.isLinkCopied = false;
    $scope.viewModel.shareAssignment = assignment;

    $scope.$$postDigest(function () {
      var shareButton = $('.google-classroom-btn');

      gapi.sharetoclassroom.render(shareButton[0], { itemtype: 'assignment', size: 30, title: assignment.name, url: $scope.getShareUrl(assignment) });
    });

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  $scope.closeSharePopUp = function () {
    $scope.viewModel.shareAssignment = null;
  };

  $scope.onClickShareCopyButton = function () {
    var copyText = $('.share-pop-up input')[0];

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand('copy');

    $scope.viewModel.isLinkCopied = true;
  };

  var CLOSING_IN_X_DAYS;

  translate('CLOSING_IN_X_DAYS', $translate, $rootScope, $scope, function (translation) {
    CLOSING_IN_X_DAYS = translation;
  });

  $scope.getClosingDaysLabel = function (assignment) {
    return CLOSING_IN_X_DAYS.replace('__DAYS__', $scope.getDaysRemaining(assignment.endDate));
  };

  $scope.getTimeLabel = function (time) {
    if (time.time) {
      var timeValue = Object.values(time.time)[0];

      return (Math.abs(timeValue) === 1 ? $scope.viewModel.translations.LAST : $scope.viewModel.translations.LAST_PLURAL) + ' ' + time.name;
    } else {
      return time.name;
    }
  };

  $scope.fetchHasSeenLanguagePopUp = UserManager.fetchHasSeenLanguagePopUp;

  $scope.viewProblem = function (assignment, event) {
    ThinkletManager.loadProblem(assignment.problemId).then(function (problem) {
      ThinkletManager.createThinklet(problem, assignment).then(function (thinklet) {
        BaseController.navigateToWorkOnThinklet($state, $scope.user, thinklet);
      });
    });

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    CueThink.logEvent('View Assignment In Student Mode', assignment.id, assignment.problemId, null, assignment.name);
  };

  setUserModesScopeMembers($scope);

  $scope.onThreeDotsPopUpClose = function () {
    $scope.viewModel.isThreeDotsPopUpVisible = false;
  };

  $scope.onThreeDotsPopUpSubmit = function () {
    $scope.viewModel.isThreeDotsPopUpVisible = false;
  };

  $scope.saveDontShowThreeDotsPopUpAgain = function () {
    UserManager.saveFlag($scope.viewModel.seenThreeDotsPopUpKey);
  };

  $scope.isActionMenuEnabled = function () {
    return PermissionsManager.getPermission(Permissions.AssignedWorkContextMenu);
  };

  if ($scope.isActionMenuEnabled()) {
    UserManager.fetchFlag($scope.viewModel.seenThreeDotsPopUpKey).then(function (result) {
      if (!result)
        $scope.viewModel.isThreeDotsPopUpVisible = true;
    });
  }

  if ($stateParams.showHelper) {
    const showHelper = setUpHelper($scope, 'SOLVE_IT', 'HOW_WILL_YOUR_STUDENTS_SOLVE_A_PROBLEM_TRY_IT_YOURSELF_USING_DIFFERENT_SOLVE_PHASE_TOOLS_CLICK_HERE_TO_LEARN_WHY', true);

    showHelper();
  }
}

export default ViewAssignedController;
