import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import 'src/app/navigation/teachernavbarController';
import '../contentAssignmentServices';
import './viewAllProblemsca.css';
import 'core/tutorial/tutorial';
import 'core/devicedetect/deviceDetectionUtil.js';
import 'config';
import _ from 'underscore';
import 'core/common/cuethinkPopUpDirective';
import 'core/common/renderMathService';
import './filterContent/filterContentDirective';
import languages from 'src/app/languages';
import 'src/images/Logo_Icon.svg';
import 'src/images/MyProblems.svg';
import 'src/images/origo.svg';
import 'src/images/SharedProblems.svg';
import 'src/images/Share.svg';
import 'src/images/X.png';
import 'src/images/spinner.gif';
import 'angular-translate';
import {
  getLocalizedProblemAnswer,
  getLocalizedProblemAnswerImage,
  getLocalizedProblemQuestion,
  getLocalizedProblemQuestionImage,
  getLocalizedProblemTitle,
  translate
} from 'core/common/translation-helpers';
import 'src/images/google-classroom.svg';
import 'src/images/DownArrow.svg';
import 'src/images/UpArrow.svg';
import 'src/images/CRCD.svg';
import 'core/common/cuethinkButtonToggleDirective';
import 'src/images/im-icon.svg';
import 'src/images/LZ icon.png';
import 'src/images/ilc.png';
import permissionsManager, { Permissions } from 'core/permissions/permissions';

function ViewContentAssignmentBankController ($scope, $state, TeacherManager, ThinkletManager, UserManager, CueThinkConfig, $controller, $q, ContentAssignmentServices, $timeout, $stateParams, deviceDetection, renderMath, $translate, $rootScope) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  var editedProblemId = $stateParams.problemId;
  var initialProblemId = $stateParams.id;
  var _origoGrade, _origoModule;
  var _imGrade;
  var _learnZillionGrade, _learnZillionUnit;
  var loadingInitialProblem, _loadedInitialProblems;

  if (!initialProblemId)
    initialProblemId = editedProblemId;

  var _initialProblem;
  var _searchDebouncer = new Debouncer(400);

  var sortByList = [
    { id: 'name', label: null },
    { id: 'date', label: null }
  ];

  translate(['NAME', 'MOST_RECENT'], $translate, $rootScope, $scope, function (translations) {
    sortByList[0].name = translations.NAME;
    sortByList[1].name = translations['MOST_RECENT'];
  });

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  $scope.viewModel = {
    createFolder: null,
    deleteFolder: null,
    dontShowDeleteFolderProblemPromptAgain: true,
    filter: {
      searchTerm: null,
      source: editedProblemId ? 'mine' : 'cuethink',
      grades: [],
      domains: [],
      languages: [],
      standards: [],
      standardTypes: [],
      sort: sortByList[0],
      sortDirection: 'asc'
    },
    folderName: null,
    getSharedUsers: null,
    imFolders: [
      {
        id: 6,
        name: 'Grade 6'
      },
      {
        id: 7,
        name: 'Grade 7'
      },
      {
        id: 8,
        name: 'Grade 8'
      }
    ],
    isCueteachEnabled: UserManager.getUser().cueteachEnabled,
    isCulturalRelevanceBoxOpen: false,
    isDeleteFolderProblemPromptVisible: false,
    isDeleteFolderPromptVisible: false,
    isDeleteProblemPromptVisible: false,
    isFolderPromptVisible: false,
    isLanguagePopUpVisible: false,
    isLearnZillionFoldersVisible: false,
    isLimitedAccessPopupVisible: false,
    isLoading: true,
    isIMFoldersVisible: false,
    isORIGOFoldersVisible: false,
    isRemoveProblemPromptVisible: false,
    isRenamingFolder: false,
    isTutorialPopUpVisible: false,
    languages: languages,
    learnZillionFolders: [
      {
        id: '3',
        name: 'Grade 3',
        folders: [
          { id: 1, name: 'Unit 1' },
          { id: 2, name: 'Unit 2' },
          { id: 3, name: 'Unit 3' },
          { id: 4, name: 'Unit 4' },
          { id: 5, name: 'Unit 5' },
          { id: 6, name: 'Unit 6' },
          { id: 7, name: 'Unit 7' },
          { id: 8, name: 'Unit 8' }
        ]
      },
      {
        id: '4',
        name: 'Grade 4',
        folders: [
          { id: 1, name: 'Unit 1' },
          { id: 2, name: 'Unit 2' },
          { id: 3, name: 'Unit 3' },
          { id: 4, name: 'Unit 4' },
          { id: 5, name: 'Unit 5' },
          { id: 6, name: 'Unit 6' },
          { id: 7, name: 'Unit 7' },
          { id: 8, name: 'Unit 8' },
          { id: 9, name: 'Unit 9' }
        ]
      },
      {
        id: '5',
        name: 'Grade 5',
        folders: [
          { id: 1, name: 'Unit 1' },
          { id: 2, name: 'Unit 2' },
          { id: 3, name: 'Unit 3' },
          { id: 4, name: 'Unit 4' },
          { id: 5, name: 'Unit 5' },
          { id: 6, name: 'Unit 6' },
          { id: 7, name: 'Unit 7' },
          { id: 8, name: 'Unit 8' },
          { id: 9, name: 'Unit 9' }
        ]
      },
      {
        id: '6',
        name: 'Grade 6',
        folders: [
          { id: 1, name: 'Unit 1' },
          { id: 2, name: 'Unit 2' },
          { id: 3, name: 'Unit 3' },
          { id: 4, name: 'Unit 4' },
          { id: 6, name: 'Unit 6' },
          { id: 7, name: 'Unit 7' },
          { id: 8, name: 'Unit 8' },
          { id: 9, name: 'Unit 9' }
        ]
      }
    ],
    loadingMyProblems: false,
    mediaUrl: CueThinkConfig.mediaURL,
    openedFolder: null,
    openSharePrompt: null,
    origoFolders: [
      {
        id: 'K',
        name: 'Grade K',
        folders: [
          /*{ id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },*/
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 1,
        name: 'Grade 1',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 2,
        name: 'Grade 2',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 3,
        name: 'Grade 3',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 4,
        name: 'Grade 4',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 5,
        name: 'Grade 5',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      },
      {
        id: 6,
        name: 'Grade 6',
        folders: [
          { id: 1, name: 'Module 1' },
          { id: 2, name: 'Module 2' },
          { id: 3, name: 'Module 3' },
          { id: 4, name: 'Module 4' },
          { id: 5, name: 'Module 5' },
          { id: 6, name: 'Module 6' },
          { id: 7, name: 'Module 7' },
          { id: 8, name: 'Module 8' },
          { id: 9, name: 'Module 9' },
          { id: 10, name: 'Module 10' },
          { id: 11, name: 'Module 11' },
          { id: 12, name: 'Module 12' }
        ]
      }
    ],
    reflections: null,
    reflectionsVisible: false,
    removeFolderProblem: null,
    renameFolder: null,
    rootFolder: null,
    selectedLanguage: $translate.use(),
    selectedProblem: null,
    sortedSelectedProblemStandards: null,
    useSavedFilter: !editedProblemId
  };

  $scope.user = UserManager.getUser();

  if (initialProblemId) {
    loadingInitialProblem = true;

    loadProblem(initialProblemId, $stateParams.state).then(function (problem) {
      _initialProblem = problem;

      var source;

      if (problem.creatorName === $scope.user.username)
        source = 'mine';
      else if (problem.creatorName === 'ORIGO Education')
        source = 'origo';
      else if (problem.creatorName === 'Illustrative Mathematics')
        source = 'im';
      else if (problem.creatorName === 'LearnZillion' || problem.creatorName === 'IL-IM')
        source = 'learnzillion';
      else if (problem.sharedWithCurrentUser)
        source = 'shared';
      else
        source = 'cuethink';

      setSource(source);

      if (insertInitialProblem(problem))
        $scope.selectProblem(problem);

      loadingInitialProblem = false;
    }).catch(function () {
      openLimitedAccessPopup();

      loadingInitialProblem = false;
    });
  }

  $scope.isDesktop = deviceDetection.isDesktop();

  function clearOrigoSelection () {
    $scope.viewModel.isORIGOFoldersVisible = false;

    $scope.viewModel.origoFolders.forEach(function (folder) {
      folder.isOpen = false;

      folder.folders.forEach(function (f) {
        f.isOpen = false;
      });
    });

    _origoGrade = _origoModule = null;
  }

  function clearIMSelection () {
    $scope.viewModel.isIMFoldersVisible = false;

    $scope.viewModel.imFolders.forEach(function (folder) {
      folder.isOpen = false;
    });

    _imGrade = null;
  }

  function clearLearnZillionSelection () {
    $scope.viewModel.isLearnZillionFoldersVisible = false;

    $scope.viewModel.learnZillionFolders.forEach(function (folder) {
      folder.isOpen = false;
    });

    _learnZillionGrade = null;
    _learnZillionUnit = null;
  }

  function setSource (value) {
    $scope.viewModel.openedFolder = null;
    $scope.viewModel.filter.source = value;

    if (localStorage !== 'undefined') {
      if (value)
        localStorage.setItem('problem-bank-source', value);
      else
        localStorage.removeItem('problem-bank-source');
    }
  }

  $scope.selectSource = function (source) {
    _initialProblem = null;

    var changed = source != $scope.viewModel.filter.source;

    $scope.viewModel.filter.source = null;

    digest();

    clearOrigoSelection();
    clearIMSelection();
    clearLearnZillionSelection();

    setSource(source);

    if (source === 'origo') {
      if (changed)
        $scope.viewModel.isORIGOFoldersVisible = true;
      else
        $scope.viewModel.isORIGOFoldersVisible = !$scope.viewModel.isORIGOFoldersVisible;
    } else if (source === 'im') {
      if (changed)
        $scope.viewModel.isIMFoldersVisible = true;
      else
        $scope.viewModel.isIMFoldersVisible = !$scope.viewModel.isIMFoldersVisible;
    } else if (source === 'learnzillion') {
      if ($scope.viewModel.setGrades)
        $scope.viewModel.setGrades([]);

      if (changed)
        $scope.viewModel.isLearnZillionFoldersVisible = true;
      else
        $scope.viewModel.isLearnZillionFoldersVisible = !$scope.viewModel.isLearnZillionFoldersVisible;
    } else {
      $scope.viewModel.isORIGOFoldersVisible = false;
      $scope.viewModel.isIMFoldersVisible = false;
      $scope.viewModel.isLearnZillionFoldersVisible = false;
    }
  };

  if (!editedProblemId && typeof localStorage !== 'undefined') {
    var source = localStorage.getItem('problem-bank-source');

    if (source) {
      $scope.selectSource(source);

      if (source === 'origo') {
        setSource(source);

        var origoFolders = localStorage.getItem('problem-bank-origo-folder');

        if (origoFolders) {
          try {
            origoFolders = JSON.parse(origoFolders);

            var gradeFolder = $scope.viewModel.origoFolders.find(function (folder) {
              return folder.id === origoFolders.grade;
            });

            if (gradeFolder) {
              gradeFolder.isOpen = true;

              setTimeout(function () {
                $scope.viewModel.setGrades([origoFolders.grade]);
              });

              var moduleFolder = gradeFolder.folders.find(function (folder) {
                return folder.id === origoFolders.module;
              });

              if (moduleFolder)
                moduleFolder.isOpen = true;
            }
          } catch (e) {
          }
        }
      } else if (source === 'im') {
        setSource(source);

        var imFolders = localStorage.getItem('problem-bank-im-folders');

        if (imFolders) {
          try {
            imFolders = JSON.parse(imFolders);

            var gradeFolder = $scope.viewModel.imFolders.find(function (folder) {
              return folder.id === imFolders.grade;
            });

            if (gradeFolder) {
              gradeFolder.isOpen = true;

              setTimeout(function () {
                $scope.viewModel.setGrades([imFolders.grade]);
              });
            }
          } catch (e) {
          }
        }
      } else if (source === 'learnzillion') {
        setSource(source);

        var learnZillionFolders = localStorage.getItem('problem-bank-lz-folders');

        if (learnZillionFolders) {
          try {
            learnZillionFolders = JSON.parse(learnZillionFolders);

            var gradeFolder = $scope.viewModel.learnZillionFolders.find(function (folder) {
              return folder.id === learnZillionFolders.grade;
            });

            if (gradeFolder) {
              gradeFolder.isOpen = true;

              setTimeout(function () {
                $scope.viewModel.setGrades([learnZillionFolders.grade]);
              });
            }
          } catch (e) {
          }
        }
      }
    } else {
      $scope.viewModel.isLoading = false;
      $scope.viewModel.loadingMyProblems = false;
    }
  }

  var _problemBank;

  var _hasReachedEndOfResults, _page = 0;

  function getLanguageIds() {
    return _.pluck($scope.viewModel.filter.languages, 'id');
  }

  function getGradeLabels() {
    return _.pluck($scope.viewModel.filter.grades, 'label');
  }

  function getStandardTypes() {
    return _.pluck($scope.viewModel.filter.standardTypes, 'state');
  }

  function getDomainIds() {
    return _.uniq(_.pluck($scope.viewModel.filter.domains, 'standard'));
  }

  function getStandardIds() {
    return _.pluck($scope.viewModel.filter.standards, 'standard');
  }

  $scope.getProblemsTitle = function () {
    if ($scope.viewModel.openedFolder)
      return $translate.instant($scope.viewModel.openedFolder.name);
    else if ($scope.viewModel.filter.source === 'all')
      return $translate.instant('ALL_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'cuethink')
      return $translate.instant('CUETHINK_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'mine')
      return $translate.instant('MY_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'shared')
      return $translate.instant('SHARED_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'origo')
      return $translate.instant('ORIGO_EDUCATION_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'im')
      return $translate.instant('ILLUSTRATIVE_MATHEMATICS_PROBLEMS');
    else if ($scope.viewModel.filter.source === 'learnzillion')
      return $translate.instant('IMAGINE_LEARNING_IM_K_12_PROBLEMS');

    return null;
  };

  function sortProblems(problems) {
    var sort;

    if ($scope.viewModel.filter.sort.id === 'name') {
      sort = function (a, b) {
        var result;

        if (a.title < b.title)
          result = -1;
        else if (a.title > b.title)
          result = 1;
        else
          result = 0;

        if ($scope.viewModel.filter.sortDirection == 'desc')
          result *= -1;

        return result;
      };
    } else if ($scope.viewModel.filter.sort.id === 'date') {
      sort = function (a, b) {
        var result = b.createAt - a.createAt;

        if ($scope.viewModel.filter.sortDirection == 'desc')
          result *= -1;

        return result;
      };
    }

    problems.sort(sort);

    return problems;
  }

  $scope.infiniteScrollHandler = function () {
    $scope.generateMoreProblems();
  };

  function insertInitialProblem (problem) {
    if (!problem || !_problemBank || _problemBank[0] === problem)
      return false;

    // open origo or IM folder
    if (problem.folder.type === 'ORIGO') {
      $scope.viewModel.filter.source = 'origo';

      _origoGrade = problem.grade;
      _origoModule = problem.moduleId;

      $scope.viewModel.origoFolders.forEach(function (folder) {
        if (folder.id == _origoGrade) {
          folder.isOpen = true;

          folder.folders.forEach(function (subfolder) {
            if (subfolder.id == _origoModule) {
              subfolder.isOpen = true;
            } else {
              subfolder.isOpen = false;
            }
          });
        } else {
          folder.isOpen = false;
        }
      });
    } else if (problem.folder.type === 'IM') {
      $scope.viewModel.filter.source = 'im';

      _imGrade = problem.grade;

      $scope.viewModel.imFolders.forEach(function (folder) {
        if (folder.id == _imGrade) {
          folder.isOpen = true;
        } else {
          folder.isOpen = false;
        }
      });
    } else if (problem.folder.type === 'LZ') {
      $scope.viewModel.filter.source = 'learnzillion';

      _learnZillionGrade = problem.grade;

      $scope.viewModel.learnZillionFolders.forEach(function (folder) {
        if (folder.id == _learnZillionGrade) {
          folder.isOpen = true;
        } else {
          folder.isOpen = false;
        }
      });
    }

    var index = _.findIndex(_problemBank, function (problem) {
      return problem.id === problem.id;
    });

    if (index !== -1)
      _problemBank.splice(index, 1);

    _problemBank.unshift(problem);

    return true;
  }

  function handleProblems (filteredProblems) {
    if (!_problemBank)
      _problemBank = [];

    if (!filteredProblems || !Array.isArray(filteredProblems) || filteredProblems.length === 0) {
      _hasReachedEndOfResults = true;
    } else {
      filteredProblems.forEach(function (problem, i) {
        if (_loadedInitialProblems || problem.id != initialProblemId)
          _problemBank.push(problem);
      });
    }

    if (!$scope.viewModel.selectedProblem) {
      if (_initialProblem)
        insertInitialProblem(_initialProblem);

      if (_problemBank.length > 0 && !loadingInitialProblem)
        $scope.selectProblem(_problemBank[0]);
    }

    $scope.viewModel.loadingMyProblems = false;

    $scope.viewModel.isLoading = false;

    digest();

    if (!_loadedInitialProblems)
      _loadedInitialProblems = true;
  }

  function getGradeIds() {
    var grades = getGradeLabels();

    var i = grades.indexOf(9);

    if (i !== -1)
      grades.splice(i, 1, 'HS');

    return grades;
  }

  var _promiseQueue = new PromiseQueue(handleProblems);
  _promiseQueue.start();

  $scope.$on("$destroy", function() {
    _promiseQueue.stop();
    _promiseQueue = null;
  });

  $scope.generateMoreProblems = function () {
    if (_hasReachedEndOfResults || !$scope.viewModel.filter.source)
      return;

    $scope.viewModel.loadingMyProblems = true;

    var limit = 24;

    var term = $scope.viewModel.filter.searchTerm;
    var languageIds = getLanguageIds();
    var grades = getGradeIds();
    var domainIds = getDomainIds();
    var standardIds = getStandardIds();
    var standardTypes = getStandardTypes();
    var sort = $scope.viewModel.filter.sort.id;
    var sortDirection = $scope.viewModel.filter.sortDirection;

    var func;

    if ($scope.viewModel.filter.source === 'mine')
      func = TeacherManager.loadTeacherProblems;
    else if ($scope.viewModel.filter.source === 'cuethink')
      func = TeacherManager.loadTeacherBankProblems;
    else if ($scope.viewModel.filter.source === 'shared')
      func = TeacherManager.loadTeacherSharedProblems;
    else if ($scope.viewModel.filter.source === 'all')
      func = TeacherManager.loadTeacherAllProblems;
    else if ($scope.viewModel.filter.source === 'origo') {
      func = function () {
        var args = [];

        for (var i = 0; i < arguments.length; i++)
          args.push(arguments[i]);

        var gradeIds;

        if (_origoGrade)
          gradeIds = [_origoGrade];
        else
          gradeIds = getGradeIds();

        args[2] = gradeIds;
        args.splice(3, 0, _origoModule);

        args.push($translate.use());

        return TeacherManager.loadOrigoProblems.apply(null, args);
      };
    } else if ($scope.viewModel.filter.source === 'im') {
      func = function () {
        var args = [];

        for (var i = 0; i < arguments.length; i++)
          args.push(arguments[i]);

        var gradeIds;

        if (_imGrade)
          gradeIds = [_imGrade];
        else
          gradeIds = getGradeIds();

        args[2] = gradeIds;

        args.push($translate.use());

        return TeacherManager.loadIMProblems.apply(null, args);
      };
    } else if ($scope.viewModel.filter.source === 'learnzillion') {
      func = function () {
        var args = [];

        for (var i = 0; i < arguments.length; i++)
          args.push(arguments[i]);

        var gradeIds;

        if (_learnZillionGrade)
          gradeIds = [_learnZillionGrade];
        else
          gradeIds = getGradeIds();

        args[2] = gradeIds;
        args.splice(3, 0, _learnZillionUnit);

        args.push($translate.use());

        return TeacherManager.loadLearnZillionProblems.apply(null, args);
      };
    }

    var promise = func(
      term,
      languageIds,
      grades,
      standardTypes,
      domainIds,
      standardIds,
      _page,
      limit,
      sort,
      sortDirection,
      $translate.use()
    );

    _promiseQueue.push(promise);

    _page++;
  };

  function generateProblems () {
    _promiseQueue.clear();

    _problemBank = null;

    $scope.viewModel.selectedProblem = null;

    _page = 0;

    _hasReachedEndOfResults = false;

    $scope.generateMoreProblems();
  }

  function removeFileFromAllFolders(file, rootFolder) {
    for (var i = rootFolder.files.length - 1; i >= 0; i--)
      if (rootFolder.files === file)
        rootFolder.files.splice(i, 1);

    rootFolder.folders.forEach(function (folder) {
      removeFileFromAllFolders(file, folder);
    });
  }

  function deleteProblem() {
    ThinkletManager.deleteMyProblemBankProblems([$scope.viewModel.selectedProblem], function (data) {
      if (data.succeed) {
        _problemBank = ViewContentAssignmentBankController.filterById([$scope.viewModel.selectedProblem.id], _problemBank);
        _folderProblems = ViewContentAssignmentBankController.filterById([$scope.viewModel.selectedProblem.id], _folderProblems);

        removeFileFromAllFolders($scope.viewModel.selectedProblem.id, $scope.viewModel.rootFolder);
        saveFolders();

        var problems = $scope.getProblems();

        if (problems.length > 0)
          $scope.selectProblem(problems[0]);
        else
          $scope.selectProblem();

        $scope.$apply();
      } else {
        alert($translate.instant('SORRY_WE_COULDNT_DELETE_THE_PROBLEM'));
      }
    });
  }

  $scope.confirmDeleteProblem = function () {
    $scope.closeDeleteProblemPrompt();

    if (typeof localStorage !== 'undefined') {
      var show = localStorage.getItem('showDeleteFolderProblemPrompt');

      if (show && JSON.parse(show) === false) {
        deleteProblem();

        return;
      }
    }

    deleteProblem();
  };

  $scope.goToCreateNewProblem = function () {
    $state.go('create-new-problem');
  };

  $scope.onSortMyProblems = function () {
    $scope.viewModel.myProblemSortAsc = !$scope.viewModel.myProblemSortAsc;

    generateProblems();
  };

  var _changingLanguage = false;

  var unsubscribe = $rootScope.$on('$translateChangeSuccess', function () {
    _changingLanguage = true;

    setTimeout(function () {
      _changingLanguage = false;
    }, 1000);

    $scope.viewModel.selectedLanguage = $translate.use();

    generateProblems();
  });

  $scope.$on("$destroy", function() {
    unsubscribe();
  });

  $scope.$watch('viewModel.filter', function () {
    if (!_changingLanguage && !$scope.viewModel.openedFolder) {
      _searchDebouncer.run(function () {
        $timeout(function () {
          generateProblems();
        });
      });
    }
  }, true);

  $scope.toggleMyBankButtons = function () {
    return ViewContentAssignmentBankController.toggleMyBankButtons($scope);
  };

  $scope.cloneProblem = function () {
    var standardTypes = getStandardTypes();
    var standardType;

    if (standardTypes.length > 0)
      standardType = standardTypes[0];

    ViewContentAssignmentBankController.cloneProblem($state, $scope.viewModel.selectedProblem.id, standardType);
  };

  $scope.editProblem = function () {
    var standardTypes = getStandardTypes();
    var standardType;

    if (standardTypes.length > 0)
      standardType = standardTypes[0];

    return ViewContentAssignmentBankController.editProblem($state, $scope.viewModel.selectedProblem.id, standardType);
  };

  $scope.assignProblem = function () {
    ViewContentAssignmentBankController.assignProblem($state, ContentAssignmentServices, $scope.viewModel.selectedProblem, $stateParams.addAssignmentProblems);
  };

  $scope.showDeletePrompt = function () {
    if ($scope.viewModel.openedFolder) {
      $scope.viewModel.isRemoveProblemPromptVisible = true;
    } else {
      if (typeof localStorage !== 'undefined') {
        var show = localStorage.getItem('showDeleteFolderProblemPrompt');

        if (show)
          show = JSON.parse(show);
        else
          show = true;

        if (show) {
          TeacherManager.loadProblemHasFolders($scope.viewModel.selectedProblem).then(function (result) {
            if (result)
              showDeleteFolderProblemPrompt();
            else
              showDeleteProblemPrompt();
          });
        } else {
          showDeleteProblemPrompt();
        }
      }
    }
  };

  function showDeleteProblemPrompt() {
    $scope.viewModel.isDeleteProblemPromptVisible = true;
  }

  $scope.canShareProblem = function (problem) {
    return !isProblemInCueThinkBank(problem) && !problem.detail && problem.questionSource === 103 && !problem.sharedWithCurrentUser && $scope.viewModel.isCueteachEnabled;
  };

  $scope.shareProblem = function () {
    $scope.viewModel.openSharePrompt();
  };

  $scope.showReflections = function () {
    $scope.viewModel.reflectionsVisible = true;
  };

  $scope.hideReflections = function () {
    $scope.viewModel.reflectionsVisible = false;
  };

  $scope.closeDeleteProblemPrompt = function () {
    $scope.viewModel.isDeleteProblemPromptVisible = false;
  };

  $scope.closeRemoveProblemPrompt = function () {
    $scope.viewModel.isRemoveProblemPromptVisible = false;
  };

  $scope.getGradeLabel = function (grade) {
    if (grade == 0)
      grade = 'K';
    else if (grade == 9)
      grade = 'HS';

    return grade;
  };

  TeacherManager.loadProblemFolders().then(function (root) {
    if (root) {
      $scope.viewModel.rootFolder = root;
    } else {
      const defaultFolders = _.clone(CueThinkConfig.defaultFolders);

      if (!$scope.isCuethinkV2User()) {
        const gettingStartedFolder = defaultFolders.find(folder => folder.name === 'GETTING_STARTED');

        gettingStartedFolder.files = gettingStartedFolder.files.filter(file => file !== 10753);
      }

      $scope.viewModel.rootFolder = { folders: defaultFolders, files: [] };
    }
  });

  function bindEvent(element, name, handler) {
    var callback;

    var unbind = function () {
      element.off(name, callback);
    };

    callback = function (event) {
      var result = handler(event);

      if (result === false)
        unbind();
    };

    element.on(name, callback);
    element.on('remove', unbind);
  }

  var _folderProblems;

  $scope.toggleFolderMenu = function () {
    $scope.viewModel.isFolderMenuOpen = !$scope.viewModel.isFolderMenuOpen;

    if ($scope.viewModel.isFolderMenuOpen) {
      setTimeout(function () {
        bindEvent($('body'), 'click', function () {
          $scope.viewModel.isFolderMenuOpen = false;

          digest();

          return false;
        });
      });
    }
  };

  $scope.openFolder = function (folder) {
    clearOrigoSelection();
    clearIMSelection();
    clearLearnZillionSelection();

    $scope.viewModel.openedFolder = folder;

    if (folder) {
      $scope.viewModel.selectedProblem = null;
      _folderProblems = [];

      if (folder.files.length > 0) {
        TeacherManager.loadFolderProblems(folder.files).then(function (problems) {
          _folderProblems = problems;

          if (problems.length > 0)
            $scope.selectProblem($scope.getProblems()[0]);
          else
            $scope.selectProblem();
        });
      }
    } else {
      generateProblems();
    }
  };

  function saveFolders() {
    TeacherManager.saveProblemFolders($scope.viewModel.rootFolder);
  }

  $scope.showCreateFolderPrompt = function () {
    $scope.viewModel.isFolderMenuOpen = false;
    $scope.viewModel.isRenamingFolder = false;
    $scope.viewModel.folderName = null;

    $scope.viewModel.isFolderPromptVisible = true;
  };

  $scope.showRenameFolderPrompt = function () {
    $scope.viewModel.isFolderMenuOpen = false;
    $scope.viewModel.isRenamingFolder = true;
    $scope.viewModel.folderName = $scope.viewModel.openedFolder.name;

    $scope.viewModel.isFolderPromptVisible = true;
  };

  $scope.onFolderPromptKeyDown = function (event) {
    if (event.keyCode === 13)
      $scope.submitFolderPrompt();
  };

  $scope.submitFolderPrompt = function () {
    $scope.cancelFolderPrompt();

    if (!$scope.viewModel.folderName || $scope.viewModel.folderName.length === 0)
      return;

    var func;

    if ($scope.viewModel.isRenamingFolder)
      func = $scope.viewModel.renameFolder;
    else
      func = $scope.viewModel.createFolder;

    func($scope.viewModel.folderName);

    saveFolders();
  };

  $scope.cancelFolderPrompt = function () {
    $scope.viewModel.isFolderPromptVisible = false;
  };

  $scope.removeFolder = function () {
    $scope.viewModel.isFolderMenuOpen = false;

    $scope.viewModel.deleteFolder();

    saveFolders();
  };

  $scope.onFoldersChange = function () {
    saveFolders();
  };

  $scope.shareFolder = function () {
    // TODO
  };

  $scope.renderDragProblem = function (event) {
    return $('<div>').text($(event.target).find('.problemTitle').text());
  };

  $scope.getSelectedProblemStandards = function () {
    var result;

    result = $scope.viewModel.selectedProblem.standard;

    if (!result)
      result = $scope.viewModel.selectedProblem.ccssId;

    if (result)
      result = result.split(/, ?/);

    return result;
  };

  function loadProblem (id, state) {
    if (!state)
      state = getStandardTypes()[0];

    if (state) {
      return ThinkletManager.loadStateProblem(id, state);
    } else {
      var deferred = $q.defer();

      var interval;

      interval = setInterval(function () {
        state = getStandardTypes()[0];

        if (state) {
          clearInterval(interval);

          ThinkletManager.loadStateProblem(id, state).then(function (problem) {
            deferred.resolve(problem);
          });
        }
      }, 500);

      return deferred.promise;
    }
  }

  function renderMathML (el) {
    el.hide();

    $timeout(function () {
      renderMath.renderElements([el[0]], function () {
        el.show();
      });
    });
  }

  function renderProblemMathML (problem, propName, el) {
    var questionEl = $('<span>' + problem[propName] + '</span>');

    questionEl.find('img').each(function (i, img) {
      img = $(img);

      var mathML = img.attr('alt');

      if (mathML) {
        mathML = atob(mathML);

        if (mathML)
          img.replaceWith(mathML);
      }
    });

    problem[propName] = questionEl.html();

    renderMathML(el);
  }

  function renderQuestionMathML(problem, el) {
    renderProblemMathML(problem, 'question', el);
  }

  function renderAnswerMathML(problem, el) {
    renderProblemMathML(problem, 'answer', el);
  }

  function presentProblemDetails(problem) {
    renderQuestionMathML(problem, $('.question:first'));
    renderAnswerMathML(problem, $('.answer:first'));

    $scope.viewModel.selectedProblem = problem;

    renderMathML($('.answer:first'));

    $scope.viewModel.sortedSelectedProblemStandards = _.sortBy($scope.getSelectedProblemStandards(), function (item) {
      return item;
    });

    $scope.viewModel.reflectionsVisible = false;

    if ($scope.viewModel.isCueteachEnabled) {
      TeacherManager.loadReflectionsForProblem(problem, function (reflections) {
        ViewContentAssignmentBankController.processReflections(reflections);

        $scope.viewModel.reflections = reflections;

        digest();
      });
    }

    if (isProblemInCueThinkBank(problem) || problem.creatorName === 'IL-IM')
      $state.go('.', { id: problem.id }, { notify: false });
    else
      $state.go('.', { id: null }, { notify: false });
  }

  function isProblemInCueThinkBank (problem) {
    return problem.addToBank || problem.folder.type === 'ORIGO' || problem.licenseType;
  }

  // mark as selected and load problem details
  $scope.selectProblem = function (problem, state) {
    if ($scope.viewModel.selectedProblem && $scope.viewModel.selectedProblem.isCrtProblem)
      $scope.viewModel.isCulturalRelevanceBoxOpen = true;

    if ($scope.viewModel.selectedProblem && $scope.viewModel.selectedProblem.id === problem.id)
      problem = null;

    if (problem) {
      if (problem === _initialProblem)
        presentProblemDetails(problem);
      else
        loadProblem(problem.id, state).then(presentProblemDetails);
    } else {
      $scope.viewModel.selectedProblem = null;
    }
  };

  $scope.removeProblemFromFolder = function () {
    $scope.viewModel.isRemoveProblemPromptVisible = false;

    $scope.viewModel.removeFolderProblem($scope.viewModel.selectedProblem.id);

    saveFolders();

    var i = _folderProblems.indexOf($scope.viewModel.selectedProblem);

    _folderProblems.splice(i, 1);

    var problem;

    if (_folderProblems.length > 0)
      problem = _folderProblems[0];

    $scope.selectProblem(problem);
  };

  function filterProblems(problems) {
    var filter = $scope.viewModel.filter;

    var searchTerm = filter.searchTerm;

    if (searchTerm && searchTerm.length > 0)
      searchTerm = searchTerm.toLowerCase();
    else
      searchTerm = null;

    var languageIds = getLanguageIds();

    if (languageIds.length === 0)
      languageIds = null;

    var grades = getGradeLabels();

    if (grades.length === 0)
      grades = null;

    var domains = getDomainIds();

    if (domains.length === 0)
      domains = null;

    var standards = getStandardIds();

    if (standards.length === 0)
      standards = null;

    var standardTypes = getStandardTypes();

    if (standardTypes.length === 0)
      standardTypes = null;

    return problems.filter(function (problem) {
      if (searchTerm) {
        if (problem.title.toLowerCase().indexOf(searchTerm) === -1)
          return false;
      }

      if (languageIds) {
        if (languageIds.indexOf(problem.language) === -1)
          return false;
      }

      if (grades) {
        var problemGrades = problem.grade.split(', ');

        if (_.intersection(grades, problemGrades).length === 0)
          return false;
      }

      if (domains) {
        if (domains.indexOf(problem.topic) === -1)
          return false;
      }

      if (standards) {
        var intersection = _.intersection(standards, problem.standards.split(','));

        if (intersection.length === 0)
          return false;
      }

      return true;
    });
  }

  function getFolderProblems() {
    var result;

    if (_folderProblems) {
      result = filterProblems(_folderProblems);

      sortProblems(result);
    } else {
      result = null;
    }

    return result;
  }

  $scope.getProblems = function () {
    if ($scope.viewModel.openedFolder)
      return getFolderProblems();
    else
      return _problemBank ? _problemBank : [];
  };

  $scope.isFolderEmpty = function () {
    return !_folderProblems || _folderProblems.length === 0;
  };

  $scope.getMediaUrl = function (path) {
    return ThinkletManager.getMediaURLFromPath(path);
  };

  $scope.draggableHelper = function (event) {
    return $('<p>').text($(event.target).parents('.problem-item').find('.title').text());
  };

  $scope.showDeleteFolderPrompt = function () {
    $scope.viewModel.isDeleteFolderPromptVisible = true;
  };

  $scope.closeDeleteFolderPrompt = function () {
    $scope.viewModel.isDeleteFolderPromptVisible = false;
  };

  $scope.submitDeleteFolderPrompt = function () {
    $scope.closeDeleteFolderPrompt();

    $scope.removeFolder();
  };

  $scope.closeTutorialPopUp = function () {
    $scope.viewModel.isTutorialPopUpVisible = false;
  };

  function findFolder(folder, callback) {
    if (callback(folder))
      return folder;

    for (var i = 0; i < folder.folders.length; i++) {
      var result = findFolder(folder.folders[i], callback);

      if (result)
        return result;
    }

    return null;
  }

  $scope.startProblemBankTutorial = function () {
    $scope.closeTutorialPopUp();

    // find Getting Started folder

    var folder = findFolder($scope.viewModel.rootFolder, function (folder) {
      return folder.name === $translate.instant('GETTING_STARTED');
    });

    if (folder)
      $scope.openFolder(folder);

    var tutorial = new Tutorial($state, $translate);
    tutorial.start(CueThinkConfig.problemBankTutorialSteps);
  };

  /*if (!tutorial.isActive()) {
    TeacherManager.fetchHasSeenProblemBankTutorial().then(function (result) {
      if (!result) {
        $scope.viewModel.isTutorialPopUpVisible = true;

        TeacherManager.flagHasSeenProblemBankTutorial();
      }
    });
  }*/

  function isFiltering() {
    if ($scope.viewModel.filter.searchTerm ||
      $scope.viewModel.filter.languages.length > 0 ||
      $scope.viewModel.filter.grades.length > 0 ||
      $scope.viewModel.filter.domains.length > 0 ||
      $scope.viewModel.filter.standards.length > 0 ||
      $scope.viewModel.filter.standardTypes.length > 0)
      return true;

    return false;
  }

  var THERE_ARE_NO_PROBLEMS_IN_THIS_FOLDER, DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS, NO_PROBLEMS_MATCH_THE_FILTERS;

  translate(['THERE_ARE_NO_PROBLEMS_IN_THIS_FOLDER', 'DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS', 'NO_PROBLEMS_MATCH_THE_FILTERS'], $translate, $rootScope, $scope, function (translations) {
    THERE_ARE_NO_PROBLEMS_IN_THIS_FOLDER = translations['THERE_ARE_NO_PROBLEMS_IN_THIS_FOLDER'];
    DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS = translations['DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS'];
    NO_PROBLEMS_MATCH_THE_FILTERS = translations['NO_PROBLEMS_MATCH_THE_FILTERS'];
  });

  $scope.getEmptyMessage = function () {
    if ($scope.viewModel.openedFolder) {
      if ($scope.isFolderEmpty())
        return THERE_ARE_NO_PROBLEMS_IN_THIS_FOLDER;
      else
        return DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS;
    } else if (isFiltering() && ($scope.viewModel.filter.source === 'mine' || $scope.viewModel.filter.source === 'shared')) {
      return DESELECT_FILTERS_TO_SEE_YOUR_PROBLEMS;
    } else {
      return NO_PROBLEMS_MATCH_THE_FILTERS;
    }

    return null;
  };

  $scope.isLimitedAccessPopupVisible = function () {
    return $scope.viewModel.isLimitedAccessPopupVisible;
  };

  function openLimitedAccessPopup() {
    $scope.viewModel.isLimitedAccessPopupVisible = true;
  }

  $scope.onUpgradePopUpClose = function () {
    closeLimitedAccessPopup();
  };

  function closeLimitedAccessPopup() {
    $scope.viewModel.isLimitedAccessPopupVisible = false;
  }

  $scope.onUpgradeClick = function () {
    window.open('https://www.cuethink.com/pricing_directsale', '_blank');

    closeLimitedAccessPopup();
  };

  $scope.loadStandardTypes = TeacherManager.loadStandardTypes;
  $scope.loadDomains = TeacherManager.loadStandardTypeDomains;
  $scope.loadStandards = TeacherManager.loadStandardTypeStandards;

  var showDeleteFolderProblemPrompt = function () {
    $scope.viewModel.isDeleteFolderProblemPromptVisible = true;
  };

  $scope.closeDeleteFolderProblemPrompt = function () {
    $scope.viewModel.isDeleteFolderProblemPromptVisible = false;
  };

  $scope.confirmDeleteFolderProblem = function () {
    deleteProblem();
  };

  $scope.saveDontShowDeleteFolderProblemPrompt = function (key) {
    if (typeof localStorage !== 'undefined')
      localStorage.setItem(key, false);
  };

  $scope.canCloneProblem = function (problem) {
    return permissionsManager.getPermission(Permissions.CloneProblem);
  };

  $scope.canEditProblem = function (problem) {
    return (!problem.addToBank && !problem.sharedWithCurrentUser) && $scope.viewModel.filter.source !== 'origo' && $scope.viewModel.filter.source !== 'im' && $scope.viewModel.filter.source !== 'learnzillion';
  };

  $scope.canDeleteProblem = function (problem) {
    return $scope.viewModel.openedFolder || (!problem.addToBank && !problem.sharedWithCurrentUser && $scope.viewModel.filter.source !== 'origo' && $scope.viewModel.filter.source !== 'im' && $scope.viewModel.filter.source !== 'learnzillion');
  };

  $scope.onClickORIGOFolder = function (folder, gradeFolder, event) {
    _initialProblem = null;

    $scope.viewModel.openedFolder = null;

    if (folder.folders) {
      $scope.viewModel.origoFolders.forEach(function (f) {
        if (f !== folder)
          f.isOpen = false;
      });

      folder.isOpen = !folder.isOpen;

      if (folder.isOpen)
        _origoGrade = folder.id;
      else
        _origoGrade = null;

      _origoModule = null;

      generateProblems();
    } else {
      gradeFolder.folders.forEach(function (f) {
        if (f !== folder)
          f.isOpen = false;
      });

      folder.isOpen = !folder.isOpen;

      _origoGrade = gradeFolder.id;

      if (folder.isOpen)
        _origoModule = folder.id;
      else
        _origoModule = null;

      if (localStorage !== 'undefined')
        localStorage.setItem('problem-bank-origo-folder', JSON.stringify({ grade: _origoGrade, module: _origoModule }));

      generateProblems();
    }

    $scope.viewModel.setGrades([_origoGrade]);

    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  $scope.onClickIMFolder = function (gradeFolder, event) {
    _initialProblem = null;

    $scope.viewModel.openedFolder = null;

    $scope.viewModel.imFolders.forEach(function (f) {
      if (f !== gradeFolder)
        f.isOpen = false;
    });

    gradeFolder.isOpen = !gradeFolder.isOpen;

    if (gradeFolder.isOpen) {
      _imGrade = gradeFolder.id;

      if (localStorage !== 'undefined')
        localStorage.setItem('problem-bank-im-folders', JSON.stringify({ grade: _imGrade }));
    } else {
      _imGrade = null;
    }

    $scope.viewModel.setGrades([_imGrade]);

    generateProblems();

    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  $scope.onClickLearnZillionFolder = function (folder, gradeFolder, event) {
    _initialProblem = null;

    $scope.viewModel.openedFolder = null;

    if (folder.folders) {
      $scope.viewModel.learnZillionFolders.forEach(function (f) {
        if (f !== folder)
          f.isOpen = false;
      });

      folder.isOpen = !folder.isOpen;

      if (folder.isOpen)
        _learnZillionGrade = folder.id;
      else
        _learnZillionGrade = null;

      _learnZillionUnit = null;

      generateProblems();
    } else {
      gradeFolder.folders.forEach(function (f) {
        if (f !== folder)
          f.isOpen = false;
      });

      folder.isOpen = !folder.isOpen;

      if (folder.isOpen) {
        _learnZillionGrade = gradeFolder.id;
        _learnZillionUnit = folder.id;

        generateProblems();

        if (localStorage !== 'undefined')
          localStorage.setItem('problem-bank-lz-folder', JSON.stringify({ grade: _learnZillionGrade, unit: _learnZillionUnit }));
      }
    }

    $scope.viewModel.setGrades([_learnZillionGrade]);

    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  $scope.submitSharePrompt = function () {
    var shareUsers = $scope.viewModel.getSharedUsers();

    if (shareUsers.length > 0)
      TeacherManager.shareProblem($scope.viewModel.selectedProblem, shareUsers);
  };

  $scope.cancelSharePrompt = function () {
  };

  $scope.getAuthor = function (problem) {
    if (problem.creatorName === 'IL-IM')
      return 'Imagine Learning';
    else
      return problem.creatorName;
  };

  $scope.getSource = function (problem) {
    if (problem.questionSourceName === 'Cloned Work') {
      return problem.sourceDetail;
    } else {
      if ($scope.viewModel.filter.source === 'origo')
        return 'ORIGO Education';
      else if ($scope.viewModel.filter.source === 'im')
        return 'Illustrative Mathematics';
      else if ($scope.viewModel.filter.source === 'learnzillion')
        return 'Imagine Learning IM K-12';
      else if (isProblemInCueThinkBank(problem) && problem.creatorName === 'cuethinkmgr')
        return 'CueThink';
      else
          return problem.creatorName;
    }
  };

  $scope.getSourceURL = function (problem) {
    if ($scope.getSource(problem).indexOf('Stepping Stones') === '-1') {
      if (problem.questionSourceName.indexOf('ORIGO') !== -1 && problem.questionSourceName.indexOf('Illustrative') !== -1 && problem.questionSourceName !== 'IL-IM') {
        if ($scope.isURL(problem.sourceDetail))
          return problem.sourceDetail;
        else if ($scope.isURL(problem.creatorName))
          return problem.creatorName;
      }
    }

    return null;
  };

  $scope.isURL = function (str) {
    return str && str.indexOf('http') === 0;
  };

  $scope.getLanguageKey = function (id) {
    var result = languages.find(function (language) {
      return language.id === id;
    });

    if (result)
      return result.name;
    else
      return null;
  };

  $scope.onClickCulturalRelevanceButton = function () {
    $scope.viewModel.isCulturalRelevanceBoxOpen = !$scope.viewModel.isCulturalRelevanceBoxOpen;
  };

  $scope.getLicenseURL = function (problem) {
    var url;

    if (problem.licenseType === 1)
      url = '/cclicensing1.html';
    else if (problem.licenseType === 2)
      url = '/cclicensing2.html';
    else if (problem.licenseType === 3)
      url = '/cclicensing3.html';

    return url;
  };

  $scope.getProblemTitle = function (problem) {
    return getLocalizedProblemTitle($translate, problem, $scope.viewModel.selectedLanguage);
  };

  $scope.getProblemQuestion = function (problem) {
    return getLocalizedProblemQuestion($translate, problem, $scope.viewModel.selectedLanguage);
  };

  $scope.getProblemAnswer = function (problem) {
    return getLocalizedProblemAnswer($translate, problem, $scope.viewModel.selectedLanguage);
  };

  $scope.getProblemQuestionImage = function (problem) {
    return getLocalizedProblemQuestionImage($translate, problem, $scope.viewModel.selectedLanguage);
  };

  $scope.getProblemAnswerImage = function (problem) {
    return getLocalizedProblemAnswerImage($translate, problem, $scope.viewModel.selectedLanguage);
  };

  UserManager.fetchHasSeenProblemBankLanguagePopUp().then(function (value) {
    if (!value)
      $scope.viewModel.isLanguagePopUpVisible = true;
  });

  $scope.onCloseLanguagePopUp = function () {
    $scope.viewModel.isLanguagePopUpVisible = false;
  };

  $scope.$on('select-button-toggle', function (event, option) {
    $scope.viewModel.selectedLanguage = option.id;

    $scope.$$postDigest(function () {
      renderQuestionMathML($scope.viewModel.selectedProblem, $('.question:first'));
      renderAnswerMathML($scope.viewModel.selectedProblem, $('.answer:first'));
    });
  });
}

ViewContentAssignmentBankController.filterById = function (needles, haystack) {
  return _.filter(haystack, function (problem) {
    for (var i = 0; i < needles.length; i++)
      if (needles[i] === problem.id)
        return false;

    return true;
  });
};

ViewContentAssignmentBankController.executeOnAllScopes = function (scope, callback) {
  callback(scope);

  if (scope.$parent)
    ViewContentAssignmentBankController.executeOnAllScopes(scope.$parent, callback);
};

ViewContentAssignmentBankController.showDeletePopup = function ($scope) {
  $scope.deleteProblemPopup = true;
};

ViewContentAssignmentBankController.toggleMyBankButtons = function ($scope) {
  $scope.mybankdropdown = !$scope.mybankdropdown;
};

ViewContentAssignmentBankController.editProblem = function ($state, problemId, standardType) {
  $state.go('create-new-problem', {
    edit: true,
    id: problemId,
    referer: $state.current.name,
    standardType: standardType
  });
};

ViewContentAssignmentBankController.cloneProblem = function ($state, problemId, standardType) {
  $state.go('create-new-problem', { clone: true, id: problemId, standardType: standardType });
};

ViewContentAssignmentBankController.assignProblem = function ($state, ContentAssignmentServices, problem, addAssignmentProblems) {
  var assignedProblems = [];

  assignedProblems.push(problem);

  if (addAssignmentProblems)
    ContentAssignmentServices.pushAssignProblems(problem);
  else
    ContentAssignmentServices.setAssignProblems(assignedProblems);

  $state.go('assign-problems', { isAddingProblems: addAssignmentProblems });
};

ViewContentAssignmentBankController.processReflections = function (reflections, $scope) {
  for (var i = 0; i < reflections.length; i++) {
    var reflection = reflections[i];

    reflection.strategyList = '';

    if (reflection.strategies.length > 0) {
      for (var j = 0; j < reflection.strategies.length; j++)
        if (reflection.strategies[j].selected)
          reflection.strategyList += reflection.strategies[j].name + ', ';

      reflection.strategyList = reflection.strategyList.substring(0, reflection.strategyList.length - 2);
    }

    reflection.grade = reflection.grade;
  }

  return reflections;
};

ViewContentAssignmentBankController.closeDeleteProblemPopup = function ($scope) {
  $scope.morePopup = false;
  $scope.deleteProblemPopup = false;
};

function PromiseQueue(handler) {
  var _promises = [];
  var _interval;

  this.push = function (promise) {
    _promises.push(promise);
  };

  function isFulfilled(promise) {
    return promise.$$state.status !== 0;
  }

  function getValue(promise) {
    return promise.$$state.value;
  }

  function handleInterval() {
    for (var i = 0; i < _promises.length; i++) {
      var promise = _promises[i];

      if (isFulfilled(promise)) {
        handler(getValue(promise));

        _promises.splice(i, 1);

        i--;
      } else {
        return;
      }
    }
  }

  this.start = function () {
    if (_interval)
      return;

    _interval = setInterval(handleInterval, 1000);
  };

  this.stop = function () {
    if (_interval)
      clearInterval(_interval);
  };

  this.clear = function () {
    _promises.splice(0);
  };
}

function Debouncer(timeout) {
  var timer;

  this.run = function (callback) {
    clearTimeout(timer);

    timer = setTimeout(callback, timeout);
  };
}

export default ViewContentAssignmentBankController;
