import ThinkletLoader from 'core/common/thinkletloader';

export default {
  data() {
    return {
      events: null,
      loadImage: null,
      ratio: null,
      thinklet: null,
      videoURL: null
    };
  },

  computed: {
    thinkletLoader() {
      return ThinkletLoader.sharedInstance; // TODO
    }
  },

  methods: {
    loadThinklet(thinklet, forRecording, callback) {
      return this.thinkletLoader.load(thinklet, this, forRecording, callback);
    }
  }
}
