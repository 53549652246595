import ShapeTool from './shape-tool';
import { inherit } from './base-tool';

export default function RectangleTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function(sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = RectangleTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  me.draw = function (obj) {
    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();

    var tmp;

    if (ex < sx) {
      tmp = sx;
      sx = ex;
      ex = tmp;
    }

    if (ey < sy) {
      tmp = sy;
      sy = ey;
      ey = tmp;
    }

    var context = whiteboard.getContext();

    context.beginPath();
    context.rect(sx, sy, ex-sx, ey-sy);
    context.closePath();
    context.stroke();
  };
}

inherit(RectangleTool, ShapeTool);

RectangleTool.type = 'rectangle';
