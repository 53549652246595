import Vuex from 'vuex';

const store = {
  state: {
    config: null,
    temporaryAvatarUrl: undefined,
    user: null
  },

  mutations: {
    SET_CONFIG(state, value) {
      state.config = value;
    },

    SET_TEMPORARY_AVATAR_URL(state, value) {
      state.temporaryAvatarUrl = value;
    },

    SET_USER(state, value) {
      state.user = value;
    }
  }
};

let sharedInstance;

export function getShared() {
  if (!sharedInstance)
    sharedInstance = new Vuex.Store(store);

  return sharedInstance;
}

export default store;
