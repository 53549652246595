import './textFieldListDirective.css';
import tpl from './textFieldListDirective.tpl.html';

function CuethinkTextFieldListDirective () {
  "ngInject";

  var link = function ($scope) {
    $scope.viewModel = {
      enabled: $scope.enabled,
      newItem: {
        enabled: true,
        justAdded: false,
        text: null
      }
    };

    if (typeof $scope.viewModel.enabled === 'undefined')
      $scope.viewModel.enabled = true;

    function digest () {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    $scope.addItem = function () {
      if (!$scope.viewModel.newItem.text)
        return;

      $scope.items.push($scope.viewModel.newItem);

      $scope.viewModel.newItem = {
        enabled: true,
        text: null
      };

      $scope.viewModel.justAdded = true;

      setTimeout(function () {
        $scope.viewModel.justAdded = false;

        digest();
      }, 5000);
    };
  };

  return {
    link: link,
    restrict: 'E',
    scope: {
      enabled: '=',
      itemName: '@',
      items: '='
    },
    template: tpl
  };
}

angular.module('cuethink.common').directive('textFieldList', CuethinkTextFieldListDirective);
