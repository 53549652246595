import './viewThinkletDirective';
import tpl from './viewThinkletScreenDirective.tpl.html';
import './viewThinkletScreenDirective.css';

angular.module('cuethink.view', ['cuethink.navigation'])
.directive('viewThinkletScreen', function () {
  return {
    link($scope) {
      $scope.$on('back', () => {
        if ($scope.goBack)
          $scope.goBack();
      });

      $scope.$on('view-thinklet', (e, id) => {
        if ($scope.viewThinklet)
          $scope.viewThinklet(id);
      });
    },
    restrict: 'E',
    scope: {
      dashboard: '=',
      defaultAvatarColors: '=',
      defaultSentenceStarters: '=',
      fetchHasSeenTrialEndPopUp: '=',
      gallery: '=',
      getTrialDays: '=',
      goBack: '=',
      isCueTeachEnabled: '=',
      isEducatorMode: '=educator',
      isStudentRubricEnabled: '=studentRubric',
      logOut: '=logout',
      navigateJoinClass: '=',
      navigateRubric: '=',
      navigateToTeacherDashboard: '=teacherDashboard',
      onSubmitMindWanderingProbe: '&',
      profile: '=',
      setHasSeenTrialEndPopUp: '=',
      settings: '=',
      setUpNavigation: '=',
      teacherManager: '=',
      thinklet: '=',
      thinkletId: '=',
      thinkletManager: '=',
      tutorial: '=',
      user: '=',
      userManager: '=',
      viewThinklet: '='
    },
    template: tpl,
    transclude: true
  };
});
