import 'core/navigation/navbar';
import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'core/tutorial/tutorial';
import 'src/components/shared/thinklet_data';
import 'angular-translate';
import BaseController from '../../components/shared/base';
import { setUserModesScopeMembers } from 'core/common/user-modes';

function TeacherNavController ($scope, UserManager, $state, CueThinkConfig, $stateParams, TutorialService, ThinkletManager, $translate) {
  "ngInject";

  var Tutorial = TutorialService;
  var config = CueThinkConfig;

  $scope.stateParams = $stateParams;
  $scope.user = UserManager.getUser();
  $scope.isTeacherExpired = UserManager.isTeacherExpired();
  $scope.getUserTrialInfo = UserManager.getUserTrialInfo;
  $scope.subMenuUrls = config.subMenuUrls;

  $scope.goDashboard = function () {
    $state.go('dashboard');
  };

  setUserModesScopeMembers($scope);

  $scope.loginIntercom = UserManager.loginIntercom;

  $scope.openTutorial = function (tutorial) {
    if (typeof tutorial.id == 'undefined')
      tutorial.id = null;
    else if (typeof Tutorial.url == 'undefined')
      tutorial.url = null;
    if (typeof Tutorial.page == 'undefined')
      tutorial.page = null;

    $state.go('proflearning', tutorial);
  };

  $scope.trialExpired = function () {
    $state.go('dashboard');
  };

  $scope.logOut = function (skipPrompt) {
    return BaseController.logOut(skipPrompt, $state, UserManager, $translate);
  };

  $scope.goLogin = function () {
    $state.go('login');
  };

  if (config.teacherTutorialSteps) {
    $scope.startTutorial = function () {
      try {
        Intercom('trackEvent', 'User started teacher tutorial', { state: $state.current.name });
      } catch (e) {
      }

      Tutorial.start(config.teacherTutorialSteps, false);
    };

    Tutorial.setOnFinishCallback(function () {
      try {
        Intercom('trackEvent', 'User finished teacher tutorial', { state: $state.current.name });
      } catch (e) {
      }
    });

    if (Tutorial.isActive()) {
      var resumedToolbarTutorial;

      $scope.$$postDigest(function () {
        if (resumedToolbarTutorial)
          return;

        resumedToolbarTutorial = true;

        Tutorial.resume();
      });
    }
  }

  if (Tutorial.toolbarTutorial && Tutorial.toolbarTutorial.isActive()) {
    Tutorial.toolbarTutorial.setLoadCallback(function (step, finish, steps) {
      if (step.state != $state.current.name && (!step.data || !step.data.thinklet))
        return;

      if (!step.data) {
        var originalState = step.state;
        var i = steps.indexOf(step);

        if (steps.length > i + 1 && steps[i + 1].state != originalState) {
          while (i > 0 && !step.data && step.state == originalState) {
            step = steps[--i];
          }
        }
      }

      if (step.data && step.data.thinklet) {
        ThinkletManager.cacheThinklet(step.data.thinklet);

        $scope.sampleThinklet = step.data.thinklet;
      }
    });

    if (Tutorial.toolbarTutorial) {
      Tutorial.toolbarTutorial.setOnFinishCallback(function () {
        let classProgressState;

        if ($scope.isEF23User() && false)
          classProgressState = 'monitor-report';
        else
          classProgressState = 'classes';

        $state.go(classProgressState);

        return false;
      });
    }

    var resumedTutorial;

    $scope.$$postDigest(function () {
      if (resumedTutorial)
        return;

      resumedTutorial = true;

      Tutorial.toolbarTutorial.resume();
    });
  }

  $scope.navigateToProblemBank = function () {
    $state.go('view-all-problems');
  };

  $scope.navigateToCreateProblem = function () {
    $state.go('create-new-problem', { id: null, clone: null, edit: null, referer: null }, { notify: true });
  };

  $scope.navigateToAssignments = function () {
    $state.go('view-all-assignments');
  };

  $scope.navigateToResourceBank = function () {
    $state.go('resource-bank');
  };

  $scope.navigateToEducatorGuide = function () {
    $state.go('educatorguide');
  };

  $scope.navigateToClassProgress = function () {
    $state.go('classes');
  };

  $scope.navigateToManageStudents = function () {
    $state.go('view-all-students');
  };

  $scope.navigateToMonitorReport = () => {
    $state.go('monitor-report');
  };

  $scope.navigateToSettings = function () {
    console.log('navigateToSettings');

    $state.go('settings');
  };

  $scope.navigateToRubricSummary = function () {
    $state.go('rubric');
  };

  $scope.navigateToCommunity = function () {
    window.open(CueThinkConfig.communityUrl, '_blank');

  	// TODO: wait for iframe fix
    // $state.go('community');
  };

  $scope.navigateToLearningHub = function () {
    $state.go('learninghub', { section: null });
  };

  $scope.$on("$destroy", function() {
    Tutorial.setLoadCallback(null);

    if (Tutorial.toolbarTutorial)
      Tutorial.toolbarTutorial.setLoadCallback(null);
  });
}

angular.module('cuethinkApp.teacherNavController', ['userManager', 'CueThinkConfig', 'cuethink.tutorial', 'thinkletManager', 'pascalprecht.translate'])
.controller('TeacherNavBarController', TeacherNavController);

export default TeacherNavController;
