import 'config';
import './config.provider';

import 'bootstrap';
import 'bootstrap/less/bootstrap.less';
import 'jquery-ui-themes/themes/ui-lightness/jquery-ui.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Vue from 'vue';

import 'src/vendor/jqdatepicker';
import 'mobile-detect/mobile-detect.min';
import 'src/vendor/google-analytics';
import 'src/app/urls';
import googleManager from 'core/google/google-manager';

import 'angular-translate';
import 'angular-ui-bootstrap';
import 'angular-ui-bootstrap/dist/ui-bootstrap-csp.css';
import 'textangularjs/dist/textAngular-sanitize.min';
import 'textangularjs/dist/textAngular.min';
import 'textangularjs/dist/textAngularSetup';
import 'angular-drag-and-drop-lists';
import 'rangy/lib/rangy-core';
import 'rangy/lib/rangy-selectionsaverestore';
import 'angular-ui-utils/modules/utils';
import 'angular-ui-sortable/dist/sortable.min';

import '../styles/app.less';
import 'angular-ui-router/release/angular-ui-router.min';
import 'ui-select/dist/select.min';
import 'ui-select/dist/select.css';
import 'selectize/dist/css/selectize.default.css';
import 'angular-ui-bootstrap';
import 'core/permissions/permissions';
import 'core/navigation/navbar';
import './content/contentModule';
import './contentassignment/contentAssignmentModule';
import 'core/roster/classes/rosterClassesDirective';
import './students/viewStudentsController';
import 'src/app/login/loginController';
import 'src/components/browse/browseController';
import 'src/components/dashboard/dashboard';
import 'src/components/assignments/assignmentsController';
import 'src/components/profile/profileController';
import fourPhaseMenuTpl from 'src/app/fourPhaseMenu/fourPhaseMenu.tpl.html';
import fourPhaseMenuController from 'src/app/fourPhaseMenu/fourPhaseMenu.controller';
import 'src/components/plan/plan';
import 'src/components/review/review';
import 'src/app/settings/settingsController';
import 'src/components/solve/solve';
import 'src/components/understand/understand';
import { bootstrapDirective as bootstrapViewThinkletDirective } from 'core/viewThinklet/viewThinkletDirective';
import 'src/components/view/view';
import 'src/components/thinklet/externalController';
import 'src/components/thinklet/consolePlayerController';
import 'src/app/teacherdashboard/classes/rosterClassesController';
import 'core/devicedetect/deviceDetectionUtil';
import './teacherdashboard/classes/rosterClassesController';
import 'src/components/redirect/redirectController';
import './educatorGuide/educatorGuideController';
import './resourceBank/resourceBankController';
import './community/communityController';
import communityController from './community/communityController';
import './error/errorController';
import './roster/view/rosterViewController';
import './proflearning/profLearningController';
import 'core/learning-hub/learningHubDirective';
import './learning-hub/learningHubController';
import learningHubController from './learning-hub/learningHubController';
import './login/loginModule';
import 'src/components/shared/base';
import viewStudentsController from './students/viewStudentsController';
import 'core/students/viewStudentsDirective';
import browseController from 'src/components/browse/browseController';
import 'core/gallery/galleryDirective';
import dashboardController from 'src/components/dashboard/dashboard';
import 'core/dashboard/dashboard-screen.directive';
import assignmentsController from 'src/components/assignments/assignmentsController';
import 'core/assignments/assignmentsDirective';
import profileController from 'src/components/profile/profileController';
import 'core/profile/profileDirective';
import planController, { setUpPlanController } from 'src/components/plan/plan';
import reviewController from 'src/components/review/review';
import globalSettingsController from 'src/app/settings/settingsController';
import settingsController from 'src/components/settings/settings';
import 'core/settings/studentSettingsDirective';
import solveController from 'src/components/solve/solve';
import understandController from 'src/components/understand/understand';
import viewThinkletController from 'src/components/view/view';
import 'core/viewThinklet/viewThinkletDirective';
import externalController from 'src/components/thinklet/externalController';
import consolePlayerController from 'src/components/thinklet/consolePlayerController';
import 'core/devicedetect/deviceDetectionUtil';
import rosterClassesController from './teacherdashboard/classes/rosterClassesController';
import 'core/roster/classes/rosterClassesDirective';
import redirectController from 'src/components/redirect/redirectController';
import educatorGuideController from './educatorGuide/educatorGuideController';
import 'core/educatorGuide/educatorGuideDirective';
import resourceBankController from './resourceBank/resourceBankController';
import 'core/resourceBank/resourceBankDirective';
import './rubric/rubricSummaryController';
import rubricSummaryController from './rubric/rubricSummaryController';
import rubricOverviewController from './rubric/rubricOverviewController';
import startAssignmentController from 'src/components/assignments/startAssignmentController';
import 'core/community/communityDirective';
import errorController from './error/errorController';
import rosterViewController from './roster/view/rosterViewController';
import 'core/viewThinklet/viewThinkletDirective';
import profLearningController from './proflearning/profLearningController';
import 'src/components/shared/cuethink';
import errorTpl from 'src/app/error/error.tpl.html';
import rosterClassesTpl from 'src/app/roster/classes/rosterClasses.tpl.html';
import rosterViewTpl from 'src/app/roster/view/rosterView.tpl.html';
import viewStudentsTpl from 'src/app/students/viewStudents.tpl.html';
import dashboardTpl from 'src/components/dashboard/dashboard.html';
import assignmentsTpl from 'src/components/assignments/assignments.tpl.html';
import profileTpl from 'src/components/profile/profile.html';
import galleryTpl from 'src/components/browse/browse.html';
import globalSettingsTpl from 'src/app/settings/settings.tpl.html';
import teacherSettingsTpl from 'src/components/settings/teacher-settings.html';
import studentSettingsTpl from 'src/components/settings/student-settings.html';
import understandTpl from 'src/components/understand/understand.html';
import planTpl from 'src/components/plan/plan.html';
import solveTpl from 'src/components/solve/solve.html';
import reviewTpl from 'src/components/review/review.html';
import viewTpl from 'src/components/view/view.html';
import externalViewTpl from 'src/components/thinklet/externalView.html';
import consolePlayerTpl from 'src/components/thinklet/consolePlayer.html';
import educatorGuideTpl from 'src/app/educatorGuide/educatorGuideController.tpl.html';
import profLearningTpl from 'src/app/proflearning/profLearning.html';
import resourceBankTpl from 'src/app/resourceBank/resourceBankController.tpl.html';
import rubricTpl from 'src/app/rubric/rubricSummary.tpl.html';
import communityTpl from 'src/app/community/community.tpl.html';
import learningHubTpl from 'src/app/learning-hub/learningHub.tpl.html';
import rubricOverviewTpl from 'src/app/rubric/rubricOverview.tpl.html';
import './contentassignment/viewassigned/viewAssignedController';
import 'src/components/shared/create/create';
import 'core/whiteboard/whiteboard.module';
import 'core/whiteboard/whiteboardplayer.module';
import 'core/whiteboard/whiteboardscrap.module';
import classCodesController from 'src/app/settings/classCodesController';
import classCodesTpl from 'src/app/settings/classCodes.tpl.html';
import { setUserModesScopeMembers } from 'core/common/user-modes';
import NotificationCenter from 'core/notifications/notification-center';
import { ROUTE_NOTIFICATION } from 'core/common/route-manager';
import RouteManager from 'core/common/route-manager';
import TranslateFilter from 'core/common/filters/translate.filter';
import { bootstrapDirective as bootstrapDigitalPromiseProbeDirective } from 'src/app/digital-promise/digitalPromiseProbe.directive';
import { bootstrapDirective as bootstrapPaginatorDirective } from 'core/common/paginatorDirective';
import { BootstrapVue } from 'bootstrap-vue';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import './reports/reports.module';
import 'src/components/shared/thinkletloader';
import TranslateManager from 'core/translate/translate-manager';
import HelperDirective from 'core/helper/helper.directive';
import ThinkletManager from '../components/shared/thinklet_data';
import { waitUntil } from 'core/common/util';
import BeliefsProbeDirective from 'src/app/beliefs-probe/beliefs-probe.directive';
import './keyboard-shortcuts';
import ChatManager from '../components/shared/ChatManager';
import cuethink from '../components/shared/cuethink';
import permissionsManager from 'core/permissions/permissions';
import UserManager from '../components/shared/user_data';

const englishTranslations = require('./translations.en.json');
const spanishTranslations = require('./translations.es.json');

Vue.use(BootstrapVue);

if (!angular.lowercase) {
  angular.lowercase = str => angular.isString(str) ? str.toLowerCase() : str;
}

var appModule = angular.module('cuethinkApp', [
  'pascalprecht.translate',
  'ui.router',
  'ui.select',
  'ui.bootstrap.datepicker',
  'ui.bootstrap.modal',
  'deviceDetection',
  'CueThinkConfig',
  'cuethink',
  'cuethinkApp.login',
  'cuethinkApp.content',
  'cuethinkApp.contentassignment',
  'cuethinkApp.reports',
  'cuethink.login',
  'cuethink.roster',
  'cuethink.students',
  'cuethink.learningHub',
  'cuethink.educatorguide',
  'baseController',
  'cuethink.settings',
  'cuethink.gallery',
  'cuethink.dashboard',
  'cuethink.assignments',
  'cuethink.profile',
  'cuethink.resourcebank',
  'cuethink.rubric',
  'cuethink.community',
  'cuethink.permissions',
  'cuethink.view',
  'createController',
  'textAngular',
  'textAngularSetup',
  'thinkletLoader',
  'whiteboard',
  'whiteboardPlayer',
  'whiteboardscrap'
])
  .config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('');
  }])
  .config(function ($stateProvider, $urlRouterProvider, $urlServiceProvider) {
    "ngInject";

    $urlServiceProvider.config.hashPrefix('');

    $urlRouterProvider.otherwise('/login');

    $stateProvider
      .state('error', {
        url: '/error?message',
        template: errorTpl,
        controller: errorController
      })

      .state('classes', {
        url: '/classes?id',
        template: rosterClassesTpl,
        controller: rosterClassesController
      })

      .state('view-teacherdash', {
        url: '/classes/view?id',
        template: rosterViewTpl,
        controller: rosterViewController
      })

      .state('view-all-students', {
        url: '/students:newUser?',
        params: {
          newUser: {
            value: ''
          }
        },
        template: viewStudentsTpl,
        controller: viewStudentsController
      })

      .state('dashboard', {
        url: '/dashboard?overdue&trialexpired&welcome&finishedTutorial',
        template: dashboardTpl,
        controller: dashboardController
      })

      .state('assignments', {
        url: '/assignments',
        template: assignmentsTpl,
        controller: assignmentsController
      })

      .state('profile', {
        url: '/profile',
        template: profileTpl,
        controller: profileController
      })

      .state('browse', {
        url: '/browse',
        template: galleryTpl,
        controller: browseController
      })

      .state('settings', {
        url: '/settings',
        template: globalSettingsTpl,
        controller: globalSettingsController
      })

      .state('teacher-settings', {
        url: '/teacher-settings',
        template: teacherSettingsTpl,
        controller: settingsController
      })

      .state('student-settings', {
        url: '/student-settings',
        template: studentSettingsTpl,
        controller: settingsController
      })

      .state('four-phase-menu', {
        url: '/fourphasemenu',
        template: fourPhaseMenuTpl,
        controller: fourPhaseMenuController,
        params: { id: null }
      })

      .state('understand', {
        url: '/understand',
        template: understandTpl,
        controller: understandController,
        params: { id: null, showBeliefsProbe: null }
      })

      .state('understand-by-id', {
        url: '/understand/:id',
        template: understandTpl,
        controller: understandController
      })

      .state('plan', {
        url: '/plan',
        template: planTpl,
        controller: planController,
        params: { id: null, showBeliefsProbe: null }
      })

      .state('solve', {
        url: '/solve',
        template: solveTpl,
        controller: solveController,
        params: { id: null, showBeliefsProbe: null }
      })

      .state('review', {
        url: '/review',
        template: reviewTpl,
        controller: reviewController,
        params: { id: null, showBeliefsProbe: null }
      })

      .state('view', {
        url: '/view',
        template: viewTpl,
        controller: viewThinkletController,
        params: {id: null, studentRubric: false}
      })

      .state('share', {
        url: '/externalview/:v',
        template: externalViewTpl,
        controller: externalController
      })

      .state('externalview', {
        url: '/externalview/{v:any}?{a:any}',
        template: consolePlayerTpl,
        controller: consolePlayerController
      })

      .state('redirect', {
        url: '/redirect?nw&name&unit&calendar&slide&url&intercomEvent',
        template: 'Redirecting...',
        controller: redirectController
      })

      .state('educatorguide', {
        url: '/educatorguide',
        template: educatorGuideTpl,
        controller: educatorGuideController
      })

      .state('proflearning', {
        url: '/plcourse/:id?url&page',
        template: profLearningTpl,
        controller: profLearningController
      })
      .state('resource-bank', {
        url: '/resourcebank?resourceName',
        template: resourceBankTpl,
        controller: resourceBankController,
        params: {
          resourceName: {
            dynamic: true
          }
        }
      })
      .state('rubric', {
        url: '/rubric',
        template: rubricTpl,
        controller: rubricSummaryController
      })
      .state('community', {
        url: '/community',
        template: communityTpl,
        controller: communityController
      })
      .state('learninghub', {
        url: '/learninghub?section&unitId',
        template: learningHubTpl,
        controller: learningHubController,
        params: {
          section: null,

          unitId: {
            dynamic: true
          }
        }
      })
      .state('rubric-overview', {
        url: '/rubricoverview',
        template: rubricOverviewTpl,
        controller: rubricOverviewController
      })
      .state('startassignment', {
        url: '/startassignment/:id',
        controller: startAssignmentController
      })
      .state('startclassassignment', {
        url: '/startproblem/:id/:classId',
        controller: startAssignmentController
      })
      .state('startproblem', {
        url: '/startproblem/:problemId',
        controller: startAssignmentController
      })
      .state('class-codes', {
        url: '/classcodes',
        controller: classCodesController,
        template: classCodesTpl
      });
  })
  .filter('trustUrl', ['$sce', function ($sce) {
    return function (recordingUrl) {
      return $sce.trustAsResourceUrl(recordingUrl);
    };
  }])
  .filter('secondsToTime', [function () {
    return function (seconds) {
      var minutes;

      minutes = Math.floor(seconds / 60);

      if (minutes < 10)
        minutes = '0' + minutes;

      seconds -= (minutes * 60);

      if (seconds < 10)
        seconds = '0' + seconds;

      return minutes + ':' + seconds;
    };
  }])
  .filter('unsafe', function ($sce) {
    return function (val) {
      return $sce.trustAsHtml(val);
    };
  }).run(function ($rootScope, $state, deviceDetection, CueThink, $transitions, UserManager, ThinkletLoader) {
  'ngInject';

  // $trace.enable();

  $rootScope.isDesktop = deviceDetection.isDesktop();

  setUserModesScopeMembers($rootScope, UserManager.getUser);

  $rootScope.isDPUser = function () {
    return UserManager.getUser() ? UserManager.getUser().isDPAccount : false;
  };

  $rootScope.isStudentMode = function () {
    var educatorStates = [
      'classes',
      'view-teacherdash',
      'view-all-students',
      'settings',
      'teacher-settings',
      'educatorguide',
      'proflearning',
      'resource-bank',
      'rubric',
      'community',
      'learninghub',
      'view-all-assignments',
      'assign-problems',
      'view-all-problems',
      'create-new-problem'
    ];

    return educatorStates.indexOf($state.current.name) === -1;
  };

  $rootScope.getMainViewClasses = function () {
    return {
      ['state-' + $state.current.name]: true,
      ['default-theme']: !UserManager.getUser() || !UserManager.getUser().theme || UserManager.getUser().theme === 'DEFAULT',
      ['electric-garden-theme']: UserManager.getUser() && UserManager.getUser().theme === 'ELECTRIC_GARDEN',
      ['tropical-theme']: UserManager.getUser() && UserManager.getUser().theme === 'TROPICAL',
      ['circus-theme']: UserManager.getUser() && UserManager.getUser().theme === 'CIRCUS',
      ['cotton-candy-theme']: UserManager.getUser() && UserManager.getUser().theme === 'COTTON_CANDY',
      ['sporty-theme']: UserManager.getUser() && UserManager.getUser().theme === 'SPORTY'
    };
  };

  //if ($rootScope.isDesktop)
  //    document.addEventListener('click', enterFullScreen);

  $transitions.onStart({}, function (transition) {
    if (typeof (Storage) !== 'undefined') {
      var user = localStorage.getItem('user');

      var name = transition.to().name;

      var publicStates = [
        'login',
        /*'registerchoice',
        'register',
        'registerstudent',
        'registerteacher',*/
        'forgotpassword',
        'changepassword',
        'changepassword2',
        'updatepassword',
        'forgotusername',
        'googlesignup',
        'cleversignup',
        'clevercallback',
        'linkaccount',
        'linkclever',
        'externalview',
        'share',
        'redirect',
        'startassignment',
        'startproblem'
      ];

      var isWhitelistedState = publicStates.includes(name);

      if (!user && !isWhitelistedState)
        return transition.router.stateService.target('login', {redirect: window.location.href});
    }
  });

  $transitions.onSuccess({}, transition => {
    const to = transition.to().name;

    RouteManager.getSharedInstance().setCurrentRoute({
      name: to,
      data: transition.params()
    });

    if (['four-phase-menu', 'understand', 'plan', 'solve', 'review'].indexOf(to) !== -1) {
      let problemName, thinkletId;

      waitUntil(() => { // thinklet may not be loaded as soon as navigation finishes
        thinkletId = localStorage.getItem('thinkletId');

        if (thinkletId) {
          const thinklet = ThinkletManager.getShared().getCachedThinklet(thinkletId);

          if (thinklet)
            problemName = thinklet.problem.title;
        }

        return thinkletId && problemName;
      }).then(() => {
        CueThink.logPageEvent('Switch Between Phases', to, transition.to().url, transition.from().url, undefined, thinkletId, problemName);
      });
    }
  });

  $rootScope.$on('$translateChangeSuccess', (event, options) => {
    NotificationCenter.getShared().post('LanguageChanged', options.language);
  });
})
.directive('beliefsProbeDirective', BeliefsProbeDirective)
.directive('helper', HelperDirective)
.directive('textarea', function () {
  // Runs during compile
  return {
    link: function (scope, iElement, iAttrs) {
      iAttrs.$set('ngTrim', 'false');
    }
  };
})
.directive('monitorReport', require('./reports/monitor-report.directive').default)
.config(['$translateProvider', function ($translateProvider) {
  var lang;

  if (typeof localStorage !== 'undefined')
    lang = localStorage.getItem('language');

  if (!lang)
    lang = 'en';

  $translateProvider
    .translations('en', englishTranslations)
    .translations('es', spanishTranslations)
    .preferredLanguage(lang);
}])
.run(function ($translate, CueThinkConfig, $state) {
  var textProperties = ['name', 'label', 'text', 'content', 'understand', 'plan', 'answer', 'question', 'option'];

  translateArray(CueThinkConfig.annotation, textProperties, $translate);
  translateObject(CueThinkConfig.subMenuUrls, textProperties, $translate);
  translateObject(CueThinkConfig.calendar, textProperties, $translate);

  $translate(CueThinkConfig.serverDownMessage).then(function (translation) {
    CueThinkConfig.serverDownMessage = translation;
  });

  translateObject(CueThinkConfig.tutorialSteps, textProperties, $translate);
  translateObject(CueThinkConfig.teacherTutorialSteps, textProperties, $translate);
  translateObject(CueThinkConfig.toolbarTutorialSteps, textProperties, $translate);
  translateObject(CueThinkConfig.problemBankTutorialSteps, textProperties, $translate);

  googleManager.setUp(CueThinkConfig.googleClientId);

  TranslateFilter.setUp($translate);
  TranslateManager.getShared().setLanguage(() => $translate.use());
  TranslateManager.getShared().setTranslator($translate.instant);

  NotificationCenter.getShared().addListener(ROUTE_NOTIFICATION, route => {
    $state.go(route.name, route.data);
  });

  NotificationCenter.getShared().addListener('LogOut', () => {
    ChatManager.getShared().disconnect();
  });
});

bootstrapDigitalPromiseProbeDirective(appModule);
bootstrapPaginatorDirective(appModule);
bootstrapViewThinkletDirective(appModule);

setUpPlanController(appModule);

function translateArray(array, textProperties, $translate) {
  for (var i = 0; i < array.length; i++) {
    var value = array[i];

    if (typeof value === 'string') {
      translateArrayIndex(array, i, $translate);
    } else if (Array.isArray(value)) {
      translateArray(value, textProperties, $translate);
    } else if (typeof value === 'object') {
      translateObject(value, textProperties, $translate);
    }
  }
}

function translateArrayIndex(array, i, $translate) {
  $translate(array[i]).then(function (translation) {
    array[i] = translation;
  });
}

function translateObject(obj, textProperties, $translate) {
  var keys = Object.keys(obj);

  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    var value = obj[key];

    if (value) {
      if (Array.isArray(value)) {
        translateArray(value, textProperties, $translate);
      } else if (typeof value === 'object') {
        translateObject(value, textProperties, $translate);
      } else if (typeof value === 'string' && textProperties.indexOf(key) !== -1) {
        translateObjectProperty(obj, key, value, $translate);
      }
    }
  }
}

function translateObjectProperty(obj, key, value, $translate) {
  $translate(value).then(function (translation) {
    obj[key] = translation;
  });
}

document.addEventListener('touchstart', function () {
}, true);
