function CuethinkDropdownMenuDirective () {
  return {
    restrict: 'A',
    scope: {
      isOpen: '=',
    },
    link: function ($scope, element) {
      element = $(element);

      function bindEvent (el, event, handler) {
        el = $(el);
        el.on(event, handler);
        el.on('remove', function () {
          el.off(event, handler);
        });
      }

      var button = element.find('[cuethink-dropdown-toggle]');

      var menu = element.find('[cuethink-dropdown-menu-list]');
      menu.hide();
      $scope.isOpen = false;
      element.removeClass('open');

      bindEvent(button, 'mouseenter', function (e) {
        $('[cuethink-dropdown-menu-list]').hide();

        menu.show();

        $scope.isOpen = true;
        element.addClass('open');
      });

      bindEvent(button, 'click', function (e) {
        menu.toggle();

        $scope.isOpen = !$scope.isOpen;

        if ($scope.isOpen)
          element.addClass('open');
        else
          element.removeClass('open');
      });

      bindEvent(menu, 'mouseleave', function (e) {
        menu.hide();

        $scope.isOpen = false;
        element.removeClass('open');
      });

      bindEvent(menu, 'click', function (e) {
        menu.hide();

        $scope.isOpen = false;
        element.removeClass('open');
      });
    }
  };
}

export default CuethinkDropdownMenuDirective;
