import 'src/components/shared/teacher_data';
import 'src/components/shared/thinklet_data';
import '../contentAssignmentServices';
import 'src/app/navigation/teachernavbarController';
import './manageAssignment.css';
import _ from 'underscore';
import 'angular-ui-bootstrap';
import '../../content/manage/createProblemPopUpDirective';
import 'angular-translate';
import {
  getLocalizedProblemAnswer,
  getLocalizedProblemAnswerImage,
  getLocalizedProblemQuestion,
  getLocalizedProblemQuestionImage,
  getLocalizedProblemTitle,
  translate
} from 'core/common/translation-helpers';
import { setUserModesScopeMembers } from 'core/common/user-modes';
import { Permissions } from 'core/permissions/permissions';

let _shownAssignHelper = false;

function AssignProblemsController ($scope, $state, $stateParams, ThinkletManager, TeacherManager, $controller, ContentAssignmentServices, renderMath, $timeout, $translate, $rootScope, PermissionsManager) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  setUserModesScopeMembers($scope);

  var assignmentId = $stateParams.id;
  var editingAssignment;

  $scope.viewModel = {
    assignedStudents: [{}],
    classStudents: {},
    classVisible: true,
    isCalendarOpened: false,
    isProblemPopUpVisible: false,
    isRemoveProblemPopUpVisible: false,
    isSubmitted: false,
    selectOptions: null,
    translations: {},
    visibleProblemDetails: {}
  };

  translate(['SELECT_CLASSES', 'SELECT_STUDENTS'], $translate, $rootScope, $scope, function (translations) {
    let classProgressState;

    if ($scope.isEF23User() && false)
      classProgressState = 'monitor-report';
    else
      classProgressState = 'classes';

    $scope.viewModel.selectOptions = [
      { id: classProgressState, name: translations.SELECT_CLASSES },
      { id: 'students', name: translations.SELECT_STUDENTS }
    ];
  });

  $scope.toggledStudents = {};

  function loadClass(classId) {
    handleCallback(function (callback) {
      return TeacherManager.loadClassRoster(classId, callback);
    }, function (data) {
      _.each(data, function (student) {
        student.classId = classId;
      });

      $scope.viewModel.classStudents[classId] = data;

      digest();
    });
  }

  $scope.changeStudentClass = function (classroom, oldClassId) {
    if (oldClassId) {
      oldClassId = parseInt(oldClassId);

      if (classroom.problems) {
        var keys = Object.keys(classroom.problems);

        for (var j = 0; j < keys.length; j++)
          delete classroom.problems[keys[j]];
      }

      delete $scope.toggledStudents[oldClassId];
    }

    if (classroom.classId)
      loadClass(classroom.classId);
  };

  $scope.isStudentSelected = function (classId, problem, student) {
    var assignments = $scope.viewModel.assignedStudents;

    for (var i = 0; i < assignments.length; i++) {
      var assignment = assignments[i];

      if (assignment.classId == classId) {
        if (assignment.problems) {
          var students = assignment.problems[problem.id];

          if (students)
            for (var j = 0; j < students.length; j++)
              if (students[j].id == student.id)
                return true;
        }
      }
    }

    return false;
  };

  $scope.shouldShowProblems = function (classroom) {
    return $scope.viewModel.classStudents[classroom.id];
  };

  $scope.getStudentCount = function (classroom, problem) {
    return (classroom.problems && classroom.problems[problem.id]) ? classroom.problems[problem.id].length : 0;
  };

  $scope.selectAllStudents = function (classId, problem) {
    var assignments = $scope.viewModel.assignedStudents;
    var classStudents = $scope.viewModel.classStudents[classId];

    for (var i = 0; i < assignments.length; i++) {
      var assignment = assignments[i];

      if (assignment.classId == classId) {
        if (!assignment.problems) {
          assignment.problems = {};

          $scope.viewModel.assignedStudents.push({});
        }

        assignment.problems[problem.id] = classStudents.slice(0);

        if (!$scope.toggledStudents[classId])
          $scope.toggledStudents[classId] = {};

        if (!$scope.toggledStudents[classId][problem.id])
          $scope.toggledStudents[classId][problem.id] = {};

        for (i = 0; i < classStudents.length; i++)
          $scope.toggledStudents[classId][problem.id][classStudents[i].id] = true;

        break;
      }
    }
  };

  $scope.deselectAllStudents = function (classId, problem) {
    var assignments = $scope.viewModel.assignedStudents;

    for (var i = 0; i < assignments.length; i++) {
      var assignment = assignments[i];

      if (!classId || (assignment.classId && assignment.classId == classId)) {
        if (!assignment.problems) {
          assignment.problems = {};

          $scope.viewModel.assignedStudents.push({});
        }

        if (problem) {
          if (assignment.problems[problem.id])
            assignment.problems[problem.id].splice(0);

          if ($scope.toggledStudents[classId])
            $scope.toggledStudents[classId][problem.id] = {};
        } else {
          for (var j = 0; j < $scope.viewModel.assignProblems.length; j++) {
            problem = $scope.viewModel.assignProblems[j];

            if (assignment.problems[problem.id])
              assignment.problems[problem.id].splice(0);

            if ($scope.toggledStudents[classId])
              $scope.toggledStudents[classId][problem.id] = {};
          }
        }

        break;
      }
    }
  };

  function indexOfSelectedClass(classId) {
    var result = -1;

    _.each($scope.classesToAssignList, function (id, index) {
      if (classId == id) {
        result = index;

        return false;
      }
    });

    return result;
  }

  $scope.toggleStudent = function (classId, problem, student) {
    var index = indexOfSelectedClass(classId);

    if (index != -1)
      $scope.classesToAssignList.splice(index, 1);

    var assignments = $scope.viewModel.assignedStudents;

    for (var i = 0; i < assignments.length; i++) {
      var assignment = assignments[i];

      if (assignment.classId == classId) {
        if (!assignment.problems) {
          assignment.problems = {};

          $scope.viewModel.assignedStudents.push({});
        }

        var students = assignment.problems[problem.id];

        if (!students)
          students = assignment.problems[problem.id] = [];

        var found;

        for (var j = 0; j < students.length; j++) {
          if (students[j].id == student.id) {
            students.splice(j, 1);

            found = true;

            break;
          }
        }

        if (!found)
          students.push(student);

        break;
      }
    }
  };

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  function handleCallback(func, callback, error) {
    var promise = func(callback, error);

    if (promise)
      promise.then(callback, error);

    return promise;
  }

  var isAddingProblems;

  if ($stateParams.isAddingProblems) {
    isAddingProblems = true;

    $scope.assignmentData = ContentAssignmentServices.getAssignmentData(function (classes) {
      $scope.classesToAssignList = classes;
    }, function (students, toggled) {
      $scope.viewModel.assignedStudents = students;
      $scope.toggledStudents = toggled;

      if (students) {
        for (var i = 0; i < students.length; i++)
          if (students[i].classId)
            loadClass(students[i].classId);
      }
    });

    assignmentId = $scope.assignmentData.id;
  } else {
    $scope.assignmentData = {
      startDate: '',
      endDate: "",
      selectedProblemIds: [],
      selectedClassIds: []
    };

    $scope.assignmentData.startDate = new Date();
    $scope.assignmentData.startDate.setHours(0);
    $scope.assignmentData.startDate.setMinutes(0);
    $scope.assignmentData.startDate.setSeconds(0);
    $scope.assignmentData.startDate.setMilliseconds(0);
  }

  var _problemToRemove;

  $scope.removeProblem = function (problem) {
    _problemToRemove = problem;

    $scope.viewModel.isRemoveProblemPopUpVisible = true;
  };

  function isSameDay(a, b) {
    return a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate();
  }

  function buildStudentIds(problem) {
    var studentIds = [];

    if ($scope.viewModel.assignedStudents && $scope.viewModel.assignedStudents.length > 0) {
      for (var i = 0; i < $scope.viewModel.assignedStudents.length; i++) {
        var classroom = $scope.viewModel.assignedStudents[i];

        if (classroom.problems) {
          var students = classroom.problems[problem.id];

          if (students) {
            for (var j = 0; j < students.length; j++) {
              var student = students[j];

              if (studentIds.indexOf(student.id) < 0)
                studentIds.push({ id: student.id, classId: classroom.classId });
            }
          }
        }
      }
    }

    return studentIds;
  }

  function validateClassesAndStudents() {
    if ($scope.viewModel.assignedStudents && $scope.viewModel.assignedStudents.length > 0) {
      for (var k = 0; k < $scope.classesToAssignList.length; k++) {
        for (var i = 0; i < $scope.viewModel.assignedStudents.length; i++) {
          var classroom = $scope.viewModel.assignedStudents[i];

          if (classroom.classId == $scope.classesToAssignList[k]) {
            if (classroom.problems) {
              var keys = Object.keys(classroom.problems);

              for (var j = 0; j < keys.length; j++) {
                if (classroom.problems[keys[j]].length > 0) {
                  alert($translate.instant('YOU_CANNOT_ASSIGN_TO_A_CLASS_AND_ALSO_STUDENTS_IN_THAT_CLASS'));

                  return false;
                }
              }
            }
          }
        }
      }
    }

    return true;
  }

  $scope.submitAssignment = function () {
    if ($scope.assignmentData.startDate > $scope.assignmentData.endDate && !isSameDay($scope.assignmentData.startDate, $scope.assignmentData.endDate)) {
      alert($translate.instant('PLEASE_SELECT_AN_END_DATE_THAT_IS_ON_OR_AFTER_THE_START_DATE'));

      return;
    }

    if (!$scope.viewModel.assignProblems || $scope.viewModel.assignProblems.length === 0) {
      alert($translate.instant('NEED_AT_LEAST_1_PROBLEM_TO_ASSIGN'));

      return;
    }

    var finished = 0;

    $scope.viewModel.isSubmitted = true;

    var success = function () {
      finished++;

      if (finished === $scope.viewModel.assignProblems.length) {
        $scope.submission = true;

        let showHelper = !_shownAssignHelper && PermissionsManager.getPermission(Permissions.TeacherAnimatedHelper);

        if (showHelper)
          _shownAssignHelper = true;

        $state.go('view-all-assignments', { showHelper });
      }
    };

    var error = function () {
      alert($translate.instant('ASSIGNMENT_SUBMIT_FAILED'));
    };

    var studentIds;

    //get the id for all selected problems and classes
    for (var i = 0; i < $scope.viewModel.assignProblems.length; i++) {
      var problem = $scope.viewModel.assignProblems[i];

      if (editingAssignment && editingAssignment.problemId == problem.id) {
        if (validateClassesAndStudents()) {
          studentIds = buildStudentIds($scope.viewModel.assignProblems[0]);

          TeacherManager.submitEditedAssignment(assignmentId, $scope.assignmentData.startDate, $scope.assignmentData.endDate, $scope.classesToAssignList, studentIds)
            .then(success)
            .catch(error);
        } else {
          break;
        }
      } else {
        if (validateClassesAndStudents()) {
          studentIds = buildStudentIds(problem);

          TeacherManager.submitAssignment(problem.id, $scope.assignmentData.startDate, $scope.assignmentData.endDate, $scope.classesToAssignList, studentIds)
            .then(success)
            .catch(error);
        } else {
          break;
        }
      }
    }
  };

  $scope.showMorePopup = function (event, problem) {
    var imgList = getLocalizedProblemQuestionImage($translate, problem);

    $scope.viewModel.problemDetails = {
      title: getLocalizedProblemTitle($translate, problem),
      question: getLocalizedProblemQuestion($translate, problem),
      answer: getLocalizedProblemAnswer($translate, problem),
      standards: problem.standard.split(','),
      imageUrl: imgList && imgList.length > 0 ? ThinkletManager.getMediaURLFromPath(imgList[0]) : null
    };

    $scope.viewModel.isProblemPopUpVisible = true;

    event.stopPropagation();
  };

  $scope.getImageUrl = function (path) {
    return ThinkletManager.getMediaURLFromPath(path);
  };

  $scope.openCalendar = function (type) {
    if (type === 'start')
      $scope.viewModel.isStartCalendarOpened = true;
    if (type === 'end')
      $scope.viewModel.isEndCalendarOpened = true;
  };

  function sortProblems() {
    if (!$scope.viewModel.assignProblems)
      return;

    $scope.viewModel.assignProblems.sort(function (a, b) {
      var result = a.title > b.title;

      if (!$scope.viewModel.problemSortAsc)
        result = !result;

      return result ? 1 : -1;
    });
  }

  $scope.onSortProblems = function () {
    $scope.viewModel.problemSortAsc = !$scope.viewModel.problemSortAsc;
    sortProblems();
  };

  handleCallback(function () {
    return TeacherManager.loadClasses();
  }, function (classes) {
    $scope.classList = classes;

    digest();
  });

  $scope.selectClass = function (classroom) {
    $scope.deselectAllStudents(classroom.id);

    var i = indexOfSelectedClass(classroom.id);
    var classes = $scope.classesToAssignList;

    if (i >= 0)
      classes.splice(i, 1);
    else
      classes.push(classroom.id);
  };

  $scope.showClasses = function () {
    if ($scope.viewModel.classVisible)
      return;

    $scope.viewModel.classVisible = true;
  };

  $scope.showStudents = function () {
    if (!$scope.viewModel.classVisible)
      return;

    $scope.viewModel.classVisible = false;
  };

  $scope.$on('select-button-toggle', function (event, option) {
    let classProgressState;

    if ($scope.isEF23User() && false)
      classProgressState = 'monitor-report';
    else
      classProgressState = 'classes';

    if (option.id === classProgressState)
      $scope.showClasses();
    else if (option.id === 'students')
      $scope.showStudents();
  });

  $scope.isClassSelected = function (classroom) {
    return $scope.classesToAssignList && indexOfSelectedClass(classroom.id) >= 0;
  };

  $scope.isValid = function () {
    if ($scope.viewModel.isSubmitted)
      return false;

    var hasClasses = !$scope.classesToAssignList || $scope.classesToAssignList.length > 0;

    if (!hasClasses && $scope.viewModel.assignedStudents) {
      for (var i = 0; i < $scope.viewModel.assignedStudents.length; i++) {
        var classroom = $scope.viewModel.assignedStudents[i];

        if (classroom.problems) {
          var keys = Object.keys(classroom.problems);

          for (var j = 0; j < keys.length; j++) {
            var problems = classroom.problems[keys[j]];

            if (problems && problems.length > 0) {
              hasClasses = true;

              break;
            }
          }
        }
      }
    }

    return hasClasses && $scope.assignmentData.endDate && $scope.viewModel.assignProblems && $scope.viewModel.assignProblems.length > 0;
  };

  var oldProblems = ContentAssignmentServices.getAssignProblems();

  if (!assignmentId && (!oldProblems || oldProblems.length === 0)) {
    $state.go('view-all-problems');

    return;
  }

  $scope.viewModel.assignProblems = oldProblems;

  function renderMathML (el) {
    el.hide();

    $timeout(function () {
      renderMath.renderElements([el[0]], function () {
        el.show();
      });
    });
  }

  function renderQuestionMathML (problem, el) {
    var questionEl = $('<span>' + problem.question + '</span>');

    questionEl.find('img').each(function (i, img) {
      img = $(img);

      var mathML = img.attr('alt');

      if (mathML) {
        mathML = atob(mathML);

        if (mathML) {
          img.replaceWith(mathML);
        }
      }
    });

    problem.question = questionEl.html();

    renderMathML(el);
  }

  $('.problem-list .question').toArray().forEach(function (questionEl, i) {
    var problem = $scope.viewModel.assignProblems[i];

    renderQuestionMathML(problem, $(questionEl));
  });

  ContentAssignmentServices.clearAssignProblems();

  if (assignmentId) {
    handleCallback(function (callback) {
      return TeacherManager.loadAssignment(assignmentId, callback);
    }, function (assignment) {
      editingAssignment = assignment;

      if (!isAddingProblems) {
        handleCallback(function (callback) {
          ThinkletManager.loadProblem(assignment.problemId).then(callback);
        }, function (problem) {
          $scope.viewModel.assignProblems.push(problem);

          digest();
        });
      }

      $scope.assignmentData.startDate = assignment.startDate;
      $scope.assignmentData.endDate = assignment.endDate;

      if (!isAddingProblems) {
        if (assignment.classIds)
          $scope.classesToAssignList = assignment.classIds;
        else
          $scope.classesToAssignList = [];

        if (assignment.studentNames) {
          for (var i = 0; i < assignment.studentNames.length; i++) {
            var classroom = assignment.studentNames[i];

            $scope.toggledStudents[classroom.classId] = {};
            $scope.toggledStudents[classroom.classId][assignment.problemId] = {};

            loadClass(classroom.classId);

            var problems = {};
            var students = [];
            problems[assignment.problemId] = students;

            for (var j = 0; j < classroom.studentIds.length; j++) {
              var id = classroom.studentIds[j];

              students.push({ id: id });

              $scope.toggledStudents[classroom.classId][assignment.problemId][id] = true;
            }

            $scope.viewModel.assignedStudents.unshift({
              classId: classroom.classId,
              className: classroom.className,
              problems: problems
            });
          }
        }
      }

      digest();
    });
  } else if (!isAddingProblems) {
    $scope.classesToAssignList = [];
  }

  $scope.addMoreProblems = function (cloneProblemId, event) {
    ContentAssignmentServices.setAssignProblems($scope.viewModel.assignProblems);
    $scope.assignmentData.id = assignmentId;
    ContentAssignmentServices.setAssignmentData($scope.assignmentData, $scope.classesToAssignList, $scope.viewModel.assignedStudents, $scope.toggledStudents);

    if (cloneProblemId)
      $state.go('create-new-problem', { id: cloneProblemId, clone: true, returnToAssign: true });
    else
      $state.go('view-all-problems', { addAssignmentProblems: true });

    if (event) {
      event.stopPropagation();
      event.preventDefault();

      return false;
    }
  };

  $scope.cloneProblem = function (problem) {
    $scope.addMoreProblems(problem.id)
  };

  $scope.canEditProblem = function (problem) {
    return problem.creatorName === $scope.user.username;
  };

  $scope.editProblem = function (problem) {
    $state.go('create-new-problem', {
      edit: true,
      id: problem.id
    });
  };

  $scope.getStandardsString = function (problem) {
    if (!problem.standard)
      return '';

    return problem.standard.split(',').join(', ').replace(/_CCK12/g, '');
  };

  $scope.toggleProblemDetails = function (problem) {
    $scope.viewModel.visibleProblemDetails[problem.id] = !$scope.viewModel.visibleProblemDetails[problem.id];
  };

  $scope.isProblemDetailsVisible = function (problem) {
    return $scope.viewModel.assignProblems.length === 1 || $scope.viewModel.visibleProblemDetails[problem.id];
  };

  $scope.onStudentToggle = function (problem, classroom, student) {
    $scope.toggleStudent(classroom.classId, problem, student);
  };

  $scope.getOnStudentToggle = function (problem, classroom) {
    return function (student) {
      return $scope.onStudentToggle(problem, classroom, student);
    };
  };

  $scope.getSelectedStudents = function (problem, classroom) {
    return [];

    /*if (!$scope.viewModel.classStudents || !$scope.viewModel.classStudents[classroom.classId]
      || !$scope.toggledStudents || !$scope.toggledStudents[classroom.classId] || !$scope.toggledStudents[classroom.classId][problem.id])
      return [];

    return $scope.viewModel.classStudents[classroom.classId].filter(function (student) {
      return $scope.toggledStudents[classroom.classId][problem.id][student.id];
    });*/
  };

  $scope.getStudentName = function (student) {
    return student.firstname + ' ' + student.lastname;
  };

  $scope.onCancelRemoveProblemPopUp = function () {
    $scope.viewModel.isRemoveProblemPopUpVisible = false;
  };

  function removeProblem () {
    var problem = _problemToRemove;

    $scope.viewModel.assignProblems = _.filter($scope.viewModel.assignProblems, function (item) {
      return problem != item;
    });

    if ($scope.assignedStudents) {
      for (var i = 0; i < $scope.assignedStudents.length; i++) {
        var classroom = $scope.assignedStudents[i];

        if (classroom.problems)
          delete classroom.problems[problem.id];
      }
    }

    ContentAssignmentServices.deleteAssignProblems(problem);

    _problemToRemove = null;
  }

  $scope.onSubmitRemoveProblemPopUp = function () {
    $scope.viewModel.isRemoveProblemPopUpVisible = false;

    removeProblem();
  };

  translate('STUDENTS', $translate, $rootScope, $scope, function (value) {
    $scope.viewModel.translations.STUDENTS = value.toLowerCase();
  });

  $scope.getStudentCountLabel = function (classroom, problem) {
    return $scope.getStudentCount(classroom, problem) + ' ' + $scope.viewModel.translations.STUDENTS;
  };

  $scope.getProblemTitle = getLocalizedProblemTitle.bind(this, $translate);
  $scope.getProblemQuestion = getLocalizedProblemQuestion.bind(this, $translate);
  $scope.getProblemAnswer = getLocalizedProblemAnswer.bind(this, $translate);
  $scope.getProblemQuestionImage = getLocalizedProblemQuestionImage.bind(this, $translate);
  $scope.getProblemAnswerImage = getLocalizedProblemAnswerImage.bind(this, $translate);
}

export default AssignProblemsController;
