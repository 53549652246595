import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import Popover from 'vue-js-popover';

window.jQuery = window.$ = require('jquery/dist/jquery');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/droppable');
require('jquery-ui/ui/effects/effect-slide');
require('jquery-ui/ui/widgets/progressbar');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui-touch-punch/jquery.ui.touch-punch');
require('angular/angular');
require('angular-dragdrop/src/angular-dragdrop');
require('qtip2/dist/jquery.qtip');
import 'qtip2/dist/jquery.qtip.min.css';
window.Flow = require('@flowjs/flow.js/dist/flow');
require('@flowjs/ng-flow/dist/ng-flow');

Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(Popover);

require('./app');
