import 'core/login/register/registerChoiceDirective';

export default function ($scope, $state, UserManager, CueThinkConfig, $stateParams) {
  "ngInject";

  $scope.name = CueThinkConfig.name;

  $scope.logIn = function () {
    $state.go('login', { ref: $stateParams.ref });
  };

  $scope.registerStudent = function () {
    $state.go('registerstudent', { ref: $stateParams.ref });
  };

  $scope.registerTeacher = function () {
    $state.go('registerteacher', { ref: $stateParams.ref });
  };
}
