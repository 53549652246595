import 'src/components/shared/cuethink';
import 'core/whiteboard/libs/imagepreloader.module';
import './external.css';
import Whiteboard from 'core/whiteboard/whiteboard';

function ConsolePlayerController ($scope, $stateParams, CueThink, imagePreloader) {
		"ngInject";

		var _isLoading = false;

		$scope.isLoading = function () {
			return !!_isLoading;
		};

		var a = $stateParams.a;

		if (!a)
			a = null;

		$scope.audioURL = a;

		var videoURL = $stateParams.v;
		$scope.time = 0;
		$scope.areaSelector = '.whiteboard-player>*:nth-child(1)';

		if (videoURL.indexOf('.txt') >= 0) {
			CueThink.get(videoURL, function (data) {
				var imageURLs = [];

				function addImageURL (event) {
					if ((event.type == 'image' || event.type == 'equation') && imageURLs.indexOf(event.url) == -1)
						imageURLs.push(event.url);
				}

				Whiteboard.crawlEvents(data.recordedEvents, addImageURL, addImageURL);

				imagePreloader.load(imageURLs, function (images) {
					$scope.ratio = data.ratio;
					$scope.events = data.recordedEvents;

					$scope.loadImage = imagePreloader.getImage;

					if (!$scope.$$phase)
						$scope.$digest();
				});
			});
		}
	}

	export default ConsolePlayerController;
