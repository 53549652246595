import './cuethink';
import './thinklet_data';
import './user_data';
import 'config';

angular.module('mediaManager', ['cuethink', 'CueThinkConfig', 'thinkletManager', 'userManager']).factory('MediaManager', ['CueThink', 'CueThinkConfig', 'ThinkletManager', 'UserManager', function (cuethink, config, $thinkletManager, $userManager) {
  "ngInject";

  return MediaManager.getShared(cuethink, config, $thinkletManager, $userManager);
}]);

export default function MediaManager(cuethink, config, $thinkletManager, $userManager) {
  var _me = this;
  var user = $userManager.getUser();

  _me.getAudioUploadParameters = function (thinklet) {
    return {
      multimedia_category: 'thinklet_video',
      thinklet_id: thinklet.id,
      username: user.username
    };
  };

  _me.handleAudioUploadResponse = function (thinklet, result) {
    thinklet.dfs_audio_url = result.id;
    thinklet.audioURL = $thinkletManager.getMediaURLFromPath(thinklet.dfs_audio_url);
  };

  _me.uploadRecording = function (thinklet, recordedEvents, audio, success, error, progress) {
    var data;

    data = {
      thinklet_id: thinklet.id,
      playback_string: angular.toJson(recordedEvents),
      username: user.username
    };

    if (progress)
      progress(0);

    cuethink.post(config.uploadVideoURL, data, function (result) {
      thinklet.dfs_playback_url = result.id;
      thinklet.videoURL = $thinkletManager.getMediaURLFromPath(thinklet.dfs_playback_url);

      $thinkletManager.cacheThinklet(thinklet);

      console.log('cached URL: ' + thinklet.videoURL);

      if (success)
        success(result, true);

      if (audio) {
        var data = new FormData();

        var params = _me.getAudioUploadParameters(thinklet);
        var keys = Object.keys(params);

        for (var i = 0; i < keys.length; i++)
          data.append(keys[i], params[keys[i]]);

        data.append('file', audio, 'audio.wav');

        cuethink.post(config.uploadAudioURL, data, function (result) {
          _me.handleAudioUploadResponse(thinklet, result);

          $thinkletManager.cacheThinklet(thinklet);

          if (success)
            success(result, false, true);
        }, error, null, progress ? function (value, bytes, total) {
          progress((1 + value) / 2, bytes, total);
        } : null);
      }
    }, error, null, progress ? function (value, bytes, total) {
      progress(value / 2, bytes, total);
    } : null);
  };

  _me.uploadWhiteboard = function (thinklet, recordedEvents, success, error) {
    if (thinklet.id < 0)
      return;

    var data = new FormData();

    data.append('file', new Blob([angular.toJson(recordedEvents)], { type: 'application/json' }), 'whiteboard.json');

    cuethink.post(config.uploadWhiteboardURL.replace('{0}', thinklet.id), data, function (result) {
      if (!thinklet.static_data)
        thinklet.static_data = [];

      thinklet.static_data.push(result.id);
      thinklet.whiteboardURL = $thinkletManager.getMediaURLFromPath(result.id);

      $thinkletManager.cacheThinklet(thinklet);

      console.log('cached URL: ' + thinklet.whiteboardURL);

      if (success)
        success(result);
    }, error);
  };

  _me.uploadScrapPaper = function (thinklet, events, success, error) {
    if (thinklet.id < 0)
      return;

    var data;

    data = new FormData();
    data.append('multimedia_category', 'thinklet_scrap');
    data.append('thinklet_id', thinklet.id);
    data.append('step', 0);
    data.append('sequence', 0);
    data.append('file', new Blob([angular.toJson(events)], { type: 'application/json' }), 'scrap.txt');
    data.append('username', user.username);

    cuethink.post(config.uploadScrapPaperURL, data, function (result) {
      // set URLs
      thinklet.scrap_paper_urls = [{ dfsMediaFile: result.id }];
      thinklet.scrapPaperURLs = [_me.getMediaURLFromPath(thinklet.scrap_paper_urls[0].dfsMediaFile)];

      $thinkletManager.cacheThinklet(thinklet);

      if (success)
        success(result, true);
    }, error);
  };

  _me.uploadThumbnail = function (thinklet, img, success, error) {
    if (thinklet.id < 0)
      return;

    var data = new FormData();

    data.append('file', img, 'thumbnail.png');
    data.append('username', user.username);

    cuethink.post(config.uploadThumbnailURL.replace('{0}', thinklet.id), data, function (result) {
      thinklet.dfs_thumbnail_url = result.id;
      thinklet.thumbnailURL = _me.getMediaURLFromPath(thinklet.dfs_thumbnail_url);

      if (success)
        success(result);
    }, error);
  };

  _me.uploadProblemImage = function (img) {
    var data = new FormData();
    data.append('file', img, 'img.png');
    data.append('username', $userManager.getUser().username);

    return cuethink.post(config.uploadProblemImageURL.replace('{0}', $userManager.getUser().id), data);
  };

  _me.deleteProblemImage = function (problemId, success, error) {
    return cuethink.delete(config.deleteProblemImageURL.replace('{0}', problemId, function (result) {
      if (success)
        success(result);
    }, error));
  };

  _me.uploadImage = function (thinkletID, image) {
    if (thinkletID < 0)
      return;

    var data = new FormData();

    data.append('file', image, 'img.png');
    data.append('username', user.username);

    return cuethink.post(config.uploadImageURL.replace('{0}', thinkletID), data);
  };

  _me.uploadScreenshot = (thinkletId, step, image, index) => {
    if (thinkletId < 0)
      return;

    var data = new FormData();

    data.append('file', image, 'img.png');
    data.append('username', user.username);

    let url;

    if (typeof index === 'undefined')
      url = config.uploadScreenshotURL;
    else
      url = config.uploadScreenshotIndexURL;

    url = url.replace('{0}', thinkletId).replace('{1}', step);

    if (typeof index !== 'undefined')
      url = url.replace('{2}', index);

    return cuethink.post(url, data);
  };

  _me.getMediaURLFromPath = function (path) {
    return config.mediaURL + '/' + path;
  };

  _me.deleteThinkletAudio = function (thinklet, callback) {
    if (thinklet.id < 0)
      return;

    cuethink.delete(config.deleteThinkletAudioURL.replace('{0}', thinklet.id), function () {
      thinklet.audioURL = thinklet.dfs_audio_url = null;
    }, callback ? function () {
      callback(false);
    } : null);
  };

  _me.renderMathML = function (thinkletId, mathML, font, size, callback) {
    var url = config.renderMathMLURL.replace('{0}', thinkletId);

    return cuethink.post(url, { mml: mathML, fontFamily: font, fontSize: size }).then(function (data) {
      var result = _me.getMediaURLFromPath(data.id);

      if (callback)
        callback(result);

      return result;
    });
  };
}

(function () {
  var shared;

  MediaManager.getShared = function (cuethink, config, $thinkletManager, $userManager) {
    if (!shared)
      shared = new MediaManager(cuethink, config, $thinkletManager, $userManager);

    return shared;
  };
})();
