import module from './settings.module';
import './settingsDirective';
import cuethinkSettings from './studentSettingsDirective';
import './settings.css';
import tpl from './teacherSettingsDirective.tpl.html';
import deleteTpl from './deleteAccountPromptDirective.tpl.html';
import { translate } from 'core/common/translation-helpers';
import 'core/common/commonModule';

export default function TeacherSettingsDirective ($q, $translate, $rootScope) {
	return {
		restrict: 'E',
		template: tpl,
        link: function ($scope) {
            cuethinkSettings.link($scope, $q, $translate, $rootScope);

          	$scope.viewModel = {
              hasSaved: false,
          		isUS: $scope.user.zip !== null,
              OTHER: null,
              roles: [
                { id: '104', label: null },
                { id: '109', label: null },
                { id: '106', label: null },
                { id: '111', label: null },
                { id: '101', label: null },
                { id: '110', label: null },
                { id: '112', label: null }
              ],
							schools: null,
              TEACHER: null
						};

          $scope.viewModel.grades = [
            'Pre-K',
            'K',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            'Other',
          ].map(function (grade) {
            return { name: grade, selected: false };
          });

          translate(['TEACHER', 'DEPARTMENT_HEAD', 'INSTRUCTIONAL_COACH', 'TECHNOLOGY_SPECIALIST', 'ADMINISTRATOR', 'CURRICULUM_COORDINATOR', 'PARENT', 'OTHER'], $translate, $rootScope, $scope, function (translations) {
            $scope.viewModel.OTHER = translations.OTHER;
            $scope.viewModel.TEACHER = translations.TEACHER;

            $scope.viewModel.roles[0].label = translations.TEACHER;
            $scope.viewModel.roles[1].label = translations['DEPARTMENT_HEAD'];
            $scope.viewModel.roles[2].label = translations['INSTRUCTIONAL_COACH'];
            $scope.viewModel.roles[3].label = translations['TECHNOLOGY_SPECIALIST'];
            $scope.viewModel.roles[4].label = translations['ADMINISTRATOR'];
            $scope.viewModel.roles[5].label = translations['CURRICULUM_COORDINATOR'];
            $scope.viewModel.roles[6].label = translations['PARENT'];

            var role = $scope.viewModel.roles.find(function (role) {
              return role.label === $scope.user.type;
            });

            if (!role) {
              role = $scope.viewModel.roles.find(function (role) {
                return role.label === $scope.viewModel.TEACHER;
              });
            }

            $scope.viewModel.role = role;
            $scope.userSettings.userType = role.id;

            $scope.viewModel.grades[$scope.viewModel.grades.length - 1].name = translations.OTHER;
          });

          $scope.viewModel.states = [
            {
              "name": "Alabama",
              "abbreviation": "AL"
            },
            {
              "name": "Alaska",
              "abbreviation": "AK"
            },
            {
              "name": "Arizona",
              "abbreviation": "AZ"
            },
            {
              "name": "Arkansas",
              "abbreviation": "AR"
            },
            {
              "name": "California",
              "abbreviation": "CA"
            },
            {
              "name": "Colorado",
              "abbreviation": "CO"
            },
            {
              "name": "Connecticut",
              "abbreviation": "CT"
            },
            {
              "name": "Delaware",
              "abbreviation": "DE"
            },
            {
              "name": "District Of Columbia",
              "abbreviation": "DC"
            },
            {
              "name": "Florida",
              "abbreviation": "FL"
            },
            {
              "name": "Georgia",
              "abbreviation": "GA"
            },
            {
              "name": "Hawaii",
              "abbreviation": "HI"
            },
            {
              "name": "Idaho",
              "abbreviation": "ID"
            },
            {
              "name": "Illinois",
              "abbreviation": "IL"
            },
            {
              "name": "Indiana",
              "abbreviation": "IN"
            },
            {
              "name": "Iowa",
              "abbreviation": "IA"
            },
            {
              "name": "Kansas",
              "abbreviation": "KS"
            },
            {
              "name": "Kentucky",
              "abbreviation": "KY"
            },
            {
              "name": "Louisiana",
              "abbreviation": "LA"
            },
            {
              "name": "Maine",
              "abbreviation": "ME"
            },
            {
              "name": "Maryland",
              "abbreviation": "MD"
            },
            {
              "name": "Massachusetts",
              "abbreviation": "MA"
            },
            {
              "name": "Michigan",
              "abbreviation": "MI"
            },
            {
              "name": "Minnesota",
              "abbreviation": "MN"
            },
            {
              "name": "Mississippi",
              "abbreviation": "MS"
            },
            {
              "name": "Missouri",
              "abbreviation": "MO"
            },
            {
              "name": "Montana",
              "abbreviation": "MT"
            },
            {
              "name": "Nebraska",
              "abbreviation": "NE"
            },
            {
              "name": "Nevada",
              "abbreviation": "NV"
            },
            {
              "name": "New Hampshire",
              "abbreviation": "NH"
            },
            {
              "name": "New Jersey",
              "abbreviation": "NJ"
            },
            {
              "name": "New Mexico",
              "abbreviation": "NM"
            },
            {
              "name": "New York",
              "abbreviation": "NY"
            },
            {
              "name": "North Carolina",
              "abbreviation": "NC"
            },
            {
              "name": "North Dakota",
              "abbreviation": "ND"
            },
            {
              "name": "Ohio",
              "abbreviation": "OH"
            },
            {
              "name": "Oklahoma",
              "abbreviation": "OK"
            },
            {
              "name": "Oregon",
              "abbreviation": "OR"
            },
            {
              "name": "Pennsylvania",
              "abbreviation": "PA"
            },
            {
              "name": "Rhode Island",
              "abbreviation": "RI"
            },
            {
              "name": "South Carolina",
              "abbreviation": "SC"
            },
            {
              "name": "South Dakota",
              "abbreviation": "SD"
            },
            {
              "name": "Tennessee",
              "abbreviation": "TN"
            },
            {
              "name": "Texas",
              "abbreviation": "TX"
            },
            {
              "name": "Utah",
              "abbreviation": "UT"
            },
            {
              "name": "Vermont",
              "abbreviation": "VT"
            },
            {
              "name": "Virginia",
              "abbreviation": "VA"
            },
            {
              "name": "Washington",
              "abbreviation": "WA"
            },
            {
              "name": "West Virginia",
              "abbreviation": "WV"
            },
            {
              "name": "Wisconsin",
              "abbreviation": "WI"
            },
            {
              "name": "Wyoming",
              "abbreviation": "WY"
            }
          ];

            $scope.userSettings.zip = $scope.user.zip;
            $scope.userSettings.state = $scope.user.state;

            if ($scope.userSettings.state) {
              $scope.viewModel.state = $scope.viewModel.states.find(function (state) {
                return state.abbreviation === $scope.userSettings.state;
              });
            }

            $scope.userSettings.schoolName = $scope.user.schoolName;
            $scope.userSettings.schoolSearchId = $scope.user.schoolSearchId;
            //$scope.userSettings.schoolSearchDistrict = $scope.user.schoolSearchDistrict;
            //$scope.userSettings.schoolSearchDistrictId = $scope.user.schoolSearchDistrictId;

            if ($scope.userSettings.zip && $scope.userSettings.state) {
              reloadSchools().then(function () {
                $scope.viewModel.school = $scope.viewModel.schools.find(function (school) {
                  return school.id == $scope.user.schoolSearchId;
                });
              });
            }

            $scope.userSettings.grade = $scope.user.grades;

            if ($scope.userSettings.grade) {
              var grades = $scope.userSettings.grade.split(',');

              $scope.viewModel.grades.forEach(function (grade) {
                if (grades.indexOf(grade.name) !== -1)
                  grade.selected = true;
              });
            }

          function getSelectedGrades () {
            return $scope.viewModel.grades.filter(function (grade) {
              return grade.selected;
            }).map(function (grade) {
              return grade.name;
            });
          }

          var _lastSchoolZipCode = $scope.user.zipCode;
          var _lastSchoolState = $scope.user.state;

          function reloadSchools () {
            var userObj = $scope.userSettings;

            if (!userObj.state || !userObj.zip)
              return;

            if (userObj.state === _lastSchoolState && userObj.zip === _lastSchoolZipCode)
              return;

            resetSchools();

            _lastSchoolZipCode = userObj.zip;
            _lastSchoolState = userObj.state;

            return $scope.loadSchools(userObj.state, userObj.zip).then(function (results) {
              results.push({
                id: null,
                name: 'Other'
              });

              $scope.viewModel.schools = results;
            });
          }

          function resetSchools () {
            $scope.viewModel.schools = null;
            $scope.userSettings.schoolSearchId = null;
            //$scope.userSettings.schoolSearchDistrict = null;
            //$scope.userSettings.schoolSearchDistrictId = null;
          }

          $scope.onIsUSChange = function () {
            if (!$scope.viewModel.isUS) {
              $scope.viewModel.state = null;
              $scope.userSettings.state = null;
              $scope.userSettings.zip = null;
              $scope.viewModel.school = null;
              $scope.userSettings.schoolSearchId = null;
              //$scope.userSettings.schoolSearchDistrict = null;
              //$scope.userSettings.schoolSearchDistrictId = null;
            }
          };

          $scope.onZipBlur = function () {
            reloadSchools();
          };

          $scope.onStateChange = function () {
            $scope.userSettings.state = $scope.viewModel.state.abbreviation;

            reloadSchools();
          };

          $scope.onSchoolChange = function () {
            if ($scope.viewModel.school.id)
              $scope.userSettings.schoolName = $scope.viewModel.school.name;

            $scope.userSettings.schoolSearchId = $scope.viewModel.school.id;
            //$scope.userSettings.schoolSearchDistrict = $scope.viewModel.school.district;
            //$scope.userSettings.schoolSearchDistrictId = $scope.viewModel.school.districtId;
          };

          $scope.getSelectedGrades = function () {
            return getSelectedGrades().join(', ');
          };

          $scope.onRoleChange = function () {
            $scope.userSettings.userType = $scope.viewModel.role.id;
          };

          $scope.onGradeChange = function () {
            $scope.userSettings.grade = $scope.viewModel.grades.filter(function (grade) {
              return grade.selected;
            }).map(function (grade) {
              return grade.name;
            }).join(',');
          };

          $scope.submit = function () {
            var promise = $scope.saveSettings(false);

            $scope.viewModel.hasSaved = true;

            setTimeout(function () {
              $scope.viewModel.hasSaved = false;

              digest();
            }, 5000);
          };

          $scope.onGradeClick = function (event) {
            event.stopPropagation();
          };

          var SETTINGS_SAVED, SAVE_SETTINGS;

          translate(['SETTINGS_SAVED', 'SAVE_SETTINGS'], $translate, $rootScope, $scope, function (translations) {
            SETTINGS_SAVED = translations['SETTINGS_SAVED'];
            SAVE_SETTINGS = translations['SAVE_SETTINGS'];
          });

          $scope.getSaveButtonTitle = function () {
            if ($scope.viewModel.hasSaved)
              return SETTINGS_SAVED;
            else
              return SAVE_SETTINGS;
          };

          function digest () {
            if ($scope.$$phase)
              return;

            try {
              $scope.$digest();
            } catch (e) {
            }
          }

          function bindEvent (el, event, handler) {
            el = $(el);
            el.on(event, handler);
            el.on('remove', function () {
              el.off(event, handler);
            });
          }

          translate('LINK_HTML', $translate, $rootScope, $scope, function (translation) {
            $scope.linkHtml = translation;

            $scope.$$postDigest(function () {
              bindEvent('.clever-link', 'click', function () {
                $scope.linkCleverAccount();
              });

              bindEvent('.google-link', 'click', function () {
                $scope.linkGoogleAccount();
              });
            });
          });
        },
		scope: {
      changePassword: '=',
      deleteAccount: '=',
			linkCleverAccount: '=',
			linkGoogleAccount: '=',
      loadSchools: '=',
      logIn: '=',
      save: '=',
      user: '='
		}
	};
}

module.directive('teacherSettings', TeacherSettingsDirective).directive('deleteAccountPrompt', function () {
  return {
    restrict: 'E',
    replace: true,
    template: deleteTpl
  };
});
