import tpl from './tutorials-popup.tpl.html';
import './tutorials-popup.css';
import '../common/cuethinkPopUpDirective';
import 'angular-translate';
import './images/Equitable Instruction.svg';
import './images/Equitable Instruction Icon.svg';
import './images/Executive Function Icon.svg';
import { setUserModesScopeMembers } from '../common/user-modes';

export default function TutorialsPopupDirective () {
  return {
    link: function ($scope) {
      $scope.viewModel = {

      };

      setUserModesScopeMembers($scope);

      $scope.onClickEducatorModeTutorialButton = function () {
        $scope.startEducatorModeTutorial();
      };

      $scope.onClickEFTutorialButton = function () {
        $scope.startEFTutorial();
      };

      $scope.onClickEquityTipsTutorialButton = function () {
        $scope.startEquityTipsTutorial();
      };
    },
    restrict: 'E',
    scope: {
      'onClose': '&',
      'startEducatorModeTutorial': '=',
      'startEFTutorial': '=startEfTutorial',
      'startEquityTipsTutorial': '=',
      'user': '='
    },
    template: tpl
  }
}
