angular.module('autosave', []).directive('autosave', function ($parse) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      var timer;
      var oldValue;

      function save() {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }

        var newValue;

        if (['checkbox', 'radio'].indexOf(element[0].type) === -1)
          newValue = element.val();
        else
          newValue = element[0].checked;

        if (oldValue !== newValue) {
          oldValue = newValue;

          scope.$eval(attr.autosave);
        }
      }

      var type = element.attr('type');

      if (type == 'text' || (!type && element.prop('tagName').toLowerCase() == 'input') || element.prop('tagName').toLowerCase() == 'textarea') {
        function startTimer() {
          clearTimeout(timer);

          timer = setTimeout(function () {
            save();
          }, 1000);
        }

        function onKeyDown(event) {
          if (event.keyCode == 13 && $(event.target).prop('tagName').toLowerCase() != 'textarea') {
            $(event.target).blur();

            return;
          }

          startTimer();
        }

        function onFocus(event) {
          oldValue = $(event.target).val();
        }

        function onBlur() {
          save();
        }

        element.on('keydown', onKeyDown);
        element.on('focus', onFocus);
        element.on('blur', onBlur);

        element.on('remove', function () {
          element.off('keydown', onKeyDown);
          element.off('focus', onFocus);
          element.off('blur', onBlur);
        });
      } else if (type == 'checkbox' || type == 'radio') {
        function onChange() {
          save();
        }

        element.on('change', onChange);

        element.on('remove', function () {
          element.off('change', onChange);
        });
      }
    }
  };
});
