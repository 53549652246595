import './form.css';
import './lists.css';
import CuethinkButtonToggleDirective from './cuethinkButtonToggleDirective';
import CuethinkCheckboxDropdownDirective from './cuethinkCheckboxDropdownDirective';
import CuethinkDropdownDirective from './cuethinkDropdownDirective';
import CuethinkDropdownMenuDirective from './cuethinkDropdownMenuDirective';
import CueThinkOneTimePopUpDirective from './cuethinkOneTimePopUpDirective';
import CuethinkPopUpDirective from './cuethinkPopUpDirective';
import setUpUiAutoFocusDirective from './form/uiAutoFocus';
import setUpUiEnterDirective from './form/uiEnter';
import setUpUiRequiredDirective from './form/uiRequired';
import CuethinkCheckboxDirective from './directives/cuethinkCheckboxDirective';

const module = angular.module('cuethink.common', [])
  .directive('cuethinkButtonToggle', CuethinkButtonToggleDirective)
  .directive('cuethinkCheckbox', CuethinkCheckboxDirective)
  .directive('cuethinkCheckboxDropdown', CuethinkCheckboxDropdownDirective)
  .directive('cuethinkDropdown', CuethinkDropdownDirective)
  .directive('cuethinkDropdownMenu', CuethinkDropdownMenuDirective)
  .directive('cuethinkOneTimePopUp', CueThinkOneTimePopUpDirective)
  .directive('cuethinkPopUp', CuethinkPopUpDirective);

setUpUiAutoFocusDirective(module);
setUpUiEnterDirective(module);
setUpUiRequiredDirective(module);

export default module;
