import '../contentModule';
import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import './shareProblem.css';
import tpl from './shareProblemDirective.tpl.html';
import 'angular-translate';

angular.module('cuethinkApp.shareProblem', ['pascalprecht.translate']).directive('shareProblem', function (TeacherManager, UserManager, $translate) {
    "ngInject";

    return {
      restrict: 'E',
      template: tpl,
      link: function ($scope) {
          $scope.viewModel = {
            shareUserName: null,
            sharedUsers: [],
            recentSharedUsers: null,
            isSharedUserNameValid: null,
            isSharePromptVisible: false,
            errorMessage: null
          };

          TeacherManager.loadRecentlySharedProblemUsers().then(function (users) {
              $scope.viewModel.recentSharedUsers = users;
          });

          $scope.open = function () {
            $scope.viewModel.shareUserName = null;
            $scope.viewModel.errorMessage = null;
            $scope.viewModel.sharedUsers.splice(0);
            $scope.viewModel.isSharePromptVisible = true;

            if ($scope.viewModel.recentSharedUsers) {
              $scope.viewModel.recentSharedUsers.forEach(function (user) {
                user.selected = false;
              });
            }
          };

          function isEmail (str) {
              return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(str);
          }

        $scope.onSharedUserChange = function () {
            if (!$scope.viewModel.shareUserName)
                return;

            if (isEmail($scope.viewModel.shareUserName)) {
                UserManager.isEmailAvailable($scope.viewModel.shareUserName).then(function (result) {
                    $scope.viewModel.isSharedUserNameValid = !result;
                });
            } else {
                UserManager.isTeacherUsernameAvailable($scope.viewModel.shareUserName).then(function (result) {
                    $scope.viewModel.isSharedUserNameValid = !result;
                });
            }
        };

        $scope.addUser = function () {
            $scope.viewModel.errorMessage = null;

            if (!$scope.viewModel.shareUserName) {
              $translate('YOU_CANNOT_ADD_A_BLANK_USERNAME').then(function (value) {
                $scope.viewModel.errorMessage = value;
              });

              return;
            }

            $scope.viewModel.shareUserName = $scope.viewModel.shareUserName.trim();

            if (!$scope.viewModel.shareUserName) {
              $translate('YOU_CANNOT_ADD_A_BLANK_USERNAME').then(function (value) {
                $scope.viewModel.errorMessage = value;
              });

              return;
            }

            if (!$scope.viewModel.isSharedUserNameValid) {
              $translate('THAT_USERNAME_DOES_NOT_EXIST').then(function (value) {
                $scope.viewModel.errorMessage = value;
              });

              return;
            }

            var myUserName = UserManager.getUser().username.toLowerCase();
            var myEmail = UserManager.getUser().email.toLowerCase();

            if ($scope.viewModel.shareUserName.toLowerCase() === myUserName || $scope.viewModel.shareUserName.toLowerCase() === myEmail) {
              $translate('YOU_CANNOT_SHARE_A_PROBLEM_WITH_YOURSELF').then(function (value) {
                $scope.viewModel.errorMessage = value;
              });

                $scope.viewModel.shareUserName = null;

                return;
            }

            var found, user;

            for (var i = 0; i < $scope.viewModel.sharedUsers.length; i++) {
                user = $scope.viewModel.sharedUsers[i];

                if (user.name === $scope.viewModel.shareUserName) {
                    found = true;

                    break;
                }
            }

            if ($scope.viewModel.recentSharedUsers) {
              for (var i = 0; i < $scope.viewModel.recentSharedUsers.length; i++) {
                user = $scope.viewModel.recentSharedUsers[i];

                if (user.username === $scope.viewModel.shareUserName) {
                  found = true;

                  break;
                }
              }
            }

            if (!found)
                $scope.viewModel.sharedUsers.push({ name: $scope.viewModel.shareUserName, selected: true });

            $scope.viewModel.shareUserName = null;
        };

        $scope.onSharedUserKeyDown = function (event) {
            if (event.keyCode !== 13 && event.keyCode !== 32)
                return;

            $scope.addUser();
        };

        $scope.submitPrompt = function () {
            $scope.addUser();

            if ($scope.canSubmit()) {
                $scope.viewModel.isSharePromptVisible = false;
                $scope.submit();
            }
        };

        $scope.cancelPrompt = function () {
            $scope.viewModel.isSharePromptVisible = false;
            $scope.cancel();
        };

        $scope.getSharedUsers = function () {
            var shareUsers = [];

            var myUserName = UserManager.getUser().username.toLowerCase();

            $scope.viewModel.sharedUsers.forEach(function (user) {
                if (myUserName === user.name)
                    return;

                if (user.selected)
                    shareUsers.push(user.name);
            });

            if ($scope.viewModel.recentSharedUsers) {
              $scope.viewModel.recentSharedUsers.forEach(function (user) {
                if (myUserName === user.username)
                    return;

                if (user.selected)
                  shareUsers.push(user.username);
              });
            }

            return shareUsers;
        };

        $scope.canSubmit = function () {
            return ($scope.viewModel.shareUserName && $scope.viewModel.isSharedUserNameValid === true) || $scope.getSharedUsers().length > 0;
        };
      },
      scope: {
          problemId: '=',
          open: '=',
          getSharedUsers: '=',
          submit: '=',
          cancel: '='
      }
    };
  });
