import WhiteboardPlayer from './whiteboard-player';
import { WhiteboardUi, TimeLabel } from './whiteboardui.jquery';
import './whiteboard.css';
import NotificationCenter from 'core/notifications/notification-center';
import Mousetrap from 'mousetrap';

function WhiteboardPlayerUi (element) {
	var _me = this, _whiteboard, _playButton, _pauseButton, _aspectRatio, _floatHandler, _timeLabel, _scrubber, _timeCodeUpdateCallback, _duration, _scrubberStartTime, _videoURL, _video, _audioURL;
	var scrubberMax = 10000, _playbackStateChangeCallback;

	element = $(element);

	function isScrubberCreated () {
		return !!_scrubber;
	}

	_me.getDuration = function () {
		return _whiteboard.getDuration();
	};

	_me.setAudioURL = function (value) {
		_audioURL = value;

		updateUI();

		if (!_whiteboard)
			return;

		_whiteboard.setAudioURL(value);
	};

	_me.setPlaybackStateChangeCallback = function (value) {
		_playbackStateChangeCallback = value;

		if (!_whiteboard)
			return;

		_whiteboard.setPlaybackStateChangeCallback(function (state) {
			if (_playbackStateChangeCallback)
				_playbackStateChangeCallback(_me, state);

			if (_onStatusChangeCallback && state === 'stopped')
				_onStatusChangeCallback('end playback');
		});
	};

	_me.getTime = function () {
		if (_whiteboard)
			return _whiteboard.getTime();
		else if (_video)
			return _video[0].currentTime;
	};

	var _seekCallback;
	_me.setSeekCallback = function (callback) {
		_seekCallback = callback;
	};

	var _events;
	_me.setEvents = function (value) {
		_events = value;

		if (Array.isArray(value) && !_whiteboard) {
			var canvas = element.find('canvas:first');

			_whiteboard = new WhiteboardPlayer(canvas[0]);
			_whiteboard.setAudioURL(_audioURL);
			_whiteboard.setPlaybackStateChangeCallback(_playbackStateChangeCallback);
			_whiteboard.setImageLoadCallback(_imageLoadCallback);
			_whiteboard.setDuration(_duration);

			_whiteboard.setPreviewEndedCallback(function () {
				_pauseButton.hide();
				_playButton.show();

				_timeLabel.stop();
				stopScrubberTimer();

				if (isScrubberCreated()) {
					try {
						_scrubber.slider('value', scrubberMax);
					} catch (err) {
						console.log(err);
					}
				}

				_timeLabel.setTime(_duration);

				if (_seekCallback)
					_seekCallback(_me, _duration);
			});

			_floatHandler = WhiteboardUi.floatElementOverElement(canvas, element.find('.canvas:first'), function (size) {
				if (_aspectRatio) {
					var h = size.width / _aspectRatio,
						controlsHeight = 50;

					size.height -= controlsHeight;

					if (h > size.height)
						size.width = size.height * _aspectRatio;
					else
						size.height = h;
				}

				return size;
			});
		}

		updateUI();

		if (!_whiteboard)
			return;

		_whiteboard.setEvents(value);
	};

	function updateUI () {
		if ((_events !== undefined && _audioURL !== undefined) || _videoURL) {
			element.find('.loading:first').hide();

			if (_videoURL || _events || _audioURL) {
				element.find('.missing:first').hide();

				if (_videoURL) {
					element.find('.canvas:first').hide();
					element.find('video:first').show();
				} else {
					element.find('.canvas:first').show();
				}

				element.find('.controls:first').addClass('visible');
			} else {
				element.find('.missing:first').show();
			}
		}
	}

	function getElement (ind)  {
		return element.find('.' + ind + ':first');
	}

	function hasEnded () {
		var duration = _whiteboard.getDuration();

		if (duration === false)
			return false;

		if (_whiteboard.hasEnded())
			return true;

		if (_whiteboard.getTime() / duration >= 0.99)
			return true;

		return false;
	}

	function play () {
		if (_whiteboard) {
			if (hasEnded()) {
				_whiteboard.stop();

				try {
					_scrubber.slider('value', 0);
				} catch (e) {
					console.log(e);
				}

				_timeLabel.setTime(0);

				if (_seekCallback)
					_seekCallback(_me, 0);
			}

			_whiteboard.play(function () {
				_playButton.hide();
				_pauseButton.show();

				_duration = _whiteboard.getDuration();

				startScrubber();
				_timeLabel.start();
			});
		} else if (_video) {
			_video.trigger('play');

			_playButton.hide();
			_pauseButton.show();

			if (_playbackStateChangeCallback)
				_playbackStateChangeCallback(_me, 'started');
		}
	}

	function activatePlay() {
		play();

		if (_onStatusChangeCallback)
			_onStatusChangeCallback('start playback');
	}

	function onPlayDown () {
		activatePlay();
	}

	function pause () {
		if (_whiteboard) {
			_whiteboard.pause();

			_timeLabel.pause();
			stopScrubberTimer();
		} else if (_video) {
			_video.trigger('pause');

			if (_playbackStateChangeCallback)
				_playbackStateChangeCallback(_me, 'paused');
		}

		_pauseButton.hide();
		_playButton.show();
	}

	function activatePause() {
		pause();

		if (_onStatusChangeCallback)
			_onStatusChangeCallback('pause playback');
	}

	function onPauseDown () {
		activatePause();
	}

	function isButtonEnabled(button) {
		return button.css('display') !== 'none';
	}

	function togglePlay() {
		if (isButtonEnabled(_playButton))
			activatePlay();
		else
			activatePause();
	}

	_me.setAspectRatio = function (value) {
		_aspectRatio = value;

		if (_floatHandler)
			_floatHandler();
	};

	_playButton = getElement('play');
	_pauseButton = getElement('pause');

	_playButton.on('mouseup', onPlayDown);

	_playButton.on('remove', function (event) {
		$(event.target).off('mouseup', onPlayDown);
	});

	_pauseButton.on('mouseup', onPauseDown);

	_pauseButton.on('remove', function (event) {
		$(event.target).off('mouseup', onPauseDown);
	});

	const keyboardShortcutUnbinds = [];

	function bindKeyboardShortcut(letter, callback) {
		const keys = ['command+shift+alt+' + letter, 'ctrl+shift+alt+' + letter];

		Mousetrap.bind(keys, callback);

		const result = () => {
			Mousetrap.unbind(keys);
		};

		keyboardShortcutUnbinds.push(result);

		return result;
	}

	bindKeyboardShortcut('p', () => {
		togglePlay();
	});

	getElement('fullscreen').on('click', function () {
		if (_whiteboard) {
			// TODO
		} else if (_video) {
			if (_video[0].requestFullScreen)
				_video[0].requestFullScreen();
			else if (_video[0].requestFullscreen)
				_video[0].requestFullscreen();
			else if (_video[0].mozRequestFullScreen)
				_video[0].mozRequestFullScreen();
			else if (_video[0].webkitRequestFullscreen)
				_video[0].webkitRequestFullscreen();
		}
	});

	var _scrubberTimer;

	function incrementScrubber () {
		var newValue, elapsedTime, oldValue, delta;

		if (!isScrubberCreated())
			return;

		try {
			oldValue = _scrubber.slider('value');
			const elapsedTimeMilliseconds = new Date().getTime() - _scrubberStartTime;
			elapsedTime = elapsedTimeMilliseconds / 1000;
			delta = elapsedTime / _duration;
			newValue = oldValue + (delta * scrubberMax);

			_scrubber.slider('value', newValue);

			if (_seekCallback)
				_seekCallback(_me, (newValue * _duration) / scrubberMax);

			if (_scrubberTimer)
				startScrubber();
		} catch (e) {
			console.log(e);
		}
	}

	function startScrubber () {
		_scrubberStartTime = new Date().getTime();
		_scrubberTimer = setTimeout(incrementScrubber, 100);
	}

	function stopScrubberTimer () {
		clearTimeout(_scrubberTimer);
		_scrubberTimer = null;
	}

	_timeLabel = new TimeLabel(getElement('time'));
	_timeLabel.setUpdateCallback(function (time) {
		if (_timeCodeUpdateCallback)
			_timeCodeUpdateCallback(time);
	});

	_me.setTime = function (time) {
		// make sure duration is loaded first
		_whiteboard.loadAudio().then(function () {
			var duration;

			if (_whiteboard)
				duration = _whiteboard.getDuration();
			else if (_video)
				duration = _video[0].duration;
			else
				return;

			if (duration === false)
				return false;

			try {
				_scrubber.slider('value', (time * scrubberMax) / duration); // TODO: cannot call methods on slider prior to initialization; attempted to call method 'value'
			} catch (e) {
				console.log(e);
			}

			setProgress(time, true);
		});
	};

	function setProgress (value, isTime) {
		let time;

		if (isTime)
			time = value;

		if (_whiteboard) {
			var wasPlaying = _whiteboard.isPlaying();
			var duration = _whiteboard.getDuration();

			if (duration === false)
				return false;

			_timeLabel.pause();
			stopScrubberTimer();

			if (time) {
				_whiteboard.setTime(value);
			} else {
				_whiteboard.setProgress(value);

				time = value * duration;
			}

			_timeLabel.setTime(time);

			if (_timeCodeUpdateCallback)
				_timeCodeUpdateCallback(time);

			if (wasPlaying) {
				_timeLabel.start();
				startScrubber();
			}
		} else if (_video) {
			if (!isTime)
				time = value * _video[0].duration;

			_video[0].currentTime = time;

			if (_timeCodeUpdateCallback)
				_timeCodeUpdateCallback(time);
		}
	}

	_scrubber = getElement('scrubber');
	_scrubber.slider({ stop: function (event, ui) {
		setProgress(ui.value / scrubberMax, false);

		if (_onStatusChangeCallback)
			_onStatusChangeCallback('seek playback');
	}, max: scrubberMax });

	_me.setTimeCodeUpdateCallback = function (value) {
		_timeCodeUpdateCallback = value;
	};

	function onTimeUpdate () {
		var time = _video[0].currentTime;

		try {
			_scrubber.slider('value', (time * scrubberMax) / _video[0].duration);
		} catch (e) {
			console.log(e);
		}

		_timeLabel.setTime(time);

		if (_seekCallback)
			_seekCallback(_me, time);
	}

	function onEnded () {
		_pauseButton.hide();
		_playButton.show();

		if (_playbackStateChangeCallback)
			_playbackStateChangeCallback(_me, 'stopped');

		if (_onStatusChangeCallback)
			_onStatusChangeCallback('end playback');
	}

	var _playerSize = {};
	function onLoaded () {
		_video.css('width', _playerSize.width + 'px');
		_video.css('height', _playerSize.height - element.find('.controls:first').height() + 'px');
	}

	_me.setVideoURL = function (value) {
		if (value && _videoURL !== value) {
			_video = element.find('video:first');

			_video.find('source').remove();

			_playerSize.width = _video.parent().width();
			_playerSize.height = _video.parent().height();

			_video.on('timeupdate', onTimeUpdate);
			_video.on('ended', onEnded);
			_video.on('remove', function (event) {
				var t = $(event.target);

				t.off('timeupdate', onTimeUpdate);
				t.off('ended', onEnded);
				t.off('loadedmetadata', onLoaded);
			});
			_video.on('loadedmetadata', onLoaded);

			var source = $('<source>');
			source.attr('type', 'video/mp4');
			source.attr('src', value);
			_video.append(source);

			_video.show();
		}

		_videoURL = value;

		updateUI();
	};

	var _imageLoadCallback;
	_me.setImageLoadCallback = function (value) {
		if (_whiteboard)
			_whiteboard.setImageLoadCallback(value);
		else
			_imageLoadCallback = value;
	};

	var _onStatusChangeCallback;
	_me.setOnStatusChangeCallback = function (value) {
		_onStatusChangeCallback = value;
	};

	_me.play = function () {
		play();
	};

	_me.pause = function () {
		pause();
	};

	_me.setDuration = function (value) {
		if (_whiteboard)
			_whiteboard.setDuration(value);
		else
			_duration = value;
	};

	// BEAC-4918
	// $(window).blur(pause);

	if (typeof NotificationCenter == 'function') {
		var onPauseVideo = function () {
			_me.pause();
		};

		var onSeekVideo = function (value) {
			_me.setTime(value);
		};

		NotificationCenter.getShared().addListener('PauseVideo', onPauseVideo);
		NotificationCenter.getShared().addListener('SeekVideo', onSeekVideo);

		element.on('remove', function () {
			NotificationCenter.getShared().removeListener('PauseVideo', onPauseVideo);
			NotificationCenter.getShared().removeListener('SeekVideo', onSeekVideo);

			keyboardShortcutUnbinds.forEach(unbind => unbind());
		});
	}
}

export default WhiteboardPlayerUi;