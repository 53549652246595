import { render, staticRenderFns } from "./learning-hub-section.vue?vue&type=template&id=07699a32&scoped=true"
import script from "./learning-hub-section.vue?vue&type=script&lang=js"
export * from "./learning-hub-section.vue?vue&type=script&lang=js"
import style0 from "./learning-hub-section.vue?vue&type=style&index=0&id=07699a32&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../beacon/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07699a32",
  null
  
)

export default component.exports