import 'config';
import 'src/vendor/audio-recorder-polyfill';
import 'angular-translate';
import { getShared as getConfigStore } from 'core/common/stores/config.store';
import LogManager from 'core/logging/log-manager';
import permissionsManager from 'core/permissions/permissions';

var cuethinkModule = angular.module('cuethink', ['CueThinkConfig']);

cuethinkModule.factory('CueThink', ['CueThinkConfig', '$state', '$q', '$translate', function (config, $state, $q, $translate) {
  "ngInject";

  return new CueThink(config, $state, $q, $translate);
}]);

function CueThink(config, $state, $q, $translate) {
  var _me = this, _user;

  if (typeof (Storage) !== 'undefined') {
    var user = localStorage.getItem('user');

    if (user) {
      try {
        _user = JSON.parse(user);

        getConfigStore().commit('SET_USER', _user);

        permissionsManager.setPermissions(_user);
      } catch (e) {
      }
    }
  }

  _me.getUser = function () {
    return _user;
  };

  _me.get = function (url, onResponseCallback, error) {
    return makeRequest(url, "GET", false, null, onResponseCallback, error);
  };

  _me.getjson = function (url, queryData, onResponseCallback, error, headers) {
    return makeRequest(url, "GET", 'application/json', queryData, function (data, status, headers) {
      if ((typeof data) == 'string')
        data = JSON.parse(data);

      if (onResponseCallback)
        onResponseCallback(data, status, headers);
    }, error, null, headers);
  };

  _me.post = function (url, postData, onResponseCallback, error, contentType, progress) {
    return makeRequest(url, "POST", contentType, postData, onResponseCallback, error, progress);
  };

  _me.postjson = function (url, postData, onResponseCallback, error) {
    return makeRequest(url, "POST", "application/json", angular.toJson(postData), onResponseCallback, error);
  };

  _me.put = function (url, postData, onResponseCallback, error) {
    return makeRequest(url, "PUT", false, postData, onResponseCallback, error);
  };

  _me.putjson = function (url, postData, onResponseCallback, error) {
    return makeRequest(url, "PUT", "application/json", angular.toJson(postData), onResponseCallback, error);
  };

  _me.delete = function (url, onResponseCallback, error) {
    return makeRequest(url, "DELETE", false, null, onResponseCallback, error);
  };

  _me.deletejson = function (url, postData, onResponseCallback, error) {
    return makeRequest(url, "DELETE", "application/json", postData, onResponseCallback, error);
  };

  function makeRequest(url, method, contentType, pData, success, error, progress, headers) {
    var apiCall = null, processData, args;
    var defer = $q.defer();

    processData = !(pData && pData.append);

    args = {
      type: method,
      data: pData,
      'url': url,
      'processData': processData
    };

    if (progress) {
      args.xhr = function () {
        var xhr = new window.XMLHttpRequest();

        if (xhr.upload) {
          xhr.upload.addEventListener('progress', function (evt) {
            if (evt.lengthComputable)
              progress(evt.loaded / evt.total, evt.loaded, evt.total);
          }, false);
        }

        xhr.addEventListener('progress', function (evt) {
          if (evt.lengthComputable)
            progress(evt.loaded / evt.total, evt.loaded, evt.total);
        }, false);

        return xhr;
      };
    }

    if (((pData && pData.append) || contentType) && method != 'GET') {
      if (!contentType)
        contentType = false;

      args.contentType = contentType;
    }

    try {
      args.crossDomain = true;
      args.xhrFields = { 'withCredentials': true };
      args.headers = headers || { 'Beacon-Request': true };
      apiCall = $.ajax(args);

      bindResponseHandlers(apiCall, url, function (result) {
        defer.resolve(result);

        if (success)
          success(result);
      }, function (result) {
        result = $translate.instant(result);

        if (typeof result === 'object')
          result = Object.values(result);

        defer.reject(result);

        if (error)
          error(result);
        else if (result && result !== 'Offline')
          alert(result);
      });
    } catch (ex) {
      ex = $translate.instant(ex);
      if (error) {
        defer.reject(ex);

        error(ex);
      } else if (error !== false && error !== 'Offline') {
        alert(ex);
      }
    }

    return defer.promise;
  }

  // duplicate function from userManager because angular doesn't allow circular dependency
  _me.setUser = function (value) {
    _user = value;

    permissionsManager.setPermissions(value);

    getConfigStore().commit('SET_USER', value);

    if (typeof(Storage) !== 'undefined') {
      if (value)
        localStorage.setItem('user', JSON.stringify(value));
      else
        localStorage.removeItem('user');
    }
  };

  function bindResponseHandlers (apiCall, url, success, error) {
    apiCall.catch(function(response) {
      if (response.status == 401) {
        _me.setUser(null);

        $state.go('login', { redirect: window.location.href });

        return;
      }

      var headers = parseHeaders(response.getAllResponseHeaders());

      handleError(url, response.responseText, headers, error);
    });

		apiCall.then(function (data, status, response) {
			if (!success && !error)
				return;

      var headers = parseHeaders(response.getAllResponseHeaders());

      if (_me.isResponseValid(data, response.status, headers)) {
        if (success)
          success(((typeof data.data) == 'undefined') ? data : data.data, response.status, headers);
      } else {
        handleError(url, data, headers, error);
      }
    });
  }

  function parseHeaders(text) {
    var headers, result;

    headers = text.split("\n");
    result = {};

    for (var i = 0; i < headers.length; i++) {
      var key, value, split;

      split = headers[i].split(': ');
      key = split[0];
      value = split[1];

      result[key] = value;
    }

    return result;
  }

  _me.isResponseValid = function (data, status, headers) {
    if (!data)
      return false;

    if (status != 200)
      return false;

    var type = headers['Content-Type'];

    if (!type)
      type = headers['content-type'];

    if (type && type.indexOf('application/json') >= 0 && data.succeed === false && !data.authenticated)
      return false;

    return true;
  };

  function handleError(url, data, headers, error) {
    if (url.indexOf('.png') != -1 || url.indexOf('.mp4') != -1)
      return;

    var msg;

    var type = headers['Content-Type'];

    if (!type)
      type = headers['content-type'];

    if (type !== undefined) {
      if (type.indexOf('/json') !== -1) {
        msg = data.message;

        if (!msg && data.messages)
          msg = data.messages;
      } else if (type.indexOf('text/') !== -1) {
        msg = data;
      }

      if (msg && msg.indexOf('504') !== -1)
        msg = config.serverDownMessage;

      if (error !== false) {
        if (error) {
          error(msg);
        } else if (msg) {
          if (typeof msg === 'array') {
            msg = msg.map(function (m) {
              return $translate.instant(m);
            }).join('\n');
          }

          if (msg !== 'Offline')
            alert(msg);
        }
      }
    } else {
      if (error)
        error(data ? data : 'Offline');
    }
  }

  _me.logEvent = function (name, assignmentId, problemId, thinkletId, data) {
    if (!name || !_user)
      return;

    console.log('Log Event: "' + name + '"');

    if (!assignmentId)
      assignmentId = 0;

    if (!problemId)
      problemId = 0;

    if (!thinkletId)
      thinkletId = 0;

    _me.postjson(config.logEventURL, {
      userId: _user.id,
      event: name,
      'assignmentId': assignmentId,
      'problemId': problemId,
      'thinkletId': thinkletId,
      'data': data
    });
  };

  _me.logPLEvent = function (name, unit, slide, calendar, link, intercom) {
    if (!name || !_user)
      return;

    console.log('Log PL Event: "' + name + '"');

    if (!unit)
      unit = 0;

    if (!slide)
      slide = 0;

    if (!link)
      link = "";

    if (!calendar)
      calendar = "";

    if (!intercom)
      intercom = "";

		_me.postjson(config.logPLEventURL, {
			'event': name,
			'unit': unit,
			'slide': slide,
      'calendar': calendar,
      'link': link,
      'intercom': intercom
    });
  };

  _me.logBehaviorEvent = function (name, page, data, object) {
    if (!name || !page)
      return;

    console.log('Log Behavior Event: "' + name + '"');

    _me.postjson(config.logBehaviorURL, {
      data: typeof data !== 'undefined' ? JSON.stringify(data) : null,
      eventType: name,
      ext: null,
      object: typeof object !== 'undefined' ? JSON.stringify(object) : null,
      pageName: page
    });
  };

  _me.logPageEvent = function (name, page, url, previousPageUrl, timeOnPage, thinkletId, problemName) {
    if (!name || !page)
      return;

    console.log('Log Page Event: "' + name + '"');

    _me.postjson(config.logPageEventURL, {
      eventType: name,
      ext: null,
      object: null,
      pageName: page,
      pageUrl: url,
      previousPageUrl,
      problemName,
      thinkletId,
      timeOnPage
    });
  };

  _me.logThinkletEvent = function (name, page, problemId, problemName, thinkletId, data) {
    if (!name || !page)
      return;

    console.log('Log Thinklet Event: "' + name + '"');

    _me.postjson(config.logThinkletEventURL, {
      data: JSON.stringify(data),
      eventType: name,
      ext: null,
      object: null,
      phase: page,
      problemId,
      problemName,
      thinkletId
    });
  };

  LogManager.getShared().addLogHandler(this);

  _me.fetchMaintenance = function () {
    var d = $q.defer();

    _me.get(config.maintenanceMessageURL, function (result) {
      d.resolve(result);
    }, function (result) {
      d.reject(result);
    });

    return d.promise;
  };
}

export default cuethinkModule;
