export default function AudioRecorder () {
  var _audioRecorder, _stopCallback, _url, _logCallback;

  this.setLogCallback = function (value) {
    _logCallback = value;
  };

  function isMobileSafari () {
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /ipad|iphone/.test(userAgent);

    if (ios && !standalone && safari) {
      return true;
    } else {
      return false;
    }
  }

  function initAudio (callback) {
    var getUserMedia = AudioRecorder.getUserMedia();

    if (!getUserMedia) {
      _error = 'No microphone';

      callback(false);

      return false;
    }

    var constraints;

    if (isMobileSafari()) {
      constraints = {
        audio: true
      };
    } else {
      constraints = {
        "audio": {
          "mandatory": {
            "googEchoCancellation": "false",
            "googAutoGainControl": "false",
            "googNoiseSuppression": "false",
            "googHighpassFilter": "false"
          },
          "optional": []
        }
      };
    }

    // ensure audio context is created in response to user click for safari
    _audioRecorder = new MediaRecorder();

    return getUserMedia(constraints, function (stream) {
      _audioRecorder.setStream(stream);

      _audioRecorder.addEventListener('dataavailable', function (e) {
        if (_logCallback)
          _logCallback('Audio Data Available', e.data.size);

        _url = URL.createObjectURL(e.data);

        if (_stopCallback) {
          _stopCallback(e.data);

          _stopCallback = null;
        }
      });

      if (callback)
        callback(true);
    }, function (e) {
      _audioRecorder = null;

      if (_logCallback)
        _logCallback('Audio Recording Error', JSON.stringify(e));

      _error = e.name;

      console.log(e);

      callback(false);
    });
  }

  this.start = function (callback) {
    return !!initAudio(function (success) {
      if (success) {
        _audioRecorder.start();

        if (_logCallback)
          _logCallback('Audio Recording Started');

        if (callback)
          callback(true);
      } else {
        if (callback)
          callback(false);

        return false;
      }
    });
  };

  this.pause = function (callback) {
    if (_audioRecorder) {
      _audioRecorder.pause();

      if (_logCallback)
        _logCallback('Audio Recording Paused');

      if (callback)
        callback(true);
    } else {
      return false;
    }

    return true;
  };

  this.resume = function (callback) {
    if (_audioRecorder) {
      _audioRecorder.resume();

      if (_logCallback)
        _logCallback('Audio Recording Resumed');

      if (callback)
        callback(true);

      return true;
    } else {
      return false;
    }
  };

  this.stop = function (callback) {
    if (!this.isRecording() && !this.isPaused()) {
      if (callback)
        callback(false);

      return;
    }

    if (_audioRecorder) {
      _stopCallback = callback;

      _audioRecorder.stop();

      if (_logCallback)
        _logCallback('Audio Recording Stopped');
    } else if (callback) {
      callback(false);
    }
  };

  this.getURL = function () {
    return _url;
  };

  this.isRecording = function () {
    return _audioRecorder && _audioRecorder.state === 'recording';
  };

  this.isPaused = function () {
    return _audioRecorder && _audioRecorder.state === 'paused';
  };

  var _error;
  this.getError = function () {
    if (_error)
      return _error;
    else if (!AudioRecorder.hasMicrophone())
      return 'No microphone';
    else
      return false;
  };

  this.disconnect = function () {
    if (_audioRecorder) {
      _audioRecorder.stop();
      _audioRecorder = null;
    }
  };
}

AudioRecorder.getUserMedia = function () {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    if (!navigator.getUserMedia)
      navigator.getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    if (!navigator.getUserMedia)
      return false;
  }

  if (!navigator.cancelAnimationFrame)
    navigator.cancelAnimationFrame = navigator.webkitCancelAnimationFrame || navigator.mozCancelAnimationFrame;

  if (!navigator.requestAnimationFrame)
    navigator.requestAnimationFrame = navigator.webkitRequestAnimationFrame || navigator.mozRequestAnimationFrame;

  return function (params, callback, error) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
      return navigator.mediaDevices.getUserMedia(params).then(callback).catch(error);
    else
      return navigator.getUserMedia(params, callback, error);
  };
};

AudioRecorder.hasMicrophone = function () {
  return !!AudioRecorder.getUserMedia();
};
