import 'core/settings/settingsDirective';
import '../navigation/teachernavbarController';
import 'src/components/shared/teacher_data';
import 'src/components/shared/user_data';
import 'src/app/urls';

export default function GlobalSettingsController ($scope, $controller, TeacherManager, UserManager, $state, CueThinkConfig) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  $scope.viewModel = {
    defaultAnnotationSentenceStarters: CueThinkConfig.defaultSettings.viewingThinklets.annotations.sentenceStarters.slice(0),
    defaultPlanSentenceStarters: CueThinkConfig.defaultSettings.creatingThinklets.plan.sentenceStarters.map(function (starter) { return starter.text; }),
    defaultPlanStrategies: CueThinkConfig.defaultSettings.creatingThinklets.plan.strategies.map(function (strategy) { return strategy.text; })
  };

  $scope.changePassword = UserManager.changeUserPassword;

  $scope.deleteAccount = function () {
    return UserManager.deleteCurrentUser(function () {
      $scope.logOut(true);
    });
  };

  $scope.linkClever = function () {
    $state.go('linkclever');
  };

  $scope.linkGoogle = function () {
    $state.go('linkaccount', { service: 'google' });
  };

  $scope.user = UserManager.getUser();

  $scope.loadClasses = function () {
    return TeacherManager.loadClasses().then(function (classes) {
      return classes.filter(function (cls) {
        return cls.createBy === $scope.user.id;
      });
    });
  };

  $scope.loadSchools = UserManager.loadNearbySchools;

  $scope.loadSettings = function () {
    return UserManager.getGlobalSettings().then(function (settings) {
      return JSON.parse(JSON.stringify(settings));
    });
  };

  $scope.logIn = function () {
    $scope.logOut(true);
  };

  $scope.save = UserManager.saveUserSettings;

  $scope.saveSettings = function (settings) {
    return UserManager.saveGlobalSettings(settings);
  };

  $scope.loadHasSeenWelcomePopUp = TeacherManager.loadHasSeenSettingsWelcomePopUp;
}

angular.module('cuethinkApp.settings', ['cuethink.settings', 'cuethinkApp.teacherNavController'])
  .controller('GlobalSettingsController', GlobalSettingsController);
