import 'src/components/shared/thinklet_data';
import GoogleSignIn from 'core/login/google-sign-in';
import 'src/components/shared/user_data';
import { CueThinkLogin } from 'core/login/loginDirective';
import CueThinkConfig from 'config';
import '../shared/teacher_data';
import { isEFUser, isEF23User, setUserModesScopeMembers } from 'core/common/user-modes';
import {Permissions} from 'core/permissions/permissions';

export default function ($scope, $controller, ThinkletManager, $state, $stateParams, UserManager, TeacherManager, PermissionsManager) {
  "ngInject";

  var assignmentId = $stateParams.id;
  var problemId = $stateParams.problemId;
  var classId = $stateParams.classId;

  if (classId) {
    problemId = assignmentId;

    assignmentId = null;
  }

  setUserModesScopeMembers($scope, UserManager.getUser);

  var createThinklet = function (assignment, problem) {
    ThinkletManager.createThinklet(problem, assignment, function (thinklet) {
      if (PermissionsManager.getPermission(Permissions.FourPhaseMenu))
        $state.go('four-phase-menu', { id: thinklet.id });
      else
        $state.go('understand', { id: thinklet.id });
    }).catch(function () {
      $state.go('dashboard');
    });
  };

  var editThinklet = function (versions) {
    var lastVersion;

    for (var i = 0; i < versions.length; i++) {
      var version = versions[i];

      if (!lastVersion || version.thinklet_version > lastVersion.thinklet_version)
        lastVersion = version;
    }

    ThinkletManager.removeCachedThinklet(lastVersion);

    if (PermissionsManager.getPermission(Permissions.FourPhaseMenu))
      $state.go('four-phase-menu', { id: lastVersion.id });
    else
      $state.go('understand', { id: lastVersion.id });
  };

  var decideToCreateOrEditThinklet = function (assignment, problem) {
    ThinkletManager.loadUserThinklets(UserManager.getUser() ? UserManager.getUser().id : null).then(function (thinklets) {
      var versions = [];

      for (var i = 0; i < thinklets.length; i++) {
        var thinklet = thinklets[i];

        if (thinklet.assignment_id === assignment.id)
          versions.push(thinklet);
      }

      if (versions.length > 0)
        editThinklet(versions);
      else
        createThinklet(assignment, problem);
    });
  };

  var proceed = function () {
    var assignmentPromise;

    if (classId) {
      var now = new Date();

      assignmentPromise = TeacherManager.submitAssignment(problemId, now, new Date(now.getTime() + (2 * 7 * 24 * 60 * 60 * 1000)), [classId]);
    } else if (assignmentId) {
      assignmentPromise = ThinkletManager.loadAssignment(assignmentId);
    } else if (problemId) {
      assignmentPromise = ThinkletManager.fetchAssignments().then(function (assignments) {
        var assignment = assignments.find(function (assignment) {
          return assignment.problemId == problemId;
        });

        if (assignment)
          return assignment;
        else
          return ThinkletManager.createAssignmentFromProblem(problemId);
      });
    } else {
      $state.go('dashboard');

      return;
    }

    assignmentPromise.then(function (assignment) {
      ThinkletManager.loadProblem(assignment.problemId).then(function (problem) {
        decideToCreateOrEditThinklet(assignment, problem);
      }).catch(function () {
        $state.go('dashboard');
      });
    }).catch(function () {
      $state.go('dashboard');
    });
  };

  if (UserManager.getUser()) {
    proceed();
  } else {
    UserManager.isCleverAuthenticated().then(function (result) {
      if (result) {
        var cleverAuthenticate = function () {
          var args = [];

          for (var i = 0; i < arguments.length; i++)
            args.push(arguments[i]);

          return UserManager.authenticateClever.apply(null, args);
        };

        CueThinkLogin.cleverAuthenticate(cleverAuthenticate, CueThinkConfig.cleverRedirectURL, CueThinkConfig.cleverClientID, function (success, user) {
          if (success)
            proceed();
          else
            $state.go('login', { redirect: $state.href($state.current.name, $stateParams) });
        });
      } else {
        new GoogleSignIn(function (user, isMobile, callback) {
          return UserManager.oAuthLogIn(user.token, isMobile, null, callback);
        }, function (user, success, message) {
          if (success)
            proceed();
          else
            $state.go('login', { redirect: $state.href($state.current.name, $stateParams) });
        }, null, true);
      }
    });
  }
}
