import 'angular-translate';
import { CueThinkChangePassword } from 'core/login/forgotpassword/changePasswordDirective';
import './createStudentPopUpDirective.css';
import tpl from './createStudentPopUpDirective.tpl.html';
var cloneDeep = require('lodash.clonedeep');

export function setUpCreateStudentPopUpDirective(module) {
  module.directive('createStudentPopUp', CreateStudentPopUpDirective);
}

export function CreateStudentPopUpDirective($translate) {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        classes: null,
        isConfirmPasswordVisible: false,
        isPasswordVisible: false,
        student: {}
      };

      $scope.viewModel.classes = cloneDeep($scope.classes.slice(0));

      if ($scope.class) {
        $scope.viewModel.classes.find(function (cls) {
          if (cls.invitationCode === $scope.class.invitationCode) {
            cls.selected = true;

            return true;
          }

          return false;
        });
      }

      function isNameValid (name) {
        if (!name) {
        } else if (/[^A-Za-z\-' ]/.test(name)) {
        } else {
          return true;
        }

        return false;
      }

      $scope.canManageClass = function (cls) {
        return !!$scope.openClasses.find(function  (c) {
          return c.id === cls.id;
        });
      };

      $scope.getStudentClassesText = function () {
        return $scope.viewModel.classes.filter(function (cls) {
          return cls.selected && $scope.canManageClass(cls);
        }).map(function (cls) {
          return cls.name;
        }).join(', ');
      };

      $scope.togglePasswordVisibility = function () {
        $scope.viewModel.isPasswordVisible = !$scope.viewModel.isPasswordVisible;
      };

      $scope.toggleConfirmPasswordVisibility = function () {
        $scope.viewModel.isConfirmPasswordVisible = !$scope.viewModel.isConfirmPasswordVisible;
      };

      $scope.submit = function () {
        var student = $scope.viewModel.student;

        if (!isNameValid(student.firstName)) {
          $translate('FIRST_NAME_IS_NOT_VALID').then(function (msg) {
            alert(msg);
          });

          return false;
        }

        if (!CueThinkChangePassword.validatePassword(student.password, student.password2))
            return false;

        var classes = $scope.viewModel.classes.filter(function (cls) {
          return cls.selected;
        });

        $scope.onSubmit({ classes: classes, student: student });
      };
    },
    scope: {
      class: '=',
      classes: '=',
      openClasses: '=',
      onCancel: '&',
      onClose: '&',
      onSubmit: '&'
    },
    template: tpl
  };
}
