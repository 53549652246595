<template>
  <div class="learning-hub">
    <learning-hub-section
      v-if="selectedSection"
      :section="selectedSection"
      @back="onSectionBack"
      @next="onSectionNext">
    </learning-hub-section>
    <div
      v-else
      class="landing">
      <h1 class="title">{{ 'LEARNING_HUB' | translate }}</h1>

      <p class="welcome">{{ 'WELCOME_TO_THE_LEARNING_HUB_ACCESS_RESOURCES_TO_HELP_YOU_GET_UP_AND_RUNNING_WITH_CUETHINK' | translate }}</p>

      <div class="buttons">
        <button
          v-for="(section, index) in sections"
          tabindex="0"
          :style="{ 'background-color': section.foregroundColor, 'background-image': 'url(' + section.smallBackgroundImage + ')' }"
          @click="onSectionButtonClick(section)">
          <span
            v-if="index < sections.length - 1"
            class="number-icon"
            :style="{ color: section.foregroundColor }">
            {{ index + 1 }}
          </span>

          <span class="title">
            {{ section.title | translate }}
            </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import translate from 'core/common/filters/translate.filter';
import LearningHubSection from './learning-hub-section';

export default {
  components: {
    LearningHubSection
  },

  filters: {
    translate
  },

  props: {
    section: String
  },

  data() {
    return {
      sections: [
        {
          backgroundColor: '#FFECE1',
          backgroundImage: require('./images/Roster_Pattern.svg'),
          description: 'LETS_ROSTER_YOUR_STUDENTS_CHOOSE_THE_OPTION_THAT_WORKS_BEST_FOR_YOU',
          foregroundColor: '#FF936D',
          items: [
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747676787?h=d0f1aaae6c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                },
                {
                  title: 'POSTER',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/CueThink%20Registration%20Poster.pdf'
                }
              ],
              icon: require('./images/ManualStudentRegistration.svg'),
              title: 'MANUAL_STUDENT_REGISTRATION'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747674033?h=2b4e239551&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/SyncFromGoogleClassroom.svg'),
              title: 'SYNC_FROM_GOOGLE_CLASSROOM'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747678215?h=8a81b793d5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/UploadCSV.svg'),
              title: 'UPLOAD_CSV_FILE'
            }
          ],
          smallBackgroundImage: require('./images/Roster_Pattern_Small.svg'),
          title: 'ROSTER'
        },

        {
          backgroundColor: '#F7EFFF',
          backgroundImage: require('./images/AssignPattern.svg'),
          description: 'WATCH_THE_VIDEOS_TO_ASSIGN_THE_SCAVENGER_HUNT_AND_YOUR_1ST_TASK',
          foregroundColor: '#C686FF',
          items: [
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747677494?h=2741006b34&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                },
                {
                  title: 'INSTRUCTIONS',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/ScavengerHunt.pdf'
                }
              ],
              icon: require('./images/ScavengerHunt_Icon.svg'),
              title: 'SCAVENGER_HUNT'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747671407?h=13a8daf1f1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/1stTask_Icon.svg'),
              title: '1ST_TASK'
            }
          ],
          smallBackgroundImage: require('./images/AssignPattern_Small.svg'),
          title: 'ASSIGN'
        },

        {
          backgroundColor: '#F6FFF9',
          backgroundImage: require('./images/Implement_Pattern.svg'),
          description: 'WATCH_THE_VIDEOS_TO_USE_CUETHINK_WITH_STUDENTS',
          foregroundColor: '#34B8A1',
          items: [
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747671796?h=51f3a8a394&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                },
                {
                  title: 'POSTER',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/SolvePhaseIconsPoster.pdf'
                }
              ],
              icon: require('./images/launchatask_icon.svg'),
              title: 'LAUNCH_A_TASK'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747674801?h=f66b37b59c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/Access Educator Guide_icon.svg'),
              title: 'ACCESS_FACILITATOR_GUIDE'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747673211?h=444905f3bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                },
                {
                  title: 'POSTER',
                  url: 'http://file.cuethink.com/documents/cuethink_resources/Annotation_Guiding_Questions.pdf'
                }
              ],
              icon: require('./images/Peer2Peer_Icon.svg'),
              title: 'PEER_2_PEER_MATH_DISCUSSION'
            }
          ],
          smallBackgroundImage: require('./images/Implement_Pattern_Small.svg'),
          title: 'IMPLEMENT'
        },

        {
          backgroundColor: '#e5eeff',
          backgroundImage: require('./images/Resources_Pattern.svg'),
          description: 'CHECK_OUT_RESOURCES_TO_HELP_YOU_USE_CUETHINK',
          foregroundColor: '#4385f5',
          items: [
            {
              buttons: [
                {
                  title: 'POSTER',
                  url: 'http://file.cuethink.com/documents/cuethink_resources/Annotation_Guiding_Questions.pdf'
                }
              ],
              icon: require('./images/Annotation_PosterIcon.svg'),
              title: 'ANNOTATION_POSTER'
            },
            {
              buttons: [
                {
                  title: 'INSTRUCTIONS',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/SolvePhaseRecordingTips-v5-LH.pdf'
                }
              ],
              icon: require('./images/MicrophoneAccess_Icon.svg'),
              title: 'MICROPHONE_ACCESS'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747671056?h=06bc80abdb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/InAppMessaging_Icon.svg'),
              title: 'IN_APP_MESSAGING'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747672819?h=a686ed62a7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                }
              ],
              icon: require('./images/CreateYourOwnTasks_Icon.svg'),
              title: 'CREATE_YOUR_OWN_TASKS'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747675694?h=6f2824fda5&badge=0&autopause=0&player_id=0&app_id=58479/embed%22'
                }
              ],
              icon: require('./images/FindingATaskIcon.svg'),
              title: 'FINDING_A_TASK'
            },
            {
              buttons: [
                {
                  title: 'POSTER',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/SolvePhaseIconsPoster.pdf'
                }
              ],
              icon: require('./images/SolvePhaseIcons.svg'),
              title: 'SOLVE_PHASE_ICONS'
            },
            {
              buttons: [
                {
                  title: 'POSTER',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/CueThink%20Registration%20Poster.pdf'
                }
              ],
              icon: require('./images/ManualRegistrationIcon.svg'),
              title: 'MANUAL_REGISTRATION_POSTER'
            },
            {
              buttons: [
                {
                  title: 'VIDEO',
                  url: 'https://player.vimeo.com/video/747677494?h=2741006b34&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22'
                },
                {
                  title: 'INSTRUCTIONS',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/ScavengerHunt.pdf'
                }
              ],
              icon: require('./images/ScavengerIcons.svg'),
              title: 'SCAVENGER_HUNT'
            },
            {
              buttons: [
                {
                  title: 'POSTER',
                  url: 'https://file.cuethink.com/documents/cuethink_resources/SuggestedLessonStructure.jpg'
                }
              ],
              icon: require('./images/ExampleCTClass_Icon.svg'),
              title: 'EXAMPLE_CUETHINK_CLASS'
            }
          ],
          smallBackgroundImage: require('./images/Resources_Pattern_Small.svg'),
          title: 'RESOURCES'
        }
      ],

      selectedSection: null
    };
  },

  created() {
    this.selectedSection = this.sections.find(section => section.title === this.section);
  },

  methods: {
    onSectionBack() {
      const i = this.sections.indexOf(this.selectedSection);

      if (i === 0)
        this.selectSection(null);
      else
        this.selectSection(this.sections[i - 1]);
    },

    onSectionButtonClick(section) {
      this.selectSection(section);
    },

    onSectionNext() {
      const i = this.sections.indexOf(this.selectedSection);

      if (i === this.sections.length - 1)
        this.selectSection(null);
      else
        this.selectSection(this.sections[i + 1]);
    },

    selectSection(section) {
      this.selectedSection = section;

      this.$emit('select-section', section);
    }
  }
}
</script>

<style scoped lang="scss">
.learning-hub {
  height: 100%;

  .landing {
    padding: 70px 150px;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-grow: 1;

    & > .title {
      text-align: center;
      color: #363845;
      font-size: 24px;
      margin-bottom: 12px;
    }

    .welcome {
      text-align: center;
      font-size: 17px;
    }

    .buttons {
      position: relative;
      margin-top: 27px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      flex-grow: 1;
      align-content: start;

      button {
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: bottom right;
        text-align: left;
        padding: 20px;
        color: #363845;
        font-size: 20px;
        font-weight: 700;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
        flex-direction: column;
        -webkit-flex-direction: column;

        &:hover {
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
        }

        &:not(:last-child) {
          height: 62%;
          min-height: 260px;
          margin-bottom: 20px;
          margin-right: 20px;
          display: inline-flex;
          display: -webkit-inline-flex;
          background-size: 115px;
          flex-grow: 1;

          &:nth-last-child(2) {
            margin-right: 0;
          }
        }

        &:last-child {
          width: 100%;
          height: 35%;
          min-height: 140px;
          padding: 0 50px;
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          -webkit-justify-content: center;
          background-size: 180px 150px;
        }

        .number-icon {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 15px;
          background-color: white;
          line-height: 31px;
          text-align: center;
          font-weight: 700;
          font-size: 16px;
        }

        .title {
          background-repeat: no-repeat;
          background-position: top left;
          background-size: 30px 30px;
        }
      }
    }
  }
}
</style>