import '../navigation/navbar';
import './assignments.css';
import tpl from './assignmentsDirective.tpl.html';
require('./assignment_problem.partial.html');

angular.module('cuethink.assignments', ['cuethink.navigation']).directive('assignments', function () {
	return {
		restrict: 'E',
		transclude: true,
		template: tpl,
		link: function ($scope) {
			$scope.currentAssignments = [];
			$scope.closedAssignments = [];

			function digest () {
				if ($scope.$$phase)
					return;

				try {
					$scope.$digest();
				} catch  (e) {
				}
			}

			{
				var success = function (data) {
					for (var j = 0; j < data.length; j++)
						if ( data[j].status === "created" )
							$scope.currentAssignments.push(data[j]);

					for (j = 0; j < data.length; j++)
						if ( data[j].status === "completed" )
							$scope.closedAssignments.push(data[j]);

					digest();
				};

				var promise = $scope.loadAssignments(success);

				if (promise)
					promise.then(success);
			}

			$scope.selectAssignment = function (assignment) {
				assignment.selected = !assignment.selected;

				if ($scope.selectedAssignment == assignment)
					$scope.selectedAssignment = null;
				else
					$scope.selectedAssignment = assignment;
			};

			$scope.selectProblem = function (problem, assignment) {

				var success = function (problem) {
					var success = function (thinklet) {
						$scope.startThinklet(thinklet);
					};

					var promise = $scope.createThinklet(problem, assignment, success);

					if (promise)
						promise.then(success);
				};

				var promise = $scope.loadProblem(problem.problemId);

				if (promise)
					promise.then(success);
			};
		},
		scope: {
			createThinklet: '=',
      dashboard: '=',
			defaultAvatarColors: '=',
			fetchHasSeenTrialEndPopUp: '=',
			gallery: '=',
			getTrialDays: '=',
      isCueteachEnabled: '=',
			loadAssignments: '=',
      loadProblem: '=',
			logOut: '=logout',
			navigateJoinClass: '=',
			navigateRubric: '=',
			navigateToTeacherDashboard: '=teacherDashboard',
			onSubmitMindWanderingProbe: '&',
      profile: '=',
			setHasSeenTrialEndPopUp: '=',
      settings: '=',
			setUpNavigation: '=',
			startThinklet: '=',
			user: '='
		}
	};
});
