import 'angular-ui-router';
import 'angular-translate';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/teacher_data';

const reportsModule = angular.module('cuethinkApp.reports', ['teacherManager', 'userManager', 'thinkletManager']);

reportsModule.config(function ($stateProvider) {
  const states = {
    'monitor-report': {
      url: '/monitor-report',
      controller: require('./monitor-report.controller').default,
      template: require('./monitor-report.tpl.html')
    }
  };

  Object.keys(states).forEach(key => {
    $stateProvider.state(key, states[key]);
  });
});

reportsModule.directive('monitorReportDirective', require('./monitor-report.directive').default);

export default reportsModule;
