import '../manageContent.css';

function ContentHeaderController ($scope) {
    "ngInject";

    ContentHeaderController.activeStep = 1;

    $scope.isStepOneActive = function () {
        return ContentHeaderController.activeStep === 1;
    };

    $scope.isStepTwoActive = function () {
        return ContentHeaderController.activeStep === 2;
    };

    $scope.isStepThreeActive = function () {
        return ContentHeaderController.activeStep === 3;
    };

    $scope.isStepTwoEnabled = function () {
        return ContentHeaderController.isStepOneFormValid;
    };

    $scope.isStepThreeEnabled = function () {
        return ContentHeaderController.isStepOneFormValid && ContentHeaderController.isStepTwoFormValid;
    };

    $scope.goToStepOne = ContentHeaderController.goToStepOne;

    $scope.goToStepTwo = function () {
        if (!$scope.isStepTwoEnabled())
            return;

        ContentHeaderController.goToStepTwo();
    };

    $scope.goToStepThree = function () {
        if (!$scope.isStepThreeEnabled())
            return;

        ContentHeaderController.goToStepThree();
    }
}

ContentHeaderController.goToStepOne = function () {
    $('.popover').hide();
    ContentHeaderController.activeStep = 1;
};

ContentHeaderController.goToStepTwo = function () {
    $('.popover').hide();

    ContentHeaderController.activeStep = 2;
};

ContentHeaderController.goToStepThree = function () {
    $('.popover').hide();

    ContentHeaderController.activeStep = 3;
};

export default ContentHeaderController;
