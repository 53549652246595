import '../common/commonModule';
import tpl from './trialEndPopUpDirective.tpl.html';

function TrialEndPopUpDirective () {
  "ngInject";

  return {
    restrict: 'E',
    scope: {
      onClose: '&',
      onSubmit: '&',
      user: '='
    },
    template: tpl,
    link: function ($scope) {
      $scope.showIntercom = function () {
        $scope.logInIntercom();

        try {
            Intercom('show');
        } catch (e) {
        }
      };

      $scope.onTrialEndPopUpSubmit = function () {
        $scope.onSubmit();

        window.open('mailto:mmoller@cuethink.com', '_blank');
      };

      $scope.onTrialEndPopUpClose = function () {
        $scope.onClose();
      };

			$scope.isTeacherOrParent = function () {
				return $scope.user.type === 'Teacher' || $scope.user.type === 'Parent';
			};
    }
  };
}

export default angular.module('cuethink.trial', ['cuethink.common'])
    .directive('trialEndPopUp', TrialEndPopUpDirective)
    .filter('propercase', function () {
      return function (str) {
        if (!str)
          return str;

        return str.substr(0, 1).toUpperCase() + (str.length > 1 ? str.substr(1).toLowerCase() : '');
      };
    });
