import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import './resourceBank.css';
import tpl from './resourceBankDirective.tpl.html';
import _ from 'underscore';

function ResourceBankDirective () {
    "ngInject";

    var linkFunction = function ($scope) {
        $scope.viewModel = {};

        $scope.$watch('resources', function () {
            if (!$scope.resources)
                return;

            $scope.filteredResources = $scope.resources;

            var items = [];

            _.each($scope.resources, function (resource) {
                if (!resource.topic)
                    return;

                var topics = resource.topic.split(', ');

                for (var i = 0; i < topics.length; i++)
                    items.push(topics[i].trim());
            });

            items = _.uniq(items, false, function (item) {
                return item.toLowerCase();
            });

            $scope.topics = _.map(items, function (topic) {
                return { name: topic };
            });

            items = [];

            _.each($scope.resources, function (resource) {
                if (!resource.type)
                    return;

                var types = resource.type.split(', ');

                for (var i = 0; i < types.length; i++)
                    items.push(types[i].trim());
            });

            items = _.uniq(items, false, function (item) {
                return item.toLowerCase();
            });

            $scope.types = _.map(items, function (type) {
                return { name: type };
            });

            var resource;

            if ($scope.resourceName) {
                resource = _.find($scope.resources, function (resource) {
                    return resource.name.trim().toLowerCase() === $scope.resourceName.trim().toLowerCase();
                });
            }

            if (!resource && $scope.resources.length > 0)
                resource = $scope.resources[0];

            $scope.selectResource(resource);
        }, true);

        $scope.filter = function () {
            $scope.filteredResources = _.filter($scope.resources, function (resource) {
                if ($scope.viewModel.searchTerm) {
                    var term = $scope.viewModel.searchTerm.toLowerCase();

                    if (!resource.name.toLowerCase().includes(term) &&
                        !resource.topic.toLowerCase().includes(term) &&
                        !resource.type.toLowerCase().includes(term) &&
                        !resource.description.toLowerCase().includes(term)) {
                        return false;
                    }
                }

                var selectedTopics = $scope.getTopicFilters();

                var match;

                if (selectedTopics.length > 0) {
                    match = _.find(selectedTopics, function (topic) {
                        return resource.topic.indexOf(topic.name) != -1;
                    });

                    if (!match)
                        return false;
                }

                var selectedTypes = $scope.getTypeFilters();

                if (selectedTypes.length > 0) {
                    match = _.find(selectedTypes, function (type) {
                        return resource.type.indexOf(type.name) != -1;
                    });

                    if (!match)
                        return false;
                }

                return true;
            });

            if ($scope.filteredResources.indexOf($scope.selectedResource) == -1)
                $scope.selectedResource = null;
        };

        $scope.toggleTopics = function ($event) {
            $scope.typesOpen = false;
            $scope.topicsOpen = !$scope.topicsOpen;

            if ($scope.topicsOpen) {
                var menu = $('.topic ul:first');

                menu.css('min-width', $($event.target).width() + 'px');
            }

            $event.stopPropagation();

            return false;
        };

        $scope.toggleTopic = function (topic, $event) {
            topic.selected = !topic.selected;

            $scope.filter();

            $event.stopPropagation();

            return false;
        };

        $scope.toggleTypes = function ($event) {
            $scope.topicsOpen = false;
            $scope.typesOpen = !$scope.typesOpen;

            if ($scope.typesOpen) {
                var menu = $('.type ul:first');

                menu.css('min-width', $($event.target).width() + 'px');
            }

            $event.stopPropagation();

            return false;
        };

        $scope.toggleType = function (type, $event) {
            type.selected = !type.selected;

            $event.stopPropagation();

            $scope.filter();

            return false;
        };

        $scope.selectResource = function (resource) {
            if ($scope.selectedResource == resource)
                $scope.selectedResource = null;
            else
                $scope.selectedResource = resource;

            if ($scope.onResourceChange)
                $scope.onResourceChange(resource);
        };

        $scope.getTopicFilters = function () {
            return _.filter($scope.topics, function (topic) {
                return topic.selected;
            });
        };

        $scope.getTypeFilters = function () {
            return _.filter($scope.types, function (type) {
                return type.selected;
            });
        };

        $scope.removeFilter = function (filter) {
            if (filter)
                filter.selected = false;
            else
                $scope.viewModel.searchTerm = null;

            $scope.filter();
        };

        $scope.clearFilter = function () {
            $scope.viewModel.searchTerm = null;

            _.each($scope.topics, function (topic) {
                topic.selected = false;
            });

            _.each($scope.types, function (type) {
                type.selected = false;
            });

            $scope.filter();
        };

        function bindEvent(el, name, handler) {
            el = $(el);
            el.on(name, handler);
            el.on('remove', function () {
                el.off(name, handler);
            });
        }

        function digest () {
            if ($scope.$$phase)
                return;

            try {
                $scope.$digest();
            } catch (e) {
            }
        }

        bindEvent($('body'), 'click', function (event) {
            $scope.typesOpen = $scope.topicsOpen = false;

            digest();
        });
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: TeacherScreenDirective.createScope({
            resources: '=',
            resourceName: '=',
            onResourceChange: '='
        })
    };
}

angular.module('cuethink.resourcebank', ['cuethink.teacherscreen']).directive('resourcebank', ResourceBankDirective);
