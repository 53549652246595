import 'core/community/communityDirective';
import '../navigation/teachernavbarController';
import 'config';

function CommunityController ($scope, $controller, CueThinkConfig) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });

    $scope.url = CueThinkConfig.communityUrl;
}

export default CommunityController;
