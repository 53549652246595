import commonModule from './commonModule';

function RenderMathService () {
  this.renderElements = function (elements, callback) {
    if (!Array.isArray(elements))
      elements = [elements];

    for (var i = 0; i < elements.length; i++)
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, elements[i]]);

    if (callback)
      MathJax.Hub.Queue(callback);
  };
}

commonModule.factory('renderMath', function () {
  return new RenderMathService();
});
