export default {
  chatHostname: 'im.cuethink.com',
  chatURL: 'https://im.cuethink.com:7443/http-bind/',
  groupChatDomain: 'conference.cuethink.com',
  hostName: 'api.cuethink.com',
  uploadHostName: 'file.cuethink.com',
  mediaHostName: 'media.cuethink.com',
  mediaURL: 'https://media.cuethink.com',
  versionURL: 'https://api.cuethink.com/{0}.html',
  uploadURL: 'https://file.cuethink.com/api/v1',
  intercomAppId: 'f2d715w',
  subMenuUrls: {
    teacherTutorials: [
      {
        name: 'EDUCATOR_MODE',
        tutorials: [
          { name: 'FULL_TUTORIAL', url: 'https://file.cuethink.com/documents/educator_mode_overview.mp4' },
          { name: 'VIEW_PROBLEM_BANK', url: 'https://file.cuethink.com/documents/view_problem_bank.mp4' },
          { name: 'ASSIGN_PROBLEMS', url: 'https://file.cuethink.com/documents/assign_problems.mp4' },
          { name: 'CREATE_NEW_PROBLEMS', url: 'https://file.cuethink.com/documents/create_new_problem.mp4' },
          { name: 'VIEW_ASSIGNED_PROBLEMS', url: 'https://file.cuethink.com/documents/view_assigned_problems.mp4' },
          { name: 'VIEW_STUDENT_DATA', url: 'https://file.cuethink.com/documents/view_student_data.mp4' },
          { name: 'MANAGE_STUDENTS', url: 'https://file.cuethink.com/documents/manage_students.mp4' }
        ]
      },
      {
        name: 'STUDENT_MODE',
        tutorials: [
          { name: 'FULL_TUTORIAL', url: 'https://file.cuethink.com/documents/student_mode.mp4' },
          { name: 'SOLVE_PHASE_TOOLS', url: 'https://file.cuethink.com/documents/solve_phase_tools.mp4' }
        ]
      },
      {
        name: 'IMPLEMENTATION_MODELS',
        tutorials: [
          {
            name: 'ONE_DAY_INTRO',
            url: 'https://file.cuethink.com/documents/1_Day_Implementation_Model.mp4'
          },
          {
            name: 'TWO_DAY_INTRO',
            url: 'https://file.cuethink.com/documents/2_Day_Implementation_Model.mp4'
          },
          {
            name: 'GRADUAL_RELEASE',
            url: 'https://file.cuethink.com/documents/Gradual_Release_Implementation_Model.mp4'
          }
        ]
      }
    ],
    courseMaterials: [
      {
        id: 1,
        name: "UNIT_1",
        url: "https://docs.google.com/presentation/d/1em4N3EKaEeTU0mKOcj1JQ0qbAVVDXo66jZB2lF_4H3A/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit1.png'
      },
      {
        id: 2,
        name: "UNIT_2",
        url: "https://docs.google.com/presentation/d/1q3kOvwVw5qkhzeQPW7gIYsh0Ujrk2uEtvz5xaSNSLac/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit2.png'
      },
      {
        id: 3,
        name: "UNIT_3",
        url: "https://docs.google.com/presentation/d/1woOQDkhheujt0Ydh9s_vvnFXGHXcm5VwcRpNNDawc4M/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit3.png'
      },
      {
        id: 4,
        name: "UNIT_4",
        url: "https://docs.google.com/presentation/d/1pwi473D9X28lE2ga32G5FdGMIipdvaGfK5H1cgRL9Xg/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit4.png'
      },
      {
        id: 5,
        name: "UNIT_5",
        url: "https://docs.google.com/presentation/d/1MI7nnE1pqQRKj3qK-UbzVYoy8RzIh8RLYtAEBEyeWSU/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit5.png'
      },
      {
        id: 6,
        name: "UNIT_6",
        url: "https://docs.google.com/presentation/d/1myzNcqhG70kAVd4OOzirqYEW7ChTMjsDbtsQoluBqtA/embed?start=false&loop=false&delayms=3000",
        imageUrl: '/images/educatorguide/unit6.png'
      }
    ],
    cuethink101Tutorials: [
      {
        name: 'TRAINING_SLIDES',
        url: 'https://docs.google.com/presentation/d/1OOBhjnOkYeNbjg0SO4yYi9E8ZFfPxvcImzFfwE0-ni0/embed?start=false&loop=false&delayms=3000'
      },
      {
        name: 'TRAINING_GUIDE',
        url: 'https://docs.google.com/document/d/13N_OgfMfaDvUOPGfRxR5thPw5l4joRnRk1PnJg1F1BA/pub'
      }
    ]
  },
  calendar: {
    id: "calendar",
    name: "CALENDAR",
    url: "https://docs.google.com/document/d/1dm_8JXXkjz5kFBhKXYGQ5wHw-cAtvQtnJhctC03vsnc/pub?embedded=true"
  },
  termsURL: '/documents/TermsofUseCueThink.htm',
  privacyPolicyURL: '/documents/PrivacyPolicyCueThink.htm',
	googleClientId: '1001595834569-qfl8t3j2itdp23vupu8n8heqnrcrv0ud.apps.googleusercontent.com',
  eurekaURL: 'https://eureka.cuethink.com',
  pathPrefix: '/api/v1',
  prefix: 'https://{0}',
  cleverClientID: 'fbbcc475211430ece9e6',
  serverDownMessage: 'SERVER_DOWN_MESSAGE',
  googleTrackingID: 'UA-41071835-3',
  sslCommonName: 'app.cuethink.com',
  communityUrl: 'https://discourse.coverq.com',
  defaultFolders: [
    {
      name: 'GETTING_STARTED',
      folders: [],
      files: [1394, 1165, 1167, 1168, 10753]
    }
  ]
};
