import '../singleclick/singleClick';
import './thinklet.css';
import listTpl from './thinklet_list.html';
import tpl from './thinklet.html';
import 'angular-translate';
import {
	// getLocalizedThinkletQuestion,
	// getLocalizedThinkletQuestionImage,
	getLocalizedThinkletTitle
} from '../common/translation-helpers';

angular.module('cuethink.thinklet', ['cuethink.singleclick', 'pascalprecht.translate']).directive('thinklet', function ($rootScope) {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			click: '=',
			create: '=',
			edit: '=',
			editable: '=',
			list: '=',
			marked: '=',
			nameFormat: '=',
			remove: '=',
			titleOnly: '=',
			thinklet: '='
		},
		template: function (el, attr) { // TODO: test
			if (attr.list)
				return listTpl;
			else
				return tpl;
		},
		link: function ($scope, $translate) {
			$scope.viewModel = {
				question: null,
				title: null
			};

			function preventParentClick (e) {
		   		if (!e)
		   			e = window.event;
		   		e.cancelBubble = true;

		   		if (e.stopPropagation)
		   			e.stopPropagation();
		   	}

			$scope.toggleButtons = function (e) {
				preventParentClick(e);

				$scope.dropdown = !$scope.dropdown;
			};

			$scope.createThinklet = function (e, thinklet) {
				preventParentClick(e);

				$scope.create(thinklet);
			};

			$scope.editThinklet = function (e, thinklet) {
				preventParentClick(e);

				$scope.edit(thinklet);
			};

			$scope.deleteThinklet = function (e, thinklet) {
				preventParentClick(e);

				$scope.remove(thinklet);
			};

			if (!$scope.isMarked) {
				$scope.isMarked = function () {
					if ($scope.marked === undefined)
						return $scope.thinklet.isPublic;
					else
						return $scope.marked;
				};
			}

			$scope.getDisplayName = function () {
				if (!$scope.nameFormat)
					return null;

				var result = $scope.nameFormat;

				result = result.replace(/%f/g, $scope.thinklet.user.firstName);
				result = result.replace(/%li/g, $scope.thinklet.user.lastName.substr(0, 1).toUpperCase());
				result = result.replace(/%l/g, $scope.thinklet.user.lastName);
				result = result.replace(/%u/g, $scope.thinklet.user.username);

				return result;
			};

			function setLocalizedText (language) {
				$scope.viewModel.title = getLocalizedThinkletTitle($translate, $scope.thinklet, language);

				if (!$scope.viewModel.title)
					$scope.viewModel.title = $scope.thinklet.title;

				// $scope.viewModel.question = getLocalizedThinkletQuestion($translate, $scope.thinklet, language);
			}

			setLocalizedText();

			var unsubscribe = $rootScope.$on('$translateChangeSuccess', function (event, options) {
				setLocalizedText(options.language);
			});

			$scope.$on("$destroy", function() {
				unsubscribe();
			});
		}
	};
});
