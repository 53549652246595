import 'src/images/magnifyingglass.svg';
import 'src/images/planicon.svg';
import 'src/images/solveicon.svg';
import 'src/images/Review-icon.svg';
import 'src/components/shared/create/create';
import './fourPhaseMenu.css';
import 'src/images/Explore_Fin_Transparent.png';
import 'src/images/Plan_Fin_Transparent.png';
import 'src/images/Solve_Fin_Transparent.png';
import 'src/images/Review_Fin_Transparent.png';
import 'angular-ui-router';
import helperStore from 'core/helper/helper.store';

export default function FourPhaseMenuController ($scope, $state, $stateParams, $controller, $transitions) {
  $controller('CreateController', { '$scope': $scope, '$stateParams': $stateParams, thinkletLoadCallback: null });

  $scope.onClickExploreButton = function () {
    $state.go('understand', { id: $stateParams.id, showBeliefsProbe: true });
  };

  $scope.onClickPlanButton = function () {
    $state.go('plan', { id: $stateParams.id, showBeliefsProbe: true });
  };

  $scope.onClickSolveButton = function () {
    $state.go('solve', { id: $stateParams.id, showBeliefsProbe: true });
  };

  $scope.onClickReviewButton = function () {
    $state.go('review', { id: $stateParams.id, showBeliefsProbe: true });
  };

  var _showHelperCompanion = true;

  var helperTimer = setTimeout(function () {
    _showHelperCompanion = false;
  }, 5000);

  $scope.setHelperIdleTitle('THINK_FIRST');
  $scope.setHelperIdleMessage('CLICK_ON_EXPLORE_TO_THINK_ABOUT_WHAT_THE_PROBLEM_IS_ASKING_SO_YOU_CAN_DO_BETTER');

  $transitions.onStart({}, function (transition) {
    if (transition.to().name === 'plan' || (transition.to().name !== 'understand' && transition.to().name !== 'solve' && transition.to().name !== 'review'))
      _showHelperCompanion = false;
  });

  $scope.$on('$destroy', function() {
    clearTimeout(helperTimer);
    helperTimer = null;

    if (_showHelperCompanion) {
      // let new screen show it when it loads
      helperStore.commit('SET_BUTTONS', null);
      helperStore.commit('SET_BUTTONS_TEXT', null);
      helperStore.commit('SET_MESSAGE', 'BE_SURE_TO_READ_THE_PROBLEM_AND_SLOW_DOWN_SO_YOU_CAN_DO_BETTER');
      helperStore.commit('SET_TITLE', 'STOP_AND_THINK');
    }
  });
}
