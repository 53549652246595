import 'core/resourceBank/resourceBankDirective';
import 'src/components/shared/teacher_data';
import 'src/app/navigation/teachernavbarController';

function ResourceBankController($scope, TeacherManager, $controller, $state, $stateParams) {
  "ngInject";

  $controller('TeacherNavBarController', { '$scope': $scope });

  TeacherManager.loadResources().then(function (resources) {
    $scope.resources = resources;
  });

  $scope.resourceName = $stateParams.resourceName;

  $scope.onResourceChange = function (resource) {
    $state.go('.', { resourceName: resource.name });
  };
}

export default ResourceBankController;
