import module from '../login.module';
import '../loginDirective';
import CleverAuthentication from '../clever';
import GoogleSignIn from '../google-sign-in';
import './linkaccount.css';
import tpl from './linkAccountDirective.tpl.html';
import _ from 'underscore';
import 'angular-translate';

module.directive('linkaccount', function ($translate) {
    var linkFunction = function ($scope) {
		$scope.formData = {
			loginForm: null
		};

		$scope.user = null;

        function digest () {
            try {
                if (!$scope.$$phase)
                    $scope.$digest();
            } catch (e) {
            }
        }

		if ($scope.service == 'google') {
			$scope.serviceName = 'Google';

			new GoogleSignIn(function (user, isMobile, callback) {
				var isFormValid = $scope.user.name && $scope.user.password;

				if (isFormValid) {
					$scope.user.email = user.email;

					return $scope.linkGoogle($scope.user, callback);
				} else {
					_.forOwn($scope.formData.loginForm.$error, function(value, key) {
						_.each(value, function(element) {
							element.$setDirty();
						});
					});
				}
			}, function (user, success, message) {
				if (!success) {
					alert($translate.instant(message));

					return;
				}

				$scope.logInSuccess(user);
			}, function (available, signIn) {
				if (available) {
					$scope.signIn = signIn;

                    digest();
                }
			}, true);
		} else if ($scope.service == 'clever') {
			$scope.serviceName = 'Clever';

			var cleverAuthentication = new CleverAuthentication($scope.linkClever, $scope.cleverRedirectUrl, $scope.cleverClientId);

			$scope.signIn = function () {
				var isFormValid = $scope.user.name && $scope.user.password;

				if (isFormValid) {
					cleverAuthentication.authenticate(function (authCallback, authCode, callback, isToken) {
						var promise = authCallback($scope.user, authCode, callback, isToken);

						if (promise)
							promose.then(callback);
					}, function (success, user) {
						if (success)
							$scope.logInSuccess(user);
						else
							alert($translate.instant(user));
					});
				} else {
					_.forOwn($scope.formData.loginForm.$error, function(value, key) {
						_.each(value, function(element) {
							element.$setDirty();
						});
					});
				}
			};
		} else {
			$scope.login();

			return;
		}

		$scope.login = function (user) {
		};

		$scope.pc_version = $scope.version;
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: {
					cancel: '=',
					cleverRedirectUrl: '=',
					cleverClientId: '=',
					forgotPassword: '=',
					googleClientId: '=',
					linkClever: '=',
					linkGoogle: '=',
					login: '=',
					logInSuccess: '=',
					logoPath: '@',
        	name: '=',
					referral: '=',
					service: '=',
					version: '='
        }
    };
});
