import NotificationCenter from '../../notifications/notification-center';
import translate from 'core/common/filters/translate.filter';

export default {
  filters: {
    translate
  },

  created() {
    NotificationCenter.getShared().addListener('LanguageChanged', lang => {
      this.onLanguageChanged(lang);
    })
  },

  methods: {
    onLanguageChange(lang) {
      // override
    },

    translate(value) {
      return translate(value);
    }
  }
}
