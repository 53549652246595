import ShapeTool from './shape-tool';
import { inherit } from './base-tool';

export default function RightTriangleTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = RightTriangleTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  me.draw = function (obj) {
    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();

    var context = whiteboard.getContext();

    context.beginPath();
    context.moveTo(sx, sy);
    context.lineTo(sx, ey);
    context.lineTo(ex, ey);
    context.closePath();
    context.stroke();
  };
}

inherit(RightTriangleTool, ShapeTool);

RightTriangleTool.type = 'righttriangle';
