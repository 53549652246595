<template>
  <div class="video-pop-up">
    <div class="body">
      <h4 class="title">
        {{ title | translate }}
      </h4>

      <button
        class="close-btn"
        tabindex="0"
        @click="onCloseButtonClick">
        {{ 'CLOSE' | translate }}
      </button>

      <div class="player-container">
        <iframe
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          frameborder="0"
          :src="url"
          :title="title">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import translate from 'core/common/filters/translate.filter';
import './images/WhiteX.svg';

export default {
  filters: {
    translate
  },

  props: {
    title: {
      required: true,
      type: String
    },

    url: {
      required: true,
      type: String
    }
  },

  methods: {
    onCloseButtonClick() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
  .video-pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);

    .body {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      padding-right: 20px;

      .title {
        color: white;
        text-align: center;
        margin-bottom: 30px;
      }

      .close-btn {
        position: absolute;
        right: 0;
        top: 30px;
        background-image: url('/images/WhiteX.svg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 10px 10px;
        width: 10px;
        height: 10px;
        background-color: transparent;
        text-indent: -9999em;
        overflow: hidden;
      }

      .player-container {
        position: relative;
        background-color: white;
        width: 850px;
        height: 478px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>