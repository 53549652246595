import tpl from './cuethinkButtonToggleDirective.tpl.html';
import './cuethinkButtonToggleDirective.css';

function CuethinkButtonToggleDirective ($timeout) {
  return {
    restrict: 'E',
    replace: true,
    template: tpl,
    link: function ($scope) {
      var value;

      $scope.isActive = function (option) {
        return value === option.id;
      };

      $scope.onClick = function (option) {
        $scope.value = value = option.id;

        $timeout(function () {
          $scope.$emit('select-button-toggle', option);
        });
      };

      if (!$scope.value)
        $scope.onClick($scope.options[0]);
    },
    scope: {
      options: '=',
      value: '='
    }
  };
}

export default CuethinkButtonToggleDirective;
