import Vue from 'vue';

export default function AngularVueComponentDirective(component, props, events) {
  return function () {
    var scope;

    if (props) {
      scope = {};

      props.forEach(prop => {
        scope[prop] = '=';
      });
    }

    return {
      link: function ($scope, element) {
        var data;
        let componentInstance;

        if (props) {
          data = {};

          props.forEach(prop => {
            data[prop] = $scope[prop];

            $scope.$watch(prop, val => {
              Vue.set(data, prop, val);

              if (componentInstance && componentInstance.$children && componentInstance.$children.length > 0
                && componentInstance.$children[0].$props) {
                componentInstance.$children[0].$props[prop] = val;
              }
            });
          });
        }

        var on;

        if (events) {
          on = {};

          if (Array.isArray(events)) {
            events.forEach(event => {
              on[event] = e => {
                $scope.$emit(event, e);
              };
            });
          } else {
            Object.keys(events).forEach(key => {
              on[key] = e => {
                $scope.$emit(events[key], e);
              };
            });
          }
        }

        var el = document.createElement('div');

        element.append(el);

        var options;

        if (data || on) {
          options = {};

          if (data) {
            options.props = data;
          }

          if (on) {
            options.on = on;
          }
        }

        componentInstance = new Vue({
          el,
          render(createElement) {
            return createElement(component, options);
          }
        });
      },
      restrict: 'E',
      scope: scope
    };
  };
}
