<template>
  <modal
    class="report-snapshot"
    @close="onClose">
    <h1>{{ (phase.toUpperCase() + '_PHASE') | translate }}</h1>

    <label class="the-question-im-trying-to-answer-is">
      <template v-if="phase === 'explore'">
        {{ 'THE_PROBLEM_IS_ASKING_ME_TO' | translate }}:
      </template>
      <template v-if="phase === 'review'">
        {{ 'BASED_ON_MY_WORK_A_SOLUTION_FOR_THIS_PROBLEM_IS' | translate }}:
      </template>

      <label class="student-names">
        {{ 'STUDENT_NAMES' | translate }}:

        <cuethink-switch
          :label="translate(isStudentNamesVisible ? 'VISIBLE' : 'HIDDEN')"
          v-model="isStudentNamesVisible">
        </cuethink-switch>
      </label>
    </label>

    <div class="table">
      <table>
        <tbody>
          <tr
            v-for="(thinklet, i) in thinklets"
            :class="{ even: i % 2 === 0, odd: i % 2 === 1 }">
            <td>
              <span
                v-if="isStudentNamesVisible"
                class="username">
                {{ thinklet.username }}:
              </span>

              <span class="response">
                <template v-if="phase === 'explore' || phase === 'plan'">
                  {{ thinklet.tryingToSolve }}
                </template>
                <template v-if="phase === 'review'">
                  {{ thinklet.answer }}
                </template>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </modal>
</template>

<script>
import translate from 'core/common/filters/translate.filter';
import modal from '../common/components/modal';
import switchComponent from '../common/components/switch';

export default {
  components: {
    modal,
    'cuethink-switch': switchComponent
  },

  filters: {
    translate
  },

  props: {
    phase: String,
    thinklets: Array
  },

  data() {
    return {
      isStudentNamesVisible: false,
      translate
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    }
  }
}
</script>

<style>
  .report-snapshot .body {
    width: calc(100% - 160px);
    max-height: 600px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .report-snapshot h1 {
    margin-bottom: 0;
  }

  .report-snapshot .close-btn {
    top: 20px;
    right: 20px;
  }

  .report-snapshot label {
    color: #363845 !important;
  }

  .report-snapshot .the-question-im-trying-to-answer-is {
    position: relative;
  }

  .report-snapshot .student-names {
    position: absolute;
    right: 0;
    top: 0;
  }

  .report-snapshot .table {
    background-color: #FAFAFA;
    border-radius: 4px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
  }

    .report-snapshot table {
      width: 100%;
    }

    .report-snapshot tr.odd {
      background-color: #ECF7FC;
    }

      .report-snapshot td {
        padding: 10px;
        height: 40px;
      }

  .report-snapshot .username {
    font-weight: 700;
  }

  .report-snapshot .v-input--switch {
    display: inline-flex;
    margin-top: 0;
    vertical-align: sub;
  }

  .report-snapshot .v-label {
    margin-bottom: 0;
  }

  .report-snapshot .switch {
    margin-left: 10px;
  }
</style>
