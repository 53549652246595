import { BaseWhiteboardTool, inherit } from './base-tool';
import NumberLineTool from './number-line-tool';

export default function GridTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, xNumbers, yNumbers, color, type) {
      this.id = whiteboard.getNextId();
      this.type = GridTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.xNumbers = xNumbers;
      this.yNumbers = yNumbers;
      this.gridType = type;
      this.showLabels = true;

      if (color)
        this.color = color;

      whiteboard.updateEventTime(this);
    };
  };

  this.isRotatable = function () {
    return false;
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  function drawGrid (obj) {
    var width = obj.coordinates[2] - obj.coordinates[0];
    var height = obj.coordinates[3] - obj.coordinates[1];

    var xUnits = obj.xNumbers ? obj.xNumbers.length + 2 : 24;

    for (var i = 0; i < xUnits; i++) {
      var x = obj.coordinates[0] + (width * (i / (xUnits - 1)));
      x *= whiteboard.getWidth();
      x -= 0.5;

      whiteboard.context.beginPath();
      whiteboard.context.moveTo(x, (obj.coordinates[1] * whiteboard.getHeight()) - 0.5);
      whiteboard.context.lineTo(x, (obj.coordinates[3] * whiteboard.getHeight()) - 0.5);
      whiteboard.context.closePath();
      whiteboard.context.stroke();
    }

    var yUnits;
    var cellSize;

    if (obj.yNumbers) {
      yUnits = obj.yNumbers.length + 2;
    } else {
      cellSize = width / (xUnits - 1);
      cellSize *= whiteboard.getWidth();

      yUnits = Math.ceil((height * whiteboard.getHeight()) / cellSize);
    }

    if (obj.yNumbers) {
      cellSize = height / (yUnits - 1);
      cellSize *= whiteboard.getHeight();
    }

    for (var j = 0; j < yUnits; j++) {
      var y = (obj.coordinates[1] * whiteboard.getHeight()) + (cellSize * j);
      y -= 0.5;

      whiteboard.context.beginPath();
      whiteboard.context.moveTo((obj.coordinates[0] * whiteboard.getWidth()) - 0.5, y);
      whiteboard.context.lineTo((obj.coordinates[2] * whiteboard.getWidth()) - 0.5, y);
      whiteboard.context.closePath();
      whiteboard.context.stroke();
    }
  }

  this.setStyles = function (obj) {
    var color = obj.color;

    if (!color)
      color = 'lightgray';

    whiteboard.setStrokeColor(color);

    whiteboard.context.lineWidth = 1;
  };

  this.draw = function (obj) {
    drawGrid(obj);

    if (obj.gridType === 'open')
      return;

    var width = obj.coordinates[2] - obj.coordinates[0];
    var height = obj.coordinates[3] - obj.coordinates[1];

    var AXIS_SIZE = 10;
    var axisWidth = AXIS_SIZE / whiteboard.getWidth();
    var axisHeight = AXIS_SIZE / whiteboard.getHeight();

    var xAxis = {}, yAxis = {};

    var xNumbers;
    var yNumbers;

    if (obj.showLabels) {
      xNumbers = obj.xNumbers;
      yNumbers = obj.xNumbers;
    } else {
      xNumbers = obj.xNumbers.map(function () { return ''; });
      yNumbers = obj.xNumbers.map(function () { return ''; });
    }

    if (obj.gridType === 'full') {
      xAxis.sx = obj.coordinates[0];
      xAxis.sy = obj.coordinates[1] + (height / 2) - (axisHeight / 4);
      xAxis.ex = obj.coordinates[2];
      xAxis.ey = obj.coordinates[1] + (height / 2) + (axisHeight / 4);

      yAxis.sx = obj.coordinates[0] + (width / 2) - (axisWidth / 4);
      yAxis.sy = obj.coordinates[1];
      yAxis.ex = obj.coordinates[0] + (width / 2) + (axisWidth / 4);
      yAxis.ey = obj.coordinates[3];
    } else if (obj.gridType === 'positive') {
      xAxis.sx = obj.coordinates[0];
      xAxis.sy = obj.coordinates[3] - (height * 2 / (yNumbers.length + 2)) - (axisHeight * 0.75) - (.5 / whiteboard.getHeight());
      xAxis.ex = obj.coordinates[2];
      xAxis.ey = xAxis.sy + axisHeight;

      yAxis.sx = obj.coordinates[0] + (width * 2 / (xNumbers.length + 2)) + (.5 / whiteboard.getWidth());
      yAxis.sy = obj.coordinates[1];
      yAxis.ex = yAxis.sx + axisWidth;
      yAxis.ey = obj.coordinates[3];
    }

    var textSpacing = 4;
    var xTickSpacing = width * whiteboard.getWidth() / (xNumbers.length + 1);
    var yTickSpacing = height * whiteboard.getHeight() / (yNumbers.length + 1);

    var shorterSide = width * whiteboard.getWidth();
    var minimumGridSizeForMaxFontSize = 400;
    var fontSize = Math.max(12 * Math.min(shorterSide / minimumGridSizeForMaxFontSize, 1), 3);

    NumberLineTool.renderNumberLine(whiteboard, yAxis.sx, yAxis.sy, yAxis.ex, yAxis.ey, yNumbers, 'black', fontSize, true, textSpacing, 2, obj.gridType === 'positive', yTickSpacing);
    NumberLineTool.renderNumberLine(whiteboard, xAxis.sx, xAxis.sy, xAxis.ex, xAxis.ey, xNumbers, 'black', fontSize, false, textSpacing, 2, obj.gridType === 'positive', xTickSpacing);
  };
}

GridTool.type = 'grid';

inherit(GridTool, BaseWhiteboardTool);
