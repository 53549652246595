import '../thinklet/thinklet_cell';
import '../navigation/navbar';
import './dashboard.css';
import '../trial/trialEndPopUpDirective';
import 'core/common/cuethinkDropdownDirective';
import { setUserModesScopeMembers } from '../common/user-modes';
import {
	canCreateThinklet,
	hasCompletedAllThinkletsForAssignment,
	hasIncompleteThinkletForAssignment,
	isAssignmentClosing,
	isAssignmentDueSoon
} from './dashboard.helpers';
import tpl from './dashboardDirective.tpl.html';
import NotificationCenter from '../notifications/notification-center';

export default function DashboardDirective($timeout) {
	return {
		link: function ($scope) {
			$scope.viewModel = {
				isClassDropdownOpen: false,
				isNotificationToggleMenuOpen: false
			};

			function digest() {
				if ($scope.$$phase)
					return;

				try {
					$scope.$digest();
				} catch (e) {
				}
			}

			function sortNotification (a, b) {
				return a.createAt < b.createAt;
			}

			function handleCallback(func, callback, error) {
				var promise = func(callback, error);

				if (promise)
					promise.then(callback, error);
			}

			var oldAssignments, newAssignments;

			$scope.areOverdueProblemsVisible = $scope.overdue;

			function loadAssignments(callback) {
				$scope.assignments = [];

				return $scope.loadAssignments($scope.user.selectedClass).then(function (data) {
					var now = new Date();

					newAssignments = [];
					oldAssignments = [];

					for (var i = 0; i < data.length; i++) {
						var assignment = data[i];
						var sevenDaysFromDue = new Date(assignment.endDate.getTime() + (7 * 24 * 60 * 60 * 1000));

						if (now < sevenDaysFromDue)
							newAssignments.push(assignment);
						else
							oldAssignments.push(assignment);
					}

					if ($scope.areOverdueProblemsVisible)
						$scope.assignments = oldAssignments;
					else
						$scope.assignments = newAssignments;

					digest();

					if (callback)
						callback();
				});
			}

			(function () {
				var thinklets;

				var callback = function () {
					$scope.thinklets = {};

					var versions = {};

					for (var i = 0; i < thinklets.length; i++) {
						var thinklet = thinklets[i];

						if (thinklet.thinklet_version === 1)
							$scope.thinklets[thinklet.assignment_id] = thinklet;

						var v = versions[thinklet.assignment_id];

						if (!v)
							versions[thinklet.assignment_id] = v = [];

						v.push(thinklet);
					}

					$scope.thinkletVersions = versions;

					digest();
				};

				$scope.loadThinklets($scope.user ? $scope.user.id : null).then(function (data) {
					thinklets = data;

					if (newAssignments)
						callback();
				});

				loadAssignments(function () {
					if (thinklets)
						callback();
				});
			})();

			handleCallback(function (callback) {
				return $scope.loadNewNotifications(callback);
			}, function (data) {
				newNotifications = data.content;
				newNotifications.sort(sortNotification);

				if (!$scope.viewingAllNotifications) {
					$scope.notifications = newNotifications;

					digest();
				}
			});

			handleCallback(function (callback) {
				return $scope.loadNotifications(callback);
			}, function (data) {
				allNotifications = data.content;
				allNotifications.sort(sortNotification);

				if ($scope.viewingAllNotifications) {
					$scope.notifications = allNotifications;

					digest();
				}
			});

			$scope.continueThinklet = function (assignment) {
				if ($scope.disableButtons)
					return;

				$scope.disableButtons = true;

				digest();

				var lastVersion;
				var versions = $scope.thinkletVersions[assignment.id];

				if (versions && versions.length > 0) {
					for (var i = 0; i < versions.length; i++) {
						var version = versions[i];

						if (!lastVersion || version.thinklet_version > lastVersion.thinklet_version)
							lastVersion = version;
					}

					$scope.editThinklet(lastVersion);
				} else if (assignment.exemplarThinkletId && $scope.copyExemplarThinklet) {
					$scope.copyExemplarThinklet(assignment.exemplarThinkletId, assignment.id).then($scope.editThinklet);
				}
			};

			$scope.beginThinklet = function (assignment) {
				if ($scope.disableButtons)
					return;

				$scope.disableButtons = true;

				digest();

				handleCallback(function () {
					return $scope.loadProblem(assignment.problemId);
				}, function (problem) {
					handleCallback(function (callback) {
						return $scope.createThinklet(problem, assignment, callback);
					}, function (thinklet) {
						$scope.startThinklet(thinklet);
					});
				});
			};

			$scope.selectNotification = function (notification) {
				if (notification.thinkletId)
					$scope.viewThinklet(notification.thinkletId, { studentRubric: notification.category === 'rubric:graded' });
			};

			$scope.removeNotification = function (notification) {
				$scope.deleteNotification(notification);

				$scope.notifications.splice($scope.notifications.indexOf(notification), 1);

				allNotifications.push(notification);

				allNotifications.sort(sortNotification);
			};

			var allNotifications, newNotifications;
			$scope.viewAllNotifications = function () {
				$scope.viewModel.isNotificationToggleMenuOpen = false;
				$scope.notifications = allNotifications;
				$scope.viewingAllNotifications = true;
			};

			$scope.viewNewNotifications = function () {
				$scope.viewModel.isNotificationToggleMenuOpen = false;
				$scope.notifications = newNotifications;
				$scope.viewingAllNotifications = false;
			};

			$scope.isClosing = function (assignment) {
				return isAssignmentClosing(assignment, $scope.thinklets);
			};

			$scope.isSoon = function (assignment) {
				return isAssignmentDueSoon(assignment, $scope.thinklets);
			};

			$scope.hasCompletedAllThinklets = function (assignment) {
				return hasCompletedAllThinkletsForAssignment(assignment, $scope.thinkletVersions);
			};

			$scope.hasIncompleteThinklet = function (assignment) {
				return hasIncompleteThinkletForAssignment(assignment, $scope.thinkletVersions);
			};

			$scope.showOverdueProblems = function () {
				$scope.navigateOverdueProblems();
			};

			$scope.showCurrentProblems = function () {
				$scope.navigateCurrentProblems();
			};

			$scope.canCreateThinklet = function (assignment) {
				return canCreateThinklet(assignment, $scope.thinkletVersions);
			};

			$timeout(function () {
				var onClick = () => {
					$scope.navigateCurrentProblems();
				};

				var dashboardButton = $('#dashboard-button').on('click', onClick);
				dashboardButton.on('remove', function (event) {
					$(event).off('click', onClick);
				});
			});

			if ($scope.user.type === 'Student' && $scope.joinClass) {
				if (!$scope.user.classes || $scope.user.classes.length === 0)
					$scope.isNewClassPromptVisible = true;

				$scope.joinNewClass = function (code) {
					if (!code)
						return;

					code = code.trim();

					$scope.joinClass(code).then(function () {
						$scope.isNewClassPromptVisible = false;

						digest();
					});
				};

				$scope.showClassPromptError = function () {
					if ($scope.user.classes && $scope.user.classes.length > 0)
						$scope.isNewClassPromptVisible = false;
					else
						$scope.isNewClassPromptError = true;
				};

				$scope.hideClassPromptError = function () {
					$scope.isNewClassPromptError = false;
				};
			}

			$scope.chooseClass = function (cls) {
				$scope.viewModel.isClassDropdownOpen = false;

				$scope.selectClass(cls);

				loadAssignments();
			};

			$scope.$watch('user.selectedClass', function () {
				if ($scope.isReadyToExploreEnabled) {
					if ($scope.user.selectedClass && $scope.user.selectedClass.name !== 'Test Class')
						$scope.isReadyToExploreVisible = false;
					else if ($scope.user.type !== 'Student' && $scope.user.isNew)
						$scope.isReadyToExploreVisible = true;
				} else {
					$scope.isReadyToExploreVisible = false;
				}
			});

			$scope.$watch('isReadyToExploreEnabled', function () {
				if ($scope.isReadyToExploreEnabled) {
					if ($scope.user.selectedClass && $scope.user.selectedClass.name !== 'Test Class')
						$scope.isReadyToExploreVisible = false;
					else if ($scope.user.type !== 'Student' && $scope.user.isNew)
						$scope.isReadyToExploreVisible = true;
				} else {
					$scope.isReadyToExploreVisible = false;
				}
			});

			$scope.hideReadyToExplore = function () {
				$scope.isReadyToExploreVisible = false;
				$scope.isReadyToExploreEnabled = false;

				$scope.setSeenReadyToExplore();
			};

			setUserModesScopeMembers($scope);

			$scope.createThinklet = $scope.thinkletManager.createThinklet;
			$scope.loadProblem = $scope.thinkletManager.loadProblem;

			var removeChoseClassListener = NotificationCenter.getShared().addListener('ChoseClass', () => loadAssignments());

			$scope.$on('$destroy', () => {
				removeChoseClassListener();
			});
		},
		restrict: 'E',
		scope: {
			copyExemplarThinklet: '=',
			deleteThinklet: '=',
			deleteNotification: '=',
			editThinklet: '=',
			isReadyToExploreEnabled: '=readyToExplore',
			joinClass: '=',
			loadAssignments: '=',
			loadNewNotifications: '=',
			loadNotifications: '=',
			loadThinklets: '=',
			navigateCurrentProblems: '=',
			navigateOverdueProblems: '=',
			overdue: '=',
			profile: '=',
			selectClass: '=',
			setSeenReadyToExplore: '=',
			startThinklet: '=',
			thinkletManager: '=',
			viewThinklet: '=',
			user: '='
		},
		template: tpl
	};
}
