import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import './community.css';
import tpl from './communityDirective.tpl.html';

angular.module('cuethink.community', ['cuethink.teacherscreen']).directive('community', function ($sce) {
  "ngInject";

  var linkFunction = function ($scope) {
    $scope.trustedUrl = $sce.trustAsResourceUrl($scope.url);
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    scope: TeacherScreenDirective.createScope({
      url: '='
    })
  };
});
