import module from '../login.module';
import '../loginDirective';
import './forgotpassword.css';
import tpl from './changePasswordScreenDirective.tpl.html';

module.directive('changePasswordScreen', function () {
    return {
        restrict: 'E',
        template: tpl,
        scope: {
            logoPath: '=',
            name: '=',
            changePassword: '=',
            logIn: '=',
            cancel: '=',
            data: '=',
            isTeacherChange: '=',
            isExpired: '='
        }
    };
});