export default [
  {
    id: 'en',
    name: 'ENGLISH'
  },
  {
    id: 'es',
    name: 'SPANISH'
  }
];
