<template>
  <div class="dashboard">
    <div class="body">
      <div
        v-if="isNewDesignEnabled || isCueCollabUser"
        class="thought-of-the-day">
        <h4>{{ 'THOUGHT_OF_THE_DAY' | translate }}</h4>

        <p>{{ mindsetMessage | translate }}</p>
      </div>
      <div
        v-else
        class="welcome-back">
        <avatar
          :size="80"
          :user="user">
        </avatar>

        <h1>{{ 'WELCOME_BACK' | translate }}, {{ user.firstName | propercase }}</h1>
      </div>

      <label class="class-picker-label">Classes:</label>

      <cuethink-dropdown
        v-if="selectedClass"
        class="class-picker"
        label="name"
        :items="user.classes"
        :selected="user.selectedClass.name"
        v-model="isClassDropdownOpen"
        @select="chooseClass">
        {{ selectedClass.name }}
      </cuethink-dropdown>

      <h3>
        {{ 'PROBLEMS' | translate }}

        <a
          class="see-past-due-problems"
          tabindex="0"
          @click.stop.prevent="onPastDueProblemsClick">
          {{ isPastDueProblemsVisible ? 'SEE_CURRENT_PROBLEMS' : 'SEE_PAST_DUE_PROBLEMS' | translate }}
        </a>
      </h3>

      <div
        v-if="thinklets"
        class="assignments">
        <div
          v-for="assignment in sortedAssignments"
          class="assignment"
          :class="{ 'is-completed': hasCompletedAllThinklets(assignment), 'is-due-soon': isSoon(assignment), 'is-past-due': isPastDue(assignment), 'on-time': isOnTime(assignment) }">
          <div class="details">
            <span class="title">{{ assignment.title }}</span>

            <span
              v-if="!(assignment.exemplarThinkletId || assignment.isExemplarAssignment)"
              class="due">
              {{ 'DUE' | translate }}: {{ assignment.endDate | formatDate('MMM D') }}

              <span
                v-if="isSoon(assignment)"
                class="due-soon">
                {{ 'DUE_SOON' | translate }}
              </span>

              <span
                v-if="isNewDesignEnabled && isPastDue(assignment)"
                class="past-due">
                {{ 'PAST_DUE' | translate }}
              </span>
            </span>
          </div>

          <div
            v-for="version in getVersions(assignment)"
            class="version"
            :class="{ incomplete: !version.isComplete }">
            V{{ version.thinklet_version }}
          </div>

          <a
            v-if="hasCompletedAllThinklets(assignment)"
            class="completed"
            tabindex="0"
            @click="onCompletedClick">
            {{ 'COMPLETED' | translate }}
          </a>
          <button
            v-if="hasIncompleteThinklet(assignment)"
            class="primary-button continue-thinklet-btn"
            tabindex="0"
            :disabled="disableButtons"
            @click="continueThinklet(assignment)">
            {{ 'CONTINUE' | translate }}
          </button>
          <button
            v-if="canCreateThinklet(assignment)"
            class="secondary-button create-thinklet-btn"
            tabindex="0"
            :disabled="disableButtons"
            @click="beginThinklet(assignment)">
            {{ 'CREATE' | translate }}
          </button>
        </div>
      </div>

      <cuethink-pop-up
        v-if="isReadyToExploreVisible"
        submit-text="OK_GOT_IT"
        title="READY_TO_EXPLORE_STUDENT_MODE"
        @submit="hideReadyToExplore">
        <p>{{ 'TWO_VERSIONS_OF_CHARLIE_S_GUMBALL_SCENARIO_HAVE_BEEN_ASSIGNED_TO_GUIDE_YOUR_EXPLORATION' | translate }}</p>

        <p>{{ 'THE_SCENARIO_PROBLEM_IS_A_PRACTICE_PROBLEM_FOR_YOU_TO_CREATE_A_THINKLET_AND_ANNOTATE_IT' | translate }}</p>

        <p>{{ 'THE_EXEMPLAR_THINKLET_MODELS_AN_EXAMPLE_OF_HIGH_QUALITY_WORK' | translate }}</p>
      </cuethink-pop-up>
    </div>

    <div class="notifications">
      <div class="notifications-header">
        <h3>
          <img
            v-if="!isNotificationsVisible"
            src="/images/NotificationsIcon.svg"
            :alt="translate('VIEW_NOTIFICATIONS')"
          />

          <button
            v-if="isNewDesignEnabled && isNotificationsVisible"
            class="back-btn"
            tabindex="0"
            @click="onNotificationsBackButtonClick">
          </button>

          <a
            v-if="isNewDesignEnabled && !isNotificationsVisible"
            href="#"
            tabindex="0"
            @click.stop.prevent="onViewNotificationsLinkClick">
            {{ 'VIEW_NOTIFICATIONS' | translate }}

            ({{ notifications ? notifications.length : 0 }})
          </a>
          <template v-else>
            {{ 'NOTIFICATIONS' | translate }}
          </template>
        </h3>

        <cuethink-dropdown
          v-if="!isNewDesignEnabled"
          class="notification-toggle"
          :items="['VIEW_NEW', 'VIEW_ALL']"
          :selected="viewingAllNotifications ? 'VIEW_ALL' : 'VIEW_NEW' | translate"
          v-model="isNotificationToggleMenuOpen"
          @select="onNotificationViewSelect">
        </cuethink-dropdown>
      </div>

      <div class="notifications-body">
        <div
          v-if="isNotificationsVisible"
          class="notifications-list">
          <cuethink-dropdown
            v-if="isNewDesignEnabled"
            class="notification-toggle"
            :items="['VIEW_NEW', 'VIEW_ALL']"
            :selected="viewingAllNotifications ? 'VIEW_ALL' : 'VIEW_NEW' | translate"
            v-model="isNotificationToggleMenuOpen"
            @select="onNotificationViewSelect">
          </cuethink-dropdown>

          <div
            v-for="notification in notifications"
            class="notification">
            <avatar
              v-if="!isNewDesignEnabled"
              class="notification-avatar"
              :font-size="12"
              :size="25"
              :user="user">
            </avatar>

            <span class="username">
              {{ notification.creatorName }}
            </span>

            <span
              v-if="notification.category === 'thinklet:flagged'"
              class="flagged">
              {{ 'FLAGGED' | translate }}
            </span>

            <a
              class="message"
              tabindex="0"
              @click="selectNotification(notification)">
              {{ notification.message }}
            </a>

            <button
              class="hide-notification-btn"
              tabindex="0"
              @click="onHideNotificationButtonClick(notification)">
              {{ 'HIDE' | translate }}
            </button>
          </div>
        </div>
        <div
          v-else
          class="view-notifications-body">
          <avatar
            :font-size="50"
            :size="100"
            :user="user">
          </avatar>

          <h1>{{ 'WELCOME_BACK' | translate }}, {{ user.firstName | propercase }}</h1>
        </div>
      </div>
    </div>

    <helper
      v-if="isNewDesignEnabled && isHelperVisible">
    </helper>

    <cuethink-pop-up
      v-if="isArchivePopUpVisible"
      message="YOUR_CLASS_HAS_BEEN_ARCHIVED_BY_YOUR_TEACHER_AND_YOU_NO_LONGER_HAVE_ACCESS_PLEASE_ASK_YOUR_TEACHER_FOR_A_NEW_CLASS_CODE"
      submit-text="OK"
      @submit="isArchivePopUpVisible = false">
    </cuethink-pop-up>

    <cuethink-pop-up
      v-if="isNewClassPromptVisible"
      cancel-text="I_DON_T_HAVE_A_NEW_CLASS_CODE"
      message="DO_YOU_HAVE_A_NEW_CLASS_CODE_ENTER_IT_HERE"
      submit-text="JOIN_NEW_CLASS"
      title="WELCOME_BACK_TO_CUETHINK"
      @cancel="showClassPromptError"
      @submit="joinNewClass(newClassCode)">
      <input
        aria-label="New Class Code"
        type="text"
        v-model="newClassCode"
      />
    </cuethink-pop-up>

    <cuethink-pop-up
      v-if="isNewClassPromptError"
      message="OOPS_LOOKS_LIKE_YOUR_NAME_GOT_REMOVED_FROM_THE_CLASS_EASY_TO_FIX_ASK_YOUR_TEACHER_FOR_THE_CLASS_CODE"
      submit-text="OK"
      title="WELCOME_BACK_TO_CUETHINK"
      @submit="hideClassPromptError">
    </cuethink-pop-up>
  </div>
</template>

<script>
import formatDate from '../common/filters/formatTime.filter';
import propercase from '../common/filters/propercase.filter';
import _ from 'underscore';
import RouteManager from '../common/route-manager';
import {
  canCreateThinklet,
  hasCompletedAllThinkletsForAssignment,
  hasIncompleteThinkletForAssignment,
  isAssignmentDueSoon
} from './dashboard.helpers';
import './images/check_icon_green.svg';
import './images/Wave.svg';
import './images/ClosexBlue_2px.svg';
import './images/BottomWaves.png';
import './images/CompletedCheckmark.svg';
import './images/Notifications_BackArrow.svg';
import './images/NotificationsIcon.svg';
import './images/ThoughtOfTheDayBackground.jpg';
import helperStore from 'core/helper/helper.store';
import NotificationCenter from '../notifications/notification-center';
import CuethinkPopUp from '../common/cuethink-pop-up';

function sortNotification (a, b) {
  return a.createAt < b.createAt;
}

let _seenHelper;

export default {
  mixins: [
    require('../common/mixins/translate.mixin').default,
    require('../common/mixins/user.mixin').default
  ],

  components: {
    avatar: () => import('../common/avatar'),
    cuethinkDropdown: () => import('../common/cuethinkDropdown'),
    CuethinkPopUp,
    helper: () => import('core/helper/helper')
  },

  filters: {
    formatDate,
    propercase
  },

  props: {
    thinkletManager: Object,
    user: Object,
    userManager: Object
  },

  data() {
    return {
      allNotifications: null,
      assignments: [],
      disableButtons: false,
      isArchivePopUpVisible: false,
      isClassDropdownOpen: false,
      isHelperVisible: !_seenHelper,
      isNewClassPromptError: false,
      isNewClassPromptVisible: false,
      isNotificationsVisible: false,
      isNotificationToggleMenuOpen: false,
      isReadyToExploreEnabled: false,
      isReadyToExploreVisible: false,
      mindsetMessage: null,
      newAssignments: [],
      newClassCode: null,
      newNotifications: null,
      notifications: null,
      oldAssignments: [],
      removeCloseHelperListener: null,
      selectedClass: null,
      thinklets: null,
      thinkletVersions: null,
      viewingAllNotifications: false
    };
  },

  computed: {
    isNewDesignEnabled() {
      return this.isEF23User || this.isCuethinkV2User;
    },

    isPastDueProblemsVisible() {
      const route = RouteManager.getSharedInstance().getCurrentRoute();

      return route && route.data ? route.data.overdue === 'true' : false;
    },

    sortedAssignments() {
      return _.sortBy(this.assignments, 'endDate');
    }
  },

  watch: {
    'user.selectedClass': function () {
      this.setIsReadyToExploreVisible();
    },

    isReadyToExploreEnabled() {
      this.setIsReadyToExploreVisible();
    }
  },

  created() {
    const mindsetMessages = [
      'PERSEVERANCE_GETS_YOU_TO_THIS_FINISH_LINE',
      'TRYING_NEW_THINGS_HELPS_YOU_LEARN',
      'THE_STRATEGIES_YOU_USE_IN_CUETHINK_CAN_HELP_YOU_ON_MATH_TESTS',
      'YOU_CAN_BREAK_DOWN_ANY_MATH_PROBLEM_USING_EXPLORE_PLAN_SOLVE_REVIEW',
      'ITS_HELPFUL_TO_ASK_QUESTIONS_ABOUT_YOUR_THINKING'
    ];

    let mindsetMessageIndex = localStorage.getItem('mindsetMessageIndex');

    if (mindsetMessageIndex === null)
      mindsetMessageIndex = 0;
    else
      mindsetMessageIndex = parseInt(mindsetMessageIndex);

    this.mindsetMessage = mindsetMessages[mindsetMessageIndex];

    if (mindsetMessageIndex < mindsetMessages.length - 1)
      mindsetMessageIndex++;
    else
      mindsetMessageIndex = 0;

    localStorage.setItem('mindsetMessageIndex', mindsetMessageIndex);

    this.selectedClass = this.user.selectedClass;

    this.loadThinklets();

    this.thinkletManager.loadNewNotifications().then(data => {
      this.newNotifications = data.content;
      this.newNotifications.sort(sortNotification);

      if (!this.viewingAllNotifications)
        this.notifications = this.newNotifications;
    });

    this.thinkletManager.loadNotifications().then(data => {
      this.allNotifications = data.content;
      this.allNotifications.sort(sortNotification);

      if (this.viewingAllNotifications)
        this.notifications = this.allNotifications;
    });

    this.isNotificationsVisible = !this.isNewDesignEnabled;

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_MESSAGE', 'SELECT_VIEW_NOTIFICATIONS_TO_SEE_ANNOTATIONS_FROM_YOUR_TEACHER_AND_PEERS')
    helperStore.commit('SET_TITLE', 'HELPER_TIP');

    const closeHelper = () => {
      this.isHelperVisible = false;
    };

    this.removeCloseHelperListener = NotificationCenter.getShared().addListener('CloseHelper', () => {
      closeHelper();
    });

    if (!_seenHelper)
      _seenHelper = true;

    if (this.user.classes.length === 0)
      this.isArchivePopUpVisible = true;

    if (this.user.type !== 'Student' && this.user.isNew) {
      this.userManager.fetchSeenReadyToExplore().then(result => {
        this.isReadyToExploreEnabled = !result;
      });
    }

    if (this.user.type === 'Student' && (!this.user.classes || this.user.classes.length === 0))
        this.isNewClassPromptVisible = true;
  },

  methods: {
    beginThinklet(assignment) {
      if (this.disableButtons)
        return;

      // wait until these load
      if (!this.thinkletVersions)
        return;

      this.disableButtons = true;

      return this.thinkletManager.loadProblem(assignment.problemId).then(problem => {
        return this.thinkletManager.createThinklet(problem, assignment).then(thinklet => {
          this.$emit('start-thinklet', thinklet);
        });
      });
    },

    canCreateThinklet(assignment) {
      return canCreateThinklet(assignment, this.thinkletVersions);
    },

    chooseClass(cls) {
      this.isClassDropdownOpen = false;

      this.selectedClass = cls;

      this.userManager.selectClass(cls);

      this.loadAssignments();
    },

    continueThinklet(assignment) {
      if (this.disableButtons)
        return;

      this.disableButtons = true;

      // wait until these load
      if (!this.thinkletVersions)
        return;

      var lastVersion;
      var versions = this.thinkletVersions[assignment.id];

      if (versions && versions.length > 0) {
        for (var i = 0; i < versions.length; i++) {
          var version = versions[i];

          if (!lastVersion || version.thinklet_version > lastVersion.thinklet_version)
            lastVersion = version;
        }

        this.editThinklet(lastVersion);
      } else if (assignment.exemplarThinkletId && this.thinkletManager.copyExemplarThinklet) {
        this.thinkletManager.copyExemplarThinklet(assignment.exemplarThinkletId, assignment.id).then(thinklet => {
          this.editThinklet(thinklet);
        });
      }
    },

    editThinklet(thinklet) {
      this.$emit('edit-thinklet', thinklet);
    },

    getVersions(assignment) {
      if (!this.thinkletVersions)
        return [];

      var versions = this.thinkletVersions[assignment.id];

      if (versions)
        return _.sortBy(versions, 'thinklet_version');
      else
        return [];
    },

    hasCompletedAllThinklets(assignment) {
      return hasCompletedAllThinkletsForAssignment(assignment, this.thinkletVersions);
    },

    hasIncompleteThinklet(assignment) {
      return hasIncompleteThinkletForAssignment(assignment, this.thinkletVersions);
    },

    hideClassPromptError() {
      this.isNewClassPromptError = false;
      this.isNewClassPromptVisible = true;
    },

    hideReadyToExplore() {
      this.isReadyToExploreVisible = false;
      this.isReadyToExploreEnabled = false;

      this.userManager.setSeenReadyToExplore();
    },

    isOnTime(assignment) {
      return !this.hasCompletedAllThinklets(assignment) && !this.isSoon(assignment) && !this.isPastDue(assignment);
    },

    isPastDue(assignment) {
       if (this.isSoon(assignment))
         return false;

      const now = new Date();
      const date = new Date(assignment.endDate);

      return now > date;
    },

    isSoon(assignment) {
      return isAssignmentDueSoon(assignment, this.thinklets);
    },

    joinNewClass(code) {
      if (!code)
        return;

      code = code.trim();

      this.userManager.joinClass(code).then(() => {
        this.isNewClassPromptVisible = false;
      });
    },

    loadAssignments() {
      this.assignments = [];

      return this.thinkletManager.fetchAssignments(this.user.selectedClass).then(data => {
        var now = new Date();

        this.newAssignments = [];
        this.oldAssignments = [];

        for (var i = 0; i < data.length; i++) {
          var assignment = data[i];
          var sevenDaysFromDue = new Date(assignment.endDate.getTime() + (7 * 24 * 60 * 60 * 1000));

          if (now.getTime() < sevenDaysFromDue.getTime())
            this.newAssignments.push(assignment);
          else
            this.oldAssignments.push(assignment);
        }

        if (this.isPastDueProblemsVisible)
          this.assignments = this.oldAssignments;
        else
          this.assignments = this.newAssignments;

        return data;
      });
    },

    loadThinklets() {
      var thinklets;

      var callback = () => {
        this.thinklets = {};

        var versions = {};

        for (var i = 0; i < thinklets.length; i++) {
          var thinklet = thinklets[i];

          if (thinklet.thinklet_version === 1)
            this.thinklets[thinklet.assignment_id] = thinklet;

          var v = versions[thinklet.assignment_id];

          if (!v)
            versions[thinklet.assignment_id] = v = [];

          v.push(thinklet);
        }

        this.thinkletVersions = versions;
      };

      const thinkletsPromise = this.thinkletManager.loadUserThinklets(this.user ? this.user.id : null).then(data => {
        thinklets = data;

        return data;
      });

      const assignmentsPromise = this.loadAssignments();

      return Promise.all([thinkletsPromise, assignmentsPromise]).then(callback);
    },

    onCompletedClick() {
      RouteManager.getSharedInstance().route('profile');
    },

    onHideNotificationButtonClick(notification) {
      this.thinkletManager.deleteNotification(notification);

      this.allNotifications.splice(this.allNotifications.indexOf(notification), 1);
      this.newNotifications.splice(this.newNotifications.indexOf(notification), 1);
    },

    onNotificationsBackButtonClick() {
      this.isNotificationsVisible = false;
    },

    onNotificationViewSelect(view) {
      this.viewingAllNotifications = view === 'VIEW_ALL';

      if (this.viewingAllNotifications)
        this.notifications = this.allNotifications;
      else
        this.notifications = this.newNotifications;
    },

    onPastDueProblemsClick() {
      if (this.isPastDueProblemsVisible)
        this.showCurrentProblems();
      else
        this.showOverdueProblems();
    },

    onViewNotificationsLinkClick() {
      this.isNotificationsVisible = true;

      this.isHelperVisible = false;
    },

    selectNotification(notification) {
      if (notification.thinkletId)
        this.$emit('view-thinklet', { studentRubric: notification.category === 'rubric:graded', thinkletId: notification.thinkletId });
    },

    setIsReadyToExploreVisible() {
      if (this.isReadyToExploreEnabled) {
        if (this.user.selectedClass && this.user.selectedClass.name !== 'Test Class')
          this.isReadyToExploreVisible = false;
        else if (this.user.type !== 'Student' && this.user.isNew)
          this.isReadyToExploreVisible = true;
      } else {
        this.isReadyToExploreVisible = false;
      }
    },

    showClassPromptError() {
      this.isNewClassPromptVisible = false;

      if (!this.user.classes || this.user.classes.length === 0)
        this.isNewClassPromptError = true;
    },

    showOverdueProblems() {
      RouteManager.getSharedInstance().route('dashboard', { overdue: true });
    },

    showCurrentProblems() {
      RouteManager.getSharedInstance().route('dashboard', { overdue: false });
    }
  },

  destroyed() {
    this.removeCloseHelperListener();

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_TITLE', null);
    helperStore.commit('SET_MESSAGE', null);
  }
}
</script>

<style lang="scss">
  .dashboard {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    background-color: #E0F6FF;
    height: 100%;

    .body {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      padding: 20px 30px;
    }

    .welcome-back {
      background-image: url('/images/Wave.svg');
      background-position: bottom right;
      background-repeat: no-repeat;
      padding: 20px 40px;
      margin-bottom: 20px;
      border-radius: 10px;
      -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
      box-shadow: 0 0 14px rgba(0, 0, 0, .1);
      background-color: #fdecdc;

      .avatar {
        float: right;
      }
    }

    h1 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .class-picker {
      margin-bottom: 20px;
      width: auto;
      font-weight: 700;
    }

      .class-picker button {
        height: 40px !important;
        line-height: 10px !important;
        font-weight: 700 !important;
        width: auto !important;
        border: 1px solid #E7E7E0 !important;
        border-radius: 2px;
        padding-left: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

        .class-picker-label {
          font-weight: 700;
          font-size: 12px;
          display: block;
        }

    h3 {
      font-size: 16px;
      font-weight: 700;
      position: relative;
      margin-top: 0;
    }

    .notifications {
      background-color: #171e26;
      color: white;
      width: 300px;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
    }

      .notifications h3 {
        margin-bottom: 0;
        flex-grow: 1;
        -webkit-flex-grow: 1;
      }

    .notifications-list {
      padding-right: 20px;
      overflow-y: auto;
      height: 100%;
    }

    .notification .message {
      color: #aaa8af;
      padding-left: 35px;
      text-decoration: underline;
      display: block;
      margin-top: 0;
    }

    .flagged {
      background-color: #d05b4a;
      color: white;
      border-radius: 4px;
      padding: 4px;
      margin-left: 4px;
    }

    .notification-toggle button {
      color: rgb(38, 169, 224) !important;
      background-color: transparent !important;
      border: 0 !important;
      padding: 0 !important;
      padding-right: 25px !important;
    }

      .notification-toggle button:hover {
        color: rgb(38, 169, 224) !important;
        border: 0 !important;
      }

    .assignments {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      overflow-y: auto;
    }

    .assignment {
      border: 1px solid rgb(38, 169, 224);
      -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
      border-radius: 4px;
      padding: 15px 20px;
      background-color: #f1faff;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      margin-bottom: 8px;
      align-items: center;
      -webkit-align-items: center;
    }

      .details {
        flex-grow: 1;
        -webkit-flex-grow: 1;
      }

    .due-soon {
      background-color: #f5a623;
      border-radius: 16px;
      padding: 2px 10px;
      margin-left: 8px;
    }

    .past-due {
      background-color: #F57F23;
      border-radius: 16px;
      padding: 2px 10px;
      margin-left: 8px;
      color: black;
    }

    .see-past-due-problems {
      color: #363845;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid rgb(38, 169, 224);
      position: absolute;
      bottom: 0;
      right: 0;
      padding-bottom: 4px;
    }

      .see-past-due-problems:hover {
        text-decoration: none;
      }

    .version {
      border: 1px solid #cce2ec;
      border-radius: 15px;
      width: 30px;
      height: 30px;
      background-color: white;
      margin-right: 10px;
      text-align: center;
      line-height: 30px;
      display: inline-block;
    }

    .completed {
      background-image: url(/images/check_icon_green.svg);
      background-size: 20px 20px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 30px;
    }

    .notifications-header {
      padding: 20px 20px 0 20px;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      align-items: center;
      -webkit-align-items: center;
    }

    .notification {
      padding: 20px 40px 20px 20px;
      border-bottom: 1px solid #272f38;
      position: relative;

      .avatar {
        margin-right: 6px;
      }

      .hide-notification-btn {
        position: absolute;
        right: 20px;
        top: 30px;
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-image: url(/images/ClosexBlue_2px.svg);
        background-position: top left;
        width: 10px;
        height: 10px;
        text-indent: -9999em;
        overflow: hidden;
      }
    }

    .notifications-body {
      overflow-y: auto;
      flex-grow: 1;
      -webkit-flex-grow: 1;

      .notification-avatar {
        display: inline-block;
      }
    }

    .title {
      font-weight: 700;
      display: block;
    }

    .due {
      font-size: 12px;
    }

    .ef23 &, .cuethinkv2 & {
      flex-flow: row-reverse;

      .notifications {
        width: 240px;
        background-color: #181E25;
      }

      .notifications-header {
        display: block;
        border-bottom: 1px solid #272f38;
        padding-top: 18px;
        padding-bottom: 16px;

        h3 {
          a, a:link, a:visited {
            color: white;
            text-decoration: none;
            vertical-align: middle;
          }
        }
      }

      .view-notifications-body {
        background-image: url(/images/BottomWaves.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 300px 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: 700;

        h1 {
          margin-bottom: 140px;
        }
      }

      .back-btn {
        background-image: url(/images/Notifications_BackArrow.svg);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 15px 15px;
        background-color: transparent;
        width: 15px;
        height: 15px;
        vertical-align: top;
        margin-right: 35px;
      }

      .notification .message {
        padding-left: 0;
      }

      .thought-of-the-day {
        border-radius: 10px;
        background-color: #a5e4d1;
        background-image: url(/images/ThoughtOfTheDayBackground.jpg);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 684px 167px;
        width: 400px;
        height: 200px;
        padding: 20px 170px 20px 20px;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;

        h4 {
          font-weight: 700;
        }

        p {
          font-weight: 500;
          font-size: 14px;
        }
      }

      .on-time {
        background-color: #f1fbff;
        border-color: #2AA6DB;
      }

      .is-past-due {
        background-color: #FEF7F2;
        border-color: #F57F23;
      }

      .is-due-soon {
        background-color: #FDFCE5;
        border-color: #F5EA23;
      }

      .is-completed {
        background-color: #F1FFF2;
        border-color: #479D4A;
      }

      .assignments button {
        border-radius: 20px;
      }

      .completed {
        padding-right: 30px;
        padding-left: 0;
        background-position: right center;
      }

      .helper {
        left: 0;
        right: unset;
        bottom: -20px;
      }

      .due-soon {
        background-color: #F5EA23;
      }

      .notifications-list .notification-toggle {
        position: relative;
        left: 20px;
        margin-top: 10px;

        .dropdown-toggle {
          height: 20px !important;
        }
      }

      .notification:nth-child(2) {
        padding-top: 10px;
      }
    }
  }
</style>
