import 'src/components/shared/user_data';
import 'src/components/shared/cuethink';
import { CueThinkLogin } from 'core/login/loginDirective';
import 'config';
import GoogleSignIn from 'core/login/google-sign-in';
import CleverAuthentication from 'core/login/clever';
import { isEF23User } from 'core/common/user-modes';

function CueThinkLoginController ($scope, UserManager, $state, CueThinkConfig, $timeout, CueThink, $stateParams, $translate) {
  "ngInject";

  var userManager = UserManager;

  $scope.name = CueThinkConfig.name;
  $scope.logIn = userManager.logIn;
  $scope.cleverRedirectURL = CueThinkConfig.cleverRedirectURL;
  $scope.cleverClientID = CueThinkConfig.cleverClientID;
  $scope.googleClientID = CueThinkConfig.googleClientId;

  var _isMobile;
  $scope.googleAuthenticate = function (user, isMobile, callback) {
    _isMobile = isMobile;

    userManager.oAuthLogIn(user, isMobile, $scope.referral, callback);
  };

  $scope.cleverAuthenticate = function () {
    var args = [];

    for (var i = 0; i < arguments.length; i++)
      args.push(arguments[i]);

    args.push($scope.referral);

    return userManager.authenticateClever.apply(null, args);
  };

  $scope.register = function () {
    $state.go('registerchoice', { ref: $scope.referral });
  };

  $scope.googleRegister = function (user, isMobile) {
    var params = {
      email: user.email,
      id_token: user.token,
      is_mobile: isMobile,
      name: user.name,
      ref: $scope.referral
    };

    $state.go('googlesignup', params);
  };

  $scope.logInSuccess = function () {
    userManager.handleLogInSuccess.apply(null, arguments);

    if ($stateParams.sso && $stateParams.sig)
      window.location.href = CueThinkConfig.communityUrl;
  };

  $scope.cleverRegister = userManager.cleverRegister;

  $scope.version = CueThinkConfig.version;

  $scope.forgotPassword = function () {
    $state.go('forgotpassword');
  };

  $scope.forgotUserName = function () {
    $state.go('forgotusername');
  };

  $scope.fetchMaintenance = CueThink.fetchMaintenance;

  $scope.resetPassword = function (code) {
    $state.go('updatepassword', { code: code });
  };

  $scope.sslCommonName = CueThinkConfig.sslCommonName;

  if ($stateParams.automatic) {
    var proceed = function (user) {
      userManager.handleLogInSuccess(user);
    };

    if (UserManager.getUser()) {
      proceed(UserManager.getUser());
    } else {
      UserManager.isCleverAuthenticated().then(function (result) {
        if (result) {
          var cleverAuthenticate = function () {
            var args = [];

            for (var i = 0; i < arguments.length; i++)
              args.push(arguments[i]);

            return UserManager.authenticateClever.apply(null, args);
          };

          CueThinkLogin.cleverAuthenticate(cleverAuthenticate, CueThinkConfig.cleverRedirectURL, CueThinkConfig.cleverClientID, function (success, user) {
            if (success)
              proceed(user);
            else
              $state.go('login');
          });
        } else {
          new GoogleSignIn(function (user, isMobile, callback) {
            return UserManager.oAuthLogIn(user.token, isMobile, null, callback);
          }, function (user, success, message) {
            if (success)
              proceed(user);
            else
              $state.go('login');
          }, null, true);
        }
      });
    }

    return;
  } else if ($stateParams.token) {
    UserManager.authenticateWithToken($stateParams.token).then(function (user) {
      CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);
    });

    return;
  }

  $scope.user = userManager.getUser();
  $scope.referral = $stateParams.ref;

  if ($stateParams.sso && $stateParams.sig && $scope.user) {
    UserManager.authenticateDiscourse($stateParams.sso, $stateParams.sig).then(function (result) {
      if (result) {
        window.location.href = result;
      } else {
        $translate('FAILED_TO_LOG_IN_TO_DISCOURSE').then(function (value) {
          $state.go('error', { message: value });
        });
      }
    });

    return;
  }
}

CueThinkLoginController.logInSuccessHandler = function (user, $state, $stateParams) {
  var stateName, params;

  if ($stateParams && $stateParams.redirect) {
    window.location.href = $stateParams.redirect;

    return;
  }

  if (user.type == 'Student') {
    stateName = 'dashboard';
  } else {
    var expired = user.expired;

    if (!expired) {
      if (typeof (Storage) !== 'undefined')
        expired = localStorage.getItem('fullAccess') === 'false';
    }

    if (expired) {
      console.log("fullAccess false");

      stateName = 'dashboard';
      params = { trialexpired: true };
    } else if (user.isLZAccount) {
      let classProgressState;

      if (isEF23User(user) && false)
        classProgressState = 'monitor-report';
      else
        classProgressState = 'classes';

      stateName = classProgressState;
    } else {
      stateName = 'view-all-assignments';
    }
  }

  $state.go(stateName, params);
};

CueThinkLoginController.cleverAuthenticateHandler = function (success, user, $state, $stateParams) {
  if (success) {
    CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);
  } else {
    var params = {
      email: user.email,
      first_name: user.firstName,
      grade: user.grade,
      is_mobile: user.isMobile,
      last_name: user.lastName,
      ref: $stateParams ? $stateParams.ref : null,
      school: user.schoolName,
      type: user.type,
      token: user.token
    };

    $state.go('cleversignup', params);
  }
};

CueThinkLoginController.cleverAuthenticate = function (userManager, $state, $stateParams) {
  var cleverAuthentication = new CleverAuthentication(userManager.authenticateClever);

  cleverAuthentication.authenticate(function (success, user) {
    CueThinkLoginController.cleverAuthenticateHandler(success, user, $state, $stateParams);
  });
};

export default CueThinkLoginController;
