import WhiteboardUi from './whiteboardui.jquery';
import './whiteboard.css';
import tpl from './whiteboard-scrap.html';
import 'angular-translate';

angular.module('whiteboardscrap', ['pascalprecht.translate']).directive('whiteboardscrap', function ($translate) {
	return {
		restrict: 'E',
		template: tpl,
		link: function (scope, elements, attributes) {
			var whiteboard, _events, _saveCallback, _shouldAutosave;

			elements = $(elements);

			scope.$watch(attributes.events, function (value) {
				_events = value;

				if (whiteboard)
					whiteboard.setEvents(value);
			});

			scope.$watch(attributes.save, function (value) {
				_saveCallback = value;

				if (whiteboard)
					whiteboard.setSaveCallback(value);
			});

			scope.$watch(attributes.shouldAutosave, function (value) {
				_shouldAutosave = value;

				if (whiteboard)
					whiteboard.setShouldAutosave(value);
			});

			function setUpWhiteboard () {
				whiteboard = new WhiteboardUi(elements.find(".whiteboard-scrap:first"), false, false, $translate);
				whiteboard.setEvents(_events);
				whiteboard.setSaveCallback(_saveCallback);
				whiteboard.setShouldAutosave(_shouldAutosave);
			}

			scope.$watch(attributes.isVisible, function (value) {
				if (value && !whiteboard)
					setTimeout(setUpWhiteboard, 1000);

				if (!value && whiteboard)
					whiteboard.saveIfChanged();
			});
		}
	};
});
