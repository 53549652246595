import { BaseWhiteboardTool, inherit } from './base-tool';

export default function TextTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, width, height, text, color) {
      this.id = whiteboard.getNextId();
      this.type = TextTool.type;
      this.coordinates = [sx, sy, width, height];
      this.text = text;

      if (color)
        this.color = color;

      whiteboard.updateEventTime(this);

      this.font = whiteboard.getFont();
    };
  };

  this.isRotatable = function () {
    return false;
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.setStyles = function (obj) {
    var color = obj.color;

    if (!color)
      color = 'black';

    whiteboard.setStrokeColor(color);
    whiteboard.setFillColor(color);
    whiteboard.setFont(obj.font);
  };

  this.draw = function (obj) {
    TextTool.renderText(whiteboard, obj);
  };
}

TextTool.type = 'text';

inherit(TextTool, BaseWhiteboardTool);

TextTool.renderText = function (whiteboard, wbevent) {
  var sx = wbevent.coordinates[0] * whiteboard.getWidth();
  var sy = wbevent.coordinates[1] * whiteboard.getHeight();
  var ex = (wbevent.coordinates[0] + wbevent.coordinates[2]) * whiteboard.getWidth();
  var ey = (wbevent.coordinates[1] + wbevent.coordinates[3]) * whiteboard.getHeight();
  var w = wbevent.coordinates[2] * whiteboard.getWidth();
  var h = wbevent.coordinates[3] * whiteboard.getHeight();
  var height = h;

  var padding = {
    top: 8,
    right: 2,
    bottom: 4,
    left: 1
  };

  h -= padding.top;
  h -= padding.bottom;

  var minHeight = padding.top + padding.bottom + 1;

  if (h < minHeight)
    h = minHeight;

  TextTool.fitText(whiteboard, wbevent.text, h, sx, sy + padding.top - 8, w, h);

  return {
    sx: sx,
    sy: sy,
    ex: ex,
    ey: ey,
    width: w,
    height: height
  };
};

TextTool.getTextThatFitsOnLine = function (whiteboard, words, maxWidth) {
  var line = '';

  var context = whiteboard.getContext();

  for (var n = 0; n < words.length; n++) {
    var word = words[n];
    var testLine;

    if (line)
      testLine = line + ' ' + word;
    else
      testLine = word;

    var textWidth = context.measureText(testLine).width;

    // new word is too long, return text up until now
    if (textWidth > maxWidth) {
      if (n === 0) { // first word is too long, wrap mid-word
        while (textWidth > maxWidth && testLine.length > 1) {
          testLine = testLine.substr(0, testLine.length - 1);

          textWidth = context.measureText(testLine).width;
        }

        return testLine;
      } else if (line) {
        return line;
      }
    } else { // overwrite result line with new word
      line = testLine;
    }
  }

  return line;
};

TextTool.measureText = function (whiteboard, text, size, maxWidth, lineHeight, callback) {
  var newlineCharacters = "\n";
  var wordSpaceCharacters = ' ';
  var lines = text.split(newlineCharacters);
  var x = 0, y = 0;
  var longestWidth = 0;

  if (!maxWidth || maxWidth < 0)
    maxWidth = Number.MAX_SAFE_INTEGER;

  var context = whiteboard.getContext();

  var oldFont = context.font;
  context.font = size + 'px ' + whiteboard.getFont();

  for (var i = 0; i < lines.length; i++) {
    var line = lines[i].trim();

    do {
      var words = line.split(wordSpaceCharacters);

      y += lineHeight;

      var lineText = TextTool.getTextThatFitsOnLine(whiteboard, words, maxWidth);

      var textWidth = context.measureText(lineText).width;

      if (textWidth > longestWidth)
        longestWidth = textWidth;

      if (callback)
        callback(lineText, x, y, textWidth);

      // overflow
      line = line.substr(lineText.length).trim();
    } while (line);
  }

  context.font = oldFont;

  return {
    height: y,
    width: longestWidth
  };
};

// renders text on the canvas on multiple lines wrapped at maxWidth
TextTool.wrapText = function (whiteboard, text, size, x, y, maxWidth, lineHeight, center) {
  return TextTool.measureText(whiteboard, text, size, maxWidth, lineHeight, function (line, x2, y2, width) {
    var x1, y1;

    if (center)
      x1 = x + ((maxWidth - width) / 2);
    else
      x1 = x + x2;

    y1 = y + y2;

    whiteboard.getContext().fillText(line, x1, y1);
  });
};

TextTool.getFittedTextFontSize = function (whiteboard, text, fontSize, width, height) {
  var size;

  var calculateSize = function () {
    size = TextTool.measureText(whiteboard, text, fontSize, width, fontSize);
  };

  calculateSize();

  while (size.height > height && fontSize > 1) {
    fontSize = Math.floor(fontSize) - 1;

    calculateSize();
  }

  return fontSize;
};

TextTool.fitText = function (whiteboard, text, fontSize, x, y, width, height) {
  fontSize = TextTool.getFittedTextFontSize(whiteboard, text, fontSize, width, height);

  TextTool.wrapText(whiteboard, text, fontSize, x, y, width, fontSize);
};
