import 'angular-ui-bootstrap';
import './landing.css';
import tpl from './introDirective.tpl.html';

angular.module('cuethink.landing', ['ui.bootstrap.carousel'])
	.directive('intro', function () {
		return {
			restrict: 'E',
			replace: true,
			template: tpl,
			scope: {
				done: '=',
				tutorial1VideoUrl: '='
			}
		};
	}).filter("trustUrl", function ($sce) {
        return function (recordingUrl) {
            return $sce.trustAsResourceUrl(recordingUrl);
        };
    });
