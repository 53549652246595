import WhiteboardPlayerUi from './whiteboard-player-ui';
import './whiteboard.css';
import tpl from './whiteboard-player.html';

angular.module('whiteboardPlayer', []).directive('whiteboardplayer', function () {
	return {
		restrict: 'E',
		template: tpl,
		link: function (scope, element, attributes) {
			var whiteboard;

			whiteboard = new WhiteboardPlayerUi($(".whiteboard-player:first"));

			function setParentScopeVariables (name, value) {
				var s;

				s = scope;

				do {
					s[name] = value;

					if (!s.$$phase) {
						try {
							s.$digest();
						} catch (e) {
						}
					}

					s = s.$parent;
				} while (s);
			}

			whiteboard.setTimeCodeUpdateCallback(function (time) {
				setParentScopeVariables('time', time);
			});

			whiteboard.setPlaybackStateChangeCallback(function (whiteboard, state) {
				if (state == 'loaded') {
					scope.loaded = true;

					console.log('loaded from state change');
				}

				setParentScopeVariables('playbackState', state);
			});

			whiteboard.setSeekCallback(function (whiteboard, time) {
				setParentScopeVariables('seekTime', time);
			});

			// TODO: update time from whiteboard callback

			var hasLoadedEvents;
			scope.$watch(attributes.events, function (value) {
				whiteboard.setEvents(value);

				if (value) {
					hasLoadedEvents = true;

					if (hasAudio === false) {
						scope.loaded = true;

						console.log('loaded from events with no audio');
					}
				}
			});

			var hasAudio;
			scope.$watch(attributes.audioUrl, function (value) {
				if (value) {
					hasAudio = true;
				} else if (value === null) {
					hasAudio = false;

					if (hasLoadedEvents) {
						scope.loaded = true;

						console.log('loaded from no audio');
					}
				}

				whiteboard.setAudioURL(value);
			});

			scope.$watch(attributes.ratio, function (value) {
				whiteboard.setAspectRatio(value);
			});

			scope.$watch(attributes.videoUrl, function (value) {
				whiteboard.setVideoURL(value);

				if (value) {
					hasLoadedEvents = true;

					if (hasAudio === false)
						scope.loaded = true;
				}
			});

			scope.$watch(attributes.playbackState, function (value) {
				if (value == 'paused')
					whiteboard.pause();
				else if (value == 'playing')
					whiteboard.start();
			});

			scope.$watch(attributes.loadImage, function (value) {
				whiteboard.setImageLoadCallback(value);
			});

			scope.$watch(attributes.onStatusChange, function (value) {
				whiteboard.setOnStatusChangeCallback(value);
			});

			scope.$watch(attributes.duration, function (value) {
				whiteboard.setDuration(value);
			});

			scope.$on('$destroy', function () {
				whiteboard.pause();
			});
		}
	};
});
