import tpl from './rubricOverviewDirective.tpl.html';
import 'angular-translate';
import { getLocalizedThinkletTitle } from '../common/translation-helpers';
import { setUserModesScopeMembers } from '../common/user-modes';

export default function RubricOverviewDirective ($translate) {
  "ngInject";

  var linkFunction = function ($scope) {
    $scope.viewModel = {
      thinklets: []
    };

    function getFieldsForStep (thinklet, step) {
      if (!thinklet.rubric || !thinklet.UPSR)
        return {};

      var result;

      if (step == 'understand') {
          if (thinklet.UPSR)
              result = { notice: thinklet.UPSR.understand.known, wonder: thinklet.UPSR.understand.wondering, estimate: thinklet.UPSR.understand.answer };
          else
              result = { notice: null, wonder: null, estimate: null };
      } else if (step == 'plan') {
          if (thinklet.UPSR)
            result = { strategies: thinklet.UPSR.plan.strategies, plan: thinklet.UPSR.plan.plan };
        else
            result = { strategies: null, plan: null };
      } else if (step == 'solve') {
          if (thinklet.UPSR)
            result = { staticData: thinklet.UPSR.solve.staticData, videoURL: thinklet.UPSR.solve.videoUrl };
        else
            result = { staticData: null, videoURL: null };
      } else if (step == 'review') {
          if (thinklet.UPSR)
            result = { recording_math_checked: thinklet.UPSR.review.recording_math_checked, recording_math_unchecked: thinklet.UPSR.review.recording_math_unchecked === 0, answer: thinklet.UPSR.review.answer };
        else
            result = { recording_math_unchecked: null, answer: null };
      }

      return result;
    }

    $scope.isInProgress = function (thinklet, step) {
      var fields = getFieldsForStep(thinklet, step);
      var result = false;
      var fieldMissing  = false;

      Object.keys(fields).forEach(function (key) {
        var value = fields[key];

        if (value)
          result = true;
        else
          fieldMissing = true;
      });

      if (result && !fieldMissing)
        result = false;

      return result;
    };

    $scope.isComplete = function (thinklet, step) {
      var fields = getFieldsForStep(thinklet, step);
      var result = true;

      Object.keys(fields).forEach(function (key) {
        var value = fields[key];

        if (!value) {
            result = false;

            return false;
        }
      });

      return result;
    };

    function loadRubrics () {
      $scope.loadRubrics().then(function (thinklets) {
        $scope.viewModel.thinklets = thinklets;
      });
    }

    loadRubrics();

    $scope.viewThinkletRubric = function (thinklet) {
      $scope.viewThinklet(thinklet);
    };

    $scope.toggleClasses = function () {
      $scope.classesVisible = !$scope.classesVisible;

      if ($scope.classesVisible) {
        $scope.$$postDigest(function () {
          var button = $('navbar .class-chooser:first');
          var list = $('navbar ul.class-list:first');
          var offset = button.offset();

          $('dashboard:first').append(list);
          list.css('left', offset.left + 'px');
          list.css('top', offset.top + button.height() + 'px');
        });
      }
    };

    $scope.chooseClass = function (cls) {
      $scope.selectClass(cls);

      $scope.classesVisible = false;

      $scope.viewModel.thinklets = null;

      loadRubrics();
    };

    $scope.getThinkletTitle = getLocalizedThinkletTitle.bind(this, $translate);

    setUserModesScopeMembers($scope);
  };

  return {
      restrict: 'E',
      template: tpl,
      link: linkFunction,
      scope: {
        defaultAvatarColors: '=',
        user: '=',
        logOut: '=logout',
        setUpNavigation: "=",
        getTrialDays: '=',
        trialExpired: '=',
        dashboard: '=',
        profile: '=',
        gallery: '=',
        navigateRubric: '=',
        settings: '=',
        tutorial: '=',
        state: '=',
        dashboardState: '=',
        profileState: '=',
        galleryState: '=',
        rubricState: '=',
        joinClass: '=',
        selectClass: '=',
        teacherDashboard: '=',
        logInIntercom: '&',
        loadRubrics: '=',
        viewThinklet: '=',
        isCueteachEnabled: '=',
        fetchHasSeenTrialEndPopUp: '=',
        setHasSeenTrialEndPopUp: '=',
        onSubmitMindWanderingProbe: '&',
        navigateJoinClass: '='
      }
  };
};
