<template>
  <div class="monitor-report">
    <h1>
      {{ 'CLASS_PROGRESS' | translate }}

      <button
        class="secondary-button update-btn"
        tabindex="0"
        @click="onUpdateButtonClick">
        {{ 'UPDATE' | translate }}
      </button>
    </h1>

    <div class="filter">
      <label>{{ 'FILTER_BY' | translate }}:</label>

      <checkbox-dropdown
        class="cuethink-dropdown"
        label="name"
        :items="classes"
        :title="translate('CLASS_NAME')"
        v-model="selectedClasses">
      </checkbox-dropdown>

      <checkbox-dropdown
        class="cuethink-dropdown"
        :items="students"
        :label="student => propercase(student.firstName + ' ' + student.lastName)"
        :title="translate('STUDENT')"
        v-model="selectedStudents">
      </checkbox-dropdown>

      <checkbox-dropdown
        class="cuethink-dropdown"
        label="title"
        :items="uniqueProblems"
        :title="translate('PROBLEM')"
        v-model="selectedProblems">
      </checkbox-dropdown>

      <b-dropdown
        class="cuethink-dropdown"
        :text="translate(selectedPhaseName)">
        <b-dropdown-item
          v-for="phase in phases"
          :key="phase.name"
          @click="onPhaseClick(phase)">
          {{ phase.name | translate }}
        </b-dropdown-item>
      </b-dropdown>

      <cuethink-dropdown
        class="time-filter"
        label="name"
        :items="timeFilters"
        :populated="true"
        :selected="translate(selectedTimeFilter.name)"
        @select="onTimeFilterSelect">
      </cuethink-dropdown>

      <button
        v-if="selectedPhase === 'explore' || selectedPhase === 'review'"
        class="secondary-button view-class-snapshot-btn"
        tabindex="0"
        @click="onViewClassSnapshotButtonClick">
        {{ 'VIEW_CLASS_SNAPSHOT' | translate }}
      </button>

      <div class="selected-filters">
        <label>{{ 'SELECTED_FILTERS' | translate }}:</label>

        <a
          v-for="cls in selectedClasses"
          class="selected-filter"
          href="#"
          tabindex="0"
          :key="cls.id"
          @click.stop.prevent="onClassFilterClick(cls)">
          {{ cls.name }}
        </a>

        <a
          v-for="student in selectedStudents"
          class="selected-filter"
          href="#"
          tabindex="0"
          :key="student.id"
          @click.stop.prevent="onStudentFilterClick(student)">
          {{ propercase(student.firstName + ' ' + student.lastName) }}
        </a>

        <a
          v-for="problem in selectedProblems"
          class="selected-filter"
          href="#"
          tabindex="0"
          :key="problem.id"
          @click.stop.prevent="onProblemFilterClick(problem)">
          {{ problem.title }}
        </a>
      </div>
    </div>

    <h4>{{ subheader | translate }}</h4>

    <div
      class="progress-cards"
      :class="{ 'video-gallery': selectedPhase === 'video' }"
      v-infinite-scroll="loadMoreThinklets">
      <div
        v-for="thinklet in thinklets"
        :class="{ 'progress-card': selectedPhase !== 'video', 'video-card': selectedPhase === 'video' }"
        :key="thinklet.id"
        @click="onViewThinkletButtonClick(thinklet)">
        <img
          v-if="getThumbnail(thinklet)"
          class="thumbnail"
          :alt="thinklet.title"
          :src="getThumbnail(thinklet)"
        />
        <div
          v-else
          class="thumbnail empty">
        </div>

        <div class="details">
          <template v-if="selectedPhase === 'video'">
            <h5 class="title">{{ thinklet.title }}</h5>

            <p class="creator">
              {{ thinklet.user.firstName | propercase }} {{ thinklet.user.lastName.substring(0, 1) | uppercase }}

              <popover :name="'users-' + thinklet.id">
                <ul class="annotation-users">
                  <li v-for="u in thinklet.annotation_user">
                    {{ u }}
                  </li>
                </ul>
              </popover>

              <span
                class="views"
                v-popover.top="{ name: 'users-' + thinklet.id }">
                {{ thinklet.annotations }}
              </span>
            </p>

            <progress-icons
              :dark-mode="true"
              :thinklet="thinklet">
            </progress-icons>
          </template>
          <template v-else>
            <p class="creator">{{ thinklet.user.firstName | propercase }} {{ thinklet.user.lastName | uppercase }}</p>

            <h5 class="title">{{ thinklet.title }}</h5>

            <template v-if="selectedPhase === 'explore'">
              <label>{{ 'THE_PROBLEM_IS_ASKING_ME_TO' | translate }}:</label>

              <p class="question">{{ thinklet.tryingToSolve }}</p>
            </template>
            <template v-if="selectedPhase === 'plan'">
              <label>{{ 'SELECTED_STRATEGIES' | translate | propercase }}:</label>

              <p class="strategies">{{ getSelectedStrategies(thinklet).join(', ') }}</p>
            </template>
            <template v-if="selectedPhase === 'solve'">
              <label>{{ 'STATUS' | translate }}:</label>

              <progress-icons
                :thinklet="thinklet">
              </progress-icons>
            </template>
            <template v-if="selectedPhase === 'review'">
              <label>{{ 'ANSWER' | translate }}:</label>

              <p class="question">{{ thinklet.answer }}</p>
            </template>
          </template>
        </div>
      </div>
    </div>

    <report-snapshot
      v-if="isSnapshotVisible"
      class="explore-snapshot"
      :phase="selectedPhase"
      :thinklets="thinklets"
      @close="onExploreSnapshotClose">
    </report-snapshot>

    <view-progress
      v-if="isViewProgressVisible"
      :phase="selectedPhase"
      :thinklet="selectedThinklet"
      :user="user"
      @close="onViewProgressClose">
    </view-progress>
  </div>
</template>

<script>
import ThinkletManager from '../../components/shared/thinklet_data';
import 'src/images/Close x blue.svg';
import 'src/images/Grey_EyeIcon.svg';
import reportSnapshot from './report-snapshot';
import translate from 'core/common/filters/translate.filter';
import TeacherManager from '../../components/shared/teacher_data';
import _ from 'underscore';
import propercase from 'core/common/filters/propercase.filter';
import ProgressIcons from './progress-icons';
import ViewProgress from './view-progress';
import infiniteScroll from 'vue-infinite-scroll';
import date from 'core/common/filters/formatTime.filter';
import uppercase from 'core/common/filters/uppercase.filter';
import 'vue-js-popover';
import UserManager from '../../components/shared/user_data';
import 'src/images/Logo_Icon.svg';

const phases = [
  {
    id: 'explore',
    name: 'EXPLORE_PHASE'
  },
  {
    id: 'plan',
    name: 'PLAN_PHASE'
  },
  {
    id: 'solve',
    name: 'SOLVE_PHASE'
  },
  {
    id: 'review',
    name: 'REVIEW_PHASE'
  },
  {
    id: 'video',
    name: 'VIDEO_GALLERY'
  }
];

export default {
  components: {
    'checkbox-dropdown': require('core/common/checkbox-dropdown').default,
    CuethinkDropdown: () => import('core/common/cuethinkDropdown'),
    ProgressIcons,
    'report-snapshot': reportSnapshot,
    ViewProgress
  },

  directives: { infiniteScroll },

  filters: {
    date,
    propercase,
    translate,
    uppercase
  },

  data() {
    return {
      assignments: [],
      classes: [],
      hasLoadedClasses: false,
      hasLoadedStudents: false,
      hasNotifiedLoaded: false,
      isSnapshotVisible: false,
      isViewProgressVisible: false,
      problems: [],
      propercase,
      selectedClasses: [],
      selectedProblems: [],
      selectedStudents: [],
      start: 0,
      limit: 10,
      phases,
      selectedPhase: 'explore',
      selectedStrategy: null,
      selectedThinklet: null,
      selectedTimeFilter: null,
      strategies: [],
      students: [],
      thinklets: [],
      timeFilters: [
        { time: { days: -14 } },
        { time: { months: -1 } },
        { time: { months: -3 } },
        { time: { months: -6 } },
        { time: { years: -1 } },
        { time: null }
      ],
      translate,
      uniqueProblems: []
    };
  },

  computed: {
    selectedAssignmentIds() {
      return this.assignments.filter(assignment => this.selectedProblems.find(problem => problem.title === assignment.name)).map(assignment => assignment.id);
    },

    selectedClassIds() {
      return this.selectedClasses.map(cls => cls.id);
    },

    selectedPhaseName() {
      return this.phases.find(phase => phase.id === this.selectedPhase).name;
    },

    selectedStudentIds() {
      return this.selectedStudents.map(user => user.id);
    },

    subheader() {
      var phase;

      if (this.selectedPhase === 'explore')
        phase = 'EXPLORE';
      else if (this.selectedPhase === 'plan')
        phase = 'PLAN';
      else if (this.selectedPhase === 'solve')
        phase = 'SOLVE';
      else if (this.selectedPhase === 'review')
        phase = 'REVIEW';
      else if (this.selectedPhase === 'video')
        return 'VIDEO_GALLERY';

      return phase + '_PHASE_PROGRESS';
    },

    user() {
      return UserManager.getShared().getUser();
    }
  },

  watch: {
    selectedClasses() {
      this.reloadThinklets();
    },

    selectedPhase() {
      this.reloadThinklets();
    },

    selectedProblems() {
      this.reloadThinklets();
    },

    selectedStudents() {
      this.reloadThinklets();
    },

    selectedTimeFilter() {
      this.reloadThinklets();
    }
  },

  created() {
    let savedFilterIndex;

    if (typeof (Storage) === 'undefined') {
      savedFilterIndex = 0;
    } else {
      savedFilterIndex = localStorage.getItem('monitor-time-filter-index');

      if (typeof savedFilterIndex !== 'number')
        savedFilterIndex = 0;
    }

    this.selectedTimeFilter = this.timeFilters[savedFilterIndex];

    TeacherManager.getShared().loadClasses().then(classes => {
      this.classes = classes;

      this.hasLoadedClasses = true;

      this.notifyLoaded();
    });

    TeacherManager.getShared().loadCoteachingAssignments().then(assignments => {
      this.assignments = assignments;

      this.problems = assignments.map(assignment => ({ id: assignment.problemId, title: assignment.name }));

      this.uniqueProblems = _.sortBy(_.uniq(this.problems, false, 'title'), 'title');
    });

    TeacherManager.getShared().loadStudents().then(students => {
      this.students = students;

      this.hasLoadedStudents = true;

      this.notifyLoaded();
    });

    var translationKeys = ['WEEKS', 'MONTH', 'MONTHS', 'YEAR', 'SHOWING_ALL', 'ASSIGNED_PROBLEMS', 'CLOSED_PROBLEMS', 'LAST', 'LAST_PLURAL'];
    var translations = {};

    translationKeys.forEach(key => {
      translations[key] = translate(key);
    });

    this.timeFilters[0].name = 'Last 2 ' + translations['WEEKS'];
    this.timeFilters[1].name = 'Last ' + translations['MONTH'];
    this.timeFilters[2].name = 'Last 3 ' + translations['MONTHS'];
    this.timeFilters[3].name = 'Last 6 ' + translations['MONTHS'];
    this.timeFilters[4].name = 'Last ' + translations['YEAR'];
    this.timeFilters[5].name = translations['SHOWING_ALL'];

    this.reloadThinklets();
  },

  methods: {
    clearThinklets() {
      this.start = 0;

      this.thinklets.splice(0);
    },

    getSelectedStrategies(thinklet) {
      return thinklet.strategies ? thinklet.strategies.filter(strategy => strategy.status === 'true').map(strategy => translate(strategy.name)) : [];
    },

    getThumbnail(thinklet) {
      let result;

      if (this.selectedPhase === 'video') {
        result = thinklet.thumbnailURL;
      } else if (this.selectedPhase === 'solve') {
        result = thinklet[this.selectedPhase + 'Screenshot'];

        if (Array.isArray(result) && result.length > 0) {
          result = result.filter(page => page.page === 1);

          if (result.length > 0)
            result = result[result.length - 1].filePath;
          else
            result = thinklet.thumbnailURL;
        } else {
          result = thinklet.thumbnailURL;
        }
      } else {
        result = thinklet[this.selectedPhase + '_screenshot'];
      }

      return result;
    },

    loadMoreThinklets() {
      this.start++;

      this.loadThinklets().then(thinklets => {
        this.thinklets = this.thinklets.concat(thinklets);
      });
    },

    loadThinklets(start, limit) {
      if (typeof start === 'undefined') {
        start = this.start;
      }

      if (typeof limit === 'undefined') {
        limit = this.limit;
      }

      return ThinkletManager.getShared().loadStudentsThinklets(null, this.selectedClassIds, this.selectedStudentIds, this.selectedAssignmentIds, null, false, 'time', false, start, limit, null, this.selectedPhase === 'explore', this.selectedPhase === 'plan', this.selectedPhase === 'solve', this.selectedPhase === 'review', this.selectedPhase === 'video').then(result => {
        this.notifyLoaded();

        return result;
      });
    },

    notifyLoaded() {
      if (!this.hasNotifiedLoaded && this.thinklets && this.thinklets.length > 0 && this.hasLoadedClasses && this.hasLoadedStudents) {
        this.hasNotifiedLoaded = true;

        this.$emit('loaded');
      }
    },

    onClassFilterClick(cls) {
      this.selectedClasses.splice(this.selectedClasses.indexOf(cls), 1);
    },

    onExploreSnapshotClose() {
      this.isSnapshotVisible = false;
    },

    onPhaseClick(phase) {
      if (this.selectedPhase !== phase.id) {
        var reload = this.selectedPhase === 'video' || phase.id === 'video';

        this.selectedPhase = phase.id;

        if (reload)
          this.reloadThinklets();
      }
    },

    onProblemFilterClick(problem) {
      this.selectedProblems.splice(this.selectedProblems.indexOf(problem), 1);
    },

    onStudentFilterClick(student) {
      this.selectedStudents.splice(this.selectedStudents.indexOf(student), 1);
    },

    onTimeFilterSelect(filter) {
      this.selectedTimeFilter = filter;

      if (typeof localStorage !== 'undefined')
        localStorage.setItem('monitor-time-filter-index', this.timeFilters.indexOf(filter));
    },

    onUpdateButtonClick() {
      this.thinklets = [];

      this.loadThinklets(0, this.thinklets.length).then(thinklets => {
        this.thinklets = thinklets;
      });
    },

    onViewClassSnapshotButtonClick() {
      this.isSnapshotVisible = true;
    },

    onViewProgressClose() {
      this.isViewProgressVisible = false;
    },

    onViewThinkletButtonClick(thinklet) {
      // this.$emit('viewThinklet', thinklet);

      this.selectedThinklet = thinklet;

      this.isViewProgressVisible = true;
    },

    reloadThinklets() {
      this.clearThinklets();

      this.loadThinklets().then(thinklets => {
        this.thinklets = thinklets;
      });
    }
  }
}
</script>

<style scoped>
  .monitor-report {
    color: #363845;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  h1 {
    margin-bottom: 10px;
  }

  .filter {
    background-color: white;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

    .filter>label {
      display: block;
      margin-bottom: 10px;
    }

    .selected-filters {
      margin-top: 10px;
    }

      .selected-filter {
        background-image: url('/images/Close x blue.svg');
        background-position: right center;
        background-size: 10px 10px;
        background-repeat: no-repeat;
        padding-right: 15px;
        color: rgb(38, 169, 224);
        margin-right: 10px;
        text-decoration: underline;
      }

  .progress-cards {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    overflow-y: auto;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-content: flex-start;
    -webkit-align-content: flex-start;
  }

  .progress-cards.video-gallery {
    justify-content: unset;
    -webkit-justify-content: unset;
  }

    .progress-card {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      cursor: pointer;
      position: relative;
      padding: 10px;
      width: calc(50% - 10px);
      background-color: white;
      border-radius: 8px;
      -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .03);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .03);
      margin-bottom: 20px;
    }

      .progress-card .thumbnail {
        width: 125px;
        height: 100px;
        margin-right: 20px;
      }

        .progress-card img.thumbnail {
          object-fit: cover;
        }

        .progress-card .thumbnail.empty {
          background-image: url(/images/Logo_Icon.svg);
          background-position: center center;
          background-size: 30px 30px;
          background-repeat: no-repeat;
        }

      .progress-card .details {
        flex-grow: 1;
        -webkit-flex-grow: 1;
      }

        .progress-card .creator {
          font-weight: 700;
          text-decoration: underline;
          margin-bottom: 0;
          margin-top: 0;
          overflow: visible;
        }

        .progress-card .title {
          margin-top: 0;
          color: #979797;
          font-weight: 500;
        }

        .progress-card .details label {
          margin-bottom: 0;
        }

      .view-class-snapshot-btn {
        background-image: url(/images/Grey_EyeIcon.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        padding-left: 40px;
        margin-left: 20px;
      }

  h1 {
    position: relative;
  }

  .update-btn {
    margin-bottom: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .update-btn, .view-class-snapshot-btn {
    border-radius: 15px;
    background-color: #dff6fe;
    color: #363845 !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
  }

  .cuethink-dropdown {
    width: 150px;
  }

  .progress-card .details {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  .time-filter {
    position: absolute;
    right: 100px;
    top: 0;
    border: 0;
  }

  .time-filter:before {
    content: '';
    background-image: url(/images/Calendar.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-color: transparent !important;
    padding-left: 20px;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: sub;
  }

  .video-card {
    width: 275px;
    height: 225px;
    background-color: #363845;
    color: white;
    display: inline-block;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .03);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .03);
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  .video-card .thumbnail {
    width: 100%;
    height: 150px;
    background-color: white;
    margin-bottom: 0;
  }

  .video-card h5, .video-card p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-card .details {
    height: 75px;
    padding: 8px;
  }

  .video-card .creator {
    color: #979797;
    position: relative;
    overflow: visible;
  }

  .views {
    background-image: url(/images/SPEECHBUBBLES.svg);
    background-size: 15px 15px;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }

    .views ul {
      color: #363845;
    }

  .annotation-users {
    list-style-type: none;
    padding-left: 0;
  }

    .annotation-users li {
      padding-left: 0;
    }
</style>

<style>
.state-monitor-report .teacher-body {
  padding-top: 48px;
}

.monitor-report .time-filter {
  width: 125px !important;
}

.monitor-report .time-filter .dropdown-toggle {
  float: none;
  background-color: transparent;
  border: 0 !important;
  padding-left: 0 !important;
  display: inline-block;
  width: 105px;
}

.monitor-report .creator .vue-popover {
  left: auto !important;
  right: -95px !important;
  bottom: 30px !important;
  top: auto !important;
  padding: 10px !important;
  color: #383645;
}

  .monitor-report .creator .vue-popover ul {
    margin-bottom: 0;
  }
</style>
