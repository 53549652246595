import 'core/viewThinklet/viewThinkletDirective';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/thinkletloader';
import 'core/tutorial/tutorial';
import 'core/viewThinklet/viewThinkletScreenDirective';
import 'src/components/shared/base';
require('src/components/shared/view_drawer.partial.html');
import 'src/app/urls';
import 'angular-translate';
import '../shared/teacher_data';
import helperStore from 'core/helper/helper.store';
import LogManager from 'core/logging/log-manager';
import NotificationCenter from 'core/notifications/notification-center';

let _seenHelper = 0;

function ViewThinkletController ($scope, $controller, $stateParams, $state, TeacherManager, ThinkletManager, UserManager, ThinkletLoader, TutorialService, CueThinkConfig, $translate) {
  "ngInject";

  $controller('BaseController', { '$scope': $scope });

  $scope.viewModel = {
    isHelperVisible: false
  };

  $scope.teacherManager = TeacherManager;
  $scope.thinkletManager = ThinkletManager;
  $scope.userManager = UserManager;

  $scope.thinkletId = $stateParams.id;

  if ($scope.thinkletId) {
    localStorage.setItem('thinkletId', $scope.thinkletId);
  } else {
    $scope.thinkletId = localStorage.getItem('thinkletId');

    if ($scope.thinkletId)
      $scope.thinkletId = parseInt($scope.thinkletId);
  }

  var back = $stateParams.back;

  $scope.goBack = function () {
    if (back == 'classes') {
      $state.go('classes', { name: $stateParams.cname, id: $stateParams.cid });
    } else if (back === 'monitor-report') {
      $state.go('monitor-report', { name: $stateParams.cname, id: $stateParams.cid });
    } else if (back == "assignment") {
      $state.go('assignment-roster', {
        "assignmentname": $stateParams.aname,
        id: $stateParams.aid,
        "classname": $stateParams.cname,
        classId: $stateParams.cid
      });
    } else if (back == "student") {
      $state.go('student', {
        "studentname": $stateParams.sname,
        id: $stateParams.sid,
        "classname": $stateParams.cname,
        classId: $stateParams.cid
      });
    } else {
      if (history.state)
        history.back();
      else
        $state.go('browse');
    }
  };

  $scope.createShareCode = ThinkletManager.createShareCode;

  $scope.user = UserManager.getUser();

  $scope.saveAnnotation = function (annotation) {
    ThinkletManager.saveAnnotation(annotation);
  };

  $scope.createAnnotation = ThinkletManager.addAnnotation;
  $scope.unflagAnnotation = ThinkletManager.unflagAnnotation;
  $scope.flagAnnotation = ThinkletManager.flagAnnotation;
  $scope.likeAnnotation = ThinkletManager.likeAnnotation;
  $scope.unlikeAnnotation =  ThinkletManager.unlikeAnnotation;
  $scope.deleteAnnotation = ThinkletManager.deleteAnnotation;
  $scope.unlike = ThinkletManager.unlikeThinklet;
  $scope.like = ThinkletManager.likeThinklet;

  $scope.loadRecording = ThinkletLoader.load;

  $state.href = function (name) {
    console.log(name);
  };

  $scope.viewThinklet = function (thinkletId) {
    $state.go('view', { id: thinkletId });
  };

  $scope.loadThinkletVersions = function (id, callback, error) {
    if (TutorialService.isActive()) {
      callback([]);

      return;
    }

    ThinkletManager.loadThinkletVersions(id, callback, error);
  };

  var _oldLoadCallback = TutorialService.getLoadCallback();

  TutorialService.setLoadCallback(function (step, finish, steps) {
    if (step.state != $state.current.name)
      return;

    if (_oldLoadCallback) {
      var shouldContinue = _oldLoadCallback(step, finish, steps);

      if (shouldContinue === false)
        return false;
    }

    if (!step.data) {
      var originalState = step.state;
      var i = steps.indexOf(step);

      if (steps.length > i + 1 && steps[i + 1].state != originalState) {
        while (i > 0 && !step.data && step.state == originalState) {
          step = steps[--i];
        }
      }
    }

    if (step.data && step.data.thinklet)
      $scope.sampleThinklet = step.data.thinklet;
  });

  $scope.createAnnotationReply = ThinkletManager.addAnnotationReply;
  $scope.deleteAnnotationReply = ThinkletManager.deleteAnnotationReply;
  $scope.editAnnotationReply = ThinkletManager.editAnnotationReply;
  $scope.flagAnnotationReply = ThinkletManager.flagAnnotationReply;
  $scope.unflagAnnotationReply = ThinkletManager.unflagAnnotationReply;
  $scope.likeAnnotationReply = ThinkletManager.likeAnnotationReply;
  $scope.unlikeAnnotationReply =  ThinkletManager.unlikeAnnotationReply;

  $scope.shareRubric = ThinkletManager.shareRubric;
  $scope.isStudentRubricEnabled = $stateParams.studentRubric;
  $scope.saveStudentReflection = ThinkletManager.saveStudentReflection;

  $scope.loadGlobalSettings = function () {
    return UserManager.getGlobalSettings().then(function (settings) {
      return settings.viewingThinklets;
    });
  };

  $scope.viewModel.defaultSentenceStarters = CueThinkConfig.defaultSettings.viewingThinklets.annotations.sentenceStarters.slice(0);

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  let removeCloseHelperListener;

  function attemptToShowHelper() {
    if (_seenHelper < 3) {
      const helperMessages = [
        'WRITE_HOW_YOUR_STRATEGY_IS_THE_SAME_OR_DIFFERENT',
        'WRITE_SOMETHING_YOU_WANT_TO_TRY_NEXT_TIME',
        'WRITE_WHAT_YOU_AGREE_OR_DISAGREE_WITH_AND_WHY'
      ];

      helperStore.commit('SET_BUTTONS_TEXT', 'WAS_THIS_HELPFUL');
      helperStore.commit('SET_BUTTONS', {
        ['<span class="thumbs-up">Thumbs Up</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-down')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', { message: helperStore.state.message, response: true, title: helperStore.state.title });

          $scope.viewModel.isHelperVisible = false;

          digest();
        },
        ['<span class="thumbs-down">Thumbs Down</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-up')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', { message: helperStore.state.message, response: false, title: helperStore.state.title });

          $scope.viewModel.isHelperVisible = false;

          digest();
        }
      });
      helperStore.commit('SET_MESSAGE', helperMessages[Math.trunc(Math.random() * helperMessages.length)]);
      helperStore.commit('SET_TITLE', 'ANNOTATE!');

      $scope.viewModel.isHelperVisible = true;

      digest();

      _seenHelper++;
    }
  }

  let _hasStarted;

  $scope.$on('update:playbackState', (e, state) => {
    if (state === 'started')
      _hasStarted = true;

    if (_hasStarted && state === 'stopped')
      attemptToShowHelper();
  });

  function closeHelper() {
    $scope.viewModel.isHelperVisible = false;

    digest();
  }

  $scope.$on('CloseHelper', () => {
    closeHelper();
  });

  removeCloseHelperListener = NotificationCenter.getShared().addListener('CloseHelper', () => {
    closeHelper();
  });

  $scope.$on('$destroy', () => {
    LogManager.getShared().logPageEvent('leave thinklet', $scope.state, window.location.pathname, null, null, $scope.thinklet.id, $scope.thinklet.title);

    TutorialService.setLoadCallback(null);

    if (removeCloseHelperListener)
      removeCloseHelperListener();
  });

  $scope.$on("$destroy", function() {
    TutorialService.setLoadCallback(null);

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_TITLE', null);
    helperStore.commit('SET_MESSAGE', null);
  });
}

export default ViewThinkletController;
