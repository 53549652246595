import Whiteboard from './whiteboard';

function WhiteboardPlayer (canvas) {
	var _me = this, _whiteboard;

	_whiteboard = new Whiteboard();
	_whiteboard.init(canvas, true);

	{
		var methodNames = ['isReadyToPlay', 'getTime', 'isPaused', 'setPreviewEndedCallback', 'setPlaybackStateChangeCallback', 'setProgress', 'setPlaybackEventChangeCallback', 'getTimeForEvent', 'getDuration', 'isPlaying', 'hasEnded', 'setEvents', 'resetPlayback', 'setTime'];

		for (var i = 0; i < methodNames.length; i++) {
			var name = methodNames[i];

			_me[name] = _whiteboard[name];
		}
	}

	_me.setAudioURL = function (value) {
		_whiteboard.setAudioURL(value);
	};

	_me.play = function (callback) {
		_whiteboard.loadAudio().then(function () {
			if (_whiteboard.isPlayingPaused()) {
				_whiteboard.resumePreview();

				if (callback)
					callback(_whiteboard.getTime() / _whiteboard.getDuration() >= 0.99);
			} else {
				_whiteboard.preview(callback ? function () {
					callback(true);
				} : null);
			}
		});
	};

	_me.loadAudio = function () {
		return _whiteboard.loadAudio();
	};

	_me.pause = function () {
		_whiteboard.pausePreview();
	};

	_me.stop = function () {
		_whiteboard.stopPreview();
	};

	_me.setEvents = function (value) {
		_whiteboard.setRecordedEvents(value);

		_whiteboard.resetPlayback();
	};

	_me.setImageLoadCallback = function (value) {
		_whiteboard.setImageLoadCallback(value);
	};

	_me.setDuration = function (value) {
		_whiteboard.setDuration(value);
	};
}

export default WhiteboardPlayer;