import 'src/components/shared/thinkletloader';
import 'src/components/shared/user_data';
import 'src/components/shared/teacher_data';
import 'src/components/shared/thinklet_data';
import 'core/viewThinklet/viewThinkletDirective';
import './external.css';
require('src/components/shared/view_drawer.partial.html');
import 'src/app/urls';
import 'angular-translate';
import logoUrl from 'src/images/Logo.png';

function ExternalController ($scope, $stateParams, $state, TeacherManager, ThinkletManager, ThinkletLoader, UserManager, CueThinkConfig) {
  "ngInject";

  $scope.viewModel = {
    defaultSentenceStarters: CueThinkConfig.defaultSettings.viewingThinklets.annotations.sentenceStarters.slice(0)
  };

  $scope.logIn = function () {
    $state.go('login');
  };

  $scope.register = function () {
    $state.go('registerchoice');
  };

  $scope.isCueteachEnabled = $scope.user ? $scope.user.cueteachEnabled : false;
  $scope.logoUrl = logoUrl;
  $scope.thinkletId = Number.MAX_SAFE_INTEGER;
  $scope.teacherManager = TeacherManager;

  var _loadThinkletPromise;

  _loadThinkletPromise = new Promise((resolve, reject) => {
    $scope.thinkletManager = Object.assign({}, ThinkletManager, {
      loadThinklet(thinkletId, callback) {
        return ThinkletManager.loadShared($stateParams.v).then(thinklet => {
          if (callback)
            callback(thinklet);

          resolve(thinklet);

          return thinklet;
        });
      },

      loadThinkletVersions() {
        return _loadThinkletPromise.then(thinklet => {
          return ThinkletManager.loadThinkletVersions(thinklet.id);
        });
      }
    });
  });

  $scope.user = UserManager.getUser();
  $scope.userManager = UserManager;
}

export default ExternalController;
