import './form.css';

export default function (module) {
    module.directive('uiEnter', [function () {
        var linkFunction = function ($scope, $elm, $attrs) {

            function handleKeypress(event) {
                if (event.which === 13) {

                    $scope.$apply(function () {
                        $scope.$eval($attrs.uiEnter);
                    });

                    event.preventDefault();
                }
            }

            $($elm).on("keydown keypress", handleKeypress);

            $scope.$on('$destroy', function () {
                $($elm).off("keydown keypress", handleKeypress);
            });
        };

        return {
            restrict: "A",
            link: linkFunction
        };
    }]);
};
