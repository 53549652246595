import tpl from './equity-tutorial.tpl.html';
import './equity-tutorial.css';
import './ef-tutorial.directive';
import './images/Slide2_UpliftStudentThinking.png';
import './images/Slide3_Showcase students’ lived experiences.png';
import './images/Slide4.png';
import './images/Slide5.png';

export default function EquityTutorialDirective () {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        steps: [
          'EQUITY_TUTORIAL_TEXT_1',
          'EQUITY_TUTORIAL_TEXT_2',
          'EQUITY_TUTORIAL_TEXT_3',
          'EQUITY_TUTORIAL_TEXT_4',
          'EQUITY_TUTORIAL_TEXT_5',
          'EQUITY_TUTORIAL_TEXT_6'
        ]
      };
    },
    restrict: 'E',
    scope: {
      onClose: '&'
    },
    template: tpl
  };
}
