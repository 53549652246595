<template>
  <label
    class="switch"
    :class="{ on: value }">
    <button
      tabindex="0"
      @click="onButtonClick">
    </button>

    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean
  },
  methods: {
    onButtonClick() {
      this.$emit('input', !this.value);
    }
  }
}
</script>

<style scoped>
  .switch {
    vertical-align: middle;
  }

  button {
    width: 50px;
    height: 25px;
    padding: 4.5px;
    background-color: #F4F4F4;
    border-radius: 12.5px;
    vertical-align: super;
    position: relative;
    border: 2px solid #EAEAEA;
    margin-right: 10px;
  }

    button:before {
      width: 16px;
      height: 16px;
      background-color: #DF685D;
      content: '';
      border-radius: 8px;
      display: block;
      position: absolute;
      left: 4.5px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      border: 2px solid #DF685D;
    }

    .on button:before {
      background-color: #69B36C;
      left: auto;
      right: 4.5px;
      border-color: #5EA461;
    }

  label {
    font-weight: 500;
  }
</style>