import 'src/components/shared/base';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import 'core/profile/profileDirective';
import 'angular-ui-bootstrap';

function ProfileController ($scope, $controller, ThinkletManager, $state, TutorialService, UserManager, CueThink) {
  "ngInject";

  $scope.viewModel = {
    nameFormat: null
  };

  UserManager.getGlobalSettings().then(function(settings) {
    $scope.viewModel.nameFormat = settings.viewingThinklets.gallery.nameFormat;
  });

  $controller('BaseController', { '$scope': $scope });

  var thinklets, _thinkletsLoadCallback;

  $scope.loadThinklets = function (id, callback) {
    if (TutorialService.isActive()) {
      if (thinklets) {
        if (callback)
          callback(thinklets);

        return Promise.resolve(thinklets);
      } else {
        return new Promise(resolve => {
          _thinkletsLoadCallback = thinklets => {
            if (callback)
              callback(thinklets);

            resolve(thinklets);
          };
        });
      }
    }

    return ThinkletManager.loadUserThinklets(id);
  };

  $scope.deleteThinklet = ThinkletManager.deleteThinklet;

  $scope.createVersion = function (thinklet) {
    ThinkletManager.createNewThinkletVersion(thinklet.id, function (thinklet) {
      $scope.editThinklet(thinklet);
    });
  };

  $scope.editThinklet = function (thinklet) {
    CueThink.logThinkletEvent('edit thinklet', $scope.state, thinklet.problemId, thinklet.title, thinklet.id);

    $scope.navigateToWorkOnThinklet(thinklet);
  };

  $scope.createThinklet = function (problem, assignmentId, callback) {
    return ThinkletManager.createThinklet(problem, assignmentId, callback);
  };

  $scope.startThinklet = function (thinklet) {
    $scope.navigateToWorkOnThinklet(thinklet);
  };

  $scope.loadProblem = ThinkletManager.loadProblem;

  $scope.viewThinklet = function (thinklet) {
    $state.go('view', { id: thinklet.id });
  };

  var _oldLoadCallback = TutorialService.getLoadCallback();

  TutorialService.setLoadCallback(function (step, finish, steps) {
    if (step.state != $state.current.name)
      return;

    if (_oldLoadCallback) {
      var shouldContinue = _oldLoadCallback(step, finish, steps);

      if (shouldContinue === false)
        return false;
    }

    if (!step.data) {
      var originalState = step.state;
      var i = steps.indexOf(step);

      if (steps.length > i + 1 && steps[i + 1].state != originalState) {
        while (i > 0 && !step.data && step.state == originalState) {
          step = steps[--i];
        }
      }
    }

    if (step.data && step.data.thinklets) {
      thinklets = step.data.thinklets;

      if (_thinkletsLoadCallback) {
        _thinkletsLoadCallback(thinklets);
        _thinkletsLoadCallback = null;
      }
    }
  });

  $scope.selectClass = UserManager.selectClass;

  $scope.$on("$destroy", function () {
    TutorialService.setLoadCallback(null);
  });
}

export default ProfileController;
