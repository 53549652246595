import 'src/components/shared/base';
import 'core/gallery/galleryDirective';
import 'src/components/shared/cuethink';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/user_data';
import 'angular-ui-bootstrap';
import 'core/tutorial/tutorial';
import HelperIdleTimer from '../shared/helper-idle-timer';
import 'core/helper/helper.directive';
import 'angular-translate';
import helperStore from 'core/helper/helper.store';
import NotificationCenter from 'core/notifications/notification-center';
import LogManager from 'core/logging/log-manager';
import { Permissions } from 'core/permissions/permissions';

let _seenHelper;

function BrowseController ($scope, ThinkletManager, UserManager, $controller, TutorialService, $state, $q, $translate, TeacherManager, CueThinkConfig, PermissionsManager) {
  "ngInject";

  $controller('BaseController', { '$scope': $scope });

  var thinklets, _finishTutorialLoad, _returnedTutorialThinklets;

  if (!$scope.viewModel)
    $scope.viewModel = {};

  $scope.viewModel.isHelperVisible = false;

  $scope.loadGalleryFilterList = ThinkletManager.loadGalleryFilterList;

  $scope.loadFilteredThinklets = function (start, limit, students, classes, problems, labels, strategies, draftsOnly) {
    if (thinklets) {
      if (_returnedTutorialThinklets) {
        var deferred = $q.defer();
        deferred.resolve([]);
        return deferred.promise;
      }

      _returnedTutorialThinklets = true;

      if (_finishTutorialLoad) {
        $scope.$$postDigest(_finishTutorialLoad);
        _finishTutorialLoad = null;
      }

      var deferred = $q.defer();
      deferred.resolve(thinklets);
      return deferred.promise;
    }

    return ThinkletManager.loadRecentThinklets(start, limit, students, classes, problems, labels, strategies, draftsOnly, null);
  };

  var _oldLoadCallback = TutorialService.getLoadCallback();

  TutorialService.setLoadCallback(function (step, finish, steps) {
    if (step.state != $state.current.name)
      return;

    if (_oldLoadCallback) {
      var shouldContinue = _oldLoadCallback(step, finish, steps);

      if (shouldContinue === false)
        return false;
    }

    if (!step.data) {
      var originalState = step.state;
      var i = steps.indexOf(step);

      if (steps.length > i + 1 && steps[i + 1].state != originalState) {
        while (i > 0 && !step.data && step.state == originalState) {
          step = steps[--i];
        }
      }
    }

    if (step.data && step.data.thinklets) {
      thinklets = step.data.thinklets;

      _finishTutorialLoad = finish;

      return false;
    }
  });

  $scope.loadGlobalSettings = function () {
    return UserManager.getGlobalSettings().then(function (settings) {
      return settings.viewingThinklets.gallery;
    });
  };

  $scope.selectClass = UserManager.selectClass;
  $scope.loadUserThinklets = ThinkletManager.loadUserThinklets;

  function digest() {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  let helperIdleTimer, onBodyClick, removeBodyClickListener, removeCloseHelperListener;

  if (!_seenHelper) {
    helperIdleTimer = new HelperIdleTimer(15000);

    helperIdleTimer.setCallback(() => {
      helperStore.commit('SET_BUTTONS_TEXT', 'WAS_THIS_HELPFUL');
      helperStore.commit('SET_BUTTONS', {
        ['<span class="thumbs-up">Thumbs Up</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-down')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', { message: helperStore.state.message, response: true, title: helperStore.state.title });

          $scope.viewModel.isHelperVisible = false;

          digest();
        },
        ['<span class="thumbs-down">Thumbs Down</span>']: e => {
          const selected = e.target.classList.contains('selected');

          if (selected)
            return;

          document.getElementsByClassName('thumbs-up')[0].classList.remove('selected');
          e.target.classList.add('selected');

          LogManager.getShared().logBehaviorEvent('Animated Helper Response', { message: helperStore.state.message, response: false, title: helperStore.state.title });

          $scope.viewModel.isHelperVisible = false;

          digest();
        }
      });

      helperStore.commit('SET_MESSAGE', 'LOOK_AT_ALL_THE_CREATIVE_SOLUTIONS_WATCH_ONE_THAT_IS_DIFFERENT_FROM_YOURS');
      helperStore.commit('SET_TITLE', null);

      $scope.viewModel.isHelperVisible = true;

      digest();

      _seenHelper = true;

      removeBodyClickListener();
    });

    function closeHelper() {
      $scope.viewModel.isHelperVisible = false;

      digest();
    }

    $scope.$on('CloseHelper', function () {
      closeHelper();
    });

    removeBodyClickListener = () => {
      document.body.removeEventListener('click', onBodyClick);
    };

    removeCloseHelperListener = NotificationCenter.getShared().addListener('CloseHelper', () => {
      closeHelper();
    });

    onBodyClick = () => {
      if (!_seenHelper)
        helperIdleTimer.reset();
    };

    document.body.addEventListener('click', onBodyClick);
  }

  $scope.colorThemes = CueThinkConfig.colorThemes;
  $scope.teacherManager = TeacherManager;
  $scope.userManager = UserManager;

  $scope.isHelperEnabled = function () {
    return PermissionsManager.getPermission(Permissions.GalleryAnimatedHelper) && $scope.viewModel.isHelperVisible;
  };

  $scope.$on('$destroy', () => {
    TutorialService.setLoadCallback(null);

    if (helperIdleTimer) {
      helperIdleTimer.setCallback(null);
      helperIdleTimer.stop();
    }

    if (removeBodyClickListener)
      removeBodyClickListener();

    if (removeCloseHelperListener)
      removeCloseHelperListener();

    helperStore.commit('SET_BUTTONS', null);
    helperStore.commit('SET_BUTTONS_TEXT', null);
    helperStore.commit('SET_TITLE', null);
    helperStore.commit('SET_MESSAGE', null);
  });
}

export default BrowseController;
