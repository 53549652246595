import module from './login.module';
import CleverAuthentication from './clever';
import GoogleSignIn from './google-sign-in';
import './login.css';
import tpl from './loginDirective.tpl.html';
import _ from 'underscore';

module.directive('login', ['$timeout', function ($timeout) {
  "ngInject";

  var linkFunction = function ($scope) {
    if ($scope.user) {
      $scope.logInSuccess($scope.user);

      return;
    }

    $scope.viewModel = {
      loginForm: null,
      user: {},
      serverErrorMessage: '',
      rememberMe: false
    };

    $scope.pc_version = $scope.version;

    $scope.resetServerErrors = function() {
      $scope.viewModel.serverErrorMessage = '';
    };

    function digest () {
      if (!$scope.$$phase) {
        try {
          $scope.$digest();
        } catch  (e) {
        }
      }
    }

    $scope.logIn = function (user) {
      var isFormValid = $scope.viewModel.loginForm.$valid;

      if (isFormValid) {
        $('input').blur();

        var success = function (success, usr) {
          if (!success) {
            if (usr.passwordCode) {
              $scope.resetPassword({ code: usr.passwordCode });
            } else {
              $scope.viewModel.serverErrorMessage = usr;

              digest();
            }

            return;
          }

          if ($scope.viewModel.rememberMe) {
            if (typeof(Storage) !== 'undefined') {
              localStorage.setItem('remember_user', user.name);
              localStorage.setItem('remember_password', user.password);
            }
          } else {
            localStorage.removeItem('remember_user');
            localStorage.removeItem('remember_password');
          }

          $scope.logInSuccess(usr);
        };

        if ($scope.referral)
          user.referral = $scope.referral;

        var promise = $scope.login(user, success);

        if (promise) {
          promise.then(function (user) {
            success(true, user);
          }, function (message) {
            success(false, message);
          });
        }
      } else {
        _.forOwn($scope.viewModel.loginForm.$error, function(value, key) {
          _.each(value, function(element) {
            element.$setDirty();
          });
        } );
      }
    };

    if (typeof(Storage) !== 'undefined') {
      var name = localStorage.getItem('remember_user');

      if (name) {
        $scope.viewModel.user = {
          'name': name,
          'password': localStorage.getItem('remember_password')
        };

        $scope.viewModel.rememberMe = true;
      }
    }

    var _isMobile;
    new GoogleSignIn(function (user, isMobile, callback) {
      _isMobile = isMobile;

      return $scope.googleAuthenticate(user.token, isMobile, callback);
    }, function (user, success, message) {
      if (success)
        $scope.logInSuccess(user);
      else
        $scope.googleRegister(user, _isMobile);
    }, function (available, signIn) {
      if (available) {
        $scope.googleSignIn = signIn;

        digest();
      }
    }, true);

    $scope.cleverSignIn = function () {
      CueThinkLogin.cleverAuthenticate($scope.cleverAuthenticate, $scope.cleverRedirectUrl, $scope.cleverClientId, function (success, user) {
        if (success)
          $scope.logInSuccess(user);
        else
          $scope.cleverRegister(user);
      });
    };

    if ($scope.fetchMaintenance) {
      $scope.fetchMaintenance().then(function (message) {
        $scope.maintenanceMessage = message;
      });
    }

    $('<script>').attr({src: 'https://seal.thawte.com/getthawteseal?host_name=' + $scope.sslCommonName + '&size=M&lang=en'}).appendTo('.seals');
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    transclude: true,
    scope: {
      cleverAuthenticate: '=',
      cleverClientId: '=',
      cleverRegister: '=',
      cleverRedirectUrl: '=',
      fetchMaintenance: '=',
      forgotPassword: '&',
      forgotUserName: '&',
      googleAuthenticate: '=',
      googleClientId: '=',
      googleRegister: '=',
      login: '=',
      logInSuccess: '=success',
      logoPath: '@',
      name: '=',
      referral: '=',
      register: '=',
      resetPassword: '&',
      sslCommonName: '=',
      user: '=',
      version: '='
    }
  };
}]);

var CueThinkLogin = {};

CueThinkLogin.cleverAuthenticate = function (authenticate, redirectURL, clientID, callback) {
  var cleverAuthentication = new CleverAuthentication(authenticate, redirectURL, clientID);

  cleverAuthentication.authenticate(callback);
};

export {
  CueThinkLogin
}
