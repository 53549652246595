import RubricReflectionDirective from './rubricReflectionDirective';
import RubricDirective from './rubricDirective';
import RubricOverviewDirective from './rubricOverviewDirective';
import RubricStatsDirective from './rubricStatsDirective';
import RubricSummaryDirective from './rubricSummaryDirective';

angular.module('cuethink.rubric', ['cuethink.common', 'pascalprecht.translate'])
  .directive('rubricReflection', RubricReflectionDirective)
  .directive('rubric', RubricDirective)
  .directive('rubricoverview', RubricOverviewDirective)
  .directive('rubricstats', RubricStatsDirective)
  .directive('rubricsummary', RubricSummaryDirective);
