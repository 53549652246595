import CueThinkLoginController from '../loginController';
import 'src/components/shared/user_data';

function CleverController ($stateParams, $state, UserManager) {
		"ngInject";

	if ($stateParams.state == 'internal')
		return;

	if (!$stateParams.code) {
		$state.go('login');

		return;
	}

	UserManager.authenticateClever($stateParams.code, function (success, user, $stateParams) {
		CueThinkLoginController.cleverAuthenticateHandler(success, user, $state, $stateParams);
	});
}

export default CleverController;
