import './cuethink';
import CueThinkConfig from 'config';
import CueThinkLoginController from 'src/app/login/loginController';
import _ from 'underscore';
import 'angular-translate';
import NotificationCenter from 'core/notifications/notification-center';
import { getShared as getSharedConfigStore } from 'core/common/stores/config.store';
import RouteManager from 'core/common/route-manager';

angular.module('userManager', ['cuethink']).factory('UserManager', ['CueThink', '$q', '$state', '$stateParams', '$translate', function (cuethink, $q, $state, $stateParams, $translate) {
  "ngInject";

  return UserManager.getShared(cuethink, CueThinkConfig, $q, $state, $stateParams, $translate);
}]);

export default function UserManager (cuethink, config, $q, $state, $stateParams, $translate) {
  var _me = this;
  var _globalSettings;
  var _digitalPromiseToolsUsed = [];
  var configStore = getSharedConfigStore();

  function setUser (value) {
    cuethink.setUser(value);
  }

  _me.getUser = function () {
    return cuethink.getUser();
  };

  _me.isTeacherExpired = function () {
    if (typeof(Storage) !== 'undefined')
      return localStorage.getItem('fullAccess') == "false";

    return false;
  };

  _me.getUserTrialInfo = function (callback) {
    return cuethink.get(config.getUserTrialInfoURL, function (data) {
      if (callback)
        callback(data);
    });
  };

  _me.loadClasses = function (success, error) {
    return cuethink.get(config.teacherClassesURL, success, error);
  };

  _me.fetchCurrentUser = function (fullAccess, callback) {
    return cuethink.get(config.currentUserURL, function (user) {
      user.avatarUrl = user.avatar === '\0' ? null : user.avatar;
      user.firstName = user.first_name;
      user.lastName = user.last_name;
      user.colorTheme = user.theme === null ? 'DEFAULT' : user.theme;

      setUser(user);

      if (fullAccess !== undefined) {
        if (typeof(Storage) !== 'undefined') {
          localStorage.removeItem('fullAccess');
          localStorage.setItem('fullAccess', fullAccess);
        }
      }

      var promises = [
        _me.fetchAnnotationsEnabled()
      ];

      promises.push(_me.isEFAccount().then(function (result) {
        user.isEFAccount = result;
      }));

      promises.push(_me.isEFLiteAccount().then(function (result) {
        user.isEFLiteAccount = result;
      }));

      promises.push(_me.isDPAccount().then(function (result) {
        user.isDPAccount = result;
      }));

      promises.push(_me.isLZAccount().then(function (result) {
        user.isLZAccount = result;
      }));

      promises.push(_me.isOrigoAccount().then(function (result) {
        user.isOrigoAccount = result;
      }));

      if (user.type === 'Student') {
        promises.push(_me.fetchSelectedClass().then(function () {
          return _me.getGlobalSettings();
        }));
      } else {
        promises.push(_me.getGlobalSettings());

        promises.push(_me.loadClasses().then(function (result) {
          user.classes = result;

          if (result.length > 0)
            user.selectedClass = result[0];
        }));
      }

      return $q.all(promises).then(function () {
        if (user.hillsideIMFolder) {
          user.cueteachEnabled = true;
          user.isCuethinkV2Account = true;
        }

        setUser(user);

        if (callback)
          callback(true, user);

        return user;
      });
    });
  };

  _me.updateCurrentUser = function (callback) {
    return cuethink.get(config.currentUserURL, function (data) {
      var keys = Object.keys(data);
      var user = _me.getUser();

      for (var i = 0; i < keys.length; i++)
        user[keys[i]] = data[keys[i]];

      user.avatarUrl = user.avatar === '\0' ? null : user.avatar;
      user.colorTheme = user.theme ? user.theme : 'DEFAULT';

      setUser(user);

      if (callback)
        callback();
    });
  };

  _me.loginIntercom = function (){
    var user = _me.getUser();

    if (user === undefined || user.email === undefined) {
      cuethink.get(config.currentUserURL, function(user) {
        try {
          window.Intercom('boot', {
            user_id: user.id,
            name: user.first_name+" "+user.last_name,
            email: user.email,
            created_at: user.accountCreatedAt/1000,
            app_id: config.intercomAppId
          });
        } catch (e) {
        }
      });
    } else {
      try {
        window.Intercom('boot', {
          user_id: user.id,
          name: user.first_name+" "+user.last_name,
          email: user.email,
          created_at: user.accountCreatedAt/1000,
          app_id: config.intercomAppId
        });
      } catch (e) {
      }
    }
  };

  _me.logOutIntercom = function () {
    // _globalSettings = null;

    try {
      Intercom('shutdown');
    } catch (e) {
    }
  };

  function logInHandler (data, callback) {
    if (!data || data.authenticated) {
      if (typeof localStorage !== 'undefined')
        localStorage.removeItem('thinklets');

      var promise = _me.fetchCurrentUser(data ? data.fullAccess : true, function (a, b) {
        _me.getGlobalSettings().then(function () {
          if (callback)
            callback(a, b);
        });
      });

      NotificationCenter.getShared().post('LogIn');

      return promise;
    } else if (callback) {
      callback(false, data.passwordCode ? data : data.message);

      return Promise.reject(data.message);
    }
  }

  this.logInHandler = logInHandler;

  function oAuthLogInHandler (data, callback) {
    if (data.authenticated) {
      _me.fetchCurrentUser(data.fullAccess, function (a, b) {
        _me.getGlobalSettings().then(function () {
          if (callback)
            callback(a, b);
        });
      });
    } else if (callback) {
      callback(false, data.user ? data.user : data.message);
    }
  }

  _me.logIn = function (user, callback) {
    deleteAllCookies();

    var url = config.logInURL;

    if (user.referral) {
      url += '?referral=' + user.referral;

      delete user.referral;
    }

    cuethink.post(url, { 'j_username': user.name, 'j_password': user.password }, function (data) {
      logInHandler(data, callback);
    });

    return undefined;
  };

  _me.linkAndLogIn = function (user, callback) {
    deleteAllCookies();

    cuethink.post(config.logInURL, { 'j_username': user.name, 'j_password': user.password, 'email': user.email }, function (data) {
      logInHandler(data, callback);
    });

    return undefined;
  };

  _me.oAuthLogIn = function (id_token, isToken, referral, callback) {
    deleteAllCookies();

    cuethink.post(config.oAuthLogInURL, { 'id_token': id_token, fromIOS: !!isToken, referral: referral }, function (data) {
      oAuthLogInHandler(data, callback);
    });

    return undefined;
  };

  _me.registerUser = function (user, callback, errorHandler) {
    if (user.type == 103)
      cuethink.postjson(config.registerURL, user, callback, errorHandler);
    else
      cuethink.postjson(config.tregisterURL, user, callback, errorHandler);
  };

  _me.fetchClasses = function (callback) {
    cuethink.get(config.classesURL, function(data) {
      if (callback)
        callback(data);
    });
  };

  _me.fetchStudents = function (callback) {
    cuethink.get(config.studentsURL, callback);
  };

  function deleteAllCookies () {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  _me.logOut = function (callback) {
    deleteAllCookies();

    _globalSettings = null;

    _digitalPromiseToolsUsed = [];

    setUser(null);

    if (typeof(Storage) !== 'undefined') {
      localStorage.removeItem('trialWarning');
      localStorage.removeItem('fullAccess');
      localStorage.removeItem('thinklets');
      localStorage.removeItem('view-thinkletid');
    }

    cuethink.get(config.logOutURL, function (result) {
      if (callback)
        callback(result);

      deleteAllCookies();
    });

    NotificationCenter.getShared().post('LogOut');
  };

  _me.changeUserPassword = function (currentPassword, newPassword, newPasswordRetype) {
    return cuethink.postjson(config.changeUserPasswordURL, { currentPassword: currentPassword, newPassword: newPassword, newPasswordRetype: newPasswordRetype });
  };

  _me.resetPassword = function (password, code, isTeacherChange, callback, error) {
    var d = $q.defer();

    cuethink.post(config.resetPasswordURL, { newPassword: password, code: code, isTeacherChange: isTeacherChange ? 1 : 0 }, function (result) {
      d.resolve(result);
    }, function (result) {
      d.reject(result);

      if (error)
        error(result);
      else
        alert($translate.instant(result));
    });

    return d.promise;
  };

  _me.forgotUserPassword = function (email, callback) {
    return cuethink.post(config.forgotUserPasswordURL, email, function(data){
      if(callback)
        callback(data);
    });
  };

  _me.resetPasswordByUsername = function (username) {
    return cuethink.post(config.resetPasswordByUsernameURL, username);
  };

  _me.forgotUserName = function (email, callback, error) {
    var d = $q.defer();

    cuethink.post(config.forgotUserNameURL + '?email=' + encodeURIComponent(email), null, function (result) {
      d.resolve(result);
    }, function (result) {
      d.reject(result);

      if (error)
        error($translate.instant(result));
      else
        alert($translate.instant(result));
    });

    return d.promise;
  };

  _me.authStatus = function (callback) {
    cuethink.get(config.authStatusURL, function(data){
      if (callback)
        callback(data.authenticated);
    });
  };

  _me.deleteCurrentUser = function (callback) {
    cuethink.delete(config.deleteCurrentUserURL, function(data){
      if (callback)
        callback(data);

      _me.logOut();
    });
  };

  _me.getTrialDaysLeft = function (callback) {
    cuethink.get(config.getTrialDaysLeftURL, function(data){
      if (callback)
        callback(data);
    });
  };

  _me.isTeacherUsernameAvailable = function (username) {
    var url = config.checkTeacherUserNameURL.replace('{0}', encodeURIComponent(username));

    return cuethink.get(url);
  };

  _me.isEmailAvailable = function (username, callback) {
    var url = config.checkEmailURL.replace('{0}', encodeURIComponent(username));

    return cuethink.get(url, function(data) {
      if (callback)
        callback(data);
    });
  };

  _me.authenticateClever = function (authCode, callback, isToken, referral) {
    var data = {
      auth_code: authCode
    };

    if (isToken)
      data.fromIOS = true;

    if (referral)
      data.referral = referral;

    cuethink.post(config.cleverAuthenticateURL, data, function (data) {
      oAuthLogInHandler(data, callback);
    });
  };

  _me.linkClever = function (user, authCode, callback, isToken) {
    deleteAllCookies();

    cuethink.post(config.logInURL, { 'j_username': user.name, 'j_password': user.password, cleverAuthCode: authCode, fromIOS: !!isToken }, function (data) {
      logInHandler(data, callback);
    });
  };

  function getFlagURL (key) {
    return config.flagURL.replace('{0}', key);
  }

  _me.saveFlag = function (key) {
    return cuethink.postjson(getFlagURL(key));
  };

  _me.fetchFlag = function (key) {
    return cuethink.getjson(getFlagURL(key));
  };

  _me.getIdleFlagURL = function () {
    return getFlagURL('2017_August_new_student');
  };

  _me.fetchHasBeenIdle = function (success, error) {
    return cuethink.getjson(_me.getIdleFlagURL(), null, function (result) {
      if (result) {
        success(false);
      } else {
        _me.setNotIdleFlag();

        success(true);
      }
    }, error);
  };

  _me.setNotIdleFlag = function () {
    return cuethink.postjson(_me.getIdleFlagURL());
  };

  _me.fetchHasSeenViewBankPrompt = function (success, error) {
    var key = 'HasSeenViewBankPrompt';
    var url = getFlagURL(key);

    return cuethink.getjson(url, null, function (result) {
      if (result) {
        success(true);
      } else {
        cuethink.postjson(url);

        success(false);
      }
    }, error);
  };

  _me.changeClass = function (code) {
    return cuethink.postjson(config.changeClassURL, code).then(function () {
      return _me.fetchCurrentUser();
    });
  };

  _me.joinClass = function (code, success, error) {
    var d = $q.defer();

    cuethink.postjson(config.joinClassURL, code, cls => {
      var callback = function () {
        _me.updateCurrentUser(() => {
          if (success)
            success(cls);

          d.resolve(cls);
        });
      };

      if (!_me.getUser() || _me.getUser().selectedClass)
        callback();
      else
        _me.selectClass(cls, callback, error);
    }, e => {
      alert(e);

      if (error)
        error(e);

      d.reject(e);
    });

    return d.promise;
  };

  _me.leaveClass = function (cls, success, error) {
    var d = $q.defer();

    cuethink.postjson(config.leaveClassURL, cls.id, function () {
      var user = _me.getUser();

      _.each(user.classes, function (c, i) {
        if (c.id == cls.id) {
          user.classes.splice(i, 1);

          return false;
        }
      });

      if (cls.id == user.selectedClass.id) {
        if (user.classes.length > 0)
          user.selectedClass = user.classes[0];
        else
          user.selectedClass = null;
      }

      setUser(user);

      d.resolve();
    }, error);

    return d.promise;
  };

  _me.selectClass = function (cls, success, error) {
    _globalSettings = null;

    var user = _me.getUser();
    user.selectedClass = cls;

    user.classes.forEach(function (c) {
      c.selected = cls.id === c.id;
    });

    setUser(user);

    if (user.type === 'Student') {
      return cuethink.postjson(config.selectClassURL, cls.id, success, error);
    } else {
      return Promise.resolve();
    }
  };

  _me.fetchSelectedClass = function (success, error) {
    var d = $q.defer();

    cuethink.getjson(config.selectedClassURL, null, function (classId) {
      var result;
      var user = _me.getUser();

      if (classId) {
        if (user.classes) {
          for (var i = 0; i < user.classes.length; i++) {
            if (classId == user.classes[i].id) {
              result = user.selectedClass = user.classes[i];

              break;
            }
          }
        }
      } else if (user.classes) {
        result = user.selectedClass = user.classes[0];
      }

      if (success)
        success(result);

      d.resolve(result);
    }, function () {
      var user = _me.getUser();

      if (user.classes)
        user.selectedClass = user.classes[0];

      if (success)
        success(user.selectedClass);

      d.resolve(user.selectedClass);
    });

    return d.promise;
  };

  _me.fetchAnnotationsEnabled = function (success, error) {
    var d = $q.defer();

    cuethink.getjson(config.userFlagURL, { flag: 'annotationDisabled' }, function (data) {
      _me.getUser().annotationDisabled = !!data;

      var result = !_me.getUser().annotationDisabled;

      if (success)
        success(result);

      d.resolve(result);
    }, error);

    return d.promise;
  };

  _me.saveUserSettings = function (settings, callback, error) {
    var d = $q.defer();

    settings = Object.assign({}, settings);

    if (settings.avatar === null)
      settings.avatar = '\0';

    cuethink.putjson(config.teacherSettingsURL, settings, function (success) {
      if (success) {
        if (callback)
          callback(success);

        _me.updateCurrentUser().then(function () {
          _me.setTemporaryAvatarUrl(undefined);

          d.resolve();
        });
      } else {
        if (error)
          error();

        d.reject();
      }
    }, function (result) {
      d.reject(result);

      if (error)
        error(result);
    });

    return d.promise;
  };

  _me.loadGlobalSettings = function () {
    var deferred = $q.defer();

    setTimeout(function () {
      var url = CueThinkConfig.globalSettingsURL;

      if (_me.getUser() && _me.getUser().type === 'Student') {
        var cls = _me.getUser().selectedClass;

        if (cls)
          url += '/' + cls.id;
      }

      cuethink.getjson(url).then(function (result) {
        if (!result.settings) {
          deferred.resolve(null);

          return;
        }

        try {
          result = JSON.parse(result.settings);

          if (!result.creatingThinklets.solve.tools.grid)
            result.creatingThinklets.solve.tools.grid = Object.assign({}, CueThinkConfig.defaultSettings.creatingThinklets.solve.tools.grid);

          if (!result.viewingThinklets.gallery.thinkletPrivacySetting)
            result.viewingThinklets.gallery.thinkletPrivacySetting = CueThinkConfig.defaultSettings.viewingThinklets.gallery.thinkletPrivacySetting;

          if (!result.creatingThinklets.solve.tools.unifixCube)
            result.creatingThinklets.solve.tools.unifixCube = Object.assign({}, CueThinkConfig.defaultSettings.creatingThinklets.solve.tools.unifixCube);

          if (!result.creatingThinklets.solve.tools.protractor)
            result.creatingThinklets.solve.tools.protractor = Object.assign({}, CueThinkConfig.defaultSettings.creatingThinklets.solve.tools.protractor);

          if (!result.creatingThinklets.solve.tools.algebraTile)
            result.creatingThinklets.solve.tools.algebraTile = Object.assign({}, CueThinkConfig.defaultSettings.creatingThinklets.solve.tools.algebraTile);

          if (!result.creatingThinklets.solve.tools.fractionBar)
            result.creatingThinklets.solve.tools.fractionBar = Object.assign({}, CueThinkConfig.defaultSettings.creatingThinklets.solve.tools.fractionBar);

          if (!result.creatingThinklets.solve.toolOrder)
            result.creatingThinklets.solve.toolOrder = CueThinkConfig.defaultSettings.creatingThinklets.solve.toolOrder.slice(0);

          deferred.resolve(result);
        } catch (e) {
          deferred.resolve(null);
        }
      });
    }, 1000);

    return deferred.promise;
  };

  _me.saveGlobalSettings = function (settings) {
    _globalSettings = settings;

    return cuethink.postjson(CueThinkConfig.globalSettingsURL, { userId: _me.getUser().id, settings: JSON.stringify(settings) });
  };

  _me.getGlobalSettings = function () {
    var deferred;

    if (_globalSettings) {
      deferred = $q.defer();

      deferred.resolve(_globalSettings);

      return deferred.promise;
    } else {
      var user = _me.getUser();

      if (user && (user.selectedClass || user.type !== 'Student')) {
        return _me.loadGlobalSettings().then(function (settings) {
          if (settings) {
            // settings.creatingThinklets.plan.strategies = _.clone(CueThinkConfig.defaultSettings.creatingThinklets.plan.strategies);
          } else {
            settings = _.clone(CueThinkConfig.defaultSettings);
          }

          _globalSettings = settings;

          return settings;
        });
      } else {
        deferred = $q.defer();

        deferred.resolve(_.clone(CueThinkConfig.defaultSettings));

        return deferred.promise;
      }
    }
  };

  _me.handleLogInSuccess = function (user) {
    CueThinkLoginController.logInSuccessHandler(user, $state, $stateParams);

    if (cuethink.appVersion)
      cuethink.logEvent('App Version', null, null, null, cuethink.appVersion);
  };

  _me.cleverRegister = function (user) {
    var userName = user.username;

    if (!userName)
      userName = user.userName;

    if (!userName && user.credentials)
      userName = user.credentials.district_username;

    var params = {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      school: user.schoolName,
      grade: user.grade,
      type: user.type,
      username: userName,
      token: user.token,
      is_mobile: user.isMobile
    };

    $state.go('cleversignup', params);
  };

  _me.authenticateDiscourse = function (sso, sig) {
    return cuethink.post(config.discourseAuthenticateURL, { sso: sso, sig: sig });
  };

  _me.loadNearbySchools = function (state, zipCode) {
    return cuethink.getjson(CueThinkConfig.nearbySchoolsURL + '?state=' + state + '&zip=' + zipCode).then(function (results) {
      if (!results || !Array.isArray(results))
        return [];

      return results.map(function (school) {
        return {
          id: school.gsId,
          name: school.name,
          district: school.district,
          districtId: school.districtId,
          type: school.type
        };
      });
    });
  };

  _me.getSeenReadyToExploreURL = function () {
    return getFlagURL('Ready_To_Explore');
  };

  _me.fetchSeenReadyToExplore = function () {
    return cuethink.getjson(_me.getSeenReadyToExploreURL());
  };

  _me.setSeenReadyToExplore = function () {
    return cuethink.postjson(_me.getSeenReadyToExploreURL());
  };

  _me.getSeenEraserTutorialURL = function () {
    return getFlagURL('SeenEraserTutorial');
  };

  _me.fetchSeenEraserTutorial = function () {
    return cuethink.getjson(_me.getSeenEraserTutorialURL()).then(function (result) {
      if (result) {
        return true;
      } else {
        _me.setSeenEraserTutorial();


        return false;
      }
    });
  };

  _me.setSeenEraserTutorial = function () {
    return cuethink.postjson(_me.getSeenEraserTutorialURL());
  };

  _me.getSeenMenuPopoverURL = function () {
    return getFlagURL('SeenMenuPopover');
  };

  _me.fetchSeenMenuPopover = function () {
    return cuethink.getjson(_me.getSeenMenuPopoverURL()).then(function (result) {
      if (result) {
        return true;
      } else {
        _me.setSeenMenuPopover();

        return false;
      }
    });
  };

  _me.setSeenMenuPopover = function () {
    return cuethink.postjson(_me.getSeenMenuPopoverURL());
  };

  _me.getSeenLanguagePopUpURL = function () {
    return getFlagURL('SeenLanguagePopUp');
  };

  _me.setSeenLanguagePopUp = function () {
    return cuethink.postjson(_me.getSeenLanguagePopUpURL());
  };

  _me.fetchHasSeenLanguagePopUp = function () {
    return cuethink.getjson(_me.getSeenLanguagePopUpURL()).then(function (result) {
      if (result) {
        return true;
      } else {
        _me.setSeenLanguagePopUp();

        return false;
      }
    });
  };

  _me.getSeenProblemBankLanguagePopUpURL = function () {
    return getFlagURL('SeenProblemBankLanguagePopUp');
  };

  _me.setSeenProblemBankLanguagePopUp = function () {
    return cuethink.postjson(_me.getSeenProblemBankLanguagePopUpURL());
  };

  _me.fetchHasSeenProblemBankLanguagePopUp = function () {
    return cuethink.getjson(_me.getSeenProblemBankLanguagePopUpURL()).then(function (result) {
      if (result) {
        return true;
      } else {
        _me.setSeenProblemBankLanguagePopUp();

        return false;
      }
    });
  };

  _me.isEFAccount = function () {
    var url;

    if (_me.getUser().type === 'Student')
      url = config.isStudentEFAccountURL;
    else
      url = config.isEFAccountURL;

    return cuethink.getjson(url).then(function (result) {
      return result.isEFAccount;
    });
  };

  _me.isEFLiteAccount = function () {
    return cuethink.getjson(config.isEFLiteAccountURL).then(function (result) {
      return result.isEFLiteAccount;
    });
  };

  _me.isDPAccount = function () {
    var url;

    if (_me.getUser().type === 'Student')
      url = config.isDPStudentAccountURL;
    else
      url = config.isDPTeacherAccountURL;

    return cuethink.getjson(url).then(function (result) {
      return result.isDPAccount;
    });
  };

  _me.isLZAccount = function () {
    return cuethink.getjson(config.isLZAccountURL).then(function (result) {
      return result.isLZAccount;
    });
  };

  _me.isOrigoAccount = function () {
    return cuethink.getjson(config.isOrigoAccountURL).then(function (result) {
      return result.isOrigoAccount;
    });
  };

  _me.isCleverAuthenticated = function () {
    return cuethink.getjson(config.cleverPresenceURL, null, null, null, { 'X-Client-ID': CueThinkConfig.cleverClientID }).then(function (result) {
      return result.authenticated;
    });
  };

  _me.submitBeliefsProbe = function (thinkletId, problemId, problemName, slider, videoWatched) {
    const data = {
      slider,
      username: _me.getUser().username,
      videoWatched
    };

    return cuethink.logThinkletEvent('Beliefs Probe', RouteManager.getSharedInstance().getCurrentRoute().name, problemId, problemName, thinkletId, data);
  };

  _me.submitMindWanderingProbe = function (options) {
    var data = {
      choiceMade: options.answer,
      probeLocation: $state.current.name,
      problemId: options.problemId,
      problemName: options.problemName,
      thinkletId: options.thinkletId,
      videoWatched: options.videoWatched
    };

    return cuethink.postjson(config.submitMindWanderingProbeURL, data);
  };

  _me.getTemporaryAvatarUrl = function () {
    return configStore.state.temporaryAvatarUrl;
  };

  _me.setTemporaryAvatarUrl = function (url) {
    configStore.commit('SET_TEMPORARY_AVATAR_URL', url);
  };

  _me.getSeenThreeDotsPopUpURL = function () {
    return getFlagURL('SeenThreeDotsPopUp');
  };

  _me.setSeenThreeDotsPopUp = function () {
    return cuethink.postjson(_me.getSeenThreeDotsPopUpURL());
  };

  _me.getSeenThreeDotsPopUp = function () {
    return cuethink.getjson(_me.getSeenThreeDotsPopUpURL()).then(function (result) {
      return result;
    });
  };

  var _chatStatus;

  _me.getChatStatus = function () {
    if (typeof localStorage === 'undefined') {
      _chatStatus = localStorage.getItem('chatStatus');
    }

    if (!_chatStatus) {
      _chatStatus = 'chat';
    }

    // TODO: hardcode for now
    _chatStatus = 'chat';

    var deferred = $q.defer();

    deferred.resolve(_chatStatus);

    return deferred.promise;
  };

  _me.setChatStatus = function (status) {
    _chatStatus = status;

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('chatStatus', _chatStatus);
    }

    var deferred = $q.defer();

    deferred.resolve();

    return deferred.promise;
  };

  _me.loadClassUsers = function (classId) {
    return cuethink.getjson(config.classStudentsURL.replace('{0}', classId)).then(function (users) {
      users.forEach(function (user) {
        user.avatarUrl = user.avatarType === '\0' ? null : user.avatarType;
        user.colorTheme = user.theme === null ? 'DEFAULT' : user.theme;
      });

      return users;
    });
  };

  _me.authenticateWithToken = function (token) {
    return cuethink.post(config.tokenAuthURL, { token: token }).then(function (response) {
      return logInHandler(response);
    });
  };

  _me.saveDigitalPromiseProbeResponse = function (response) {
    return cuethink.postjson(config.saveDigitalPromiseProbeURL, { probeLocation: 'logout', probeData: JSON.stringify(response) });
  };

  _me.getDigitalPromiseToolsUsed = function () {
    return _digitalPromiseToolsUsed;
  };

  _me.addDigitalPromiseToolUsed = function (tool) {
    if (_digitalPromiseToolsUsed.indexOf(tool) === -1)
      _digitalPromiseToolsUsed.push(tool);
  };

  _me.loadChatUsers = function (name) {
    return cuethink.getjson(config.chatUsersURL.replace('{0}', name)).then(result => result.participants || []);
  };
}

(function() {
  var shared;

  UserManager.getShared = function (cuethink, config, $q, $state, $stateParams, $translate) {
    if (!shared)
      shared = new UserManager(cuethink, config, $q, $state, $stateParams, $translate);

    return shared;
  };
})();
