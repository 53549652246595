import Mousetrap from 'mousetrap';
import RouteManager from 'core/common/route-manager';

export function bindKeyboardShortcut(letter, callback) {
  const keys = ['command+alt+' + letter, 'ctrl+alt+' + letter];

  Mousetrap.bind(keys, callback);

  return () => {
    Mousetrap.unbind(keys, callback);
  };
}

// navigation

bindKeyboardShortcut('d', () => {
  RouteManager.getSharedInstance().route('dashboard');
});

bindKeyboardShortcut('a', () => {
  RouteManager.getSharedInstance().route('assignments');
});

bindKeyboardShortcut('p', () => {
  RouteManager.getSharedInstance().route('profile');
});

bindKeyboardShortcut('g', () => {
  RouteManager.getSharedInstance().route('browse');
});

bindKeyboardShortcut('s', () => {
  RouteManager.getSharedInstance().route('student-settings');
});

bindKeyboardShortcut('u', () => {
  if (localStorage.getItem('thinkletId'))
    RouteManager.getSharedInstance().route('understand');
});

bindKeyboardShortcut('e', () => {
  if (localStorage.getItem('thinkletId'))
    RouteManager.getSharedInstance().route('understand');
});

bindKeyboardShortcut('l', () => {
  if (localStorage.getItem('thinkletId'))
    RouteManager.getSharedInstance().route('plan');
});

bindKeyboardShortcut('o', () => {
  if (localStorage.getItem('thinkletId'))
    RouteManager.getSharedInstance().route('solve');
});

bindKeyboardShortcut('r', () => {
  if (localStorage.getItem('thinkletId'))
    RouteManager.getSharedInstance().route('review');
});
