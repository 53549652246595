export default function SpeedTester (rateCallback) {
  var _firstUpdateTime;
  var _lastUpdateTime;
  var _lastBytes;

  this.update = function (bytes) {
    var time = new Date().getTime();

    if (_lastUpdateTime && typeof _lastBytes === 'number') {
      var rate = (bytes - _lastBytes) / (time - _lastUpdateTime);

      rateCallback(rate, time - _firstUpdateTime);
    } else {
      _firstUpdateTime = time;
    }

    _lastUpdateTime = time;
    _lastBytes = bytes;
  };

  this.reset = function () {
    _lastUpdateTime = null;
    _lastBytes = null;
  };
}
