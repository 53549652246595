import module from '../login.module';
import '../loginDirective';
import './forgotpassword.css';
import tpl from './forgotPasswordDirective.tpl.html';
import _ from 'underscore';
import 'angular-translate';

module.directive('forgotpassword', function ($translate) {
	var linkFunction = function ($scope) {
		$scope.forgotPassword = function () {
			if ($scope.username) {
				$scope.isSubmitting = true;

				var success = function (data) {
					$translate('IT_LOOKS_LIKE_WE_DO_NOT_HAVE_YOUR_EMAIL_ADDRESS').then(function (msg) {
						if (data.validEmail)
							alert($translate.instant(data.message));
						else
							alert(msg);

						$scope.cancel();
					});
				};

				var error = function () {
					$scope.isSubmitting = false;
				};

				var promise = $scope.resetPassword($scope.username, success, error);

				if (promise)
					promise.then(success).catch(error);
			} else {
				_.forOwn($scope.formData.resetUsernameForm.$error, function(value, key) {
					_.each(value, function (element) {
						element.$setDirty();
					});
				});
			}
		};
	};

	return {
		restrict: 'E',
		template: tpl,
		link: linkFunction,
		scope: {
			name: '=',
			logoPath: '@',
			resetPassword: '=',
			cancel: '=',
      forgotUserName: '&'
		}
	};
});
