import 'src/components/shared/base';
import 'src/components/shared/teacher_data';
import 'core/question/question';
import 'core/common/autosave';
import 'core/whiteboard/whiteboardscrap.module';
import 'src/components/shared/cuethink';
import 'src/components/shared/media';
import 'src/components/shared/thinkletloader';
import './create.css';
import 'angular-translate';
import 'src/images/CenterDashboardButton.svg';
import 'src/images/JournalBackArrow.svg';
import {
  getLocalizedProblemQuestionImage,
  getLocalizedThinkletQuestion,
  getLocalizedThinkletQuestionImage,
  translate
} from 'core/common/translation-helpers';
import understandIconUrl from 'src/images/magnifyingglass.svg';
import planIconUrl from 'src/images/planicon.svg';
import solveIconUrl from 'src/images/solveicon.svg';
import reviewIconUrl from 'src/images/Review-icon.svg';
import 'src/images/DropDownArrow_Dark.svg';
import 'src/images/Prev_Arrow.svg';
import 'src/images/Next_Arrow.svg';
import 'core/common/cuethinkPopUpDirective';
import 'core/common/commonModule';
import 'src/components/shared/user_data';
import FacilitatorGuideDirective from 'core/facilitatorGuide/facilitatorGuideDirective';
import 'src/app/config.provider';
import 'src/components/shared/create/facilitatorGuide.partial.html';
import NotificationCenter from 'core/notifications/notification-center';
import {SpeechToText} from 'core/speech/speech';
import 'src/images/Chat_Icon.svg';
import StudentChatDirective from 'src/app/chat/studentChatDirective';
import 'core/helper/helper.directive';
import helperStore from 'core/helper/helper.store';
import html2canvas from 'html2canvas';
import MediaManager from '../media';
import HelperIdleTimer from '../helper-idle-timer';
import 'src/app/beliefs-probe/beliefs-probe.directive';
import setUpFacilitatorGuide from 'core/facilitatorGuide/facilitatorGuideSetup';
import 'src/images/Dropdown_ChatArrow_BlackDown.svg';
import 'src/images/Dropdown_ChatArrow_Black_Up.svg';
import permissionsManager, { Permissions } from 'core/permissions/permissions';

require('src/components/navigation/create_navbar.partial.html');

var _lastThinkletId;

const _beliefsProbeSeen = {};

NotificationCenter.getShared().addListener('LogOut', () => {
  const keys = Object.keys(_beliefsProbeSeen);

  keys.forEach(key => delete _beliefsProbeSeen[key]);
});

angular.module('createController', ['baseController', 'thinkletManager', 'question', 'autosave', 'whiteboardscrap', 'cuethink', 'mediaManager', 'thinkletLoader', 'cuethink.common', 'pascalprecht.translate'])
  .controller('CreateController', ['$scope', '$controller', '$stateParams', 'ThinkletManager', '$timeout', 'thinkletLoadCallback', 'CueThink', 'MediaManager', '$state', '$translate', '$rootScope', 'UserManager', 'TeacherManager', 'CueThinkConfig', '$transitions', 'PermissionsManager', function ($scope, $controller, $stateParams, $thinkletManager, $timeout, thinkletLoadCallback, $cuethink, mediaManager, $state, $translate, $rootScope, userManager, TeacherManager, CueThinkConfig, $transitions, PermissionsManager) {
  "ngInject";

  var _isSavingScrapPaper;
  var _attemptedLanguageSwitch, _forceQuestionProcess;
  var speechToText;

  var baseController = $controller('BaseController', { '$scope': $scope });

  if (!$scope.viewModel)
    $scope.viewModel = {};

  $scope.viewModel.isBeliefsProbeVisible = false;

  $scope.facilitatorGuideBackgroundColor = null;
  $scope.facilitatorGuideNotes = null;
  $scope.facilitatorGuideShowForAllClasses = false;
  $scope.hideHelperOnPopoverClose = true;
  $scope.isFacilitatorGuideVisible = false;
  $scope.isLanguagePopUpVisible = false;

  speechToText = new SpeechToText();

  $scope.speechToText = speechToText;

  speechToText.setCallback(function (text) {
    NotificationCenter.getShared().post('SpeechToText', text);
  });

	function digest () {
		if ($scope.$$phase)
			return;

		try {
			$scope.$digest();
		} catch (e) {
		}
	}

	var thinkletId = $stateParams.id;

	if (typeof localStorage !== 'undefined') {
	  if (thinkletId)
	    localStorage.setItem('thinkletId', thinkletId);
	  else
      thinkletId = localStorage.getItem('thinkletId');
  }

	if (!thinkletId) {
    $state.go('dashboard');

    return;
  }

	$scope.thinklet = $thinkletManager.getCachedThinklet(thinkletId);

	if ($scope.thinklet && $scope.thinklet.id != -1 && $scope.thinklet.user.id !== $scope.user.id) {
	  $state.go('dashboard');

	  return;
  }

  if (_lastThinkletId !== thinkletId)
    NotificationCenter.getShared().post('LoadingNewThinklet');

  _lastThinkletId = thinkletId;

  function saveScrapPaper (whiteboard, events) {
    if (_isSavingScrapPaper || $scope.thinklet.id < 0)
      return;

    _isSavingScrapPaper = true;

    mediaManager.uploadScrapPaper($scope.thinklet, {
      ratio: whiteboard.getWidth() / whiteboard.getHeight(),
      events: events
    }, function () {
      _isSavingScrapPaper = false;
    }, function (error) {
      _isSavingScrapPaper = false;

      alert($translate.instant(error));
    });
  }

  function loadScrapPaper () {
    if ($scope.thinklet.scrapPaperURLs && $scope.thinklet.scrapPaperURLs.length > 0) { // TODO: support images
      $cuethink.get($scope.thinklet.scrapPaperURLs[0], function (data) {
        if ((typeof data) != 'object')
          return;

        $scope.scrapPaperEvents = data.events;
        $scope.ratio = data.ratio;

        digest();
      }, false);
    }
  }

  function renderNewlinesInHtml (text) {
    return text.replace(/\n/g, '<br />');
  }

  function processQuestion () {
    var question = $('.question-text');

    if (!_forceQuestionProcess && $scope.thinklet.annotatedQuestion) {
      $scope.annotatedQuestion = $scope.thinklet.annotatedQuestion;
    } else {
      question.hide();

      $scope.questionText = getLocalizedThinkletQuestion($translate, $scope.thinklet);

      var renderMath = function () {
        if (typeof MathJax == 'undefined') {
          setTimeout(renderMath, 1000);

          return;
        }

        MathJax.Hub.Queue(["Typeset", MathJax.Hub, $('question')[0]]);

        question.show();
      };

      $timeout(renderMath, 0);

      digest();
    }

    $scope.questionImages = getLocalizedProblemQuestionImage($translate, $scope.thinklet.problem);
  }

  $scope.isUnderstand = function () {
    return $state.current.name === 'understand';
  };

  $scope.isPlan = function () {
    return $state.current.name === 'plan';
  };

  $scope.isSolve = function () {
    return $state.current.name === 'solve';
  };

  $scope.isReview = function () {
    return $state.current.name === 'review';
  };

  $scope.getStepName = function () {
    if ($scope.isUnderstand() && $scope.isNewDesign())
      return 'explore';
    else
      return $state.current.name;
  };

  function processThinklet () {
    processQuestion();

    if ($scope.thinklet.plan)
      $scope.formattedPlan = renderNewlinesInHtml($scope.thinklet.plan);

    loadScrapPaper();

    if ($scope.user.type !== 'Student' && $state.current.name !== 'four-phase-menu' && $scope.isNewDesign())
      setUpFacilitatorGuide($scope, TeacherManager, userManager, $scope.getStepName(), $scope.thinklet, $scope.user, CueThinkConfig.colorThemes, true);
  }

  if ($scope.thinklet) {
    processThinklet();

    if (thinkletLoadCallback)
      thinkletLoadCallback($scope.thinklet);
  } else {
    $scope.thinklet = { id: thinkletId };

    $thinkletManager.loadThinklet(thinkletId, function (thinklet) {
      if (thinklet.user.id !== $scope.user.id) {
        $state.go('dashboard');

        return;
      }

      $scope.thinklet = thinklet;

      processThinklet();

      digest();

      $thinkletManager.cacheThinklet(thinklet);

      if (thinkletLoadCallback)
        thinkletLoadCallback(thinklet);
    }, false, true);
  }

  $scope.onKeyDown = function ($event) {
    if ($event.keyCode === 13)
      $($event.target).blur();
  };

  var _saveQueue = [], _isSaving;

  $scope.resetHelperIdleTimer = function() {
    HelperIdleTimer.getSharedInstance().reset();
  };

  $scope.save = function (data) {
    HelperIdleTimer.getSharedInstance().reset();

    if (!$scope.thinklet || $scope.thinklet.id < 0)
      return;

    if (_isSaving) {
      _saveQueue.push(data);

      return;
    }

    _isSaving = true;

    var result = $thinkletManager.saveThinklet($scope.thinklet.id, data, $scope.thinklet, function () {
      $thinkletManager.cacheThinklet($scope.thinklet);

      _isSaving = false;

      if (_saveQueue.length > 0) {
        var item = _saveQueue.shift();

        $scope.save(item);
      }
    }, function (error) {
      _isSaving = false;

      if (_saveQueue.length > 0) {
        var item = _saveQueue.shift();

        $scope.save(item);
      }

      alert($translate.instant(error));
    });

    // $scope.saveScreenshot();

    if (($scope.isEF23User())
      && $state.current.name === 'plan'
      && (!_beliefsProbeSeen[$scope.user.id] || !_beliefsProbeSeen[$scope.user.id].plan)) {
      if (!_beliefsProbeSeen[$scope.user.id])
        _beliefsProbeSeen[$scope.user.id] = {};

      _beliefsProbeSeen[$scope.user.id]['plan'] = false; // mark it to be shown
    }

    return result;
  };

  $scope.onQuestionChange = function (html) {
    $scope.thinklet.annotatedQuestion = html;

    $scope.save({annotatedQuestion: html});
  };

  var _popUpImageUrls;

  $scope.selectImage = function (imageUrls) {
    _popUpImageUrls = imageUrls;
    $scope.popUpImageURL = imageUrls[0];
  };

  $scope.onClickPreviousImage = function ($event) {
    var i = _popUpImageUrls.indexOf($scope.popUpImageURL);

    if (i > 0)
      i--;

    $scope.popUpImageURL = _popUpImageUrls[i];

    $event.stopPropagation();
    $event.preventDefault();
  };

  $scope.onClickNextImage = function ($event) {
    var i = _popUpImageUrls.indexOf($scope.popUpImageURL);

    if (i < (_popUpImageUrls.length - 1))
      i++;

    $scope.popUpImageURL = _popUpImageUrls[i];

    $event.stopPropagation();
    $event.preventDefault();
  };

  $scope.closeImage = function ($event) {
    _popUpImageUrls = null;
    $scope.popUpImageURL = null;

    $event.stopPropagation();
    $event.preventDefault();
  };

  $scope.showScrapPaper = function () {
    if (!$scope.thinklet)
      return;

    $scope.isScrapPaperVisible = true;
    $scope.saveScrapPaper = saveScrapPaper; // begin autosaving
  };

  $scope.closeScrapPaper = function () {
    $scope.isScrapPaperVisible = false;
  };

  this.loadThinkletRecording = baseController.loadThinkletRecording;

  this.addLeaveListener = baseController.addLeaveListener;

  var isPaused;
  $scope.speak = function (callback) {
    if (typeof responsiveVoice !== 'undefined') {
      if (isPaused) {
        responsiveVoice.resume();

        isPaused = false;
      } else if (responsiveVoice.isPlaying()) {
        responsiveVoice.pause();

        if (callback)
          callback();

        isPaused = true;
      } else {
        responsiveVoice.speak($scope.thinklet.problem.question, null, { onend: callback });

        userManager.addDigitalPromiseToolUsed('TEXT_TO_SPEECH');
      }
    }
  };

  $scope.processWord = function (element) {
    var text = element.text().trim();

    var isTeX = text.match(/^\$\$.+\$\$$/);

    if (!isTeX)
      isTeX = text.match(/^\\\(.+\\\)$/);

    if (isTeX) {
      element.hide();
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, element[0]]);
      MathJax.Hub.Queue(function () {
        element.show();
      });
    }
  };

  function addTooltip(el, text, position, pointer) {
    if (/ipad|iphone/.test(window.navigator.userAgent.toLowerCase()))
      return;

    if (!position)
      position = 'bottom center';

    if (!pointer)
      pointer = 'top left';

    el.qtip({
      content: text,
      position: {
        at: position,
        my: pointer
      },
      style: {
        classes: 'whiteboard-qtip',
        def: false
      },
      hide: {
        event: false,
        inactive: 1000
      }
    });
  }

  translate('SCRAP_PAPER', $translate, $rootScope, $scope, function (text) {
    addTooltip($('#scrap-paper'), text, 'left center', 'right center');
  });

  $(window).on('unload', function () {
    if (typeof responsiveVoice != 'undefined')
      responsiveVoice.cancel();
  });

  $scope.getTitle = function () {
    if ($scope.isUnderstand())
      return $translate.instant('EXPLORE');
    else if ($scope.isPlan())
      return $translate.instant('PLAN');
    else if ($scope.isSolve())
      return $translate.instant('SOLVE');
    else if ($scope.isReview())
      return $translate.instant('REVIEW');
    else
      return null;
  };

  $scope.goToFourPhaseMenu = function () {
    $state.go('four-phase-menu', { id: thinkletId });
  };

  $scope.dontShowLanguagePopUpAgain = false;
  $scope.dontShowLanguagePopUpKey = 'dontShowLanguagePopUp';

  userManager.fetchFlag($scope.dontShowLanguagePopUpKey).then(function (value) {
    $scope.dontShowLanguagePopUpAgain = value;
  });

  $scope.confirmLanguage = function (language) {
    if ($scope.thinklet.annotatedQuestion && !$scope.dontShowLanguagePopUpAgain) {
      _attemptedLanguageSwitch = language;

      $scope.isLanguagePopUpVisible = true;

      return false;
    }

    return true;
  };

  var unsubscribe = $rootScope.$on('$translateChangeSuccess', function (event, options) {
    if (_forceQuestionProcess || !$scope.thinklet.annotatedQuestion)
      processQuestion();

    _forceQuestionProcess = false;

    $scope.questionImages = getLocalizedThinkletQuestionImage($translate, $scope.thinklet, options.language);
  });

  $scope.navigateToUnderstand = function () {
    $state.go('understand', { id: thinkletId });
  };

  $scope.navigateToPlan = function () {
    $state.go('plan', { id: thinkletId });
  };

  $scope.navigateToSolve = function () {
    $state.go('solve', { id: thinkletId });
  };

  $scope.navigateToReview = function () {
    $state.go('review', { id: thinkletId });
  };

  $scope.getStepIcon = function () {
    if ($scope.isUnderstand())
      return understandIconUrl;
    else if ($scope.isPlan())
      return planIconUrl;
    else if ($scope.isSolve())
      return solveIconUrl;
    else if ($scope.isReview())
      return reviewIconUrl;
    else
      return null;
  };

  $scope.onCancelLanguagePopUp = function () {
    $scope.isLanguagePopUpVisible = false;
  };

  $scope.onSubmitLanguagePopUp = function () {
    _forceQuestionProcess = true;

    $scope.isLanguagePopUpVisible = false;

    $scope.thinklet.annotatedQuestion = null;
    $scope.save({ annotatedQuestion: null });

    $translate.use(_attemptedLanguageSwitch);
  };

  $scope.canClickPreviousButton = function () {
    if (!_popUpImageUrls)
      return false;

    var i = _popUpImageUrls.indexOf($scope.popUpImageURL);

    if (i === 0)
      return false;

    return true;
  };

  $scope.canClickNextButton = function () {
    if (!_popUpImageUrls)
      return false;

    var i = _popUpImageUrls.indexOf($scope.popUpImageURL);

    if (i === _popUpImageUrls.length - 1)
      return false;

    return true;
  };

  $scope.saveDontShowLanguagePopUpAgain = function (value) {
    $scope.dontShowLanguagePopUpAgain = value;

    userManager.saveFlag($scope.dontShowLanguagePopUpKey);
  };

  $scope.isSpeechToTextEnabled = function () {
    return speechToText && speechToText.isEnabled();
  };

  $scope.isSpeechToTextSupported = function () {
    return typeof SpeechRecognition !== 'undefined' || typeof webkitSpeechRecognition !== 'undefined';
  };

  $scope.setSpeechToTextEnabled = function (val) {
    if (val) {
      speechToText.start();

      userManager.addDigitalPromiseToolUsed('SPEECH_TO_TEXT');
    } else {
      speechToText.stop();
    }

    digest();
  };

  var baseLogOut = $scope.logOut;

  $scope.logOut = function (skipPrompt) {
    if (speechToText)
      speechToText.stop();

    return baseLogOut(skipPrompt);
  };

  $scope.isChatEnabled = false;
  $scope.isChatVisible = false;

  $scope.isChatSupported = function () {
    return PermissionsManager.getPermission(Permissions.Chat)
      && $scope.getStepName() !== 'four-phase-menu'
      && $scope.user.type === 'Student'
      && $scope.thinklet.studentChatroom
      && $scope.thinklet.studentChatroom.chatroomName
      && !CueThinkConfig.defaultFolders.find(folder => folder.files.indexOf($scope.thinklet.problem.id) !== -1); // disable for starter problems
  };

  $scope.onChatButtonClick = function () {
    $scope.isChatEnabled = true;

    $scope.isChatVisible = !$scope.isChatVisible;

    if (!$scope.isChatVisible) {
      speechToText.stop();

      NotificationCenter.getShared().post('SpeechToTextDisabled');
    }

    digest();
  };

  $scope.$on('CloseChat', function () {
    $scope.isChatVisible = false;

    digest();
  });

  $scope.isHelperVisible = false;
  var _helperIdleTitle, _helperIdleMessage, _helperIdleButtons, _limitToState;

  $scope.setHelperVisible = function (value, title, message, buttons) {
    if (value) {
      if (_limitToState && $scope.state !== _limitToState)
        return;

      $cuethink.logThinkletEvent('Animated Helper', $scope.state === 'understand' ? 'explore' : $scope.state, $scope.thinklet.problem.id, $scope.thinklet.problem.title, $scope.thinklet.id, {
        message,
        title
      });
    } else {
      HelperIdleTimer.getSharedInstance().reset();
    }

    $scope.isHelperVisible = value;

    $scope.setHelperTitle(title);
    $scope.setHelperMessage(message);
    $scope.setHelperButtons(buttons);

    var elements = document.getElementsByTagName('helper');

    if (elements.length > 0)
      elements[0].style.visibility = value ? 'visible' : 'hidden';
  };

  $scope.setHelperPosition = value => helperStore.commit('SET_POSITION', value);

  $scope.setHelperPosition('top');

  $scope.setHelperTitle = function (value) {
    $scope.helperTitle = value;

    helperStore.commit('SET_TITLE', value);
  };

  $scope.setHelperMessage = function (value) {
    $scope.helperMessage = value;

    helperStore.commit('SET_MESSAGE', value);
  };

  $scope.setHelperButtons = function (value) {
    $scope.helperButtons = value;

    helperStore.commit('SET_BUTTONS_TEXT', null); // TODO
    helperStore.commit('SET_BUTTONS', value);
  };

  let _idleHelperCallback;

  $scope.setIdleHelperCallback = value => _idleHelperCallback = value;

  function showIdleHelper() {
    let result;

    if (_idleHelperCallback)
      result = _idleHelperCallback();

    if (result !== false)
      $scope.setHelperVisible(true, _helperIdleTitle, _helperIdleMessage, _helperIdleButtons);
  }

  $scope.setHelperIdleTitle = function (value) {
    _helperIdleTitle = value;

    if (value)
      HelperIdleTimer.getSharedInstance().setCallback(showIdleHelper);
  };

  $scope.setHelperIdleMessage = function (value, limitToState) {
    _helperIdleMessage = value;
    _limitToState = limitToState ? $scope.state : false;

    if (value)
      HelperIdleTimer.getSharedInstance().setCallback(showIdleHelper);
  };

  $scope.setHelperIdleButtons = function (buttons) {
    _helperIdleButtons = buttons;

    if (buttons)
      HelperIdleTimer.getSharedInstance().setCallback(showIdleHelper);
  };

  /*$scope.$$postDigest(() => {
    if (helperStore.state.message)
      $scope.setHelperVisible(true, helperStore.state.title, helperStore.state.message);
    else if (HelperIdleTimer.getSharedInstance().hasFired() && _helperIdleTitle)
      showIdleHelper();
    else
      $scope.setHelperVisible(false);
  });*/

  // TODO: fix
  $scope.$on('CloseHelper', function () {
    $scope.setHelperVisible(false);
  });

  var removeCloseHelperListener = NotificationCenter.getShared().addListener('CloseHelper', () => {
    $scope.setHelperVisible(false);
  });

  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(callback, type, quality) {
        var canvas = this;

        setTimeout(function() {
          var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
            len = binStr.length,
            arr = new Uint8Array(len);

          for (var i = 0; i < len; i++ )
            arr[i] = binStr.charCodeAt(i);

          callback(new Blob([arr], { type: type || 'image/png' }));
        });
      }
    });
  }

  $scope.saveScreenshot = (index, step) => {
    if (!step)
      step = $scope.getStepName();

    html2canvas(document.body).then(canvas => {
      canvas.toBlob(blob => {
        MediaManager.getShared().uploadScreenshot($scope.thinklet.id, step, blob, index).then(response => {
          var url = MediaManager.getShared().getMediaURLFromPath(response.id);

          $scope.thinklet[step + '_screenshot'] = url;
        });
      }, 'image/png');
    });
  };

  $scope.getPlanButtonTitle = function () {
    var result = $translate.instant($scope.isNewDesign() ? 'VIEW_PLAN' : ($scope.showQuestion ? 'PLAN' : 'QUESTION'));

    if (!$scope.isNewDesign())
      result = result.toUpperCase();

    return result;
  };

  $scope.showQuestion = true;
  $scope.isViewPlanVisible = false;

  $scope.toggleQuestion = function () {
    if ($scope.isNewDesign())
      $scope.isViewPlanVisible = true;
    else
      $scope.showQuestion = !$scope.showQuestion;
  };

  $scope.onViewPlanCloseButtonClick = function () {
    $scope.isViewPlanVisible = false;
  };

  $scope.onViewPlanBackgroundClick = function () {
    $scope.isViewPlanVisible = false;
  };

  $scope.onViewPlanBodyClick = function (event) {
    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  $scope.getStrategyIconUrl = function (strategy) {
    var fileName;

    if (strategy.name === 'DRAW_A_PICTURE' || strategy.name === $translate.instant('DRAW_A_PICTURE'))
      fileName = 'draw.svg';
    else if (strategy.name === 'MAKE_A_TABLE' || strategy.name === $translate.instant('MAKE_A_TABLE'))
      fileName = 'table-list.svg';
    else if (strategy.name === 'SOLVE_WITH_AN_EASIER_PROBLEM' || strategy.name === $translate.instant('SOLVE_WITH_AN_EASIER_PROBLEM'))
      fileName = 'solveicon.svg';
    else if (strategy.name === 'WORK_BACKWARDS' || strategy.name === $translate.instant('WORK_BACKWARDS'))
      fileName = 'backward-icon.svg';
    else if (strategy.name === 'GUESS_CHECK_AND_REVISE' || strategy.name === $translate.instant('GUESS_CHECK_AND_REVISE'))
      fileName = 'guess_check_icon.svg';
    else if (strategy.name === 'MODEL_IT_WITH_MANIPULATIVES' || strategy.name === $translate.instant('MODEL_IT_WITH_MANIPULATIVES')
      || strategy.name === 'SHOW_WITH_MANIPULATIVES' || strategy.name === $translate.instant('SHOW_WITH_MANIPULATIVES')
      || strategy.name === 'USE_MANIPULATIVES' || strategy.name === $translate.instant('USE_MANIPULATIVES'))
      fileName = 'manipulatives.svg';
    else if (strategy.name === 'LOOK_FOR_A_PATTERN' || strategy.name === $translate.instant('LOOK_FOR_A_PATTERN'))
      fileName = 'pattern_icon_new.svg';
    else if (strategy.name === 'MODEL_WITH_AN_EQUATION' || strategy.name === $translate.instant('MODEL_WITH_AN_EQUATION')
      || strategy.name === 'SHOW_WITH_AN_EQUATION' || strategy.name === $translate.instant('SHOW_WITH_AN_EQUATION'))
      fileName = 'equation_icon.svg';
    else if (strategy.name === 'TRY_WITH_DIFFERENT_NUMBERS' || strategy.name === $translate.instant('TRY_WITH_DIFFERENT_NUMBERS')
      || strategy.name === 'TRY_WITH_FRIENDLIER_NUMBERS' || strategy.name === $translate.instant('TRY_WITH_FRIENDLIER_NUMBERS'))
      fileName = 'DifferentEquation.svg';

    if (fileName)
      return '/images/' + fileName;
    else
      return null;
  };

  $scope.onStrategyCheckboxChange = function () {
    $scope.save({ complexItems: $scope.thinklet.complexItems });
  };

  let lastTransitionAttempt;

  if (permissionsManager.getPermission(Permissions.BeliefsProbe)) {
    $scope.showBeliefsProbe = state => {
      if (_beliefsProbeSeen[$scope.user.id] && _beliefsProbeSeen[$scope.user.id][state])
        return;

      $scope.viewModel.isBeliefsProbeVisible = true;

      let map = _beliefsProbeSeen[$scope.user.id];

      if (!map) {
        map = {};

        _beliefsProbeSeen[$scope.user.id] = map;
      }

      map[state] = true;

      $scope.$on('BeliefsProbeSubmit', () => {
        $scope.viewModel.isBeliefsProbeVisible = false;

        digest();

        if (lastTransitionAttempt)
          $state.go(lastTransitionAttempt.name, lastTransitionAttempt.params);
      });
    };

    $scope.addLeaveListener(transition => {
      const from = transition.from().name;

      lastTransitionAttempt = transition.to();

      if (from === 'four-phase-menu'
        && (!_beliefsProbeSeen[$scope.user.id] || !_beliefsProbeSeen[$scope.user.id][from])) {
        $scope.showBeliefsProbe(from);

        return false;
      } else if (from === 'plan'
        && (_beliefsProbeSeen[$scope.user.id] && _beliefsProbeSeen[$scope.user.id][from] === false)) {
        $scope.showBeliefsProbe(from);

        return false;
      }
    });
  }

  $scope.isViewPlanEnabled = function () {
    return PermissionsManager.getPermission(Permissions.SolvePlanDrawer);
  };

  $scope.isMetaCognitiveIconEnabled = function () {
    return PermissionsManager.getPermission(Permissions.MetaCognitiveHelper);
  };

  $scope.$on('$destroy', function() {
    $scope.setHelperVisible(false);

    HelperIdleTimer.getSharedInstance().setCallback(null);
    HelperIdleTimer.getSharedInstance().stop();

    removeCloseHelperListener();

    unsubscribe();

    if (speechToText) {
      speechToText.stop();
      speechToText = null;

      $scope.speechToText = speechToText;
    }
  });
}])
.directive('facilitatorGuide', FacilitatorGuideDirective)
.directive('studentChat', StudentChatDirective);
