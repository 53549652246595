import ShapeTool from './shape-tool';
import { inherit } from './base-tool';

export default function EllipseTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (x, y, w, h) {
      this.id = whiteboard.getNextId();
      this.type = EllipseTool.type;
      this.coordinates = [x, y, w, h];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  this.getRedrawnObject = function (beginShape, x, y) {
    var sx = beginShape.x;
    var sy = beginShape.y;
    var wid = x - sx;
    var hei = y - sy;

    var cls = this.getDataConstructor();

    return new cls(sx, sy, wid, hei);
  };

  me.draw = function (obj) {
    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var w = obj.coordinates[2] * whiteboard.getWidth();
    var h = obj.coordinates[3] * whiteboard.getHeight();

    EllipseTool.strokeEllipse(whiteboard.getContext(), sx, sy, w, h);
  };
}

inherit(EllipseTool, ShapeTool);

EllipseTool.type = 'oval';

EllipseTool.strokeEllipse = function (context, sx, sy, w, h) {
  var kappa = 0.5522848;
  var ox = (w / 2) * kappa;
  var oy = (h / 2) * kappa;
  var xe = sx + w;
  var ye = sy + h;
  var xm = sx + w / 2;
  var ym = sy + h / 2;

  context.beginPath();
  context.moveTo(sx, ym);
  context.bezierCurveTo(sx, ym - oy, xm - ox, sy, xm, sy);
  context.bezierCurveTo(xm + ox, sy, xe, ym - oy, xe, ym);
  context.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
  context.bezierCurveTo(xm - ox, ye, sx, ym + oy, sx, ym);
  context.closePath();
  context.stroke();
};
