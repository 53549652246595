function ImagePreloader () {
	var _me = this, _images = {};

	_me.load = function (urls, callback) {
		if (urls.length === 0) {
			if (callback)
				callback(_images);

			return;
		}

		var loadCount = 0;

		for (var i = 0; i < urls.length; i++) {
			var url = urls[i];

			_me.loadImage(url, function (url, image) {
				loadCount++;

				if (loadCount === urls.length && callback)
					callback(_images);
			});
		}
	};

	_me.loadImage = function (url, callback) {
		if (url.indexOf('http://localhost') !== 0)
			url = url.replace('http://', 'https://');

		if (_images[url]) {
			callback(url, _images[url]);

			return;
        }

		var xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';

		xhr.onload = function(e) {
		  if (this.status == 200) {
		    var reader = new window.FileReader();
			 reader.readAsDataURL(this.response);

			 reader.onload = function() {
				var image = new Image();

				function onLoad () {
					_images[url] = image;

					callback(url, image);
				}

				function onError () {
					console.log('image failed');

					callback(url, false);
				}

				$(image).on('load', onLoad);
				$(image).on('error', onError);

				$(image).on('remove', function () {
					$(this).off('load', onLoad);
					$(this).off('load', onError);
				});

				image.src = reader.result; // TODO: test with png;
			  };
		  } else {
			callback(url, false);
		  }
		};

		xhr.onerror = function (e) {
			console.log(e);

            callback(url, false);
		};

		xhr.send();
	};

	_me.getImage = function (url) {
		return _images[url];
	};
}

export default ImagePreloader;
