import 'src/components/shared/base';
import 'core/dashboard/dashboard-screen.directive';
import 'src/components/shared/thinklet_data';
import 'core/common/commonModule';

function DashboardController ($scope, $controller, ThinkletManager, $state, $stateParams, UserManager, $timeout, TeacherManager, $translate) {
  "ngInject";

  $controller('BaseController', { '$scope': $scope });

  $scope.viewModel = {
  };

  $scope.loadThinklets = ThinkletManager.loadUserThinklets;
  $scope.loadAssignments = ThinkletManager.fetchAssignments;
  $scope.loadNotifications = ThinkletManager.loadNotifications;
  $scope.deleteThinklet = ThinkletManager.deleteThinklet;
  $scope.thinkletManager = ThinkletManager;

  $scope.editThinklet = function (thinklet) {
    if (typeof thinklet == 'object')
      ThinkletManager.removeCachedThinklet(thinklet);

    $scope.navigateToWorkOnThinklet(thinklet);
  };

  $scope.viewThinklet = function (thinkletId, params) {
    $state.go('view', Object.assign({ id: thinkletId }, params ? params : {}));
  };

  $scope.startThinklet = function (thinklet) {
    $scope.navigateToWorkOnThinklet(thinklet);
  };

  $scope.navigateAssignments = function () {
    $state.go('assignments');
  };

  $scope.deleteNotification = ThinkletManager.deleteNotification;
  $scope.loadNewNotifications = ThinkletManager.loadNewNotifications;
  $scope.isOverdue = $stateParams.overdue === 'true' || $stateParams.overdue === true;
  $scope.logInIntercom = UserManager.loginIntercom;

  $scope.navigateCurrentProblems = function () {
    $state.go('dashboard', { overdue: false });
  };

  $scope.navigateOverdueProblems = function () {
    $state.go('dashboard', { overdue: true });
  };

  $scope.joinClass = UserManager.changeClass;
  $scope.fetchHasBeenIdle = UserManager.fetchHasBeenIdle;
  $scope.selectClass = UserManager.selectClass;

  function showNavMenuPopover () {
    var msgId = 'CLICK_HERE_TO_VIEW_THE_TUTORIAL_FROM_ANY_PAGE';

    $translate(msgId).then(function (msg) {
      var btn = $('.quaid-button').popover({
        content: msg,
        placement: 'bottom',
        trigger: 'manual'
      }).popover('show');

      $timeout(function () {
        btn.popover('hide');
      }, 5000);
    });
  }

  if ($stateParams.welcome && !$scope.isCuethinkV2User()) {
    $scope.isWelcomeTeacherPopupVisible = true;

    $scope.closeWelcomeTeacherPopup = function () {
      $scope.isWelcomeTeacherPopupVisible = false;

      showNavMenuPopover();
    };

    $scope.submitWelcomeTeacherPopup = function () {
      $scope.isWelcomeTeacherPopupVisible = false;

      if (!$scope.isCuethinkV2User())
        $scope.startTutorial();
    };
  }

  if ($stateParams.finishedTutorial && $scope.user.type !== 'Student') {
    UserManager.fetchSeenMenuPopover().then(function (seen) {
      if (!seen)
        $timeout(showNavMenuPopover, 500);
    });
  }

  $scope.copyExemplarThinklet = ThinkletManager.copyExemplarThinklet;

  if ($scope.user.type !== 'Student' && $scope.user.isNew) {
    UserManager.fetchSeenReadyToExplore().then(function (result) {
      $scope.isReadyToExploreEnabled = !result;
    });
  }

  $scope.copyExemplarThinklet = ThinkletManager.copyExemplarThinklet;
  $scope.setSeenReadyToExplore = UserManager.setSeenReadyToExplore;
  $scope.fetchHasSeenTrialEndPopUp = TeacherManager.fetchHasSeenTrialEndPopUp;
  $scope.setHasSeenTrialEndPopUp = TeacherManager.setHasSeenTrialEndPopUp;
  $scope.fetchHasSeenLanguagePopUp = UserManager.fetchHasSeenLanguagePopUp;
  $scope.userManager = UserManager;
}

export default DashboardController;
