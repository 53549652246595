import 'core/educatorGuide/educatorGuideDirective';
import 'src/components/shared/teacher_data';
import 'src/app/navigation/teachernavbarController';

function EducatorGuideController ($scope, TeacherManager, $controller, $state) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });

    TeacherManager.loadEducatorGuides().then(function (guides) {
        $scope.guides = guides;
    });

    $scope.viewGuide = function (guide) {
        $state.go('proflearning', { id: guide.id, url: guide.url, page: guide.page });
    };
}

export default EducatorGuideController;