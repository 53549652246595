import 'angular-translate';
import './classCodesDirective.css';
import tpl from './classCodesDirective.tpl.html';
import { setUserModesScopeMembers } from '../common/user-modes';
import 'core/common/cuethinkPopUpDirective';

export default function ClassCodesDirective($translate) {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        classToRemove: null,
        newClassCode: null,
        seenRemovePopUp: false
      };

      $scope.addClass = function ($event) {
        if ($scope.isEFUser() || $scope.isEF23User()) {
          if ($scope.viewModel.newClassCode) {
            $scope.classes.push({ invitationCode: $scope.viewModel.newClassCode });

            $scope.viewModel.newClassCode = null;
          }
        } else {
          $scope.classes.push({ invitationCode: $scope.viewModel.newClassCode, name: $scope.viewModel.newClassCode });
        }

        $scope.viewModel.newClassCode = null;

        if ($event) {
          $event.stopPropagation();
          $event.preventDefault();
        }

        return false;
      };

      $scope.onCancelRemovePopUp = function () {
        $scope.viewModel.classToRemove = null;
      };

      $scope.onCloseRemovePopUp = function () {
        $scope.viewModel.classToRemove = null;
      };

      $scope.onRemoveLinkClick = function (cls, $event) {
        $scope.removePopUpTitle = $translate.instant('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CLASS', { className: cls.name });

        $scope.viewModel.classToRemove = cls;

        if ($event) {
          $event.stopPropagation();
          $event.preventDefault();
        }

        return false;
      };

      $scope.onSubmitRemovePopUp = function () {
        $scope.removeClass($scope.viewModel.classToRemove);

        $scope.viewModel.classToRemove = null;
      };

      $scope.removeClass = function (cls) {
        $scope.classes.splice($scope.classes.indexOf(cls), 1);

        $scope.viewModel.classToRemove = null;

        return false;
      };

      setUserModesScopeMembers($scope);
    },
    restrict: 'E',
    scope: {
      classes: '=',
      user: '=',
      userManager: '='
    },
    template: tpl
  }
}
