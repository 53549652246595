import './cuethinkPopUpDirective';
import './cuethinkOneTimePopUpDirective.css';

export default function CueThinkOneTimePopUpDirective () {
  return {
    link: function ($scope) {
      $scope.viewModel = {
        isVisible: false
      };

      $scope.onSubmit = function () {
        $scope.viewModel.isVisible = false;
      };

      $scope.hasSeen().then(function (value) {
        if (!value)
          $scope.viewModel.isVisible = true;
      });
    },
    scope: {
      arrow: '@',
      bottom: '@',
      hasSeen: '=',
      left: '@',
      message: '@',
      right: '@',
      spotlight: '@',
      submitText: '@',
      title: '@',
      top: '@'
    },
    template: require('./cuethinkOnetimePopUpDirective.tpl.html')
  };
}
