<template>
  <div class="vue-student-chat">
    <div
      class="chat-overlay"
      @click="onOverlayClick">
    </div>

    <div class="body">
      <button
        class="close"
        tabindex="0"
        @click="onCloseButtonClick">
      </button>

      <div class="header">
        <button
          v-if="false"
          class="share-screenshot"
          tabindex="0"
          @click.prevent.stop="onShareScreenshotButtonClick">
          {{ $options.filters.translate('SHARE_SCREENSHOT') | lowercase }}
        </button>

        <span
          v-if="false"
          class="status"
          :class="{ active: status === 'ACTIVE', away: status === 'AWAY' }">
          {{ $options.filters.translate('STATUS') | lowercase }}:

          <span class="dropdown">
            <a
              class="dropdown-toggle"
              data-toggle="dropdown"
              tabindex="0">
              {{ status | translate }}
            </a>

            <div class="dropdown-menu">
              <li
                class="dropdown-item"
                @click.prevent.stop="onStatusClick('ACTIVE')">
                {{ 'ACTIVE' | translate }}
              </li>

              <li
                class="dropdown-item"
                @click.prevent.stop="onStatusClick('AWAY')">
                {{ 'AWAY' | translate }}
              </li>
            </div>
          </span>
        </span>

        <label class="team">{{ 'TEAM' | translate }}</label>

        <button
          v-for="chatUser in chatUsers"
          class="chat-user"
          tabindex="0">
          <img
            v-if="getAvatarUrl(chatUser)"
            alt="Avatar"
            class="avatar"
            :src="getAvatarUrl(chatUser)"
          />
          <span
            v-else
            class="avatar"
            tabindex="0"
            :style="{ 'background-color': getDefaultAvatarColors(chatUser).background, 'border-color': getDefaultAvatarColors(chatUser).border }">
            {{ chatUser.firstName.substr(0, 1).toUpperCase() }}{{ chatUser.lastName.substr(0, 1).toUpperCase() }}
          </span>

          <span
            class="status-indicator"
            :class="{ active: chatUser.status === 'ACTIVE', away: chatUser.status === 'AWAY' }">
          </span>

          <span class="name">{{ chatUser.firstName | propercase }} {{ chatUser.lastName.substr(0, 1).toUpperCase() }}</span>
        </button>
      </div>

      <div
        class="chat"
        ref="chatMessages">
        <div
          v-for="message in messages"
          class="message"
          :class="{ 'self-message': message.userName && (message.userName === user.username) }">
          <div>
            <img
              v-if="getMessageAvatarImageURL(message)"
              alt="Avatar"
              class="avatar"
              :src="getMessageAvatarImageURL(message)"
            />
            <span
              v-if="!getMessageAvatarImageURL(message)"
              class="avatar"
              :style="{ 'background-color': getDefaultAvatarColors(getMessageUser(message.userName)).background, 'border-color': getDefaultAvatarColors(getMessageUser(message.userName)).border }">
              {{ getMessageUser(message.userName).firstName.substr(0, 1).toUpperCase() }}{{ getMessageUser(message.userName).lastName.substr(0, 1).toUpperCase() }}
            </span>
          </div>

          <div class="contents">
            <p>
              <span
                v-if="message.userName"
                class="name">
                {{ getMessageUser(message.userName).firstName | propercase }}
              </span>

              <!--<span
                v-if="message.timestamp"
                class="timestamp">
                {{ message.timestamp | formatTime('h:mma') }}
              </span>-->
            </p>

            <p class="bubble">
              <span
                v-if="message.isSystem"
                v-html="message.text">
              </span>
              <span v-if="!message.isSystem">
                {{ message.text }}
              </span>

              <button
                v-if="message.imageUrl"
                class="image"
                tabindex="0"
                @click.prevent.stop="onImageClick(message.imageUrl)">
                <img
                  alt="Message Image"
                  :src="message.imageUrl"
                />
              </button>
            </p>
          </div>
        </div>
      </div>

      <div class="sentence-starters">
        <button
          class="sentence-starters-toggle"
          tabindex="0"
          :class="{ open: isSentenceStartersVisible }"
          @click.prevent.stop="onSentenceStartersToggleClick">
          {{ 'SENTENCE_STARTERS' | translate }}
        </button>

        <div
          v-if="isSentenceStartersVisible"
          class="sentence-starters-body">
          <div class="tabs">
            <button
              class="question-tab"
              tabindex="0"
              :class="{ selected: selectedTab === 'QUESTION' }"
              @click.stop.prevent="onTabButtonClick('QUESTION')">
              {{ 'QUESTION' | translate }}
            </button>

            <button
              class="giving-help-tab"
              tabindex="0"
              :class="{ selected: selectedTab === 'GIVING_HELP' }"
              @click.stop.prevent="onTabButtonClick('GIVING_HELP')">
              {{ 'GIVING_HELP' | translate }}
            </button>

            <button
              class="appreciating-tab"
              tabindex="0"
              :class="{ selected: selectedTab === 'APPRECIATION' }"
              @click.stop.prevent="onTabButtonClick('APPRECIATION')">
              {{ 'APPRECIATION' | translate }}
            </button>
          </div>

          <ul class="sentence-starter-list">
            <li
              v-for="sentenceStarter in sentenceStarters[selectedTab]"
              class="sentence-starter"
              @click.stop.prevent="onSentenceStarterClick(sentenceStarter)">
              {{ sentenceStarter | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="new-message">
        <v-textarea
          auto-grow
          rows="3"
          solo
          :label="$options.filters.translate('ENTER_YOUR_CHAT_MESSAGE_HERE')"
          v-model="newMessageText"
          @keydown="onNewMessageKeyDown"
        />

        <button
          class="speech-to-text-btn"
          tabindex="0"
          :class="{ enabled: isSpeechToTextEnabled }"
          @click.prevent.stop="onSpeechToTextButtonClick">
        </button>

        <button
          class="send-btn"
          tabindex="0"
          @click.prevent.stop="onSendButtonClick">
          <span>{{ 'SEND' | translate }}</span>
        </button>
      </div>
    </div>

    <div
      v-if="selectedImageUrl"
      class="image-pop-up"
      @click.prevent.stop="onImagePreviewClick">
      <img
        alt="Image Pop Up"
        :src="selectedImageUrl"
      />
    </div>
  </div>
</template>

<script>
import formatTime from 'core/common/filters/formatTime.filter';
import lowercase from 'core/common/filters/lowercase.filter';
import propercase from 'core/common/filters/propercase.filter';
import translate from 'core/common/filters/translate.filter';
import systemImageUrl from 'src/images/CueThinkIconWhiteOnBlue.svg';
import CueThinkConfig from 'src/app/config.provider';
import html2canvas from 'html2canvas';
import UserManager from 'src/components/shared/user_data';
import MediaManager from 'src/components/shared/media';
import 'src/images/SpeechToText.svg';
import 'src/images/Send_Icon.png';
import 'src/images/Chat_Icon.svg';
import 'src/images/DropDownArrow.svg';
import 'src/images/ExpandIcon.svg';
import 'src/images/Question_Icon.svg';
import 'src/images/GivingHelp_Icon.svg';
import 'src/images/ThumbsUp_Icon.svg';
import 'src/images/ScreenShot.svg';
import 'src/images/Dropdown_ChatArrow.svg';
import ChatManager from 'src/components/shared/ChatManager';
import NotificationCenter from 'core/notifications/notification-center';
import 'src/images/Dropdown_ChatArrow_Up.svg';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'src/images/TextToSpeech_Green.svg';
import 'src/images/CloseXBold.svg';
import LogManager from 'core/logging/log-manager';

export default {
  vuetify: new Vuetify(),

  filters: { formatTime, lowercase, propercase, translate },

  props: {
    assignmentId: Number,
    chatRoomName: String,
    classId: Number,
    speechToText: Object,
    thinkletId: Number
  },

  data() {
    return {
      chatClient: ChatManager.getShared(),
      chatUsers: [],
      classUsers: [],
      hasScrolled: false,
      isSentenceStartersVisible: false,
      isSpeechToTextEnabled: false,
      messages: [],
      newMessageText: null,
      participantsTimer: null,
      selectedImageUrl: null,
      sentenceStarters: {
        QUESTION: [
          'I_DONT_UNDERSTAND_CAN_YOU_HELP_ME_WITH',
          'IM_NOT_SURE_WHAT_TO_DO_WHAT_CAN_I_TRY_NEXT',
          'CAN_YOU_SHOW_ME_HOW'
        ],
        GIVING_HELP: [
          'I_CAN_HELP_HAVE_YOU_TRIED',
          'WHAT_IF_YOU_TRY',
          'ITS_OKAY_WHAT_HAVE_YOU_TRIED_SO_FAR'
        ],
        APPRECIATION: [
          'THANKS_YOUR_EXPLANATION_HELPS_ME_UNDERSTAND',
          'OHH_NOW_I_SEE_MY_MISTAKE_BECAUSE',
          'WOW_I_HADNT_THOUGHT_OF_DOING_IT_THAT_WAY'
        ]
      },
      selectedTab: 'QUESTION',
      status: null
    };
  },

  computed: {
    defaultAvatarColors() {
      return CueThinkConfig.defaultAvatarColors;
    },

    user() {
      return UserManager.getShared().getUser();
    }
  },

  watch: {
    messages() {
      if (this.$refs.chatMessages && this.messages.length > 0 && !this.hasScrolled) {
        setTimeout(() => {
          this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
        });

        this.hasScrolled = true;
      }
    }
  },

  created() {
    LogManager.getShared().logBehaviorEvent('Load Chat Class Users', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });

    const classUsersPromise = UserManager.getShared().loadClassUsers(this.classId).then(users => {
      this.classUsers = users;

      LogManager.getShared().logBehaviorEvent('Loaded Chat Class Users', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });
    });

    LogManager.getShared().logBehaviorEvent('Connect Chat', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });

    var connectPromise = this.chatClient.connect(this.user, () => {
      this.joinRoom();
    }).then(() => {
      LogManager.getShared().logBehaviorEvent('Connected Chat', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });

      if (this.status)
        this.updateStatus(this.status);
    }).catch(e => {
      console.log(e);

      LogManager.getShared().logBehaviorEvent('Connect Chat Error', { assignmentId: this.assignmentId, classId: this.classId, error: e, thinkletId: this.thinkletId, userId: this.user.id });
    });

    Promise.all([connectPromise, classUsersPromise]).then(() => {
      this.joinRoom();
    });

    NotificationCenter.getShared().addListener('SpeechToText', text => {
      if (!this.isSpeechToTextEnabled)
        return;

      if (typeof this.newMessageText !== 'string')
        this.newMessageText = '';

      if (this.newMessageText && this.newMessageText[this.newMessageText.length - 1] !== ' ')
        this.newMessageText += ' ';

      this.newMessageText += text + ' ';
    });
  },

  methods: {
    close() {
      this.$emit('CloseChat');
    },

    getAvatarUrl(user) {
      var url;

      if (user.id === this.user.id)
        url = UserManager.getShared().getTemporaryAvatarUrl();

      if (typeof url === 'undefined')
        url = user.avatarUrl;

      return url;
    },

    getDefaultAvatarColors(user) {
      var i = user.id % (this.defaultAvatarColors.length - 1);

      return this.defaultAvatarColors[i];
    },

    getMessageAvatarImageURL(message) {
      if (message.isSystem) {
        return systemImageUrl;
      } else {
        const user = this.getMessageUser(message.userName);

        return user ? user.avatarUrl : systemImageUrl;
      }
    },

    getMessageUser(userName) {
      let result = this.chatUsers.find(user => user.username === userName || parseInt(userName) === user.id);

      if (!result)
        result = this.classUsers.find(user => user.username === userName || parseInt(userName) === user.id);

      return result;
    },

    joinRoom() {
      LogManager.getShared().logBehaviorEvent('Join Chat Room', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });

      this.chatClient.join(this.chatRoomName, userName => {
        const classUser = this.classUsers.find(classUser => {
          return classUser.username === userName;
        });

        if (classUser) {
          const existingUser = this.chatUsers.find(user => user.username === classUser.username);

          if (!existingUser)
            this.chatUsers.push(classUser);
        }
      }).then(data => {
        LogManager.getShared().logBehaviorEvent('Joined Chat Room', { assignmentId: this.assignmentId, classId: this.classId, thinkletId: this.thinkletId, userId: this.user.id });

        this.messages = data.messages;

        UserManager.getShared().getChatStatus().then(status => {
          this.updateStatus(status);
        });

        this.updateParticipants();
      });
    },

    onOverlayClick() {
      this.close();
    },

    onCloseButtonClick() {
      this.close();
    },

    onImageClick(url) {
      this.selectedImageUrl = url;
    },

    onImagePreviewClick() {
      this.selectedImageUrl = null;
    },

    onNewMessageKeyDown(e) {
      if (e.key.key === 13 || e.keyCode === 13) {
        this.onSendButtonClick();

        e.preventDefault();
        e.stopPropagation();

        return false;
      }
    },

    onSentenceStartersToggleClick() {
      this.isSentenceStartersVisible = !this.isSentenceStartersVisible;
    },

    onShareScreenshotButtonClick() {
      var me = this;

      html2canvas(document.body, {
        onclone(doc) {
          // don't screenshot chat
          var chatNode = doc.getElementsByClassName('vue-student-chat')[0];

          chatNode.parentNode.removeChild(chatNode);
        }
      }).then(canvas => {
        canvas.toBlob(blob => {
          MediaManager.getShared().uploadImage(this.thinkletId, blob).then(function (response) {
            var url = MediaManager.getShared().getMediaURLFromPath(response.id);

            this.sendMessage(null, '<img alt="Image" src="' + encodeURIComponent(url) + '" />');
          });
        }, 'image/png');
      });
    },

    onSendButtonClick() {
      if (!this.newMessageText)
        return;

      this.sendMessage(this.newMessageText);

      this.newMessageText = null;

      this.isSentenceStartersVisible = false;

      this.setSpeechToTextEnabled(false);
    },

    onSentenceStarterClick(starter) {
      if (typeof this.newMessageText !== 'string')
        this.newMessageText = '';

      if (this.newMessageText)
        this.newMessageText += ' ';

      this.newMessageText += translate(starter) + ' ';
    },

    onSpeechToTextButtonClick() {
      this.setSpeechToTextEnabled(!this.isSpeechToTextEnabled);
    },

    onStatusClick(status) {
      this.updateStatus(status);
    },

    onTabButtonClick(tab) {
      this.selectedTab = tab;
    },

    sendMessage(message, htmlMessage) {
      this.chatClient.sendMessage(message, htmlMessage);
    },

    setSpeechToTextEnabled(value) {
      if (this.isSpeechToTextEnabled === value)
        return;

      if (value && this.speechToText.isEnabled()) // already in use
        return;

      this.isSpeechToTextEnabled = value;

      if (value)
        this.speechToText.start();
      else
        this.speechToText.stop();
    },

    startParticipantsTimer() {
      this.participantsTimer = setTimeout(() => {
        this.updateParticipants();
      }, 20000);
    },

    updateParticipants() {
      let chatUsers;

      const classUsersPromise = UserManager.getShared().loadChatUsers(this.chatRoomName).then(users => {
        chatUsers = users;
      }).then(() => {
        if (chatUsers.length > 0) {
          chatUsers.forEach(chatUser => {
            if (chatUser) {
              const classUser = this.classUsers.find(classUser => {
                const i = chatUser.jid.indexOf('/');
                const username = chatUser.jid.substr(i + 1);

                return classUser.username === username;
              });

              if (classUser) {
                const existingUser = this.chatUsers.find(user => user.username === classUser.username);

                if (!existingUser)
                  this.chatUsers.push(classUser);
              }
            }
          });

          const thisUser = this.chatUsers.find(chatUser => chatUser.username === this.user.username);

          if (!thisUser)
            this.chatUsers.unshift(this.user);
        } else {
          this.chatUsers = [this.user];
        }

        this.startParticipantsTimer();
      });
    },

    updateStatus(status) {
      this.status = status;

      UserManager.getShared().setChatStatus(status);

      this.chatClient.setStatus(status);
    },

    destroyed() {
      clearTimeout(this.participantsTimer);
      this.participantsTimer = null;

      this.chatClient.disconnect();
    }
  }
}
</script>

<style lang="scss">
  .vue-student-chat {
    .chat-overlay {
      background-color: rgba(0, 0, 0, .15);
      z-index: 0;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: all;
    }

    .body {
      -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .175);
      box-shadow: 0 0 3px rgba(0, 0, 0, .175);
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      position: relative;
    }

    .header {
      background-color: #FAFAFA;
      border-bottom: 1px solid #EAEAEA;
      padding-top: 25px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }

    .chat {
      padding: 20px 17px;
    }

    h4 {
      font-size: 14px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
    }

    label {
      margin-top: 0;
      font-size: 12px;
    }

    .chat-user {
      font-size: 11px;
      line-height: 13px;
      background-color: transparent;
      margin-right: 10px;
      width: 72px;
      cursor: default;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      vertical-align: top;
      margin-bottom: 10px;
    }

      .chat-user .name {
        word-wrap: anywhere;
        white-space: pre-wrap;
        display: block;
        text-align: center;
      }

      .chat-user img {
        margin-bottom: 4px;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .chat-user .avatar {
        width: 45px;
        height: 45px;
        display: block;
        margin-bottom: 7px;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .chat-user span.avatar {
        border-radius: 22.5px;
        color: white;
        text-align: center;
        font-size: 21px;
        display: inline-block;
        line-height: 45px;
        font-weight: 500;
        border-width: 1px;
        border-style: solid;
      }

    .bubble {
      background-color: #FAFAFA;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      direction: ltr !important;
      color: #363845;
      border-bottom-left-radius: 0;
    }

    .self-message .bubble {
      background-color: #EAF6FC;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 0;
    }

    .bubble img {
      max-width: 100%;
    }

    .share-screenshot {
      height: 30px;
      border-radius: 15px;
      border: 1px solid rgb(138, 169, 224);
      color: rgb(38, 169, 224) !important;
      padding: 0 10px 0 20px;
      font-size: 12px;
      float: right;
      line-height: 30px;
      background-image: url(/images/ScreenShot.svg);
      background-size: 10px 10px;
      background-repeat: no-repeat;
      background-position: center left 8px;
      background-color: transparent;
    }

    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 0 !important;
      display: block;
    }

    span.avatar {
      border-radius: 15px;
      color: white;
      text-align: center;
      font-size: 14px;
      display: inline-block;
      line-height: 30px;
      font-weight: 500;
      border-width: 1px;
      border-style: solid;
    }

    .status {
      font-size: 12px;
    }

    .status .dropdown-toggle {
      font-size: 12px;
      text-decoration: none;
      padding-left: 15px;
      padding-right: 12px;
      position: relative;
    }

    .status .dropdown-toggle:after {
      content: '';
      background-image: url(/images/Dropdown_ChatArrow.svg);
      background-size: 8px 5px;
      background-position: center right 2px;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 0;
      width: 12px;
      height: 5px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    .status .open .dropdown-toggle:after {
    }

    .status .dropdown-toggle:before {
      content: '';
      border-radius: 5px;
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    .status.active .dropdown-toggle:before {
      background-color: #8ED591;
    }

    .status.away .dropdown-toggle:before {
      background-color: #F5A623;
    }

    .status .dropdown-item {
      cursor: pointer;
    }

    .status .dropdown-item:hover {
      text-decoration: none;
    }

    .speech-to-text-btn, .send-btn {
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .speech-to-text-btn {
      background-image: url(/images/SpeechToText.svg);
      background-position: top left;
      margin-right: 4px;
      width: 29px;
      height: 29px;
      background-size: 29px 29px;
    }

    .speech-to-text-btn.enabled {
      background-image: url(/images/TextToSpeech_Green.svg);
    }

    .send-btn {
      background-color: rgb(38, 169, 224);
      background-image: url(/images/Send_Icon.png);
      background-position: center left 8px;
      color: white !important;
      padding: 9px 12px 9px 27px;
      font-weight: 500;
      margin-bottom: 1.75px;
      background-size: 15px 15px;
      border-radius: 2px;
      vertical-align: top;
      line-height: 16.98px;
    }

    .send-btn span {
      vertical-align: middle;
    }

    .sentence-starters {
      border-top: 1px solid #D4EDF8;
      border-bottom: 1px solid #D4EDF8;
    }

    .sentence-starters .sentence-starters-toggle {
      background-repeat: no-repeat;
      background-position: center right 10px;
      background-image: url(/images/DropDownArrow.svg);
      background-size: 20px 20px;
      vertical-align: middle;
      background-color: #EAF6FC;
      text-align: left;
      width: 100%;
      padding: 10px 17px;
      color: rgb(38, 169, 224) !important;
      font-weight: 700;
    }

      .sentence-starters-toggle:active {
        opacity: 1;
      }

    .sentence-starters .sentence-starters-toggle.open {
      background-image: url(/images/Dropdown_ChatArrow_Up.svg);
    }

    .sentence-starters .sentence-starters-body {
      background-color: white;
    }

    .sentence-starters .tabs {
      padding: 15px 10px 0 10px;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      justify-content: space-around;
      -webkit-justify-content: space-around;
      border-bottom: 1px solid #EAEAEA;
    }

    .sentence-starters .tabs > * {
      padding-bottom: 15px;
      color: #333 !important;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 15px 15px;
      background-color: transparent;
      font-weight: 500;
      opacity: .5;
      font-size: 12px;
      padding-left: 20px;
    }

    .sentence-starters .tabs > .selected {
      border-bottom: 1px solid #2AA6DB;
      opacity: 1;
      line-height: 16px;
    }

    .sentence-starters .sentence-starter-list {
      padding: 10px;
      overflow-y: auto;
      margin-bottom: 0 !important;
      list-style-type: none;
    }

    .sentence-starters .sentence-starter {
      border-radius: 15px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5px;
      border: 1px solid #EAEAEA;
      font-size: 12px;
      cursor: pointer;
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }

    .sentence-starters .sentence-starter:last-child {
      margin-bottom: 0;
    }

    .sentence-starters .sentence-starter:hover {
      text-decoration: none;
      border-color: #AAA;
    }

    .image-pop-up {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
    }

    .image-pop-up img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      max-width: 80%;
      max-height: 80%;
    }

    .image {
      position: relative;
    }

    .image:before {
      content: '';
      background-image: url(/images/ExpandIcon.svg);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .question-tab {
      background-image: url(/images/Question_Icon.svg);
    }

    .giving-help-tab {
      background-image: url(/images/GivingHelp_Icon.svg);
    }

    .appreciating-tab {
      background-image: url(/images/ThumbsUp_Icon.svg);
    }

    .new-message {
      text-align: right;
      padding: 11px 10px 10px 11px;
      align-items: flex-end;
      -webkit-align-items: flex-end;
    }

      .new-message button {
        display: inline-block;
      }

    .status-indicator {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .status-indicator.active {
      background-color: #8ED591;
    }

    .status-indicator.away {
      background-color: #F5A623;
    }

    .name {
      color: #363845;
      font-weight: 700;
      font-size: 12px;
    }

    .timestamp {
      color: #979797;
      font-size: 12px;
      font-weight: 700;
      margin-top: 4px;
    }

    .chat {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      overflow-y: auto;
    }

    .message {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .message.self-message {
      direction: rtl;
    }

    .message .contents {
      flex-grow: 1;
      -webkit-flex-grow: 1;
    }

    .message.self-message .contents {
      margin-right: 10px;
    }

    .message:not(.self-message) .contents {
      margin-left: 10px;
    }

    .v-input__control {
      min-height: unset !important;
    }

    .v-input__slot {
      margin-bottom: 0;
      box-shadow: none !important;
      padding: 0 !important;
      min-height: unset !important;
    }

    .v-text-field__details {
      display: none;
    }

    .v-label {
      color: #a9a9a9;
      top: 0 !important;
      margin-top: 10px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      left: 10px !important;
    }

    textarea {
      margin-top: 0 !important;
      line-height: 20px !important;
      border-radius: 2px;
      padding: 10px 10px !important;
      font-family: "Museo Sans";
      font-weight: 500;
      font-size: 14px;
      border: 1px solid #E7E6E6 !important;
      color: #363845 !important;
    }

      textarea:focus {
        border-color: #979797 !important;
      }

    .v-textarea {
      margin-bottom: 10px;
    }

    .v-text-field__slot {
      align-self: unset !important;
    }

    button.close {
      background-color: transparent;
      background-size: 15px 15px;
      background-image: url('/images/CloseXBold.svg');
      background-repeat: no-repeat;
      background-position: top left;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 15px;
      right: 15px;
      opacity: 1;
    }
  }

  .team {
    font-size: 14px !important;
    font-weight: 700;
  }
</style>
