import RegularPolygonTool from './regular-polygon-tool';
import { inherit } from './base-tool';

export default function PentagonTool (whiteboard) {
  var me = this;

  RegularPolygonTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, color) {
      this.id = whiteboard.getNextId();
      this.type = PentagonTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  this.getSideCount = function () {
    return 5;
  };

  this.getAngleOffset = function () {
    return -Math.PI / 2;
  };
}

inherit(PentagonTool, RegularPolygonTool);

PentagonTool.type = 'pentagon';
