import 'core/rubric/rubric.module';
import 'core/rubric/rubricSummaryDirective';
import 'src/app/navigation/teachernavbarController';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/teacher_data';
import _ from 'underscore';

function RubricSummaryController ($scope, TeacherManager, ThinkletManager, $controller, $q, $state) {
    "ngInject";

    var teacherManager = TeacherManager;
    var thinkletManager = ThinkletManager;

    $controller('TeacherNavBarController', { '$scope': $scope });

    $scope.loadAssignments = function () {
        return teacherManager.loadCoteachingAssignments(true);
    };

    $scope.loadRubricStats = teacherManager.loadRubricStats;
    $scope.loadClasses = teacherManager.loadClasses;

    $scope.loadThinklets = function (assignmentIds, classIds, studentIds) {
        var deferred = $q.defer();

        thinkletManager.loadStudentsThinklets(null, classIds, studentIds, assignmentIds, null, null, null, function (thinklets) {
            deferred.resolve(thinklets);
        }, function (result) {
            deferred.reject(result);
        });

        return deferred.promise;
    };

    $scope.viewThinklet = function (id) {
        $state.go('view-teacherdash', { id: id });
    };

    $scope.loadAssignment = function (id) {
        var deferred = $q.defer();

        teacherManager.loadAssignment(id, function (result) {
            deferred.resolve(result);
        }, function (result) {
            deferred.reject(result);
        });

        return deferred.promise;
    };
}

export default RubricSummaryController;
