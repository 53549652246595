import module from '../login.module';
import tpl from './registerChoiceDirective.tpl.html';

module.directive('registerChoice', function () {
  "ngInject";

  var linkFunction = function () {
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    scope: {
      'logoPath': '@',
      'name': '=',
      'registerTeacher': '=',
      registerStudent: '=',
      logIn: '='
    },
    replace: true
  };
});
