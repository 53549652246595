import 'angular-translate';
import 'core/common/commonModule';
import 'core/login/login.module';
import 'core/teacherscreen/teacherScreenDirective';
import ChooseAvatarDirective from './chooseAvatarDirective';
import ChooseColorThemeDirective from './chooseColorThemeDirective';
import ClassCodesDirective from './classCodesDirective';

var settingsModule = angular.module('cuethink.settings', ['cuethink.common', 'cuethink.login', 'cuethink.teacherscreen', 'pascalprecht.translate', 'dndLists']);

settingsModule.directive('chooseAvatar', ChooseAvatarDirective);
settingsModule.directive('chooseColorTheme', ChooseColorThemeDirective);
settingsModule.directive('classCodes', ClassCodesDirective);

export default settingsModule;
