import '../navigation/teachernavbarController';
import 'core/teacherscreen/teacherScreenDirective';
import 'src/components/shared/user_data';
import 'src/components/shared/thinklet_data';
import 'src/components/shared/teacher_data';
import 'angular-translate';
import Tutorial from 'core/tutorial/tutorial';
import { isEF23User } from 'core/common/user-modes';

export default function MonitorReportController($scope, $controller, TeacherManager, UserManager, ThinkletManager, $state, $translate, TutorialService, CueThinkConfig) {
  $controller('TeacherNavBarController', { '$scope': $scope });

  $scope.isTutorialPopUpVisible = false;

  var handleTutorial = function () {
    if (!UserManager.getUser().cueteachEnabled || isEF23User(UserManager.getUser()))
      return;

    TeacherManager.fetchSeenToolbarTutorial().then(function (value) {
      if (value)
        return;

      TeacherManager.flagSeenToolbarTutorial(true);

      $scope.isTutorialPopUpVisible = true;
    });
  };

  $scope.closeTutorialPopUp = function () {
    $scope.isTutorialPopUpVisible = false;
  };

  $scope.deferTutorialPopUp = function () {
    TeacherManager.flagSeenToolbarTutorial(false);
  };

  $scope.startToolbarTutorial = function () {
    TutorialService.toolbarTutorial = new Tutorial($state, $translate);

    TutorialService.toolbarTutorial.start(CueThinkConfig.toolbarTutorialSteps);
  };

  $scope.startClassProgressTutorial = function () {
    $scope.isTutorialPopUpVisible = false;

    $scope.startToolbarTutorial();
  };

  $scope.$on('loaded', () => {
    handleTutorial();
  });

  $scope.$on('viewThinklet', (e, thinklet) => {
    $state.go('view-teacherdash', { id: thinklet.id })
  });
}
