<template>
  <div class="modal">
    <div class="body">
      <button
        class="close-btn"
        tabindex="0"
        @click="onCloseButtonClick">
        {{ 'CLOSE' | translate }}
      </button>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import translate from 'core/common/filters/translate.filter';
import 'src/images/Close x.svg';

export default {
  filters: { translate },

  methods: {
    onCloseButtonClick() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
  .modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: block !important;
  }

    .body {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      background-color: white;
      border-radius: 8px;
      padding: 40px;
    }

  .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 1 !important;
    margin-top: 0 !important;
    margin-bottom: 0;
    padding: 0;
    background-image: url('/images/Close x.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: top left;
    text-indent: -9999em;
    width: 16px;
    height: 16px;
    overflow: hidden;
  }
</style>
