export default function HelperIdleTimer(timeout) {
  var _callback;
  var _helperTimer;
  var _fired;

  this.hasFired = function () {
    return _fired;
  };

  this.reset = function () {
    _fired = false;

    this.stop();

    _helperTimer = setTimeout(function () {
      _fired = true;

      if (_callback)
        _callback();
    }, timeout);
  };

  this.setCallback = function (callback) {
    _callback = callback;
  };

  this.stop = () => {
    if (_helperTimer)
      clearTimeout(_helperTimer);
  };

  this.reset();
}

HelperIdleTimer.getSharedInstance = function () {
  if (!HelperIdleTimer.sharedInstance)
    HelperIdleTimer.sharedInstance = new HelperIdleTimer(120  * 1000);

  return HelperIdleTimer.sharedInstance;
};
