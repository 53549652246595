import { BaseWhiteboardTool, inherit } from './base-tool';
import Whiteboard from '../whiteboard';
import './unifix-cubes.css';
import '../images/Merge_Left.svg';
import '../images/Merge_Right.svg';
import '../images/Cube_Blue.svg';
import '../images/Cube_Red.svg';
import '../images/Cube_Grey.svg';
import '../images/Cube_Green.svg';
import '../images/CubeIcon.svg';

export default function UnifixCubeTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, isHorizontal, color, strokeColor) {
      this.id = whiteboard.getNextId();
      this.type = UnifixCubeTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.isHorizontal = isHorizontal;

      if (color)
        this.color = color;

      if (strokeColor)
        this.strokeColor = strokeColor;

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isTransformable = function () {
    return false;
  };

  this.isRotatable = function () {
    return false;
  };

  this.setStyles = function (obj) {
    UnifixCubeTool.setCubeStyles(whiteboard, obj);
  };

  this.draw = function (obj) {
    UnifixCubeTool.drawUnifixCube(whiteboard, obj.coordinates[0], obj.coordinates[1], obj.coordinates[2], obj.coordinates[3], false, obj.isHorizontal);
  };

  function tryToSnapTogetherVertically (obj, cube, xDistanceThreshold, yDistanceThreshold, topHeight) {
    var sx, sy, ex, ey, height, cubeHeight, constructor, stack, newHeight;

    if (obj.type === UnifixCubeTool.type) {
      if (Math.abs(obj.coordinates[0] - cube.coordinates[0]) <= xDistanceThreshold) { // aligned horizontally
        if (Math.abs(obj.coordinates[1] - cube.coordinates[3]) <= yDistanceThreshold) { // aligned above
          sx = obj.coordinates[0];
          sy = obj.coordinates[1] + topHeight - (obj.coordinates[3] - obj.coordinates[1]);
          ex = obj.coordinates[2];
          ey = obj.coordinates[3];

          constructor = new UnifixCubeStackTool(whiteboard).getDataConstructor();
          stack = new constructor(sx, sy, ex, ey, [{ color: obj.color, strokeColor: obj.strokeColor }, { color: cube.color, strokeColor: cube.strokeColor }], obj.isHorizontal);
          whiteboard.deleteEvent(cube);
          whiteboard.deleteEvent(obj);
          whiteboard.addEvent(stack);

          return stack;
        } else if (Math.abs(obj.coordinates[3] - cube.coordinates[1]) <= yDistanceThreshold) { // aligned below
          height = cube.coordinates[3] - cube.coordinates[1];

          sx = obj.coordinates[0];
          sy = obj.coordinates[1];
          ex = obj.coordinates[2];
          ey = obj.coordinates[3] + height - topHeight;

          constructor = new UnifixCubeStackTool(whiteboard).getDataConstructor();
          stack = new constructor(sx, sy, ex, ey, [{ color: cube.color, strokeColor: cube.strokeColor }, { color: obj.color, strokeColor: obj.strokeColor }], obj.isHorizontal);
          whiteboard.deleteEvent(cube);
          whiteboard.deleteEvent(obj);
          whiteboard.addEvent(stack);

          return stack;
        }
      }
    } else if (obj.type === UnifixCubeStackTool.type) {
      if (Math.abs(obj.coordinates[0] - cube.coordinates[0]) <= xDistanceThreshold) { // aligned horizontally
        if (Math.abs(obj.coordinates[1] - cube.coordinates[3]) <= yDistanceThreshold) { // aligned above
          height = obj.coordinates[3] - obj.coordinates[1];
          cubeHeight = cube.coordinates[3] - cube.coordinates[1];

          obj.cubeColors.push({ color: cube.color, strokeColor: cube.strokeColor });

          newHeight = height + cubeHeight - topHeight;

          obj.coordinates[1] = obj.coordinates[3] - newHeight;

          whiteboard.deleteEvent(cube);

          return obj;
        } else if (Math.abs(obj.coordinates[3] - cube.coordinates[1]) <= yDistanceThreshold) { // aligned below
          height = obj.coordinates[3] - obj.coordinates[1];
          cubeHeight = cube.coordinates[3] - cube.coordinates[1];

          obj.cubeColors.unshift({ color: cube.color, strokeColor: cube.strokeColor });

          newHeight = height + cubeHeight - topHeight;

          obj.coordinates[3] = obj.coordinates[1] + newHeight;

          whiteboard.deleteEvent(cube);

          return obj;
        }
      }
    }
  }

  function tryToSnapTogetherHorizontally (obj, cube, xDistanceThreshold, yDistanceThreshold, topWidth) {
    var sx, sy, ex, ey, width, cubeWidth, constructor, stack, newWidth;

    if (obj.type === UnifixCubeTool.type) {
      if (Math.abs(obj.coordinates[1] - cube.coordinates[1]) <= yDistanceThreshold) { // aligned vertically
        if (Math.abs(obj.coordinates[0] - cube.coordinates[2]) <= xDistanceThreshold) { // aligned to the left
          width = cube.coordinates[2] - cube.coordinates[0];
          cubeWidth = obj.coordinates[2] - obj.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          sx = obj.coordinates[2] - newWidth;
          sy = obj.coordinates[1];
          ex = obj.coordinates[2];
          ey = obj.coordinates[3];

          constructor = new UnifixCubeStackTool(whiteboard).getDataConstructor();
          stack = new constructor(sx, sy, ex, ey, [{ color: cube.color, strokeColor: cube.strokeColor }, { color: obj.color, strokeColor: obj.strokeColor }], obj.isHorizontal);
          whiteboard.deleteEvent(cube);
          whiteboard.deleteEvent(obj);
          whiteboard.addEvent(stack);

          return stack;
        } else if (Math.abs(obj.coordinates[2] - cube.coordinates[0]) <= xDistanceThreshold) { // aligned to the right
          width = cube.coordinates[2] - cube.coordinates[0];
          cubeWidth = obj.coordinates[2] - obj.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          sx = obj.coordinates[0];
          sy = obj.coordinates[1];
          ex = obj.coordinates[0] + newWidth;
          ey = obj.coordinates[3];

          constructor = new UnifixCubeStackTool(whiteboard).getDataConstructor();
          stack = new constructor(sx, sy, ex, ey, [{ color: obj.color, strokeColor: obj.strokeColor }, { color: cube.color, strokeColor: cube.strokeColor }], obj.isHorizontal);
          whiteboard.deleteEvent(cube);
          whiteboard.deleteEvent(obj);
          whiteboard.addEvent(stack);

          return stack;
        }
      }
    } else if (obj.type === UnifixCubeStackTool.type) {
      if (Math.abs(obj.coordinates[1] - cube.coordinates[1]) <= yDistanceThreshold) { // aligned vertically
        if (Math.abs(obj.coordinates[0] - cube.coordinates[2]) <= xDistanceThreshold) { // aligned to the left
          width = obj.coordinates[2] - obj.coordinates[0];
          cubeWidth = cube.coordinates[2] - cube.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          obj.cubeColors.unshift({ color: cube.color, strokeColor: cube.strokeColor });

          obj.coordinates[0] = obj.coordinates[2] - newWidth;

          whiteboard.deleteEvent(cube);

          return obj;
        } else if (Math.abs(obj.coordinates[2] - cube.coordinates[0]) <= xDistanceThreshold) { // aligned to the right
          width = obj.coordinates[2] - obj.coordinates[0];
          cubeWidth = cube.coordinates[2] - cube.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          obj.cubeColors.push({ color: cube.color, strokeColor: cube.strokeColor });

          obj.coordinates[2] = obj.coordinates[0] + newWidth;

          whiteboard.deleteEvent(cube);

          return obj;
        }
      }
    }
  }

  function tryToSnapTogether (obj, cube, xDistanceThreshold, yDistanceThreshold, topWidth, topHeight) {
    if (obj.isHorizontal && cube.isHorizontal)
      return tryToSnapTogetherHorizontally(obj, cube, xDistanceThreshold, yDistanceThreshold, topWidth);
    else if (!obj.isHorizontal && !cube.isHorizontal)
      return tryToSnapTogetherVertically(obj, cube, xDistanceThreshold, yDistanceThreshold, topHeight);
  }

  this.finishMoving = function (cube) {
    var distanceThreshold = 30;
    var xDistanceThreshold = distanceThreshold / whiteboard.getWidth();
    var yDistanceThreshold = distanceThreshold / whiteboard.getHeight();
    var topWidth = 10 / whiteboard.getWidth();
    var topHeight = 10 / whiteboard.getHeight();

    var found = Whiteboard.crawlEvents(whiteboard.getEventsSinceLastClear(whiteboard.getEventsForCurrentPage(), false), function (obj) {
      if (obj !== cube && (obj.type == UnifixCubeStackTool.type || obj.type == UnifixCubeTool.type)) {
        var result = tryToSnapTogether(obj, cube, xDistanceThreshold, yDistanceThreshold, topWidth, topHeight);

        if (result)
          return result;
      }
    });

    if (found) {
      whiteboard.redraw();

      return found;
    } else {
      return undefined;
    }
  };
}

UnifixCubeTool.setCubeStyles = function (whiteboard, obj) {
  var color = obj.color;

  if (!color)
    color = '#2aa6db';

  var strokeColor = obj.strokeColor;

  if (!strokeColor)
    strokeColor = '#1e9dd3';

  whiteboard.setStrokeColor(strokeColor);
  whiteboard.setFillColor(color);

  whiteboard.context.lineWidth = 4;
};

UnifixCubeTool.drawUnifixCube = function (whiteboard, sx, sy, ex, ey, isConnected, isHorizontal) {
  sx *= whiteboard.getWidth();
  sy *= whiteboard.getHeight();
  ex *= whiteboard.getWidth();
  ey *= whiteboard.getHeight();

  var width = ex - sx;
  var height = ey - sy;
  var borderRadius = .04 * width;
  var topHeight = 10;
  var padding = 2;
  var topWidth;
  var rightX, rightY, bottomRightX, bottomRightY, bottomX, bottomY, bottomLeftX, bottomLeftY, leftX, leftY, topLeftX, topLeftY, topX, topY, topRightX, topRightY;

  whiteboard.context.beginPath();

  if (isHorizontal) {
    topWidth = 0.7 * height;

    if (isConnected) {
      /*
          ______________
         /              \ __start/end
         |              |
         |              |
         \______________/
       */

      rightX = ex - topHeight - padding;
      rightY = sy + padding + borderRadius;
      bottomRightX = ex - padding - topHeight;
      bottomRightY = ey - padding;
      bottomX = sx + padding;
      bottomY = ey - padding;
      bottomLeftX = sx + padding;
      bottomLeftY = ey - padding;
      leftX = sx + padding;
      leftY = sy + padding + borderRadius;
      topLeftX = sx + padding;
      topLeftY = sy + padding;
      topX = sx + padding + borderRadius;
      topY = sy + padding;
      topRightX = ex - padding - topHeight;
      topRightY = sy + padding;

      // right
      whiteboard.context.moveTo(rightX, rightY);
      whiteboard.context.lineTo(rightX, bottomRightY - borderRadius); // right line
      whiteboard.context.arcTo(bottomRightX, bottomRightY, bottomRightX - borderRadius, bottomRightY, borderRadius); // bottom right corner

      // bottom
      whiteboard.context.lineTo(bottomX, bottomY); // bottom line
      whiteboard.context.arcTo(bottomLeftX, bottomLeftY, leftX, bottomLeftY - borderRadius, borderRadius); // bottom left corner

      // left
      whiteboard.context.lineTo(leftX, leftY); // left line
      whiteboard.context.arcTo(topLeftX, topLeftY, topX, topLeftY, borderRadius); // top left corner

      // top
      whiteboard.context.lineTo(topRightX - borderRadius, topY); // top line
      whiteboard.context.arcTo(topRightX, topRightY, topRightX, topRightY + borderRadius, borderRadius); // top right corner
    } else {
      /*
          _______________
         /              |__  __start/end
         |                 |
         |               __|
         \______________|
       */

      rightX = ex - padding;
      rightY = sy + ((height - topWidth) / 2) + borderRadius;
      var innerRightX = rightX - topHeight;
      var innerRightY = sy + padding + borderRadius;
      bottomRightX = ex - padding - topHeight;
      bottomRightY = ey - padding;
      bottomX = sx + padding;
      bottomY = ey - padding;
      bottomLeftX = sx + padding;
      bottomLeftY = ey - padding;
      leftX = sx + padding;
      leftY = sy + padding + borderRadius;
      topLeftX = sx + padding;
      topLeftY = sy + padding;
      topX = sx + padding + borderRadius;
      topY = sy + padding;
      topRightX = ex - padding - topHeight;
      topRightY = sy + padding;

      // right side
      whiteboard.context.moveTo(rightX, rightY);
      whiteboard.context.lineTo(rightX, ey - ((height - topWidth) / 2));
      whiteboard.context.arcTo(rightX, ey - ((height - topWidth) / 2), rightX - borderRadius, ey - ((height - topWidth) / 2), borderRadius);
      whiteboard.context.lineTo(innerRightX, ey - ((height - topWidth) / 2));
      whiteboard.context.lineTo(innerRightX, bottomRightY - borderRadius);
      whiteboard.context.arcTo(bottomRightX, bottomRightY, bottomRightX - borderRadius, bottomRightY, borderRadius);

      // bottom
      whiteboard.context.lineTo(bottomX, bottomY);
      whiteboard.context.arcTo(bottomLeftX, bottomLeftY, bottomLeftX, bottomLeftY - borderRadius, borderRadius);

      // left
      whiteboard.context.lineTo(leftX, leftY);
      whiteboard.context.arcTo(topLeftX, topLeftY, topX, topLeftY, borderRadius);

      // top
      whiteboard.context.lineTo(innerRightX - borderRadius, topY);
      whiteboard.context.arcTo(topRightX, topRightY, topRightX, innerRightY, borderRadius);

      // top right
      whiteboard.context.lineTo(innerRightX, rightY - borderRadius);
      whiteboard.context.lineTo(rightX - borderRadius, rightY - borderRadius);
      whiteboard.context.arcTo(rightX, rightY - borderRadius, rightX, rightY, borderRadius);
    }
  } else {
    topWidth = 0.7 * width;

    if (isConnected) {
      whiteboard.context.moveTo(padding + sx + borderRadius, padding + sy + topHeight);
      whiteboard.context.lineTo(ex - borderRadius - padding, padding + sy + topHeight); // top line
      whiteboard.context.arcTo(ex - padding, padding + sy + topHeight, ex - padding, padding + sy + borderRadius + topHeight, borderRadius); // top right corner
      whiteboard.context.lineTo(ex - padding, ey - padding - borderRadius); // right line
      whiteboard.context.arcTo(ex - padding, ey - padding, ex - borderRadius - padding, ey - padding, borderRadius); // bottom right corner
      whiteboard.context.lineTo(padding + sx + borderRadius, ey - padding); // bottom line
      whiteboard.context.arcTo(padding + sx, ey - padding, padding + sx, ey - borderRadius - padding, borderRadius); // bottom left corner
      whiteboard.context.lineTo(padding + sx, padding + sy + borderRadius + topHeight); // left line
      whiteboard.context.arcTo(padding + sx, padding + sy + topHeight, padding + sx + borderRadius, padding + sy + topHeight, borderRadius); // top left corner
    } else {
      whiteboard.context.moveTo(padding + sx + ((width - topWidth) / 2) + borderRadius, padding + sy);
      whiteboard.context.lineTo(ex - ((width - topWidth) / 2) - borderRadius - padding, padding + sy);
      whiteboard.context.arcTo(ex - ((width - topWidth) / 2) - padding, padding + sy, ex - ((width - topWidth) / 2) - padding, padding + sy + borderRadius, borderRadius);
      whiteboard.context.lineTo(ex - ((width - topWidth) / 2) - padding, padding + sy + topHeight);
      whiteboard.context.lineTo(ex - borderRadius - padding, padding + sy + topHeight);
      whiteboard.context.arcTo(ex - padding, padding + sy + topHeight, ex - padding, padding + sy + topHeight + borderRadius, borderRadius);
      whiteboard.context.lineTo(ex - padding, ey - borderRadius - padding);
      whiteboard.context.arcTo(ex - padding, ey - padding, ex - borderRadius - padding, ey - padding, borderRadius);
      whiteboard.context.lineTo(padding + sx + borderRadius, ey - padding);
      whiteboard.context.arcTo(padding + sx, ey - padding, padding + sx, ey - borderRadius - padding, borderRadius);
      whiteboard.context.lineTo(padding + sx, padding + sy + topHeight + borderRadius);
      whiteboard.context.arcTo(padding + sx, padding + sy + topHeight, padding + sx + borderRadius, padding + sy + topHeight, borderRadius);
      whiteboard.context.lineTo(padding + sx + ((width - topWidth) / 2), padding + sy + topHeight);
      whiteboard.context.lineTo(padding + sx + ((width - topWidth) / 2), padding + sy + borderRadius);
      whiteboard.context.arcTo(padding + sx + ((width - topWidth) / 2), padding + sy, padding + sx + ((width - topWidth) / 2) + borderRadius, padding + sy, borderRadius);
    }
  }

  whiteboard.context.closePath();
  whiteboard.context.fill();
  whiteboard.context.stroke();
};

UnifixCubeTool.type = 'unifixcube';

inherit(UnifixCubeTool, BaseWhiteboardTool);

// cube stack

export function UnifixCubeStackTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, cubeColors, isHorizontal) {
      this.id = whiteboard.getNextId();
      this.type = UnifixCubeStackTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.cubeColors = cubeColors;
      this.isHorizontal = isHorizontal;

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isTransformable = function () {
    return false;
  };

  this.isRotatable = function () {
    return false;
  };

  this.draw = function (obj) {
    var bounds, sx, ex, sy, ey;

    bounds = UnifixCubeStackTool.getCubeBoundsInStack(whiteboard, obj, 0);

    if (obj.isHorizontal) {
      sx = bounds.left;
      ex = bounds.right;
      sy = obj.coordinates[1];
      ey = obj.coordinates[3];
    } else {
      sx = obj.coordinates[0];
      ex = obj.coordinates[2];
      sy = bounds.top;
      ey = bounds.bottom;
    }

    UnifixCubeTool.setCubeStyles(whiteboard, obj.cubeColors[0]);
    UnifixCubeTool.drawUnifixCube(whiteboard, sx, sy, ex, ey, obj.cubeColors.length > 1, obj.isHorizontal);

    for (var i = 1; i < obj.cubeColors.length; i++) {
      var cube = obj.cubeColors[i];

      bounds = UnifixCubeStackTool.getCubeBoundsInStack(whiteboard, obj, i);

      if (obj.isHorizontal) {
        sx = bounds.left;
        ex = bounds.right;
      } else {
        sy = bounds.top;
        ey = bounds.bottom;
      }

      UnifixCubeTool.setCubeStyles(whiteboard, cube);
      UnifixCubeTool.drawUnifixCube(whiteboard, sx, sy, ex, ey, i < obj.cubeColors.length - 1, obj.isHorizontal);
    }
  };

  function tryToSnapTogetherVertically (obj, stack, xDistanceThreshold, yDistanceThreshold, topHeight) {
    var height, cubeHeight, stackWidth, newHeight, stackHeight;

    if (obj.type === UnifixCubeTool.type) {
      if (Math.abs(obj.coordinates[0] - stack.coordinates[0]) <= xDistanceThreshold) { // aligned horizontally
        if (Math.abs(obj.coordinates[1] - stack.coordinates[3]) <= yDistanceThreshold) { // aligned above
          height = stack.coordinates[3] - stack.coordinates[1];
          cubeHeight = obj.coordinates[3] - obj.coordinates[1];
          newHeight = height + cubeHeight - topHeight;
          stackWidth = stack.coordinates[2] - stack.coordinates[0];

          stack.cubeColors.unshift({ color: obj.color, strokeColor: obj.strokeColor });

          stack.coordinates[0] = obj.coordinates[0];
          stack.coordinates[1] = obj.coordinates[3] - newHeight;
          stack.coordinates[2] = stack.coordinates[0] + stackWidth;
          stack.coordinates[3] = obj.coordinates[3];

          whiteboard.deleteEvent(obj);

          return stack;
        } else if (Math.abs(obj.coordinates[3] - stack.coordinates[1]) <= yDistanceThreshold) { // aligned below
          height = stack.coordinates[3] - stack.coordinates[1];
          cubeHeight = obj.coordinates[3] - obj.coordinates[1];
          newHeight = height + cubeHeight - topHeight;
          stackWidth = stack.coordinates[2] - stack.coordinates[0];

          stack.cubeColors.push({ color: obj.color, strokeColor: obj.strokeColor });

          stack.coordinates[0] = obj.coordinates[0];
          stack.coordinates[1] = obj.coordinates[1];
          stack.coordinates[2] = stack.coordinates[0] + stackWidth;
          stack.coordinates[3] = stack.coordinates[1] + newHeight;

          whiteboard.deleteEvent(obj);

          return stack;
        }
      }
    } else if (obj.type === UnifixCubeStackTool.type) {
      if (Math.abs(obj.coordinates[0] - stack.coordinates[0]) <= xDistanceThreshold) { // aligned horizontally
        if (Math.abs(obj.coordinates[1] - stack.coordinates[3]) <= yDistanceThreshold) { // aligned above
          height = obj.coordinates[3] - obj.coordinates[1];
          stackHeight = stack.coordinates[3] - stack.coordinates[1];
          newHeight = height + stackHeight - topHeight;

          stack.cubeColors.forEach(function (cube) {
            obj.cubeColors.push(cube);
          });

          obj.coordinates[1] = obj.coordinates[3] - newHeight;

          whiteboard.deleteEvent(stack);

          return obj;
        } else if (Math.abs(obj.coordinates[3] - stack.coordinates[1]) <= yDistanceThreshold) { // aligned below
          height = obj.coordinates[3] - obj.coordinates[1];
          stackHeight = stack.coordinates[3] - stack.coordinates[1];
          newHeight = height + stackHeight - topHeight;

          obj.cubeColors = stack.cubeColors.concat(obj.cubeColors);

          obj.coordinates[3] = obj.coordinates[1] + newHeight;

          whiteboard.deleteEvent(stack);

          return obj;
        }
      }
    }
  }

  function tryToSnapTogetherHorizontally (obj, stack, xDistanceThreshold, yDistanceThreshold, topWidth) {
    var width, cubeWidth, newWidth, stackWidth;

    if (obj.type === UnifixCubeTool.type) {
      if (Math.abs(obj.coordinates[1] - stack.coordinates[1]) <= yDistanceThreshold) { // aligned vertically
        if (Math.abs(obj.coordinates[0] - stack.coordinates[2]) <= xDistanceThreshold) { // aligned to the left
          width = stack.coordinates[2] - stack.coordinates[0];
          cubeWidth = obj.coordinates[2] - obj.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          stack.cubeColors.push({ color: obj.color, strokeColor: obj.strokeColor });

          stack.coordinates[0] = obj.coordinates[2] - newWidth;
          stack.coordinates[1] = obj.coordinates[1];
          stack.coordinates[2] = obj.coordinates[2];
          stack.coordinates[3] = obj.coordinates[3];

          whiteboard.deleteEvent(obj);

          return stack;
        } else if (Math.abs(obj.coordinates[2] - stack.coordinates[0]) <= xDistanceThreshold) { // aligned to the right
          width = stack.coordinates[2] - stack.coordinates[0];
          cubeWidth = obj.coordinates[2] - obj.coordinates[0];
          newWidth = width + cubeWidth - topWidth;

          stack.cubeColors.unshift({ color: obj.color, strokeColor: obj.strokeColor });

          stack.coordinates[0] = obj.coordinates[0];
          stack.coordinates[1] = obj.coordinates[1];
          stack.coordinates[2] = stack.coordinates[0] + newWidth;
          stack.coordinates[3] = obj.coordinates[3];

          whiteboard.deleteEvent(obj);

          return stack;
        }
      }
    } else if (obj.type === UnifixCubeStackTool.type) {
      if (Math.abs(obj.coordinates[1] - stack.coordinates[1]) <= yDistanceThreshold) { // aligned vertically
        if (Math.abs(obj.coordinates[0] - stack.coordinates[2]) <= xDistanceThreshold) { // aligned to the left
          width = obj.coordinates[2] - obj.coordinates[0];
          stackWidth = stack.coordinates[2] - stack.coordinates[0];
          newWidth = width + stackWidth - topWidth;

          obj.cubeColors = stack.cubeColors.concat(obj.cubeColors);

          obj.coordinates[0] = obj.coordinates[2] - newWidth;

          whiteboard.deleteEvent(stack);

          return obj;
        } else if (Math.abs(obj.coordinates[2] - stack.coordinates[0]) <= xDistanceThreshold) { // aligned to the right
          width = obj.coordinates[2] - obj.coordinates[0];
          stackWidth = stack.coordinates[2] - stack.coordinates[0];
          newWidth = width + stackWidth - topWidth;

          stack.cubeColors.forEach(function (cube) {
            obj.cubeColors.push(cube);
          });

          obj.coordinates[2] = obj.coordinates[0] + newWidth;

          whiteboard.deleteEvent(stack);

          return obj;
        }
      }
    }
  }

  function tryToSnapTogether (obj, stack, xDistanceThreshold, yDistanceThreshold, topWidth, topHeight) {
    if (obj.isHorizontal && stack.isHorizontal)
      return tryToSnapTogetherHorizontally(obj, stack, xDistanceThreshold, yDistanceThreshold, topWidth);
    else if (!obj.isHorizontal && !stack.isHorizontal)
      return tryToSnapTogetherVertically(obj, stack, xDistanceThreshold, yDistanceThreshold, topHeight);
  }

  this.finishMoving = function (stack) {
    var distanceThreshold = 30;
    var xDistanceThreshold = distanceThreshold / whiteboard.getWidth();
    var yDistanceThreshold = distanceThreshold / whiteboard.getHeight();
    var topWidth = 10 / whiteboard.getWidth();
    var topHeight = 10 / whiteboard.getHeight();

    var found = Whiteboard.crawlEvents(whiteboard.getEventsSinceLastClear(whiteboard.getEventsForCurrentPage(), false), function (obj) {
      if (obj !== stack && (obj.type == UnifixCubeStackTool.type || obj.type == UnifixCubeTool.type)) {
        var result = tryToSnapTogether(obj, stack, xDistanceThreshold, yDistanceThreshold, topWidth, topHeight);

        if (result)
          return result;
      }
    });

    if (found) {
      whiteboard.redraw();

      return found;
    } else {
      return undefined;
    }
  };
}

UnifixCubeStackTool.type = 'unifixcubestack';

inherit(UnifixCubeStackTool, BaseWhiteboardTool);

UnifixCubeStackTool.getCubeBoundsInStack = function (whiteboard, stack, i) {
  var topHeight;

  if (stack.isHorizontal) {
    var width = stack.coordinates[2] - stack.coordinates[0];
    topHeight = 10 / whiteboard.getWidth();
    var cubeWidth = (width + (topHeight * (stack.cubeColors.length - 1))) / stack.cubeColors.length;
    var sx = stack.coordinates[0] + ((cubeWidth - topHeight) * i);
    var ex = sx + cubeWidth;

    return {
      left: sx,
      right: ex,
      top: stack.coordinates[1],
      bottom: stack.coordinates[3]
    };
  } else {
    var height = stack.coordinates[3] - stack.coordinates[1];
    topHeight = 10 / whiteboard.getHeight();
    var cubeHeight = (height + (topHeight * (stack.cubeColors.length - 1))) / stack.cubeColors.length;
    var sy = stack.coordinates[1] + ((cubeHeight - topHeight) * (stack.cubeColors.length - 1 - i));
    var ey = sy + cubeHeight;

    return {
      left: stack.coordinates[0],
      right: stack.coordinates[2],
      top: sy,
      bottom: ey
    };
  }
};
