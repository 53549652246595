import 'src/app/settings/settingsController';
import 'src/components/shared/base';
import 'src/components/shared/user_data';
import 'src/components/shared/teacher_data';
import 'src/app/navigation/teachernavbarController';
import 'core/settings/studentSettingsDirective';
import 'core/settings/teacherSettingsDirective';
import '../../app/config.provider';

function SettingsController ($scope, $controller, UserManager, $state, CueThinkConfig) {
	"ngInject";

	$controller('BaseController', { '$scope': $scope });

	$scope.save = UserManager.saveUserSettings;
	$scope.changePassword = UserManager.changeUserPassword;
	$scope.joinClass = UserManager.joinClass;
	$scope.leaveClass = UserManager.leaveClass;
	$scope.loadSchools = UserManager.loadNearbySchools;

	$scope.viewModel = {
	  colorThemes: CueThinkConfig.colorThemes
  };

	$scope.deleteAccount = function () {
		return UserManager.deleteCurrentUser(function () {
			$scope.logOut(true);
		});
	};

	$scope.linkClever = function () {
		$state.go('linkclever');
	};

	$scope.linkGoogle = function () {
		$state.go('linkaccount', { service: 'google' });
	};

	$scope.$on('SetAvatar', function (event, url) {
    UserManager.setTemporaryAvatarUrl(url);
  });

	$scope.defaultAvatarColors = CueThinkConfig.defaultAvatarColors;
	$scope.userManager = UserManager;
}

export default SettingsController;
