import './cuethink';
import './user_data';
import './thinklet_data';
import 'config';
import _ from 'underscore';
import resources from 'resources.csv';
import 'angular-translate';
import UserManager from "./user_data";
import CueThinkConfig from "../../app/config.provider";

angular.module('teacherManager', ['cuethink', 'CueThinkConfig', 'userManager']).factory('TeacherManager', ['CueThink', 'UserManager', 'CueThinkConfig', '$q', 'ThinkletManager', function (cuethink, userManager, config, $q, thinkletManager) {
  "ngInject";

  TeacherManager.sharedInstance = new TeacherManager(cuethink, userManager, config, $q, thinkletManager);

  return TeacherManager.sharedInstance;
}]);

export default function TeacherManager (cuethink, userManager, config, $q, thinkletManager) {
  var _me = this;

  _me.loadClasses = function (success, error) {
    return cuethink.get(config.teacherClassesURL, success, error).then(function (classes) {
      classes.forEach(function (cls) {
        if (cls.students) {
          cls.students.forEach(function (student) {
            if (!student.classIds) {
              if (student.classId)
                student.classIds = [student.classId];
              else
                student.classIds = [cls.id];
            }
          });
        }
      });

      return classes;
    });
  };

  _me.loadMyClasses = function (success, error) {
    return _me.loadClasses().then(function (classes) {
      classes = classes.filter(function (cls) {
        return userManager.getUser().id === cls.createBy;
      });

      if (success)
        success(classes);

      return classes;
    }, function (result) {
      if (error)
        error(result);
    });
  };

  _me.loadClass = function (classId, success, error) {
    cuethink.get(config.loadClassURL.replace('{0}', classId), success, error);
  };

  _me.loadClassRoster = function (classId, success, error) {
    cuethink.get(config.classRosterURL.replace('{0}', classId), success, error);
  };

  _me.loadStudentsData = function (success, error) {
    cuethink.get(config.studentsDataURL, success, error);
  };

  _me.createClass = function (newClass, success, error) {
    return cuethink.postjson(config.createClassURL, newClass, function (result) {
      var user = userManager.getUser();
      var classes = user.classesCreatedByMe;

      if (!classes)
        classes = user.classesCreatedByMe = [];

      classes.push(result);
      user.classes.push(result);

      cuethink.setUser(user);

      if (success)
        success(result);
    }, error);
  };

  _me.coteachClass = function (code) {
    var data = new FormData();
    data.append('invitationCode', code);

    return cuethink.post(config.coteachClassURL, data).then(function (cls) {
      userManager.getUser().classes.push(cls);

      return cls;
    });
  };

  _me.leaveClass = function (classToLeave, success, error) {
    var data = new FormData();
    data.append('invitationCode', classToLeave.invitationCode);
    cuethink.delete(config.coteachClassURL, data, success, error);
  };

  _me.createStudent = function (student) {
    student.type = 103;
    student.expired = true;

    return cuethink.postjson(config.registerURL, student).then(function (data) {
      student.id = data.user.id;

      return student;
    });
  };

  _me.registerGoogleClassroomStudents = function (students) {
    return cuethink.postjson(config.registerGoogleClassroomStudentsURL, students);
  };

  _me.editStudent = function (student) {
    return cuethink.postjson(config.editStudentURL, student);
  };

  _me.moveStudents = function (classId, studentIds, success, error) {
    cuethink.postjson(config.moveStudentURL.replace('{0}', classId), studentIds, success, error);
  };

  _me.deleteStudent = function (studentId, classId, success, error) {
    return cuethink.postjson(config.deleteStudentURL.replace('{0}', studentId), classId, success, error);
  };

  _me.removeStudentFromMyClasses = function (id) {
    return cuethink.postjson(config.removeStudentURL.replace('{0}', id));
  };

  _me.loadStudents = function (success, error) {
    var d = $q.defer();

    cuethink.get(config.studentsURL, function (result) {
      if (success)
        success(result);

      d.resolve(result);
    }, function (err) {
      if (error)
        error(err);

      d.reject(err);
    });

    return d.promise;
  };

  _me.loadProblems = function (success, error) {
    return cuethink.get(config.teacherProblemsURL, success, error);
  };

  _me.loadProblemsFromAssignments = function () {
    var deferred = $q.defer();

    var mapAssignmentToProblem = function (assignment) {
      return {
        id: assignment.problemId,
        title: assignment.name,
        assignmentId: assignment.id
      };
    };

    var openDeferred = $q.defer();
    var openProblems;

    _me.loadOpenAssignments().then(function (assignments) {
      openProblems = _.map(assignments, mapAssignmentToProblem);

      openDeferred.resolve(closedProblems);
    }, function (result) {
      openDeferred.reject(result);
    });

    var closedDeferred = $q.defer();
    var closedProblems;

    _me.loadClosedAssignments().then(function (assignments) {
      closedProblems = _.map(assignments, mapAssignmentToProblem);

      closedDeferred.resolve(closedProblems);
    }, function (result) {
      closedDeferred.reject(result);
    });

    $q.all([openDeferred.promise, closedDeferred.promise]).then(function () {
      var result = openProblems;

      _.each(closedProblems, function (problem) {
        if (!_.find(result, { id: problem.id }))
          result.push(problem);
      });

      deferred.resolve(result);
    });

    return deferred.promise;
  };

  function getTeacherProblemsData (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = {
      titleOrCreator: searchTerm,
      orderBy: sort === 'name' ? 'p.title' : 'p.id',
      orderType: sortAscending ? 'asc' : 'desc',
      start: page,
      limit: limit
    };

    //if (languages)
    //  data.languages = languages.join(',');

    if (grades)
      data.grades = grades.join(',');

    if (standardTypes)
      data.standardTypes = standardTypes.join(',');

    if (domains)
      data.standardDomains = domains.join(',');

    if (standards)
      data.standards = standards.join(',');

    if (locale)
      data.locale = locale;

    return data;
  }

  _me.loadTeacherProblems = function (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);
    data.createdBy = userManager.getUser().id;

    return cuethink.getjson(config.problemsLiteURL, data);
  };

  _me.loadTeacherAllProblems = function (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);
    data.problemBank = 'Yes';
    data.queryAll = true;

    return cuethink.getjson(config.problemsLiteURL, data);
  };

  _me.loadTeacherBankProblems = function (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);
    data.problemBank = 'Yes';

    return cuethink.getjson(config.problemsLiteURL, data).then(function (problems) {
      return problems.filter(function (problem) {
        return problem.addToBank;
      });
    });
  };

  _me.loadTeacherSharedProblems = function (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);
    data.sharedTo = userManager.getUser().id;

    return cuethink.getjson(config.problemsLiteURL, data);
  };

  _me.loadOrigoProblems = function (searchTerm, languages, grades, module, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);

    if (!grades)
      data.grades = '';

    if (module)
      data.moduleId = module;

    return cuethink.getjson(config.origoProblemsURL, data);
  };

  _me.loadIMProblems = function (searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);

    if (!grades)
      data.grades = '';

    return cuethink.getjson(config.imProblemsURL, data).then(function (problems) {
      return problems.map(function (problem) {
        problem.grade = problem.gradeFolder;

        return problem;
      });
    });
  };

  _me.loadLearnZillionProblems = function (searchTerm, languages, grades, unit, standardTypes, domains, standards, page, limit, sort, sortAscending, locale) {
    var data = getTeacherProblemsData(searchTerm, languages, grades, standardTypes, domains, standards, page, limit, sort, sortAscending, locale);

    if (!grades)
      data.grades = '';

    if (module)
      data.UnitId = unit;

    return cuethink.getjson(config.learnZillionProblemsURL, data).then(function (problems) {
      return problems.map(function (problem) {
        problem.grade = problem.gradeFolder;

        return problem;
      });
    });
  };

  _me.loadFilteredTeacherProblems = function (teacherId, filterParams, success, error) {
    return cuethink.getjson(config.teacherProblemsIdURL.replace('{0}', teacherId), filterParams, success, error);
  };

  _me.loadFilteredCuethinkProblems = function (filterParams, success, error) {
    if (!filterParams)
      filterParams = {};

    filterParams.problemBank = 'yes';

    return cuethink.getjson(config.cuethinkProblemsURL, filterParams, success, error);
  };

  function getDataForAssignment (problemId, startDate, endDate, classIds, students) {
    var data = {
      problemId: problemId,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      classIds: classIds,
      students: students
    };

    if (problemId)
      data.problemId = problemId;

    return data;
  }

  _me.submitAssignment = function (problemId, startDate, endDate, classIds, students) {
    return cuethink.postjson(config.createAssignmentURL, getDataForAssignment(problemId, startDate, endDate, classIds, students));
  };

  _me.submitEditedAssignment = function (assignmentId, startDate, endDate, classIds, students) {
    return cuethink.putjson(config.editAssignmentURL.replace('{0}', assignmentId), getDataForAssignment(null, startDate, endDate, classIds, students));
  };

  _me.getDefaultReviewChecklist = function () {
    return ['DOES_MY_ANSWER_MAKE_SENSE', 'DID_I_INCLUDE_UNITS', 'DID_I_CHECK_MY_WORK', 'COULD_SOMEONE_SEE_HOW_I_FOUND_MY_ANSWER', 'DID_I_SHOW_HOW_THE_WORK_AND_PLAN_CONNECT'];
  };

  function getDataForProblemSubmission (addToBank, title, questionSource, sourceDetail, question, answer, state, grade, domainIds, standards, checkList, strategies, images) {
    var result = {
      title: title,
      question: question,
      answer: answer
    };

    var i;

    if (standards) {
      var standardIds = [];

      standards.forEach(function (standard) {
        standardIds.push(standard.id + '_' + standard.state);
      });

      result.standard = standardIds.join(',');

      result.addToBank = false;
      result.gradeBand = '101'; //placeholder since gradeBand is no longer needed
      result.grade = grade;
      result.imgList = images;

      if (result.imgList)
        for (i = 0; i < result.imgList.length; i++)
          result.imgList[i] = thinkletManager.getPathFromMediaUrl(result.imgList[i]);
    } else {
      result.questionImage = images;
      result.answerImage = null;

      if (result.questionImage)
        for (i = 0; i < result.questionImage.length; i++)
          result.questionImage[i] = thinkletManager.getPathFromMediaUrl(result.questionImage[i]);
    }

    if (sourceDetail)
      result.sourceDetail = sourceDetail;

    if (questionSource)
      result.questionSource = questionSource;

    if (domainIds) {
      result.root_standard = domainIds.join(',');
      result.topic = domainIds.length > 0 ? domainIds[0] : null;
    }

    if (state)
      result.state = state;

    if (strategies)
      result.strategyCheckList = strategies.join('\n');

    if (checkList)
      result.genericCheckList = checkList.join('\n');

    if (addToBank)
      result.addToBank = true;

    return result;
  }

  _me.submitProblem = function (addToBank, title, questionSource, sourceDetail, question, answer, state, grade, domainIds, standards, checkList, strategies, images, translations) {
    var problem = getDataForProblemSubmission(addToBank, title, questionSource, sourceDetail, question, answer, state, grade, domainIds, standards, checkList, strategies, images);

    if (translations) {
      translations = translations.map(function (translation) {
        var result = getDataForProblemSubmission(translation.addToBank, translation.title, translation.questionSource, translation.sourceDetail, translation.question, translation.answer, translation.state, translation.grade, translation.domainIds, translation.standards, translation.checkList, translation.strategies, translation.images);
        result.locale = translation.locale;

        return result;
      });
    } else {
      translations = [];
    }

    var data = {
      problem: problem,
      problemTranslations: translations
    };

    return cuethink.postjson(config.saveProblemURL, data).then(function (response) {
      return thinkletManager.processProblem(response);
    });
  };

  _me.submitEditedProblem = function (problemId, addToBank, title, questionSource, sourceDetail, question, answer, state, grade, domainIds, standards, checkList, strategies, images, translations) {
    var problem = getDataForProblemSubmission(addToBank, title, questionSource, sourceDetail, question, answer, state, grade, domainIds, standards, checkList, strategies, images);
    problem.id = problemId;

    if (translations) {
      translations = translations.map(function (translation) {
        var result = getDataForProblemSubmission(translation.addToBank, translation.title, translation.questionSource, translation.sourceDetail, translation.question, translation.answer, translation.state, translation.grade, translation.domainIds, translation.standards, translation.checkList, translation.strategies, translation.images);
        result.locale = translation.locale;

        return result;
      });
    }

    var data = {
      problem: problem,
      problemTranslations: translations
    };

    return cuethink.putjson(config.updateProblemURL.replace('{0}', problem.id), data).then(function (response) {
      return thinkletManager.processProblem(response);
    });
  };

  _me.loadTopics = function (success, error) {
    cuethink.get(config.topicsURL, success, error);
  };

  _me.loadPurpose = function (success, error) {
    cuethink.get(config.objectivesURL, success, error);
  };

  _me.loadGrades = function (success, error) {
    cuethink.get(config.gradesURL, success, error);
  };

  _me.loadSources = function (success, error) {
    cuethink.get(config.sourcesURL, success, error);
  };

  _me.loadccssGrades = function (success, error) {
    cuethink.get(config.ccssGradesURL, success, error);
  };

  _me.loadccssCategory = function (grade, success, error) {
    cuethink.get(config.ccssCategoryURL.replace('{grade}', grade), success, error);
  };

  _me.loadccssSubCategory = function (category, success, error) {
    return cuethink.get(config.ccssSubCategoryURL.replace('{category}', category), success, error);
  };

  _me.loadccssId = function (id, success, error) {
    cuethink.get(config.ccssIdURL.replace('{id}', id), success, error);
  };
  //end content creation calls

  function processAssignment (assignment) {
    if (assignment.plan_start_date)
      assignment.startDate = assignment.plan_start_date;

    if (assignment.plan_end_date)
      assignment.endDate = assignment.plan_end_date;

    assignment.startDate = new Date(assignment.startDate);
    assignment.endDate = new Date(assignment.endDate);

    if (assignment.classNames && !Array.isArray(assignment.classNames)) {
      var names = assignment.classNames.split(',');

      assignment.classes = names.map(function (name) {
        return { name: name };
      });
    } else if (assignment.classes) {
      var classes;

      classes = assignment.classes.classes;

      if (!classes)
        classes = assignment.classes.classData;

      if (classes) {
        assignment.classes = classes.filter(function (cls) {
          return !!cls;
        });
      }
    }
  }

  function getAssignmentsParameters (problemIds, classIds, createdAtStart, sort, sortDirection, start, limit) {
    var result = {};

    if (problemIds && problemIds.length > 0)
      result.problemIds = problemIds.join(',');

    if (classIds && classIds.length > 0)
      result.classIds = classIds.join(',');

    if (createdAtStart)
      result.createdAtStart = createdAtStart;

    if (sort)
      result.orderBy = sort;

    if (sortDirection)
      result.orderType = sortDirection;

    if (typeof start !== 'undefined')
      result.start = start;

    if (typeof limit !== 'undefined')
      result.limit = limit;

    return result;
  }

  _me.loadOpenAssignments = function (problemIds, classIds, createdAtStart, sort, sortDirection, start, limit) {
    var deferred = $q.defer();

    cuethink.getjson(config.openAssignmentsURL, getAssignmentsParameters(problemIds, classIds, createdAtStart, sort, sortDirection, start, limit), function (assignments) {
      for (var i = 0; i < assignments.length; i++)
        processAssignment(assignments[i]);

      deferred.resolve(assignments);
    }, function (result) {
      deferred.reject(result);
    });

    return deferred.promise;
  };

  _me.loadClosedAssignments = function (problemIds, classIds, createdAtStart, sort, sortDirection, start, limit) {
    var deferred = $q.defer();

    cuethink.getjson(config.closedAssignmentsURL, getAssignmentsParameters(problemIds, classIds, createdAtStart, sort, sortDirection, start, limit), function (assignments) {
      for (var i = 0; i < assignments.length; i++)
        processAssignment(assignments[i]);

      deferred.resolve(assignments);
    }, function (result) {
      deferred.reject(result);
    });

    return deferred.promise;
  };

  _me.loadAssignmentStats = function (assignmentId, classId) {
    return cuethink.getjson(config.assignmentStatsURL.replace('{0}', classId).replace('{1}', assignmentId)).then(function (result) {
      result = result[0];

      if (result.studentNames)
        result = result.studentNames;
      else if (result.classNames)
        result = result.classNames;

      result = result[0];

      return result;
    });
  };

  _me.loadCoteachingAssignments = function (sortByRubric) {
    return cuethink.get(config.coteachingAssignmentsURL + (sortByRubric ? '?orderBy=rubric' : ''));
  };

  _me.loadAssignment = function (id, success, error) {
    cuethink.getjson(config.assignmentURL.replace('{0}', id), null, function (assignment) {
      // hack for typo in API response
      _.each(assignment.classNames, function (item) {
        item.completedThinkletsStudents = item.completetThinkletsStudents;
        delete item.completetThinkletsStudents;
      });

      _.each(assignment.studentNames, function (item) {
        item.completedThinkletsStudents = item.completetThinkletsStudents;
        delete item.completetThinkletsStudents;
      });

      processAssignment(assignment);

      success(assignment);
    }, error);
  };

  _me.loadSelectedAssignment = function (id, success, error) {
    cuethink.get(config.assignmentURL.replace('{0}', id), success, error);
  };

  _me.loadFilteredAssignments = function (filterParams, success, error) {
    cuethink.getjson(config.openAssignmentsURL, filterParams, success, error);
  };

  _me.closeSelectedAssignments = function (id, success, error) {
    cuethink.put(config.closeSelectedAssignmentsURL.replace('{0}', id), null, success, error);
  };

  _me.openSelectedAssignments = function (id, success, error) {
    cuethink.put(config.openSelectedAssignmentsURL.replace('{0}', id), null, success, error);
  };

  _me.deleteAssignment = function (id) {
    return cuethink.deletejson(config.deleteAssignmentURL.replace('{0}', id));
  };

  _me.changeStudentPassword = function (password, success, error) {
    cuethink.postjson(config.changeStudentPasswordURL, password, success, error);
  };

  _me.resetStudentPassword = function (user, success, error) {
    return cuethink.post(config.expirePasswordURL.replace('{0}', encodeURIComponent(user.username)), null, success, error);
  };

  _me.loadRubricStats = function (problemId, assignmentId, classIds, studentIds, version) {
    var data = { 'problemId': problemId, 'assignmentId': assignmentId };

    if (classIds)
      data.classIds = classIds.join(',');

    if (studentIds)
      data.studentIds = studentIds.join(',');

    if (version)
      data.version = version;

    return cuethink.getjson(config.rubricStatsURL, data).then(function (result) {
      result.understandList = [];
      result.planList = [];
      result.solveList = [];
      result.reviewList = [];
      result.responseList = [];
      result.answerList = [];

      var map = {};

      result.noticings_zero_namelist = _.uniq(result.noticings_zero_namelist);
      result.noticings_one_namelist = _.uniq(result.noticings_one_namelist);
      result.noticings_two_namelist = _.uniq(result.noticings_two_namelist);
      result.noticings_three_plus_namelist = _.uniq(result.noticings_three_plus_namelist);
      result.wonderings_zero_namelist = _.uniq(result.wonderings_zero_namelist);
      result.wonderings_one_namelist = _.uniq(result.wonderings_one_namelist);
      result.wonderings_two_namelist = _.uniq(result.wonderings_two_namelist);
      result.wonderings_three_plus_namelist = _.uniq(result.wonderings_three_plus_namelist);

      if (result.rubricData) {
        for (var i = 0; i < result.rubricData.length; i++) {
          var question = result.rubricData[i];
          var stats;

          stats = map[question.id];

          if (!stats) {
            var list;

            stats = { name: question.name, 'stats': {} };
            map[question.id] = stats;

            if (question.type == 'understand')
              list = result.understandList;
            else if (question.type == 'plan')
              list = result.planList;
            else if (question.type == 'solve')
              list = result.solveList;
            else if (question.type == 'review')
              list = result.reviewList;
            else if (question.type == 'answer')
              list = result.answerList;
            else if (question.type == 'response')
              list = result.responseList;

            list.push(stats);
          }

          stats.stats[question.optionId] = { count: question.thinkletCount, namelist: _.uniq(question.namelist) };
        }
      }

      return result;
    });
  };

  _me.saveRubricNotes = function (thinklet, type, notes) {
    if (!thinklet.rubric)
      thinklet.rubric = {};

    if (!thinklet.rubric.notes)
      thinklet.rubric.notes = [];

    var note = _.find(thinklet.rubric.notes, { type: type });

    if (!notes)
      notes = '';

    if (note) {
      note.note = notes;
    } else {
      note = { type: type, note: notes };

      thinklet.rubric.notes.push(note);
    }

    return cuethink.postjson(config.rubricNotesURL.replace('{0}', thinklet.id), note);
  };

  _me.loadReflectionStrategies = function () {
    return cuethink.getjson(config.reflectionStrategiesURL);
  };

  _me.saveReflection = function (assignment, problem, reflection, isShared, success, error) {
    var data = {
      assignmentId: assignment.id,
      problemId: problem.id,
      goals: reflection.goals,
      misconceptions: reflection.misconceptions,
      notes: reflection.notes,
      shared: isShared,
      strategies: reflection.strategies
    };

    cuethink.postjson(config.saveReflectionURL, data, success, error);
  };

  _me.loadReflections = function (assignment, success, error) {
    cuethink.getjson(config.loadReflectionsURL.replace('{0}', assignment.id), null, success, error);
  };

  _me.loadReflectionsForProblem = function (problem, success, error) {
    cuethink.getjson(config.loadProblemReflectionsURL.replace('{0}', problem.id), null, success ? function (reflections) {
      var result = [];

      for (var i = 0; i < reflections.length; i++) {
        var reflection = reflections[i];

        if (reflection.strategies || reflection.goals || reflection.notes || reflection.misconceptions)
          result.push(reflection);
      }

      success(result);
    } : null, error);
  };

  _me.deleteClass = function (id, success, error) {
    return cuethink.deletejson(config.deleteClassStudentsURL.replace('{0}', id), null, function () {
      return cuethink.deletejson(config.deleteClassURL.replace('{0}', id), success, error);
    }, error);
  };

  _me.archiveClass = function (classroom, success, error) {
    classroom.isArchived = true;

    return cuethink.postjson(config.archiveClassURL.replace('{0}', classroom.id), null, success, error);
  };

  _me.reactivateClass = function (classroom, success, error) {
    classroom.isArchived = false;

    return cuethink.postjson(config.reactivateClassURL.replace('{0}', classroom.id), null, success, error);
  };

  _me.loadArchivedClasses = function (success, error) {
    return cuethink.getjson(config.archivedClassesURL, null, success, error);
  };

  _me.fetchHasLoggedInBefore = function (success, error) {
    return cuethink.getjson(config.flagURL.replace('{0}', '2.0.18_login'), null, function (result) {
      if (!result)
        cuethink.postjson(config.flagURL.replace('{0}', '2.0.18_login'), null, success, error);

      if (success)
        success(result);
    }, error);
  };

  _me.fetchHasSeenTrialEndPopUp = function () {
    return cuethink.getjson(config.flagURL.replace('{0}', 'HasSeenTrialEndPopUp'));
  };

  _me.setHasSeenTrialEndPopUp = function () {
    return cuethink.postjson(config.flagURL.replace('{0}', 'HasSeenTrialEndPopUp'));
  };

  function getFlagURL (key) {
    return config.flagURL.replace('{0}', key);
  }

  _me.loadHasSeenSettingsWelcomePopUp = function () {
    var key = 'HasSeenSettingsWelcomePopUp';
    var url = getFlagURL(key);

    return cuethink.getjson(url, null, function (result) {
      if (!result)
        cuethink.postjson(url);

      return result;
    });
  };

  _me.allowAnnotations = function (student) {
    if (!student.userFlags)
      student.userFlags = {};

    student.userFlags.annotationDisabled = false;

    return cuethink.post(config.saveFlagURL.replace('{0}', student.id), { flag: 'annotationDisabled', value: false });
  };

  _me.disallowAnnotations = function (student) {
    if (!student.userFlags)
      student.userFlags = {};

    student.userFlags.annotationDisabled = true;

    return cuethink.post(config.saveFlagURL.replace('{0}', student.id), { flag: 'annotationDisabled', value: true });
  };

  _me.fetchHasSeenIncompleteReflectionsTooltip = function () {
    var d = $q.defer();

    cuethink.getjson(config.flagURL.replace('{0}', 'hasSeenIncompleteReflectionsTooltip'), null, function (result) {
      d.resolve(result);
    }, function (result) {
      d.reject(result);
    });

    return d.promise;
  };

  _me.setHasSeenIncompleteReflectionsTooltip = function () {
    var d = $q.defer();

    cuethink.postjson(config.flagURL.replace('{0}', 'hasSeenIncompleteReflectionsTooltip'), null, function (result) {
      d.resolve(result);
    }, function (result) {
      d.reject(result);
    });

    return d.promise;
  };

  var _resourceBankResources = [];

  (function () {
    resources.forEach(function (resource) {
      resource.name = resource['Name of Resource'];

      if (!resource.name)
        return false;

      resource.imageUrl = '/images/resourceBank/' + encodeURIComponent(resource.Image);
      resource.url = resource['Resource Link'];
      resource.downloadUrl = resource['Download Link'];
      resource.description = resource.Description;
      resource.topic = resource.Topic;
      resource.type = resource.Type;

      _resourceBankResources.push(resource);
    });
  })();

  _me.loadResources = function () {
    var defer = $q.defer();
    defer.resolve(_resourceBankResources);
    return defer.promise;
  };

  _me.loadEducatorGuides = function () {
    var deferred = $q.defer();

    var guides = _.map(config.subMenuUrls.courseMaterials, function (guide) {
      var result = {};

      var keys = Object.keys(guide);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        result[key] = guide[key];
      }

      return result;
    });

    deferred.resolve(guides);

    return deferred.promise;
  };

  _me.loadProblemFolders = function () {
    var defer = $q.defer();

    cuethink.get(config.problemFoldersURL).then(function (response) {
      defer.resolve(response ? JSON.parse(response) : null);
    }, function (response) {
      defer.reject(response);
    });

    return defer.promise;
  };

  _me.loadFolderProblems = function (ids) {
    return cuethink.getjson(config.folderProblemsURL, { problemIDs: ids.join(',') });
  };

  _me.saveProblemFolders = function (structure) {
    return cuethink.post(config.problemFoldersURL, { structure: JSON.stringify(structure) });
  };

  _me.createStarterAssignments = function () {
    return cuethink.post(config.starterAssignmentsURL);
  };

  _me.loadLabels = function (forCoteacher) {
    return cuethink.getjson(config.labelsURL, { coTeacherTags: forCoteacher });
  };

  _me.loadRelatedLabels = function (problemId) {
    return cuethink.get(config.relatedLabelsURL.replace('{0}', problemId));
  };

  _me.addLabel = function (thinkletId, label) {
    return cuethink.postjson(config.addLabelURL.replace('{0}', thinkletId), label).then(function (result) {
      label.id = result.id;

      return label;
    });
  };

  _me.removeLabel = function (thinkletId, label) {
    return cuethink.delete(config.removeLabelURL.replace('{0}', thinkletId).replace('{1}', label.id));
  };

  _me.saveLabel = function (label) {
    var data = {
      label: label.label,
      private: !!label.private
    };

    label.private = !!label.private;

    return cuethink.putjson(config.saveLabelURL.replace('{0}', label.id), data);
  };

  _me.deleteLabel = function (label) {
    return cuethink.delete(config.deleteLabelURL.replace('{0}', label.id));
  };

  _me.fetchSeenToolbarTutorial = function () {
    return cuethink.getjson(config.flagURL.replace('{0}', 'seenToolbarTutorial'));
  };

  _me.flagSeenToolbarTutorial = function () {
    return cuethink.postjson(config.flagURL.replace('{0}', 'seenToolbarTutorial'));
  };

  _me.fetchHasSeenProblemBankTutorial = function () {
    return cuethink.getjson(config.flagURL.replace('{0}', 'seenProblemBankTutorial'));
  };

  _me.flagHasSeenProblemBankTutorial = function () {
    return cuethink.postjson(config.flagURL.replace('{0}', 'seenProblemBankTutorial'));
  };

  _me.loadRecentlySharedProblemUsers = function () {
    return cuethink.getjson(config.recentSharedProblemUsersURL);
  };

  _me.loadSharedProblemUsers = function (problemId) {
    return cuethink.getjson(config.sharedProblemUsersURL.replace('{0}', problemId));
  };

  _me.shareProblem = function (problem, users) {
    return cuethink.putjson(config.shareProblemURL.replace('{0}', problem.id), users);
  };

  _me.loadLearningHubUnits = function () {
    var units, statuses;

    var unitsPromise = cuethink.getjson(config.learningHubUnitsURL).then(function (result) {
      units = result;
    });

    var statusPromise = _me.loadLearningHubUnitsStatus().then(function (result) {
      statuses = result;
    });

    return $q.all([unitsPromise, statusPromise]).then(function () {
      units.forEach(function (unit) {
        unit.status = _.find(statuses, { unitId: unit.id });

        if (!unit.status)
          unit.status = { unitId: unit.id };

        if (unit.status.actionResponses) {
          unit.status.actionResponseIds = unit.status.actionResponses.map(function (response) {
            return response.id;
          });
        }
      });

      return units;
    });
  };

  _me.saveLearningHubUnit = function (status) {
    if (!status.id) {
      return cuethink.postjson(config.saveLearningHubUnitURL, { userId: userManager.getUser().id, unitId: status.unitId }).then(function (result) {
        status.id = result.id;

        return _me.saveLearningHubUnit(status);
      });
    }

    var data = {
      id: status.id,
      reflectChecked: status.reflectChecked,
      tryChecked: status.tryChecked,
      viewChecked: status.viewChecked,
      watchChecked: status.watchChecked,
      teacherResponse: status.teacherResponse,
      reflectResponse: status.reflectResponse
    };

    if (status.actionResponseIds)
      data.actionResponseIds = status.actionResponseIds.join(',');

    return cuethink.putjson(config.updateLearningHubUnitURL.replace('{0}', status.id), data);
  };

  _me.loadLearningHubUnitsStatus = function () {
    return cuethink.getjson(config.learningHubUnitsStatusURL.replace('{0}', userManager.getUser().id));
  };

  _me.loadLearningHubUnitStatus = function (unitId) {
    return cuethink.getjson(config.learningHubUnitStatusURL.replace('{0}', unitId));
  };

  /*
  _me.fetchSeenToolbarTutorial = function () {
    var deferred = $q.defer();

    var user = userManager.getUser();
    deferred.resolve(user.userFlags ? user.userFlags.hasSeenToolbarTutorial : false);

    return deferred.promise;
  };

  _me.flagSeenToolbarTutorial = function (value) {
    var user = userManager.getUser();

    if (!user.userFlags)
      user.userFlags = {};

    user.userFlags.hasSeenToolbarTutorial = value;

    return cuethink.post(config.saveFlagURL.replace('{0}', user.id), { flag: 'hasSeenToolbarTutorial', value: value });
  };
  */

  _me.fetchJoinedClasses = function () {
    var deferred = $q.defer();

    cuethink.getjson(config.joinedClassesURL, null, null, function () {
      deferred.resolve([]);
    }).then(function (classes) {
      // certain user types don't have joined classes
      if (!classes)
        classes = [];

        deferred.resolve(classes);
    }, function () {
      deferred.resolve([]);
    });

    return deferred.promise;
  };

  _me.importRoster = function (file, classId) {
    var deferred = $q.defer();

    var data = new FormData();
    data.append('file', file, 'roster.csv');

    cuethink.post(config.importRosterURL.replace('{0}', classId), data, function (result) {
      deferred.resolve(parseInt(result));
    }, function (errors) {
      deferred.reject(errors);
    });

    return deferred.promise;
  };

  _me.leaveCoteachClass = function (classId) {
    return cuethink.post(config.leaveCoteachClassURL.replace('{0}', classId));
  };

  _me.editClass = function (cls) {
    var data = {
      name: cls.name,
      grade: cls.grade
    };

    return cuethink.postjson(config.editClassURL.replace('{0}', cls.id), data);
  };

  _me.loadStandardTypes = function () {
    return cuethink.getjson(config.standardTypesURL);
  };

  _me.loadStandardTypeDomains = function (grades, types) {
    return cuethink.getjson(config.standardTypeDomainsURL.replace('{0}', grades.join(',')).replace('{1}', types ? types.join(',') : 'null'));
  };

  _me.loadStandardTypeStandards = function (grades, types, domains) {
    return cuethink.getjson(config.standardTypeStandardsURL.replace('{0}', grades.join(',')).replace('{1}', types ? types.join(',') : 'null').replace('{2}', domains ? domains.join(',') : 'null'));
  };

  _me.loadProblemHasFolders = function (problem) {
    return cuethink.getjson(config.foldersForProblemURL.replace('{0}', problem.id));
  };

  _me.loadFilterProblems = function (openAssignments) {
    return cuethink.getjson(config.filterProblemsURL, { completed: !openAssignments });
  };

  _me.loadHasIncompleteReflections = function () {
    return cuethink.getjson(config.hasIncompleteReflectionsURL);
  };

  _me.fetchDontShowArchivePopUp = function () {
    return cuethink.getjson(config.flagURL.replace('{0}', 'dontShowArchivePopUp'));
  };

  _me.saveDontShowArchivePopUp = function () {
    return cuethink.postjson(config.flagURL.replace('{0}', 'dontShowArchivePopUp'));
  };

  _me.fetchDontShowLeaveClassPopUpAgain = function () {
    return cuethink.getjson(config.flagURL.replace('{0}', 'dontShowLeaveClassPopUp'));
  };

  _me.saveDontShowLeaveClassPopUpAgain = function () {
    return cuethink.postjson(config.flagURL.replace('{0}', 'dontShowLeaveClassPopUp'));
  };

  _me.fetchFacilitatorGuideNotes = function (assignmentId, step) {
    if (assignmentId === 0)
      return _me.fetchGeneralFacilitatorGuideNotes(step);

    return cuethink.getjson(config.facilitatorGuideURL.replace('{0}', assignmentId).replace('{1}', step)).then(function (response) {
      return response.note ? response.note.note : null;
    });
  };

  _me.saveFacilitatorGuideNotes = function (assignmentId, step, notes, showForAllClasses) {
    if (assignmentId === 0)
      return _me.saveGeneralFacilitatorGuideNotes(step, notes, showForAllClasses);

    const data = {
      extend: null,
      metadata: null,
      note: notes
    };

    var phaseId;

    if (step === 'understand')
      phaseId = 101;
    else if (step === 'plan')
      phaseId = 102;
    else if (step === 'solve')
      phaseId = 103;
    else if (step === 'review')
      phaseId = 104;
    else if (step === 'explore')
      phaseId = 105;

    data.assignmentId = assignmentId;
    data.classId = cuethink.getUser().selectedClass ? cuethink.getUser().selectedClass.id : null;
    data.forAllClasses = true;
    data.phaseId = phaseId;

    return cuethink.postjson(config.saveFacilitatorGuideURL, data);
  };

  _me.fetchGeneralFacilitatorGuideNotes = function (step) {
    return cuethink.getjson(config.generalFacilitatorGuideURL.replace('{0}', step)).then(function (response) {
      return response.note ? response.note : null;
    });
  };

  _me.saveGeneralFacilitatorGuideNotes = function (step, notes, showForAllClasses) {
    return cuethink.postjson(config.saveGeneralFacilitatorGuideURL.replace('{0}', step), {
      extend: null,
      location: step,
      metadata: null,
      note: notes
    });
  };
}

TeacherManager.sharedInstance = null;

TeacherManager.getShared = () => {
  return TeacherManager.sharedInstance;
};
