import '../common/commonModule';
import './rubric.css';
import tpl from './rubric.tpl.html';
import statsTpl from './rubricStatsModal.tpl.html';
import _ from 'underscore';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import { setUserModesScopeMembers } from '../common/user-modes';

function RubricDirective ($translate, $rootScope) {
    "ngInject";

    var linkFunction = function ($scope, element) {
        setUserModesScopeMembers($scope);

        $scope.viewModel = {
            studentReflection: null,
            isGraded: false,
            understandNotes: null,
            planNotes: null,
            solveNotes: null,
            reviewNotes: null
        };

        if ($scope.reset) {
            $scope.showResetPrompt = function () {
                $scope.isResetPromptVisible = true;
            };

            $scope.resetRubric = function () {
                $scope.viewModel.understandNotes = $scope.viewModel.planNotes = $scope.viewModel.solveNotes = $scope.viewModel.reviewNotes = null;

                $scope.reset($scope.thinklet);

                $scope.closeResetPrompt();
            };

            $scope.closeResetPrompt = function () {
                $scope.isResetPromptVisible = false;
            };
        }

        $scope.$watch('thinklet', function () {
            if (!$scope.thinklet || !$scope.thinklet.rubric)
                return;

            var note = _.find($scope.thinklet.rubric.notes, { type: 'understand' });

            if (note)
              $scope.viewModel.understandNotes = note.note;

            note = _.find($scope.thinklet.rubric.notes, { type: 'plan' });

            if (note)
                $scope.viewModel.planNotes = note.note;

            note = _.find($scope.thinklet.rubric.notes, { type: 'solve' });

            if (note)
                $scope.viewModel.solveNotes = note.note;

            note = _.find($scope.thinklet.rubric.notes, { type: 'review' });

            if (note)
                $scope.viewModel.reviewNotes = note.note;

            $scope.viewModel.studentReflection = $scope.thinklet.rubric.studentReflection;
        });

        $scope.onShareChange = function (isRubricShared) {
            if ($scope.shareRubric && $scope.isGraded())
                $scope.shareRubric(isRubricShared);
        };

        $scope.isThinkletComplete = function () {
          return $scope.thinklet.status === 'completed';
        };

        $scope.isGraded = function () {
            if ($scope.thinklet && $scope.thinklet.rubric) {
                var hasUnderstandGrade = $scope.thinklet.rubric.understandList.find(function (question) {
                   return !!question.optionId;
                });

                if (hasUnderstandGrade)
                    return true;

                var hasPlanGrade = $scope.thinklet.rubric.planList.find(function (question) {
                  return !!question.optionId;
                });

                if (hasPlanGrade)
                  return true;

                  var hasSolveGrade = $scope.thinklet.rubric.solveList.find(function (question) {
                    return !!question.optionId;
                  });

                  if (hasSolveGrade)
                    return true;

                  var hasReviewGrade = $scope.thinklet.rubric.reviewList.find(function (question) {
                    return !!question.optionId;
                  });

                  if (hasReviewGrade)
                    return true;

                var hasNote = [$scope.viewModel.understandNotes, $scope.viewModel.planNotes, $scope.viewModel.solveNotes, $scope.viewModel.reviewNotes].find(function (note) {
                  return note && note.length > 0;
                });

                if (hasNote)
                    return true;
            }

            return false;
         };

        function addTooltip (el, text, position, pointer, callback) {
          if (/ipad|iphone/.test(window.navigator.userAgent.toLowerCase()))
            return;

          if (!position)
            position = 'bottom center';

          if (!pointer)
            pointer = 'top left';

          var options = {
            content: text,
            position: {
              at: position,
              my: pointer
            },
            style: {
              classes: 'whiteboard-qtip',
              def: false
            },
            hide: { when: { event: 'inactive' } }
          };

          if (callback) {
            options.events = {
              show: function (event) {
                if (callback())
                  event.preventDefault();
                else
                  return true;
              }
            };
          }

          el.qtip(options);
        }

        function saveUnderstandNotes () {
          $scope.saveNotes($scope.thinklet, 'understand', $scope.viewModel.understandNotes);
        }

        function savePlanNotes () {
          $scope.saveNotes($scope.thinklet, 'plan', $scope.viewModel.planNotes);
        }

        function saveSolveNotes () {
          $scope.saveNotes($scope.thinklet, 'solve', $scope.viewModel.solveNotes);
        }

        function saveReviewNotes () {
          $scope.saveNotes($scope.thinklet, 'review', $scope.viewModel.reviewNotes);
        }

        $scope.$$postDigest(function () {
          translate('SHARE_RUBRIC_WITH_STUDENT', $translate, $rootScope, $scope, function (msg) {
            addTooltip($(element).find('.share-rubric-label'), msg, 'bottom left', 'top left', function () {
              return $scope.isGraded();
            });
          });

          autosaveField($(element).find('.understand-notes'), saveUnderstandNotes);
          autosaveField($(element).find('.plan-notes'), savePlanNotes);
          autosaveField($(element).find('.solve-notes'), saveSolveNotes);
          autosaveField($(element).find('.review-notes'), saveReviewNotes);
        });
    };

    return {
        restrict: 'E',
        template: tpl,
        link: linkFunction,
        scope: {
          close: '=',
          isEditable: '=',
          isRubricShared: '=',
          isStudent: '=',
          reset: '=',
          save: '=',
          saveNotes: '=',
          saveStudentReflection: '=',
          shareRubric: '=',
          thinklet: '=',
          user: '=',
          userName: '='
        }
    };
}

RubricDirective.showRubricStats = function ($uibModal, loadStats, problemId, assignmentId, title, classIds, className) {
    loadStats(problemId, assignmentId, classIds, null, function (rubric) {
        if (rubric.planList) {
            rubric.planList.sort(function (a, b) {
                return a.name > b.name;
            });
        }

        if (rubric.responseList) {
            rubric.responseList.sort(function (a, b) {
                return a.name > b.name;
            });
        }

        $uibModal.open({
            template: statsTpl,
            animation: false,
            controller: function ($scope) {
                "ngInject";

                $scope.title = title;
                $scope.className = className;
                $scope.rubric = rubric;
            },
            windowClass: 'rubric-stats'
        });
    });
};

RubricDirective.getCorrectSolutionResponse = function (rubric) {
    for (var i = 0; i < rubric.solveList.length; i++) {
        var question = rubric.solveList[i];
        var name = question.name.toLowerCase();

        if (name.indexOf('correct') >= 0 && name.indexOf('solution') >= 0)
            return question;
    }

    return false;
};

function autosaveField (el, save) {
  el = $(el);

  function bindEvent (element, type, handler) {
    element.on(type, handler);
    element.on('remove', function (event) {
      $(event.target).off(type, handler);
    });
  }

  var timer;

  bindEvent(el, 'input', function () {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(function () {
      save();

      timer = null;
    }, 500);
  });
}

export default RubricDirective;
