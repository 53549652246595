import 'core/navigation/navbar';
import TeacherNavBarDirective from 'core/navigation/teacherNavBar.directive';
import '../landing/introDirective';
import './teacherscreen.css';
import tpl from './teacherScreenDirective.tpl.html';

function TeacherScreenDirective () {
    "ngInject";

    return {
        restrict: 'E',
        template: tpl,
        transclude: true,
        link: function () {
        },
        scope: TeacherNavBarDirective.createScope()
    };
}

TeacherScreenDirective.createScope = TeacherNavBarDirective.createScope;

angular.module('cuethink.teacherscreen', ['cuethink.landing', 'cuethink.navigation']).directive('teacherscreen', TeacherScreenDirective);

export default TeacherScreenDirective;
