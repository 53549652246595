import 'angular-translate';
import './facilitatorGuideDirective.css';
import tpl from './facilitatorGuideDirective.tpl.html';
import './images/Close x.svg';
import './images/magnifyingglass.svg';
import './images/planicon.svg';
import './images/Review-icon.svg';
import './images/solveicon.svg';
import 'core/common/autosave';
import './images/GalleryIconYellow.svg';

export default function FacilitatorGuideDirective($translate) {
  return {
    restrict: 'E',
    link: function ($scope) {
      var text = {
        explore: {
          description: 'THE_EXPLORE_PHASE_OFFERS_A_CHANCE_FOR_ALL_STUDENTS_TO_ENTER_AND_MAKE_SENSE_OF_THE_PROBLEM_BY_NOTICING_AND_WONDERING_UNPACK_THE_PROBLEM_TOGETHER_AS_A_CLASS',
          askYourStudentsItems: [
            'CAN_YOU_PICTURE_WHATS_HAPPENING_IN_YOUR_BRAIN',
            'HOW_IS_THIS_PROBLEM_SIMILAR_TO_SOMETHING_IN_YOUR_LIFE',
            'WHAT_QUESTIONS_DO_YOU_HAVE',
          ],
          lookAndListenForItems: [
            'PARTS_OF_THE_PROBLEM_THAT_STAND_OUT',
            'CONNECTIONS_TO_PRIOR_KNOWLEDGE_AND_EXPERIENCES',
            'IDEAS_FOR_GETTING_STARTED'
          ]
        },
        gallery: {
          description: 'THE_CLASS_GALLERY_IS_A_DIGITAL_BULLETIN_BOARD_TO_USE_DURING_WHOLE_CLASS_DISCUSSIONS_IDENTIFY_THINKLETS_TO_SHOW_DIFFERENT_WAYS_OF_SOLVING_AND_TO_CLARIFY_MISCONCEPTIONS_STUDENTS_USE_IDEAS_FROM_THE_CLASS_DISCUSSION_TO_WRITE_ANNOTATIONS',
          askYourStudentsItems: [
            'HOW_ARE_THESE_STRATEGIES_SIMILAR_OR_DIFFERENT',
            'WHAT_DO_YOU_AGREE_OR_DISAGREE_WITH_WHY',
            'SHARE_SOMETHING_THAT_GAVE_YOU_AN_AHA_MOMENT',
          ],
          lookAndListenForItems: [
            'DIFFERENT_WAYS_OF_SOLVING',
            'RESPONSES_FROM_MANY_STUDENTS',
            'USE_OF_MATHEMATICAL_LANGUAGE'
          ]
        },
        plan: {
          description: 'THE_PLAN_PHASE_GUIDES_STUDENTS_TO_DEVELOP_A_SOLUTION_PATHWAYS_WHE_SHARING_PLANNING_JOURNAL_EXAMPLES_SELECT_AT_LEAST_ONE_STUDENT_WHOSE_VOICE_IS_OFTEN_UNHEARD_TO_ENRICH_LEARNING',
          askYourStudentsItems: [
            'WHAT_IS_THE_PROBLEM_ASKING_YOU_TO_DO',
            'WHAT_COULD_YOU_TRY_FIRST',
            'WHAT_COULD_YOU_DO_IF_YOU_GET_STUCK',
            'WHAT_STRATEGIES_HAVE_YOU_USED_BEFORE_TO_SOLVE_SIMILAR_PROBLEMS'
          ],
          lookAndListenForItems: [
            'REASONING_FOR_CHOOSING_A_STRATEGY',
            'SENTENCE_FRAMES_USED_TO_ORGANIZE_THINKING',
            'CONNECTIONS_BETWEEN_THE_PLANNING_JOURNAL_AND_SELECTED_STRATEGIES'
          ]
        },
        solve: {
          description: 'THE_SOLVE_PHASE_ENCOURAGES_STUDENTS_TO_EXPERIMENT_AND_RECORD_DIFFERENT_WAYS_OF_THINKING_STUDENTS_CAN_COLLABORATE_AND_REFERENCE_OTHERS_IDEAS_FOR_DEEPER_UNDERSTANDING',
          askYourStudentsItems: [
            'IS_YOUR_STRATEGY_WORKING_IF_NOT_WHAT_ELSE_COULD_YOU_TRY',
            'LOOK_AT_YOUR_PLAN_WHAT_HAVE_YOU_ALREADY_DONE_WHAT_DO_YOU_HAVE_LEFT',
            'HOW_CAN_YOUR_EXPERIENCES_HELP_YOU_SOLVE_THE_PROBLEM'
          ],
          lookAndListenForItems: [
            'DIFFERENT_TOOLS_USED_TO_SOLVE_THE_PROBLEM',
            'ADJUSTMENTS_MADE_TO_THE_PLAN',
            'EXPLANATIONS_OF_THINKING_AND_REASONING'
          ]
        },
        review: {
          description: 'THE_REVIEW_PHASE_PROVIDES_A_PLACE_FOR_STUDENTS_TO_STOP_AND_THINKING_ABOUT_THEIR_WORK_BEFORE_SUBMITTING_HIGHLIGHT_THE_THINKING_OF_STUDENTS_WHO_MAY_NOT_YET_FEEL_CONFIDENT_IN_MATH',
          askYourStudentsItems: [
            'WHAT_IS_SOMETHING_YOU_THINK_YOU_DID_WELL',
            'WHAT_MIGHT_YOU_DO_DIFFERENTLY_NEXT_TIME',
            'HOW_DID_YOU_OVERCOME_ANY_FRUSTRATION'
          ],
          lookAndListenForItems: [
            'REFLECTIONS_FROM_WATCHING_RECORDINGS',
            'REFERENCING_OTHER_PHASES_AND_MAKING_REVISIONS_AS_NEEDED',
            'EXAMPLES_OF_PERSEVERANCE_AND_CREATIVITY'
          ]
        }
      };

      $scope.getDescription = function () {
        return text[$scope.step].description;
      };

      $scope.getAskYourStudentsItems = function () {
        return text[$scope.step].askYourStudentsItems;
      };

      $scope.getLookAndListenForItems = function () {
        return text[$scope.step].lookAndListenForItems;
      };

      $scope.onAutosave = function () {
        $scope.save({ notes: $scope.notes });
      };

      const onDocumentClick = () => {
        $scope.onClose();
      };

      document.addEventListener('click', onDocumentClick);

      $scope.$on('$destroy', () => {
        document.removeEventListener('click', onDocumentClick);
      });
    },
    scope: {
      backgroundColor: '=',
      notes: '=',
      onClose: '&close',
      save: '&',
      showForAllClasses: '=',
      step: '='
    },
    template: tpl
  }
}
