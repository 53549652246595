/**
 * UI for HTML5 Canvas Whiteboard
 *
 * Authors:
 * Antti Hukkanen
 * Kristoffer Snabb
 *
 * Aalto University School of Science and Technology
 * Course: T-111.2350 Multimediatekniikka / Multimedia Technology
 *
 * Under MIT Licence
 *
 */

import Whiteboard from './whiteboard';
import './whiteboard.css';
import _ from 'underscore';
import SpreadsheetTool from 'core/whiteboard/tools/spreadsheet-tool';
import GridTool from './tools/grid-tool';
import './images/Grid.svg';
import './images/GridFull.svg';
import './images/GridPositive.svg';
import './images/GridOpen.svg';
import NumberLineTool from './tools/number-line-tool';
import './images/CubeIcon.svg';
import './images/Cube_Blue.svg';
import './images/Cube_Green.svg';
import './images/Cube_Grey.svg';
import './images/Cube_Red.svg';
import UnifixCubeTool, {UnifixCubeStackTool} from "./tools/unifix-cube-tool";
import './images/Expand_btn.png';
import TextTool from './tools/text-tool';
import './images/HTO_Icon.svg';
import './images/10frame.svg';
import './images/WPP.svg';
import './images/LDiagramIcon.svg';
import './images/ProtractorIcon.svg';
import './images/Prev_Arrow.svg';
import './images/Next_Arrow.svg';
import './images/StopIcon.svg';
import './images/Record.svg';
import './images/PauseIcon.svg';
import './images/AlgebraTiles.svg';
import './images/algebratile1.svg';
import './images/algebratilex.svg';
import './images/algebratilex2.svg';
import './images/algebratile-1.svg';
import './images/algebratile-x.svg';
import './images/algebratile-x2.svg';
import './images/FractionIcon_blue.svg';
import './images/fractionbar1.svg';
import './images/fractionbar1-2.svg';
import './images/fractionbar1-3.svg';
import './images/fractionbar1-4.svg';
import './images/fractionbar1-5.svg';
import './images/fractionbar1-6.svg';
import './images/fractionbar1-8.svg';
import './images/fractionbar1-10.svg';
import './images/fractionbar1-12.svg';
import AlgebraTileTool from './tools/algebra-tile-tool';
import FractionBarTool from "./tools/fraction-bar-tool";
import './images/Horizontal_Arrow.svg';
import './images/Vertical_Change_Arrow.svg';
import './images/WhiteboardTrashIcon.svg';
import Mousetrap from 'mousetrap';

var EXIF = require('exif-js');

function WhiteboardUi (element, recordAudio, isReadOnly, $translate, $compile) {
  var _me = this, _whiteboard;
  var _recordButton, _stopButton, _statusLabel, _colorPicker, _shapePicker, _unifixCubePicker, _fileInput, _pageLabel, _saveCallback, _saveTimer, _lastRecordedEvents, _textField, _shouldAutosave;
  var _drawButton, _highlighterButton, _eraserButton, _shapeButton, _textButton, _selectButton, _undoButton, _redoButton, _equationButton, _protractorButton, _algebraTileButton, _fractionBarButton, _fractionBarPicker, _moreToolsButton;
  var lastX, lastY;
  var _timer, _recordAudio = recordAudio !== false, _dragged;
  var _recordingCallback;
  var _hasChanged;
  var _uploadCallback;
  var _dimensionsPicker;
  var _stampPicker;
  var _stampButton;
  var _hundredBlockButton, _oneBlockButton, _tenBlockButton;
  var _diagramButton, _diagramPicker, _placeValueMatButton, _tenFrameButton, _partPartWholeButton, _lDiagramButton;
  var _userImages;
  var _spreadsheetTool = new SpreadsheetTool(_me, $, _, $compile);
  var _deleteButton;
  var _editingEquation;
  var addingImage;
  var _labelsToggle, _labelsToggleCloseButton, _labelsToggleSwitch;
  var _cubesAdded = 0, _algebraTilesWidth = 0, _fractionBarsLeft = 0, _fractionBarsTop = 40;
  var _algebraTilePicker;
  var _algebraTile1Button, _algebraTileN1Button, _algebraTileXButton, _algebraTileNXButton, _algebraTileX2Button, _algebraTileNX2Button;
  var _fractionBar1Button, _fractionBar12Button, _fractionBar13Button, _fractionBar14Button, _fractionBar15Button, _fractionBar16Button, _fractionBar18Button, _fractionBar110Button, _fractionBar112Button;
  let _hiddenToolsEl, _moreToolsList;
  let _logCallback;
  const defaultTextFieldHeight = 37;

  element = $(element);

  element.click(() => {
    element.find('.record-helper-text').remove();
  });

  _me.getCanvasElement = function () {
    return this.canvasElement;
  };

  function onRecordFinish () {
    startAutosaving();

    $translate('READY').then(function (text) {
      _statusLabel.text(text);
    });
  }

  function onRecord (whiteboard, events, recordedEvents, audio) {
    stopAutosaving();

    if (_recordingCallback)
      _recordingCallback(whiteboard, events, recordedEvents, audio, onRecordFinish);
    else
      onRecordFinish();
  }

  function enableUndo () {
    _undoButton.removeClass('off');
  }

  _me.setUploadCallback = function (value) {
    _uploadCallback = value;
  };

  _me.setImageLoadCallback = function (value) {
    _whiteboard.setImageLoadCallback(value);
  };

  function bindStampButton (button) {
    WhiteboardUi.bindEvent(button, 'mouseup', onStampDown);
  }

  _me.setUserImages = function (value) {
    _userImages = value;

    var stampPicker = element.find('.stamps');
    stampPicker.find('.user-image').remove();

    if (!value)
      return;

    value.forEach(function (url) {
      var button = $('<button>');
      button.attr('tabindex', 0);
      button.attr('type', 'button');
      button.addClass('image-button');
      button.addClass('user-image');
      button.css('background-image', 'url(' + url + ')');
      button.css('width', '47.5px');
      button.css('height', '47.5px');
      button.css('background-size', '47.5px');
      button.css('background-repeat', 'no-repeat');
      button.css('background-position', 'top left');

      bindStampButton(button);

      stampPicker.append(button);
    });
  };

  _me.setRecordAudioStartCallback = function (value) {
    _whiteboard.setRecordAudioStartCallback(value);
  };

  _me.setRecordAudioPauseCallback = function (value) {
    _whiteboard.setRecordAudioPauseCallback(value);
  };

  _me.setRecordAudioStopCallback = function (value) {
    _whiteboard.setRecordAudioStopCallback(value);
  };

  _me.setAudioRecordingErrorCallback = function (value) {
    _whiteboard.setAudioRecordingErrorCallback(value);
  };

  _me.setLogCallback = function (value) {
    _logCallback = value;

    _whiteboard.setLogCallback(value);
  };

  var _recordingStatusChangeCallback;
  _me.setRecordingStatusChangeCallback = function (value) {
    _recordingStatusChangeCallback = value;
  };

  _me.setMicrophoneNotAvailableCallback = function (value) {
    _whiteboard.setMicrophoneNotAvailableCallback(value);
  };

  function getShapeClass (button) {
    var classes = $(button).attr('class').split(' ');

    for (var i = 0; i < classes.length; i++) {
      var cls = classes[i];

      if (cls !== 'image-button' && cls !== 'ng-binding')
        return cls;
    }

    return null;
  }

  function getShapeClasses () {
    var result = [];

    element.find('.shapes button').each(function (i, button) {
      var cls = getShapeClass(button);

      if (cls)
        result.push(cls);
    });

    return result;
  }

  var _shapeClasses = getShapeClasses();

  function selectShape (button, startHandler) {
    var className = getShapeClass(button);

    _me.changeTool();

    unbindAll();
    bindStart(startHandler);

    _shapeButton.addClass('selected');

    _shapeClasses.forEach(function (cls) {
      _shapeButton.removeClass(cls);
    });

    _shapeButton.addClass(className);

    if (_onToolChangeCallback)
      _onToolChangeCallback(className);
  }

  function beginShape (event, tool) {
    disableRedo();

    tool.beginDraw(_me.getX(event), _me.getY(event));

    _dragged = false;

    bindMove(function(event) {
      lastX = _me.getX(event);
      lastY = _me.getY(event);

      tool.redraw(lastX, lastY);

      _dragged = true;
    });

    bindEnd(function () {
      endShape(tool);
    }, false);
  }

  function endShape (tool) {
    unbindMove();
    unbindEnd();

    if (!_dragged)
      return;

    tool.endDraw(lastX, lastY);

    enableUndo();

    _hasChanged = true;
  }

  function getTouchFromEvent (event) {
    if (event.changedTouches && event.changedTouches.length > 0) {
      return event.changedTouches.item(0);
    } else if (event.originalEvent) {
      if (event.originalEvent.changedTouches && event.originalEvent.changedTouches.length > 0)
        return event.originalEvent.changedTouches.item(0);
      else if (event.originalEvent.clientX)
        return event.originalEvent;
    } else if (event.clientX) {
      return event;
    }

    return null;
  }

  var publicMembers = {
    canvasElement: element.find('canvas:first'), // jQuery element for canvas

    /**
     * The default ids and classes for the element
     * configurations are the index names used in this
     * array.
     *
     * If names or classes have different names, they
     * should be defined in the script initialization,
     * that is WhiteboardUi.init() function.
     *
     * The purpose of this list is only to show what
     * element definitions this scripts uses.
     */
    elementConf: {
    },

    setRecordingCallback: function (value) {
      _recordingCallback = value;

      _whiteboard.setRecordingCallback(onRecord);
    },

    /**
     * Resolves the X coordinate of the given event inside
     * the canvas element.
     *
     * @param event The event that has been executed.
     * @return The x coordinate of the event inside the
     * canvas element.
     */
    getX: function(event) {
      var x;

      if (event.pageX) {
        x = event.pageX;
      } else if (event.touches && event.touches.length > 0) {
        x = event.touches.item(0).clientX;
      } else if (event.originalEvent) {
        if (event.originalEvent.touches && event.originalEvent.touches.length > 0)
          x = event.originalEvent.touches.item(0).clientX;
        else if (event.originalEvent.layerX)
          x = event.originalEvent.layerX;
        else
          return -1;
      } else {
        return -1;
      }

      var cssx = (x - _me.canvasElement.offset().left);
      var xrel = _whiteboard.getRelative().width;
      var canvasx = cssx * xrel;
      return canvasx / _whiteboard.getWidth();
    },

    /**
     * Resolves the Y coordinate of the given event inside
     * the canvas element.
     *
     * @param event The event that has been executed.
     * @return The y coordinate of the event inside the
     * canvas element.
     */
    getY: function(event) {
      var y;

      if (event.pageY) {
        y = event.pageY;
      } else if (event.touches && event.touches.length > 0) {
        y = event.touches.item(0).clientY;
      } else if (event.originalEvent) {
        if (event.originalEvent.touches && event.originalEvent.touches.length > 0)
          y = event.originalEvent.touches.item(0).clientY;
        else if (event.originalEvent.layerY)
          y = event.originalEvent.layerY;
        else
          return -1;
      } else {
        return -1;
      }

      var cssy = (y - _me.canvasElement.offset().top);
      var yrel = _whiteboard.getRelative().height;
      var canvasy = cssy * yrel;
      return canvasy / _whiteboard.getHeight();
    },

    /**
     * Returns the canvas element to its default definition
     * without any extra classes defined by any of the selected
     * UI tools.
     */
    changeTool: function() {
      hideEditors();

      unbindAll();

      deselect();

      _colorPicker.hide();
      _shapePicker.hide();
      _dimensionsPicker.hide();
      _numberLineChooser.hide();
      _gridChooser.hide();
      _diagramPicker.hide();
      _stampPicker.hide();
      _unifixCubePicker.hide();
      _fractionBarPicker.hide();
      _algebraTilePicker.hide();

      element.find('button.tool').removeClass('selected');
    },

    /**
     * Activates pencil tool and adds pencil_active class
     * to canvas element.
     */
    activatePencil: function() {
      unbindAll();
      bindStart(_me.beginPencilDraw);
      _drawButton.addClass('selected');
    },

    /**
     * Begins the pencil draw after user action that is usually
     * mouse down. This should be executed on mousedown event
     * after activating the pen tool.
     *
     * @param event The event that has been executed to perform
     * this action
     */
    beginPencilDraw: function(event) {
      disableRedo();

      _whiteboard.beginPencilDraw(_me.getX(event), _me.getY(event));
      bindMove(function(event) {
        _whiteboard.pencilDraw(_me.getX(event), _me.getY(event));

        _hasChanged = true;
      });
      bindEnd(_me.endPencilDraw);
    },

    /**
     * Ends pencil draw which means that mouse moving won't
     * be registered as drawing action anymore. This should be
     * executed on mouseup after user has started drawing.
     */
    endPencilDraw: function () {
      _whiteboard.endDrawing();

      unbindMove();
      unbindEnd();

      enableUndo();
    },

    beginHighlighterDraw: function (event) {
      var x, y, oldX, oldY;

      disableRedo();

      x = _me.getX(event);
      y = _me.getY(event);
      oldX = x * element.width();
      oldY = y * element.height();

      _whiteboard.beginHighlighterDraw(x, y);
      bindMove(function(event) {
        var x, y, xPixels, yPixels;

        x = _me.getX(event);
        y = _me.getY(event);
        xPixels = x * element.width();
        yPixels = y * element.height();

        var distance = Math.sqrt(Math.pow(xPixels - oldX, 2) + Math.pow(yPixels - oldY, 2));

        if (distance < _whiteboard.getHighlighterSize())
          return;

        oldX = xPixels;
        oldY = yPixels;

        _whiteboard.highlighterDraw(x, y);

        _hasChanged = true;
      });
      bindEnd(_me.endHighlighterDraw);
    },

    endHighlighterDraw: function () {
      _whiteboard.endDrawing();

      unbindMove();
      unbindEnd();

      enableUndo();
    },

    /**
     * Activates erasing tool and adds eraser_active class
     * to canvas element.
     */
    activateEraser: function() {
      if (!isButtonEnabled(_eraserButton))
        return;

      bindStart(_me.beginErasing);
    },

    /**
     * Begins the erasing action after user action that is usually
     * mouse down. This should be executed on mousedown event
     * after activating the erasing tool.
     *
     * @param event The event that has been executed to perform
     * this action
     */
    beginErasing: function(event) {
      disableRedo();

      _whiteboard.beginErasing(_me.getX(event), _me.getY(event));
      bindMove(function(event) {
        _whiteboard.erasePoint(_me.getX(event), _me.getY(event));

        _hasChanged = true;
      });
      bindEnd(_me.endErasing);
    },

    /**
     * Ends erasing which means that mouse moving won't
     * be registered as erasing action anymore. This should be
     * executed on mouseup after user has started erasing.
     */
    endErasing: function() {
      unbindMove();
      unbindEnd();

      enableUndo();
    }
  };

  for (var key in publicMembers)
    this[key] = publicMembers[key];

  /**
   * Resolves the element name from WhiteboardUi.elemConf.
   * If index defined by ind parameter can be found in that
   * array and the array's value is returned. Otherwise
   * the ind parameter itself is returned.
   *
   * @param ind The element's index name in WhiteboardUi.elemConf
   * @return The elements correct name
   */
  function getElementName (ind) {
    if (_me.elementConf[ind] === undefined ||
      _me.elementConf[ind] === null)
      return ind;

    return _me.elementConf[ind];
  }

  /**
   * Resolves the jQuery element with the defined id which
   * is resolved by WhiteboardUi.getElementName function.
   *
   * @param ind The element's index name in WhiteboardUi.elemConf
   * or the wanted id name that's not included in that array.
   * @return The jQuery element with the resolved id
   */
  function getElement (ind)  {
    return element.find('.' + getElementName(ind) + ':first');
  }

  _timer = new TimeLabel(getElement('timecode'));
  _statusLabel = getElement('status');
  _colorPicker = getElement('colorpicker');
  _shapePicker = getElement('shapes');
  _stampPicker = getElement('stamps');
  _diagramPicker = getElement('diagrams');
  _unifixCubePicker = getElement('unifix-cube-picker');
  _pageLabel = getElement('page-label');
  _algebraTilePicker = getElement('algebra-tile-picker');
  _fractionBarPicker = getElement('fraction-bar-picker');
  _hiddenToolsEl = getElement('hidden-tools');
  _moreToolsList = getElement('more-tools-list');

  // dimensions picker

  _dimensionsPicker = getElement('array-dimensions');

  var _dimensionButtons = element.find('.array-dimensions .grid button');
  var _dimensionsLabel = element.find('.array-dimensions .dimensions');

  function selectDimensionButton (el) {
    var size = $(el).text();
    size = size.split(' x ');

    _arrayRows = parseInt(size[1]);
    _arrayColumns = parseInt(size[0]);

    _dimensionButtons.each(function (i, button) {
      $(button).removeClass('selected');
    });

    _dimensionsLabel.text(null);

    if (_arrayButton.hasClass('selected'))
      activateArray();
    else if (_tableButton.hasClass('selected'))
      activateTable();
    else if (_spreadsheetButton.hasClass('selected'))
      activateSpreadsheet();
  }

  var onDimensionsButtonClick = function (e) {
    selectDimensionButton(e.target);
  };

  function highlightDimensionButtons (el) {
    var text = $(el).text();
    var size = text;
    size = size.split(' x ');

    var rows = parseInt(size[1]);
    var cols = parseInt(size[0]);

    _dimensionButtons.each(function (i, button) {
      button = $(button);

      var size = button.text();
      size = size.split(' x ');

      var r = parseInt(size[1]);
      var c = parseInt(size[0]);

      if (r <= rows && c <= cols)
        button.addClass('selected');
      else
        button.removeClass('selected');
    });

    _dimensionsLabel.text(text);
  }

  var onDimensionsButtonMouseEnter = function (e) {
    highlightDimensionButtons(e.target);
  };

  WhiteboardUi.bindEvent(_dimensionButtons, 'click', onDimensionsButtonClick);
  WhiteboardUi.bindEvent(_dimensionButtons, 'mouseenter', onDimensionsButtonMouseEnter);

  function getElementAtPoint (parentEl, x, y) {
    var result;

    var offset = parentEl.offset();
    x -= offset.left;
    y -= offset.top;

    parentEl.find('*').each(function (i, el) {
      el = $(el);

      // only want last descendants
      if (el.children().length > 0)
        return;

      var pos = el.position();

      console.log(x + ',' + y + ': ' + pos.left + ','+ pos.top + ' (' + el.text() + ')');

      if (x >= pos.left && y >= pos.top && x < pos.left + el.width() && y < pos.top + el.height()) {
        result = el;

        return false;
      }
    });

    return result;
  }

  var _isDraggingDimension;
  var _dimensionHighlighterInterval;
  var _lastDimensionTouch;
  var _dimensionPickerGrid = _dimensionsPicker.find('.grid');

  function onDimensionHighlighterIntervalFire () {
    var touch = _lastDimensionTouch;
    _lastDimensionTouch = null;

    if (!touch)
      return;

    var el = getElementAtPoint(_dimensionPickerGrid, touch.clientX, touch.clientY);

    if (!el)
      return;

    if (_dimensionButtons.index(el) !== -1) {
      //console.log(touch.clientX + ',' + touch.clientY + ': '+ $(el).text());

      highlightDimensionButtons(el);
    }
  }

  function onDimensionButtonTouchStart () {
    _isDraggingDimension = true;

    _dimensionHighlighterInterval = setInterval(onDimensionHighlighterIntervalFire, 100);
  }

  function onDimensionPickerTouchMove (e) {
    if (!_isDraggingDimension)
      return;

    _lastDimensionTouch = getTouchFromEvent(e);
  }

  function onDimensionPickerTouchEnd (e) {
    _isDraggingDimension = false;
    clearInterval(_dimensionHighlighterInterval);
    _dimensionHighlighterInterval = null;

    var touch = getTouchFromEvent(e);

    var el = getElementAtPoint(_dimensionPickerGrid, touch.clientX, touch.clientY);

    if (!el)
      return;

    if (_dimensionButtons.index(el) !== -1)
      selectDimensionButton(el);
  }

  WhiteboardUi.bindEvent(_dimensionButtons.first(), 'touchstart', onDimensionButtonTouchStart);
  WhiteboardUi.bindEvent(_dimensionPickerGrid, 'touchmove', onDimensionPickerTouchMove);
  WhiteboardUi.bindEvent(_dimensionPickerGrid, 'touchend', onDimensionPickerTouchEnd);

  var _overwriteCallback;
  _me.setOverwriteCallback = function (value) {
    _overwriteCallback = value;
  };

  function startRecording () {
    if (_spreadsheetTool.commit())
      _hasChanged = true;

    if (_whiteboard.isRecording())
      return;

    if (_whiteboard.isRecordingPaused()) {
      _whiteboard.startRecording(recordingStarted);
    } else {
      _lastRecordedEvents = _whiteboard.getRecordedEvents();

      _timer.reset();

      _whiteboard.startRecording(recordingStarted);
    }
  }

  function recordingStarted () {
    _recordButton.addClass('recording');
    _stopButton.addClass('enabled');

    $translate('RECORDING').then(function (text) {
      _statusLabel.text(text);
    });

    _timer.start();

    if (_recordingStatusChangeCallback)
      _recordingStatusChangeCallback(true);
  }

  var _stopBlinking;
  function pauseRecording () {
    if (!_whiteboard.isRecording() || _whiteboard.isRecordingPaused())
      return;

    _timer.pause();

    _whiteboard.pauseRecording();

    _recordButton.removeClass('recording');
    _stopBlinking = WhiteboardUi.blinkElement(_recordButton);

    $translate('PAUSED').then(function (text) {
      _statusLabel.text(text);
    });
  }

  _me.pauseRecording = pauseRecording;

  function stopBlinking () {
    if (_stopBlinking) {
      _stopBlinking();
      _stopBlinking = null;
    }
  }

  function activateRecord() {
    var isRecording = _whiteboard.isRecording();

    if (isRecording) {
      pauseRecording();

      if (_onStatusChangeCallback)
        _onStatusChangeCallback('pause record');
    } else if (_whiteboard.isRecordingPaused()) {
      stopBlinking();

      startRecording();

      if (_onStatusChangeCallback)
        _onStatusChangeCallback('resume record');
    } else {
      if (_onStatusChangeCallback)
        _onStatusChangeCallback('start record');

      if (_overwriteCallback && _overwriteCallback(startRecording))
        return;

      startRecording();
    }
  }

  function onRecordDown () {
    activateRecord();
  }

  function stop () {
    _timer.stop();

    if (_whiteboard.isRecording() || _whiteboard.isRecordingPaused()) {
      if (_onStatusChangeCallback)
        _onStatusChangeCallback('stop record');

      hideEditors();

      _lastRecordedEvents = null;

      _whiteboard.stopRecording();

      _recordButton.removeClass('recording');
      stopBlinking();
      _stopButton.removeClass('enabled');

      $translate('UPLOADING').then(function (text) {
        _statusLabel.text(text);
      });

      if (_recordingStatusChangeCallback)
        _recordingStatusChangeCallback(false);
    }
  }

  function onStopDown () {
    stop();
  }

  function positionElementUnderElement (under, over, ignoreAncestors) {
    var originalTarget = over;
    var parent = under.parent();
    var pos = over.position();
    var padding = over.css(['borderLeftWidth', 'borderTopWidth', 'paddingLeft', 'paddingRight']);
    var overCss = padding;

    if (!padding.borderLeftWidth)
      padding.borderLeftWidth = 0;

    if (!padding.borderTopWidth)
      padding.borderTopWidth = 0;

    if (!padding.paddingLeftWidth)
      padding.paddingLeftWidth = 0;

    if (!padding.paddingTop)
      padding.paddingTop = 0;

    pos.left += parseInt(padding.borderLeftWidth);
    pos.top += parseInt(padding.borderTopWidth);

    while (over.parent()[0] !== parent[0]) {
      over = over.parent();

      var p = over.position();

      padding = over.css(['paddingLeft', 'paddingTop', 'borderLeftWidth', 'borderTopWidth']);

      if (!padding.paddingLeft)
        padding.paddingLeft = 0;

      if (!padding.paddingTop)
        padding.paddingTop = 0;

      if (!padding.borderLeftWidth)
        padding.borderLeftWidth = 0;

      if (!padding.borderTopWidth)
        padding.borderTopWidth = 0;

      p.left += parseInt(padding.paddingLeft);
      p.top += parseInt(padding.paddingTop);
      p.left += parseInt(padding.borderLeftWidth);
      p.top += parseInt(padding.borderTopWidth);

      if (!ignoreAncestors)
        pos.left += p.left;

      pos.top += p.top;
    }

    var underWidth = under.width();
    var underCss = under.css(['paddingLeft', 'paddingRight']);
    underWidth += parseInt(underCss.paddingLeft);
    underWidth += parseInt(underCss.paddingRight);

    var overWidth = originalTarget.width();
    overWidth += parseInt(overCss.paddingLeft);
    overWidth += parseInt(overCss.paddingRight);

    pos.left -= (underWidth - overWidth) / 2;

    under.css('left', pos.left + 'px');
    under.css('top', pos.top + over.height() + 'px');
    under.css('position', 'absolute');

    var position = parent.css('position');

    if (position !== 'absolute' && position !== 'relative')
      parent.css('position', 'relative');
  }

  function activatePen() {
    if (!isButtonEnabled(_drawButton))
      return;

    if (!_drawButton.hasClass('selected')) {
      _me.changeTool();

      _drawButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('pen');
    }

    togglePicker(_colorPicker, _drawButton);
  }

  function onDrawDown () {
    activatePen();
  }

  _me.activateHighlighter = function () {
    if (!isButtonEnabled(_highlighterButton))
      return;

    bindStart(_me.beginHighlighterDraw);
  };

  function onHighlighterDown () {
    if (!_highlighterButton.hasClass('selected')) {
      _me.changeTool();

      _highlighterButton.addClass('selected');

      _me.activateHighlighter();

      if (_onToolChangeCallback)
        _onToolChangeCallback('highlight');
    }
  }

  function selectColorButton (t) {
    var className = t.attr('class').split(' ')[0];
    var color;

    if (className === 'blue')
      color = 'rgb(68, 83, 164)';
    else if (className === 'red')
      color = 'rgb(191, 64, 63)';
    else if (className === 'green')
      color = 'rgb(140, 198, 64)';
    else if (className === 'orange')
      color = 'rgb(210, 112, 39)';
    else if (className === 'purple')
      color = 'rgb(80, 31, 95)';
    else
      color = className = 'black';

    _drawButton.attr('class', 'tool draw drawpolyline image-button selected ' + className);
    _whiteboard.setStrokeStyle(color);
    _me.activatePencil();
  }

  function onColorDown (event) {
    _colorPicker.hide();

    var t = $(event.target);

    selectColorButton(t);
  }

  function togglePicker (picker, button) {
    if (picker !== _colorPicker)
      _colorPicker.hide();

    if (picker !== _shapePicker)
      _shapePicker.hide();

    if (picker !== _dimensionsPicker)
      _dimensionsPicker.hide();

    if (picker !== _numberLineChooser)
      _numberLineChooser.hide();

    if (picker !== _gridChooser)
      _gridChooser.hide();

    if (picker !== _stampPicker)
      _stampPicker.hide();

    if (picker !== _diagramPicker)
      _diagramPicker.hide();

    if (picker.css('display') === 'none') {
      picker.css('display', 'flex');

      if (button) {
        positionElementUnderElement(picker, button, button.parent().is(_moreToolsList));

        picker.css('top', picker.position().top + 10 + 'px');
      }

      return true;
    } else {
      picker.css('display', 'none');

      return false;
    }
  }

  function activateShapes() {
    if (!isButtonEnabled(_shapeButton))
      return;

    if (!_shapeButton.hasClass('selected')) {
      _me.changeTool();
      _shapeButton.addClass('selected');
    }

    togglePicker(_shapePicker, _shapeButton);
  }

  function onShapeDown () {
    activateShapes();
  }

  function bindShapeButton (button, tool) {
    var onShapeDown = function () {
      _shapePicker.hide();

      selectShape(button, function (event) {
        beginShape(event, tool);
      });
    };

    WhiteboardUi.bindEvent(button, 'mouseup', onShapeDown);
  }

  var _imageURL, _imageWidth, _imageHeight, _isStampResizable;

  function activateStamps(event) {
    if (!isButtonEnabled(_stampButton))
      return;

    var target = event ? $(event.target) : null;

    if (target && target.prop('tagName').toLowerCase() !== 'button')
      target = target.parent();

    if (!target || target.is(_stampButton)) {
      if (!_stampButton.hasClass('selected')) {
        _me.changeTool();
        _stampButton.addClass('selected');
      }

      togglePicker(_stampPicker, _stampButton);
    } else {
      _imageURL = target.css('background-image').match(/\(['"]?([^'"]+)['"]?\)/)[1];

      var setSizeAndActivateStamp = function (size) {
        if (typeof size !== 'object' || size === null) {
          if (typeof size === 'string') {
            size = size.split(' ');

            if (size.length === 1) {
              size = {
                width: parseInt(size[0])
              };

              size.height = size.width;
            } else {
              if (size[0] === 'auto')
                size[0] = size[1];
              else if (size[1] === 'auto')
                size[1] = size[0];

              size = {
                width: parseInt(size[0]),
                height: parseInt(size[1])
              };
            }
          } else {
            size = {
              width: target.width(),
              height: target.height()
            };
          }
        }

        if (target.is(_tenBlockButton))
          size.width = 8;

        if (target.is(_tenBlockButton) || target.is(_oneBlockButton) || target.is(_hundredBlockButton)) {
          _isStampResizable = false;
        } else {
          _isStampResizable = true;
        }

        var sizeMultipler;

        if (target.hasClass('user-image')) // use full image size when stamping question images
          sizeMultipler = 1;
        else
          sizeMultipler = 2;

        size.width *= sizeMultipler;
        size.height *= sizeMultipler;

        if (size.width > _whiteboard.getWidth()) {
          var oldWidth = size.width;

          size.width = 0.8 * _whiteboard.getWidth();
          size.height = (size.width * size.height) / oldWidth;
        }

        if (size.height > _whiteboard.getHeight()) {
          var oldHeight = size.height;

          size.height = 0.8 * _whiteboard.getHeight();
          size.width = (size.width * size.height) / oldHeight;
        }

        _imageWidth = size.width / _whiteboard.getWidth();
        _imageHeight = size.height / _whiteboard.getHeight();

        _stampPicker.hide();

        activateStamp();
      };

      var size;

      if (target.hasClass('user-image')) {
        var image = new Image();
        image.src = _imageURL;

        image.onload = function () {
          size = {
            width: image.width,
            height: image.height
          };

          setSizeAndActivateStamp(size);
        };
      } else {
        size = target.css('background-size');

        if (size === 'auto' || size === 'auto auto')
          size = null;

        setSizeAndActivateStamp(size);
      }

      if (_onToolChangeCallback) {
        const parts = target.attr('class').split(' ');
        const type = parts.find(part => part !== 'ng-binding');

        _onToolChangeCallback('stamp', type);
      }
    }
  }

  function onStampDown (event) {
    activateStamps(event);
  }

  function activateStamp () {
    _me.changeTool();

    unbindStart();
    bindStart(beginStamp);
  }

  function beginStamp (event) {
    disableRedo();

    var x = _me.getX(event);
    var y = _me.getY(event);

    addingImage = true;

    var callback = function (url) {
      _whiteboard.addImage(x - (_imageWidth / 2), y - (_imageHeight / 2), _imageWidth, _imageHeight, url, undefined, _isStampResizable);

      enableUndo();

      _hasChanged = true;
    };

    if (_uploadCallback)
      _uploadCallback(_imageURL, callback);
    else
      callback(_imageURL);

    enableUndo();

    _hasChanged = true;
  }

  function onProtractorDown () {
    activateProtractor();

    if (_onToolChangeCallback)
      _onToolChangeCallback('protractor');
  }

  function activateProtractor () {
    if (!isButtonEnabled(_protractorButton))
      return;

    _me.changeTool();

    var width = (2710 / 4) / _whiteboard.getWidth();
    var height = (1470 / 4) / _whiteboard.getHeight();
    var sx = 0.5 - (width / 2);
    var sy = 0.5 - (height / 2);
    var ex = 0.5 + (width / 2);
    var ey = 0.5 + (height / 2);

    _whiteboard.drawProtractor(sx, sy, ex, ey);

    _hasChanged = true;

    enableUndo();
  }

  function onMoreToolsButtonDown() {
    if (_moreToolsList.css('display') === 'inline-block') {
      _moreToolsList.hide();
      _moreToolsButton.removeClass('open');
    } else {
      _moreToolsList.css('display', 'inline-block');
      _moreToolsButton.addClass('open');

      if (_logCallback)
        _logCallback('opened more tools');
    }

    if (typeof localStorage !== 'undefined') {
      if (_moreToolsList.css('display') === 'inline-block')
        localStorage.setItem('isWhiteboardToolsExpanded', true);
      else
        localStorage.removeItem('isWhiteboardToolsExpanded');
    }
  }

  function selectTable() {
    if (!isButtonEnabled(_tableButton))
      return;

    if (!_tableButton.hasClass('selected')) {
      _me.changeTool();
      _tableButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('table');
    }

    if (togglePicker(_dimensionsPicker, _tableButton))
      _arrayRows = _arrayColumns = 0;
  }

  function onTableDown () {
    selectTable();
  }

  function activateTable () {
    _me.changeTool();

    if (_tableTextField)
      _tableTextField.blur();

    _whiteboard.drawTable(0.25, 0.25, 0.75, 0.75, _arrayRows, _arrayColumns);

    _arrayRows = _arrayColumns = 0;

    _hasChanged = true;

    enableUndo();

    activateText();
  }

  function onSpreadsheetDown () {
    if (!_spreadsheetButton.hasClass('selected')) {
      _me.changeTool();
      _spreadsheetButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('spreadsheet');
    }

    if (togglePicker(_dimensionsPicker, _spreadsheetButton))
      _arrayRows = _arrayColumns = 0;
  }

  function activateSpreadsheet () {
    _me.changeTool();

    var object = _whiteboard.drawSpreadsheet(0.1, 0.2, 0.75, 0.75, _arrayRows, _arrayColumns);

    _arrayRows = _arrayColumns = 0;

    _hasChanged = true;

    enableUndo();

    activateText();

    _spreadsheetTool.setUp(object);
  }

  function selectArray() {
    if (!isButtonEnabled(_arrayButton))
      return;

    if (!_arrayButton.hasClass('selected')) {
      _me.changeTool();
      _arrayButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('array');
    }

    if (togglePicker(_dimensionsPicker, _arrayButton))
      _arrayRows = _arrayColumns = 0;
  }

  function onArrayDown () {
    selectArray();
  }

  function selectNumberLine() {
    if (!isButtonEnabled(_numberLineButton))
      return;

    if (!_numberLineButton.hasClass('selected')) {
      _me.changeTool();
      _numberLineButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('numberline');
    }

    togglePicker(_numberLineChooser, _numberLineButton);
  }

  function onNumberLineDown () {
    selectNumberLine();
  }

  var _numberLinePopUp;
  var _numberLineOneLineCheckBox;
  var _numberLineTwoLinesCheckBox;
  var _numberLineHorizontalCheckBox;
  var _numberLineVerticalCheckBox;
  var _numberLine1StartValueField;
  var _numberLine1EndValueField;
  var _numberLine1IntervalField;
  var _numberLine2ValuesHeading;
  var _numberLine2Values;
  var _numberLine2StartValueField;
  var _numberLine2EndValueField;
  var _numberLine2IntervalField;
  var _numberLinePreview;

  function onNumberLineCountInput () {
    if (this === _numberLineTwoLinesCheckBox[0] && _numberLineTwoLinesCheckBox.is(':checked')) {
      _numberLine2ValuesHeading.css('visibility', 'visible');
      _numberLine2Values.css('visibility', 'visible');
    } else {
      _numberLine2ValuesHeading.css('visibility', 'hidden');
      _numberLine2Values.css('visibility', 'hidden');
    }
  }

  function onNumberLineValueKeyPress (e) {
    return /[0-9\.-]/.test(e.key);
  }

  function PreviewWhiteboard(canvas) {
    var context = canvas.getContext('2d');

    Object.defineProperty(this, 'context', {
      get: function () {
        return context;
      }
    });

    this.getCanvas = function () {
      return canvas;
    };

    this.getContext = function () {
      return context;
    };

    this.getWidth = function () {
      return canvas.width;
    };

    this.getHeight = function () {
      return canvas.height;
    };

    this.setStrokeColor = function (color) {
      context.strokeStyle = color;
    };

    this.setFillColor = function (color) {
      context.fillStyle = color;
    };

    this.getFont = function () {
      return 'sans-serif';
    };
  }

  function renderNumberLinePreview(isOneLine, isHorizontal, startValue1, endValue1, interval1, startValue2, endValue2, interval2) {
    if (isHorizontal) {
      _numberLinePreview[0].width = 200;
      _numberLinePreview[0].height = 50;

      if (!isOneLine)
        _numberLinePreview[0].height *= 2;
    } else {
      _numberLinePreview[0].width = 50;
      _numberLinePreview[0].height = 200;

      if (!isOneLine)
        _numberLinePreview[0].width *= 2;
    }

    var previewNumberLine = new NumberLineTool(new PreviewWhiteboard(_numberLinePreview[0]));

    previewNumberLine.whiteboard.getContext().clearRect(0, 0, previewNumberLine.whiteboard.getWidth(), previewNumberLine.whiteboard.getHeight());

    previewNumberLine.draw({
      color: 'black',
      coordinates: [0, 0, 1, 1],
      numbers: getNumberLineNumbers(isOneLine, startValue1, endValue1, interval1, startValue2, endValue2, interval2),
      type: NumberLineTool.type
    });
  }

  function updateNumberLinePreview() {
    var isOneLine = _numberLineOneLineCheckBox.is(':checked');
    var isHorizontal = _numberLineHorizontalCheckBox.is(':checked');

    var startValue1 = parseFloat(_numberLine1StartValueField.val());
    var endValue1 = parseFloat(_numberLine1EndValueField.val());
    var interval1 = parseInt(_numberLine1IntervalField.val());
    var startValue2 = parseFloat(_numberLine2StartValueField.val());
    var endValue2 = parseFloat(_numberLine2EndValueField.val());
    var interval2 = parseInt(_numberLine2IntervalField.val());

    renderNumberLinePreview(isOneLine, isHorizontal, startValue1, endValue1, interval1, startValue2, endValue2, interval2);
  }

  function onNumberLineFieldInput() {
    updateNumberLinePreview();
  }

  function openNumberLinePopUp() {
    if (_numberLinePopUp) {
      // reset fields
      _numberLineTwoLinesCheckBox.prop('checked', true);
      _numberLineHorizontalCheckBox.prop('checked', true);
      _numberLine1StartValueField.val(0);
      _numberLine1EndValueField.val(10);
      _numberLine1IntervalField.val(1);
      _numberLine2StartValueField.val(0);
      _numberLine2EndValueField.val(10);
      _numberLine2IntervalField.val(1);

      _numberLine2ValuesHeading.css('visibility', 'visible');
      _numberLine2Values.css('visibility', 'visible');

      updateNumberLinePreview();
    } else {
      _numberLinePopUp = element.find('.number-line-pop-up');
      _numberLineOneLineCheckBox = _numberLinePopUp.find('#one-line');
      _numberLineTwoLinesCheckBox = _numberLinePopUp.find('#two-lines');
      _numberLineHorizontalCheckBox = _numberLinePopUp.find('#horizontal-line');
      _numberLineVerticalCheckBox = _numberLinePopUp.find('#vertical-line');
      _numberLine1StartValueField = _numberLinePopUp.find('#number-line-start-value-1');
      _numberLine1EndValueField = _numberLinePopUp.find('#number-line-end-value-1');
      _numberLine1IntervalField = _numberLinePopUp.find('#number-line-interval-1');
      _numberLine2ValuesHeading = _numberLinePopUp.find('.number-line-2-values-heading');
      _numberLine2Values = _numberLinePopUp.find('.number-line-2-values');
      _numberLine2StartValueField = _numberLinePopUp.find('#number-line-start-value-2');
      _numberLine2EndValueField = _numberLinePopUp.find('#number-line-end-value-2');
      _numberLine2IntervalField = _numberLinePopUp.find('#number-line-interval-2');
      _numberLinePreview = _numberLinePopUp.find('.preview canvas');

      WhiteboardUi.bindEvent(_numberLineOneLineCheckBox, 'input', onNumberLineCountInput);
      WhiteboardUi.bindEvent(_numberLineTwoLinesCheckBox, 'input', onNumberLineCountInput);
      WhiteboardUi.bindEvent(_numberLine1StartValueField, 'keypress', onNumberLineValueKeyPress);
      WhiteboardUi.bindEvent(_numberLine1EndValueField, 'keypress', onNumberLineValueKeyPress);
      WhiteboardUi.bindEvent(_numberLine2StartValueField, 'keypress', onNumberLineValueKeyPress);
      WhiteboardUi.bindEvent(_numberLine2EndValueField, 'keypress', onNumberLineValueKeyPress);
      WhiteboardUi.bindEvent(_numberLineOneLineCheckBox, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLineTwoLinesCheckBox, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLineHorizontalCheckBox, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLineVerticalCheckBox, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine1StartValueField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine1EndValueField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine1IntervalField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine2StartValueField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine2EndValueField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLine2IntervalField, 'input', onNumberLineFieldInput);
      WhiteboardUi.bindEvent(_numberLinePopUp.find('.close'), 'click', closeNumberLinePopUp);
      WhiteboardUi.bindEvent(_numberLinePopUp.find('.submit'), 'click', submitNumberLinePopUp);

      updateNumberLinePreview();
    }

    _numberLinePopUp.show();
  }

  function closeNumberLinePopUp () {
    _numberLinePopUp.hide();
  }

  function roundToDecimalPlace(num, places) {
    var multiplier = Math.pow(10, places);

    return Math.round(num * multiplier) / multiplier;
  }

  function getNumberLineNumbers(isOneLine, startValue1, endValue1, interval1, startValue2, endValue2, interval2) {
    var numbers = [];
    var startValue = startValue1;
    var endValue = endValue1;
    var interval = interval1;

    var range = endValue - startValue;

    interval++;

    for (var i = 0; i < interval; i++)
      numbers.push(roundToDecimalPlace(startValue + ((i * range) / (interval - 1)), 2));

    if (!isOneLine) {
      var numberLine1 = numbers;

      numbers = [];

      startValue = startValue2;
      endValue = endValue2;
      interval = interval2;

      range = endValue - startValue;

      interval++;

      for (i = 0; i < interval; i++)
        numbers.push(roundToDecimalPlace(startValue + ((i * range) / (interval - 1)), 2));

      numbers = [numberLine1, numbers];
    }

    return numbers;
  }

  function submitNumberLinePopUp () {
    var isOneLine = _numberLineOneLineCheckBox.is(':checked');
    var isHorizontal = _numberLineHorizontalCheckBox.is(':checked');

    var startValue = _numberLine1StartValueField.val();
    var endValue = _numberLine1EndValueField.val();
    var interval = _numberLine1IntervalField.val();

    if (typeof startValue === 'undefined' || startValue === '' || startValue === null || typeof endValue === 'undefined' || endValue === '' || endValue === null) {
      $translate('PLEASE_FILL_OUT_ALL_FIELDS').then(function (value) {
        alert(value);
      });

      return;
    }

    startValue = parseFloat(startValue);
    endValue = parseFloat(endValue);
    interval = parseFloat(interval);

    if (isNaN(startValue) || isNaN(endValue)) {
      $translate('VALUES_MUST_BE_NUMERIC').then(function (value) {
        alert(value);
      });

      return;
    }

    if (startValue >= endValue) {
      $translate('START_VALUE_MUST_BE_LESS_THAN_END_VALUE').then(function (value) {
        alert(value);
      });

      return;
    }

    if (interval < 0) {
      $translate('INTERVAL_MUST_BE_AT_LEAST_ZERO').then(function (value) {
        alert(value);
      });

      return;
    }

    if ((interval + '').indexOf('.') !== -1) {
      $translate('INTERVAL_MUST_BE_WHOLE_NUMBER').then(function (value) {
        alert(value);
      });

      return;
    }

    var startValue1 = startValue;
    var endValue1 = endValue;
    var interval1 = interval;
    var startValue2;
    var endValue2;
    var interval2;

    if (!isOneLine) {
      startValue = _numberLine2StartValueField.val();
      endValue = _numberLine2EndValueField.val();
      interval = _numberLine2IntervalField.val();

      if (typeof startValue === 'undefined' || startValue === '' || startValue === null || typeof endValue === 'undefined' || endValue === '' || endValue === null) {
        $translate('PLEASE_FILL_OUT_ALL_FIELDS').then(function (value) {
          alert(value);
        });

        return;
      }

      startValue = parseFloat(startValue);
      endValue = parseFloat(endValue);
      interval = parseFloat(interval);

      if (isNaN(startValue) || isNaN(endValue)) {
        $translate('VALUES_MUST_BE_NUMERIC').then(function (value) {
          alert(value);
        });

        return;
      }

      if (startValue >= endValue) {
        $translate('START_VALUE_MUST_BE_LESS_THAN_END_VALUE').then(function (value) {
          alert(value);
        });

        return;
      }

      if (interval < 0) {
        $translate('INTERVAL_MUST_BE_AT_LEAST_ZERO').then(function (value) {
          alert(value);
        });

        return;
      }

      if ((interval + '').indexOf('.') !== -1) {
        $translate('INTERVAL_MUST_BE_WHOLE_NUMBER').then(function (value) {
          alert(value);
        });

        return;
      }

      startValue2 = startValue;
      endValue2 = endValue;
      interval2 = interval;
    }

    var numbers = getNumberLineNumbers(isOneLine, startValue1, endValue1, interval1, startValue2, endValue2, interval2);

    activateNumberLine(numbers, isHorizontal);

    closeNumberLinePopUp();
  }

  function onNumberLineChoiceDown (event) {
    var numbers;

    _numberLineChooser.hide();

    if (event.target === _zeroToTenLink[0])
      numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    else if (event.target === _zeroToTwentyLink[0])
      numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
    else if (event.target === _generatorLink[0]) {
      openNumberLinePopUp();

      return;
    } else if (event.target === _blankLink[0])
      numbers = [null, null, null, null, null, null, null, null, null, null];
    else
      numbers = null;

    activateNumberLine(numbers, true);
  }

  var _arrayRows, _arrayColumns;

  function activateArray () {
    _me.changeTool();

    var tools = element.find('.tools:first');

    _whiteboard.drawArray(0.375, (tools.position().top + tools.height() + 20) / _whiteboard.getHeight(), 0.625, (element.find('.record-container:first').position().top - 20) / _whiteboard.getHeight(), _arrayRows, _arrayColumns);

    enableUndo();

    _hasChanged = true;
  }

  function activateNumberLine (numbers, isHorizontal) {
    _me.changeTool();

    var size = 50;

    if (numbers && Array.isArray(numbers[0]))
      size *= numbers.length * 2;

    if (_tableTextField)
      _tableTextField.blur();

    var width, height;
    var x, y;
    var length;

    if (isHorizontal) {
      length = (_whiteboard.getWidth() - 16) / _whiteboard.getWidth();
      x = 0.5 - (length /  2);
      y = ((_whiteboard.getHeight() - size) / 2) / _whiteboard.getHeight();
      width = length;
      height = size / _whiteboard.getHeight();
    } else {
      length = (_whiteboard.getHeight() - 150) / _whiteboard.getHeight();
      x = ((_whiteboard.getWidth() - size) / 2) / _whiteboard.getWidth();
      y = 0.5 - (length / 2);
      width = size / _whiteboard.getWidth();
      height = length;
    }

    _whiteboard.drawNumberLine(x, y, x + width, y + height, numbers, !isHorizontal);

    _hasChanged = true;

    enableUndo();
  }

  function selectGrid() {
    if (!isButtonEnabled(_gridButton))
      return;

    if (!_gridButton.hasClass('selected')) {
      _me.changeTool();
      _gridButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback(GridTool.type);
    }

    togglePicker(_gridChooser, _gridButton);
  }

  function onGridDown () {
    selectGrid();
  }

  function onGridChoiceDown (event) {
    _gridChooser.hide();

    var type;

    if (event.target === _fullGridButton[0])
      type = 'full';
    else if (event.target === _positiveGridButton[0])
      type = 'positive';
    else if (event.target === _openGridButton[0])
      type = 'open';

    activateGrid(type);
  }

  function activateGrid (type) {
    _me.changeTool();

    var GRID_SIZE = {
      width: null,
      height: (_whiteboard.getHeight() - 150) / _whiteboard.getHeight()
    };
    var sx, sy, ex, ey;

    GRID_SIZE.width = (GRID_SIZE.height * _whiteboard.getHeight()) / _whiteboard.getWidth();

    if (type === 'open') {
      GRID_SIZE.width *= 24 / 11; // dimensions of open grid

      if (GRID_SIZE.width >= 1) {
        GRID_SIZE.width *= 0.5;
        GRID_SIZE.height *= 0.5;
      }

      sx = 0.5 - (GRID_SIZE.width / 2);
      sy = 0.5 - (GRID_SIZE.height / 2);
      ex = 0.5 + (GRID_SIZE.width / 2);
      ey = 0.5 + (GRID_SIZE.height / 2);
    } else {
      sx = 0.5 - (GRID_SIZE.width / 2);
      sy = 0.5 - (GRID_SIZE.height / 2);
      ex = 0.5 + (GRID_SIZE.width / 2);
      ey = 0.5 + (GRID_SIZE.height / 2);
    }

    var xNumbers, yNumbers;

    if (type === 'full') {
      xNumbers = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      yNumbers = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reverse(); // number line reverses vertical numbers
    } else if (type === 'positive') {
      xNumbers = [-1, '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      yNumbers = [-1, '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reverse();
    }

    var obj = _whiteboard.drawGrid(sx, sy, ex, ey, xNumbers, yNumbers, type);

    _hasChanged = true;

    activateSelect();

    if (obj)
      selectObject(obj);
  }

  function onBondDown () {
    activateBond();
  }

  function activateBond () {
    if (!isButtonEnabled(_bondButton))
      return;

    if (!_bondButton.hasClass('selected')) {
      _me.changeTool();
      _bondButton.addClass('selected');

      bindStart(beginBond);

      if (_onToolChangeCallback)
        _onToolChangeCallback('bond');
    }
  }

  function beginBond (event) {
    disableRedo();

    var x = _me.getX(event);
    var y = _me.getY(event);
    var h = 2/3;
    var ey = y + h;
    var ex = x + (h * _whiteboard.getHeight() / _whiteboard.getWidth());

    _whiteboard.drawBond(x, y, ex, ey);

    enableUndo();

    _hasChanged = true;
  }

  // unifix cubes

  function onUnifixCubeDown () {
    activateUnifixCube();
  }

  function activateUnifixCube () {
    if (!isButtonEnabled(_unifixCubeButton))
      return;

    if (!_unifixCubeButton.hasClass('selected')) {
      _me.changeTool();
      _unifixCubeButton.addClass('selected');

      enableSelect();

      if (_onToolChangeCallback)
        _onToolChangeCallback('unifix cube');
    }

    _unifixCubePicker.show();
  }

  function onUnifixCubeShiftButtonClick() {
    if (!_selectedEvent || _selectedEvent.type !== UnifixCubeStackTool.type || _selectedEvent.cubeColors.length <= 1)
      return;

    var stack = _selectedEvent;
    var bounds;

    var topHeight, cube;

    if (stack.isHorizontal) {
      bounds = UnifixCubeStackTool.getCubeBoundsInStack(_whiteboard, stack, 0);
      topHeight = 10 / _whiteboard.getWidth();

      var cubeWidth = bounds.right - bounds.left;
      cube = _whiteboard.drawUnifixCube(bounds.left, bounds.top, bounds.right, bounds.bottom, stack.isHorizontal, stack.cubeColors[0].color, stack.cubeColors[0].strokeColor);

      stack.coordinates[0] += (20 / _whiteboard.getWidth()) + cubeWidth;

      if (stack.cubeColors.length - 1 === 1)
        stack.coordinates[2] = stack.coordinates[0] + cubeWidth;
      else
        stack.coordinates[2] += (20 / _whiteboard.getWidth()) + topHeight;
    } else {
      bounds = UnifixCubeStackTool.getCubeBoundsInStack(_whiteboard, stack, 0);
      topHeight = 10 / _whiteboard.getHeight();

      var cubeHeight = bounds.bottom - bounds.top;
      bounds.top += (20 / _whiteboard.getHeight());
      bounds.bottom += (20 / _whiteboard.getHeight());
      cube = _whiteboard.drawUnifixCube(bounds.left, bounds.top, bounds.right, bounds.bottom, stack.isHorizontal, stack.cubeColors[0].color, stack.cubeColors[0].strokeColor);

      if (stack.cubeColors.length - 1 === 1)
        stack.coordinates[3] = stack.coordinates[1] + cubeHeight;
      else
        stack.coordinates[3] -= cubeHeight - topHeight;
    }

    stack.cubeColors.shift();

    _hasChanged = true;

    enableUndo();

    selectObject(stack); // force whiteboard to save pre-selected state so canvas renders correctly during move
    _whiteboard.redraw();
  }

  function onUnifixCubePopButtonClick() {
    if (!_selectedEvent || _selectedEvent.type !== UnifixCubeStackTool.type || _selectedEvent.cubeColors.length <= 1)
      return;

    var stack = _selectedEvent;
    var bounds, cube, topHeight;

    if (stack.isHorizontal) {
      bounds = UnifixCubeStackTool.getCubeBoundsInStack(_whiteboard, stack, stack.cubeColors.length - 1);
      topHeight = (10 / _whiteboard.getWidth());

      var cubeWidth = bounds.right - bounds.left;
      bounds.left += (20 / _whiteboard.getWidth());
      bounds.right += (20 / _whiteboard.getWidth());
      cube = _whiteboard.drawUnifixCube(bounds.left, bounds.top, bounds.right, bounds.bottom, stack.isHorizontal, stack.cubeColors[stack.cubeColors.length - 1].color, stack.cubeColors[stack.cubeColors.length - 1].strokeColor);

      if (stack.cubeColors.length - 1 === 1)
        stack.coordinates[2] = stack.coordinates[0] + cubeWidth;
      else
        stack.coordinates[2] -= cubeWidth - topHeight;
    } else {
      bounds = UnifixCubeStackTool.getCubeBoundsInStack(_whiteboard, stack, stack.cubeColors.length - 1);
      topHeight = (10 / _whiteboard.getHeight());

      var cubeHeight = bounds.bottom - bounds.top;
      cube = _whiteboard.drawUnifixCube(bounds.left, bounds.top, bounds.right, bounds.bottom, stack.isHorizontal, stack.cubeColors[stack.cubeColors.length - 1].color, stack.cubeColors[stack.cubeColors.length - 1].strokeColor);

      stack.coordinates[1] += (20 / _whiteboard.getHeight()) + cubeHeight;

      if (stack.cubeColors.length - 1 === 1)
        stack.coordinates[3] = stack.coordinates[1] + cubeHeight;
      else
        stack.coordinates[3] += (20 / _whiteboard.getHeight()) + topHeight;
    }

    stack.cubeColors.pop();

    _hasChanged = true;

    enableUndo();

    selectObject(stack); // force whiteboard to save pre-selected state so canvas renders correctly during move
    _whiteboard.redraw();
  }

  function onUnifixCubeButtonClick (event) {
    var color, strokeColor;

    if (event.target === _horizontalBlueUnifixCubeButton[0] || event.target === _verticalBlueUnifixCubeButton[0]) {
      color = '#2AA6DB';
      strokeColor = '#1295CC';
    } else if (event.target === _horizontalBlackUnifixCubeButton[0] || event.target === _verticalBlackUnifixCubeButton[0]) {
      color = '#363845';
      strokeColor = '#1F212B';
    } else if (event.target === _horizontalGreenUnifixCubeButton[0] || event.target === _verticalGreenUnifixCubeButton[0]) {
      color = '#7FBD41';
      strokeColor = '#74B037';
    } else if (event.target === _horizontalRedUnifixCubeButton[0] || event.target === _verticalRedUnifixCubeButton[0]) {
      color = '#EC412B';
      strokeColor = '#C32814';
    }

    var isHorizontal = event.target === _horizontalBlueUnifixCubeButton[0] || event.target === _horizontalBlackUnifixCubeButton[0] || event.target === _horizontalGreenUnifixCubeButton[0] || event.target === _horizontalRedUnifixCubeButton[0];

    disableRedo();

    var width, height;

    if (isHorizontal) {
      width = 70;
      height = 60;
    } else {
      width = 60;
      height = 70;
    }

    var position = getNextObjectPositionByCount(_cubesAdded, width, height);

    var x = position.x / _whiteboard.getWidth();
    var y = position.y / _whiteboard.getHeight();
    width /= _whiteboard.getWidth();
    height /= _whiteboard.getHeight();

    _whiteboard.drawUnifixCube(x, y, x + width, y + height, isHorizontal, color, strokeColor);

    enableUndo();

    _hasChanged = true;

    _cubesAdded++;

    if (_selectedEvent)
      _whiteboard.select(_selectedEvent); // re-save other objects to be redrawn while existing selected object is moved around
  }

  function getNextObjectPositionByCount(count, width, height) {
    var padding = 20;
    var cols = Math.floor((_whiteboard.getWidth() - padding - 50) / (width + padding));
    var row = Math.floor(count / cols);
    var x = 50 + padding + ((count % cols) * (width + padding));
    var y = (height + padding) * row;

    return { x, y };
  }

  function onAlgebraTileDown() {
    activateAlgebraTile();
  }

  function activateAlgebraTile() {
    if (!isButtonEnabled(_algebraTileButton))
      return;

    if (!_algebraTileButton.hasClass('selected')) {
      _me.changeTool();
      _algebraTileButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('algebra tile');

      enableSelect();
    }

    _algebraTilePicker.show();
  }

  function onAlgebraTileCloseClick() {
    _algebraTilePicker.hide();

    activateSelect();
  }

  function onAlgebraTileClick(event) {
    var type;

    if (event.target === _algebraTile1Button[0])
      type = '1';
    else if (event.target === _algebraTileN1Button[0])
      type = '-1';
    else if (event.target === _algebraTileXButton[0])
      type = 'x';
    else if (event.target === _algebraTileNXButton[0])
      type = '-x';
    else if (event.target === _algebraTileX2Button[0])
      type = 'x^2';
    else if (event.target === _algebraTileNX2Button[0])
      type = '-x^2';

    var size = AlgebraTileTool.getSize(type);
    var height = AlgebraTileTool.LONG_LENGTH;

    var padding = 20;
    var maxWidth = _whiteboard.getWidth() - padding - 50;
    var row = Math.floor(_algebraTilesWidth / maxWidth);
    var x = 50 + padding + (_algebraTilesWidth % maxWidth);
    var y = 50 + (padding * 4) + ((height + padding) * row);

    var obj = _whiteboard.drawAlgebraTile(x / _whiteboard.getWidth(), y / _whiteboard.getHeight(), type);

    _algebraTilesWidth += size.width + 20;

    if (obj) {
      selectObject(obj); // force whiteboard to save pre-selected state so canvas renders correctly during move
      _whiteboard.redraw();
    }

    enableUndo();

    _hasChanged = true;
  }

  function onFractionBarDown() {
    activateFractionBar();
  }

  function activateFractionBar() {
    if (!isButtonEnabled(_fractionBarButton))
      return;

    if (!_fractionBarButton.hasClass('selected')) {
      _me.changeTool();
      _fractionBarButton.addClass('selected');

      enableSelect();

      if (_onToolChangeCallback)
        _onToolChangeCallback('fraction bar');
    }

    _fractionBarPicker.show();
  }

  function onFractionBarCloseClick() {
    _fractionBarPicker.hide();

    activateSelect();
  }

  function getNextObjectPositionBySize(width, height, left, top) {
    var padding = 20;
    var wrapped;

    if (_fractionBarsLeft + 20 + width <= _whiteboard.getWidth()) {
      left += padding;

      wrapped = false;
    } else {
      left = padding;
      top += height + padding;

      wrapped = true;
    }

    return { x: left, y: top, wrapped, padding };
  }

  function onFractionBarClick(event) {
    var type;

    if (event.target === _fractionBar1Button[0])
      type = '1';
    else if (event.target === _fractionBar12Button[0])
      type = '1/2';
    else if (event.target === _fractionBar13Button[0])
      type = '1/3';
    else if (event.target === _fractionBar14Button[0])
      type = '1/4';
    else if (event.target === _fractionBar15Button[0])
      type = '1/5';
    else if (event.target === _fractionBar16Button[0])
      type = '1/6';
    else if (event.target === _fractionBar18Button[0])
      type = '1/8';
    else if (event.target === _fractionBar110Button[0])
      type = '1/10';
    else if (event.target === _fractionBar112Button[0])
      type = '1/12';

    var size = FractionBarTool.getSize(type);
    var width = size.width;
    var height = size.height;

    var position = getNextObjectPositionBySize(width, height, _fractionBarsLeft, _fractionBarsTop);

    var obj = _whiteboard.drawFractionBar(position.x / _whiteboard.getWidth(), position.y / _whiteboard.getHeight(), type);

    _fractionBarsLeft = position.x + width + position.padding;
    _fractionBarsTop = position.y;

    enableUndo();

    _hasChanged = true;

    if (obj)
      selectObject(obj);
  }

  var _shiftUnifixCubeButton = element.find('.unifix-cube-picker .shift-unifix-cube');
  var _popUnifixCubeButton = element.find('.unifix-cube-picker .pop-unifix-cube');
  var _verticalBlueUnifixCubeButton = getElement('vertical-blue-unifix-cube');
  var _horizontalBlueUnifixCubeButton = getElement('horizontal-blue-unifix-cube');
  var _verticalBlackUnifixCubeButton = getElement('vertical-black-unifix-cube');
  var _horizontalBlackUnifixCubeButton = getElement('horizontal-black-unifix-cube');
  var _verticalGreenUnifixCubeButton = getElement('vertical-green-unifix-cube');
  var _horizontalGreenUnifixCubeButton = getElement('horizontal-green-unifix-cube');
  var _verticalRedUnifixCubeButton = getElement('vertical-red-unifix-cube');
  var _horizontalRedUnifixCubeButton = getElement('horizontal-red-unifix-cube');
  var _closeUnifixCubePickerButton = element.find('.unifix-cube-picker .close');

  WhiteboardUi.bindEvent(_shiftUnifixCubeButton, 'click', onUnifixCubeShiftButtonClick);
  WhiteboardUi.bindEvent(_popUnifixCubeButton, 'click', onUnifixCubePopButtonClick);
  WhiteboardUi.bindEvent(_verticalBlueUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_horizontalBlueUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_verticalBlackUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_horizontalBlackUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_verticalGreenUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_horizontalGreenUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_verticalRedUnifixCubeButton, 'click', onUnifixCubeButtonClick);
  WhiteboardUi.bindEvent(_horizontalRedUnifixCubeButton, 'click', onUnifixCubeButtonClick);

  _algebraTile1Button = _algebraTilePicker.find('.algebra-tile-1');
  _algebraTileN1Button = _algebraTilePicker.find('.algebra-tile--1');
  _algebraTileXButton = _algebraTilePicker.find('.algebra-tile-x');
  _algebraTileNXButton = _algebraTilePicker.find('.algebra-tile--x');
  _algebraTileX2Button = _algebraTilePicker.find('.algebra-tile-x2');
  _algebraTileNX2Button = _algebraTilePicker.find('.algebra-tile--x2');

  WhiteboardUi.bindEvent(_algebraTilePicker.find('.close'), 'click', onAlgebraTileCloseClick);
  WhiteboardUi.bindEvent(_algebraTile1Button, 'click', onAlgebraTileClick);
  WhiteboardUi.bindEvent(_algebraTileN1Button, 'click', onAlgebraTileClick);
  WhiteboardUi.bindEvent(_algebraTileXButton, 'click', onAlgebraTileClick);
  WhiteboardUi.bindEvent(_algebraTileNXButton, 'click', onAlgebraTileClick);
  WhiteboardUi.bindEvent(_algebraTileX2Button, 'click', onAlgebraTileClick);
  WhiteboardUi.bindEvent(_algebraTileNX2Button, 'click', onAlgebraTileClick);

  _fractionBar1Button = _fractionBarPicker.find('.fraction-bar-1');
  _fractionBar12Button = _fractionBarPicker.find('.fraction-bar-1-2');
  _fractionBar13Button = _fractionBarPicker.find('.fraction-bar-1-3');
  _fractionBar14Button = _fractionBarPicker.find('.fraction-bar-1-4');
  _fractionBar15Button = _fractionBarPicker.find('.fraction-bar-1-5');
  _fractionBar16Button = _fractionBarPicker.find('.fraction-bar-1-6');
  _fractionBar18Button = _fractionBarPicker.find('.fraction-bar-1-8');
  _fractionBar110Button = _fractionBarPicker.find('.fraction-bar-1-10');
  _fractionBar112Button = _fractionBarPicker.find('.fraction-bar-1-12');

  WhiteboardUi.bindEvent(_fractionBarPicker.find('.close'), 'click', onFractionBarCloseClick);
  WhiteboardUi.bindEvent(_fractionBar1Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar12Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar13Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar14Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar15Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar16Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar18Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar110Button, 'click', onFractionBarClick);
  WhiteboardUi.bindEvent(_fractionBar112Button, 'click', onFractionBarClick);

  WhiteboardUi.bindEvent(_closeUnifixCubePickerButton, 'click', function () {
    _unifixCubePicker.hide();
  });

  // diagrams

  function activateDiagrams(event) {
    if (!isButtonEnabled(_diagramButton))
      return;

    var target = event ? $(event.target) : null;

    if (target && target.prop('tagName').toLowerCase() !== 'button')
      target = target.parent();

    if (!target || target.is(_diagramButton)) {
      if (!_diagramButton.hasClass('selected')) {
        _me.changeTool();
        _diagramButton.addClass('selected');
      }

      togglePicker(_diagramPicker, _diagramButton);
    } else {
      activateDiagram(target);

      _diagramPicker.hide();

      if (_onToolChangeCallback)
        _onToolChangeCallback('diagram');
    }
  }

  function onDiagramDown (event) {
    activateDiagrams(event);
  }

  function activateDiagram (el) {
    _me.changeTool();

    disableRedo();

    var w = _whiteboard.getWidth();
    var h = _whiteboard.getHeight();

    var type = el.attr('class').split(' ');

    _.each(type, function (cls) {
      if (cls !== 'image-button' && cls !== 'ng-binding') {
        type = cls;

        return false;
      }
    });

    if (el.is(_tenFrameButton)) {
      w = 0.75 / 2;
      h = (2 * w) / 5;
      h = (h * _whiteboard.getWidth()) / (_whiteboard.getHeight());

      if (h > w) {
        h = w;
        w = (5 * h) / 2;
        w = (w * _whiteboard.getHeight()) / (_whiteboard.getWidth());
      }
    } else if (el.is(_placeValueMatButton)) {
      w = 0.8;
      h = 0.75;
    } else if (el.is(_partPartWholeButton)) {
      w = 1/3;
      h = w * 0.75;
      h = h * _whiteboard.getWidth() / _whiteboard.getHeight();
    } else if (el.is(_lDiagramButton)) {
      h = 2/3;
      w = h * _whiteboard.getHeight() / _whiteboard.getWidth();
    }

    var sx = (1 - w) / 2;
    var sy = (1 - h) / 2;
    var ex = sx + w;
    var ey = sy + h;

    _whiteboard.drawDiagram(sx, sy, ex, ey, type);

    enableUndo();

    _hasChanged = true;
  }

  /*function adjustElementForContentArea (el, area) {
    var result = {};

    result.left = area.left;
    result.top = area.top;
    result.width = area.width;
    result.height = area.height;

    if (el.css('box-sizing') === 'border-box') {
      var border = el.css('border-width');

      if (border) {
        border = parseInt(border, 10);
        var delta = border * 2;

        result.left -= border;
        result.top -= border;
        result.width += delta;
        result.height += delta;
      }

      var padding = el.css('padding-left');

      if (padding) {
        padding = parseInt(padding, 10);

        result.left -= padding;
        result.width += padding;
      }

      padding = el.css('padding-top');

      if (padding) {
        padding = parseInt(padding, 10);

        result.top -= padding;
        result.height += padding;
      }

      padding = el.css('padding-right');

      if (padding) {
        padding = parseInt(padding, 10);

        result.left -= padding;
        result.width += padding;
      }

      padding = el.css('padding-bottom');

      if (padding) {
        padding = parseInt(padding, 10);

        result.top -= padding;
        result.height += padding;
      }
    }

    if (el.prop('tagName').toLowerCase() === 'textarea')
      result.top += 10;

    el.css('left', result.left + 'px');
    el.css('top', result.top + 'px');

    return result;
  }*/

  function getContentArea (el) {
    el = $(el);

    const canvasTop = $(_me.getCanvasElement()).position().top;

    var result = el.position();
    result.top -= canvasTop;
    result.width = el.outerWidth();

    el.height(1);

    result.height = el[0].scrollHeight + 7;

    return result;
  }

  function endText () {
    if (!_textField)
      return;

    if (_textButton.hasClass('selected')) {
      unbindStart();
      bindStart(beginText);
    }

    const val = _textField.val().trim();

    if (val !== _textField.val())
      _textField.val(val);

    if (val) {
      var area = getContentArea(_textField);

      // make text object same size as text field and render text inside accounting for padding, borderr, and font size
      // adjust text field position and size to keep text same position and size
      _whiteboard.drawText(
        (area.left + 1) / _whiteboard.getWidth(),
        (area.top + 1) / _whiteboard.getHeight(),
        (area.width - 3) / _whiteboard.getWidth(),
        (area.height - 3) / _whiteboard.getHeight(),
        val
      );
    }

    _textField.remove();
    _textField = null;

    enableUndo();

    _hasChanged = true;
  }

  var _textEvent;
  function endEditText (event) {
    if (!_textEvent)
      return;

    var field = $(event.target);

    const val = field.val().trim();

    if (val !== field.val())
      field.val(val);

    if (val) {
      var area = getContentArea(field);
      var fontSize = field.css('font-size').match(/^[0-9\.]+/);

      _whiteboard.editText(
        _textEvent,
        val,
        (area.width - 2) / _whiteboard.getWidth(),
        (area.height - 3) / _whiteboard.getHeight(),
        fontSize / _whiteboard.getHeight()
      );
    }

    _whiteboard.showEvent(_textEvent);

    field.remove();

    _textField = null;
    _textEvent = null;

    _hasChanged = true;
  }

  function editText (event) {
    _textEvent = event;

    var x = event.coordinates[0] * _whiteboard.getWidth();
    var y = event.coordinates[1] * _whiteboard.getHeight();
    var w = event.coordinates[2] * _whiteboard.getWidth();
    var h = event.coordinates[3] * _whiteboard.getHeight();

    var padding = {
      top: 8,
      right: 2,
      bottom: 4,
      left: 1,
    };

    var fontSize = h - padding.top - padding.bottom;
    fontSize = TextTool.getFittedTextFontSize(_whiteboard, event.text, fontSize, w, fontSize);

    // adjust text field position and size to keep text same position and size
    var field = createTextField(x - 1, y - 1, w + 3, h + 3, fontSize, true);

    // adjustElementForContentArea(field, { left: x, top: y, width: w, height: h });

    field.css('paddingLeft', '1px');
    field.val(event.text);
    field.css('color', event.color);

    field.on('blur', endEditText);
    field.on('remove', function (event) {
      $(event.target).off('blur', endEditText);
    });

    _textField = field;

    _whiteboard.hideEvent(event);
  }

  function createElement (name, x, y, w, h) {
    var result;

    result = $('<' + name + '>');
    result.css('position', 'absolute');
    result.css('left', x + 'px');
    result.css('top', $(_me.getCanvasElement()).position().top + y + 'px');

    if (!w)
      w = 300;

    if (!h)
      h = defaultTextFieldHeight;

    result.css('width', w + 'px');
    result.css('height', h + 'px');

    element.append(result);

    return result;
  }

  _me.createElement = createElement;

  function autosizeTextField (textField, fontSize, maxWidth) {
    var resize = function (newText) {
      if (!newText)
        return;

      var field = $(textField);
      var text = field.val().trim();

      var before = text.substr(0, field[0].selectionStart);

      if (newText === '\n')
        newText += String.fromCharCode(10);

      var after = text.substr(field[0].selectionEnd);

      text = before + newText + after;
      var size = TextTool.measureText(_whiteboard, text, fontSize, maxWidth, fontSize + 11);

      size.width += 30;

      if (size.width >= maxWidth)
        size.width = maxWidth;

      size.height += 4;

      field.css('width', size.width + 'px');
      field.css('height', size.height + 'px');
    };

    var onInput = function (event) {
      resize(event.data || event.originalEvent.data);
    };

    textField.on('input', onInput);
    textField.on('remove', function (event) {
      $(event.target).off('input', onInput);
    });

    var onPaste = function (event) {
      resize((event.clipboardData || event.originalEvent.clipboardData).getData('text/plain'));
    };

    textField.on('paste', onPaste);
    textField.on('remove', function (event) {
      $(event.target).off('paste', onPaste);
    });
  }

  function createTextField (x, y, w, h, fontSize, autosize) {
    var result;

    var fontName;
    fontName = _whiteboard.getFont();

    if (!fontSize)
      fontSize = 26;

    if (!w) {
      w = element.width() - x;

      if (300 < w)
        w = 300;
    }

    if (!h)
      h = defaultTextFieldHeight;

    result = createElement('textarea', x, y, w, h);
    result.css('font-family', fontName);
    result.css('font-size', fontSize + 'px');
    result.css('line-height', fontSize + 'px');
    result.css('color', _whiteboard.getStrokeStyle());

    if (autosize)
      autosizeTextField(result, fontSize, element.width() - x);

    setTimeout(function () {
      result[0].focus();
    }, 0);

    return result;
  }

  var _tableTextField;
  var _lastEditedCell;

  function setUpTable (table, cell) {
    var textEvent;

    cell.x = Math.trunc(Math.round(cell.x * _whiteboard.getWidth()));
    cell.y = Math.trunc(Math.round(cell.y * _whiteboard.getHeight()));
    cell.width = Math.trunc(Math.round(cell.width * _whiteboard.getWidth()));
    cell.height = Math.trunc(Math.round(cell.height * _whiteboard.getHeight()));

    _lastEditedCell = textEvent = _whiteboard.editTableText(table, cell.row, cell.col, false);

    _tableTextField = createTextField(cell.x, cell.y, cell.width, cell.height, Math.trunc(cell.height / 3));

    if (cell.text !== false)
      _tableTextField.val(cell.text);

    _tableTextField.addClass('cell');

    var endTableText = function () {
      var field = $(this);

      field.remove();

      const val = field.val().trim();

      _whiteboard.editTableText(table, cell.row, cell.col, val);

      if (cell.text === val || (!cell.text && !val))
        _whiteboard.removeEvent(textEvent);
      else
        _hasChanged = true;

      if (_lastEditedCell === textEvent)
        _lastEditedCell = null;
    };

    var onTableKeypress = function () {
      var el = $(this);

      if (el.get(0).scrollHeight > el.height())
        el.css('font-size', '-=1');
    };

    _tableTextField.on('blur', endTableText);
    _tableTextField.on('keypress', onTableKeypress);
    _tableTextField.on('remove', function () {
      var el = $(this);

      el.off('blur', endTableText);
      el.off('keypress', onTableKeypress);

      _tableTextField = null;
    });
  }

  /*function setUpNumberLine (object, cell) {
    var textEvent;

    cell.x = Math.trunc(Math.round(cell.x * _whiteboard.getWidth()));
    cell.y = Math.trunc(Math.round(cell.y * _whiteboard.getHeight()));
    cell.width = Math.trunc(Math.round(cell.width * _whiteboard.getWidth()));
    cell.height = Math.trunc(Math.round(cell.height * _whiteboard.getHeight()));

    _lastEditedCell = textEvent = _whiteboard.editNumberLineText(object, cell.i, false);

    _tableTextField = createTextField(cell.x, cell.y, cell.width, cell.height, Math.trunc(cell.height));

    if (cell.text !== false)
      _tableTextField.val(cell.text);

    _tableTextField.css('color', object.color);
    _tableTextField.addClass('cell');

    var endNumberLineText = function () {
      var field = $(this);

      field.remove();

      _whiteboard.editNumberLineText(object, cell.i, field.val());

      if (cell.text == field.val() || (!cell.text && !field.val()))
        _whiteboard.removeEvent(textEvent);
      else
        _hasChanged = true;

      if (_lastEditedCell == textEvent)
        _lastEditedCell = null;
    };

    var onNumberLineKeypress = function () {
      var el = $(this);

      if (el.get(0).scrollHeight > el.height())
        el.css('font-size', '-=1');
    };

    _tableTextField.on('blur', endNumberLineText);
    _tableTextField.on('keypress', onNumberLineKeypress);
    _tableTextField.on('remove', function () {
      var el = $(this);

      el.off('blur', endNumberLineText);
      el.off('keypress', onNumberLineKeypress);

      _tableTextField = null;
    });
  }*/

  function editEquation(obj) {
    _editingEquation = obj;

    showEquationEditor(obj.mathML);
  }

  function beginText (event) {
    if (_textEvent && _textField)
      endEditText({ target: _textField });

    if (_spreadsheetTool.commit())
      _hasChanged = true;

    var x = _me.getX(event);
    var y = _me.getY(event);
    var cell = {};
    var object = _whiteboard.getObjectAtPoint(x, y, ['text', 'table'/*, 'numberline'*/, SpreadsheetTool.type, 'equation'], cell);

    if (object && ['table', 'text'/*, 'numberline'*/, SpreadsheetTool.type, 'equation'].includes(object.type)) {
      if (object.type === 'table')
        setUpTable(object, cell);
      else if (object.type === SpreadsheetTool.type) {
        _spreadsheetTool.setUp(object, x - object.coordinates[0], y - object.coordinates[1]);
      /* // TODO: disable in lieu of letting user use text toolelse if (object.type == 'numberline') {
        setUpNumberLine(object, cell);
      }*/} else if (object.type === 'text')
        editText(object);
      else if (object.type === 'equation')
        editEquation(object);
    } else {
      disableRedo();

      unbindStart();
      bindStart(endText);

      x = Math.round(x * _whiteboard.getWidth());
      y = Math.round(y * _whiteboard.getHeight());

      const fontSize = 25;

      var field = createTextField(x, y, null, null, fontSize, true);

      field.css('paddingLeft', '1px');

      field.on('blur', endText);
      field.on('remove', function (event) {
        $(event.target).off('blur', endText);
      });

      _textField = field;
    }
  }

  _me.beginText = beginText;

  function activateText () {
    if (!isButtonEnabled(_textButton))
      return;

    if (!_textButton.hasClass('selected')) {
      _me.changeTool();
      bindStart(beginText);
      _textButton.addClass('selected');

      if (_onToolChangeCallback)
        _onToolChangeCallback('text');
    }
  }

  function onTextDown () {
    activateText();
  }

  function activateImage() {
    if (!isButtonEnabled(_imageButton))
      return;

    onFileClick();

    _fileInput.click();
  }

  function onAddImageDown () {
    activateImage();
  }

  var _isSelectingImage;
  function onFileClick () {
    _fileInput[0].value = null;

    _isSelectingImage = true;

    _me.changeTool();
    _imageButton.addClass('selected');
  }

  function base64URLToBlob (data) {
    // data:image/jpeg;base64,

    var result = data.match(/data:(.*);base64,(.*)/);
    var contentType = result[1];
    var b64Data = result[2];

    var byteCharacters = atob(b64Data);

    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++)
      byteNumbers[i] = byteCharacters.charCodeAt(i);

    var byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], {type: contentType});
  }

  function onReaderLoad (event) {
    addingImage = true;

    if (_uploadCallback) {
      var blob = base64URLToBlob(event.target.result);

      _uploadCallback(blob, onUploadImage);
    } else {
      addImage(event.target.result);
    }
  }

  function addImage (url, x, y, width, height, beforeAdd, mathML) {
    var image;

    if (typeof x === 'function') {
      beforeAdd = x;
      x = y = width = height = undefined;
    }

    disableRedo();

    _me.changeTool();

    image = new Image();

    $(image).on('load', function () {
      var finalWidth, finalHeight;
      var orientation;

      var callback = function () {
        if (beforeAdd) {
          var result = beforeAdd(image, width, height, orientation);

          if (result.width)
            width = result.width;

          if (result.height)
            height = result.height;

          if (result.orientation)
            orientation = result.orientation;
        }

        if (!x && finalWidth) {
          var canvasWidth = _me.canvasElement.width();

          x = (canvasWidth - finalWidth) / 2;
          x /= _whiteboard.getWidth();
        }

        if (!y && finalHeight) {
          var canvasHeight = _me.canvasElement.height();

          y = (canvasHeight - finalHeight) / 2;
          y /= _whiteboard.getHeight();
        }

        var commitImage;

        if (addingImage) {
          commitImage = _whiteboard.addImage;
        } else {
          commitImage = function (x, y, width, height, url, orientation) {
            if (_editingEquation) {
              x = _editingEquation.coordinates[0];
              y = _editingEquation.coordinates[1];
              orientation = _editingEquation.orientation;

              _whiteboard.deleteEvent(_editingEquation);

              _editingEquation = null;
            }

            return _whiteboard.addEquation(x, y, width, height, url, orientation, mathML);
          };
        }

        var img = commitImage(x, y, width, height, url, orientation);

        if (_imageTime !== undefined && _imageTime !== null) {
          img.time = _imageTime; // set time when the user chose the image not when it loaded
          _imageTime = null;
        }

        enableUndo();

        activateSelect();

        if (img)
          selectObject(img);

        _deleteButton.removeClass('off');

        _hasChanged = true;
      };

      if (width && height) {
        finalWidth = width;
        finalHeight = height;
        orientation = 0;

        callback();
      } else {
        EXIF.getData(image, function () {
          var orientation = EXIF.getTag(image, "Orientation");
          var thirdWidth = _me.canvasElement.width() / 3;

          if (image.width > thirdWidth) {
            width = thirdWidth;
            height = (thirdWidth * image.height) / image.width;
          } else {
            width = image.width;
            height = image.height;
          }

          if (orientation < 5) {
            finalWidth = width;
            finalHeight = height;
          } else {
            finalWidth = height;
            finalHeight = width;
          }

          width /= _whiteboard.getWidth();
          height /= _whiteboard.getHeight();

          callback();
        });
      }
    });

    image.src = url;
  }

  function onUploadImage (url) {
    addImage(url);
  }

  var _imageTime;
  function onFileInputChange () {
    var file = _fileInput[0].files[0];

    _isSelectingImage = false;

    if (!file || !file.type.match('image/'))
      return;

    if (_whiteboard.isRecording())
      _imageTime = _whiteboard.getRecordedTime();

    // clear file input value so that change event fires if same file is chosen
    var clone = _fileInput.clone(true);
    _fileInput.replaceWith(clone);
    _fileInput = clone;

    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsDataURL(file);

    if (_onToolChangeCallback)
      _onToolChangeCallback('image');
  }

  var _selectedEvent, _isMoving;

  function move (x, y) {
    var xDelta, yDelta;

    xDelta = x - lastX;
    lastX = x;

    yDelta = y - lastY;
    lastY = y;

    _whiteboard.move(xDelta, yDelta);

    recalculateSelectedBounds();
  }

  function getAngle (x1, y1, x2, y2) {
    return Math.atan2(y2 - y1, x2 - x1);
  }

  var _lastAngle;
  function rotate (x, y) {
    var angle = getAngle(_originX, _originY, x, y);
    var angleDelta = angle - _lastAngle;
    _lastAngle = angle;

    _whiteboard.rotate(angleDelta);
  }

  function resize (x, y) {
    _whiteboard.resize(x, y, _resizeKey);

    recalculateSelectedBounds();
  }

  var _isRotating, _startX, _startY, _originX, _originY, _isResizing, _resizeKey, _rotateBounds, _isMouseDown;
  var RESIZE_PADDING = 5;

  function beginSelect (event) {
    _isMouseDown = true;

    lastX = _me.getX(event);
    lastY = _me.getY(event);

    _isMoving = false;

    if (_selectedEvent) {
      var canTransform = _whiteboard.canTransform(_selectedEvent);
      var canRotate = _whiteboard.canRotate(_selectedEvent);

      if (canTransform || canRotate) {
        _isResizing = false;

        if (canTransform) {
          for (var i = 0; i < _selectedAnchorKeys.length; i++) {
            var key = _selectedAnchorKeys[i];
            var anchor = _selectedAnchors[key];

            anchor.left -= RESIZE_PADDING / _whiteboard.getWidth();
            anchor.right += RESIZE_PADDING / _whiteboard.getWidth();
            anchor.top -= RESIZE_PADDING / _whiteboard.getHeight();
            anchor.bottom += RESIZE_PADDING / _whiteboard.getHeight();

            if (Whiteboard.isPointInRect({ x: lastX, y: lastY }, anchor)) {
              _resizeKey = key;
              _isResizing = true;

              break;
            }
          }
        }

        if (_isResizing) {
          _isRotating = false;
        } else if (canRotate && Whiteboard.isPointInRect({ x: lastX, y: lastY }, _rotateBounds)) {
          _isRotating = true;

          _startX = lastX;
          _startY = lastY;

          _lastAngle = getAngle(_originX, _originY, lastX, lastY);
        } else {
          _isRotating = false;
        }
      } else {
        _isResizing = _isRotating = false;
      }
    }
  }

  var _lastResizePointer;
  function applyCursor (x, y) {
    if (!_cursorAreas && !_isRotating)
      return;

    var pointer;

    if (_isRotating) {
      pointer = 'url(/images/rotate_2.png), default';
    } else if (_isResizing) {
      pointer = _lastResizePointer;
    } else {
      for (var i = _cursorAreas.length - 1; i >= 0; i--) {
        var area = _cursorAreas[i];

        if (Whiteboard.isPointInRect({ x: x, y: y }, area.bounds)) {
          if (area.type === 'move')
            pointer = area.type;
          else if (area.type === 'nesw-resize' || area.type === 'nwse-resize')
            pointer = _lastResizePointer = area.type;
          else if (area.type === 'rotate')
            pointer = 'url(/images/rotate_2.png), default';

          break;
        }
      }
    }

    setCursor(pointer);
  }

  var _lastScale;
  function onSelectGesture (event) {
    var scale, eScale;

    if (event.scale === undefined)
      scale = event.originalEvent.scale;

    eScale = scale;

    if (event.type !== 'gesturestart')
      scale /= _lastScale;

    _lastScale = eScale;

    _whiteboard.scale(scale);

    return false;
  }

  function moveSelect (event) {
    var x, y;

    x = _me.getX(event);
    y = _me.getY(event);

    if (x === lastX && y === lastY)
      return;

    if (_isMouseDown) {
      _isMoving = true;

      if (_isRotating)
        rotate(x, y);
      else if (_isResizing)
        resize(x, y);
      else
        move(x, y);
    }

    applyCursor(x, y, _isMouseDown);

    lastX = x;
    lastY = y;
  }

  var _cursorAreas;
  function recalculateSelectedBounds () {
    if (!_selectedEvent)
      return;

    _selectedBounds = Whiteboard.getEventBounds(_selectedEvent, _whiteboard.getWidth(), _whiteboard.getHeight());
    _selectedBounds.width = _selectedBounds.right - _selectedBounds.left;
    _selectedBounds.height = _selectedBounds.bottom - _selectedBounds.top;
    _originX = _selectedBounds.left + (_selectedBounds.width / 2);
    _originY = _selectedBounds.top + (_selectedBounds.height / 2);

    if (_whiteboard.canTransform(_selectedEvent)) {
      _selectedAnchors = _whiteboard.getBoundsForAnchors(_selectedEvent);
      _selectedAnchorKeys = Object.keys(_selectedAnchors);
    } else {
      _selectedAnchors = _selectedAnchorKeys = null;
    }

    if (_whiteboard.canRotate(_selectedEvent))
      _rotateBounds = _whiteboard.getBoundsForRotateAnchor(_selectedEvent);
    else
      _rotateBounds = null;

    _cursorAreas = getCursorAreas();
  }

  function selectObject (obj, callWhiteboardSelect) {
    if (callWhiteboardSelect !== false) {
      _whiteboard.select(obj);

      _selectedEvent = obj;
    }

    recalculateSelectedBounds();

    applyCursor(lastX, lastY);

    _deleteButton.removeClass('off');

    bindGesture(onSelectGesture);
    bindMove(moveSelect);

    if (obj.type === GridTool.type && obj.gridType !== 'open') {
      _labelsToggleSwitch.prop('checked', obj.showLabels !== false);

      if (obj.showLabels === false)
        _labelsToggle.find('.slider').removeClass('checked');
      else
        _labelsToggle.find('.slider').addClass('checked');

      _labelsToggle.css('display', 'flex');
    } else if (obj.type === UnifixCubeStackTool.type) {
      _unifixCubePicker.show();
    } else if (obj.type !== UnifixCubeTool.type) {
      _unifixCubePicker.hide();
    }
  }

  var _selectedBounds, _selectedAnchors, _selectedAnchorKeys;
  function endSelect () {
    _isMouseDown = _isResizing = false;

    if (_isMoving) {
      _isMoving = false;
      _hasChanged = true;

      var obj = _whiteboard.finishMoving(_selectedEvent);

      if (obj && obj.type)
        selectObject(obj);
    } else {
      _selectedEvent = _whiteboard.select(lastX, lastY);

      if (_selectedEvent)
        selectObject(_selectedEvent, false);
      else
        deselect();
    }
  }

  function deselect() {
    _labelsToggle.hide();

    _whiteboard.deselect();

    _selectedEvent = null;

    _cursorAreas = null;

    unbindMove();

    _deleteButton.addClass('off');
  }

  function setCursor (value) {
    if (!value)
      value = '';

    _me.canvasElement[0].style.cursor = value;
  }

  function bindStart (handler) {
    var h = function (event) {
      handler(event);

      return false;
    };

    _me.canvasElement.unbind("mousedown", h);
    _me.canvasElement.unbind("touchstart", h);
    _me.canvasElement.bind("mousedown", h);
    _me.canvasElement.bind("touchstart", h);
  }

  function bindMove (handler) {
    var h = function (event) {
      handler(event);

      return false;
    };

    _me.canvasElement.unbind("mousemove", h);
    _me.canvasElement.unbind("touchmove", h);
    _me.canvasElement.bind("mousemove", h);
    _me.canvasElement.bind("touchmove", h);
  }

  function bindEnd (handler, bindCancel) {
    var h = function (event) {
      handler(event);

      return false;
    };

    _me.canvasElement.unbind("mouseup", h);
    _me.canvasElement.unbind("touchend", h);
    _me.canvasElement.bind("mouseup", h);
    _me.canvasElement.bind("touchend", h);

    if (bindCancel !== false)
      _me.canvasElement.bind("touchcancel", h);
  }

  function bindGesture (handler) {
    var h = function (event) {
      handler(event);

      return false;
    };

    _me.canvasElement.unbind("gesturestart", h);
    _me.canvasElement.unbind("gesturechange", h);
    _me.canvasElement.unbind("gestureend", h);
    _me.canvasElement.bind("gesturestart", h);
    _me.canvasElement.bind("gesturechange", h);
    _me.canvasElement.bind("gestureend", h);
  }

  function unbindStart () {
    _me.canvasElement.unbind("mousedown");
    _me.canvasElement.unbind("touchstart");
  }

  function unbindMove () {
    setCursor(null);

    _me.canvasElement.unbind("mousemove");
    _me.canvasElement.unbind("touchmove");
  }

  function unbindEnd () {
    _me.canvasElement.unbind("mouseup");
    _me.canvasElement.unbind("touchcancel");
    _me.canvasElement.unbind("touchend");
  }

  function unbindGesture () {
    _me.canvasElement.unbind("gesturestart");
    _me.canvasElement.unbind("gesturechange");
    _me.canvasElement.unbind("gestureend");
  }

  function activateSelect () {
    if (!isButtonEnabled(_selectButton))
      return;

    if (!_selectButton.hasClass('selected')) {
      _me.changeTool();
      _selectButton.addClass('selected');

      enableSelect();

      if (_onToolChangeCallback)
        _onToolChangeCallback('select');
    }
  }

  function enableSelect () {
    bindStart(beginSelect);
    bindEnd(endSelect);
  }

  function onSelectDown () {
    activateSelect();
  }

  _me.setEvents = function (value) {
    _whiteboard.setEvents(value);

    _whiteboard.drawCurrentPage();

    if (value && value.length > 0 && _undoButton)
      enableUndo();
  };

  _me.setAudioURL = function () {
    $translate('READY').then(function (text) {
      _statusLabel.text(text);
    });
  };

  function updatePage () {
    _pageLabel.text((_whiteboard.getPage() + 1) + '/' + _whiteboard.getPageCount());

    if (_whiteboard.getPage() === 0)
      _previousButton.addClass('off');
    else
      _previousButton.removeClass('off');

    _hasChanged = true;
  }

  function activateNext() {
    if (_whiteboard.isPlaying())
      return;

    hideEditors();

    deselect();

    const pages = _whiteboard.getPageCount();

    _whiteboard.advancePage();

    updatePage();

    if (_onPageChangeCallback)
      _onPageChangeCallback(_whiteboard.getPage(), _whiteboard.getPageCount() > pages);
  }

  function onNextDown () {
    activateNext();
  }

  function activatePrevious() {
    if (_whiteboard.isPlaying() || _whiteboard.getPage() === 0)
      return;

    deselect();

    hideEditors();

    _whiteboard.reversePage();

    updatePage();

    if (_onPageChangeCallback)
      _onPageChangeCallback(_whiteboard.getPage(), false);
  }

  function onPreviousDown () {
    activatePrevious();
  }

  function startAutosaving () {
    stopAutosaving();

    if (_saveCallback && _shouldAutosave)
      _saveTimer = setTimeout(fireSaveTimer, 10000);
  }

  function stopAutosaving () {
    clearTimeout(_saveTimer);
    _saveTimer = null;
  }

  _me.setSaveCallback = function (value) {
    _saveCallback = value;

    if (_saveCallback && _shouldAutosave && _hasChanged)
      startAutosaving();
    else
      stopAutosaving();
  };

  _me.setShouldAutosave = function (value) {
    _shouldAutosave = value;

    if (_saveCallback && _shouldAutosave)
      startAutosaving();
    else
      stopAutosaving();
  };

  function save () {
    if (_saveCallback) {
      var events, recordedEvents, promises = [];

      _hasChanged = false;

      var promise = _whiteboard.getEvents().then(function (e) {
        events = e;

        return e;
      });

      promises.push(promise);

      if (_lastRecordedEvents) {
        promise = new Promise(function (resolve) {
          recordedEvents = _lastRecordedEvents;

          resolve(recordedEvents);
        });
      } else {
        promise = _whiteboard.getRecordedEvents(true).then(function (e) {
          recordedEvents = e;

          return e;
        });
      }

      promises.push(promise);

      Promise.all(promises).then(function () {
        _saveCallback(_whiteboard, events, recordedEvents);
      });
    }
  }

  _me.saveIfChanged = function () {
    if (_hasChanged) {
      _hasChanged = false;

      save();
    }
  };

  function fireSaveTimer () {
    if (_saveCallback && element.parents('body').length > 0) {
      _me.saveIfChanged();

      startAutosaving();
    }
  }

  function activateClear() {
    if (!isButtonEnabled(_clearButton))
      return;

    _me.changeTool();

    disableRedo();

    _whiteboard.clear();

    enableUndo();

    _hasChanged = true;

    if (_onToolChangeCallback)
      _onToolChangeCallback('clear');
  }

  function onClearDown () {
    activateClear();
  }

  /**
   * Adds all the UI's needed action listeners for buttons
   * and other UI elements.
   */

  function activateUndo() {
    if (!isButtonEnabled(_undoButton))
      return;

    if (_undoButton.hasClass('off'))
      return;

    if (_lastEditedCell) {
      _whiteboard.removeEvent(_lastEditedCell);
      _lastEditedCell = null;
    }

    var canUndo = _whiteboard.undo();

    if (canUndo)
      _undoButton.removeClass('off');
    else
      _undoButton.addClass('off');

    _redoButton.removeClass('off');

    _hasChanged = true;

    if (_onToolChangeCallback)
      _onToolChangeCallback('undo');
  }

  function onUndoDown () {
    activateUndo();
  }

  function activateRedo() {
    if (!isButtonEnabled(_redoButton))
      return;

    if (_redoButton.hasClass('off'))
      return;

    var canRedo = _whiteboard.redo();

    if (canRedo)
      _redoButton.removeClass('off');
    else
      _redoButton.addClass('off');

    _undoButton.removeClass('off');

    _hasChanged = true;

    if (_onToolChangeCallback)
      _onToolChangeCallback('redo');
  }

  function onRedoDown () {
    activateRedo();
  }

  function disableRedo () {
    _redoButton.addClass('off');
  }

  function activateDelete() {
    if (!isButtonEnabled(_deleteButton))
      return;

    var event = _whiteboard.getSelectedEvent();

    if (!event) {
      if (_onToolChangeCallback)
        _onToolChangeCallback('delete');

      return;
    }

    disableRedo();

    _whiteboard.deleteEvent(event);

    deselect();

    _hasChanged = true;

    if (_onToolChangeCallback)
      _onToolChangeCallback('delete');
  }

  function onDeleteDown () {
    activateDelete();
  }

  _me.setOnChangeCallback = value => {
    _whiteboard.setOnChangeCallback(value);
  };

  var _onToolChangeCallback;
  _me.setOnToolChangeCallback = function (value) {
    _onToolChangeCallback = value;
  };

  var _onPageChangeCallback;
  _me.setOnPageChangeCallback = function (value) {
    _onPageChangeCallback = value;
  };

  var _onStatusChangeCallback;
  _me.setOnStatusChangeCallback = function (value) {
    _onStatusChangeCallback = value;
  };

  function isButtonEnabled(button) {
    return button.css('display') !== 'none';
  }

  function setButtonEnabled (button, value) {
    if (value)
      button.show();
    else
      button.hide();
  }

  var toolIdClassMap = {
    'text': 'add-text',
    'pen': 'draw',
    'highlighter': 'highlighter',
    'image': 'add-image',
    'shapes': 'add-shape',
    'stamps': 'stamp',
    'numberBond': 'bond',
    'unifixCube': 'unifix-cube',
    'diagrams': 'diagram',
    'array': 'array',
    'numberLine': 'numberline',
    'protractor': 'protractor',
    'table': 'spreadsheet',
    'grid': 'grid',
    'equation': 'equation',
    'algebraTile': 'algebra-tile',
    'fractionBar': 'fraction-bar'
  };

  _me.setToolOrder = function (value) {
    var tools = element.find('.tools>.tool');

    var toolContainer = element.find('.tools');

    value.forEach(function (type) {
      var tool;

      tools.each(function (i, toolEl) {
        toolEl = $(toolEl);

        if (toolEl.hasClass(toolIdClassMap[type])) {
          tool = toolEl;

          return false;
        }
      });

      if (tool)
        toolContainer.append(tool);
    });

    toolContainer.append(_hiddenToolsEl);

    var imageOffset = toolContainer.find('.add-image').position();
    toolContainer.find('input[type=image]').css('left', imageOffset.left + 'px');
  };

  _me.setHiddenTools = function (value) {
    if (value && value.length > 0) {
      var tools = element.find('.tools>.tool');

      value.forEach(type => {
        var tool;

        tools.each(function (i, toolEl) {
          toolEl = $(toolEl);

          if (toolEl.hasClass(toolIdClassMap[type])) {
            tool = toolEl;

            return false;
          }
        });

        if (tool)
          _moreToolsList.append(tool);
      });

      _moreToolsButton.show();
    } else {
      _moreToolsButton.hide();
    }
  };

  _me.setAlgebraTilesEnabled = function (value) {
    setButtonEnabled(_algebraTileButton, value);
  };

  _me.setPenEnabled = function (value) {
    setButtonEnabled(_drawButton, value);
  };

  _me.setHighlighterEnabled = function (value) {
    setButtonEnabled(_highlighterButton, value);
  };

  _me.setEraserEnabled = function (value) {
    setButtonEnabled(_eraserButton, value);
  };

  _me.setFractionBarsEnabled = function (value) {
    setButtonEnabled(_fractionBarButton, value);
  };

  _me.setImageEnabled = function (value) {
    setButtonEnabled(_imageButton, value);
    setButtonEnabled(_fileInput, value);
  };

  _me.setShapesEnabled = function (value) {
    setButtonEnabled(_shapeButton, value);
  };

  _me.setEllipseEnabled = function (value) {
    setButtonEnabled(_ellipseButton, value);
  };

  _me.setTriangleEnabled = function (value) {
    setButtonEnabled(_triangleButton, value);
  };

  _me.setLineEnabled = function (value) {
    setButtonEnabled(_lineButton, value);
  };

  _me.setProtractorEnabled = function (value) {
    setButtonEnabled(_protractorButton, value);
  };

  _me.setRectangleEnabled = function (value) {
    setButtonEnabled(_rectangleButton, value);
  };

  _me.setCurvedArrowEnabled = function (value) {
    setButtonEnabled(_curvedArrowButton, value);
  };

  _me.setVerticalCurvedArrowEnabled = function (value) {
    setButtonEnabled(_verticalCurvedArrowButton, value);
  };

  _me.setStampsEnabled = function (value) {
    setButtonEnabled(_stampButton, value);
  };

  _me.setDiagramEnabled = function (value) {
    setButtonEnabled(_diagramButton, value);
  };

  _me.setTableEnabled = function (value) {
    setButtonEnabled(_tableButton, value);
  };

  _me.setSpreadsheetEnabled = function (value) {
    setButtonEnabled(_spreadsheetButton, value);
  };

  _me.setArrayEnabled = function (value) {
    setButtonEnabled(_arrayButton, value);
  };

  _me.setBondEnabled = function (value) {
    setButtonEnabled(_bondButton, value);
  };

  _me.setGridEnabled = function (value) {
    setButtonEnabled(_gridButton, value);
  };

  _me.setUnifixCubeEnabled = function (value) {
    setButtonEnabled(_unifixCubeButton, value);
  };

  _me.setEquationEnabled = function (value) {
    setButtonEnabled(_equationButton, value);
  };

  _me.setNumberLineEnabled = function (value) {
    setButtonEnabled(_numberLineButton, value);
  };

  _me.setTextEnabled = function (value) {
    setButtonEnabled(_textButton, value);
  };

  _me.setUndoEnabled = function (value) {
    setButtonEnabled(_undoButton, value);
  };

  _me.setRedoEnabled = function (value) {
    setButtonEnabled(_redoButton, value);
  };

  _me.setSelectEnabled = function (value) {
    setButtonEnabled(_selectButton, value);

    if (!value)
      setButtonEnabled(_deleteButton, value);
  };

  _me.setDeleteEnabled = function (value) {
    setButtonEnabled(_deleteButton, value);
  };

  _me.setClearEnabled = function (value) {
    setButtonEnabled(_clearButton, value);
  };

  var _equationEditor, _equationEditorContainer, _renderEquationCallback, _closeEquationButton, _equationEditorBody;

  function closeEquation () {
    if (_equationEditorContainer)
      _equationEditorContainer.hide();
  }

  function commitEquation () {
    var markup = _equationEditor.getMathML();

    if (!markup || markup === '<math xmlns="http://www.w3.org/1998/Math/MathML"/>' || markup === '<math></math>')
      return;

    if (_whiteboard.isRecording())
      _imageTime = _whiteboard.getRecordedTime();

    addingImage = false;

    var model = _equationEditor.getEditorModel();
    var styles = model.getCurrentStyles();
    var font = styles.getFontFamily();
    var size = styles.getFontSize();

    if (font === 'inherit')
      font = equationParameters.fontFamily;

    if (size === 0)
      size = parseInt(equationParameters.fontSize);

    _renderEquationCallback(markup, font, size).then(function (url, x, y, width, height, beforeAdd) {
      return addImage(url, x, y, width, height, beforeAdd, markup);
    });
  }

  function onEquationCloseClick() {
    _editingEquation = null;

    closeEquation();
  }

  function equationSaveClick () {
    commitEquation();

    _equationEditor.setMathML('<math></math>');

    closeEquation();
  }

  var equationParameters = {
    language: 'en',
    fontSize: '28px',
    hand: false,
    fontStyle: 'normal',
    fontFamily: 'Arial',
    toolbar: '<toolbar ref="general" removeLinks="true"><removeTab ref="contextual" /></toolbar>'
  };

  function createEquationEditor(mathML) {
    _equationEditorContainer = element.find('.equation-editor-container:first');
    _equationEditorContainer.draggable();

    _equationEditorBody = _equationEditorContainer.find('.equation-editor:first');

    _closeEquationButton = _equationEditorContainer.find('button.close:first');
    WhiteboardUi.bindEvent(_closeEquationButton, 'click', onEquationCloseClick);

    WhiteboardUi.bindEvent(_equationEditorContainer.find('.save:first'), 'click', equationSaveClick);

    var newParams = {};

    if (mathML)
      newParams.mml = mathML;

    var params = Object.assign({}, equationParameters, newParams);

    _equationEditor = com.wiris.jsEditor.JsEditor.newInstance(params);
    _equationEditor.insertInto(_equationEditorBody[0]);
  }

  function showEquationEditor (mathML) {
    if (!_renderEquationCallback)
      return;

    if (_equationEditor) {
      if (mathML)
      _equationEditor.setMathML(mathML);
    } else {
      createEquationEditor(mathML);
    }

    if (_equationEditorContainer.css('display') === 'none')
      _equationEditorContainer.show();
    else
      _equationEditorContainer.hide();
  }

  function activateEquation() {
    if (!isButtonEnabled(_equationButton))
      return;

    if (!_equationButton.hasClass('selected')) {
      _me.changeTool();
      _equationButton.addClass('selected');
    }

    showEquationEditor();
  }

  function onEquationDown () {
    activateEquation();
  }

  _me.setRenderEquationCallback = function (value) {
    _renderEquationCallback = value;
  };

  function addTooltip (el, text, position, pointer) {
    if (/ipad|iphone/.test(window.navigator.userAgent.toLowerCase()))
      return;

    if (!position)
      position = 'bottom center';

    if (!pointer)
      pointer = 'top left';

    el.qtip({
      content: text,
      position: {
        at: position,
        my: pointer
      },
      style: {
        classes: 'whiteboard-qtip',
        def: false
      },
      hide: { when: { event: 'inactive' } }
    });
  }

  function getCursorAreas () {
    var result = [];

    if (_selectedEvent) {
      result.push({ type: 'move', bounds: _selectedBounds });

      if (_selectedAnchorKeys) {
        for (var i = 0; i < _selectedAnchorKeys.length; i++) {
          var key = _selectedAnchorKeys[i];
          var type;

          if (key === 'topLeft' || key === 'bottomRight')
            type = 'nwse-resize';
          else if (key === 'topRight' || key === 'bottomLeft')
            type = 'nesw-resize';
          else
            type = 'resize';

          var bounds = _selectedAnchors[key];

          bounds.left -= RESIZE_PADDING / _whiteboard.getWidth();
          bounds.right += RESIZE_PADDING / _whiteboard.getWidth();
          bounds.top -= RESIZE_PADDING / _whiteboard.getHeight();
          bounds.bottom += RESIZE_PADDING / _whiteboard.getHeight();

          result.push({ type: type, bounds: bounds });
        }
      }

      if (_rotateBounds)
        result.push({ type: 'rotate', bounds: _rotateBounds });
    }

    return result;
  }

  function onEraseDown () {
    if (!_eraserButton.hasClass('selected')) {
      _me.changeTool();

      _eraserButton.addClass('selected');

      _me.activateEraser();

      if (_onToolChangeCallback)
        _onToolChangeCallback('erase');
    }
  }

  function unbindAll () {
    unbindStart();
    unbindMove();
    unbindEnd();
    unbindGesture();
  }

  function hideEditors () {
    if (_spreadsheetTool.commit())
      _hasChanged = true;

    closeEquation();
  }

  var _imageButton, _colorButtons, _clearButton, _nextButton, _previousButton;
  var _tableButton, _spreadsheetButton, _arrayButton, _bondButton, _numberLineButton;
  var _rectangleButton, _ellipseButton, _triangleButton, _lineButton, _curvedArrowButton, _verticalCurvedArrowButton;
  var _rightTriangleButton, _trapezoidButton, _octagonButton, _hexagonButton, _pentagonButton, _parallelogramButton;
  var _numberLineChooser, _zeroToTenLink, _zeroToTwentyLink, _blankLink, _openLink, _generatorLink, _gridButton, _fullGridButton, _positiveGridButton, _openGridButton, _gridChooser, _unifixCubeButton;

  _drawButton = getElement('draw');
  _highlighterButton = getElement('highlighter');
  _imageButton = getElement('add-image');
  _fileInput = element.find('input[type=file]:first');
  _eraserButton = getElement('eraser');
  _recordButton = getElement('record');
  _stopButton = getElement('stop');
  _shapeButton = getElement('add-shape');
  _stampButton = getElement('stamp');
  _diagramButton = getElement('diagram');
  _colorButtons = element.find('.colorpicker button');
  _rectangleButton = getElement('rectangle');
  _ellipseButton = getElement('ellipse');
  _triangleButton = getElement('triangle');
  _lineButton = getElement('line');
  _curvedArrowButton = getElement('curved-arrow');
  _verticalCurvedArrowButton = getElement('vertical-curved-arrow');
  _rightTriangleButton = getElement('right-triangle');
  _trapezoidButton = getElement('trapezoid');
  _parallelogramButton = getElement('parallelogram');
  _hexagonButton = getElement('hexagon');
  _pentagonButton = getElement('pentagon');
  _octagonButton = getElement('octagon');
  _oneBlockButton = getElement('one-block');
  _tenBlockButton = getElement('ten-block');
  _hundredBlockButton = getElement('hundred-block');
  _placeValueMatButton = getElement('place-value-mat');
  _tenFrameButton = getElement('ten-frame');
  _partPartWholeButton = getElement('part-part-whole');
  _lDiagramButton = getElement('l-diagram');
  _tableButton = getElement('table');
  _spreadsheetButton = getElement('spreadsheet');
  _arrayButton = getElement('array');
  _bondButton = getElement('bond');
  _numberLineButton = getElement('numberline');
  _numberLineChooser = getElement('number-line-chooser');
  _zeroToTenLink = _numberLineChooser.find('.0-10:first');
  _zeroToTwentyLink = _numberLineChooser.find('.0-20:first');
  _blankLink = _numberLineChooser.find('.blank:first');
  _openLink = _numberLineChooser.find('.open:first');
  _generatorLink = _numberLineChooser.find('.generator:first');
  _textButton = getElement('add-text');
  _selectButton = getElement('select');
  _deleteButton = getElement('delete');
  _clearButton = getElement('clear');
  _nextButton = getElement('next');
  _previousButton = getElement('previous');
  _undoButton = getElement('undo');
  _redoButton = getElement('redo');
  _equationButton = getElement('equation');
  _gridButton = getElement('grid');
  _gridChooser = getElement('grid-chooser');
  _fullGridButton = _gridChooser.find('.full-grid');
  _positiveGridButton = _gridChooser.find('.positive-grid');
  _openGridButton = _gridChooser.find('.open-grid');
  _unifixCubeButton = getElement('unifix-cube');
  _protractorButton = getElement('protractor');
  _algebraTileButton = getElement('algebra-tile');
  _fractionBarButton = getElement('fraction-bar');
  _moreToolsButton = getElement('more-tools');

  const keyboardShortcutUnbinds = [];

  function bindKeyboardShortcut(letter, callback) {
    const keys = ['command+shift+alt+' + letter, 'ctrl+shift+alt+' + letter];

    Mousetrap.bind(keys, callback);

    const result = () => {
      Mousetrap.unbind(keys);
    };

    keyboardShortcutUnbinds.push(result);

    return result;
  }

  bindKeyboardShortcut('d', () => {
    activatePen();
  });

  bindKeyboardShortcut('h', () => {
    _me.activateHighlighter();
  });

  bindKeyboardShortcut('i', () => {
    activateImage();
  });

  bindKeyboardShortcut('e', () => {
    _me.activateEraser();
  });

  bindKeyboardShortcut('r', () => {
    activateRecord();
  });

  bindKeyboardShortcut('p', () => {
    activateRecord();
  });

  bindKeyboardShortcut('s', () => {
    stop();
  });

  bindKeyboardShortcut('j', () => {
    activateShapes();
  });

  bindKeyboardShortcut('m', () => {
    activateStamps();
  });

  bindKeyboardShortcut('k', () => {
    activateDiagrams();
  });

  bindKeyboardShortcut('t', () => {
    activateText();
  });

  bindKeyboardShortcut('l', () => {
    activateSelect();
  });

  // UI calls this array
  bindKeyboardShortcut('a', () => {
    selectTable();
  });

  // UI calls this table
  bindKeyboardShortcut('b', () => {
    selectArray();
  });

  bindKeyboardShortcut('n', () => {
    activateBond();
  });

  bindKeyboardShortcut('u', () => {
    selectNumberLine();
  });

  bindKeyboardShortcut('del', () => {
    activateDelete();
  });

  bindKeyboardShortcut('c', () => {
    activateClear();
  });

  bindKeyboardShortcut('left', () => {
    activatePrevious();
  });

  bindKeyboardShortcut('right', () => {
    activateNext();
  });

  bindKeyboardShortcut('z', () => {
    activateUndo();
  });

  bindKeyboardShortcut('y', () => {
    activateRedo();
  });

  bindKeyboardShortcut('q', () => {
    activateEquation();
  });

  bindKeyboardShortcut('g', () => {
    selectGrid();
  });

  bindKeyboardShortcut('x', () => {
    activateUnifixCube();
  });

  bindKeyboardShortcut('o', () => {
    activateProtractor();
  });

  bindKeyboardShortcut('w', () => {
    activateAlgebraTile();
  });

  bindKeyboardShortcut('f', () => {
    activateFractionBar();
  });

  function addListeners () {
    $translate([
      'PEN',
      'HIGHLIGHTER',
      'IMAGE',
      'ERASER',
      'RECORD',
      'STOP',
      'SHAPES',
      'STAMPS',
      'DIAGRAMS',
      'TEXT',
      'SELECT',
      'TABLE',
      'ARRAY',
      'NUMBER_BOND',
      'NUMBER_LINE',
      'DELETE_2',
      'CLEAR_ALL',
      'PREVIOUS_PAGE',
      'NEXT_PAGE',
      'UNDO',
      'REDO',
      'EQUATION',
      'GRID',
      'UNIFIX_CUBE',
      'PROTRACTOR',
      'ALGEBRA_TILE',
      'FRACTION_BAR',
      'MORE_TOOLS'
    ]).then(function (translations) {
      WhiteboardUi.bindEvent(_drawButton, 'mouseup', onDrawDown);
      addTooltip(_drawButton, translations.PEN, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_highlighterButton, 'mouseup', onHighlighterDown);
      addTooltip(_highlighterButton, translations.HIGHLIGHTER, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_imageButton, 'mouseup', onAddImageDown);
      addTooltip(_imageButton, translations.IMAGE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_fileInput, 'change', onFileInputChange);
      WhiteboardUi.bindEvent(_fileInput, 'click', onFileClick);
      addTooltip(_fileInput, translations.IMAGE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_eraserButton, 'mouseup', onEraseDown);
      addTooltip(_eraserButton, translations.ERASER, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_recordButton, 'mouseup', onRecordDown);
      addTooltip(_recordButton, translations.RECORD, 'top center', 'bottom center');

      WhiteboardUi.bindEvent(_stopButton, 'mouseup', onStopDown);
      addTooltip(_stopButton, translations.STOP, 'top center', 'bottom center');

      WhiteboardUi.bindEvent(_shapeButton, 'mouseup', onShapeDown);
      addTooltip(_shapeButton, translations.SHAPES, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_stampButton, 'mouseup', onStampDown);
      addTooltip(_stampButton, translations.STAMPS, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_diagramButton, 'mouseup', onDiagramDown);
      addTooltip(_diagramButton, translations.DIAGRAMS, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_unifixCubeButton, 'mouseup', onUnifixCubeDown);
      addTooltip(_unifixCubeButton, translations.UNIFIX_CUBE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_algebraTileButton, 'mouseup', onAlgebraTileDown);
      addTooltip(_algebraTileButton, translations.ALGEBRA_TILE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_fractionBarButton, 'mouseup', onFractionBarDown);
      addTooltip(_fractionBarButton, translations.FRACTION_BAR, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_colorButtons, 'mouseup', onColorDown);

      bindShapeButton(_rectangleButton, _whiteboard.getRectangleTool());
      bindShapeButton(_ellipseButton, _whiteboard.getEllipseTool());
      bindShapeButton(_triangleButton, _whiteboard.getTriangleTool());
      bindShapeButton(_lineButton, _whiteboard.getLineTool());
      bindShapeButton(_curvedArrowButton, _whiteboard.getCurvedArrowTool());
      bindShapeButton(_verticalCurvedArrowButton, _whiteboard.getVerticalCurvedArrowTool());
      bindShapeButton(_rightTriangleButton, _whiteboard.getRightTriangleTool());
      bindShapeButton(_trapezoidButton, _whiteboard.getTrapezoidTool());
      bindShapeButton(_parallelogramButton, _whiteboard.getParallelogramTool());
      bindShapeButton(_hexagonButton, _whiteboard.getHexagonTool());
      bindShapeButton(_pentagonButton, _whiteboard.getPentagonTool());
      bindShapeButton(_octagonButton, _whiteboard.getOctagonTool());

      bindStampButton(getElement('one-block'));
      bindStampButton(_tenBlockButton);
      bindStampButton(getElement('hundred-block'));
      bindStampButton(getElement('stick-figure'));
      bindStampButton(getElement('one-cent'));
      bindStampButton(getElement('five-cent'));
      bindStampButton(getElement('ten-cent'));
      bindStampButton(getElement('twenty-five-cent'));
      //bindStampButton(_oneCentBackButton = getElement('one-cent-back'));
      //bindStampButton(_fiveCentBackButton = getElement('five-cent-back'));
      //bindStampButton(_tenCentBackButton = getElement('ten-cent-back'));
      bindStampButton(element.find('.stamps .dollar'));
      bindStampButton(getElement('red-disc'));
      bindStampButton(getElement('yellow-disc'));

      WhiteboardUi.bindEvent(_placeValueMatButton, 'mouseup', onDiagramDown);
      WhiteboardUi.bindEvent(_tenFrameButton, 'mouseup', onDiagramDown);
      WhiteboardUi.bindEvent(_partPartWholeButton, 'mouseup', onDiagramDown);
      WhiteboardUi.bindEvent(_lDiagramButton, 'mouseup', onDiagramDown);

      WhiteboardUi.bindEvent(_tableButton, 'mouseup', onTableDown);
      addTooltip(_tableButton, translations.TABLE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_spreadsheetButton, 'mouseup', onSpreadsheetDown);
      addTooltip(_spreadsheetButton, translations.TABLE, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_arrayButton, 'mouseup', onArrayDown);
      addTooltip(_arrayButton, translations.ARRAY, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_bondButton, 'mouseup', onBondDown);
      addTooltip(_bondButton, translations['NUMBER_BOND'], 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_numberLineButton, 'mouseup', onNumberLineDown);
      addTooltip(_numberLineButton, translations['NUMBER_LINE'], 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_zeroToTenLink, 'mouseup', onNumberLineChoiceDown);
      WhiteboardUi.bindEvent(_zeroToTwentyLink, 'mouseup', onNumberLineChoiceDown);
      WhiteboardUi.bindEvent(_blankLink, 'mouseup', onNumberLineChoiceDown);
      WhiteboardUi.bindEvent(_openLink, 'mouseup', onNumberLineChoiceDown);
      WhiteboardUi.bindEvent(_generatorLink, 'mouseup', onNumberLineChoiceDown);

      WhiteboardUi.bindEvent(_gridButton, 'mouseup', onGridDown);
      addTooltip(_gridButton, translations['GRID'], 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_fullGridButton, 'mouseup', onGridChoiceDown);
      WhiteboardUi.bindEvent(_positiveGridButton, 'mouseup', onGridChoiceDown);
      WhiteboardUi.bindEvent(_openGridButton, 'mouseup', onGridChoiceDown);

      WhiteboardUi.bindEvent(_textButton, 'mouseup', onTextDown);
      addTooltip(_textButton, translations.TEXT, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_selectButton, 'mouseup', onSelectDown);
      addTooltip(_selectButton, translations.SELECT, 'center left', 'center right');

      WhiteboardUi.bindEvent(_deleteButton, 'mouseup', onDeleteDown);
      addTooltip(_deleteButton, translations['DELETE_2'], 'center left', 'center right');

      WhiteboardUi.bindEvent(_clearButton, 'mouseup', onClearDown);
      addTooltip(_clearButton, translations['CLEAR_ALL'], 'center left', 'center right');

      WhiteboardUi.bindEvent(_nextButton, 'mouseup', onNextDown);
      addTooltip(_nextButton, translations['NEXT_PAGE'], 'top left', 'bottom right');

      WhiteboardUi.bindEvent(_previousButton, 'mouseup', onPreviousDown);
      addTooltip(_previousButton, translations['PREVIOUS_PAGE'], 'top right', 'bottom left');

      WhiteboardUi.bindEvent(_undoButton, 'mouseup', onUndoDown);
      addTooltip(_undoButton, translations.UNDO, 'center left', 'center right');

      WhiteboardUi.bindEvent(_redoButton, 'mouseup', onRedoDown);
      addTooltip(_redoButton, translations.REDO, 'center left', 'center right');

      WhiteboardUi.bindEvent(_equationButton, 'mouseup', onEquationDown);
      addTooltip(_equationButton, translations.EQUATION, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_protractorButton, 'mouseup', onProtractorDown);
      addTooltip(_protractorButton, translations.PROTRACTOR, 'top center', 'bottom left');

      WhiteboardUi.bindEvent(_moreToolsButton, 'mouseup', onMoreToolsButtonDown);
      addTooltip(_moreToolsButton, translations['MORE_TOOLS'], 'top center', 'bottom left');
    });
  }

  _whiteboard = new Whiteboard();
  _whiteboard.init(_me.canvasElement[0], false, _recordAudio);

  _me.getWidth = _whiteboard.getWidth;
  _me.getHeight = _whiteboard.getHeight;
  _me.getFont = _whiteboard.getFont;
  _me.beginEditing = _whiteboard.beginEditing;
  _me.editSpreadsheet = _whiteboard.editSpreadsheet;
  _me.getRelative = _whiteboard.getRelative;

  if (!isReadOnly) {
    addListeners();

    _me.activatePencil();

    _me.save = save;

    $(element).on('remove', function () {
      if (!_whiteboard.isRecording()) {
        hideEditors();

        _me.saveIfChanged();
      }

      keyboardShortcutUnbinds.forEach(unbind => unbind());
    });

    // BEAC-4175: iOS 14 Safari causes whiteboard to blur when asking permission or typing on keyboard
    /*$(window).blur(function(e) {
      if (!_isSelectingImage)
        pauseRecording();
    });*/
  }

  // labels toggle
  _labelsToggle = element.find('.labels-toggle');
  _labelsToggleSwitch = _labelsToggle.find('input');
  _labelsToggleCloseButton = _labelsToggle.find('.close');

  WhiteboardUi.bindEvent(_labelsToggle.find('input'), 'input', function (event) {
    if (_selectedEvent) {
      _selectedEvent.showLabels = event.target.checked;

      if (_selectedEvent.showLabels)
        _labelsToggle.find('.slider').addClass('checked');
      else
        _labelsToggle.find('.slider').removeClass('checked');

      _whiteboard.redraw();

      _hasChanged = true;
    }
  });

  WhiteboardUi.bindEvent(_labelsToggleCloseButton, 'click', function () {
    _labelsToggle.hide();
  });

  if (typeof localStorage !== 'undefined'
    && localStorage.getItem('isWhiteboardToolsExpanded')
    && _moreToolsButton.is(':visible')) {
    _moreToolsList.css('display', 'inline-block');

    _moreToolsButton.addClass('open');
  }
}

WhiteboardUi.floatElementOverElement = function (over, under, callback) {
  under.css('position', 'relative');
  over.css('position', 'absolute');

  var handler = function () {
    var size = {
      width: under.width(),
      height: under.height()
    };

    if (callback)
      size = callback(size);

    over.attr('width', parseInt(size.width + '', 10));
    over.attr('height', parseInt(size.height + '', 10));
  };

  handler();

  $(window).bind('resize', handler);
  $(window).on('remove', function () {
    $(window).off('resize', handler);
  });

  return handler;
};

WhiteboardUi.blinkElement = function (element) {
  var interval = setInterval(function () {
    var opacity = element.css('opacity');

    if (opacity === undefined || opacity === 1)
      element.css('opacity', 0);
    else
      element.css('opacity', 1);
  }, 1000);

  return function () {
    clearInterval(interval);

    element.css('opacity', 1);
  };
};

WhiteboardUi.bindEvent = function (element, type, handler) {
  element = $(element);

  element.on(type, handler);
  element.on('remove', function (event) {
    $(event.target).off(type, handler);
  });
};

function TimeLabel (element) {
  var _me = this, _time = 0, _timer;
  var _updateCallback, _isPaused, _isPlaying;

  function update () {
    var minutes, seconds, time;

    time = Math.floor(_time);

    minutes = Math.floor(time / 60);
    seconds = time % 60;

    if (minutes < 10)
      minutes = '0' + minutes;

    if (seconds < 10)
      seconds = '0' + seconds;

    element.text(minutes + ':' + seconds);

    if (_updateCallback)
      _updateCallback(_time);
  }

  function fireTimer () {
    if (!_isPlaying)
      return;

    _timer = null;

    _me.setTime(_time + 1);

    if (_updateCallback)
      _updateCallback(_time);

    if (!_isPlaying)
      return;

    scheduleTimer();
  }

  function scheduleTimer () {
    _timer = setTimeout(fireTimer, 1000);
  }

  _me.start = function () {
    var wasPaused = _isPaused;
    _isPlaying = true;

    if (_updateCallback)
      _updateCallback(_time);

    if (_updateCallback)
      _updateCallback(_time);

    scheduleTimer();

    if (wasPaused)
      _isPaused = false;
    else
      _me.reset();
  };

  _me.pause = function () {
    _isPlaying = false;
    _isPaused = true;

    clearTimeout(_timer);
    _timer = null;
  };

  _me.stop = function () {
    if (_timer) {
      clearTimeout(_timer);
      _timer = null;
    }

    _isPlaying = _isPaused = false;
  };

  _me.reset = function () {
    _me.setTime(0);
  };

  _me.setTime = function (value) {
    var wasPlaying;

    if (_timer) {
      wasPlaying = true;

      _me.pause();
    }

    _time = value;

    update();

    if (wasPlaying)
      _me.start();
  };

  _me.setUpdateCallback = function (value) {
    _updateCallback = value;
  };

  $(element).on('remove', function () {
    _me.stop();
  });
}

export default WhiteboardUi;
export { WhiteboardUi, TimeLabel };
