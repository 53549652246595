import '../educatorGuide/educatorGuideController';
import 'src/components/shared/user_data';
import 'src/app/navigation/teachernavbarController';
import './viewAssigned.css';

function ProfLearningController ($scope, $stateParams,  UserManager, $controller, CueThinkConfig) {
    "ngInject";

    $controller('TeacherNavBarController', { '$scope': $scope });

    $scope.user = UserManager.getUser();

    var materialId = $stateParams.id;
    var materials = CueThinkConfig.subMenuUrls.courseMaterials;

    function digest () {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    if (materialId == "calendar") {
        $scope.url = CueThinkConfig.calendar.url;
        $scope.inCalendarPage = true;
    } else if ($stateParams.url) {
        $scope.url = $stateParams.url;
    } else {
      for (var i = 0; i < materials.length; i++) {
        if (materials[i].id == materialId) {
          $scope.url = materials[i].url;

          break;
        }
      }
    }
  }

export default ProfLearningController;
