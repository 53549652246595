<template>
  <div
    class="progress-icons"
    :class="{ dark: darkMode }">
    <!--<div
      class="explore-progress-icon"
      :class="{ complete: isComplete(thinklet, 'explore'), 'in-progress': isInProgress(thinklet, 'explore') }">
      E
    </div>-->

    <div
      class="plan-progress-icon"
      :class="{ complete: isComplete(thinklet, 'plan'), 'in-progress': isInProgress(thinklet, 'plan') }">
      P
    </div>

    <div
      class="solve-progress-icon"
      :class="{ complete: isComplete(thinklet, 'solve'), 'in-progress': isInProgress(thinklet, 'solve') }">
      S
    </div>

    <!--<div
      class="review-progress-icon"
      :class="{ complete: isComplete(thinklet, 'review'), 'in-progress': isInProgress(thinklet, 'review') }">
      R
    </div>-->
  </div>
</template>

<script>
import UserManager from 'src/components/shared/user_data';
import { isNewDesign } from 'core/common/user-modes';

export default {
  mixins: [require('core/common/mixins/user.mixin').default],

  props: {
    darkMode: Boolean,
    thinklet: Object
  },

  computed: {
    permissionUser() {
      return UserManager.getShared().getUser();
    }
  },

  methods: {
    getFieldsForStep (thinklet, step) {
      if (!thinklet.rubric && !thinklet.UPSR && !thinklet.progress)
        return {};

      var result;

      if (step === 'understand') {
        if (thinklet.UPSR)
          result = { notice: thinklet.UPSR.understand.known, wonder: thinklet.UPSR.understand.wondering, estimate: thinklet.UPSR.understand.answer };
        else if (thinklet.progress)
          result = { notice: thinklet.progress.noticings, wonder: thinklet.progress.wondering, estimate: thinklet.tryingToSolve };
        else
          result = { notice: null, wonder: null, estimate: null };
      } else if (step === 'plan') {
        if (thinklet.UPSR)
          result = { strategies: thinklet.UPSR.plan.strategies, plan: thinklet.UPSR.plan.plan };
        else if (thinklet.progress)
          result = { strategies: thinklet.strategies.length, plan: thinklet.progress.plan };
        else
          result = { strategies: null, plan: null };
      } else if (step === 'solve') {
        if (thinklet.UPSR)
          result = { staticData: thinklet.UPSR.solve.staticData, videoURL: thinklet.UPSR.solve.videoUrl };
        else if (thinklet.progress)
          result = { staticData: thinklet.progress.staticData, videoURL: thinklet.progress.videoUrl };
        else
          result = { staticData: null, videoURL: null };
      } else if (step === 'review') {
        if (thinklet.UPSR) {
          result = {
            answer: thinklet.UPSR.review.answer
          };

          if (isNewDesign(this.permissionUser)) {
            result.recording_math_checked = thinklet.UPSR.review.recordingChecked;
            result.recording_math_unchecked = thinklet.UPSR.review.recordingUnChecked === 0;
          } else {
            result.recording_math_checked = thinklet.UPSR.review.mathChecked;
            result.recording_math_unchecked = thinklet.UPSR.review.matchUnChecked === 0;
          }
        } else if (thinklet.progress) {
          result = {
            answer: thinklet.progress.answer
          };

          if (isNewDesign(this.permissionUser)) {
            result.recording_math_checked = thinklet.progress.recordingChecked;
            result.recording_math_unchecked = thinklet.progress.recordingUnChecked === 0;
          } else {
            result.recording_math_checked = thinklet.progress.mathChecked;
            result.recording_math_unchecked = thinklet.progress.matchUnChecked === 0;
          }
        } else {
          result = { recording_math_unchecked: null, answer: null };
        }
      }

      return result;
    },

    isComplete(thinklet, step) {
      const fields = this.getFieldsForStep(thinklet, step);
      let result = true;

      Object.keys(fields).forEach(function (key) {
        var value = fields[key];

        if (!value)
          result = false;
      });

      return result;
    },

    isInProgress(thinklet, step) {
      var fields = this.getFieldsForStep(thinklet, step);
      var result = false;
      var fieldMissing  = false;

      Object.keys(fields).forEach(function (key) {
        var value = fields[key];

        if (value)
          result = true;
        else
          fieldMissing = true;
      });

      if (result && !fieldMissing)
        result = false;

      return result;
    },
  }
}
</script>

<style scoped>
.progress-icons>* {
  width: 20px;
  height: 20px;
  line-height: 15px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  padding: 2px;
  border-color: #979797;
  font-size: 11px;
}

  .progress-icons .in-progress {
    background-color: rgb(224, 246, 255);
    color: #363845;
  }

  .progress-icons .complete {
    background-color: #2AA6DB;
    color: white;
  }

.dark .progress-icons>* {
  border-color: white;
}

.dark .progress-icons .in-progress {
  background-color: lightskyblue;
  color: #363845;
  border-color: lightskyblue;
}

.dark .progress-icons .complete {
  border-color: #2AA6DB;
}
</style>
