<template>
  <cuethink-pop-up
    class="beliefs-probe"
    :class="{ video: isVideoVisible }"
    :showClose="false"
    :submit-enabled="submitEnabled"
    :submit-text="translate('DONE')"
    :title="translate(isVideoVisible ? 'POPUP_INSTRUCTIONS' : 'WHAT_DO_YOU_THINK_ABOUT_SOLVING_THIS_PROBLEM_RIGHT_NOW')"
    @submit="onSubmit">
    <template v-if="isVideoVisible">
      <button
        class="back-btn"
        tabindex="0"
        @click="onBackButtonClick">
        {{ 'BACK' | translate | lowercase }}
      </button>

      <iframe
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        frameborder="0"
        height="360"
        title="Beliefs Instructions Video"
        width="640"
        :src="videoUrl">
      </iframe>
    </template>
    <template v-else>
      <vue-slider
        :marks="[0, 25, 50, 75, 100]"
        v-model="belief">
      </vue-slider>

      <div class="labels">
        <span>{{ 'I_DONT_KNOW_IF_I_CAN_SOLVE_IT_YET' | translate }}</span>

        <span>{{ 'I_THINK_I_CAN_SOLVE_IT' | translate }}</span>

        <span>{{ 'I_KNOW_I_CAN_SOLVE_IT' | translate }}</span>
      </div>

      <a
        class="watch-instructions"
        href="#"
        tabindex="0"
        @click.stop.prevent="onWatchInstructionsLinkClick">
        {{ 'WATCH_INSTRUCTIONS_AGAIN' | translate }}
      </a>
    </template>
  </cuethink-pop-up>
</template>

<script>
import 'src/images/play button blue.svg';
import 'vue-slider-component/theme/default.css';
import UserManager from '../../components/shared/user_data';
import RouteManager from 'core/common/route-manager';

export default {
  mixins: [require('core/common/mixins/translate.mixin').default],

  components: {
    CuethinkPopUp: () => import('core/common/cuethink-pop-up'),
    VueSlider: () => import('vue-slider-component')
  },

  filters: {
    lowercase: require('core/common/filters/lowercase.filter').default,
  },

  props: {
    problemId: Number,
    problemName: String,
    thinkletId: Number
  },

  data() {
    return {
      belief: 0,
      isVideoVisible: false,
      submitEnabled: false,
      videoUrl: 'https://player.vimeo.com/video/822361154?h=0fcf01db49&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
      videoWatched: false
    };
  },

  watch: {
    belief() {
      this.submitEnabled = true;
    }
  },

  mounted() {
    const addScript = () => {
      const script = document.createElement('script');
      script.src = 'https://player.vimeo.com/api/player.js';
      this.$el.append(script);
    };

    if (this.$el.append) {
      addScript();
    } else {
      setTimeout(() => addScript(), 0);
    }
  },

  methods: {
    onBackButtonClick() {
      this.isVideoVisible = false;
    },

    onSubmit() {
      if (this.isVideoVisible) {
        this.isVideoVisible = false;
      } else {
        UserManager.getShared().submitBeliefsProbe(this.thinkletId, this.problemId, this.problemName, this.belief, this.videoWatched);

        this.$emit('BeliefsProbeSubmit', this.belief);
      }
    },

    onWatchInstructionsLinkClick() {
      this.isVideoVisible = true;

      this.videoWatched = true; // TODO
    }
  }
}
</script>

<style scoped>
  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

    .labels>* {
      width: 100px;
      font-weight: 700;
    }

  .watch-instructions {
    text-decoration: none;
    background-image: url('/images/play button blue.svg');
    background-size: 10px 10px;
    background-position: left center;
    background-repeat: no-repeat;
    color: black;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 180px;
    display: block;
    border-right: 0 !important;
    border-bottom: 1px solid rgb(38, 169, 224);
    font-size: 14px;
    padding-left: 10px;
    position: relative;
    top: 60px;
  }

  .back-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    background-image: url(/images/Icon_LeftArrow.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 20px;
    color: black;
    font-size: 20px;
  }
</style>

<style>
  .beliefs-probe .body {
    min-width: 500px !important;
    width: auto !important;
  }

  .beliefs-probe .title {
    font-size: 20px !important;
    margin-bottom: 30px !important;
    color: #979797;
    width: 400px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
  }

  .beliefs-probe.video .title {
    width: auto !important;
  }

  .beliefs-probe .vue-slider {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .beliefs-probe .vue-slider-rail {
    height: 10px;
  }

  .beliefs-probe .vue-slider-marks {
    position: relative;
    top: -45px;
  }

    .beliefs-probe .vue-slider-mark-step {
      display: none;
    }

  .beliefs-probe .vue-slider-dot-handle {
    background-color: rgb(38, 169, 224);
  }

  .beliefs-probe:not(.video) .buttons {
    position: relative;
    top: -40px;
  }

  .beliefs-probe .buttons {
    justify-content: center;
  }

  .beliefs-probe .submit {
    flex-grow: 0 !important;
    width: 100px;
  }

  .beliefs-probe.video .submit {
    display: none;
  }
</style>