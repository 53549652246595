import RegularPolygonTool from './regular-polygon-tool';
import { inherit } from './base-tool';

export default function HexagonTool (whiteboard) {
  var me = this;

  RegularPolygonTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, color) {
      this.id = whiteboard.getNextId();
      this.type = HexagonTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  this.getSideCount = function () {
    return 6;
  };
}

inherit(HexagonTool, RegularPolygonTool);

HexagonTool.type = 'hexagon';
