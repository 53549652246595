import DashboardDirective from './dashboardDirective';
import VueDashboardDirective from './vue-dashboard.directive';
import tpl from './dashboard-screen.directive.tpl.html';
import {setUserModesScopeMembers} from '../common/user-modes';
import NotificationCenter from '../notifications/notification-center';

export default function DashboardScreenDirective() {
  return {
    link: function ($scope) {
      $scope.$on('edit-thinklet', (event, thinklet) => $scope.editThinklet(thinklet));
      $scope.$on('start-thinklet', (event, thinklet) => $scope.startThinklet(thinklet));
      $scope.$on('view-thinklet', (e, { studentRubric, thinkletId }) => {
        $scope.viewThinklet(thinkletId, { studentRubric });
      });

      setUserModesScopeMembers($scope);

      $scope.toggleClasses = function () {
        $scope.classesVisible = !$scope.classesVisible;

        if ($scope.classesVisible) {
          $scope.$$postDigest(function () {
            var button = $('navbar .class-chooser:first');
            var list = $('navbar ul.class-list:first');
            var offset = button.offset();

            $('dashboard:first').append(list);
            list.css('left', offset.left + 'px');
            list.css('top', offset.top + button.height() + 'px');
          });
        }
      };

      $scope.chooseClass = function (cls) {
        $scope.selectClass(cls);

        $scope.classesVisible = false;

        NotificationCenter.getShared().post('ChoseClass', cls);
      };
    },
    restrict: 'E',
    scope: {
      copyExemplarThinklet: '=',
      deleteThinklet: '=',
      dashboard: '=',
      dashboardState: '=',
      defaultAvatarColors: '=',
      deleteNotification: '=',
      editThinklet: '=',
      fetchHasBeenIdle: '=',
      fetchHasSeenTrialEndPopUp: '=',
      gallery: '=',
      galleryState: '=',
      getTrialDays: '=',
      isCueteachEnabled: '=',
      isReadyToExploreEnabled: '=readyToExplore',
      joinClass: '=',
      loadAssignments: '=',
      loadNewNotifications: '=',
      loadNotifications: '=',
      loadThinklets: '=',
      logInIntercom: '&',
      logOut: '=logout',
      navigateAssignments: '=',
      navigateCurrentProblems: '=',
      navigateJoinClass: '=',
      navigateOverdueProblems: '=',
      navigateRubric: '=',
      onSubmitMindWanderingProbe: '&',
      overdue: '=',
      profile: '=',
      profileState: '=',
      selectClass: '=',
      setHasSeenTrialEndPopUp: '=',
      setSeenReadyToExplore: '=',
      settings: '=',
      setUpNavigation: '=',
      startThinklet: '=',
      state: '=',
      teacherDashboard: '=',
      thinkletManager: '=',
      tutorial: '=',
      viewThinklet: '=',
      user: '=',
      userManager: '='
    },
    template: tpl
  };
}

angular.module('cuethink.dashboard', ['cuethink.thinklet', 'cuethink.navigation', 'cuethink.common', 'cuethink.trial'])
  .directive('dashboardScreen', DashboardScreenDirective)
  .directive('dashboard', DashboardDirective)
  .directive('vueDashboard', VueDashboardDirective);
