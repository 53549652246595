import './navbar';
import './navbar.css';
import tpl from './teacher_nav.html';
import 'core/common/commonModule'; // cuethink dropdown and cuethink dropdown menu
require('./courseMaterialPopup.partial.html');
require('./tutorialPopup.partial.html');
require('./fullTourPopup.partial.html');
import languages from 'src/app/languages';
import { translate } from 'core/common/translation-helpers';
import TutorialsPopUpDirective from 'core/popups/tutorials-popup.directive';
import { setUserModesScopeMembers } from '../common/user-modes';
import 'angular-ui-router';
import { Permissions } from '../permissions/permissions';

function TeacherNavBarDirective ($translate, $rootScope, CueThink, $state, PermissionsManager) {
	"ngInject";

	return {
		restrict: 'E',
		transclude: true,
		template: tpl,
		link: function ($scope) {
			var userAgent = window.navigator.userAgent.toLowerCase();
			var ios = /ipad|iphone/.test(userAgent);
			var qtip;

			if (!ios) {
				translate('YOU_CAN_GO_TO_STUDENT_MODE_AT_ANY_TIME', $translate, $rootScope, $scope, function (msg) {
					qtip = $('.user-menu:first button:first').qtip({
						content: msg,
						position: {
							at: 'center right',
							my: 'left center',
						},
						style: {
							classes: 'cuethink-qtip-style',
							def: false
						},
						hide: { when: { event: 'inactive' }, delay: 2000 }
					});

					qtip = qtip.qtip('api');
				});
			}

			if ($scope.user.type == 'Student' || $scope.user.expired)
				$scope.goDashboard();

			setUserModesScopeMembers($scope);

			// Check User Trial Info
			if (!$scope.isEFUser() && !$scope.isEF23User() && !$scope.user.isCleverAccount) {
				$scope.getUserTrialInfo(function (data) {
					if (data.expired) {
						$scope.trialExpired();

						return;
					}

					$scope.isTrialUser = data.isTrialUser;

					if (data.isTrialUser)
						$scope.daysLeft = data.daysLeft;

					digest();
				});
			}

			$scope.loginIntercom();

			$scope.goToDashboard =  function () {
				if (qtip)
					qtip.hide();

				$scope.goDashboard();
			};

			function getIndexOfTutorial (tutorial, tutorials) {
				var keys = Object.keys(tutorials);

				for (var i = 0; i < keys.length; i++) {
					var items = tutorials[keys[i]];
					var result;

					if (Array.isArray(items))
						result = items.indexOf(tutorial);
					else
						result = getIndexOfTutorial(tutorial, items);

					if (result !== -1)
						return result;
				}

				return -1;
			}

			$scope.closeSubMenu = function () {
				$scope.isSubMenuVisible = false;
			};

			var _lastOpenedTutorial;
			$scope.openTutorial = function (tutorial) {
				if (tutorial.url) {
					$scope.closeSubMenu();

					$scope.tutorial = tutorial;

					try {
						Intercom('trackEvent', 'User viewed a tutorial video', { tutorial: tutorial.name });
					} catch (e) {
					}

					$scope.tutorialPopup = true;
				} else if (tutorial.tutorials) {
					if (tutorial === _lastOpenedTutorial && $scope.isSubMenuVisible) {
						$scope.closeSubMenu();
					} else {
						_lastOpenedTutorial = tutorial;

						$scope.subMenuTutorials = tutorial.tutorials;

						$scope.isCMSubMenuVisible = false;
						$scope.isSubMenuVisible = true;

						$scope.$$postDigest(function () {
							var submenu = $('.tutorial-list .submenu:first');

							var i = getIndexOfTutorial(tutorial, $scope.subMenuUrls);
							var top = submenu.parent().find('*:eq(' + i + ')').offset().top;
							var overflow = top + submenu.height() - $(window).height();

							if (overflow > 0)
								top -= overflow;

							submenu.css('top', top + 'px');
						});
					}
				}
			};

			$scope.openResource = function (resource) {
				$scope.presentTutorial({ url: resource.url, page: 'resources' });
			};

			$scope.toggleMaterialSubMenu = function () {
				if ($scope.isCMSubMenuVisible) {
					$scope.isCMSubMenuVisible = false;
				} else {
					$scope.isSubMenuVisible = false;
					$scope.isCMSubMenuVisible = true;

					$scope.$$postDigest(function () {
						var submenu = $('.prof-learning .submenu:first');

						var top = submenu.prev().offset().top;
						var overflow = top + submenu.height() - $(window).height();

						if (overflow > 0)
							top -= overflow;

						submenu.css('top', top + 'px');
					});
				}
			};

			$scope.openMaterial = function (courseMaterial) {
				try {
					Intercom('trackEvent', 'USER VIEWED PL COURSE', { "content": "Course Materials" });
				} catch (e) {
				}

				$scope.presentTutorial({"id": courseMaterial.id});
			};

			$scope.openCalendar = function () {
				try {
					Intercom('trackEvent', 'USER VIEWED PL COURSE', { "content": "Calendar" });
				} catch (e) {
				}

				$scope.presentTutorial({"id": 'calendar'});
			};

			$scope.closePopup = function () {
				$('video').trigger('pause');

				$scope.tutorialPopup = false;
			};

			$scope.closeCourseMaterialPopup = function () {
				$scope.courseMaterialPopup = false;
			};

			$scope.openFullTour = function () {
				try {
					Intercom('trackEvent', 'User viewed a full tour slides', {});
				} catch (e) {
				}


				$scope.fullTourPopup = true;
			};

			$scope.closeFullTourPopup = function () {
				$scope.fullTourPopup = false;
			};

			$scope.showIntercom = function () {
				try {
					Intercom('show');
				} catch (e) {
				}
			};

			$scope.myInterval = 5000;
			$scope.noWrapSlides = false;

			if ($scope.loadSlides) {
				var success = function (images) {
					$scope.slides = [];

					for (var i = 0; i <= images.length; i++) {
						$scope.slides.push({
							image: images[i],
						});
					}
				};

				var promise = $scope.loadSlides(success);

				if (promise)
					promise.then(success);
			}

			$scope.viewModel = {
				isEFTutorialVisible: false,
				isEquityTutorialVisible: false,
				isLanguageMenuOpen: false,
				isTutorialsPopUpVisible: false,
				isUserMenuOpen: false,
				language: null,
				languages: languages
			};

			if (typeof localStorage !== 'undefined') {
				var lang = localStorage.getItem('language');

				if (!lang)
					lang = 'en';

				$scope.viewModel.language = $scope.viewModel.languages.find(function (l) {
					return l.id === lang;
				});
			}

			function digest () {
				if ($scope.$$phase)
				  return;

				try {
				  $scope.$digest();
				} catch (e) {
				}
			}

			if ($scope.startTutorial) {
				$scope.beginTutorial = function () {
					$scope.isQuaidMenuVisible = false;

					$scope.isAssignmentMenuOpen = true;

					$scope.$$postDigest(function () {
						$scope.startTutorial();
					});
				};
			}

			$scope.onMouseOver = function (event, isOpenProperty) {
				$scope[isOpenProperty] = true;
			};

			$scope.onMouseLeave = function (isOpenProperty) {
				$scope[isOpenProperty] = false;
			};

			if ($scope.user.cueteachEnabled)
				$scope.isCueTeachEnabled = true;

			/*
			<li><a href="https://www.cuethink.com/pricing_directsale" target="_blank">Pricing</a></li>
			<li><a href="https://file.cuethink.com/documents/cuethink_resources/Pilot%20Brochure%20V.1.pdf" target="_blank">Pilot</a></li>
			<li><a href="https://file.cuethink.com/documents/cuethink_resources/CueThink%20PLP%20Brochure_v1.3.pdf" target="_blank">Professional Learning Program</a></li>
			*/

			translate(['CONTACT_FOR_SCHOOL_PRICING', 'CONTACT', 'CONTACT_FOR_PRICING', 'I_AM_A_PARENT_GUARDIAN_AND_AM_INTERESTED_IN_PURCHASING_CUETHINK', 'INDIVIDUAL_PRICING'], $translate, $rootScope, $scope, function (translations) {
				var title;
				var body;

				if ($scope.user.type === 'Parent' || $scope.user.type === 'Homeschool')
					title = translations.CONTACT_FOR_PRICING;
				else
					title = translations.CONTACT_FOR_SCHOOL_PRICING;

				var links = [{
					name: title,
					url: 'mailto:mmoller@cuethink.com' + (body ? '?body=' + encodeURIComponent(body) : '')
				}];

				$scope.viewModel.trialLinks = links;
			});

			$scope.switchLanguage = function (language) {
				$translate.use(language.id);

				if (typeof localStorage !== 'undefined')
					localStorage.setItem('language', language.id);

				$scope.viewModel.language = language;

				$scope.viewModel.isLanguageMenuOpen = false;

				$('.language-menu').removeClass('open');
			};

			function bindEvent (el, type, handler) {
				$(el).on(type, handler);

				$(el).on('remove', function () {
					$(el).off(type, handler);
				});
			}

			bindEvent($('.language-menu'), 'mouseleave', function () {
				$scope.viewModel.isLanguageMenuOpen = false;
			});

			$scope.onLanguageMenuToggle = function () {
				if ($scope.viewModel.isLanguageMenuOpen)
					$scope.viewModel.isUserMenuOpen = false;
			};

			$scope.onUserMenuToggle = function () {
				if ($scope.viewModel.isUserMenuOpen)
					$scope.viewModel.isLanguageMenuOpen = false;
			};

			$scope.isSpanishEnabled = function () {
				if (typeof localStorage === 'undefined') {
					return false;
				} else {
					var lang = localStorage.getItem('language');

					return lang === 'es';
				}
			};

			$scope.onLanguageMenuMouseLeave = function () {
				$scope.viewModel.isLanguageMenuOpen = false;
			};

			$scope.onUserMenuMouseLeave = function () {
				$scope.viewModel.isUserMenuOpen = false;
			};

			$scope.onClickTutorialMenuItem = function () {
				if ($scope.isEFUser() || $scope.isEF23User())
					$scope.viewModel.isTutorialsPopUpVisible = true;
				else
					$scope.startTutorial();
			};

			$scope.onCloseTutorialsPopUp = function () {
				$scope.viewModel.isTutorialsPopUpVisible = false;
			};

			$scope.startEducatorModeTutorial = function () {
				$scope.onCloseTutorialsPopUp();

				$scope.startTutorial();
			};

			$scope.startEFTutorial = function () {
				$scope.onCloseTutorialsPopUp();

				$scope.viewModel.isEFTutorialVisible = true;

				CueThink.logBehaviorEvent('Tutorial EF Tour', $state.current.name);
			};

			$scope.onCloseEFTutorial = function () {
				$scope.viewModel.isEFTutorialVisible = false;
			};

			$scope.startEquityTipsTutorial = function () {
				$scope.onCloseTutorialsPopUp();

				$scope.viewModel.isEquityTutorialVisible = true;

				CueThink.logBehaviorEvent('Tutorial Equity Tour', $state.current.name);
			};

			$scope.onCloseEquityTutorial = function () {
				$scope.viewModel.isEquityTutorialVisible = false;
			};

			$scope.isRubricEnabled = function () {
				return PermissionsManager.getPermission(Permissions.Rubric);
			};

			$scope.isLanguageToggleEnabled = function () {
				return PermissionsManager.getPermission(Permissions.Languages);
			};
		},
		scope: TeacherNavBarDirective.createScope()
	};
}

TeacherNavBarDirective.createScope = function (props) {
	var teacherNavBarProps = {
		currentPage: '=',
		getUserTrialInfo:'=',
		goDashboard: '=dashboard',
		isTeacherExpired: '=',
		loadSlides: '=',
		loginIntercom: '=',
		logOut: '=',
		navigateToAssignments: '&viewAssignments',
		navigateToClassProgress: '&classProgress',
		navigateToCommunity: '&community',
		navigateToCreateProblem: '&createProblem',
		navigateToEducatorGuide: '&educatorGuide',
		navigateToLearningHub: '&learningHub',
		navigateToManageStudents: '&manageStudents',
		navigateToMonitorReport: '&monitorReport',
		navigateToProblemBank: '&problemBank',
		navigateToResourceBank: '&resourceBank',
		navigateToReviewReport: '&reviewReport',
		navigateToRubricSummary: '&rubric',
		navigateToSettings: '&settings',
		presentTutorial: '=',
		subMenuUrls: '=',
		startTutorial: '=',
		trialExpired: '=',
		user: '='
	};

	if (props)
		return Object.assign(teacherNavBarProps, props);
	else
		return teacherNavBarProps;
};

angular.module('cuethink.navigation')
	.directive('teachernavbar', TeacherNavBarDirective)
	.directive('tutorialsPopup', TutorialsPopUpDirective)
	.filter('trustUrl', ['$sce', function ($sce) {
        return function (recordingUrl) {
            return $sce.trustAsResourceUrl(recordingUrl);
        };
    }]);

export default TeacherNavBarDirective;
