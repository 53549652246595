export function BaseWhiteboardTool (whiteboard, $, _) {
  this.whiteboard = whiteboard;

  this.getDataConstructor = function () {
    // override
  };

  this.getType = function () {
    return this.constructor.type;
  };

  this.isRotatable = function () {
    return this.isTransformable();
  };

  this.isSelectable = function () {
    return false;
  };

  this.isTransformable = function () {
    return true;
  };

  this.isUndoable = function () {
    return false;
  };

  this.hasSelectionBorder = function () {
    return true;
  };

  this.render = function (obj) {
    var context = whiteboard.getContext();

    whiteboard.setFillColor(null);
    context.save();

    this.setStyles(obj);
    this.draw(obj);

    context.restore();
  };

  this.setStyles = function (obj) {
    // override
  };

  this.draw = function (obj) {
    // override
  };

  this.finishMoving = function (obj) {
  };
}

BaseWhiteboardTool.bindEvent = function ($, element, type, handler) {
  element = $(element);

  element.on(type, handler);
  element.on('remove', function (event) {
    $(event.target).off(type, handler);
  });
};

BaseWhiteboardTool.isiOS = function () {
  var userAgent = window.navigator.userAgent;

  return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
};

BaseWhiteboardTool.prototype.getX = function (event) {
  var x;

  if (event.pageX) {
    x = event.pageX;
  } else if (event.touches && event.touches.length > 0) {
    x = event.touches.item(0).clientX;
  } else if (event.originalEvent) {
    if (event.originalEvent.touches && event.originalEvent.touches.length > 0)
      x = event.originalEvent.touches.item(0).clientX;
    else if (event.originalEvent.layerX)
      x = event.originalEvent.layerX;
    else
      return -1;
  } else {
    return -1;
  }

  var cssx = (x - this.whiteboard.getCanvasElement().offset().left);
  var xrel = this.whiteboard.getRelative().width;
  var canvasx = cssx * xrel;
  return canvasx / this.whiteboard.getWidth();
};

BaseWhiteboardTool.prototype.getY = function (event) {
  var y;

  if (event.pageY) {
    y = event.pageY;
  } else if (event.touches && event.touches.length > 0) {
    y = event.touches.item(0).clientY;
  } else if (event.originalEvent) {
    if (event.originalEvent.touches && event.originalEvent.touches.length > 0)
      y = event.originalEvent.touches.item(0).clientY;
    else if (event.originalEvent.layerY)
      y = event.originalEvent.layerY;
    else
      return -1;
  } else {
    return -1;
  }

  var cssy = (y - this.whiteboard.getCanvasElement().offset().top);
  var yrel = this.whiteboard.getRelative().height;
  var canvasy = cssy * yrel;
  return canvasy / this.whiteboard.getHeight();
};

export function inherit (child, parent) {
  child.prototype = Object.create(parent.prototype, {
    constructor: {value: child, configurable: true}
  });
}
