import TeacherScreenDirective from 'core/teacherscreen/teacherScreenDirective';
import RubricDirective from 'core/rubric/rubricDirective';
import '../rosterView.css';
import tpl from './rosterClassesDirective.tpl.html';
import _ from 'underscore';
import 'angular-translate';
import { translate } from 'core/common/translation-helpers';
import { setUserModesScopeMembers } from '../../common/user-modes';
import '../images/InfoIcon.svg';

function RosterClassesDirective ($q, $translate, $rootScope) {
  "ngInject";

  var linkFunction = function ($scope) {
    var constantStudents;
    var constantThinklets = [];
    var constantProblems;
    var constantClasses;
    var _resultsPerPage = 20;
    var _hasReachedLastPage;
    var thinkletsDefer = $q.defer();

    $scope.viewModel = {
      labels: null,
      translations: {
        LAST: null
      }
    };

    $scope.timeFilters = [
      { time: { days: -14 } },
      { time: { months: -1 } },
      { time: { months: -3 } },
      { time: { months: -6 } },
      { time: { years: -1 } },
      { time: null }
    ];

    $scope.getTimeFilterLabel = function (item, last) {
      var result;

      if (item) {
        if (item.time && last) {
          result = $scope.viewModel.translations.LAST + ' ';
        } else {
          result = '';
        }

        if (item.time) {
          if (item.time.days === -14)
            result += '2 ' + $translate.instant('WEEKS');
          else if (item.time.months === -1)
            result += $translate.instant('MONTH');
          else if (item.time.months === -3)
            result += '3 ' + $translate.instant('MONTHS');
          else if (item.time.months === -6)
            result += '6 ' + $translate.instant('MONTHS');
          else if (item.time.years === -1)
            result += $translate.instant('YEAR');
        } else {
          return $translate.instant('SHOWING_ALL');
        }
      } else {
        result = null;
      }

      return result;
    };

    $scope.page = -1;
    $scope.sort = 'time';
    $scope.reverse = true;

    $scope.modes = [
      { id: 'overview', name: null  },
      { id: 'answers', name: null }
    ];

    translate(['OVERVIEW', 'ANSWERS', 'LAST'], $translate, $rootScope, $scope, function (translations) {
      $scope.modes[0].name = translations['OVERVIEW'];
      $scope.modes[1].name = translations['ANSWERS'];
      $scope.viewModel.translations.LAST = translations.LAST;
    });

    $scope.mode = $scope.selectedMode = $scope.modes[0];

    $scope.tags = [];

    $scope.grading = {};

    function digest() {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    function waitToFinish(func, callback) {
      var deferred = $q.defer();
      var promise;
      var done;

      var finished = function () {
        if (done)
          return;

        done = true;

        if (callback)
          callback.apply(this, arguments);

        deferred.resolve(arguments);
      };

      promise = func(finished);

      if (promise)
        promise.then(finished);

      return deferred.promise;
    }

    var _savedFilter;

    if (typeof (Storage) !== 'undefined') {
      _savedFilter = localStorage.getItem('roster-filter');

      if (_savedFilter)
        _savedFilter = JSON.parse(_savedFilter);
    }

    function removeDuplicatesById(items) {
      return _.uniq(items, false, function (item) {
        return item.id;
      });
    }

    $scope.isLoading = true;

    function getVersion(thinklet) {
      return thinklet.version;
    }

    function modifyDate(date, days, months, years) {
      date.setDate(date.getDate() + days);
      date.setMonth(date.getMonth() + months);
      date.setFullYear(date.getFullYear() + years);

      return date;
    }

    function getTimeFilterDate() {
      if (!$scope.timeFilter.time)
        return null;

      var time = $scope.timeFilter.time;

      return modifyDate(new Date(), time.days ? time.days : 0, time.months ? time.months : 0, time.years ? time.years : 0);
    }

    function makeIteratee(propertyName) {
      return function (item) {
        return item[propertyName];
      };
    }

    loadFilter();

    if (!$scope.timeFilter)
      $scope.timeFilter = $scope.timeFilters[1];

    $scope.getSelectedClasses = function () {
      if ($scope.classrooms && $scope.classrooms.length > 0) {
        return _.filter($scope.classrooms, { selected: true });
      } else {
        if (_savedFilter)
          return _.filter(_savedFilter.classes, { selected: true });
        else
          return null;
      }
    };

    function getSelectedClassIds() {
      var classes = $scope.getSelectedClasses();

      if (classes)
        return _.map(classes, makeIteratee('id'));
      else
        return null;
    }

    $scope.getSelectedUsers = function () {
      if ($scope.students && $scope.students.length > 0) {
        return _.filter($scope.students, { selected: true });
      } else {
        if (_savedFilter)
          return _.filter(_savedFilter.users, { selected: true });
        else
          return null;
      }
    };

    function getSelectedUserIds() {
      var users = $scope.getSelectedUsers();

      if (users)
        return _.map(users, makeIteratee('id'));
      else
        return null;
    }

    $scope.getSelectedProblems = function () {
      if ($scope.problems && $scope.problems.length > 0) {
        return _.filter($scope.problems, { selected: true });
      } else {
        if (_savedFilter)
          return _.filter(_savedFilter.problems, { selected: true });
        else
          return null;
      }
    };

    function getSelectedProblemAssignmentIds() {
      var problems = $scope.getSelectedProblems();

      if (!problems)
        return null;

      var result = [];

      _.each(problems, function (problem) {
        _.each(problem.assignmentIds, function (id) {
          result.push(id);
        });
      });

      return result;
    }

    function loadThinklets() {
      $scope.isLoading = true;

      return waitToFinish(function (callback) {
        var load = function () {
          var classIds = getSelectedClassIds();
          var userIds = getSelectedUserIds();
          var assignmentIds = getSelectedProblemAssignmentIds();
          var labels = getSelectedLabels();

          if (labels) {
            labels = labels.map(function (label) {
              return label.label;
            });
          }

          return $scope.loadStudentsThinklets(getTimeFilterDate(), classIds, userIds, assignmentIds, labels, $scope.selectedMode.id == 'answers', $scope.sort, !$scope.reverse, $scope.page, _resultsPerPage, callback);
        };

        if ($scope.classrooms) {
          return load();
        } else {
          var deferred = $q.defer();

          var interval = setInterval(function () {
            if ($scope.classrooms) {
              clearInterval(interval);
              interval = null;

              load().then(function (result) {
                deferred.resolve(result);
              });
            }
          }, 100);

          return deferred.promise;
        }
      }, function (thinklets) {
        thinkletsDefer.resolve(thinklets);

        if (thinklets.length < _resultsPerPage)
          _hasReachedLastPage = true;

        var i;
        var tagObject = {};

        var tagIterator = function (tag) {
          tagObject[tag] = null;
        };

        for (i = 0; i < thinklets.length; i++) {
          var thinklet = thinklets[i];

          if (thinklet.endDateV2)
            thinklet.planEndDate = thinklet.endDateV2;

          if (thinklet.isRubricCommented)
            thinklet.time = thinklet.studentReflectionDate;
          else if (thinklet.status === 'completed')
            thinklet.time = thinklet.completedAt;
          else
            thinklet.time = thinklet.createdAt;

          var value;

          if (thinklet.status === 'completed') {
            if (thinklet.isRubricCommented) {
              value = 2;
            } else {
              value = 1;
            }
          } else {
            value = 0;
          }

          thinklet.statusValue = value;

          _.each(thinklet.tags, tagIterator);

          if (thinklet.rubric) {
            var item = RubricDirective.getCorrectSolutionResponse(thinklet.rubric);

            if (item) {
              var grade = item.optionId;

              if (typeof grade == 'number') {
                if (grade == 1) {
                } else if (grade == 2) {
                  grade = 0;
                } else if (grade == 3) {
                  grade = -1;
                } else {
                  grade = null;
                }

                if (typeof grade == 'number')
                  $scope.grading[thinklet.id] = grade;
              }
            }
          }
        }

        // TODO: handle on server side
        // thinklets = groupThinkletsByVersion(thinklets);
        constantThinklets = constantThinklets.concat(thinklets);

        var tags = [];

        _.each(tagObject, function (value, key) {
          tags.push({ name: key });
        });

        $scope.tags = tags;

        filterThinklets();

        if (constantClasses && constantStudents) {
          $scope.isLoading = false;

          digest();
        }
      });
    }

    var studentsPromise = waitToFinish(function (callback) {
      return $scope.loadStudents(callback);
    }, function (students) {
      constantStudents = students.slice(0);
      $scope.students = removeDuplicatesById(students);

      if (_savedFilter && _savedFilter.users) {
        _.each($scope.students, function (user) {
          if (_.find(_savedFilter.users, { id: user.id }))
            user.selected = true;
        });
      }
    });

    var classesPromise = waitToFinish(function () {
      return $scope.loadClasses();
    }, function (classes) {
      constantClasses = classes.slice(0);
      $scope.classrooms = classes;

      if (_savedFilter && _savedFilter.classes) {
        _.each($scope.classrooms, function (classroom) {
          if (_.find(_savedFilter.classes, { id: classroom.id }))
            classroom.selected = true;
        });
      }
    });

    var _isProblemsLoading = true;

    $scope.isProblemsLoading = function () {
      return _isProblemsLoading;
    };

    waitToFinish(function (callback) {
      return $scope.loadProblems(callback);
    }, function (problems) {
      constantProblems = problems;

      $scope.problems = constantProblems.slice(0);

      if (_savedFilter && _savedFilter.problems) {
        var found = 0;

        _.each($scope.problems, function (problem) {
          if (_.find(_savedFilter.problems, { title: problem.title })) {
            problem.selected = true;

            found++;
          }
        });

        if (found != _savedFilter.problems.length)
          $scope.filter();
      }

      _isProblemsLoading = false;

      digest();
    });

    $q.all([studentsPromise, classesPromise, thinkletsDefer.promise]).then(function () {
      $scope.isLoading = false;

      handleTutorial();

      digest();
    });

    function arrayHasItemFromArray(needles, haystack) {
      var result = false;

      _.each(needles, function (needle) {
        _.each(haystack, function (hay) {
          if (needle == hay) {
            result = true;

            return false;
          }
        });
      });

      return result;
    }

    function filterThinklets() {
      var completedOnly;

      if ($scope.selectedMode.id == 'overview')
        completedOnly = false;
      else
        completedOnly = true;

      if (completedOnly || _.filter($scope.tags, { selected: true }).length > 0) {
        $scope.thinklets = _.filter(constantThinklets, function (thinklet) {
          return $scope.getFilteredVersions(thinklet, completedOnly).length > 0;
        });
      } else {
        $scope.thinklets = constantThinklets.slice(0);
      }
    }

    $scope.filter = function () {
      $scope.page = -1;
      constantThinklets.splice(0);
      $scope.thinklets.splice(0);
      _hasReachedLastPage = false;

      saveFilterData();

      $scope.generateMoreThinklets();
    };

    $scope.filterByTime = function (time) {
      $scope.timeFilter = time;

      $scope.filter();
    };

    $scope.clearFilter = function (type, obj) {
      if (type) {
        var filterListKey;

        if (type === "class")
          filterListKey = 'getSelectedClasses';

        if (type === "user")
          filterListKey = 'getSelectedUsers';

        if (type === "problem")
          filterListKey = 'getSelectedProblems';

        if (type === "label")
          filterListKey = 'getSelectedLabels';

        if (type === "tag")
          filterListKey = 'tags';

        var items = $scope[filterListKey];

        if (typeof items == 'function')
          items = items();

        var filterItem = _.find(items, function (item) {
          if (item.id)
            return item.id == obj.id;
          else
            return item == obj;
        });

        if (filterItem)
          delete filterItem.selected;
      } else {
        _.each($scope.getSelectedClasses(), function (item) {
          delete item.selected;
        });

        _.each($scope.getSelectedUsers(), function (item) {
          delete item.selected;
        });

        _.each($scope.getSelectedProblems(), function (item) {
          delete item.selected;
        });

        _.each($scope.getSelectedProblems(), function (item) {
          delete item.selected;
        });

        _.each($scope.getSelectedLabels(), function (label) {
          delete label.selected;
        });
      }

      $scope.filter();
    };

    $scope.setFilter = function (type, thinklet) {
      var filterListKey, propertyName;
      var compare;

      if (type === 'class') {
        filterListKey = 'classrooms';
        propertyName = 'class_id';

        compare = function (item, value) {
          return item.id === value;
        };
      } else if (type === 'user') {
        filterListKey = 'students';
        propertyName = 'username';

        compare = function (item, value) {
          return item.username === value;
        };
      } else if (type === 'problem') {
        filterListKey = 'problems';
        propertyName = 'problemId';

        compare = function (item, value) {
          return item.id === value;
        };
      } else if (type === 'tag') {
        filterListKey = 'tags';
        propertyName = 'tags';

        compare = function (item, value) {
          return value.indexOf(item.name) != -1;
        };
      } else {
        return;
      }

      var items = $scope[filterListKey];

      _.each(items, function (item) {
        if (compare(item, thinklet[propertyName]))
          item.selected = true;
      });

      $scope.filter();
    };

    function loadFilter() {
      if (_savedFilter) {
        if (_savedFilter.selectedMode)
          $scope.selectedMode = _savedFilter.selectedMode;

        if (_savedFilter.timeFilter)
          $scope.timeFilter = _savedFilter.timeFilter;

        return true;
      } else {
        return false;
      }
    }

    $scope.changeSortOrder = function (key) {
      if (key === $scope.sort)
        $scope.reverse = !$scope.reverse;

      $scope.sort = key;

      $scope.filter();
    };

    $scope.selectStudent = function (student) {
      $scope.navigateStudent(student);
    };

    $scope.selectAssignment = function (assignment, student) {
      $scope.navigateAssignment(assignment, student);
    };

    $scope.selectThinkletTD = function (thinklet) {
      $scope.selectThinklet(thinklet);
    };

    function saveFilterData() {
      if (typeof (Storage) !== 'undefined') {
        localStorage.removeItem('roster-filter');

        var data = {
          selectedMode: $scope.selectedMode,
          classes: $scope.getSelectedClasses(),
          users: $scope.getSelectedUsers(),
          problems: $scope.getSelectedProblems(),
          labels: getSelectedLabels(),
          timeFilter: $scope.timeFilter
        };

        _savedFilter = data;

        localStorage.setItem('roster-filter', JSON.stringify(data));
      }
    }

    $scope.mainQuestion = true;
    $scope.checkPointQuestion = false;
    $scope.isOpen = false;
    $scope.mainQuestionClass = "active";
    $scope.checkPointClass = "";

    $scope.showMainQuestion = function () {
      $scope.mainQuestionClass = "active";
      $scope.checkPointClass = "";
      $scope.mainQuestion = true;
      $scope.checkPointQuestion = false;
    };

    $scope.showCheckPointQuestion = function () {
      $scope.checkPointClass = "active";
      $scope.mainQuestionClass = "";
      $scope.mainQuestion = false;
      $scope.checkPointQuestion = true;
    };

    function isSameDay(a, b) {
      return a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate();
    }

    $scope.isPastDue = function (thinklet) {
      var date;

      if (thinklet.completedAt)
        date = new Date(thinklet.completedAt);
      else
        date = new Date();

      if (date < thinklet.planEndDate)
        return false;
      else if (isSameDay(date, new Date(thinklet.planEndDate)))
        return false;
      else
        return true;
    };

    $scope.viewAnswer = function (thinklet) {
      $scope.answerThinklet = thinklet;
    };

    $scope.isInProgress = function (thinklet, step) {
      if (step == 'understand') {
        return thinklet.u === 1;
      } else if (step == 'plan') {
        return thinklet.p === 1;
      } else if (step == 'solve') {
        return thinklet.s === 1;
      } else if (step == 'review') {
        return thinklet.r === 1;
      }
    };

    $scope.isComplete = function (thinklet, step) {
      if (step == 'understand') {
        return thinklet.u === 2;
      } else if (step == 'plan') {
        return thinklet.p === 2;
      } else if (step == 'solve') {
        return thinklet.s === 2;
      } else if (step == 'review') {
        return thinklet.r === 2;
      }
    };

    $scope.selectMode = function (mode) {
      $scope.selectedMode = mode;

      $scope.viewModel.isModeOpen = false;

      $scope.filter();
    };

    if ($scope.saveCorrect) {
      $scope.setGrade = function (thinklet, grade) {
        var oldGrade = grade;

        if (grade == -1) {
          grade = 3;
        } else if (grade === 0) {
          grade = 2;
        } else if (grade == 1) {
        } else {
          grade = null;
        }

        if (typeof grade == 'number') {
          $scope.grading[thinklet.id] = oldGrade;

          $scope.saveCorrect(thinklet, grade);
        }
      };
    }

    function getSelectedTags() {
      return _.map(_.filter($scope.tags, { selected: true }), function (tag) {
        return tag.name;
      });
    }

    $scope.isFiltering = function () {
      return !!_.find(['classrooms', 'problems', 'students', 'labels'], function (filter) {
        return _.find($scope[filter], { selected: true });
      });
    };

    function doesThinkletPassFilter(thinklet) {
      if ($scope.selectedMode.id == 'answers' && thinklet.status != 'completed')
        return false;

      var result = true;

      var isSearchByTag = _.find($scope.tags, { selected: true });

      if (isSearchByTag) {
        result = thinklet.tags && result;
        var inTagList = false;

        var selectedTags = getSelectedTags();

        if (arrayHasItemFromArray(selectedTags, thinklet.tags))
          inTagList = true;

        result = result && inTagList;
      }

      return result;
    }

    $scope.getFilteredVersions = function (thinklet) {
      var result = [];

      if (doesThinkletPassFilter(thinklet))
        result.push(thinklet);

      if (thinklet.versions) {
        var versions = _.filter(thinklet.versions, doesThinkletPassFilter);

        if (versions.length > 0)
          result = result.concat(versions);
      }

      if (result.length > 0) {
        result = _.sortBy(result, function (version) {
          return -version.version;
        });
      }

      return result;
    };

    $scope.generateMoreThinklets = function () {
      if (_hasReachedLastPage)
        return;

      $scope.page++;

      loadThinklets();
    };

    // labels

    if ($scope.isCueTeachEnabled) {
      $scope.loadLabels().then(function (labels) {
        if (_savedFilter && _savedFilter.labels) {
          labels.forEach(function (label) {
            var found;

            $.each(_savedFilter.labels, function (i, l) {
              if (l.id === label.id) {
                found = true;

                return false;
              }
            });

            if (found)
              label.selected = true;
          });
        }

        $scope.labels = labels;
      });
    }

    $scope.isLabelsLoading = function () {
      return !$scope.labels;
    };

    function getSelectedLabels() {
      var labels = [];

      if ($scope.labels && $scope.labels.length > 0) {
        $scope.labels.forEach(function (label) {
          if (label.selected)
            labels.push(label);
        });
      } else {
        if (_savedFilter)
          return _.filter(_savedFilter.labels, { selected: true });
        else
          return null;
      }

      return labels;
    }

    $scope.getSelectedLabels = getSelectedLabels;

    var handleTutorial = function () {
      if (!$scope.thinklets || $scope.thinklets.length === 0 || !$scope.startToolbarTutorial || !$scope.flagSeenTutorial || !$scope.isCueTeachEnabled)
        return;

      $scope.fetchSeenTutorial().then(function (value) {
        if (value)
          return;

        $scope.flagSeenTutorial(true);

        $scope.isTutorialPopUpVisible = true;
      });
    };

    $scope.closeTutorialPopUp = function () {
      $scope.isTutorialPopUpVisible = false;
    };

    $scope.deferTutorialPopUp = function () {
      $scope.flagSeenTutorial(false);
    };

    $scope.startClassProgressTutorial = function () {
      $scope.isTutorialPopUpVisible = false;

      $scope.startToolbarTutorial();
    };

    var NOT_SCORED;

    translate('NOT_SCORED', $translate, $rootScope, $scope, function (text) {
      NOT_SCORED = text;

      digest();
    });

    $scope.getScore = function (thinklet) {
      return typeof thinklet.score === 'number' ? thinklet.score + '/' + thinklet.maxscore : NOT_SCORED.toLowerCase();
    };

    setUserModesScopeMembers($scope);

    $scope.getFirstStepLetter = function () {
      return $scope.isNewDesign() ? 'E' : 'U';
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    scope: TeacherScreenDirective.createScope({
      loadStudents: '=',
      loadClasses: '=',
      loadStudentsThinklets: '=',
      loadProblems: '=',
      navigateStudent: '=',
      classId: '=',
      navigateAssignment: '=',
      selectThinklet: '=',
      saveCorrect: '=',
      answerTemplate: '=',
      viewOverdueProblems: '=',
      loadLabels: '&',
      isCueTeachEnabled: '=cueteach',
      fetchSeenTutorial: '=',
      flagSeenTutorial: '=',
      startToolbarTutorial: '='
    })
  };
}

angular.module('cuethink.roster', ['cuethink.rubric', 'cuethink.teacherscreen', 'infinite-scroll', 'pascalprecht.translate'])
.directive('rosterclasses', RosterClassesDirective)
.filter('trustHtml', function ($sce) {
  return function (val) {
    return $sce.trustAsHtml(val);
  };
});
