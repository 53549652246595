import LogManager from '../logging/log-manager';
import { isNewDesign } from '../common/user-modes';

export default function setUp ($scope, TeacherManager, userManager, state, thinklet, user, colorThemes, automaticallyShow) {
  TeacherManager.fetchFacilitatorGuideNotes(thinklet ? thinklet.assignmentId : 0, state === 'browse' ? 'gallery' : state).then(function (result) {
    $scope.facilitatorGuideNotes = result;
    // $scope.facilitatorGuideShowForAllClasses = result.showForAllClasses;
  });

  var colorTheme = colorThemes.find(function (theme) { return theme.name === user.colorTheme; });

  var hexToRgb = function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  let colors = colorTheme.colors[state === 'understand' ? 'explore' : state];

  if (!colors)
    colors = colorTheme.colors[Object.keys(colorTheme.colors)[0]];

  var rgb = hexToRgb(colors.navBar);

  $scope.facilitatorGuideBackgroundColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', .5);';

  let _facilitatorGuideTimestamp;

  function logFacilitatorGuide() {
    LogManager.getShared().logPageEvent('Facilitator Guide', state, window.location.pathname, null, ((new Date().getTime()) - _facilitatorGuideTimestamp) / 1000, thinklet ? thinklet.id : 0, thinklet ? thinklet.problem.title : null);
  }

  $scope.isGuideButtonVisible = function () {
    return ($scope.isNewDesign() || $scope.isCueCollabUser()) && $scope.getStepName() !== 'four-phase-menu' && $scope.user.type !== 'Student';
  };

  function hideFacilitatorGuide() {
    $scope.isFacilitatorGuideVisible = false;

    logFacilitatorGuide();
  }

  function showFacilitatorGuide() {
    $scope.isFacilitatorGuideVisible = true;

    _facilitatorGuideTimestamp = new Date().getTime();
  }

  $scope.onGuideButtonClick = function () {
    if ($scope.isFacilitatorGuideVisible)
      hideFacilitatorGuide();
    else
      showFacilitatorGuide();
  };

  $scope.onFacilitatorGuideClose = function () {
    hideFacilitatorGuide();
  };

  $scope.onFacilitatorGuidePopUpClose = function () {
    $scope.isFacilitatorGuidePopUpVisible = false;
  };

  $scope.onFacilitatorGuideSave = TeacherManager.saveFacilitatorGuideNotes.bind(null, thinklet ? thinklet.assignmentId : 0, state === 'browse' ? 'gallery' : state);

  $scope.facilitatorGuidePopUpDontShowAgainKey = 'DontShowFacilitatorGuidePopUpAgainKey';
  $scope.facilitatorGuidePopUpDontShowAgainChecked = true;
  $scope.isFacilitatorGuidePopUpVisible = false;

  if (isNewDesign(user) && automaticallyShow) {
    userManager.fetchFlag($scope.facilitatorGuidePopUpDontShowAgainKey).then(function (result) {
      if (!result) {
        showFacilitatorGuide();

        $scope.isFacilitatorGuidePopUpVisible = true;
      }
    });
  }

  $scope.saveFacilitatorGuidePopUpDontShowAgain = function () {
    userManager.saveFlag($scope.facilitatorGuidePopUpDontShowAgainKey);
  };

  $scope.onFacilitatorGuidePopUpSubmit = function () {
    $scope.isFacilitatorGuidePopUpVisible = false;
  };
}