export function setUserModesScopeMembers($scope, getUser) {
  if (!getUser)
    getUser = () => $scope.user;

  $scope.isCuethinkV2User = function (user) {
    if (!user)
      user = getUser();

    return isCuethinkV2User(user);
  };

  $scope.isEFUser = function (user) {
    if (!user)
      user = getUser();

    return isEFUser(user);
  };

  $scope.isEF23User = function (user) {
    if (!user)
      user = getUser();

    return isEF23User(user);
  };

  $scope.isEFLiteUser = function (user) {
    if (!user)
      user = getUser();

    return isEFLiteUser(user);
  };

  $scope.isLZUser = function (user) {
    if (!user)
      user = getUser();

    return isLZUser(user);
  };

  $scope.isDPUser = function (user) {
    if (!user)
      user = getUser();

    return isDPUser(user);
  };

  $scope.isOrigoUser = function (user) {
    if (!user)
      user = getUser();

    return isOrigoUser(user);
  };

  $scope.isNewDesign = function (user) {
    if (!user)
      user = getUser();

    return isNewDesign(user);
  };
}

export function isCueCollabUser (user) {
  return user && user.isCueCollabAccount;
}

export function isCuethinkV2User (user) {
  return user && user.isCuethinkV2Account;
}

export function isEFUser(user) {
  return user && user.isEFAccount;
}

export function isEF23User(user) {
  return user && user.ef23Group;
}

export function isEFLiteUser(user) {
  return user && user.isEFLiteAccount;
}

export function isLZUser(user) {
  return user && user.isLZAccount;
}

export function isDPUser(user) {
  return user && user.isDPAccount;
}

export function isOrigoUser(user) {
  return user && user.isOrigoAccount;
}

export function isNewDesign(user) {
  return isEFUser(user) || isEF23User(user) || isEFLiteUser(user) || isDPUser(user) || isLZUser(user) || isCuethinkV2User(user) || isCueCollabUser(user);
}
