import module from './settings.module';
import './settingsDirective';
import tpl from './passwordSettingsDirective.tpl.html';
import { CueThinkChangePassword } from 'core/login/forgotpassword/changePasswordDirective';
import CueThinkSettingsDirective from './settingsDirective';
import 'angular-translate';

export default function PasswordSettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    CueThinkSettingsDirective.bootstrapSettingsDirective($scope, $translate, $rootScope);

    Object.assign($scope.viewModel, {
      isSaveSuccessful: false,
      revealCurrentPassword: false,
      revealNewPassword: false,
      revealRetypeNewPassword: false,
      userPassword: {
        currentPassword: null,
        newPassword: null,
        newPasswordRetype: null
      }
    });

    var oldSave = $scope.saveSettings;

    $scope.submit = function () {
      var currentPassword = $scope.viewModel.userPassword.currentPassword;
      var newPassword = $scope.viewModel.userPassword.newPassword;
      var newPasswordRetype = $scope.viewModel.userPassword.newPasswordRetype;

      if (!CueThinkChangePassword.validatePassword(newPassword, newPasswordRetype))
        return;

      oldSave();

      $scope.changePassword(currentPassword, newPassword, newPasswordRetype).then(function () {
        $translate('PASSWORD_SUCCESSFULLY_CHANGED').then(function (msg) {
          $scope.viewModel.isSaveSuccessful = true;

          alert(msg);

          $scope.logIn();
        });
      });
    };

    $scope.toggleRevealCurrentPassword = function () {
      $scope.viewModel.revealCurrentPassword = !$scope.viewModel.revealCurrentPassword;
    };

    $scope.toggleRevealNewPassword = function () {
      $scope.viewModel.revealNewPassword = !$scope.viewModel.revealNewPassword;

    };

    $scope.toggleRevealRetypeNewPassword = function () {
      $scope.viewModel.revealRetypeNewPassword = !$scope.viewModel.revealRetypeNewPassword;
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: CueThinkSettingsDirective.createSettingsScope({
      changePassword: '=',
      logIn: '='
    })
  };
}

module.directive('passwordSettings', PasswordSettingsDirective);
