import './cuethinkDropdownDirective';
import tpl from './cuethinkCheckboxDropdownDirective.tpl.html';
import './cuethinkCheckboxDropdownDirective.css';

function CuethinkCheckboxDropdownDirective () {
  return {
    link: function ($scope) {
      $scope.isDropdownOpen = false;

      $scope.onClick = function (item) {
        item.selected = !item.selected;

        $scope.onToggle(item);
      };
    },
    replace: true,
    restrict: 'E',
    scope: {
      getLabel: '=label',
      isSelected: '=',
      items: '=',
      onToggle: '=',
      selected: '='
    },
    template: tpl,
    transclude: true
  };
}

export default CuethinkCheckboxDropdownDirective;
