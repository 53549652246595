angular.module('jq.datepicker', []).directive('jqdatepicker', function(){
	return{
		restrict: 'A',
		require: 'ngModel',
		link: function(scope, element, attrs, ctrl){
			element.datepicker({
				dateFormat: 'yy-mm-dd',
				onSelect: function(date){
					scope.$apply(function(){
						ctrl.$setViewValue(date);
					});
				}
			});
		}
	};
});
