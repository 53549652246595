import module from './settings.module';
import '../common/commonModule';
import '../login/loginDirective';
import '../teacherscreen/teacherScreenDirective';
import tpl from './settingsDirective.tpl.html';
import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import './annotationSettingsDirective';
import './fourPhasesSettingsDirective';
import './gallerySettingsDirective';
import './passwordSettingsDirective';
import './planPhaseSettingsDirective';
import './solveSettingsDirective';
import { translate } from 'core/common/translation-helpers';
import { setUserModesScopeMembers } from '../common/user-modes';
import permissionsManager, { Permissions } from '../permissions/permissions';

export default function CueThinkSettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    setUserModesScopeMembers($scope);

    $scope.viewModel = {
      dontShowSavePromptAgain: true,
      isWelcomePopUpVisible: false,
      sections: [],
      selectedSection: null,
      settings: null
    };

    var INFORMATION, PASSWORD;

    translate(['ACCOUNT', 'INFORMATION', 'PASSWORD', 'CREATING_THINKLETS', 'PLAN_PHASE', 'SOLVE', 'VIEWING_THINKLETS', 'ANNOTATIONS', 'GALLERY', 'FOUR_PHASES'], $translate, $rootScope, $scope, function (translations) {
      INFORMATION = translations.INFORMATION;
      PASSWORD = translations.PASSWORD;

      var accountSection = {
        name: translations.ACCOUNT,
        sections: [
          {
            name: translations.INFORMATION
          },
          {
            name: translations.PASSWORD
          }
        ]
      };

      var createSection = {
        name: translations['CREATING_THINKLETS'],
        sections: [
          /*{
            name: translations['FOUR_PHASES'],
            directive: 'four-phases-settings',
            settings: 'creatingThinklets.fourPhases'
          },*/
        ]
      };

      if (!permissionsManager.getPermission(Permissions.SmartDesign)) {
        createSection.sections.push({
          name: translations['SOLVE'],
          directive: 'solve-settings',
          settings: 'creatingThinklets.solve'
        });
      }

      if (!$scope.isEFUser() && !$scope.isEFLiteUser() && !$scope.isEF23User()) {
        createSection.sections.unshift({
          id: 'plan',
          name: translations['PLAN_PHASE'],
          directive: 'plan-phase-settings',
          settings: 'creatingThinklets.plan'
        });
      }

      var viewSection = {
        name: translations['VIEWING_THINKLETS'],
        sections: [
          {
            name: translations.ANNOTATIONS,
            directive: 'annotation-settings',
            settings: 'viewingThinklets.annotations'
          },
          {
            name: translations.GALLERY,
            directive: 'gallery-settings',
            settings: 'viewingThinklets.gallery'
          }
        ]
      };

      $scope.viewModel.sections = [
        accountSection,
        createSection,
        viewSection
      ];

      $scope.viewModel.selectedSection = $scope.viewModel.sections[0].sections[0];
    });

    $scope.isAccountSettingsSelected = function () {
      return $scope.viewModel.selectedSection.name === INFORMATION;
    };

    $scope.isPasswordSettingsSelected = function () {
      return $scope.viewModel.selectedSection.name === PASSWORD;
    };

    $scope.selectSection = function (section) {
      $scope.viewModel.selectedSection = section;
    };

    $scope.loadSettings().then(function (settings) {
      $scope.viewModel.settings = settings;
    });

    function getPropertyByPath (obj, path) {
      var result = obj;

      path.split('.').forEach(function (key) {
        result = result[key];
      });

      return result;
    }

    $scope.getSettings = function (path) {
      if ($scope.viewModel.settings && path)
        return getPropertyByPath($scope.viewModel.settings, path);
      else
        return null;
    };

    function save () {
      $scope.saveSettings($scope.viewModel.settings);
    }

    $scope.saveSection = function () {
      // TODO: remove true if strategies are unhidden
      if (true || $scope.viewModel.dontShowSavePromptAgain || $scope.viewModel.selectedSection.id !== 'plan')
        save();
      else
        $scope.viewModel.isSavePromptVisible = true;
    };

    $scope.onSavePromptClose = function () {
      $scope.viewModel.isSavePromptVisible = false;
    };

    $scope.onSavePromptSubmit = save;

    $scope.saveDontShowAgain = function (key) {
      $scope.viewModel.dontShowSavePromptAgain = true;

      if (typeof localStorage !== 'undefined')
        localStorage.setItem(key, true);
    };

    if (typeof localStorage !== 'undefined')
      $scope.viewModel.dontShowSavePromptAgain = !!localStorage.getItem('dont-show-global-settings-save-prompt');

    $scope.loadHasSeenWelcomePopUp().then(function (result) {
      if (!result)
        $scope.viewModel.isWelcomePopUpVisible = true;
    });

    $scope.onWelcomePopUpSubmit = function () {
      $scope.viewModel.isWelcomePopUpVisible = false;
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: TeacherScreenDirective.createScope({
      changePassword: '=',
      defaultAnnotationSentenceStarters: '=',
      // defaultPlanSentenceStarters: '=',
      defaultPlanStrategies: '=',
      deleteAccount: '=',
      getTrialDays: '=',
      isCueteachEnabled: '=',
      loadClasses: '=',
      linkCleverAccount: '=',
      linkGoogleAccount: '=',
      loadHasSeenWelcomePopUp: '=',
      loadSchools: '=',
      loadSettings: '=',
      logIn: '=',
      save: '=',
      saveSettings: '=',
      setUpNavigation: '=',
      tutorial: '='
    })
  };
}

module.directive('settings', CueThinkSettingsDirective).directive('settingsSlot', function ($compile) {
  return {
    scope: {
      defaultAnnotationSentenceStarters: '=',
      // defaultPlanSentenceStarters: '=',
      defaultPlanStrategies: '=',
      directive: '=',
      loadClasses: '=',
      save: '&',
      settings: '='
    },
    link: function ($scope, element) {
      var el;

      function render () {
        if (el)
          el.remove();

        var template = '<' + $scope.directive + ' default-annotation-sentence-starters="defaultAnnotationSentenceStarters"';
        // template += ' default-plan-sentence-starters="defaultPlanSentenceStarters"';
        template += ' default-plan-strategies="defaultPlanStrategies"';
        template += ' settings="settings" save="save()" load-classes="loadClasses"></' + $scope.directive + '>';

        el = $compile(template)($scope);

        element.append(el);
      }

      $scope.$watch('directive', render);
    }
  }
});

CueThinkSettingsDirective.createSettingsScope = function (scope) {
  return Object.assign({
    save: '&',
    settings: '='
  }, scope);
};

CueThinkSettingsDirective.bootstrapSettingsDirective = function ($scope, $translate, $rootScope) {
  $scope.viewModel = {
    hasSaved: false
  };

  var SETTINGS_SAVED, SAVE_SETTINGS;

  translate(['SETTINGS_SAVED', 'SAVE_SETTINGS'], $translate, $rootScope, $scope, function (translations) {
    SETTINGS_SAVED = translations['SETTINGS_SAVED'];
    SAVE_SETTINGS = translations['SAVE_SETTINGS'];
  });

  $scope.getSaveButtonTitle = function () {
    if ($scope.viewModel.hasSaved)
      return SETTINGS_SAVED;
    else
      return SAVE_SETTINGS;
  };

  function digest () {
    if ($scope.$$phase)
      return;

    try {
      $scope.$digest();
    } catch (e) {
    }
  }

  $scope.saveSettings = function () {
    $scope.viewModel.hasSaved = true;

    setTimeout(function () {
      $scope.viewModel.hasSaved = false;

      digest();
    }, 5000);

    $scope.save();
  };
};
