import 'tabulator-tables/dist/js/tabulator.min.js';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { BaseWhiteboardTool, inherit } from './base-tool';
import Whiteboard from '../whiteboard';

var Tabulator = require('tabulator-tables');

// TODO: move to whiteboard and add whiteboard UI param
export default function SpreadsheetTool (whiteboard, $, _, $compile) {
  var _me = this;
  var _spreadsheetEvent;
  var _spreadsheet;

  BaseWhiteboardTool.call(this, whiteboard, $, _);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, rows, cols) {
      this.id = whiteboard.getNextId();
      this.type = SpreadsheetTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.data = [];
      this.rowHeights = [];
      this.colWidths = [];

      var rowHeight = (ey - sy) / rows;
      var colWidth = (ex - sx) / cols;

      for (var i = 0; i < rows; i++) {
        var row = [];

        this.data.push(row);

        this.rowHeights.push(rowHeight);

        for (var j = 0; j < cols; j++)
          row.push(null);
      }

      for (i = 0; i < cols; i++)
        this.colWidths.push(colWidth);

      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  function createSpreadsheetRow (row) {
    var obj = { id: new Date().getTime() + '' };

    row.forEach(function (val, i) {
      obj[i + ''] = typeof val === 'string' ? val : '';
    });

    return obj;
  }

  var _editingCell;

  function setSpreadsheetRowHeights (spreadsheet, mutator) {
    var rows = spreadsheet.getRows();
    var tableHeight = 0;

    rows.forEach(function (row) {
      tableHeight += row._row.getHeight();
    });

    if (mutator)
      mutator();

    var rowHeight = tableHeight / spreadsheet.getRows().length;

    spreadsheet.getRows().forEach(function (row) {
      row._row.setHeight(rowHeight);

      row._row.cells.forEach(function (cell) {
        cell.setHeight(rowHeight);
      });
    });
  }

  function setUpSpreadsheetMenu (spreadsheet, callback, $scope) {
    var el = $('<div>');

    $.get('cell-menu.partial.html', null, function (response) {
      var compiled = $compile(response);

      el.html(compiled);
      el = el.children().first();

      var menu = el.find('.cell-dropdown-menu');
      menu.hide();
      el.hide();

      BaseWhiteboardTool.bindEvent($, el.find('.menu-toggle'), 'click', function (e) {
        menu.toggle();

        e.stopPropagation();
      });

      BaseWhiteboardTool.bindEvent($, el.find('.add-row'), 'click', function (e) {
        setSpreadsheetRowHeights(spreadsheet, function () {
          spreadsheet.addData({}, false, _editingCell.getRow());
        });

        el.hide();
        menu.hide();

        e.stopPropagation();
      });

      BaseWhiteboardTool.bindEvent($, el.find('.remove-row'), 'click', function (e) {
        if (spreadsheet.getDataCount() > 1) {
          setSpreadsheetRowHeights(spreadsheet, function () {
            _editingCell.getRow().delete();
          });
        }

        el.hide();
        menu.hide();

        e.stopPropagation();
      });

      BaseWhiteboardTool.bindEvent($, el.find('.add-column'), 'click', function (e) {
        var columns = spreadsheet.getColumns();

        var index = _.findIndex(columns, function (col) {
          return col.getDefinition().field === _editingCell.getColumn().getDefinition().field;
        });

        var colDefs = [];
        var col = createSpreadsheetColumn(spreadsheet.getColumns().length, 120);

        columns.forEach(function (column, i) {
          colDefs.push(column.getDefinition());

          if (i === index)
            colDefs.push(col);
        });

        spreadsheet.setColumns(colDefs);

        el.hide();
        menu.hide();

        e.stopPropagation();
      });

      BaseWhiteboardTool.bindEvent($, el.find('.remove-column'), 'click', function (e) {
        if (spreadsheet.getColumns().length > 1) {

          var property = _editingCell.getColumn().getDefinition().field;

          _editingCell.getColumn().delete();

          var data = spreadsheet.getData();

          data.forEach(function (row) {
            delete row[property];
          });

          spreadsheet.replaceData(data);
        }

        el.hide();
        menu.hide();

        e.stopPropagation();
      });

      $('body').append(el);

      callback(el);
    });

    $(spreadsheet.element).on('remove', function () {
      el.hide();
    });
  }

  function createSpreadsheetColumn (i, width) {
    return { title: '', field: i + '', width: width, editor: 'textarea', variableHeight: false };
  }

  function onSpreadsheetClick (event) {
    save(false);
  }

  function editCell (x, y) {
    var cellPosition = getSpreadsheetCellIndexAtCoordinate(_spreadsheetEvent, x, y);
    var row;

    if (cellPosition.row !== -1 && cellPosition.col !== -1)
      row = _spreadsheet.getRows()[cellPosition.row];

    if (row) {
      var cell = row.getCell(cellPosition.col);

      if (cell) {
        setTimeout(function () {
          cell.edit();

          setTimeout(function () {
            $(cell.getElement()).find('textarea').focus();
          }, 100);
        }, 100);
      }
    }
  }

  this.isRotatable = function () {
    return false;
  };

  _me.setUp = function (object, x, y) {
    if (_spreadsheet)
      return;

    var bounds = Whiteboard.getEventBounds(object);
    var spreadsheetX = bounds.left * whiteboard.getWidth();
    var spreadsheetY = bounds.top * whiteboard.getHeight();
    var w = (1 - bounds.left) * whiteboard.getWidth();
    var h = (1 - bounds.top) * whiteboard.getHeight();

    var spreadsheetEl = whiteboard.createElement('div', spreadsheetX, spreadsheetY, w, h);
    spreadsheetEl.attr('id', 'interactive-table');

    var body = $('body');

    var style = $('<style>');
    style.text('.tabulator-cell { ' +
      'color: ' + object.color + ';' +
      'font-family: ' + whiteboard.getFont() + ';' +
      'font-size: 16px;' +
      ' }');
    body.append(style);

    var columns = [];

    for (var i = 0; i < object.data[0].length; i++)
      columns.push(createSpreadsheetColumn(i, object.colWidths[i] * whiteboard.getWidth()));

    var data = [];

    object.data.forEach(function (row) {
      data.push(createSpreadsheetRow(row));
    });

    var menu;

    var positionMenu = function () {
      if (!_editingCell)
        return;

      var cell = _editingCell;
      var el = $(cell.getElement());
      var offset = el.offset();

      offset.right = body.width() - offset.left - el.width();

      menu.css('right', offset.right + 8 + 'px');
      menu.css('top', offset.top + 8 + 'px');
      menu.show();
    };

    var lastCellEntered;

    var handleCellEntered = function (cellElement) {
      if (!menu)
        return;

      menu.find('.cell-dropdown-menu').hide();

      var cell;

      $.each(_spreadsheet.getRows(), function (i, row) {
        $.each(row.getCells(), function (i, c) {
          if (c.getElement() === cellElement) {
            cell = c;

            return false;
          }
        });

        if (cell)
          return false;
      });

      if (cell) {
        _editingCell = cell;

        // delay so that iOS doesn't get interrupted focusing text field
        if (BaseWhiteboardTool.isiOS()) {
          setTimeout(function () {
            positionMenu();
          }, 500);
        } else {
          positionMenu();
        }
      }
    };

    var onCellMouseEnter = function (e) {
      lastCellEntered = this;

      handleCellEntered(this);
    };

    var onCellMouseLeave = function (e) {
      lastCellEntered = null;
    };

    var onResizeHandleMouseEnter = function () {
      if (menu)
        menu.hide();
    };

    var onResizeHandleMouseLeave = function () {
      if (lastCellEntered)
        handleCellEntered(lastCellEntered);
    };

    var options = {
      data: data,
      columns: columns,
      resizableRows: true,
      movableRows: false,
      width: spreadsheetEl.width(),
      height: spreadsheetEl.height(),
      movableColumns: false,
      selectable: false,
      rowFormatter: function (row) {
        var height = object.rowHeights[row.getPosition()] * whiteboard.getHeight();
        height -= 2;

        row._row.setHeight(height);

        var el = $(row.getElement());
        var cell = el.find('.tabulator-cell');
        BaseWhiteboardTool.bindEvent($, cell, 'mouseenter', onCellMouseEnter);
        BaseWhiteboardTool.bindEvent($, cell, 'mouseleave', onCellMouseLeave);

        var resizeHandle = cell.find('.tabulator-col-resize-handle');

        BaseWhiteboardTool.bindEvent($, resizeHandle, 'mouseenter', onResizeHandleMouseEnter);
        BaseWhiteboardTool.bindEvent($, resizeHandle, 'mouseleave', onResizeHandleMouseLeave);
      }
    };

    _spreadsheetEvent = object;
    _spreadsheet = new Tabulator('#interactive-table', options);

    whiteboard.beginEditing(object);

    setUpSpreadsheetMenu(_spreadsheet, function (el) {
      menu = el;
    });

    if (x !== undefined && y !== undefined)
      editCell(x, y);

    BaseWhiteboardTool.bindEvent($, _spreadsheet.element, 'click', onSpreadsheetClick);
  };

  function getSpreadsheetCellIndexAtCoordinate (spreadsheet, x, y) {
    var result = { row: -1, col: -1 };

    var colRight = 0;
    var colWidths = spreadsheet.colWidths;

    for (var i = 0; i < colWidths.length; i++) {
      colRight += colWidths[i];

      if (x < colRight) {
        result.col = i;

        break;
      }
    }

    var rowBottom = 0;
    var rowHeights = spreadsheet.rowHeights;

    for (i = 0; i < rowHeights.length; i++) {
      rowBottom += rowHeights[i];

      if (y < rowBottom) {
        result.row = i;

        break;
      }
    }

    return result;
  }

  function save (shouldRedraw) {
    var rowHeights = [];
    var colWidths = [];
    var w = whiteboard.getWidth();
    var h = whiteboard.getHeight();

    var rows = _spreadsheet.getRows();

    for (var i = 0; i < rows.length; i++) {
      var rowHeight = $(rows[i].getElement()).height();
      rowHeight += 2;

      rowHeights.push(rowHeight / h);
    }

    var columns = _spreadsheet.getColumns();

    for (i = 0; i < columns.length; i++)
      colWidths.push(columns[i]._column.width / w);

    var data = [];

    _spreadsheet.getData().forEach(function (row) {
      var record = [];

      for (var i = 0; i < columns.length; i++)
        record.push(row[i + '']);

      data.push(record);
    });

    whiteboard.editSpreadsheet(_spreadsheetEvent, data, rowHeights, colWidths, shouldRedraw);
  }

  // TODO: hide spreadsheet object while editing and make spreadsheet background transparent

  _me.commit = function () {
    if (!_spreadsheet)
      return false;

    $(_spreadsheet.element).find('textarea').blur();

    save(true);

    _spreadsheetEvent = null;

    $(_spreadsheet.element).remove();
    _spreadsheet = null;

    return true;
  };

  _me.getSpreadsheet = function () {
    return _spreadsheet;
  };

  _me.draw = function () {
    // TODO
  };
}

inherit(SpreadsheetTool, BaseWhiteboardTool);

SpreadsheetTool.type = 'spreadsheet';
