import WhiteboardUi from './whiteboardui.jquery';
import './whiteboard.css';
import tpl from './whiteboard.html';
import 'angular-translate';

angular.module('whiteboard', ['pascalprecht.translate'])
	.directive('whiteboard', function ($translate, $interpolate) {
    	"ngInject";

		return {
			restrict: 'E',
			template: tpl,
			transclude: true,
			link: function (scope, elements, attributes) {
				var whiteboard;

				elements = $(elements);

				function setParentScopeVariables (name, value) {
					var s;

					s = scope;

					do {
						s[name] = value;

						if (!s.$$phase) {
							try {
								s.$digest();
							} catch (e) {
							}
						}

						s = s.$parent;
					} while (s);
				}

				whiteboard = new WhiteboardUi(elements.find('.whiteboard:first'), !attributes.readOnly, attributes.readOnly, $translate, function (html) {
					return $interpolate(html);
				});

				if (attributes.isRecording) {
					whiteboard.setRecordingStatusChangeCallback(function (value) {
						setParentScopeVariables('isRecording', value);
					});
				}

				scope.$watch(attributes.isPaused, function (value) {
					if (value)
						whiteboard.pauseRecording();
				});

				scope.$watch(attributes.recordingCallback, function (value) {
					whiteboard.setRecordingCallback(value);
				});

				if (attributes.audioRecorderError) {
					whiteboard.setAudioRecordingErrorCallback(function (value) {
						setParentScopeVariables('audioRecorderError', value);
					});
				}

				scope.$watch(attributes.audioUrl, function (value) {
					whiteboard.setAudioURL(value);
				});

				scope.$watch(attributes.events, function (value) {
					whiteboard.setEvents(value);
				});

				scope.$watch(attributes.logEvent, function (value) {
					whiteboard.setLogCallback(value);
				});

				scope.$watch(attributes.save, function (value) {
					whiteboard.setSaveCallback(value);
				});

				scope.$watch(attributes.shouldAutosave, function (value) {
					whiteboard.setShouldAutosave(value);
				});

				scope.$watch(attributes.overwrite, function (value) {
					whiteboard.setOverwriteCallback(value);
				});

				scope.$watch(attributes.upload, function (value) {
					whiteboard.setUploadCallback(value);
				});

				scope.$watch(attributes.loadImage, function (value) {
					whiteboard.setImageLoadCallback(value);
				});

        scope.$watch(attributes.userImages, function (value) {
          whiteboard.setUserImages(value);
        });

				scope.$watch(attributes.onToolChange, function (value) {
					whiteboard.setOnToolChangeCallback(value);
				});

				scope.$watch(attributes.onPageChange, function (value) {
					whiteboard.setOnPageChangeCallback(value);
				});

				scope.$watch(attributes.onChange, value => {
					whiteboard.setOnChangeCallback(value);
				});

				scope.$watch(attributes.onStatusChange, function (value) {
					whiteboard.setOnStatusChangeCallback(value);
				});

				scope.$watch(attributes.onMicrophoneNotAvailable, function (value) {
					whiteboard.setMicrophoneNotAvailableCallback(value);
				});

				scope.$watch(attributes.toolOrder, function (value) {
					if (value === undefined)
						return;

					whiteboard.setToolOrder(value);
				});

				scope.$watch(attributes.hiddenTools, function (value) {
					if (value === undefined)
						return;

					whiteboard.setHiddenTools(value);
				});

				scope.$watch(attributes.enableAlgebraTiles, function (value) {
					if (value === undefined)
						return;

					whiteboard.setAlgebraTilesEnabled(value);
				});

				scope.$watch(attributes.enablePen, function (value) {
					if (value === undefined)
						return;

					whiteboard.setPenEnabled(value);
				});

				scope.$watch(attributes.enableHighlighter, function (value) {
					if (value === undefined)
						return;

					whiteboard.setHighlighterEnabled(value);
				});

				scope.$watch(attributes.enableEraser, function (value) {
					if (value === undefined)
						return;

					whiteboard.setEraserEnabled(value);
				});

				scope.$watch(attributes.enableFractionBars, function (value) {
					if (value === undefined)
						return;

					whiteboard.setFractionBarsEnabled(value);
				});

				scope.$watch(attributes.enableImage, function (value) {
					if (value === undefined)
						return;

					whiteboard.setImageEnabled(value);
				});

				scope.$watch(attributes.enableShapes, function (value) {
					if (value === undefined)
						return;

					whiteboard.setShapesEnabled(value);
				});

				scope.$watch(attributes.enableProtractor, function (value) {
					if (value === undefined)
						return;

					whiteboard.setProtractorEnabled(value);
				});

				scope.$watch(attributes.enableRectangle, function (value) {
					if (value === undefined)
						return;

					whiteboard.setRectangleEnabled(value);
				});

				scope.$watch(attributes.enableLine, function (value) {
					if (value === undefined)
						return;

					whiteboard.setLineEnabled(value);
				});

				scope.$watch(attributes.enableTriangle, function (value) {
					if (value === undefined)
						return;

					whiteboard.setTriangleEnabled(value);
				});

				scope.$watch(attributes.enableEllipse, function (value) {
					if (value === undefined)
						return;

					whiteboard.setEllipseEnabled(value);
				});

				scope.$watch(attributes.enableStamps, function (value) {
					if (value === undefined)
						return;

					whiteboard.setStampsEnabled(value);
				});

				scope.$watch(attributes.enableDiagram, function (value) {
					if (value === undefined)
						return;

					whiteboard.setDiagramEnabled(value);
				});

				scope.$watch(attributes.enableTable, function (value) {
					if (value === undefined)
						return;

					whiteboard.setTableEnabled(value);
				});

				scope.$watch(attributes.enableSpreadsheet, function (value) {
					if (value === undefined)
						return;

					whiteboard.setSpreadsheetEnabled(value);
				});

				scope.$watch(attributes.enableArray, function (value) {
					if (value === undefined)
						return;

					whiteboard.setArrayEnabled(value);
				});

				scope.$watch(attributes.enableBond, function (value) {
					if (value === undefined)
						return;

					whiteboard.setBondEnabled(value);
				});

				scope.$watch(attributes.enableGrid, function (value) {
					if (value === undefined)
						return;

					whiteboard.setGridEnabled(value);
				});

				scope.$watch(attributes.enableEquation, function (value) {
					if (value === undefined)
						return;

					whiteboard.setEquationEnabled(value);
				});

				scope.$watch(attributes.enableNumberLine, function (value) {
					if (value === undefined)
						return;

					whiteboard.setNumberLineEnabled(value);
				});

				scope.$watch(attributes.enableText, function (value) {
					if (value === undefined)
						return;

					whiteboard.setTextEnabled(value);
				});

				scope.$watch(attributes.enableUnifixCube, function (value) {
					if (value === undefined)
						return;

					whiteboard.setUnifixCubeEnabled(value);
				});

				scope.$watch(attributes.enableUndo, function (value) {
					if (value === undefined)
						return;

					whiteboard.setUndoEnabled(value);
				});

				scope.$watch(attributes.enableRedo, function (value) {
					if (value === undefined)
						return;

					whiteboard.setRedoEnabled(value);
				});

				scope.$watch(attributes.enableSelect, function (value) {
					if (value === undefined)
						return;

					whiteboard.setSelectEnabled(value);
				});

				scope.$watch(attributes.enableDelete, function (value) {
					if (value === undefined)
						return;

					whiteboard.setDeleteEnabled(value);
				});

				scope.$watch(attributes.enableClear, function (value) {
					if (value === undefined)
						return;

					whiteboard.setClearEnabled(value);
				});

				scope.$watch(attributes.renderEquation, function (value) {
					whiteboard.setRenderEquationCallback(value);
				});

				scope.$on('$destroy', function () {
					whiteboard.saveIfChanged();
				});
			}
		};
	});
