import '../login/loginDirective';
import TeacherScreenDirective from '../teacherscreen/teacherScreenDirective';
import './students.css';
import tpl from './viewStudentsDirective.tpl.html';
require('./classDropdown.partial.html');
require('./create_class_popup.partial.html');
require('./edit_class_popup.partial.html');
require('./join_class_popup.partial.html');
require('./edit_student_popup.partial.html');
require('./delete_students_popup.partial.html');
require('./student_password_popup.partial.html');
require('./importRoster.partial.html');
require('./confirm_leave_class.partial.html');
require('./joinedClassDropdown.partial.html');
require('./archive_class.partial.html');
import _ from 'underscore';
import { CueThinkChangePassword } from 'core/login/forgotpassword/changePasswordDirective';
import 'angular-translate';
import googleManager from 'core/google/google-manager';
import googleClassroom from 'core/google/google-classroom';
import './images/archive-icon.svg';
import './images/edit-pencil.svg';
import './images/google-classroom.svg';
import './images/leave-icon.svg';
import { translate } from 'core/common/translation-helpers';
import { setUpCreateStudentPopUpDirective } from './createStudentPopUpDirective';
import 'core/common/cuethinkPopUpDirective';
import 'angular-translate';
var cloneDeep = require('lodash.clonedeep');

var module = angular.module('cuethink.students', ['cuethink.login', 'cuethink.teacherscreen', 'flow', 'pascalprecht.translate', 'cuethink.common']);

setUpCreateStudentPopUpDirective(module);

module.directive('viewstudents', function ($translate, $rootScope, $q) {
  var linkFunction = function ($scope) {
    $scope.viewModel = {
      activeClasses: null,
      archivedClasses: null,
      dontShowArchivePopUpKey: 'dontShowArchivePopUp',
      dontShowLeaveClassPopUpAgainKey: 'dontShowLeaveClassPopUpAgainKey',
      googleClassroomCourses: null,
      importedStudentCount: null,
      importedStudentErrors: null,
      isArchiveClassPopupVisible: false,
      isCreateClassPopUpVisible: false,
      isCreateStudentPopUpVisible: false,
      isDontShowArchivePopUpAgainChecked: false,
      isDontShowLeaveClassPopUpAgainChecked: false,
      isEditStudentPopUpVisible: false,
      isImportGoogleClassroomPopUpVisible: false,
      isImportGoogleClassroomSuccessPopUpVisible: false,
      isImportRosterPopupVisible: false,
      isJoinedClassesIconsVisible: false,
      isJoinedClassPopoverOpen: {},
      isMyClassesIconsVisible: false,
      isPopoverOpen: {},
      openClasses: null,
      selectAllCourses: false,
      studentClasses: null
    };

    translate(['ALLOWED', 'NOT_ALLOWED'], $translate, $rootScope, $scope, function (translations) {
      $scope.viewModel.translations = translations;
    });

    var _dontShowArchivePopUp, _dontShowLeaveClassPopUp;

    function digest() {
      if ($scope.$$phase)
        return;

      try {
        $scope.$digest();
      } catch (e) {
      }
    }

    function sortClass (a, b) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      if (a < b)
        return -1;

      if (a > b)
        return 1;

      return 0;
    }

    function handleCallback(func, callback) {
      var promise = func(callback);

      if (promise)
        promise.then(callback);
    }

    $scope.classFilter = [];
    $scope.dropdown = false;

    $scope.toggleMenu = function (c) {
      c.dropdown = !c.dropdown;
    };

    function loadSavedClass () {
      if (!$scope.classFilter || !$scope.viewModel.joinedClasses)
        return;

      if (typeof(localStorage) !== 'undefined') {        //check if saved class filter is valid;
        var cachedClass = localStorage.getItem('class-roster-filter');

        if (cachedClass) {
          cachedClass = JSON.parse(cachedClass);

          var cls = $scope.classFilter.find(function (cls) {
            return cls.id === cachedClass.id;
          });

          if (!cls) {
            cls = $scope.viewModel.joinedClasses.find(function (cls) {
              return cls.id === cachedClass.id;
            });
          }

          if (cls) {
            $scope.selectClass(cls);

            return;
          }
        }
      }

      if ($scope.classFilter.length > 0)
        $scope.selectClass($scope.classFilter[0]);
    }

    handleCallback(function () {
      return $scope.loadClasses();
    }, function (classes) {
      classes.sort(sortClass);

      $scope.classFilter = $scope.viewModel.openClasses = classes;

      loadSavedClass();

      digest();
    });

    handleCallback(function (callback) {
      return $scope.loadArchivedClasses(callback);
    }, function (classes) {
      classes.sort(sortClass);

      $scope.viewModel.archivedClasses = classes;
    });

    handleCallback(function (callback) {
      return $scope.loadccssGrades(callback);
    }, function (grades) {
      $scope.grades = [];

      var shiftGradeK = false;

      for (var i = 0; i < grades.length; i++) {
        if (grades[i] === "K") {
          $scope.grades[0] = "K";

          for (var j = 1; j < grades.length; j++) {
            if (j <= i) {
              $scope.grades[j] = grades[j - 1];
            }
            else {
              $scope.grades[j] = grades[j];
            }
          }
          shiftGradeK = true;
          break;
        }
      }

      if (!shiftGradeK)
        $scope.grades = grades;

      digest();
    });

    //***
    //* start open and close popup functions
    $scope.openClassPopup = function () {
      $scope.newClass = {};
      $scope.viewModel.isCreateClassPopUpVisible = true;
    };

    if ($scope.stateParams.newUser)
      $scope.openClassPopup();

    $scope.openCreateStudentPopup = function () {
      $scope.viewModel.activeClasses = getActiveClasses();

      $scope.viewModel.isCreateStudentPopUpVisible = true;
    };

    function getAllClasses () {
      return $scope.viewModel.openClasses.concat($scope.viewModel.archivedClasses).concat($scope.viewModel.joinedClasses);
    }

    function getActiveClasses () {
      return $scope.viewModel.openClasses.concat($scope.viewModel.joinedClasses);
    }

    function getStudentClasses (student) {
      var result = [];

      var classes = getAllClasses();

      classes.forEach(function (cls) {
        if (!cls.students)
          return;

        if (result.indexOf(cls) === -1) {
          var found = cls.students.find(function (s) {
            return s.id === student.id;
          });

          cls.selected = !!found;

          result.push(cls);
        }
      });

      return result;
    }

    $scope.getStudentClassesText = function () {
      return $scope.viewModel.studentClasses.filter(function (cls) {
        return cls.selected && $scope.canManageClass(cls);
      }).map(function (cls) {
        return cls.name;
      }).join(', ');
    };

    $scope.openEditStudentPopup = function (student) {
      $scope.editedStudent = {
        id: student.id,
        username: student.username,
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email
      };

      $scope.viewModel.isEditStudentPopUpVisible = true;
      $scope.viewModel.isUsernameValid = true;

      var classes = cloneDeep(getStudentClasses(student));

      classes.sort(sortClass);

      $scope.viewModel.studentClasses = classes;
    };

    $scope.openDeleteStudentPopup = function (student) {
      $scope.deleteStudentPopup = true;
    };

    $scope.closeClassPopup = function () {
      $scope.viewModel.isCreateClassPopUpVisible = false;
    };

    $scope.closeStudentPopup = function () {
      $scope.viewModel.isCreateStudentPopUpVisible = false;
    };

    $scope.closeEditStudentPopUp = function () {
      $scope.viewModel.isEditStudentPopUpVisible = false;
    };

    $scope.closeDeleteStudentPopup = function () {
      $scope.deleteStudentPopup = false;
    };

    $scope.joinClass = function (code) {
      $scope.closeJoinClassPopUp();

      $scope.joinExistingClass(code).then(function (newClass) {
        $scope.viewModel.joinedClasses.push(newClass);
        $scope.viewModel.joinedClasses.sort(sortClass);

        $scope.selectClass(newClass);
      });
    };

    $scope.closeJoinClassPopUp = function () {
      $scope.viewModel.isJoinClassPopUpVisible = false;
    };

    //***
    //* end open and close popup functions

    $scope.saveFilter = function () {
      if (typeof(localStorage) !== 'undefined') {
        if ($scope.viewModel.selectedClass)
          localStorage.setItem('class-roster-filter', JSON.stringify($scope.viewModel.selectedClass));
        else
          localStorage.removeItem('class-roster-filter');
      }
    };

    $scope.reverse = false;
    $scope.sort = "firstName";

    $scope.changeSortOrder = function (key) {
      if ($scope.sort == key) {
        $scope.reverse = !$scope.reverse;

        return;
      }

      $scope.sort = key;
      $scope.reverse = false;
    };

    $scope.newClassSelector = { "isNew": true };

    function handleCreateClass (cls) {
      if (cls.isOwnedByCurrentUser === true || typeof cls.isOwnedByCurrentUser === 'undefined') {
        $scope.viewModel.openClasses.push(cls);
      } else {
        $scope.viewModel.joinedClasses.push(cls);
      }
    }

    $scope.createNewClass = function (callback) {
      if (!$scope.newClass.grade) {
        alert($translate.instant('PLEASE_SELECT_A_GRADE'));

        return false;
      }

      $scope.closeClassPopup();

      return $scope.createClass($scope.newClass, callback).then(function (data) {
        handleCreateClass(data);

        $scope.selectClass(data);

        if (callback)
          callback(data);
      });
    };

    function closeClassDropdowns () {
      var keys = Object.keys($scope.viewModel.isPopoverOpen);
      var key;

      for (var i = 0; i < keys.length; i++) {
        key = keys[i];

        if (typeof $scope.viewModel.isPopoverOpen[key] === 'boolean')
          $scope.viewModel.isPopoverOpen[key] = false;
      }

      keys = Object.keys($scope.viewModel.isJoinedClassPopoverOpen);

      for (i = 0; i < keys.length; i++) {
        key = keys[i];

        if (typeof $scope.viewModel.isJoinedClassPopoverOpen[key] === 'boolean')
          $scope.viewModel.isJoinedClassPopoverOpen[key] = false;
      }
    }

    if ($scope.editClass) {
      var _originalEditedClass;

      $scope.openEditClassPopup = function (cls) {
        closeClassDropdowns();

        _originalEditedClass = cls;
        $scope.viewModel.editClass = _.clone(cls);
      };

      $scope.closeEditClassPopup = function () {
        $scope.viewModel.editClass = null;
      };

      $scope.saveClass = function () {
        $scope.editClass($scope.viewModel.editClass);

        var keys = Object.keys($scope.viewModel.editClass);

        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];

          _originalEditedClass[key] = $scope.viewModel.editClass[key];
        }

        $scope.closeEditClassPopup();
      };
    }

    $scope.createNewStudent = function (student, classes) {
      if (!classes || classes.length === 0)
        return;

      student.invitationCode = classes[0].invitationCode;

      return $scope.createStudent(student).then(function (data) {
        if (data.messages) {
          $translate(data.messages).then(function (translations) {
            Object.values(translations).forEach(function (translation, i) {
              data.messages[i] = translation;
            });

            alert(data.messages.join('\n'));
          });

          return;
        }

        if (data.id !== undefined) {
          var success = function () {
            $translate('STUDENT_SUCCESSFULLY_CREATED').then(function (msg) {
              alert(msg);
            });

            var allClasses = $scope.viewModel.openClasses.concat($scope.viewModel.joinedClasses).concat($scope.viewModel.archivedClasses);

            classes.forEach(function (cls) {
              var c = allClasses.find(function (c) {
                return c.invitationCode === cls.invitationCode;
              });

              if (c)
                c.students.push(data);
            });

            $scope.closeStudentPopup();
          };

          if (classes.length > 1) {
            var classIds = classes.slice(1).map(function (cls) {
              return cls.id;
            }).join(',');

            var postData = {
              id: student.id,
              username: student.username,
              firstName: student.firstName,
              lastName: student.lastName,
              email: student.email,
              classIds: classIds
            };

            return $scope.editStudent(postData).then(success);
          } else {
            success();

            return Promise.resolve();
          }
        }
      });
    };

    $scope.selectClass = function (classroom) {
      $scope.viewModel.selectedClass = classroom;

      $scope.saveFilter();

      digest();
    };

    function removeStudentFromStudents(student, students) {
      for (var i = 0; i < students.length; i++) {
        var s = students[i];

        if (s.id === student.id) {
          students.splice(i, 1);

          return true;
        }
      }
    }

    function removeStudentFromClass (student, classes, cls) {
      if (cls) {
        var found = classes.find(function (c) {
          return c.id === cls.id;
        });

        if (found)
          removeStudentFromStudents(student, found.students);
      } else {
        classes.forEach(function (cls) {
          removeStudentFromStudents(student, cls.students);
        });
      }
    }

    function removeStudentFromClasses (student, cls) {
      removeStudentFromClass(student, $scope.viewModel.openClasses, cls);
      removeStudentFromClass(student, $scope.viewModel.archivedClasses, cls);
      removeStudentFromClass(student, $scope.viewModel.joinedClasses, cls);
    }

    function addStudentToClass (student, cls) {
      cls.students.forEach(function (s) {
        if (s.id === student.id) {
          s.username = student.username;
          s.firstName = student.firstName;
          s.lastName = student.lastName;
          s.email = student.email;
        }
      });

      $scope.viewModel.openClasses.concat($scope.viewModel.archivedClasses).forEach(function (c) {
        if (cls.id === c.id) {
          var found = c.students.find(function (s) {
            return s.id === student.id;
          });

          if (!found)
            c.students.push(student);
        }
      });
    }

    //***
    //* start popup functions
    $scope.editStudentInfo = function () {
      if (!$scope.isStudentSettingsValid())
        return;

      var classIds = $scope.viewModel.studentClasses.filter(function (cls) {
        return cls.createBy === $scope.user.id && cls.selected;
      }).map(function (cls) {
        return cls.id;
      }).join(',');

      var postData = {
        id: $scope.editedStudent.id,
        username: $scope.editedStudent.username,
        firstName: $scope.editedStudent.firstName,
        lastName: $scope.editedStudent.lastName,
        email: $scope.editedStudent.email,
        classIds: classIds
      };

      return $scope.editStudent(postData).then(function () {
        $scope.viewModel.studentClasses.forEach(function (cls) {
          if (cls.selected) {
            addStudentToClass($scope.editedStudent, cls);
          } else {
            removeStudentFromClasses($scope.editedStudent, cls);
          }
        });

        alert($translate.instant('CHANGE_SUCCESSFUL'));

        $scope.viewModel.isEditStudentPopUpVisible = false;

        digest();
      });
    };

    $scope.deleteStudentAccount = function () {
      var student;

      student = $scope.editedStudent;

      $scope.removeStudent(student.id);
      $scope.deleteStudentPopup = $scope.viewModel.isEditStudentPopUpVisible = false;
      $scope.headerButtons = false;

      removeStudentFromClasses(student);
    };

    var _classToArchive;
    $scope.archiveClassroom = function (classroom) {
      closeClassDropdowns();

      _classToArchive = classroom;

      if (_dontShowArchivePopUp)
        $scope.confirmArchiveClass();
      else
        $scope.viewModel.isArchiveClassPopupVisible = true;
    };

    $scope.reactivateClassroom = function () {
      var classroom = $scope.viewModel.selectedClass;

      $scope.viewModel.archivedClasses.splice($scope.viewModel.archivedClasses.indexOf(classroom), 1);

      $scope.reactivateClass(classroom);

      $scope.viewModel.openClasses.push(classroom);

      $scope.viewModel.selectedClass = null;
    };

    $scope.confirmArchiveClass = function () {
      $scope.archiveClass(_classToArchive).then(function () {
        $scope.classFilter.splice($scope.classFilter.indexOf(_classToArchive), 1);
        $scope.viewModel.archivedClasses.push(_classToArchive);

        $scope.selectClass(null);
      }).catch(function () {
      }).finally(function () {
        _classToArchive = null;

        $scope.closeArchiveClassPopup();
      });
    };

    $scope.closeArchiveClassPopup = function () {
      $scope.viewModel.isArchiveClassPopupVisible = false;
    };

    $scope.toggleArchivedClasses = function () {
      $scope.isArchiveVisible = !$scope.isArchiveVisible;

      if ($scope.isArchiveVisible)
        $scope.classFilter = $scope.viewModel.archivedClasses;
      else
        $scope.classFilter = $scope.viewModel.openClasses;

      $scope.viewModel.selectedClass = null;
    };

    if ($scope.expireStudentPassword) {
      $scope.resetStudentPassword = function () {
        if (!$scope.editedStudent)
          return;

        $scope.expireStudentPassword($scope.editedStudent).then(function () {
          alert($translate.instant('PASSWORD_SUCCESSFULLY_CHANGED_2'));

          $scope.closePasswordPopup();
          $scope.closeEditStudentPopUp();
        });
      };
    }

    $scope.openJoinClassPopUp = function () {
      $scope.viewModel.isJoinClassPopUpVisible = true;
      $scope.viewModel.invitationCode = null;
    };

    function loadJoinedClasses () {
      return $scope.fetchJoinedClasses().then(function (classes) {
        if (classes)
          classes.sort(sortClass);

        $scope.viewModel.joinedClasses = classes;

        return classes;
      });
    }

    loadJoinedClasses();

    $scope.getCoteachers = function () {
      var result = $scope.viewModel.selectedClass;

      if (!result)
        return null;

      result = result.teachers;

      if (!result)
        return null;

      return result.map(function (teacher) {
        return teacher.firstName + ' ' + teacher.lastName;
      }).join(', ');
    };

    $scope.isStudentSettingsValid = function () {
      return $scope.editedStudent.firstName && $scope.editedStudent.lastName && $scope.editedStudent.username && $scope.viewModel.isUsernameValid;
    };

    var _rosterFile;

    $scope.openImportRosterPopup = function () {
      $scope.newClass = {};
      $scope.viewModel.isImportRosterPopupVisible = true;
      $scope.viewModel.importedStudentCount = null;
      _rosterFile = null;
    };

    $scope.closeImportRosterPopup = function () {
      if ($scope.viewModel.importedStudentCount)
        window.location.reload();

      $scope.viewModel.isImportRosterPopupVisible = false;
      $scope.viewModel.importedStudentCount = null;
      $scope.viewModel.importedStudentErrors = null;
    };

    $scope.resetImportRosterPopUp = function () {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    };

    $scope.addRosterFile = function (file) {
      _rosterFile = file;
    };

    $scope.submitRoster = function () {
      if ($scope.viewModel.importedStudentCount === null) {
        if (!_rosterFile)
          return;

        if (_rosterFile.name.indexOf('.csv') === -1) {
          alert($translate.instant('PLEASE_UPLOAD_CSV_FILES_ONLY'));

          return;
        }

        $scope.importRoster(_rosterFile, $scope.viewModel.selectedClass.id).then(function (result) {
          $scope.viewModel.importedStudentCount = result;
        }, function (errors) {
          if (typeof errors === 'string')
            errors = [errors];

          $scope.viewModel.importedStudentErrors = errors;
        });
      } else {
        $scope.finishImportRoster();
      }
    };

    $scope.clearImportRosterFile = function ($flow) {
      $flow.files.splice(0);
      _rosterFile = null;
    };

    $scope.finishImportRoster = function () {
      window.location.reload();
    };

    $scope.canManageClass = function (cls) {
      return !!$scope.viewModel.openClasses.find(function  (c) {
        return c.id === cls.id;
      });
    };

    $scope.studentPassword = {
    };

    $scope.openPasswordPopup = function (student) {
      $scope.studentPasswordPopup = true;
      $scope.studentPassword.newPassword = null;
      $scope.studentPassword.newPasswordRetype = null;
    };

    $scope.closePasswordPopup = function () {
      $scope.studentPasswordPopup = false;
    };

    $scope.setNewStudentPassword = function () {
      if (!CueThinkChangePassword.validatePassword($scope.studentPassword.newPassword, $scope.studentPassword.newPasswordRetype))
        return false;

      $scope.studentPassword.id = $scope.editedStudent.id;

      handleCallback(function (callback) {
        return $scope.changeStudentPassword($scope.studentPassword, callback);
      }, function (data) {
        if (data.succeed)
          $scope.resetStudentPassword();
      });
    };

    $scope.openLeaveClassPopup = function (cls) {
      closeClassDropdowns();

      $scope.viewModel.leavingClass = cls;

      if (_dontShowLeaveClassPopUp)
        $scope.leaveCoteachClass();
      else
        $scope.viewModel.isLeaveClassPopupVisible = true;
    };

    $scope.closeLeaveClassPopup = function () {
      $scope.viewModel.isLeaveClassPopupVisible = false;
    };

    $scope.leaveCoteachClass = function () {
      $scope.closeLeaveClassPopup();

      $scope.leaveClass($scope.viewModel.leavingClass.id);

      $scope.viewModel.joinedClasses.splice($scope.viewModel.joinedClasses.indexOf($scope.viewModel.leavingClass), 1);

      $scope.viewModel.leavingClass = null;

      $scope.selectClass(null);
    };

    $scope.fetchDontShowArchivePopUp().then(function (checked) {
      _dontShowArchivePopUp = checked;
    });

    $scope.saveDontShowArchivePopUpAgain = function () {
      _dontShowArchivePopUp = true;

      $scope.saveDontShowArchivePopUp();
    };

    $scope.fetchDontShowLeaveClassPopUpAgain().then(function (checked) {
      _dontShowLeaveClassPopUp = checked;
    });

    $scope.saveDontShowLeaveClassPopUp = function () {
      _dontShowLeaveClassPopUp = true;

      $scope.saveDontShowLeaveClassPopUpAgain();
    };

    $scope.toggleMyClassesIcons = function () {
      $scope.viewModel.isMyClassesIconsVisible = !$scope.viewModel.isMyClassesIconsVisible;
    };

    $scope.toggleJoinedClassesIcons = function () {
      $scope.viewModel.isJoinedClassesIconsVisible = !$scope.viewModel.isJoinedClassesIconsVisible;
    };

    $scope.isGoogleClassroom = function (cls) {
      if (!cls)
        return false;

      return getGoogleClassroomCourseId(cls);
    };

    function updateStudentClasses (student, classes) {
      var classIds = classes.map(function (cls) {
        return cls.id;
      }).join(',');

      return $scope.editStudent({
        id: student.id,
        classIds: classIds
      });
    }

    function leaveClassForStudent (cls, student) {
      var classes = getStudentClasses(student).slice(0);

      classes = classes.filter(function (c) {
        return cls.id !== c.id;
      });

      return updateStudentClasses(student, classes).then(function () {
        removeStudentFromClass(student, $scope.viewModel.openClasses, cls);
      });
    }

    function joinClassForStudent (cls, student) {
      var classes = getStudentClasses(student).slice(0);

      classes.push(cls);

      return updateStudentClasses(student, classes).then(function () {
        addStudentToClass(student, cls);
      });
    }

    function getGoogleClassroomCourseId (cls) {
      return cls.googleClassroomId;
    }

    function getAllStudents () {
      var students = {};

      $scope.viewModel.openClasses.concat($scope.viewModel.archivedClasses).concat($scope.viewModel.joinedClasses).forEach(function (cls) {
        cls.students.forEach(function (student) {
          if (!students[student.id])
            students[student.id] = student;
        });
      });

      return Object.values(students);
    }

    $scope.syncGoogleClassroom = function (cls) {
      if (!getGoogleClassroomCourseId(cls))
        return;

      googleClassroom.getCourse(getGoogleClassroomCourseId(cls), false).then(function (course) {
        cls.name = course.name;

        var promises = [];

        var allStudents = getAllStudents();
        var registerStudents = [];

        googleClassroom.listStudents(getGoogleClassroomCourseId(cls), false).then(function (students) {
          // leave class for students no longer in google course
          cls.students.forEach(function (student) {
            var googleStudent = students.find(function (s) {
              return s.profile.emailAddress === student.email;
            });

            if (!googleStudent)
              promises.push(leaveClassForStudent(cls, student));
          });

          // add student to class for each new google course student
          students.forEach(function (student) {
            var s = cls.students.find(function (s) {
              return s.email === student.profile.emailAddress;
            });

            if (!s) {
              var existingStudent = allStudents.find(function (s) {
                return s.email === student.profile.email;
              });

              if (existingStudent) {
                promises.push(joinClassForStudent(cls, existingStudent));
              } else {
                student = {
                  firstName: student.profile.name.givenName,
                  lastName: student.profile.name.familyName,
                  email: student.profile.emailAddress,
                  invitationCode: cls.invitationCode,
                  googleId: student.profile.id
                };

                registerStudents.push(student);
              }
            }
          });

          var promise = $scope.registerGoogleClassroomStudents(registerStudents).then(function (result) {
            return handleRegisterGoogleClassroomStudents(result, cls, registerStudents);
          });

          promises.push(promise);

          var success = function () {
            alert($translate.instant('SYNC_SUCCESSFUL'));
          };

          if (promises.length > 0)
            $q.all(promises).then(success);
          else
            success();
        });
      });
    };

    $scope.importGoogleClassroom = function () {
      $scope.closeClassPopup();

      var classes = getAllClasses();

      googleClassroom.listCourses(true).then(function (courses) {
        var user = googleManager.getUser();

        $scope.viewModel.googleUser = {
          name: user.getName(),
          email: user.getEmail()
        };

        if (courses.length === 0) {
          alert($translate.instant('NO_GOOGLE_CLASSROOM_COURSES_FOUND'));

          return;
        }

        courses = courses.filter(function (course) {
          if (course.courseState !== 'ACTIVE')
            return false;

          var exists = classes.find(function (cls) {
            return getGoogleClassroomCourseId(cls) === course.id;
          });

          if (exists)
            return false;

          // course.selected = true;

          return true;
        });

        $scope.viewModel.googleClassroomCourses = courses;

        $scope.viewModel.isImportGoogleClassroomPopUpVisible = true;

        digest();
      });
    };

    function handleRegisterGoogleClassroomStudents (result, cls, preregisterStudents, allStudents) {
      var students = Object.values(result).map(function (s) {
        var registerStudent = preregisterStudents.find(function (rs) {
          return rs.email === s.data.userEmail;
        });

        registerStudent.id = s.data.userId;
        registerStudent.classId = s.data.classId;
        registerStudent.email = s.data.userEmail;

        return registerStudent;
      });

      if (allStudents)
        allStudents = allStudents.concat(students);

      cls.students = cls.students.concat(students);

      return students;
    }

    function loadStudentsForGoogleCourse (cls, course) {
      var allStudents = getAllStudents();
      var registerStudents = [];

      return googleClassroom.listStudents(course.id, false).then(function (students) {
        if (students.length > 0) {
          students.forEach(function (student) {
            var existingStudent = allStudents.find(function (s) {
              return s.email === student.profile.emailAddress;
            });

            if (existingStudent) {
              joinClassForStudent(cls, existingStudent);
            } else {
              student = {
                firstName: student.profile.name.givenName,
                lastName: student.profile.name.familyName,
                email: student.profile.emailAddress,
                invitationCode: cls.invitationCode,
                googleId: student.profile.id
              };

              registerStudents.push(student);
            }
          });

          return $scope.registerGoogleClassroomStudents(registerStudents).then(function (result) {
            handleRegisterGoogleClassroomStudents(result, cls, registerStudents, allStudents);

            return cls;
          });
        } else {
          return cls;
        }
      });
    }

    $scope.switchGoogleAccount = function () {
      $scope.closeImportGoogleClassroomPopUp();

      $scope.importGoogleClassroom();
    };

    $scope.importGoogleClassroomCourses = function () {
      var courses = $scope.viewModel.googleClassroomCourses.filter(function (course) {
        return course.selected;
      });

      $scope.closeImportGoogleClassroomPopUp();

      var promises = [];

      var myGoogleId = googleManager.getUser().getId();

      courses.forEach(function (course) {
        var isCourseOwner = course.ownerId === myGoogleId;

        var promise = $scope.createClass({
          name: course.name,
          grade: course.grade,
          googleClassroomId: course.id
        }).then(function (cls) {
          cls.students = [];

          handleCreateClass(cls);

          return loadStudentsForGoogleCourse(cls, course);
        });

        promises.push(promise);
      });

      $q.all(promises).then(function () {
        $scope.viewModel.isImportGoogleClassroomSuccessPopUpVisible = true;
      });
    };

    $scope.closeImportGoogleClassroomPopUp = function () {
      $scope.viewModel.isImportGoogleClassroomPopUpVisible = false;
    };

    $scope.closeImportGoogleClassroomSuccessPopUp = function () {
      $scope.viewModel.isImportGoogleClassroomSuccessPopUpVisible = false;
    };

    $scope.onSelectAllCoursesChange = function () {
      $scope.viewModel.googleClassroomCourses.forEach(function (course) {
        course.selected = $scope.viewModel.selectAllCourses;
      });
    };

    $scope.onCourseCheckChange = function () {
      var allChecked = $scope.viewModel.googleClassroomCourses.every(function (course) {
        return course.selected;
      });

      $scope.viewModel.selectAllCourses = allChecked;
    };

    $scope.hasClassSelected = function () {
      return !!$scope.viewModel.studentClasses.find(function (cls) {
        return cls.selected;
      });
    };

    $scope.validateImportGoogleClassroomPopUp = function () {
      var isMissingGrade;

      var courses = $scope.viewModel.googleClassroomCourses.filter(function (course) {
        if (course.selected && !course.grade)
          isMissingGrade = true;

        return course.selected;
      });

      if (isMissingGrade) {
        alert($translate.instant('MISSING_GRADE'));

        return false;
      }

      if (courses.length === 0)
        return false;

      return true;
    };

    $scope.isJoinedClass = function (cls) {
      if ($scope.viewModel.joinedClasses)
        return $scope.viewModel.joinedClasses.indexOf(cls) !== -1;
      else
        return false;
    };

    $scope.getGradeLabel = function (grade) {
      var labels = {
        K: 'GRADE_K',
        1: '1ST_GRADE',
        2: '2ND_GRADE',
        3: '3RD_GRADE',
        4: '4TH_GRADE',
        5: '5TH_GRADE',
        6: '6TH_GRADE',
        7: '7TH_GRADE',
        8: '8TH_GRADE',
        HS: 'HIGHSCHOOL'
      };

      return $translate.instant(labels[grade]);
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: linkFunction,
    scope: TeacherScreenDirective.createScope({
      loadClasses: '=',
      loadccssGrades: '=',
      createClass: '=',
      joinExistingClass: '=',
      createStudent: '=',
      registerGoogleClassroomStudents: '=',
      editStudent: '=',
      removeStudent: '=',
      stateParams: '=',
      archiveClass: '=',
      reactivateClass: '=',
      deleteClass: '=',
      loadArchivedClasses: '=',
      allowAnnotations: '=',
      disallowAnnotations: '=',
      expireStudentPassword: '=',
      fetchJoinedClasses: '=',
      rosterTemplateUrl: '=',
      rosterTemplateErrorUrl: '=',
      importRoster: '=',
      changeStudentPassword: '=',
      leaveClass: '=',
      editClass: '=',
      fetchDontShowArchivePopUp: '=',
      saveDontShowArchivePopUp: '=',
      fetchDontShowLeaveClassPopUpAgain: '=',
      saveDontShowLeaveClassPopUpAgain: '='
    })
  };
});

function DelayedAction (callback, delay) {
  var timerId;

  this.queue = function () {
    if (timerId)
      clearTimeout(timerId);

    timerId = setTimeout(callback, delay);
  };
}
