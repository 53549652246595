import 'src/components/shared/user_data';
import 'core/login/forgotpassword/changePasswordScreenDirective';

function ChangePasswordController ($scope, UserManager, CueThinkConfig, $state, $stateParams) {
    "ngInject";

    $scope.data = {};
    $scope.isExpired = $stateParams.isExpired == 1;
    $scope.isTeacherChange = $stateParams.isTeacherChange;
    $scope.name = CueThinkConfig.name;
    $scope.referral = $stateParams.ref;

    $scope.changePassword = function (password) {
        return UserManager.resetPassword(password, $stateParams.code, $stateParams.isTeacherChange == 1);
    };

    $scope.logIn = function () {
        $state.go('login', { ref: $scope.referral });
    };

    $scope.cancel = function () {
        $state.go('login', { ref: $scope.referral });
    };
}

export default ChangePasswordController;
