import 'src/components/shared/user_data';
import 'core/login/forgotpassword/forgotUserNameDirective';

function ForgotUserNameController ($scope, UserManager, CueThinkConfig, $state) {
	"ngInject";

	$scope.name = CueThinkConfig.name;

	$scope.submit = function (email, callback) {
		return UserManager.forgotUserName(email, callback);
	};

	$scope.cancel = function () {
			$state.go('login');
	};

	$scope.forgotPassword = function () {
		$state.go('forgotpassword');
	};
}

export default ForgotUserNameController;
