import { BaseWhiteboardTool, inherit } from './base-tool';

export default function NumberLineTool (whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey, numbers, color, isVertical) {
      this.id = whiteboard.getNextId();
      this.type = NumberLineTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.numbers = numbers;

      if (color)
        this.color = color;

      this.isVertical = isVertical;

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.draw = function (obj) {
    NumberLineTool.renderNumberLine(whiteboard, obj.coordinates[0], obj.coordinates[1], obj.coordinates[2], obj.coordinates[3], obj.numbers, obj.color, undefined, undefined, undefined, undefined, undefined, undefined, obj.isVertical);
  };
}

NumberLineTool.type = 'numberline';

inherit(NumberLineTool, BaseWhiteboardTool);

NumberLineTool.isNumberLineHorizontal = function (whiteboard, numberLine) {
  if (typeof numberLine.isVertical !== 'undefined')
    return !numberLine.isVertical;

  var sx = numberLine.coordinates[0];
  var sy = numberLine.coordinates[1];
  var ex = numberLine.coordinates[2];
  var ey = numberLine.coordinates[3];
  var w = (ex - sx) * whiteboard.getWidth();
  var h = (ey - sy) * whiteboard.getHeight();

  return w >= h;
};

NumberLineTool.getProgressForNumberLine = function (x, y, w, h, count, i, isHorizontal, tickSpacing) {
  var length, start, padding;

  if (typeof tickSpacing === 'number')
    padding = tickSpacing;
  else
    padding = .1;

  if (isHorizontal) {
    start = x;
    length = w * (1 - padding - padding);

    if (!tickSpacing)
      padding = w * padding;
  } else {
    start = y;
    length = h * (1 - padding - padding);

    if (!tickSpacing)
      padding = padding * h;
  }

  if (tickSpacing) {
    return start + ((i + 1) * padding);
  } else {
    return start + padding + ((i * length) / (count - 1));
  }
};

NumberLineTool.getBoundsForNumberLineCell = function (whiteboard, sx, sy, ex, ey, text, length, i, isHorizontal, fontSize, flipNumberPosition, textSpacing, tickSpacing) {
  var w = ex - sx;
  var h = ey - sy;
  var numberLine = { coordinates: [sx, sy, ex, ey] };

  if (!fontSize)
    fontSize = NumberLineTool.getNumberLineFontSize(whiteboard, numberLine, null, isHorizontal);

  var cellSize = fontSize;

  var size = cellSize / whiteboard.getHeight();
  var progress = NumberLineTool.getProgressForNumberLine(sx, sy, w, h, length, i, isHorizontal, tickSpacing);

  if (typeof textSpacing !== 'number')
    textSpacing = 8

  if (isHorizontal)
    textSpacing /= whiteboard.getHeight();
  else
    textSpacing /= whiteboard.getWidth();

  var result = {
    width: cellSize / whiteboard.getWidth(),
    height: size
  };

  if (isHorizontal) {
    result.x = progress - (fontSize / (2 * whiteboard.getWidth()));

    if (flipNumberPosition)
      result.y = sy - textSpacing - size;
    else
      result.y = sy + (h / 2) + textSpacing;
  } else {
    if (flipNumberPosition) {
      result.x = sx - textSpacing - (18 / (whiteboard.getWidth())); // not dependent on text width because the number box is a fixed size and the render code centers the number within it
    } else {
      result.x = sx + (w / 2) + textSpacing;
    }

    result.y = progress - (fontSize / (1.25 * whiteboard.getHeight()));
  }

  return result;
};

NumberLineTool.walkNumberLine = function (x, y, w, h, count, callback, isHorizontal, tickSpacing) {
  for (var i = 0; i < count; i++) {
    var result = callback(i, NumberLineTool.getProgressForNumberLine(x, y, w, h, count, i, isHorizontal, tickSpacing));

    if (result !== undefined)
      return result;
  }
};

NumberLineTool.getNumberLineFontSize = function (whiteboard, numberLine, count, isHorizontal) {
  var length;

  if (isHorizontal)
    length = (numberLine.coordinates[2] - numberLine.coordinates[0]) * whiteboard.getWidth();
  else
    length = (numberLine.coordinates[3] - numberLine.coordinates[1]) * whiteboard.getHeight();

  var result = (length * 24) / 1008;

  if (isHorizontal)
    result /= 2;

  result = Math.max(result, 8);

  return result;
};

NumberLineTool.setNumberLineFontSize = function (whiteboard, w, h, count, isHorizontal) {
  var numberLine;

  if (typeof w == 'number') {
    numberLine = { coordinates: [0, 0, w, h] };
  } else {
    numberLine = w;
    isHorizontal = count;
    count = h;

    var sx = numberLine.coordinates[0];
    var sy = numberLine.coordinates[1];
    var ex = numberLine.coordinates[2];
    var ey = numberLine.coordinates[3];

    sx *= whiteboard.getWidth();
    sy *= whiteboard.getHeight();
    ex *= whiteboard.getWidth();
    ey *= whiteboard.getHeight();

    w = ex - sx;
    h = ey - sy;

    // scale numbers down by number of number lines
    if (numberLine.numbers && numberLine.numbers.length > 0 && Array.isArray(numberLine.numbers[0])) {
      if (isHorizontal) {
        h /= numberLine.length;
      } else {
        w /= numberLine.length;
      }
    }
  }

  whiteboard.context.font = NumberLineTool.getNumberLineFontSize(whiteboard, numberLine, count, isHorizontal) + 'px ' + whiteboard.getFont();
};

NumberLineTool.renderNumberLine = function (whiteboard, sx, sy, ex, ey, numbers, color, fontSize, flipNumberPosition, textSpacing, lineWidth, showMiddleTick, tickSpacing, isVertical) {
  if (typeof showMiddleTick === 'undefined')
    showMiddleTick = true;

  var numberLine = {
    color: color,
    coordinates: [sx, sy, ex, ey],
    numbers: numbers,
    isVertical
  };

  if (!numbers)
    numbers = [];

  var numberArray = numbers;

  if (!Array.isArray(numbers[0]))
    numberArray = [numbers];

  sx *= whiteboard.getWidth();
  sy *= whiteboard.getHeight();
  ex *= whiteboard.getWidth();
  ey *= whiteboard.getHeight();

  var w = ex - sx;
  var h = ey - sy;
  var isHorizontal = NumberLineTool.isNumberLineHorizontal(whiteboard, numberLine);

  if (isHorizontal)
    h /= numberArray.length > 0 ? numberArray.length : 1;
  else
    w /= numberArray.length > 0 ? numberArray.length : 1;

  var start = {}, end = {};
  var ARROW_SIZE = 20;
  var arrowSize;
  var halfArrowSize;
  var offset;

  if (isHorizontal)
    offset = (h / 2);
  else
    offset = (w / 2);

  offset /= 2;

  whiteboard.setStrokeColor(color);
  whiteboard.setFillColor(color);

  if (!lineWidth)
    lineWidth = 1;

  whiteboard.context.lineWidth = lineWidth;

  var PIXEL_OFFSET = 1;

  for (var numberLineIndex = 0; numberLineIndex < numberArray.length; numberLineIndex++) {
    var nums = numberArray[numberLineIndex];

    if (isHorizontal) { // horizontal
      arrowSize = (ARROW_SIZE * w) / 1008;
      halfArrowSize = arrowSize / 2;

      start.x = sx;
      start.y = sy + (numberLineIndex * h);

      end.x = ex;
      end.y = sy + ((numberLineIndex + 1) * h);

      // left arrow
      whiteboard.context.beginPath();
      whiteboard.context.moveTo(sx, start.y + offset - PIXEL_OFFSET);
      whiteboard.context.lineTo(sx + arrowSize, start.y + offset - halfArrowSize - PIXEL_OFFSET);
      whiteboard.context.lineTo(sx + arrowSize, start.y + offset + halfArrowSize - PIXEL_OFFSET);
      whiteboard.context.lineTo(sx, start.y + offset - PIXEL_OFFSET);
      whiteboard.context.closePath();
      whiteboard.context.fill();

      // right arrow
      whiteboard.context.beginPath();
      whiteboard.context.moveTo(ex, start.y + offset - PIXEL_OFFSET);
      whiteboard.context.lineTo(ex - arrowSize, start.y + offset - halfArrowSize - PIXEL_OFFSET);
      whiteboard.context.lineTo(ex - arrowSize,start.y + offset + halfArrowSize - PIXEL_OFFSET);
      whiteboard.context.lineTo(ex, start.y + offset - PIXEL_OFFSET);
      whiteboard.context.closePath();
      whiteboard.context.fill();
    } else { // vertical
      if (nums)
        nums = nums.slice(0).reverse();

      arrowSize = (ARROW_SIZE * h) / 1008;
      halfArrowSize = arrowSize / 2;

      start.x = sx + (numberLineIndex * w);
      start.y = sy;

      end.x = sx + ((numberLineIndex + 1) * w);
      end.y = ey;

      // top arrow
      whiteboard.context.beginPath();
      whiteboard.context.moveTo(start.x + offset - PIXEL_OFFSET, sy);
      whiteboard.context.lineTo(start.x + offset - halfArrowSize - PIXEL_OFFSET, sy + arrowSize);
      whiteboard.context.lineTo(start.x + offset + halfArrowSize - PIXEL_OFFSET, sy + arrowSize);
      whiteboard.context.lineTo(start.x + offset - PIXEL_OFFSET, sy);
      whiteboard.context.closePath();
      whiteboard.context.fill();

      // bottom arrow
      whiteboard.context.beginPath();
      whiteboard.context.moveTo(start.x + offset - PIXEL_OFFSET, ey);
      whiteboard.context.lineTo(start.x + offset - halfArrowSize - PIXEL_OFFSET, ey - arrowSize);
      whiteboard.context.lineTo(start.x + offset + halfArrowSize - PIXEL_OFFSET, ey - arrowSize);
      whiteboard.context.lineTo(start.x + offset - PIXEL_OFFSET, ey);
      whiteboard.context.closePath();
      whiteboard.context.fill();
    }

    // middle line
    whiteboard.context.beginPath();

    if (isHorizontal) {
      whiteboard.context.moveTo(start.x, start.y + offset - PIXEL_OFFSET);
      whiteboard.context.lineTo(end.x, start.y + offset - PIXEL_OFFSET);
    } else {
      whiteboard.context.moveTo(start.x + offset - PIXEL_OFFSET, start.y);
      whiteboard.context.lineTo(start.x + offset - PIXEL_OFFSET, end.y);
    }

    whiteboard.context.closePath();
    whiteboard.context.stroke();

    var tickLength = Math.max(offset, 8);

    if (nums && nums.length > 0) {
      if (fontSize)
        whiteboard.context.font = fontSize + 'px ' + whiteboard.getFont();
      else
        NumberLineTool.setNumberLineFontSize(whiteboard, numberLine, nums.length, isHorizontal);

      NumberLineTool.walkNumberLine(start.x, start.y, w, h, nums.length, function (i, progress) {
        if (isHorizontal)
          end.x = start.x = progress;
        else
          end.y = start.y = progress;

        // tick mark
        if (showMiddleTick || nums.length % 2 === 0 || i !== Math.floor(nums.length / 2)) {
          whiteboard.setStrokeColor(color);
          whiteboard.context.beginPath();

          if (isHorizontal) {
            whiteboard.context.moveTo(start.x - PIXEL_OFFSET, start.y + offset - (tickLength / 2));
            whiteboard.context.lineTo(end.x - PIXEL_OFFSET, start.y + offset + (tickLength / 2));
          } else {
            whiteboard.context.moveTo(start.x + offset - (tickLength / 2), start.y - PIXEL_OFFSET);
            whiteboard.context.lineTo(start.x + offset + (tickLength / 2), end.y - PIXEL_OFFSET);
          }

          whiteboard.context.closePath();
          whiteboard.context.stroke();

          NumberLineTool.renderNumberLineText(whiteboard, numberLine, numberLineIndex, nums[i], i, fontSize, isHorizontal, flipNumberPosition, textSpacing, tickSpacing);
        }
      }, isHorizontal, tickSpacing);
    }
  }
};

NumberLineTool.renderNumberLineText = function (whiteboard, numberLine, numberLineIndex, text, index, setFont, isHorizontal, flipNumberPosition, textSpacing, tickSpacing) {
  var numberArray = numberLine.numbers;

  if (numberArray && numberArray.length > 0 && !Array.isArray(numberArray[0]))
    numberArray = [numberArray];

  var numbers = numberArray[numberLineIndex];

  if (typeof setFont === 'function')
    NumberLineTool.setNumberLineFontSize(whiteboard, numberLine, null, isHorizontal);

  var childNumberLine = {
    coordinates: numberLine.coordinates.slice(0)
  };

  // offset number line by number line index
  if (isHorizontal) {
    var height = (childNumberLine.coordinates[3] - childNumberLine.coordinates[1]) / numberArray.length;

    childNumberLine.coordinates[1] += height * numberLineIndex;
    childNumberLine.coordinates[3] = childNumberLine.coordinates[1] + height;

    if (tickSpacing)
      tickSpacing /= whiteboard.getWidth();
  } else {
    var width = (childNumberLine.coordinates[2] - childNumberLine.coordinates[0]) / numberArray.length;

    childNumberLine.coordinates[0] += width * numberLineIndex;
    childNumberLine.coordinates[2] = childNumberLine.coordinates[0] + width;

    if (tickSpacing)
      tickSpacing /= whiteboard.getHeight();
  }

  var bounds = NumberLineTool.getBoundsForNumberLineCell(whiteboard, childNumberLine.coordinates[0], childNumberLine.coordinates[1], childNumberLine.coordinates[2], childNumberLine.coordinates[3], text, numbers.length, index, isHorizontal, typeof setFont === 'number' ? setFont : null, flipNumberPosition, textSpacing, tickSpacing);
  bounds.x *= whiteboard.getWidth();
  bounds.y *= whiteboard.getHeight();
  bounds.width *= whiteboard.getWidth();
  bounds.height *= whiteboard.getHeight();

  if (text || text === 0) {
    // whiteboard.setFillColor('white');
    // whiteboard.context.fillRect(bounds.x - 1, bounds.y - 1, bounds.width + 2, bounds.height + 2);

    var textWidth = whiteboard.context.measureText(text).width;

    whiteboard.setFillColor(numberLine.color);
    whiteboard.context.fillText(text, bounds.x + ((bounds.width - textWidth) / 2), bounds.y + bounds.height);
  }/* else { // TODO: disable in lieu of letting user use text tool
    whiteboard.setStrokeColor('black');
    var oldLineWidth = whiteboard.context.lineWidth;
    whiteboard.context.lineWidth = 1;
    whiteboard.context.beginPath();
    whiteboard.context.rect(bounds.x, bounds.y, bounds.width, bounds.height);
    whiteboard.context.closePath();
    whiteboard.context.stroke();
    whiteboard.context.lineWidth = oldLineWidth;
  }*/
};
