import 'jquery';
import 'angular-local-storage/dist/angular-local-storage.min';
import 'src/app/navigation/teachernavbarController';
import 'core/rubric/rubric.module';
import 'core/teacherscreen/teacherScreenDirective';
import 'ng-infinite-scroll/build/ng-infinite-scroll';
import 'src/app/common/utility/recursionHelper';
import '../content/contentModule';
import 'core/tutorial/tutorial.js';
import 'core/devicedetect/deviceDetectionUtil.js';
import 'core/common/commonModule.js';
import viewBankTpl from './viewbank/viewAllProblems.tpl.html';
import assignProblemTpl from './assign/assignProblems.tpl.html';
import viewAssignedTpl from './viewassigned/viewAssigned.tpl.html';
import { setUpProblemFilterDirective } from './viewbank/filterContent/filterContentDirective'
import viewContentAssignmentBankController from './viewbank/viewContentController';
import assignProblemsController from './assign/assignProblemsController';
import viewAssignedController from './viewassigned/viewAssignedController';
import 'angular-ui-bootstrap';
import 'angular-translate';
import { setUpFoldersDirective } from './viewbank/foldersDirective';

var contentModule = angular.module('cuethinkApp.contentassignment', ['LocalStorageModule', 'cuethinkApp.teacherNavController', 'cuethink.rubric', 'ngDragDrop', 'cuethink.teacherscreen', 'infinite-scroll', 'RecursionHelper', 'cuethinkApp.content', 'cuethink.tutorial', 'deviceDetection', 'cuethink.common', 'ui.bootstrap', 'pascalprecht.translate']);

export default contentModule;

setUpProblemFilterDirective(contentModule);
setUpFoldersDirective(contentModule);

contentModule.config(function ($stateProvider) {
  $stateProvider
  .state('view-all-problems', {
    url: '/view-all-problems?problemId&id&addAssignmentProblems&state',
    template: viewBankTpl,
    controller: viewContentAssignmentBankController,
    params: {
      id: {
        dynamic: true
      }
    }
  })
  .state('assign-problems', {
    url: '/assign-problems/:id?isAddingProblems',
    template: assignProblemTpl,
    controller: assignProblemsController,
    params: {
      id: {
        value: ''
      }
    }
  })
  .state('view-all-assignments', {
    url: '/view-all-assignments',
    params: { showHelper: false },
    template: viewAssignedTpl,
    controller: viewAssignedController
  });
}).filter('stripHtml', function () {
  function strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  return function (input) {
    return strip(input);
  };
}).filter('shorten', function () {
  return function (str) {
    if (str.length > 25)
      str = str.substr(0, 22) + '...';

    return str;
  };
}).filter('toTrusted', function ($sce) {
  return function (value) {
    return $sce.trustAsHtml(value);
  };
});
