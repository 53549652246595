(function () {
  var k = {};

  function l (e, t) {
    if (!(e instanceof t))
      throw new TypeError("Cannot call a class as a function");
  }

  function g (e, t) {
    for (var r = 0; r < t.length; r++) {
      var a = t[r];

      a.enumerable = a.enumerable || false;
      a.configurable = true;

      if ("value" in a)
        a.writable = true;

      Object.defineProperty(e, a.key, a);
    }
  }

  function m (e, t, r) {
    return t && g(e.prototype, t), r && g(e, r), e;
  }

  var context, f, h = window.AudioContext || window.webkitAudioContext;

  function o (e) {
    var t = e.toString().replace(/^(\(\)\s*=>|function\s*\(\))\s*{/, "").replace(/}$/, "");
    var r = new Blob([t]);

    return new Worker(URL.createObjectURL(r));
  }

  function d (e) {
    var t = new Event("error");

    return t.data = new Error("Wrong state for " + e), t;
  }

  var AudioRecorderPolyfill = function () {
    function e () {
      var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;

      l(this, e);

      context = new h(this.config);
      this.config = r;
      this.state = "inactive";
      this.em = document.createDocumentFragment();
      this.encoder = o(e.encoder);
      var a = this;

      this.encoder.addEventListener("message", function (e) {
        var t = new Event("dataavailable");

        t.data = new Blob([e.data], {
          type: a.mimeType
        });

        a.em.dispatchEvent(t);

        if ("inactive" === a.state)
          a.em.dispatchEvent(new Event("stop"));
      });
    }

    var commands = [
    {
      key: 'setStream',
      value: function (stream) {
        this.stream = stream;
      }
    },
    {
      key: "start",
      value: function () {
        if ("inactive" !== this.state)
          return this.em.dispatchEvent(d("start"));

        if (!this.stream)
          throw 'Set stream before starting recording';

        this.state = "recording";
        this.input = context.createMediaStreamSource(this.stream);

        f = context.createScriptProcessor(2048, 1, 1);

        var t = this;

        t.encoder.postMessage(["init", context.sampleRate]);

        f.onaudioprocess = function (e) {
          if ("recording" === t.state)
            t.encoder.postMessage(["encode", e.inputBuffer.getChannelData(0)]);
        };

        this.input.connect(f);
        f.connect(context.destination);
        this.em.dispatchEvent(new Event("start"));
      }
    }, {
      key: "stop",
      value: function () {
        if ("inactive" === this.state) {
          return this.em.dispatchEvent(d("stop"));
        } else {
          this.requestData();

          this.state = "inactive";

          this.stream.getTracks().forEach(function (e) {
            e.stop();
          });

          this.input.disconnect();
          f.disconnect();
          context.close();
        }
      }
    }, {
      key: "pause",
      value: function () {
        if ("recording" === this.state) {
          this.state = "paused";

          return this.em.dispatchEvent(new Event("pause"));
        } else {
          return this.em.dispatchEvent(d("pause"));
        }
      }
    }, {
      key: "resume",
      value: function () {
        if ("paused" === this.state) {
          this.state = "recording";

          return this.em.dispatchEvent(new Event("resume"));
        } else {
          return this.em.dispatchEvent(d("resume"));
        }
      }
    }, {
      key: "requestData",
      value: function () {
        if ("inactive" === this.state)
          return this.em.dispatchEvent(d("requestData"));
        else
          return this.encoder.postMessage(["dump", context.sampleRate]);
      }
    }, {
      key: "addEventListener",
      value: function () {
        var e = this.em;

        e.addEventListener.apply(e, arguments);
      }
    }, {
      key: "removeEventListener",
      value: function () {
        var e = this.em;

        e.removeEventListener.apply(e, arguments);
      }
    }, {
      key: "dispatchEvent",
      value: function () {
        var e = this.em;

        e.dispatchEvent.apply(e, arguments);
      }
    }];

    return m(e, commands);
  }();

  AudioRecorderPolyfill.prototype.mimeType = "audio/wav";

  AudioRecorderPolyfill.isTypeSupported = function (e) {
    return AudioRecorderPolyfill.prototype.mimeType === e;
  };

  AudioRecorderPolyfill.notSupported = !navigator.mediaDevices || !h;

  AudioRecorderPolyfill.encoder = function () {
    var t = 2, n = [];

    this.onmessage = function (e) {
      if ("encode" === e.data[0]) {
        (function (e) {
          for (var s = e.length, a = new Uint8Array(s * t), i = 0; i < s; i++) {
            var r = i * t;
            var U = e[i];

            if (U > 1)
              U = 1;
            else if (U < -1)
              U = -1;

            U *= 32768;
            a[r] = U;
            a[r + 1] = U >> 8;
          }

          n.push(a);
        })(e.data[1]);
      } else if ("dump" === e.data[0]) {
        (function (e) {
          var s = n.length ? n[0].length : 0;
          var a = n.length * s;
          var i = new Uint8Array(44 + a);
          var r = new DataView(i.buffer);

          r.setUint32(0, 1380533830, false);
          r.setUint32(4, 36 + a, true);
          r.setUint32(8, 1463899717, false);
          r.setUint32(12, 1718449184, false);
          r.setUint32(16, 16, true);
          r.setUint16(20, 1, true);
          r.setUint16(22, 1, true);
          r.setUint32(24, e, true);
          r.setUint32(28, e * t, true);
          r.setUint16(32, t, true);
          r.setUint16(34, 8 * t, true);
          r.setUint32(36, 1684108385, false);
          r.setUint32(40, a, true);

          for (var U = 0; U < n.length; U++)
            i.set(n[U], U * s + 44);

          n = [];

          this.postMessage(i.buffer, [i.buffer]);
        })(e.data[1]);
      }
    };
  };

  k = AudioRecorderPolyfill;
  window.MediaRecorder = k;
})();
