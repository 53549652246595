import module from './settings.module';
import './settingsDirective';
import tpl from './gallerySettingsDirective.tpl.html';
import CueThinkSettingsDirective from './settingsDirective';

export default function GallerySettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    CueThinkSettingsDirective.bootstrapSettingsDirective($scope, $translate, $rootScope);
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: CueThinkSettingsDirective.createSettingsScope()
  };
};

module.directive('gallerySettings', GallerySettingsDirective);
