import 'core/login/loginDirective';
import loginTpl from './login.tpl.html';
import registerTpl from './register/register.tpl.html';
import forgotPasswordTpl from './forgotPassword/forgotPassword.tpl.html';
import forgotUserNameTpl from './forgotPassword/forgotUserName.tpl.html';
import changePasswordTpl from './forgotPassword/changePassword.tpl.html';
import ssoRegisterTpl from './ssoregister/ssoregister.tpl.html';
import linkAccountTpl from './linkAccount/linkAccount.tpl.html';
import loginController from './loginController';
import registerController from './register/registerController';
import forgotPasswordController from './forgotPassword/forgotPasswordController';
import forgotUserNameController from './forgotPassword/forgotUserNameController';
import changePasswordController from './forgotPassword/changePasswordController';
import ssoRegisterController from './ssoregister/ssoregisterController';
import linkAccountController from './linkAccount/linkAccountController';
import cleverController  from './clever/CleverController';
import registerChoiceTpl from './register/registerChoice.tpl.html';
import RegisterChoiceController from './register/registerChoiceController';

angular.module('cuethinkApp.login', ['cuethink.login'])
.config(function ($stateProvider) {
  $stateProvider
  .state('login', {
    url: '/login?redirect&clever&sso&sig&ref&automatic&token',
    template: loginTpl,
    controller: loginController
  })

  /*.state('registerchoice', {
    url: '/registerchoice?ref',
    template: registerChoiceTpl,
    controller: RegisterChoiceController
  })

  .state('register', {
    url: '/register?ref',
    template: registerTpl,
    controller: 'registerController',
    params: { type: 'student' }
  })

  .state('registerstudent', {
    url: '/register/student?ref',
    template: registerTpl,
    controller: registerController,
    params: { type: 'student' }
  })

  .state('registerteacher', {
    url: '/register/educator?ref',
    template: registerTpl,
    controller: registerController,
    params: { type: 'teacher' }
  })*/

  .state('forgotpassword', {
    url: '/forgotpassword',
    template: forgotPasswordTpl,
    controller: forgotPasswordController
  })

  .state('forgotusername', {
    url: '/forgotusername',
    template: forgotUserNameTpl,
    controller: forgotUserNameController
  })

  .state('changepassword', {
    url: '/changepassword/:code?ref',
    template: changePasswordTpl,
    controller: changePasswordController
  })

  .state('updatepassword', {
    url: '/updatepassword/:code',
    template: changePasswordTpl,
    params: { isExpired: true },
    controller: changePasswordController
  })

  .state('changepassword2', {
    url: '/changepassword/:code/:isTeacherChange',
    template: changePasswordTpl,
    controller: changePasswordController
  })

  .state('googlesignup', {
    url: '/googlesignup?email&name&id_token&is_mobile&ref',
      template: ssoRegisterTpl,
    controller: ssoRegisterController,
    params: { is_google: true }
  })

  .state('cleversignup', {
    url: '/cleversignup?token&email&name&first_name&last_name&grade&school&type&is_mobile&ref',
    template: ssoRegisterTpl,
    controller: ssoRegisterController,
    params: { is_clever: true }
  })

  .state('linkaccount', {
    url: '/linkaccount/:service?ref',
    template: linkAccountTpl,
    controller: linkAccountController
  })

  .state('linkclever', {
    url: '/linkclever?ref',
    template: linkAccountTpl,
    controller: linkAccountController,
    params: { service: 'clever' }
  })

  .state('clevercallback', {
    url: '/clevercallback?code&scope&state',
    controller: cleverController
  });
});
