export default function NotificationCenter () {
	var _me = this, _listeners = {};

	_me.addListener = function (name, listener) {
		var items = _listeners[name];

		if (!items)
			_listeners[name] = items = [];

		items.push(listener);

		return () => {
			_me.removeListener(name, listener);
		};
	};

	_me.post = function (name, value) {
		var items = _listeners[name];

		if (!items)
			return;

		for (var i = 0; i < items.length; i++) {
			try {
				items[i](value);
			} catch (e) {
				console.log(e);
			}
		}
	};

	_me.removeListener = function (name, value) {
		var items = _listeners[name];

		if (!items)
			return;

		var i = items.indexOf(value);

		if (i != -1)
			items.splice(i, 1);
	};
}

NotificationCenter.getShared = function () {
	if (!NotificationCenter.sharedInstance)
		NotificationCenter.sharedInstance = new NotificationCenter();

	return NotificationCenter.sharedInstance;
};