import { BaseWhiteboardTool } from './base-tool';

export default function FractionBarTool(whiteboard) {
  BaseWhiteboardTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, type) {
      this.id = whiteboard.getNextId();
      this.type = FractionBarTool.type;
      this.label = type;

      var size = FractionBarTool.getSize(type);
      var w = size.width;
      var h = size.height;

      var ex = sx + (w / whiteboard.getWidth());
      var ey = sy + (h / whiteboard.getHeight());

      this.coordinates = [sx, sy, ex, ey];

      whiteboard.updateEventTime(this);
    };
  };

  this.isSelectable = function () {
    return true;
  };

  this.isUndoable = function () {
    return true;
  };

  this.isTransformable = function () {
    return false;
  };

  this.isRotatable = function () {
    return true;
  };

  this.setStyles = function (obj) {
    var strokeColor, fillColor;

    if (obj.label === '1') {
      strokeColor = '#DF685D';
      fillColor = '#E6867E';
    } else if (obj.label === '1/2') {
      strokeColor = '#D1589E';
      fillColor = '#DA79B1';
    } else if (obj.label === '1/3') {
      strokeColor = '#F5A623';
      fillColor = '#F7B84F';
    } else if (obj.label === '1/4') {
      strokeColor = '#F8E71C';
      fillColor = '#F9EC49';
    } else if (obj.label === '1/5') {
      strokeColor = '#69B36C';
      fillColor = '#69B36C';
    } else if (obj.label === '1/6') {
      strokeColor = '#3296C0';
      fillColor = '#55B8E2';
    } else if (obj.label === '1/8') {
      strokeColor = '#566FB5';
      fillColor = '#788CC4';
    } else if (obj.label === '1/10') {
      strokeColor = '#D1589E';
      fillColor = '#74C3C4';
    } else if (obj.label === '1/12') {
      strokeColor = '#B0B0B0';
      fillColor = '#BFBFBF';
    }

    whiteboard.setStrokeColor(strokeColor);
    whiteboard.setFillColor(fillColor);
    whiteboard.setLineWidth(0.5);
    whiteboard.getContext().font = '12px Museo Sans';
  };

  function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke === 'undefined') {
      stroke = true;
    }

    if (typeof radius === 'undefined') {
      radius = 5;
    }

    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};

      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

    if (fill) {
      ctx.fill();
    }

    if (stroke) {
      ctx.stroke();
    }
  }

  this.draw = function (obj) {
    var sx, sy, ex, ey, w, h;

    sx = obj.coordinates[0] * whiteboard.getWidth();
    sy = obj.coordinates[1] * whiteboard.getHeight();
    ex = obj.coordinates[2] * whiteboard.getWidth();
    ey = obj.coordinates[3] * whiteboard.getHeight();

    var context = whiteboard.getContext();

    w = ex - sx;
    h = ey - sy;
    context.fillRect(sx, sy, w, h);
    context.strokeRect(sx, sy, w, h);

    roundRect(context, sx, sy, w, h, 2, true, true);

    var textWidth = context.measureText(obj.label).width;
    var textHeight = 12;

    whiteboard.setFillColor('black');

    var textXOffset = 0;
    var textYOffset = -2;

    var slashIndex = obj.label.indexOf('/');

    if (slashIndex === -1) {
      context.fillText(obj.label, sx + ((w - textWidth) / 2) + textXOffset, sy + ((h - textHeight) / 2) + textHeight + textYOffset, 1000, textHeight);
    } else {
      var numerator = obj.label.substr(0, slashIndex);
      var denominator = obj.label.substr(slashIndex + 1);

      whiteboard.getContext().font = '8px Museo Sans';

      drawFraction(context, numerator, denominator, sx + (w / 2), sy + (h / 2));
    }
  };
}

FractionBarTool.type = 'fractionbar';

function drawFraction(context, numerator, denominator, x, y) {
  var numeratorWidth = context.measureText(numerator).width;
  var denominatorWidth = context.measureText(denominator).width;
  var textWidth;

  if (numeratorWidth > denominatorWidth)
    textWidth = numeratorWidth;
  else
    textWidth = denominatorWidth;

  var textHeight = parseFloat(context.font);

  // numerator
  context.fillText(numerator, x - (numeratorWidth / 2), y - 2, 1000, textHeight);

  // divider
  context.lineWidth = 0.5;
  context.strokeStyle = context.fillStyle;
  context.moveTo(x - (textWidth / 2) - 2, y + 0.5);
  context.lineTo(x + (textWidth / 2) + 2, y + 0.5);
  context.stroke();

  // denominator
  context.fillText(denominator, x - (denominatorWidth / 2), y + textHeight, 1000, textHeight);
}

FractionBarTool.getSize = function (type) {
  var w, h = 25;

  if (type === '1')
    w = 249;
  else if (type === '1/2')
    w = 123;
  else if (type === '1/3')
    w = 80;
  else if (type === '1/4')
    w = 62;
  else if (type === '1/5')
    w = 49;
  else if (type === '1/6')
    w = 42;
  else if (type === '1/8')
    w = 31;
  else if (type === '1/10')
    w = 24;
  else if (type === '1/12')
    w = 22;

  return { width: w, height: h };
};
