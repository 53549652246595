import './cuethinkPopUpDirective.css';
import tpl from './cuethinkPopUpDirective.tpl.html';
import './images/Close x.svg';
import Tutorial from 'core/tutorial/tutorial';
import 'angular-translate';

function CuethinkPopUpDirective ($translate) {
  "ngInject";

  var linkFunction = function ($scope, el, attrs) {
    var _spotlightElement;
      
    $scope.viewModel = {
      dontShowAgain: typeof $scope.dontShowAgainChecked === 'boolean' ? $scope.dontShowAgainChecked : true
    };

    $scope.$watch('title', value => {
      if (value) {
        $translate(value).then(translation => {
          $scope.translatedTitle = translation;
        }).catch(() => {
          $scope.translatedTitle = value;
        });
      } else {
        $scope.translatedTitle = value;
      }
    });

    $scope.submit = function () {
      if ($scope.validate && !$scope.validate())
        return;

      var result = $scope.onSubmit();

      if (result !== false)
        $scope.close(true);
    };

    function saveDontShow () {
      if ($scope.saveDontShowAgain && $scope.viewModel.dontShowAgain && $scope.dontShowAgainKey)
        $scope.saveDontShowAgain($scope.dontShowAgainKey);
    }

    $scope.close = function (submitted) {
      if (_spotlightElement) {
        _spotlightElement.remove();
        _spotlightElement = null;
      }

      saveDontShow();

      $scope.onClose({ submitted: submitted });
    };

    $scope.cancel = function () {
      saveDontShow();

      $scope.onCancel();
    };

    $scope.isCancelButtonVisible = function () {
      return typeof $scope.cancelText === 'string' && $scope.cancelText.length > 0;
    };

    if ($scope.spotlight) {
      _spotlightElement = Tutorial.spotlightElement($scope.spotlight);
    }
    
    $scope.getBodyStyle = function () {
      return {
        bottom: $scope.bottom,
        left: $scope.left,
        right: $scope.right,
        top: $scope.top,
        transform: ($scope.bottom || $scope.left || $scope.right || $scope.top) ? 'none' : null,
        '-webkit-transform': ($scope.bottom || $scope.left || $scope.right || $scope.top) ? 'none' : null
      };
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    transclude: true,
    link: linkFunction,
    scope: {
      arrow: '@',
      bottom: '@',
      cancelText: '@',
      dontShowAgainChecked: '=',
      dontShowAgainKey: '@',
      icon: '@',
      left: '@',
      message: '@',
      onCancel: '&',
      onClose: '&',
      onSubmit: '&',
      right: '@',
      saveDontShowAgain: '=',
      showClose: '=',
      spotlight: '@',
      submitText: '@',
      title: '@',
      top: '@',
      validate: '='
    }
  };
}

export default CuethinkPopUpDirective;
