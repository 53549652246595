import module from './settings.module';
import './settingsDirective';
import tpl from './annotationSettingsDirective.tpl.html';
import 'core/common/textFieldListDirective';
import CueThinkSettingsDirective from './settingsDirective';
import './images/UpArrow.svg';
import './images/DownArrow.svg';

export default function AnnotationSettingsDirective ($translate, $rootScope) {
  "ngInject";

  var link = function ($scope) {
    CueThinkSettingsDirective.bootstrapSettingsDirective($scope, $translate, $rootScope);

    Object.assign($scope.viewModel, {
      classes: null,
      justSaved: false,
      newSentenceStarter: null,
      sentenceStarters: null
    });

    $scope.loadClasses().then(function (classes) {
      classes.forEach(function (cls) {
        if ($scope.settings.notAllowedForClasses.indexOf(cls.id) !== -1)
          cls.selected = true;
      });

      $scope.viewModel.classes = classes;
    });

    function translateSentenceStarters() {
      $scope.viewModel.sentenceStarters = [];

      $scope.settings.sentenceStarters.forEach(function (starter) {
        if (starter.text) {
          $translate(starter.text).then(function (translation) {
            $scope.viewModel.sentenceStarters.push({
              enabled: starter.enabled,
              text: translation
            });
          }).catch(function (id) {
            $scope.viewModel.sentenceStarters.push({
              enabled: starter.enabled,
              text: id
            });
          });
        } else {
          $scope.viewModel.sentenceStarters.push({
            enabled: starter.enabled,
            text: starter.text
          });
        }
      });
    }

    translateSentenceStarters();

    var unsubscribe = $rootScope.$on('$translateChangeSuccess', function () {
      translateSentenceStarters();
    });

    $scope.$on("$destroy", function() {
      unsubscribe();
    });

    $scope.isClassesDropdownEnabled = function () {
      return $scope.settings.enabled === 'restricted';
    };

    $scope.getSelectedClassNames = function () {
      if ($scope.isClassesDropdownEnabled() && $scope.viewModel.classes) {
        return $scope.viewModel.classes.filter(function (cls) {
          return cls.selected;
        }).map(function (cls) {
          return cls.name;
        }).join(', ');
      }

      return '';
    };

    $scope.addStarter = function () {
      if (!$scope.viewModel.newSentenceStarter)
        return;

      $scope.settings.sentenceStarters.push({
        enabled: true,
        text: $scope.viewModel.newSentenceStarter
      });

      $scope.viewModel.newSentenceStarter = null;
    };

    $scope.onClassesDropdownClick = function (event) {
      if (!$scope.isClassesDropdownEnabled()) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        return false;
      }
    };

    var oldSave = $scope.saveSettings;

    $scope.saveSettings = function () {
      $scope.settings.notAllowedForClasses = $scope.viewModel.classes.filter(function (cls) {
        return cls.selected;
      }).map(function (cls) {
        return cls.id;
      });

      $scope.settings.sentenceStarters = $scope.viewModel.sentenceStarters.map(function (starter) {
        var defaultStarter = $scope.defaultAnnotationSentenceStarters.find(function (item) {
          return $translate.instant(item.text) === starter.text;
        });

        if (defaultStarter) {
          starter = {
            enabled: starter.enabled,
            text: defaultStarter.text // use translation id
          };
        }

        return starter;
      });

      oldSave();
    };
  };

  return {
    restrict: 'E',
    template: tpl,
    link: link,
    scope: CueThinkSettingsDirective.createSettingsScope({
      defaultAnnotationSentenceStarters: '=',
      loadClasses: '='
    })
  };
}

module.directive('annotationSettings', AnnotationSettingsDirective);
