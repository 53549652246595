import CueThinkConfig from 'config';

var properties = {
  name: 'CueThink',
  version: '2.0.60.5',
  annotation: [
    'GREAT_TIP',
    'GOOD_GUESS_AND_CHECK',
    'GOOD_WORK_BACKWARDS',
    'GOOD_WRITE_EQUATION',
    'CAN_YOU_CLARIFY',
    'MORE_DETAILS_PLEASE',
    'SWEET',
    'THAT_IS_COOL',
    'THAT_IS_WICKED_COOL',
    'GOOD_USE_OF_VISUALS',
    'I_WOULD_USE_THIS_METHOD',
    'I_LIKE_YOUR_EXPLANATION'
  ],
  communityLogInPath: '/login',
  communityAuthenticatePath: '/session/sso_login',
  rosterTemplateURL: '/roster.csv',

  addURL: function (key, value, hostName) {
    if (hostName) {
      this[key + 'URL'] = hostName + value;
    } else if (CueThinkConfig.hostName) {
      hostName = CueThinkConfig.hostName;

      this[key + 'URL'] = this.prefix.replace('{0}', hostName) + this.pathPrefix + value;
    } else {
      this[key + 'URL'] = this.pathPrefix + value;
    }
  }
};

var keys = Object.keys(properties);
for (var i = 0; i < keys.length; i++)
  CueThinkConfig[keys[i]] = properties[keys[i]];

CueThinkConfig.addURL('incrementViews', '/thinklet/{0}/video/views');
CueThinkConfig.addURL('userThinklets', '/dashboard/student/user/{0}/thinklets');
CueThinkConfig.addURL('userThinkletsAll', '/user/{0}/thinklets');
CueThinkConfig.addURL('studentsThinklets', '/dashboard/teacher/thinkletsdatalite');
CueThinkConfig.addURL('saveThinklet', '/thinklet/{0}/data');
CueThinkConfig.addURL('likeThinklet', '/thinklet/{0}/like');
CueThinkConfig.addURL('missionProblems', '/problems/mission');
CueThinkConfig.addURL('appraise', '/thinklet/{0}/appraisal');
CueThinkConfig.addURL('thinklet', '/thinklet/{0}');
CueThinkConfig.addURL('problem', '/problemv2/{0}');
CueThinkConfig.addURL('saveProblem', '/problemv2');
CueThinkConfig.addURL('updateProblem', '/problemv2/{0}');
CueThinkConfig.addURL('stateProblem', '/problemv2/{0}/{1}');
CueThinkConfig.addURL('user', '/user/{0}');
CueThinkConfig.addURL('news', '/news');
CueThinkConfig.addURL('sources', '/questionSources');
CueThinkConfig.addURL('grades', '/grades');
CueThinkConfig.addURL('searchTags', '/tags/search/{0}');
CueThinkConfig.addURL('createThinklet', '/problem/{0}/thinklets');
CueThinkConfig.addURL('createAssignment', '/assignmentsv2');
CueThinkConfig.addURL('editAssignment', '/assignmentv2/{0}');
CueThinkConfig.addURL('problemStatistics', '/problem/{0}/statistics');
CueThinkConfig.addURL('problemStandardTypeStatistics', '/problem/statistics/{0}/{1}');
CueThinkConfig.addURL('cuethinkProblems', '/data/problems');
CueThinkConfig.addURL('teacherProblems', '/dashboard/teacher/problems');
CueThinkConfig.addURL('teacherProblemsId', '/dashboard/teacher/problems?createBy={0}');
CueThinkConfig.addURL('guides', '/problem/{0}/supports/3');
CueThinkConfig.addURL('currentUser', '/user/current');
CueThinkConfig.addURL('objectives', '/objectives');
CueThinkConfig.addURL('defaultStrategies', '/strategies');
CueThinkConfig.addURL('strategies', '/problem/{0}/strategies');
CueThinkConfig.addURL('unlikeThinklet', '/thinklet/{0}/unlike');
CueThinkConfig.addURL('logInForm', '/login');
CueThinkConfig.addURL('similar', '/thinklet/{0}/similar');
CueThinkConfig.addURL('publish', '/thinklet/{0}/publish');
CueThinkConfig.addURL('unflagThinklet', '/thinklet/{0}/unflag');
CueThinkConfig.addURL('logIn', '/auth/login');
CueThinkConfig.addURL('logOut', '/auth/logout');
CueThinkConfig.addURL('topics', '/topics');
CueThinkConfig.addURL('objectives', '/objectives');
CueThinkConfig.addURL('problems', '/problems');
CueThinkConfig.addURL('problemsLite', '/problems/lite');
CueThinkConfig.addURL('origoProblems', '/origoProblems/lite');
CueThinkConfig.addURL('flagThinklet', '/thinklet/{0}/flag');
CueThinkConfig.addURL('tregister', '/tregister');
CueThinkConfig.addURL('register', '/register');
CueThinkConfig.addURL('forgotPassword', '/passwordrecovery/emailonly');
CueThinkConfig.addURL('recentThinklets', '/thinklets/recentlite?start={0}&limit={1}');
CueThinkConfig.addURL('currentThinklet', '/thinklet/current');
CueThinkConfig.addURL('galleryFilterList', '/thinklets/recent/problemlist');
CueThinkConfig.addURL('video', '/thinklet/{0}/video');
CueThinkConfig.addURL('addAnnotation', '/thinklet/{0}/annotations');
CueThinkConfig.addURL('comments', '/thinklet/{0}/comments');
CueThinkConfig.addURL('checklists', '/thinklet/{0}/checklists');
CueThinkConfig.addURL('comment', '/comment/{0}');
CueThinkConfig.addURL('annotation', '/annotation/{0}');
CueThinkConfig.addURL('authStatus', '/auth/status');
CueThinkConfig.addURL('assignments', '/dashboard/student/assignmentsv2');
CueThinkConfig.addURL('assignment', '/assignmentv2/{0}');
CueThinkConfig.addURL('assignmentProblems', '/assignment/{0}/problems');
CueThinkConfig.addURL('closeSelectedAssignments', '/assignment/{0}/status/completed');
CueThinkConfig.addURL('openSelectedAssignments', '/assignment/{0}/status/current');
CueThinkConfig.addURL('assignmentClasses', '/class/{0}');
CueThinkConfig.addURL('groupUsers', '/group/{0}/students');
CueThinkConfig.addURL('notifications', '/dashboard/student/notifications');
CueThinkConfig.addURL('checkTeacherUserName', '/username/teacher/{0}/available');
CueThinkConfig.addURL('checkEmail', '/email/{0}/available');
CueThinkConfig.addURL('schools', '/schools');
CueThinkConfig.addURL('teacherRegistration', '/pregister');
CueThinkConfig.addURL('createClass', '/dashboard/teacher/classes');
CueThinkConfig.addURL('coteachClass', '/dashboard/teacher/class/coteaching');
CueThinkConfig.addURL('classes', '/dashboard/teacher/classes');
CueThinkConfig.addURL('loadClass', '/dashboard/teacher/classes/{0}');
CueThinkConfig.addURL('validateInvitationCode', '/class/invitationcode/{0}/valid');
CueThinkConfig.addURL('changedThinklets', '/thinklets/recent');
CueThinkConfig.addURL('flagAnnotation', '/annotation/{0}/flag');
CueThinkConfig.addURL('unflagAnnotation', '/annotation/{0}/unflag');
CueThinkConfig.addURL('likeAnnotation', '/annotation/{0}/like');
CueThinkConfig.addURL('unlikeAnnotation', '/annotation/{0}/unlike');
CueThinkConfig.addURL('saveAnnotation', '/annotation/{0}');
CueThinkConfig.addURL('teacherSettings', '/dashboard/teacher/settings');
CueThinkConfig.addURL('globalSettings', '/globalsettings'); // TODO
CueThinkConfig.addURL('changeStudentPassword', '/dashboard/teacher/changestudentpassword');
CueThinkConfig.addURL('changePassword', '/dashboard/teacher/settings/resetpassword');
CueThinkConfig.addURL('changeUserPassword', '/user/settings/changepassword');
CueThinkConfig.addURL('assignmentExists', '/thinklet/{0}/assignmentexist');
CueThinkConfig.addURL('teacherClasses', '/dashboard/teacher/classes');
CueThinkConfig.addURL('students', '/dashboard/teacher/studentslite');
CueThinkConfig.addURL('studentsData', '/dashboard/teacher/students/viewdata');
CueThinkConfig.addURL('editStudent', '/dashboard/teacher/student/settings');
CueThinkConfig.addURL('moveStudent', '/class/{0}/movestudents');
CueThinkConfig.addURL('deleteStudent', '/dashboard/teacher/student/{0}/leaveclass');
CueThinkConfig.addURL('classRoster', '/dashboard/teacher/class/{0}/students');
CueThinkConfig.addURL('ccssGrades', '/ccss/grades?rdm=' + Math.random());
CueThinkConfig.addURL('ccssCategory', '/ccss/grade/{grade}/categories?rdm=' + Math.random());
CueThinkConfig.addURL('ccssSubCategory', '/ccss/category/{category}?rdm=' + Math.random());
CueThinkConfig.addURL('ccssId', '/ccss/id/{id}?rdm=' + Math.random());
CueThinkConfig.addURL('uploadVideo', '/multimedia/playback/upload', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('uploadAudio', '/multimedia/upload', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('uploadScrapPaper', '/multimedia/upload', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('deleteThinklet', '/thinklet/{0}');
CueThinkConfig.addURL('uploadThumbnail', '/multimedia/thinklet/{0}/thumbnail', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('uploadProblemImage', '/multimedia/user/{0}/problem/image', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('deleteProblemImage', '/multimedia/problem/{0}/image', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('unpublishThinklet', '/thinklet/{0}/unpublish');
CueThinkConfig.addURL('forgotUserPassword', '/passwordrecovery/emailonly');
CueThinkConfig.addURL('authStatus', '/auth/status');
CueThinkConfig.addURL('uploadImage', '/multimedia/thinklet/{0}/wbimage', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('uploadScreenshot', '/multimedia/thinklet/{0}/{1}', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('uploadScreenshotIndex', '/multimedia/thinklet/{0}/{1}/{2}', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('deleteThinkletAudio', '/multimedia/thinklet/{0}/audio', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('deleteCurrentUser', '/dashboard/teacher/deleteall');
CueThinkConfig.addURL('getTrialDaysLeft', '/dashboard/teacher/daysleft');
CueThinkConfig.addURL('oAuthLogIn', '/auth/oauth/login');
CueThinkConfig.addURL('uploadWhiteboard', '/multimedia/thinklet/{0}/staticdata', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('resetPasswordByUsername', '/passwordrecovery/usernameonly');
CueThinkConfig.addURL('cleverAuthenticate', '/auth/clever/login');
CueThinkConfig.addURL('createShareCode', '/thinklet/{0}/createsharecode');
CueThinkConfig.addURL('playShared', '/video/shared/{0}');
CueThinkConfig.addURL('logEvent', '/logevent');
CueThinkConfig.addURL('logPLEvent', '/logplevent');
CueThinkConfig.addURL('logBehavior', '/log/user/behavior');
CueThinkConfig.addURL('logPageEvent', '/log/user/page');
CueThinkConfig.addURL('logThinkletEvent', '/log/user/thinklet');
CueThinkConfig.addURL('rubric', '/cueteach/thinklet/{0}/rubric');
CueThinkConfig.addURL('rubricStats', '/cueteach/problem/rubric');
CueThinkConfig.addURL('tags', '/cueteach/thinklets/taglist');
CueThinkConfig.addURL('tagsAndStrategies', '/cueteach/thinklets/recent/taglist');
CueThinkConfig.addURL('renderMathML', '/multimedia/thinklet/{0}/wbimage/mathml', CueThinkConfig.uploadURL);
CueThinkConfig.addURL('saveTags', '/cueteach/thinklet/{0}/tags');
CueThinkConfig.addURL('reflectionStrategies', '/cueteach/reflection/strategies');
CueThinkConfig.addURL('saveReflection', '/cueteach/reflections');
CueThinkConfig.addURL('loadReflections', '/cueteach/assignment/{0}/reflections');
CueThinkConfig.addURL('loadProblemReflections', '/cueteach/problem/{0}/reflections/shared');
CueThinkConfig.addURL('openAssignments', '/dashboard/teacher/assignmentsv2/current/lite');
CueThinkConfig.addURL('closedAssignments', '/dashboard/teacher/assignmentsv2/completed/lite');
CueThinkConfig.addURL('assignmentsStudents', '/dashboard/teacher/assignmentsv2/current/students/lite');
CueThinkConfig.addURL('deleteAssignment', '/assignmentv2/{0}');
CueThinkConfig.addURL('newNotifications', '/dashboard/student/notifications/new');
CueThinkConfig.addURL('deleteNotification', '/notification/{0}/old');
//CueThinkConfig.addURL('deleteOldNotification', '/notification/{0}/old');
CueThinkConfig.addURL('deleteClass', '/class/{0}');
CueThinkConfig.addURL('deleteClassStudents', '/class/{0}/deleteall');
CueThinkConfig.addURL('archiveClass', '/class/{0}/archive');
CueThinkConfig.addURL('reactivateClass', '/class/{0}/reactivate');
CueThinkConfig.addURL('archivedClasses', '/dashboard/teacher/archivedclasses');
CueThinkConfig.addURL('thinkletVersion', '/thinklet/{0}/newversion');
CueThinkConfig.addURL('flag', '/user/current/flag?flag={0}');
CueThinkConfig.addURL('thinkletVersions', '/thinklet/{0}/versioninfo');
CueThinkConfig.addURL('changeClass', '/dashboard/student/changeclass');
CueThinkConfig.addURL('getUserTrialInfo', '/dashboard/teacher/trialinfo');
CueThinkConfig.addURL('userFlag', '/user/current/flag');
CueThinkConfig.addURL('joinClass', '/dashboard/student/joinclass');
CueThinkConfig.addURL('leaveClass', '/dashboard/student/leaveclass');
CueThinkConfig.addURL('selectClass', '/dashboard/student/selectclass');
CueThinkConfig.addURL('selectedClass', '/dashboard/student/selectedclass');
CueThinkConfig.addURL('classAssignments', '/dashboard/student/assignmentsv2');
CueThinkConfig.addURL('saveFlag', '/user/{0}/flag');
CueThinkConfig.addURL('maintenanceMessage', '/systemmessage/maintenance');
CueThinkConfig.addURL('addAnnotationReply', '/annotation/{0}/replies');
CueThinkConfig.addURL('deleteAnnotationReply', '/annotationreply/{0}');
CueThinkConfig.addURL('editAnnotationReply', '/annotationreply/{0}');
CueThinkConfig.addURL('flagAnnotationReply', '/annotationreply/{0}/flag');
CueThinkConfig.addURL('unflagAnnotationReply', '/annotationreply/{0}/unflag');
CueThinkConfig.addURL('likeAnnotationReply', '/annotationreply/{0}/like');
CueThinkConfig.addURL('unlikeAnnotationReply', '/annotationreply/{0}/unlike');
CueThinkConfig.addURL('resetPassword', '/passwordrecovery/code');
CueThinkConfig.addURL('expirePassword', '/passwordrecovery/student/{0}');
CueThinkConfig.addURL('forgotUserName', '/retrieveusername');
CueThinkConfig.addURL('problemFolders', '/dashboard/teacher/problemfolder');
CueThinkConfig.addURL('starterAssignments', '/dashboard/teacher/starterassignments');
CueThinkConfig.addURL('copyExemplarThinklet', '/thinklet/copyexemplar');
CueThinkConfig.addURL('resetRubric', '/cueteach/thinklet/{0}/rubric');
CueThinkConfig.addURL('rubricNotes', '/cueteach/thinklet/{0}/rubric');
CueThinkConfig.addURL('coteachingAssignments', '/dashboard/teacher/assignmentsv2/list');
CueThinkConfig.addURL('labels', '/cueteach/labels');
CueThinkConfig.addURL('relatedLabels', '/cueteach/problem/{0}/labels');
CueThinkConfig.addURL('addLabel', '/cueteach/thinklet/{0}/label');
CueThinkConfig.addURL('removeLabel', '/cueteach/thinklet/{0}/label/{1}');
CueThinkConfig.addURL('saveLabel', '/cueteach/label/{0}');
CueThinkConfig.addURL('deleteLabel', '/cueteach/label/{0}');
CueThinkConfig.addURL('folderProblems', '/problemIDs');
CueThinkConfig.addURL('sharedProblemUsers', '/dashboard/teacher/problem/{0}/share');
CueThinkConfig.addURL('shareProblem', '/dashboard/teacher/problem/{0}/share');
CueThinkConfig.addURL('recentSharedProblemUsers', '/dashboard/teacher/recentlyShared');
CueThinkConfig.addURL('discourseAuthenticate', '/auth/sso');
CueThinkConfig.addURL('learningHubUnits', '/learning_hub/units');
CueThinkConfig.addURL('saveLearningHubUnit', '/learning_hub/status');
CueThinkConfig.addURL('updateLearningHubUnit', '/learning_hub/status/{0}');
CueThinkConfig.addURL('learningHubUnitsStatus', '/learning_hub/users/{0}/status');
CueThinkConfig.addURL('learningHubUnitStatus', '/learning_hub/status/{0}');
CueThinkConfig.addURL('nearbySchools', '/greatschool/byzipandstate');
CueThinkConfig.addURL('joinedClasses', '/joined/classes/current');
CueThinkConfig.addURL('importRoster', '/import/classuser/{0}');
CueThinkConfig.addURL('removeStudent', '/remove/allTeacherClasses/{0}');
CueThinkConfig.addURL('leaveCoteachClass', '/coteacher/leave/{0}');
CueThinkConfig.addURL('expireStudentPassword', '/user/expired/{0}');
CueThinkConfig.addURL('editClass', '/class/update/{0}');
CueThinkConfig.addURL('importRosterError', '/import/classuser/errorcsv');
CueThinkConfig.addURL('allTeacherProblems', '/data/problems');
CueThinkConfig.addURL('shareRubric', '/thinklet/{0}/rubricsharewithstudent/{1}');
CueThinkConfig.addURL('loadRubrics', '/dashboard/students/thinkletsdata');
CueThinkConfig.addURL('saveStudentReflection', '/thinklet/{0}/studentreflection');
CueThinkConfig.addURL('standardTypes', '/standardTypes');
CueThinkConfig.addURL('standardTypeDomains', '/standard/domain/{0}/{1}');
CueThinkConfig.addURL('standardTypeStandards', '/standards/{0}/{1}/{2}/');
CueThinkConfig.addURL('assignmentStats', '/dashboard/teacher/assignmentsv2/current/{0}/{1}');
CueThinkConfig.addURL('foldersForProblem', '/problemfolder/{0}');
CueThinkConfig.addURL('filterProblems', '/teacher/assignment/problems');
CueThinkConfig.addURL('hasIncompleteReflections', '/teacher/assignment/hasReflections');
CueThinkConfig.addURL('registerGoogleClassroomStudents', '/gclassregister');
CueThinkConfig.addURL('imProblems', '/imProblems/lite');
CueThinkConfig.addURL('isEFAccount', '/dashboard/teacher/isefaccount');
CueThinkConfig.addURL('isEFLiteAccount', '/dashboard/teacher/isefliteaccount');
CueThinkConfig.addURL('isLZAccount', '/dashboard/teacher/islzaccount');
CueThinkConfig.addURL('isOrigoAccount', '/dashboard/teacher/isorigoaccount');
CueThinkConfig.addURL('isStudentEFAccount', '/dashboard/student/isefaccount');
CueThinkConfig.addURL('cleverPresence', '/oauth/authenticated', 'https://clever.com');
CueThinkConfig.addURL('submitBeliefsProbe', '/ef/probe/event');
CueThinkConfig.addURL('submitMindWanderingProbe', '/ef/probe/event');
CueThinkConfig.addURL('createAssignmentFromProblem', '/assignmentv2/student/problem/{problemId}');
CueThinkConfig.addURL('learnZillionProblems', '/lzProblems/lite');
CueThinkConfig.addURL('facilitatorGuide', '/assignmentv2/{0}/notes/{1}')
CueThinkConfig.addURL('saveFacilitatorGuide', '/assignmentv2/note');
CueThinkConfig.addURL('generalFacilitatorGuide', '/teacher/note/{0}');
CueThinkConfig.addURL('saveGeneralFacilitatorGuide', '/teacher/note/{0}');
CueThinkConfig.addURL('tokenAuth', '/auth/token/exchange');
CueThinkConfig.addURL('classStudents', '/dashboard/student/class/{0}/studentlist');
CueThinkConfig.addURL('saveDigitalPromiseProbe', '/dp/probe/event');
CueThinkConfig.addURL('isDPStudentAccount', '/dashboard/student/isdpaccount');
CueThinkConfig.addURL('isDPTeacherAccount', '/dashboard/teacher/isdpaccount');
CueThinkConfig.addURL('chatUsers', '/assignmentv2/chatroom/{0}/participants');

var domainName = window.location.href;
length = domainName.indexOf('//');
length = domainName.indexOf('/', length + 2);
domainName = domainName.substr(0, length);

CueThinkConfig.addURL('cleverRedirect', '/clevercallback', domainName);

(function () {
  var user = {
    username: 'jw74',
    firstName: 'Jane',
    lastName: 'Smith',
    first_name: 'Jane',
    last_name: 'Smith'
  };

  CueThinkConfig.tutorialSteps = [
    {
      state: 'dashboard',
      params: {overdue: 'false'},
      content: 'WELCOME_TO_CUETHINK_LETS_GET_STARTED',
      placement: 'center'
    },
    {
      state: 'dashboard',
      selector: '.nav-buttons',
      content: 'THESE_BUTTONS_HELP_YOU_NAVIGATE',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '#dashboard-button',
      content: 'THE_HOME_BUTTON_IS_YOUR_DASHBOARD',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '#profile-button',
      content: 'THE_PROFILE_BUTTON_SHOWS',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '#browse-button',
      content: 'THE_GALLERY_BUTTON_SHOWS',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '#assignment-table',
      content: 'THIS_AREA_SHOWS_YOU_ALL',
      placement: 'right'
    },
    {
      state: 'dashboard',
      selector: '.due-header',
      content: 'THIS_IS_THE_DAY',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '.title-header',
      content: 'THIS_IS_THE_TITLE_OF_THE_PROBLEM',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '.versions-header',
      content: 'THIS_SHOWS_YOU_HOW_MANY_REVISIONS',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '.status-header',
      content: 'THE_STATUS_OF_A_THINKLET',
      placement: 'bottom'
    },
    {
      state: 'dashboard',
      selector: '.view-overdue-problems',
      content: 'OVERDUE_PROBLEMS_ARE_NO_LONGER',
      placement: 'top'
    },
    {
      state: 'dashboard',
      content: 'LETS_STEP_THROUGH',
      placement: 'center'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#understand-button',
      content: 'UNDERSTAND_IS_WHERE',
      placement: 'bottom',
      data: {
        thinklet: {
          id: -1,
          title: 'CHARLIES_GUMBALLS_SCENARIO',
          user: user,
          views: 4,
          timestamp: '',
          problem: {
            question: 'SCENARIO_CHARLIE'
          },
          annotations: [
            {
              text: 'THIS_IS_A_GREAT_STRATEGY',
              user: user,
              time: 2
            }
          ],
          complexItems: {
            strategies: [
              {name: 'USE_AN_OPEN_NUMBER_LINE', status: 'true'},
              {name: 'DRAW_A_PICTURE'},
              {name: 'MAKE_A_TABLE'},
              {name: 'TRY_WITH_DIFFERENT_NUMBERS'},
              {name: 'WORK_BACKWARDS', status: 'true'},
              {name: 'GUESS_CHECK_AND_REVISE'},
              {name: 'MODEL_IT_WITH_MANIPULATIVES'},
              {name: 'LOOK_FOR_A_PATTERN'},
              {name: 'MODEL_WITH_AN_EQUATION'}
            ],
            math_checklist: [
              {name: 'DOES_MY_ANSWER_MAKE_SENSE', status: 'true'},
              {name: 'DID_I_INCLUDE_UNITS', status: 'true'},
              {name: 'DID_I_CHECK_MY_WORK', status: 'true'},
              {name: 'COULD_SOMEONE_SEE_HOW_I_FOUND_MY_ANSWER', status: 'true'},
              {name: 'DID_I_SHOW_HOW_THE_WORK', status: 'true'}
            ],
            recording_checklist: [
              {name: 'MY_VOICE_IS_LOUD_AND_CLEAR', status: 'true'},
              {name: 'MY_WRITING_IS_CLEAR', status: 'true'},
              {name: 'THERE_IS_NO_DISTRACTING_BACKGROUND_NOISE', status: 'true'},
              {name: 'I_USED_THE_HIGHLIGHTER', status: 'true'}
            ]
          },
          items: {
            known: [
              'GIANT_BAG_OF_GUMBALLS',
              'GIVES_HALF_OF_WHAT_HE_HAS',
              'GIVES_HALF_OF_WHATS_LEFT_AFTER',
              'GIVES_HALF_OF_WHATS_LEFT_NOW',
              'GIVES_5_GUMBALLS',
              'HE_HAS_10_GUMBALLS_LEFT'
            ],
            wondering: [
              'I_WONDER_IF_HE_STARTED'
            ]
          },
          understand: 'MORE_THAN_100',
          plan: 'DRAW_AN_OPEN_NUMBER_LINE',
          answer: {answer: '120_GUMBALLS'}
        }
      }
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#understand .highlighter',
      content: 'USE_THE_HIGHLIGHTER_TO_HIGHLIGHT',
      placement: 'bottom'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#understand .strikethrough',
      content: 'USE_THE_STRIKEOUT_TOOL',
      placement: 'bottom'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#understand .eraser',
      content: 'USE_THE_ERASER',
      placement: 'bottom'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#understand .add-words',
      content: 'SELECT_THE_ADD_BUTTON',
      placement: 'bottom'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#notice',
      content: 'WHAT_DO_YOU_NOTICE_IS_WHERE',
      placement: 'top'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#wonder',
      content: 'WHAT_DO_YOU_WONDER_ABOUT_IS_WHERE',
      placement: 'top'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#estimate',
      content: 'ESTIMATE_YOUR_ANSWER_IS_WHERE',
      placement: 'top'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#scrap-paper',
      content: 'USE_THE_SCRAP_PAPER',
      placement: 'left'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#create-navbar',
      content: 'NAVIGATE_THROUGH_THE_FOUR_STEPS',
      placement: 'bottom'
    },
    {
      state: 'understand',
      params: {id: -1},
      selector: '#plan-button',
      content: 'PLAN_IS_WHERE',
      placement: 'bottom'
    },
    {
      state: 'plan',
      params: {id: -1},
      selector: '#strategies',
      content: 'WEVE_GIVEN_YOU_SOME_POSSIBLE_WAYS',
      placement: 'top'
    },
    {
      state: 'plan',
      params: {id: -1},
      selector: '.writePlanContainer',
      content: 'WRITE_A_DETAILED_PLAN_ABOUT',
      placement: 'top'
    },
    {
      state: 'plan',
      params: {id: -1},
      selector: '#create-navbar',
      content: 'YOU_CAN_USE_THE_NAVIGATION_BAR',
      placement: 'bottom'
    },
    {
      state: 'solve',
      params: {id: -1},
      selector: '#solve-button',
      content: 'NOW_ITS_TIME_TO_SHOW',
      placement: 'bottom',
      images: {
        '#solve .whiteboard:first': '/images/sample_whiteboard.png'
      }
    },
    {
      state: 'solve',
      params: {id: -1},
      selector: 'whiteboard',
      content: 'USE_THE_SPACE_PROVIDED',
      placement: 'top'
    },
    {
      state: 'solve',
      params: {id: -1},
      selector: 'whiteboard .next',
      content: 'IF_YOU_NEED_MORE_SPACE',
      placement: 'top'
    },
    {
      state: 'solve',
      params: {id: -1},
      selector: 'whiteboard .record',
      content: 'WHEN_YOURE_READY_TO_START',
      placement: 'top'
    },
    {
      state: 'solve',
      params: {id: -1},
      selector: 'whiteboard .stop',
      content: 'ONCE_YOURE_DONE',
      placement: 'top'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: '#review-button',
      content: 'IN_THE_REVIEW_SCREEN',
      placement: 'bottom',
      images: {
        '#review whiteboardplayer:first': '/images/sample_player.png'
      },
      hide: '.whiteboard-player .missing'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: '#checklists',
      content: 'USE_THE_CHECKLISTS_PROVIDED',
      placement: 'top'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: 'whiteboardplayer',
      content: 'WATCH_THE_THINKLET_YOU_RECORDED',
      placement: 'top'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: '#review-estimate',
      content: 'FINALLY_COMPARE_YOUR_FINAL_ANSWER',
      placement: 'top'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: '.answer-container',
      content: 'ENTER_YOUR_FINAL_ANSWER',
      placement: 'top'
    },
    {
      state: 'review',
      params: {id: -1},
      selector: '#profile-button',
      content: 'NOW_LETS_GO_TO_YOUR_PROFILE',
      placement: 'bottom'
    },
    {
      state: 'profile',
      content: 'THIS_IS_YOUR_PROFILE',
      placement: 'center',
      data: {
        thinklets: [
          {
            title: 'HANDSHAKE_PROBLEM_ELEMENTARY',
            user: user,
            views: 1,
            isComplete: true,
            thumbnailURL: '/images/sample_whiteboard.png'
          }
        ]
      }
    },
    {
      state: 'profile',
      selector: 'thinklet:first .thinklet-dropdown-menu:first',
      content: 'CLICK_ON_THE_THREE_DOTS_TO_OPEN_THE_MENU_IF_YOU_CHOOSE_TO_EDIT_YOU_WILL_GO_TO_THE__PHASES_FOR_THAT_THINKLET_SO_YOU_CAN_MAKE_CHANGES_WHEN_YOU_SUBMIT_MULTIPLE_VERSIONS_OF_A_THINKLET_SOMEONE_VIEWING_THAT_THINKLET_WILL_SEE_CIRCLES_THAT_SAY_V__V_FOR_EACH_VERSION',
      placement: 'bottom',
      show: '#completed-thinklets thinklet:first .thinklet-dropdown-menu'
    },
    {
      state: 'profile',
      selector: '#browse-button',
      content: 'NOW_LET_S_GO_TO_THE_GALLERY_WHERE_YOU_CAN_SEE_ALL_THE_THINKLETS_THAT_HAVE_BEEN_SHARED_BY_YOUR_CLASS',
      placement: 'bottom'
    },
    {
      state: 'browse',
      selector: 'thinklet:first',
      content: 'IN_THE_GALLERY_ARE_ALL_OF_THE_THINKLETS_THAT_HAVE_BEEN_SHARED_WITH_YOU_CLICK_ON_A_THINKLET_TO_VIEW_IT',
      placement: 'bottom',
      data: {
        thinklets: [
          {
            title: 'HANDSHAKE_PROBLEM_ELEMENTARY',
            user: user,
            views: 1,
            thumbnailURL: '/images/sample_whiteboard.png'
          }
        ]
      }
    },
    {
      state: 'view',
      params: { id: -1 },
      content: 'HERE_YOU_CAN_VIEW_AND_ANNOTATE_THE_THINKLET',
      placement: 'center',
      data: {
        thinklet: {
          id: -1,
          title: 'CHARLIES_GUMBALLS_SCENARIO',
          user: user,
          views: 4,
          timestamp: '',
          problem: {
            question: 'SCENARIO_CHARLIE'
          },
          annotations: [
            {
              text: 'THIS_IS_A_GREAT_STRATEGY_BECAUSE_YOU_SHOW_HOW_MANY_GUM_BALLS_EACH_PERSON_GETS',
              user: user,
              time: 0
            }
          ]
        }
      },
      images: {
        'whiteboardplayer:first': '/images/sample_player.png'
      },
      hide: '.whiteboard-player .missing'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '#add-annotation',
      content: 'WHILE_WATCHING_THE_THINKLET_PAY_ATTENTION_TO_HOW_THE_CREATOR_SOLVED_THE_PROBLEM_TO_MAKE_A_COMMENT_CLICK_THE__ANNOTATION_BUTTON',
      placement: 'bottom'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '.new-annotation',
      content: 'YOU_CAN_MOVE_THE_ANNOTATION_BOX_TO_APPEAR_NEXT_TO_WHAT_YOU_ARE_COMMENTING_ON_YOUR_ANNOTATION_IS_LINKED_TO_THE_SPECIFIC_TIME_IN_THE_VIDEO',
      placement: 'top',
      click: '#add-annotation'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '.video-annotation:first .buttons:first',
      content: 'ONCE_YOU_VE_MADE_AN_ANNOTATION_PRESS_THE_THREE_DOTS_NEXT_TO_THE_ANNOTATION_TO_EDIT_OR_DELETE_THE_ANNOTATION_YOU_CAN_ALSO_VIEW_AND_FLAG_YOUR_CLASSMATES_ANNOTATIONS_ON_THE_SIDE_SCREEN_THE_CREATOR_OF_AN_ANNOTATION_WILL_RECEIVE_A_NOTIFICATION_ON_THEIR_HOMEPAGE_IF_THEIR_ANNOTATION_IS_FLAGGED',
      placement: 'left',
      click: 'newannotation .cancel'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '.bottomContent',
      content: 'VIEW_MORE_PROBLEM_DETAILS_BELOW_THE_VIDEO_RECORDING',
      placement: 'top'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '#show-details',
      content: 'OPEN_THE_DRAWER_HERE_TO_SEE_NOTICINGS_WONDERINGS_STRATEGIES_AND_WRITTEN_PLAN_FOR_THE_THINKLET_YOU_ARE_VIEWING',
      placement: 'left'
    },
    {
      state: 'view',
      params: {id: -1},
      selector: '.user-menu',
      content: 'THAT_S_IT_TAP_THE_AVATAR_IN_THE_CORNER_TO_GO_THROUGH_THIS_FULL_TUTORIAL_AGAIN',
      placement: 'bottom'
    }
  ];

  CueThinkConfig.teacherTutorialSteps = [
    {
      state: 'view-all-problems',
      selector: '.assignments-nav-button',
      content: 'H_WANT_TO_SELECT_A_PROBLEM_TO_ASSIGN__H__P_IN_ASSIGNMENTS_GT_PROBLEM_BANK_SELECT_A_PROBLEM_TO_VIEW_THE_STANDARDS_QUESTION_AND_ANSWER__P',
      placement: 'bottom'
    },
    {
      state: 'view-all-problems',
      selector: '.details .buttons',
      content: 'H_WHAT_CAN_BE_DONE_TO_A_PROBLEM__H__P_CHOOSE_TO_CLONE_EDIT_OR_ASSIGN_THE_PROBLEM_PROBLEMS_CAN_ALSO_BE_SAVED_TO_FOLDERS_FOR_LATER_USE__P',
      placement: 'left'
    },
    {
      state: 'create-new-problem',
      selector: '.assignments-nav-button',
      content: 'H_WANT_TO_CREATE_YOUR_OWN_PROBLEM__H__P_IN_ASSIGNMENTS_GT_NEW_PROBLEM_CREATE_AN_ORIGINAL_PROBLEM_OR_ENTER_ONE_FROM_YOUR_CURRICULUM__P',
      placement: 'bottom'
    },
    {
      state: 'view-all-assignments',
      selector: '.assignments-nav-button',
      content: 'H_WANT_TO_SEE_PROBLEMS_YOU_ASSIGNED__H__P_IN_ASSIGNMENTS_GT_ASSIGNED_WORK_VIEW_PROBLEMS_YOUR_CLASSES_ARE_SOLVING_SELECT_THE_CLASS_NAME_TO_VIEW_STUDENTS_STATUS_AND_SELECT_THE_THREE_DOTS_TO_EDIT_THE_PROBLEM_OR_DUE_DATE__P',
      placement: 'bottom'
    },
    {
      state: 'classes',
      selector: '.mode-btn',
      content: 'H_WANT_TO_SEE_STUDENTS_PROGRESS_ON_THEIR_WORK__H__P_IN_REPORTS_GT_CLASS_PROGRESS_SWITCH_BETWEEN_DATA_OVERVIEW_AND_DATA_ANSWERS_TO_REVIEW_STUDENTS_ANSWERS_AND_VIDEO_VIGNETTES_CALLED_I_THINKLETS_I__P',
      placement: 'bottom'
    },
    {
      state: 'view-all-students',
      selector: '.roster-nav-button',
      content: 'H_WANT_TO_SEE_A_LIST_OF_YOUR_STUDENTS_AND_CLASSES__H__P_IN_ROSTER_GT_CLASSES_YOU_CAN__P_UL_LI_CREATE_A_NEW_CLASS_LI_LI_JOIN_ANOTHER_TEACHER_S_CLASS_LI_LI_MANAGE_YOUR_ROSTER_AND_FIND_INVITATION_CODES_FOR_STUDENTS_TO_USE_WHEN_REGISTERING_THEIR_ACCOUNTS_LI__UL',
      placement: 'bottom'
    },
    {
      state: 'resource-bank',
      selector: '.support-nav-button',
      content: 'H_WANT_ADDITIONAL_RESOURCES_FOR_USING_CUETHINK__H__P_IN_SUPPORT_GT_RESOURCE_BANK_SEARCH_BY_TOPIC_AND_TYPE_FILTERS_TO_FIND_GUIDES_FOR_GETTING_STARTED_AND_INSTRUCTIONAL_BEST_PRACTICES__P',
      placement: 'bottom'
    },
    {
      state: 'learninghub',
      selector: '.support-nav-button',
      content: {
        default: 'H_WANT_ADDITIONAL_SUPPORT_USING_CUETHINK_WITH_YOUR_STUDENTS__H__P_IN_SUPPORT_GT_LEARNING_HUB_YOU_CAN__P_UL_LI_WATCH_SHORT_VIDEOS_AND_VIEW_RESOURCES_ON_TEACHING_STUDENTS_ABOUT_CUETHINK_LI_LI_TRY_SPECIFIC_ACTIVITIES_WITH_STUDENTS_LI_LI_REFLECT_ON_HOW_THE_LESSONS_WENT_LI__UL',
        newDesign: 'WANT_ADDITIONAL_SUPPORT_USING_CUETHINK_WITH_YOUR_STUDENTS_IN_SUPPORT_LEARNING_HUB_YOU_CAN_WATCH_SHORT_VIDEOS_AND_VIEW_RESOURCES_TO_GET_UP_AND_RUNNING_WITH_CUETHINK'
      },
      placement: 'bottom'
    },
    {
      state: 'learninghub',
      selector: '.user-menu li:first',
      content: 'H_WANT_TO_SEE_STUDENT_MODE__H__P_SWITCH_TO_STUDENT_MODE_TO_WORK_IN_THE_STUDENT_EXPERIENCE_ONCE_IN_STUDENT_MODE_SELECT_THE_PURPLE_QUADE_CHARACTER_IN_THE_UPPER_RIGHT_CORNER_TO_SWITCH_BACK_TO_EDUCATOR_MODE__P',
      placement: 'left',
      show: '.user-menu .dropdown-menu'
    }
  ];

  CueThinkConfig.toolbarTutorialSteps = [
    {
      state: 'view-teacherdash',
      params: { id: -1 },
      selector: '.annotations-button',
      content: 'H_WANT_TO_WRITE_AN_ANNOTATION__H__P_SELECT_THE_ANNOTATION_ICON_TO_VIEW_AND_WRITE_FEEDBACK_TEACHERS_CAN_WRITE_BOTH_PUBLIC_AND_PRIVATE_ANNOTATIONS_TO_HELP_STUDENTS_REFLECT_AND_IMPROVE_THEIR_WORK__P',
      placement: 'left',
      data: {
        thinklet: {
          id: -1,
          title: 'CHARLIES_GUMBALLS_SCENARIO',
          user: user,
          views: 4,
          timestamp: '',
          problem: {
            question: 'SCENARIO_CHARLIE'
          },
          "rubric": {
            "thinklet_id": 7855,
            "noticings": 0,
            "wonderings": 0,
            "understandList": [{
              "id": 7,
              "optionId": 2,
              "name": "IDENTIFIED_RELEVANT_DETAILS",
              "option": "PARTIALLY",
              "type": "understand",
              "note": null
            }, {
              "id": 8,
              "optionId": 1,
              "name": "IDENTIFIED_THE_QUESTION",
              "option": "YES",
              "type": "understand",
              "note": null
            }, {
              "id": 9,
              "optionId": 1,
              "name": "ESTIMATE_REASONABLE",
              "option": "YES",
              "type": "understand",
              "note": null
            }],
            "notes": [],
            "solveList": [{
              "id": 11,
              "optionId": 1,
              "name": "MATH_REASONING_CLEAR_EXPLANATION",
              "option": "YES",
              "type": "solve",
              "note": null
            }, {
              "id": 12,
              "optionId": null,
              "name": "SOLUTION_CORRECT",
              "option": null,
              "type": "solve",
              "note": null
            }, {
              "id": 13,
              "optionId": null,
              "name": "STRATEGY_USED_EFFECTIVELY",
              "option": null,
              "type": "solve",
              "note": null
            }],
            "reviewList": [{
              "id": 14,
              "optionId": 1,
              "name": "CHECKED_WORK",
              "option": "YES",
              "type": "review",
              "note": null
            }, {
              "id": 15,
              "optionId": null,
              "name": "RECORDING_CLEAR",
              "option": null,
              "type": "review",
              "note": null
            }],
            "planList": [{
              "id": 1,
              "optionId": 1,
              "name": "WRITTEN_PLAN_IS_CONSISTENT_WITH_SELECTED_STRATEGIES",
              "option": "YES",
              "type": "plan",
              "note": null
            }, {
              "id": 10,
              "optionId": null,
              "name": "WRITTEN_PLAN_CLEAR_EXPLANATION",
              "option": null,
              "type": "plan",
              "note": null
            }],
            "responseList": [{
              "id": 2,
              "optionId": null,
              "name": "MATH_REASONING_CLEAR_EXPLANATION",
              "option": null,
              "type": "response",
              "note": null
            }, {
              "id": 3,
              "optionId": null,
              "name": "PROCEDURE_MATHEMATICALLY_SOUND",
              "option": null,
              "type": "response",
              "note": null
            }, {
              "id": 4,
              "optionId": null,
              "name": "SOLUTION_CORRECT",
              "option": null,
              "type": "response",
              "note": null
            }, {
              "id": 5,
              "optionId": null,
              "name": "STRATEGY_USED_EFFECTIVELY",
              "option": null,
              "type": "response",
              "note": null
            }],
            "answerList": [{
              "id": 6,
              "optionId": null,
              "name": "CORRECT_SOLUTION",
              "option": null,
              "type": "answer",
              "note": null
            }]
          },
          labels: [
            {label: 'CLEAR_CALCULATION', private: false}
          ]
        }
      },
      images: {
        'whiteboardplayer:first': '/images/sample_player.png'
      },
      hide: '.whiteboard-player .missing'
    },
    {
      state: 'view-teacherdash',
      params: {id: -1},
      selector: '.rubric-button',
      content: 'H_WANT_TO_ASSESS_A_THINKLET__H__P_WHEN_YOU_RE_READY_TO_DEEPLY_ANALYZE_STUDENT_WORK_SELECT_THE_RUBRIC_ICON_TO_ACCESS_THE_RUBRIC_USING_THE_EMBEDDED_RUBRIC_HELPS_TEACHERS_BETTER_UNDERSTAND_STUDENTS__PROBLEM_SOLVING_PROCESS_AND_IDENTIFY_STRENGTHS_AND_MISCONCEPTIONS__P',
      placement: 'left',
      click: '.rubric-button'
    },
    {
      state: 'view-teacherdash',
      params: {id: -1},
      selector: '.labels-button',
      content: 'LABELS_TUTORIAL_TEXT',
      placement: 'left',
      click: '.labels-button'
    },
    {
      state: 'view-teacherdash',
      params: {id: -1},
      selector: '.add-new-tag input',
      content: 'ADD_NEW_TAG_TEXT',
      placement: 'left',
      padding: {
        left: 10
      }
    },
    {
      state: 'view-teacherdash',
      params: {id: -1},
      selector: '.labels li:first .private',
      content: 'KEEP_TAG_PRIVATE_TEXT',
      placement: 'bottom'
    }
  ];

  CueThinkConfig.problemBankTutorialSteps = [
    {
      state: 'view-all-problems',
      selector: '.folders-pane',
      content: 'H_WANT_TO_VIEW_PROBLEMS__H__P_YOU_CAN_CHOOSE_FROM__P_UL_LI_CUETHINK_PROBLEMS__LI_LI_MY_PROBLEMS_LI_LI_PROBLEMS_IN_FOLDERS_LI__UL',
      placement: 'right'
    },
    {
      state: 'view-all-problems',
      selector: '.filterContent',
      content: 'H_WANT_TO_FILTER_PROBLEMS__H__P_FILTER_PROBLEMS_BY_TITLE_AUTHOR_OR_COMMON_CORE_STANDARDS_FILTERS_CAN_ALSO_BE_APPLIED_TO_PROBLEMS_WITHIN_A_FOLDER__P',
      placement: 'bottom'
    },
    {
      state: 'view-all-problems',
      selector: '.folders',
      content: 'H_WANT_TO_ORGANIZE_PROBLEMS_INTO_FOLDERS__H__P_SELECT__NEW_TO_CREATE_A_FOLDER_OR_SUBFOLDER_THEN_DRAG_AND_DROP_PROBLEMS_INTO_THE_FOLDER__P',
      placement: 'right',
      padding: 10
    },
    {
      state: 'view-all-problems',
      selector: '.myProblemBank',
      content: 'H_WANT_TO_EDIT_A_FOLDER__H__P_WHEN_VIEWING_A_FOLDER_THE_NAME_WILL_TURN_BLACK_AND_APPEAR_ABOVE_THE_PROBLEMS_SELECT_THE_THREE_DOTS_TO_EDIT_THE_FOLDER_NAME_OR_DELETE_THE_FOLDER__P',
      placement: 'right'
    },
    {
      state: 'view-all-problems',
      selector: '.details',
      content: 'H_WANT_TO_VIEW_AND_ASSIGN_A_PROBLEM__H__P_SELECT_A_PROBLEM_AND_VIEW_INFORMATION_SUCH_AS_THE_STANDARD_QUESTION_AND_ANSWER__P',
      placement: 'left'
    },
    {
      state: 'view-all-problems',
      selector: '.details .delete',
      content: 'H_WANT_TO_REMOVE_A_PROBLEM_FROM_A_FOLDER__H__P_SELECT_REMOVE_TO_TAKE_THE_PROBLEM_OUT_OF_YOUR_FOLDER_TO_DELETE_A_PROBLEM_YOU_CREATED_GO_TO_MY_PROBLEMS__P',
      placement: 'top',
      padding: {left: 10, right: 10, top: 10, bottom: 20}
    }
  ];

  CueThinkConfig.sentenceStarters = [
    'THE_STRATEGY_I_AM_GOING_TO_USE_IS',
    'FIRST_I_WILL',
    'THEN_I_WILL',
    'I_WILL_CHECK_MY_WORK_BY',
    'IF_MY_STRATEGY_IS_NOT_WORKING_I_WILL'
  ];

  CueThinkConfig.defaultSettings = {
    creatingThinklets: {
      fourPhases: {
        understand: {
          enabled: true,
          required: false
        },
        plan: {
          enabled: true,
          required: false
        },
        solve: {
          enabled: true,
          required: true
        },
        review: {
          enabled: true,
          required: true
        }
      },
      plan: {
        sentenceStarters: [
          {
            enabled: true,
            text: 'THE_STRATEGY_I_AM_GOING_TO_USE_IS'
          },
          {
            enabled: true,
            text: 'FIRST_I_WILL'
          },
          {
            enabled: true,
            text: 'THEN_I_WILL'
          },
          {
            enabled: true,
            text: 'I_WILL_CHECK_MY_WORK_BY'
          },
          {
            enabled: true,
            text: 'IF_MY_STRATEGY_IS_NOT_WORKING_I_WILL'
          }
        ],
        strategies: [
          {
            enabled: true,
            text: 'DRAW_A_PICTURE'
          },
          {
            enabled: true,
            text: 'USE_MANIPULATIVES'
          },
          {
            enabled: true,
            text: 'LOOK_FOR_A_PATTERN'
          },
          {
            enabled: true,
            text: 'MAKE_A_TABLE'
          },
          {
            enabled: true,
            text: 'TRY_WITH_FRIENDLIER_NUMBERS'
          },
          {
            enabled: true,
            text: 'WORK_BACKWARDS'
          },
          {
            enabled: true,
            text: 'GUESS_CHECK_AND_REVISE'
          },
          {
            enabled: true,
            text: 'SHOW_WITH_AN_EQUATION'
          }
        ]
      },
      solve: {
        toolOrder: ['text', 'pen', 'highlighter', 'image', 'shapes', 'stamps', 'numberBond', 'unifixCube', 'diagrams', 'array', 'numberLine', 'fractionBar', 'protractor', 'table', 'grid', 'equation', 'algebraTile'],
        tools: {
          pen: {
            enabled: true
          },
          highlighter: {
            enabled: true
          },
          image: {
            enabled: true
          },
          shapes: {
            enabled: true
          },
          stamps: {
            enabled: true
          },
          diagrams: {
            enabled: true
          },
          table: {
            enabled: true
          },
          array: {
            enabled: true
          },
          numberBond: {
            enabled: true
          },
          grid: {
            enabled: true
          },
          equation: {
            enabled: true
          },
          numberLine: {
            enabled: true
          },
          text: {
            enabled: true
          },
          unifixCube: {
            enabled: true
          },
          protractor: {
            enabled: true
          },
          algebraTile: {
            enabled: true
          },
          fractionBar: {
            enabled: true
          }
        }
      }
    },
    viewingThinklets: {
      annotations: {
        enabled: true,
        notAllowedForClasses: [],
        sentenceStarters: [
          {
            enabled: true,
            text: 'NEXT_TIME_MAYBE_YOU_CAN',
          },
          {
            enabled: true,
            text: 'I_LIKE_THE_WAY_YOU',
          },
          {
            enabled: true,
            text: 'I_RESPECTFULLY_DISAGREE_WITH_YOU',
          },
          {
            enabled: true,
            text: 'MY_STRATEGY_IS_LIKE_YOURS_BECAUSE',
          },
          {
            enabled: true,
            text: "I_HADN_T_THOUGHT_OF",
          },
          {
            enabled: true,
            text: 'WHY_DID_YOU'
          }
        ]
      },
      gallery: {
        nameFormat: '%f %li',
        thinkletPrivacySetting: 'in-progress'
      }
    }
  };

  CueThinkConfig.colorThemes = [
    {
      name: 'DEFAULT',
      colors: {
        explore: {
          background: '#FFA62F',
          navBar: '#FFC87E'
        },
        plan: {
          background: '#64C695',
          navBar: '#7AE1AD'
        },
        solve: {
          background: '#00A6E5',
          navBar: '#00BAFF'
        },
        review: {
          background: '#9A9FFF',
          navBar: '#B7BBFF'
        }
      }
    },
    {
      name: 'ELECTRIC_GARDEN',
      colors: {
        explore: {
          background: '#61D283',
          navBar: '#7BF19E'
        },
        plan: {
          background: '#D2C2F7',
          navBar: '#E5DAFF'
        },
        solve: {
          background: '#D3FF65',
          navBar: '#DFFF8F'
        },
        review: {
          background: '#1A434E',
          navBar: '#27606F',
          text: 'white'
        }
      }
    },
    {
      name: 'SPORTY',
      colors: {
        explore: {
          background: '#FC8C34',
          navBar: '#FFC87E'
        },
        plan: {
          background: '#41B4E6',
          navBar: '#52CBFF'
        },
        solve: {
          background: '#8AEAFF',
          navBar: '#B3F1FF'
        },
        review: {
          background: '#3A64AA',
          navBar: '#5B98FF',
          text: 'white'
        }
      }
    },
    {
      name: 'COTTON_CANDY',
      colors: {
        explore: {
          background: '#E480CC',
          navBar: '#F89FE3'
        },
        plan: {
          background: '#4798FF',
          navBar: '#7AB4FF'
        },
        solve: {
          background: '#7DDFFF',
          navBar: '#A4E6FF'
        },
        review: {
          background: '#FFBFE2',
          navBar: '#FFD5EC'
        }
      }
    },
    {
      name: 'TROPICAL',
      colors: {
        explore: {
          background: '#D6FFF7',
          navBar: '#E8FFFB'
        },
        plan: {
          background: '#7CE3D7',
          navBar: '#8EFFF2'
        },
        solve: {
          background: '#FF958F',
          navBar: '#FDA7A2'
        },
        review: {
          background: '#FF714E',
          navBar: '#FF9479'
        }
      }
    },
    {
      name: 'CIRCUS',
      colors: {
        explore: {
          background: '#FF716C',
          navBar: '#FF9995'
        },
        plan: {
          background: '#FFC440',
          navBar: '#FFD372'
        },
        solve: {
          background: '#6DD773',
          navBar: '#87F48D'
        },
        review: {
          background: '#509CFF',
          navBar: '#7FB6FF'
        }
      }
    }
  ];

  CueThinkConfig.defaultAvatarColors = [
    {
      background: '#FFA62F',
      border: '#E88D14'
    },
    {
      background: '#64C695',
      border: '#2CA76A'
    },
    {
      background: '#00A6E5',
      border: '#018DC1'
    },
    {
      background: '#9A9FFF',
      border: '#787DE3'
    },
    {
      background: '#FF7C2F',
      border: '#D9631D'
    },
    {
      background: '#64BFC6',
      border: '#47ABB3'
    },
    {
      background: '#FFDA2F',
      border: '#FFDA2F'
    },
    {
      background: '#FFDA2F',
      border: '#E6C321'
    }
  ];

  CueThinkConfig.digitalPromiseProbePrompt = {
    responses: [
      {
        text: 'FRACTION_BARS',
        image: '/images/FractionBarImage_@2x.jpg',
        questions: [
          {
            text: 'WERE_THEY_HELPFUL',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          },
          {
            text: 'WERE_THEY_EASY_TO_USE',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          }
        ]
      },
      {
        text: 'ALGEBRA_TILES',
        image: '/images/Algebratiles_2x.jpg',
        questions: [
          {
            text: 'WERE_THEY_HELPFUL',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' },
            ],
            textfield: 'PLEASE_EXPLAIN'
          },
          {
            text: 'WERE_THEY_EASY_TO_USE',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          }
        ]
      },
      {
        text: 'SENTENCE_STARTERS',
        image: '/images/SentencesStarters_2x.jpg',
        questions: [
          {
            text: 'WERE_THEY_HELPFUL',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          },
          {
            text: 'WERE_THEY_EASY_TO_USE',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          }
        ]
      },
      {
        text: 'TEXT_TO_SPEECH',
        image: '/images/SpeechToText_2x.jpg',
        questions: [
          {
            text: 'WAS_THIS_HELPFUL',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          },
          {
            text: 'WAS_THIS_EASY_TO_USE',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          }
        ]
      },
      {
        text: 'SPEECH_TO_TEXT',
        image: '/images/SpeechToText_2x.jpg',
        questions: [
          {
            text: 'WAS_THIS_HELPFUL',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          },
          {
            text: 'WAS_THIS_EASY_TO_USE',
            responses: [
              { text: 'YES' },
              { text: 'KIND_OF' },
              { text: 'NO' }
            ],
            textfield: 'PLEASE_EXPLAIN'
          }
        ]
      }
    ]
  };

  CueThinkConfig.smartDesignSettings = {
    creatingThinklets: {
      plan: {
        strategies: [
          {
            enabled: true,
            text: 'CREATE_A_MODEL'
          },
          {
            enabled: true,
            text: 'LOOK_FOR_A_PATTERN'
          },
          {
            enabled: true,
            text: 'GUESS_CHECK_AND_REVISE'
          },
          {
            enabled: true,
            text: 'TRY_WITH_FRIENDLIER_NUMBERS'
          },
          {
            enabled: true,
            text: 'SHOW_WITH_AN_EQUATION'
          }
        ]
      },
      solve: {
        toolOrder: ['text', 'pen', 'shapes', 'stamps', 'numberLine', 'fractionBar', 'table', 'grid', 'equation', 'protractor', 'unifixCube', 'image', 'diagrams', 'algebraTile'],
        hiddenTools: ['protractor', 'unifixCube', 'image', 'diagrams', 'algebraTile'],
        tools: {
          text: {
            enabled: true
          },
          pen: {
            enabled: true
          },
          shapes: {
            enabled: true
          },
          stamps: {
            enabled: true
          },
          numberLine: {
            enabled: true
          },
          fractionBar: {
            enabled: true
          },
          table: {
            enabled: true
          },
          grid: {
            enabled: true
          },
          equation: {
            enabled: true
          },
          protractor: {
            enabled: true
          },
          unifixCube: {
            enabled: true
          },
          image: {
            enabled: true
          },
          diagrams: {
            enabled: true
          },
          algebraTile: {
            enabled: true
          }
        }
      }
    }
  };

  CueThinkConfig.strategyVideos = {
    'CREATE_A_MODEL': 'https://player.vimeo.com/video/922957170?h=e26f3b6990',
    'LOOK_FOR_A_PATTERN': 'https://player.vimeo.com/video/922963705?h=325e1287ab',
    'GUESS_CHECK_AND_REVISE': 'https://player.vimeo.com/video/922964818?h=1532c8b378',
    'TRY_WITH_FRIENDLIER_NUMBERS': 'https://player.vimeo.com/video/918067238?h=4bd351294a',
    'SHOW_WITH_AN_EQUATION': 'https://player.vimeo.com/video/922967398?h=f071d7e34d'
  };
})();
