import ShapeTool from './shape-tool';
import { inherit } from './base-tool';

export default function LineTool (whiteboard) {
  var me = this;

  ShapeTool.call(this, whiteboard);

  this.getDataConstructor = function () {
    return function (sx, sy, ex, ey) {
      this.id = whiteboard.getNextId();
      this.type = LineTool.type;
      this.coordinates = [sx, sy, ex, ey];
      this.color = whiteboard.getColor();

      whiteboard.updateEventTime(this);
    };
  };

  me.draw = function (obj) {
    var context = whiteboard.getContext();

    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();

    context.beginPath();
    context.moveTo(sx, sy);
    context.lineTo(ex, ey);
    context.closePath();
    context.stroke();

    var tmp;

    if (obj.selected) {
      if (ex < sx) {
        tmp = sx;
        sx = ex;
        ex = tmp;
      }

      if (ey < sy) {
        tmp = sy;
        sy = ey;
        ey = tmp;
      }
    }
  };
}

inherit(LineTool, ShapeTool);

LineTool.type = 'line';
