import ShapeTool from './shape-tool';
import { inherit } from './base-tool';

export default function RegularPolygonTool (whiteboard) {
  ShapeTool.call(this, whiteboard);

  this.getSideCount = function () {
    // override
  };

  this.getAngleOffset = function () {
    return 0;
  };

  this.draw = function (obj) {
    var context = whiteboard.getContext();

    var sx = obj.coordinates[0] * whiteboard.getWidth();
    var sy = obj.coordinates[1] * whiteboard.getHeight();
    var ex = obj.coordinates[2] * whiteboard.getWidth();
    var ey = obj.coordinates[3] * whiteboard.getHeight();

    var tmp;

    if (ex < sx) {
      tmp = sx;
      sx = ex;
      ex = tmp;
    }

    if (ey < sy) {
      tmp = sy;
      sy = ey;
      ey = tmp;
    }

    var width = ex - sx;
    var height = ey - sy;
    var halfWidth = width / 2;
    var halfHeight = height / 2;

    var vertices = RegularPolygonTool.getRegularPolygonVertices(this.getSideCount(), this.getAngleOffset());

    vertices.forEach(function (vertex) {
      vertex.x = sx + halfWidth + (vertex.x * halfWidth);
      vertex.y = sy + halfHeight + (vertex.y * halfHeight);
    });

    context.beginPath();

    var vertex = vertices[0];
    context.moveTo(vertex.x, vertex.y);

    for (var i = 1; i < vertices.length; i++) {
      vertex = vertices[i];

      context.lineTo(vertex.x, vertex.y);
    }

    context.closePath();
    context.stroke();
  };
}

inherit(RegularPolygonTool, ShapeTool);

RegularPolygonTool.getRegularPolygonVertices = function (sides, angleOffset) {
  var result = [];

  for (var i = 0; i < sides; i++) {
    var angle = angleOffset + ((i * 2 * Math.PI) / sides);

    result.push({
      x: Math.cos(angle),
      y: Math.sin(angle)
    });
  }

  return result;
};
